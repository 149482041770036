import React from "react"
import Modal from "react-modal"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import LiveMeetingSelectedCaseDetails from "./LiveMeetingSelectedCaseDetails"
import LiveMeetingCaseSelect from "./LiveMeetingCaseSelect"
import Button from "../../../../shared/components/buttons/Button"
import { MockStore } from "redux-mock-store"
import { IconFolder } from "../../../../svg/icons"
import { defaultModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"
import { CaseStage } from "../../state/liveMeetingTypes"

interface IProps {
  store?: MockStore
  meetingId: string
}

const LiveMeetingCaseContainer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "liveMeeting"])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const user = useSelector(getCurrentUserWithConfig)
  const selectedMeetingCase = useSelector(getSelectedMeetingCase)
  const liveMeetingConfig = useSelector(getLiveMeetingConfig)

  const openModal = (): void => { setIsOpen(true) }

  const closeModal = (): void => { setIsOpen(false) }

  const renderSelectCaseButton = (): JSX.Element => {
    if (!user.config?.isPresenter) return

    const buttonText = selectedMeetingCase ? t("liveMeeting:switchCase") : t("liveMeeting:selectCase")

    const enabled = liveMeetingConfig.caseStage == CaseStage.PRESENTING

    return (
      <Button action={openModal} className="self-center" variant={StyleVariants.ORANGE} isChunky={true} isDisabled={!enabled}>
        {buttonText}
      </Button>
    )
  }

  if (!user.config) return null

  return (
    <div>
      <div className="sm:flex">
        <span className="flex items-center text-ch-purple-500 mr-3">
          <IconFolder className="fill-current w-6 h-6 mr-2" title={t("shared:case")} />
          <span className="text-lg">
            {t("shared:case")}
          </span>
        </span>

        <div className="flex-1 mr-3">
          <LiveMeetingSelectedCaseDetails />
        </div>

        <div className="flex sm:justify-end">
          {renderSelectCaseButton()}
        </div>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeModal} >
          <h2 className="text-lg text-ch-blue-600 mb-1">
            {t("liveMeeting:chooseCase")}
          </h2>
          <LiveMeetingCaseSelect meetingId={props.meetingId} closeModal={closeModal} />
        </ModalContainer>
      </Modal>
    </div>
  )
}

export default LiveMeetingCaseContainer
