import React, { useContext } from "react"
import { UserConfigContext } from "../../../features/userConfig/contexts/UserConfigContext"
import Flag from "../../../shared/components/ui/Flag"

const Timezone: React.FC = (): JSX.Element => {
  const { timezone } = useContext(UserConfigContext)

  return (
    <div className="flex items-center">
      <div className="border-2 border-ch-gray-300 rounded-full overflow-hidden relative w-8 h-8 mr-2 hidden">
        <Flag flagKey={timezone} className="absolute w-full h-full" />
      </div>
      <span className="text-ch-gray-500">
        {timezone}
      </span>
    </div>
  )
}

export default Timezone
