import { Reducer, AnyAction } from "redux"
import { SharedActionTypes } from "../../../../shared/state/sharedTypes"
import { INavUIState, NavActionTypes } from "../navTypes"

export const initialState: INavUIState = {
  isMenuOpen: true,
  isQuickViewOpen: false,
  quickViewResourceId: null
}

export const navUIReducer: Reducer<INavUIState> = (state: INavUIState = initialState, action: AnyAction): INavUIState => {
  switch (action.type) {
    case SharedActionTypes.REHYDRATE:
      if(!action.payload?.ui.nav) return { ...state }
      return {
        ...state,
        ...action.payload.ui.nav
      }
    case NavActionTypes.TOGGLE_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload
      }
    case NavActionTypes.TOGGLE_IS_QUICK_VIEW_OPEN:
      return {
        ...state,
        ...action.payload
      }
    default:
      return { ...state }
  }
}
