import { ChatActionTypes, ChatCableActionTypes, IExportChatAsyncAction, IExportChatData, IFetchChatAsyncAction, IMarkChatMessagesAsReadAction, IToggleMutedChatAsyncAction, IUpdateUserIsTypingAction } from "./chatTypes"

export const fetchChatAsync = (chatId: string): IFetchChatAsyncAction => {
  return {
    type: ChatActionTypes.FETCH_CHAT_ASYNC_PENDING,
    payload: chatId
  }
}

export const endChatAsync = (chatId: string): IFetchChatAsyncAction => {
  return {
    type: ChatActionTypes.END_CHAT_ASYNC_PENDING,
    payload: chatId
  }
}

export const toggleMutedChatAsync = (chatId: string): IToggleMutedChatAsyncAction => {
  return {
    type: ChatActionTypes.TOGGLE_MUTED_CHAT_ASYNC_PENDING,
    payload: chatId
  }
}

export const exportChatAsync = (chatId: string, exportChatData: IExportChatData): IExportChatAsyncAction => {
  return {
    type: ChatActionTypes.EXPORT_CHAT_ASYNC_PENDING,
    payload: {
      chatId: chatId,
      exportChatData: exportChatData
    }
  }
}

export const updateUserIsTyping = (chatId: string, userId: string, isTyping: boolean): IUpdateUserIsTypingAction => {
  return {
    type: ChatCableActionTypes.UPDATE_USER_IS_TYPING,
    payload: {
      chatId,
      userId,
      isTyping
    },
    meta: { isOptimistic: false }
  }
}

export const fetchChatAssetsAsync = (chatId: string): IFetchChatAsyncAction => {
  return {
    type: ChatActionTypes.FETCH_CHAT_ASSETS_ASYNC_PENDING,
    payload: chatId
  }
}

export const markChatMessagesAsRead = (chatId: string): IMarkChatMessagesAsReadAction => {
  return {
    type: ChatCableActionTypes.MARK_CHAT_MESSAGES_AS_READ,
    payload: {
      chatId: chatId
    }
  }
}
