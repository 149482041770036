import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { lookupLiveMeetingViewerForAsset } from "../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../shared/selectors/asset"
import { ILiveMeetingAssetViewerProps } from "../../state/liveMeetingTypes"

interface IProps {
  assetId: string
}

const LiveMeetingYourViewAssetViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["asset"])
  const dispatch = useDispatch()
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const updateYourViewSelectedAsset = (assetId: string) => dispatch(updateYourViewSelectedAsset(assetId))

  if (!asset) return <h3 className="text-lg font-bold text-center">{t("asset:assetNotFound")}</h3>

  const AssetViewer = lookupLiveMeetingViewerForAsset(asset)

  if (!AssetViewer) return <h3 className="text-lg font-bold text-center">{t("asset:couldNotDisplayAsset")}</h3>

  const viewerProps: ILiveMeetingAssetViewerProps = {
    assetId: props.assetId,
    onSelectAsset: updateYourViewSelectedAsset
  }

  return (
    <AssetViewer {...viewerProps} />
  )
}

export default LiveMeetingYourViewAssetViewer
