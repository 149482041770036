import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const patientAmendmentValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    reason: Yup
      .string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(1500, i18n.t("shared:maxLengthExceeded", { maxLength: 1500 })),
    amendmentType: Yup
      .string()
      .trim()
      .required(i18n.t("shared:required"))
      .nullable()
  })
}
