import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, ICreatePathwayAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"
import history from "../../../../app/routes/history"
import { RoutePaths } from "../../../../app/routes/Routes"
import { generatePath } from "react-router"

export function* createPathwayAsync(action: ICreatePathwayAction): Generator {
  try {
    const pathway = {
      pathway: action.payload
    }

    const response: any = yield call(API.post, "/pathways", pathway, withAuthHeader())

    const normalizedResponse = normalize(response.data, pathwaysSchema)

    const pathwayDetailsUrl = generatePath(RoutePaths.PATHWAY, {
      pathwayId: response.data.id
    })

    yield put({
      type: PathwayActionTypes.CREATE_PATHWAY_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "createPathwaySuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, pathwayDetailsUrl)

  } catch (error) {
    yield put({
      type: PathwayActionTypes.CREATE_PATHWAY_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createPathwayFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreatePathwayAsync(): Generator {
  yield takeLeading(PathwayActionTypes.CREATE_PATHWAY_ASYNC_PENDING, createPathwayAsync)
}
