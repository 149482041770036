import React from "react"
import { useTranslation } from "react-i18next"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { differenceInSeconds } from "date-fns"

type Props = {
  startDate: string
  endDate: string
}

const MeetingDateTime = (props: Props): JSX.Element => {
  const { t } = useTranslation(["meeting"])
  const startDate = new Date(props.startDate)
  const endDate = new Date(props.endDate)
  const secondsDuration = differenceInSeconds(endDate, startDate)

  const duration = {
    minutes: Math.floor(secondsDuration / 60),
    hours: Math.floor(secondsDuration / 60 / 60)
  }

  const hourLabel = t("hour"),
    hoursLabel = t("hours"),
    minLabel = t("min")
  const getMinText = (hours: number, minutes) => {
    const minLeft = minutes - Math.floor(hours) * 60
    return minLeft > 0 ? ` ${minLeft} ${minLabel}` : ""
  }
  const hourText = (hours: number) => {
    hours = Math.floor(hours)
    return hours > 1 ? `${hours} ${hoursLabel}` : `1 ${hourLabel}`
  }

  let durationText = ""
  if (duration.hours < 1) {
    durationText = `${getMinText(0, duration.minutes).trim()}`
  } else {
    durationText = `${hourText(duration.hours)}${getMinText(
      duration.hours,
      duration.minutes
    )}`
  }
  return (
    <div>
      <TimeDefaultFormat utcTime={props.startDate} />
      &nbsp;<span>({durationText})</span>
    </div>
  )
}

export default MeetingDateTime
