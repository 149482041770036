import { Formik, Form } from "formik"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import FieldError from "../../../shared/components/forms/FieldError"
import InputField from "../../../shared/components/forms/InputField"
import Label from "../../../shared/components/forms/Label"
import Buttons from "../../../shared/components/layout/Buttons"
import { getCasePathwayById } from "../../../shared/selectors/casePathway"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ICasePathway, ICasePathwayEditData } from "../state/casePathwayTypes"
import { casePathwayEditValidation } from "../state/validation/casePathwayEditValidation"

interface IPropsFromParent {
  submitHandler: (values, options) => void
  cancelHandler: () => void
  casePathwayId: string
}

const EditCasePathwayForm: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  const initialValues: ICasePathwayEditData = {
    name: casePathway.name,
    description: casePathway.description,
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={props.submitHandler}
    validationSchema={casePathwayEditValidation}
  >
    {({
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      dirty,
      errors,
      touched
    }) => {
      return (
        <Form>
           <div className="mb-2">
            <Label name="name" required>
              {t("pathway:name")}
            </Label>
            <InputField
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              autoComplete="off"
              autoFocus={true}
            />
            <FieldError
              errorMessage={errors.name as string}
              isVisible={(errors.name && touched.name) as boolean}
            />
          </div>

          <div className="mb-2">
            <Label name="description" required>
              {t("pathway:summary")}
            </Label>
            <InputField
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              autoComplete="off"
              as="textarea"
              className={"h-32"}
            />
            <FieldError
              errorMessage={errors.description as string}
              isVisible={(errors.description && touched.description) as boolean}
            />
          </div>

          <div className="flex justify-center mb-6">
            <Buttons buttons={[
              <Button
                key="1"
                variant={StyleVariants.BLUE_ALT}
                action={() => props.cancelHandler()}
              >
                {t("shared:cancel")}
              </Button>,
              <Button
                key="2"
                isDisabled={!dirty || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}
              >
                {t("case:casePathways:buttons:updateCasePathway")}
              </Button>]}
            />
          </div>
        </Form>
      )
    }}
  </Formik >
}

export default EditCasePathwayForm
