export interface IMeetingsUIState {
  idsOnIndexPage: string[]
  totalMeetingsCount: number
  totalPages: number
  appliedFilters: string[]
  orderBy: string
  resultsPerPage: number
  searchTerm: string
  isListView: boolean
}

export enum MeetingsOrderByOptions {
  DateDesc = "date_desc",
  DateAsc = "date_asc",
  NameAsc = "name_asc",
  NameDesc = "name_desc"
}
