import * as Yup from "yup"
import i18n from "../../../../app/i18n"
import { dateIsFuture } from "../../../../shared/helpers/timeHelpers"

export const caseTaskNewValidation = (timezone: string): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    action: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(100, i18n.t("shared:maxLengthExceeded", { maxLength: 100 })),
    description: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(1500, i18n.t("shared:maxLengthExceeded", { maxLength: 1500 })),
    assigneeIds: Yup.array()
      .required(i18n.t("shared:required"))
      .min(1, i18n.t("shared:mustSelectAtLeastOne")),
    dueDate: Yup.date()
      .required(i18n.t("shared:required"))
      .test("dateisFuture", i18n.t("shared:requireFutureDate"), function (value) {
        return dateIsFuture(value, this.parent.startTime, timezone)
      }),
  })
}
