import React, { useState } from "react"
import { Form, Formik } from "formik"
import _ from "lodash"
import { ITeamNewData } from "../../team/state/teamTypes"
import { mdtTeamNewValidationSchema, teamNewValidationSchema } from "../state/AddNewTeamValidation"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import { createTeamAsync } from "../../team/state/teamActions"
import Card from "../../../shared/components/layout/Card"
import TeamNewFormContent from "./TeamNewFormContent"
import MdtTeamNewFormContent from "./MdtTeamNewFormContent"
import { useAppSelector, useAppDispatch } from "../../../shared/hooks"
import { getTenantConfig } from "../../../shared/selectors/tenantConfig"
import MdtTeamToggleField from "./MdtTeamToggleTypeField"


const TeamNewForm: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const createTeam = (team: ITeamNewData) => dispatch(createTeamAsync(team))
  const { mdtTeamsEnabled } = useAppSelector(getTenantConfig)

  const initialValues: ITeamNewData = {
    teamType: "basic",
    name: "",
    specialtyId: "",
    ownerId: "",
    teamUsers: []
  }
  const [isMdtTeam, setIsMdtTeam] = useState(false)

  return (
    <Card className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={isMdtTeam ? mdtTeamNewValidationSchema() : teamNewValidationSchema()}
        onSubmit={(values, { setSubmitting }) => {
          const data = teamNewValidationSchema().cast(values)

          createTeam(data)
          setSubmitting(false)
        }}
      >
        {() => {
          return (
            <Form className="w-full lg:w-2/3">
              <DenotesRequiredMessage />

              {mdtTeamsEnabled ? <MdtTeamToggleField isMdtTeam={isMdtTeam} setIsMdtTeam={setIsMdtTeam} /> : null}

              {isMdtTeam ? <MdtTeamNewFormContent /> : <TeamNewFormContent />}
            </Form>
          )
        }}
      </Formik>
    </Card >
  )
}

export default TeamNewForm
