import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { ChatActionTypes, IExportChatAsyncAction } from "../chatTypes"

export function* exportChatAsync(action: IExportChatAsyncAction): Generator {
  try {
    const chatId = action.payload.chatId
    const exportChatData = action.payload.exportChatData

    const data = {
      chat: exportChatData
    }

    const response: any = yield call(API.patch, `/chats/${chatId}/assets/export`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchChatsSchema)

    yield put({
      type: ChatActionTypes.EXPORT_CHAT_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "exportChatSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: ChatActionTypes.EXPORT_CHAT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "exportChatFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchExportChatAsync(): Generator {
  yield takeLeading(ChatActionTypes.EXPORT_CHAT_ASYNC_PENDING, exportChatAsync)
}
