import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import * as Yup from "yup"
import { IAppState } from "../../../../../app/appTypes"
import i18n from "../../../../../app/i18n"
import Button from "../../../../../shared/components/buttons/Button"
import DenotesRequiredMessage from "../../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../../shared/components/forms/FieldError"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import MentionField from "../../../../../shared/components/forms/MentionField"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { getTaskById } from "../../../../../shared/selectors/task"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { ICasePathwayTaskUpdateData } from "../../../../tasks/state/tasksTypes"

interface IEditTaskProps {
  taskId: string
  cancelHandler: () => void
  submitHandler: (values: ICasePathwayTaskUpdateData) => void
}

const validationSchema = Yup.object().shape(
  {
    action: Yup.string()
      .nullable()
      .required(i18n.t("shared:required")),
    description: Yup.string()
      .nullable()
      .required(i18n.t("shared:required"))
  }
)

const EditCasePathwayTaskForm: React.FC<IEditTaskProps> = (props: IEditTaskProps): JSX.Element => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const { t } = useTranslation(["shared", "task"])

  const initialValues = {
    action: task.action,
    description: task.description
  }

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(true)
      props.submitHandler(values)
    }}
  >
    {({
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      dirty,
      setFieldValue,
      errors,
      touched,
      isValid
    }) => {
      return (
        <Form>
          <div className="mb-6 w-full">
          <DenotesRequiredMessage />
          <div className="mb-2">
            <Label name="action" required={true}>
              {t("task:action")}
            </Label>
            <InputField
              type="text"
              name="action"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.action}
              autoComplete="off"
            />
            <FieldError errorMessage={errors.action as string} isVisible={(errors.action && touched.action) as boolean} />
          </div>

          <div className="mb-2">
            <Label name="description" required={true}>
              {t("task:description")}
            </Label>
            <MentionField
              name="description"
              setValue={(description) => setFieldValue("description", description)}
              onBlur={handleBlur}
              users={null}
              value={values.description}
            />
            <FieldError errorMessage={errors.description as string} isVisible={(errors.description && touched.description) as boolean} />
          </div>

          </div>
          <div className="flex justify-center mb-6">
            <Buttons buttons={[
              <Button
                key="1"
                variant={StyleVariants.BLUE_ALT}
                action={() => props.cancelHandler()}
              >
                {t("shared:cancel")}
              </Button>,
              <Button
                key="2"
                isDisabled={!dirty || !isValid || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}
              >
                {t("task:updateTask")}
              </Button>]}
            />
          </div>
        </Form>
      )
    }}
  </Formik >
}

export default EditCasePathwayTaskForm
