import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { ChatActionTypes, IEndChatAsyncAction } from "../chatTypes"

export function* endChatAsync(action: IEndChatAsyncAction): Generator {
  try {
    const chatId = action.payload

    const response: any = yield call(API.patch, `/chats/${chatId}/end`, {}, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchChatsSchema)

    yield put({
      type: ChatActionTypes.END_CHAT_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "endChatSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: ChatActionTypes.END_CHAT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "endChatFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchEndChatAsync(): Generator {
  yield takeLeading(ChatActionTypes.END_CHAT_ASYNC_PENDING, endChatAsync)
}
