import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconMessage({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 35 35"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M31.111 0H3.89C1.744 0 0 1.57 0 3.5v21C0 26.43 1.744 28 3.889 28h6.805l6.806 7 6.806-7h6.805C33.256 28 35 26.43 35 24.5v-21C35 1.57 33.256 0 31.111 0zM11.667 17.5c-.511 0-1.017-.09-1.489-.267a3.944 3.944 0 01-1.262-.759 3.502 3.502 0 01-.843-1.136 3.199 3.199 0 01-.295-1.34c0-.46.1-.915.296-1.34.196-.424.482-.81.844-1.135.361-.325.79-.583 1.262-.759a4.266 4.266 0 011.489-.266c1.031 0 2.02.37 2.75 1.026.73.657 1.139 1.548 1.138 2.476 0 .928-.41 1.819-1.14 2.475-.73.657-1.719 1.025-2.75 1.025zm11.666 0c-.51 0-1.016-.09-1.488-.267a3.944 3.944 0 01-1.262-.759 3.5 3.5 0 01-.843-1.136 3.199 3.199 0 01-.296-1.34c0-.46.101-.915.297-1.34a3.5 3.5 0 01.843-1.135c.362-.325.79-.583 1.263-.759a4.267 4.267 0 011.488-.266c1.032 0 2.021.37 2.75 1.026.73.657 1.14 1.548 1.14 2.476-.001.928-.411 1.819-1.14 2.475-.73.657-1.72 1.025-2.752 1.025z"
      />
    </svg>
  );
}

export default SvgIconMessage;
