import React from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import IconCircleContainer from "../../../../../shared/components/icons/IconCircleContainer"
import Card from "../../../../../shared/components/layout/Card"
import DetailsCard from "../../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../../shared/components/layout/detailsCard/DetailsCardRight"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { getTaskById } from "../../../../../shared/selectors/task"
import { smallModalStyle } from "../../../../../shared/state/sharedTypes"
import { IconPathway } from "../../../../../svg/icons"
import { updateCasePathwayTaskAction } from "../../../../casePathways/state/casePathwayActions"
import { ICasePathwayTaskUpdateData } from "../../../../tasks/state/tasksTypes"
import AssignCasePathwayTaskForm from "./AssignCasePathwayTaskForm"
import CasePathwayTaskDetails from "./CasePathwayTaskDetails"
import PathwayTaskStatusLabel from "./PathwayTaskStatusLabel"

interface IProps {
  taskId: string
  casePathwayId: string
  isOpen: boolean
  closeModal: () => void
}

const AssignCasePathwayTaskModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const casePathway = useSelector(getCasePathwayById(props))
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const dispatch = useDispatch()

  const submitHandler = (taskData: ICasePathwayTaskUpdateData) => {
    dispatch(updateCasePathwayTaskAction(task.caseId, props.taskId, taskData))
    props.closeModal()
  }

  if (!casePathway) return null
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={smallModalStyle} >
      <Card>
        <DetailsCard className="bg-white" hasBorder={false}>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-7" sizeClasses={["w-12", "h-12"]}>
              <IconPathway className="w-6 h-6" title={casePathway.name} />
            </IconCircleContainer>
            <div className="flex-1 lg:grid grid-cols-2 gap-3">
              <ul className="col-span-1">
                <li>
                  <h4 className="font-semibold">
                    {casePathway.name}
                  </h4>
                </li>
              </ul>
            </div>
          </DetailsCardInner>
        </DetailsCard>

        <DetailsCard className="bg-ch-blue-100 rounded-md text-sm mt-4" >
          <DetailsCardInner>
            <CasePathwayTaskDetails {...props} />
          </DetailsCardInner>
          <DetailsCardRight>
            <div className="flex flex-row items-baseline justify-end">
              <PathwayTaskStatusLabel task={task} />
            </div>
          </DetailsCardRight>
        </DetailsCard>
      </Card>

      <AssignCasePathwayTaskForm taskId={props.taskId} cancelHandler={props.closeModal} submitHandler={submitHandler} />
    </Modal >
  )
}

export default AssignCasePathwayTaskModal


