/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { useField, useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import enGB from "date-fns/locale/en-GB"
import { formatDatePickerString } from "../../helpers/timeHelpers"
import SvgIconCalendar from "../../../svg/icons/IconCalendar"

interface IProps {
  name: string
  className?: string
  onChange?: (e) => void
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
}

export const DatePickerField: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const defaultClasses = [
    "shadow",
    "appearance-none",
    "border",
    "rounded",
    "w-40",
    "py-2",
    "px-3",
    "text-gray-700",
    "leading-tight",
    "focus:outline-none",
    "focus:shadow-outline",
    "focus:ring-2",
    "ring-ch-blue-alt-300"
  ]
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  const defaultChangeHandler = (val) => {
    setFieldValue(field.name, val)
  }

  const changeHandler = props.onChange ? props.onChange : defaultChangeHandler

  return (
    <label className="flex flex-row items-center">
      <SvgIconCalendar className="text-ch-blue-500 self-center mx-1 w-5 h-5" />
      <DatePicker
        {...field}
        id={props.name}
        locale={enGB}
        className={allClasses}
        dateFormat={formatDatePickerString}
        selected={field.value && new Date(field.value) || null}
        onChange={changeHandler}
        minDate={new Date()}
        showDisabledMonthNavigation
        showMonthDropdown={props.showMonthDropdown}
        showYearDropdown={props.showYearDropdown}
      />
    </label>
  )
}

export default DatePickerField
