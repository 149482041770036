import * as Yup from "yup"
import i18n from "../../../app/i18n"

export const setPasswordValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    password: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .matches(/[^a-zA-Z0-9]/, i18n.t("account:passwordMustContainSpecial"))
      .matches(/[0-9]/, i18n.t("account:passwordMustContainNumber"))
      .matches(/[A-Z]/, i18n.t("account:passwordMustContainUpperCase"))
      .matches(/[a-z]/, i18n.t("account:passwordMustContainLowerCase"))
      .min(8, i18n.t("account:passwordTooShort")),
    passwordConfirmation: Yup.string()
      .trim()
      .oneOf([Yup.ref("password"), null], i18n.t("account:passwordsMustMatch"))
      .required(i18n.t("shared:required"))
  })
}
