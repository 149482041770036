import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { INotification } from "../notificationTypes"
import { NotificationsActionTypes, NotificationsCableActionTypes } from "../../../notifications/state/notificationsTypes"
import { normalize } from "normalizr"
import { notificationsSchema } from "../../../../apis/contextmeeting/schema"

type TByIdState = { [id: string]: INotification }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const markAllAsRead = (notificationsById: TByIdState): TByIdState => {
  return _.mapValues(notificationsById, (notification: INotification) => {
    return {
      ...notification,
      readAt: new Date().toISOString()
    }
  })
}

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
      return { ...action.payload.all.entities.notifications }

    case NotificationsCableActionTypes.DELIVER_NOTIFICATION:
      return {
        ...state,
        // Payload from cable must be normalized
        ...normalize(action.payload.notification, notificationsSchema).entities.notifications || []
      }

    case NotificationsCableActionTypes.MARK_ALL_AS_READ:
      return {
        ...state,
        ...markAllAsRead(state)
      }

    case NotificationsCableActionTypes.MARK_AS_READ:
      return {
        ...state,
        [action.payload.notificationId]: {
          ...state[action.payload.notificationId],
          readAt: new Date().toISOString()
        }
      }

    case NotificationsCableActionTypes.MARK_AS_UNREAD:
      return {
        ...state,
        [action.payload.notificationId]: {
          ...state[action.payload.notificationId],
          readAt: null
        }
      }
    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)

    case NotificationsCableActionTypes.DELIVER_NOTIFICATION:
      return _.uniq(_.concat(action.payload.notification.id, state))

    default:
      return state
  }
}

export const notificationEntityReducer: Reducer<INormalizedEntities<INotification>> = combineReducers({
  byId,
  allIds
})
