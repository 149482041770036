import { Form, Formik } from "formik"
import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import { searchValidationSchema } from "../../state/sharedValidations"
import Button from "../buttons/Button"
import FieldError from "../forms/FieldError"
import InputField from "../forms/InputField"
import _ from "lodash"
import { ISearchQuery, ISetSearchQueryFunction } from "../../hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
  buttonText?: string
}

const SearchInput: React.FC<Props> = (props): JSX.Element => {
  const initialValues = {
    searchTerm: props.searchQuery.searchTerm || ""
  }

  const search = (searchTerm) => {
    props.setSearchQuery({ searchTerm: searchTerm })
  }

  const renderContent = (): JSX.Element => {
    return (
      <div className="flex flex-wrap">
        <Formik
          initialValues={initialValues}
          validationSchema={searchValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            search(values.searchTerm)
            setSubmitting(false)
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            submitForm
          }) => {
            const debouncedSubmit = _.debounce(submitForm, 500)
            return (
              <Form className="mt-2 w-full">
                <div className="mb-2">
                  <InputField
                    type="text"
                    name="searchTerm"
                    onChange={(e) => {
                      handleChange(e)
                      debouncedSubmit()
                    }}
                    onBlur={handleBlur}
                    value={values.searchTerm}
                    autoComplete="off"
                    autoFocus={false}
                  />
                  <FieldError errorMessage={errors.searchTerm as string} isVisible={(errors.searchTerm && touched.searchTerm) as boolean} />
                </div>
                <div className="flex justify-center">
                  <Button
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}>
                    {props.buttonText}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }

  return renderContent()
}

export default SearchInput
