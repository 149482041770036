import React from "react"
import { useTranslation } from "react-i18next"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconPathway } from "../../../svg/icons"
import { useSelector } from "react-redux"
import Card from "../../../shared/components/layout/Card"
import { getCasePathwayById } from "../../../shared/selectors/casePathway"
import { ICasePathway } from "../state/casePathwayTypes"
import CasePathwayDetails from "./CasePathwayDetails"

type IProps = {
  casePathwayId: string
}

const CasePathwayHeader: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  return (
    <div>
      <Card>
        <DetailsCard hasBorder={false}>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-4">
              <IconPathway className="fill-current w-6 h-6" title={t("shared:pathway")} />
            </IconCircleContainer>
            <CasePathwayDetails casePathwayId={props.casePathwayId} />
          </DetailsCardInner>
        </DetailsCard>
        <div className="mt-4 p-4 bg-ch-blue-100 rounded-md">
          <div><b>{t("case:casePathways:summary")}:</b></div>
          <div>{casePathway.description || t("case:casePathways:noSummary")}</div>
        </div>
      </Card>
    </div>
  )
}

export default CasePathwayHeader
