import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const patientCheckValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    lastName: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    dateOfBirth: Yup.string()
    .when(["dobDay", "dobMonth", "dobYear"], {
      is: (dobDay: string, dobMonth: string, dobYear: string) => {
        return (!dobDay || !dobMonth || !dobYear)
      },
      then: Yup.string().trim().required(i18n.t("shared:required"))
    })
  })
}
