import React, { useEffect, useState } from "react"
import fetchBlob from "../../../../../shared/helpers/fetchBlob"
import { IDocumentAsset } from "../../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../../shared/selectors/asset"
import { IAppState } from "../../../../../app/appTypes"
import LoadingSpinner from "../../../../../shared/components/ui/LoadingSpinner"
import PdfViewer from "../../../../../shared/components/ui/PdfViewer"
import { useDispatch, useSelector } from "react-redux"
import { updateSharedPdfStateAction } from "../../../state/liveMeetingActions"
import { ISharedPdfState } from "../../../state/liveMeetingTypes"

interface IProps {
  assetId: string
  rotation?: number
  hideRotateButton?: boolean
}

const LiveMeetingPdfViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const pdf = asset.asset as IDocumentAsset
  const [blobUrl, setBlobUrl] = useState(null)
  const dispatch = useDispatch()
  const updateSharedPdfState = (pdfState: ISharedPdfState) => dispatch(updateSharedPdfStateAction(pdfState))

  useEffect(() => {
    fetchBlob(pdf.path, setBlobUrl)
  }, [pdf.path])

  if (!blobUrl) return <LoadingSpinner />

  return <PdfViewer
    url={blobUrl}
    isDownloadable={false}
    rotateCallback={updateSharedPdfState}
    rotation={props.rotation}
    hideRotateButton={props.hideRotateButton}
  />
}

LiveMeetingPdfViewer.defaultProps = {
  rotation: 0
}

export default LiveMeetingPdfViewer
