import { MeetingCaseActionTypes, ICreateMeetingCaseAction, IFetchMeetingCaseAsyncAction, IDeleteMeetingCaseAction } from "./meetingCaseTypes"

export const createMeetingCaseAction = (meetingId: string, caseId: string, redirectPath?: string): ICreateMeetingCaseAction => {
  return {
    type: MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_PENDING,
    payload: {
      meetingId,
      caseId,
      redirectPath
    }
  }
}

export const fetchMeetingCaseAction = (meetingCaseId: string): IFetchMeetingCaseAsyncAction => {
  return {
    type: MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_PENDING,
    payload: meetingCaseId
  }
}

export const deleteMeetingCaseAction = (meetingId: string, caseId: string, meetingCaseId: string): IDeleteMeetingCaseAction => {
  return {
    type: MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_PENDING,
    payload: {
      meetingId,
      caseId,
      meetingCaseId
    }
  }
}

