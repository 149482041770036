import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { toggleIsMenuOpen } from "../state/navActions"
import { IconMenu } from "../../../svg/icons"
import { getIsMenuOpen } from "../../../shared/selectors/ui"

const MenuButton: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const isMenuOpen = useSelector(getIsMenuOpen)
  const dispatch = useDispatch()
  const onToggleMenuIsOpen = (isMenuOpen: boolean) => { dispatch(toggleIsMenuOpen(isMenuOpen)) }

  if (isMenuOpen) {
    return (
      <a
        href="#"
        className="flex flex-col items-center text-ch-gray-600 transform transition-transform duration-300 rotate-90"
        onClick={() => onToggleMenuIsOpen(false)}
      >
        <IconMenu
          className="fill-current w-6 h-6"
          title={t("shared:closeMenu")}
        />
      </a>
    )
  } else {
    return (
      <a
        href="#"
        className="flex flex-col items-center text-ch-gray-600 transform transition-transform duration-300"
        onClick={() => onToggleMenuIsOpen(true)}
      >
        <IconMenu
          className="fill-current w-6 h-6"
          title={t("shared:openMenu")}
        />
      </a>
    )
  }
}

export default MenuButton
