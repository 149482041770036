import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RoutePaths } from "../../app/routes/Routes"
import { fetchTermsAction } from "../../features/account/state/accountActions"
import TenantLogoUploadForm from "../../features/admin/components/TenantLogoUploadForm"
import TermsFileNewForm from "../../features/admin/components/TermsFileNewForm"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import IconCircleContainer from "../../shared/components/icons/IconCircleContainer"
import ImageThumb from "../../shared/components/images/ImageThumb"
import DetailsCard from "../../shared/components/layout/detailsCard/DetailsCard"
import TextLink from "../../shared/components/text/TextLink"
import TimeDefaultFormat from "../../shared/components/time/TimeDefaultFormat"
import { getTerms } from "../../shared/selectors/user"
import { IconPdf } from "../../svg/icons"

const AdminPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "admin"])
  const dispatch = useDispatch()
  const fetchTerms = () => dispatch(fetchTermsAction())
  const terms = useSelector(getTerms)

  const { tenantLogoPath, tenantName } = useContext(
    TenantConfigContext
  )

  useEffect(() => {
    fetchTerms()
  }, [])

  const renderTermDetails = (): JSX.Element => {
    if (!terms) return (
      <h3 className="text-lg text-center">
        {t("shared:noTermsAdmin")}
      </h3>
    )
    return (
      <div className="flex-1 lg:grid grid-cols-2 gap-3">
        <ul className="col-span-1">
          <li>

            <TextLink href={RoutePaths.TERMS}>
              {t("shared:versionWithNumber", { version: terms.version })} - {terms.filename}
            </TextLink>
          </li>
          <li>
            {t("shared:uploaded")}: <TimeDefaultFormat utcTime={terms.createdAt} />
          </li>
        </ul>
      </div>
    )
  }

  const renderTermsOfUseSection = (): JSX.Element => {
    return (
      <div>
        <h3 className="mb-2 text-lg">
          {t("shared:termsOfUse")}
        </h3>
        <DetailsCard>
          <div>
            <div className="flex">
              <IconCircleContainer className="mr-4">
                <IconPdf className="fill-current w-6 h-6" title={t("shared:termsOfUse")} />
              </IconCircleContainer>
              {renderTermDetails()}
            </div>
            <TermsFileNewForm />
          </div>

        </DetailsCard>
      </div>
    )
  }

  const renderTenantLogoPreview = (): JSX.Element => {
    if (!tenantLogoPath) return null

    return (
      <div className="w-32 h-32 p-1 flex items-center justify-center mb-2 overflow-hidden border border-ch-gray-400">
        <ImageThumb path={tenantLogoPath} title={tenantName} />
      </div>
    )
  }

  const renderTenantLogoSection = (): JSX.Element => {
    return (
      <div>
        <h3 className="mb-2 text-lg">
          {t("admin:tenantLogo")}
        </h3>
        <DetailsCard>
          <div>
            {renderTenantLogoPreview()}
            <TenantLogoUploadForm />
          </div>
        </DetailsCard>
      </div>
    )
  }

  return (
    <div>
      {renderTenantLogoSection()}
      {renderTermsOfUseSection()}
    </div>
  )
}

export default AdminPage
