import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

// See TeanNewForm for example of usage
// I've avoided to rely on FomikContext, as in that case there's just to many fields to pass along.
// Instead, the handling of setting values is done completely on the consumer side through the two handler functions.

interface Props {
  handlerA: VoidFunction
  handlerB: VoidFunction
  textAKey: string
  textBKey: string
  AActive: boolean
  translationNamespace: string
}

const ToggleField: React.FC<Props> = (props) => {
  const { t } = useTranslation([props.translationNamespace])

  return (
    <ul className="flex rounded-md">
      <li>
        <a
          href="#"
          onClick={props.handlerA}
          className={classNames("flex flex-col border-2 border-r-0 border-ch-blue-400 items-center rounded-l-md py-1 px-2 whitespace-nowrap", {
            "bg-ch-blue-400": props.AActive,
            "hover:bg-ch-blue-300": !props.AActive,
            "bg-white": !props.AActive,
          })}>
          <span>{t(props.textAKey)}</span>
        </a>
      </li>
      <li>
        <a
          href="#"
          onClick={props.handlerB}
          className={classNames("flex flex-col border-2 border-l-0 border-ch-blue-400 items-center rounded-r-md py-1 px-2 whitespace-nowrap", {
            "bg-ch-blue-400": !props.AActive,
            "hover:bg-ch-blue-300": props.AActive,
            "bg-white": props.AActive,
          })}>
          <span>{t(props.textBKey)}</span>
        </a>
      </li>
    </ul>
  )
}

export default ToggleField
