/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { addHours, addDays, format, isValid, isFuture, parse } from "date-fns"
import { zonedTimeToUtc, utcToZonedTime, format as formatWithTimezone } from "date-fns-tz"
import { enUS, enGB, enAU } from "date-fns/locale"
import { TSelectFieldOptions } from "../components/forms/StandardSelectField"
import _ from "lodash"

type MeetingDates = {
  start: Date
  end: Date
}

export const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

// format time string for date-fns eg 07:30am
export const formatTimeString = "hh:mmaaaaa'm'"

// format date string for display date-fns eg 03 Jan 2021
export const formatDateString = "dd MMM yyyy"

// format date from date picker for parsing in forms eg 01/05/2021
export const formatDatePickerString = "dd/MM/yyyy"

export const formatDatePicker = (date: Date): string => {
  return format(date, formatDatePickerString)
}

export const timeFormatValid = (time: string): boolean => {
  return isValid(parse(time, formatTimeString, new Date()))
}

export const addHour = (time: string, hours: number): string => {
  const t = parse(time, formatTimeString, new Date())
  if (isValid(t)) {
    return format(addHours(new Date(t), hours), formatTimeString)
  }
}

export const parseTime = (time: string): Date => {
  return parse(time, formatTimeString, new Date())
}

export const dateWithTime = (date: Date, time: string): Date => {
  const dateString = formatDatePicker(date)
  return parse(
    dateString + " " + time,
    `${formatDatePickerString} ${formatTimeString}`, new Date()
  )
}

export const startEndDates = (startDate: Date, startTime: string, endTime: string, timezone: string): MeetingDates => {
  const start = dateWithTime(startDate, startTime)
  const endDate = dateWithTime(startDate, endTime)
  const end =
    endDate <= start ? addDays(endDate, 1) : endDate

  return {
    start: zonedTimeToUtc(start, timezone),
    end: zonedTimeToUtc(end, timezone)
  }
}

export const valuesToDateOfBirth = (day: string, month: string, year: string): string => {
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
}

export const dateIsFuture = (date: Date, startTime = "11:59pm", timezone: string): boolean => {
  if (!date) return false

  const utcTime = zonedTimeToUtc(dateWithTime(date, startTime), timezone)
  return isFuture(utcTime)
}

export const utcDateToZonedTime = (date: string, timezone: string): Date => {
  if (isValid(new Date(date))) {
    return utcToZonedTime(date, timezone)
  }
}
export const zonedDateToUtc = (date: Date, timezone: string, time?: string): Date => {
  const dateToConvert = time ? dateWithTime(date, time) : new Date(date)
  if (isValid(dateToConvert)) {
    return zonedTimeToUtc(dateToConvert, timezone)
  }
}

// I've made some changes to the implementation of this, but I'm no 100% sure
// it's right, as timezones are hard. As far as I've tested things, it seems to
// work alright, and so the time zone isn't needed. But until this is confirmed,
// I'd rather not remove it, as we might still need it if there is an issue with it..
export const utcDateToDaysFromNow = (dateString: string, _timezone: string): number => {
  const date = new Date(dateString)
  date.setHours(23)
  date.setMinutes(59)

  if (isValid(date)) {
    const difference = date.getTime() - new Date().getTime()
    const differenceInDays = Math.floor(difference / 1000 / 60 / 60 / 24)
    return differenceInDays
  }
}

export const daysFromNowToDate = (daysFromNow): Date => {
  return addDays(new Date(), daysFromNow)
}

export const dateOnly = (utcTime: string, timezone: string): string => {
  const dateZoned = utcDateToZonedTime(utcTime, timezone)
  return format(dateZoned, formatDateString, { locale: enGB })
}

// returns translated day date eg Mon 5th - locale needs importing
// locale https://date-fns.org/v2.20.2/docs/Locale
export const formatDayDate = (date: Date): string => {
  return format(new Date(date), "E do", { locale: enGB })
}

// The timezone name eg CET for Central European Time is generated by the Intl API which works best when a nearby locale is provided.
// So to see CET you would need locale enGB. Otherwise defaults to GMT+1 format.
// To see EST you would need enUS, AEST then enAU etc. Add to this list as needed
export const localeForTime = (timezone: string): Locale => {
  if (timezone.includes("America")) {
    return enUS
  }
  else if (timezone.includes("Australia")) {
    return enAU
  }
  else {
    return enGB
  }
}

const dateFormatter = (utcTime: string, timezone: string, withoutTZ?: boolean): string => {
  const dateZoned = utcDateToZonedTime(utcTime, timezone)
  const format = `${formatDateString} ${formatTimeString}${withoutTZ ? "" : " zzz"}`
  return formatWithTimezone(dateZoned, format, {
    locale: localeForTime(timezone),
    timeZone: timezone
  })
}

export const dateWithTimezone = (utcTime: string, timezone: string) => {
  return dateFormatter(utcTime, timezone)
}

export const dateWithoutTimezone = (utcTime: string, timezone: string) => {
  return dateFormatter(utcTime, timezone, true)
}

export const getDayOptions = (): TSelectFieldOptions => {
  return _.range(1, 32).map(day => {
    return {
      label: day.toString(),
      value: day.toString()
    }
  })
}

// Should be type TFunction<string[]>
// CI does not think this type is exported from from "react-i18next"
export const getMonthOptions = (t: any): TSelectFieldOptions => {
  return _.range(1, 13)
    .map(month => {
      return {
        label: t(`shared:month${month}`),
        value: month.toString()
      }
    })
}

export const getYearOptions = (): TSelectFieldOptions => {
  const now = new Date()
  return _.range(now.getFullYear() - 121, now.getFullYear() + 1)
    .reverse()
    .map(year => {
      return {
        label: year.toString(),
        value: year.toString()
      }
    })
}
