import React from "react"
import Modal from "react-modal"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { getChatById } from "../../../../shared/selectors/message"
import { getTeamById } from "../../../../shared/selectors/team"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import { IconTeam } from "../../../../svg/icons"
import { ITeam } from "../../../team/state/teamTypes"
import TeamDetails from "../../../teams/components/details/TeamDetails"
import UserListItem from "../../../users/components/list/UserListItem"
import { ChatTypes, IChat } from "../../state/chatTypes"

interface IPropsFromParent {
  closeModal: () => void
  isOpen: boolean
  chatId: string
}

type Props = IPropsFromParent

const ChatMembersModal: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const team: ITeam = useSelector((state: IAppState) => getTeamById(state, chat.team))

  const renderTeamHeader = (): JSX.Element => {
    if (chat.type != ChatTypes.TEAM || !team) return null
    return (
      <DetailsCard className="mb-6">
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconTeam className="fill-current w-6 h-6" />
          </IconCircleContainer>
          <TeamDetails teamId={team.id} userId={team.owner} />
        </DetailsCardInner>
      </DetailsCard>
    )
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        {renderTeamHeader()}

        <h2 className="font-semibold mb-2">Members</h2>

        <ul>
          {chat.users.map((userId: string) => (
            <UserListItem
              key={userId}
              userId={userId}
              hideButtons
            />
          ))}
        </ul>
      </ModalContainer>
    </Modal >
  )
}

export default ChatMembersModal
