import {
  AccountActionTypes,
  IAccountSetPasswordAsyncAction,
  ISetPasswordData,
  IAccountSendResetPasswordLinkAsyncAction,
  IAccountSignInTypeAsyncAction,
  IAccountSignInUserAsyncAction,
  IAccountSignOutUserAsyncAction,
  IFetchTermsAsyncAction,
  IAcceptTermsAsyncAction,
  IAcceptTermsData
} from "./accountTypes"

export const setPasswordAction = (setPasswordData: ISetPasswordData): IAccountSetPasswordAsyncAction => {
  return {
    type: AccountActionTypes.SET_PASSWORD_ASYNC_PENDING,
    payload: setPasswordData
  }
}

export const sendResetPasswordLinkAction = (email: string): IAccountSendResetPasswordLinkAsyncAction => {
  return {
    type: AccountActionTypes.SEND_RESET_PASSWORD_LINK_ASYNC_PENDING,
    payload: {
      email: email
    }
  }
}

export const signInUserAction = (email: string, password: string): IAccountSignInUserAsyncAction => {
  return {
    type: AccountActionTypes.SIGN_IN_USER_ASYNC_PENDING,
    payload: {
      email: email,
      password: password
    }
  }
}

export const signOutUserAction = (): IAccountSignOutUserAsyncAction => {
  return {
    type: AccountActionTypes.SIGN_OUT_USER_ASYNC_PENDING
  }
}

export const userSignInTypeAction = (email: string): IAccountSignInTypeAsyncAction => {
  return {
    type: AccountActionTypes.USER_SIGN_IN_TYPE_ASYNC_PENDING,
    payload: email
  }
}

export const fetchTermsAction = (): IFetchTermsAsyncAction => {
  return {
    type: AccountActionTypes.FETCH_TERMS_ASYNC_PENDING
  }
}

export const acceptTermsAction = (acceptTermsData: IAcceptTermsData): IAcceptTermsAsyncAction => {
  return {
    type: AccountActionTypes.ACCEPT_TERMS_ASYNC_PENDING,
    payload: acceptTermsData
  }
}
