import { IBaseAction } from "../../../shared/types/baseActionTypes"

export interface IHaveTeamId {
  teamId: string
}

export interface ITeamPermissions {
  canDelete: boolean
  canEdit: boolean
  canInvite: boolean
  canUninvite: boolean
}

export enum TeamTypes {
  mdt = "mdt",
  basic = "basic"
}

export interface ITeam {
  specialtyName: string
  updatedAt: string
  id: string
  name: string
  users: string[]
  teamUsers: string[]
  permissions: ITeamPermissions,
  specialtyId: string
  owner: string
  activeInCurrentTenant?: boolean
  teamType: TeamTypes
}

export interface IMdtTeamUserData {
  id: string
  userId: string
  role: string
}

export interface ITeamNewData {
  teamType: string
  name: string
  specialtyId: string
  ownerId: string
  userIds?: string[]
  teamIds?: string[]
  teamUsers: string[] | IMdtTeamUserData[]
}

export interface ITeamUpdateData {
  name: string
  specialtyId: string
  ownerId: string
}

export interface ITeamUsersUpdateData {
  teamUsers: IMdtTeamUserData[]
}

// Actions
export interface IFetchTeamAsyncAction extends IBaseAction {
  payload: string
}

export interface ICreateTeamAction extends IBaseAction {
  payload: ITeamNewData
}

export interface IHaveTeamIdParam {
  match?: {
    params: {
      teamId: string
    }
  }
}

export interface IUpdateTeamAction extends IBaseAction {
  payload: {
    id: string,
    team: ITeamNewData
  }
}

export interface IUpdateTeamUsersAction extends IBaseAction {
  payload: {
    id: string,
    data: ITeamUsersUpdateData
  }
}


export interface ITeamDeleteAction extends IBaseAction {
  payload: string
}

// Action Types
export enum TeamActionTypes {
  FETCH_TEAM_ASYNC_PENDING = "@@team/FETCH_TEAM_ASYNC_PENDING",
  FETCH_TEAM_ASYNC_SUCCESS = "@@team/FETCH_TEAM_ASYNC_SUCCESS",
  FETCH_TEAM_ASYNC_ERROR = "@@team/FETCH_TEAM_ASYNC_ERROR",

  CREATE_TEAM_ASYNC_PENDING = "@@team/CREATE_TEAM_ASYNC_PENDING",
  CREATE_TEAM_ASYNC_SUCCESS = "@@team/CREATE_TEAM_ASYNC_SUCCESS",
  CREATE_TEAM_ASYNC_ERROR = "@@team/CREATE_TEAM_ASYNC_ERROR",

  UPDATE_TEAM_ASYNC_PENDING = "@@team/UPDATE_TEAM_ASYNC_PENDING",
  UPDATE_TEAM_ASYNC_SUCCESS = "@@team/UPDATE_TEAM_ASYNC_SUCCESS",
  UPDATE_TEAM_ASYNC_ERROR = "@@team/UPDATE_TEAM_ASYNC_ERROR",

  UPDATE_TEAM_USERS_ASYNC_PENDING = "@@team/UPDATE_TEAM_USERS_ASYNC_PENDING",
  UPDATE_TEAM_USERS_ASYNC_SUCCESS = "@@team/UPDATE_TEAM_USERS_ASYNC_SUCCESS",
  UPDATE_TEAM_USERS_ASYNC_ERROR = "@@team/UPDATE_TEAM_USERS_ASYNC_ERROR",

  DELETE_TEAM_ASYNC_PENDING = "@@team/DELETE_TEAM_ASYNC_PENDING",
  DELETE_TEAM_ASYNC_SUCCESS = "@@team/DELETE_TEAM_ASYNC_SUCCESS",
  DELETE_TEAM_ASYNC_ERROR = "@@team/DELETE_TEAM_ASYNC_ERROR"
}
