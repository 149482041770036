import classNames from "classnames"
import React, { useContext, useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../app/appTypes"
import { getMessageById } from "../../../shared/selectors/message"
import { getChatMessagesUI } from "../../../shared/selectors/ui"
import { IconClose, IconMessage } from "../../../svg/icons"
import { updateNotificationMessageIdAction } from "../../messages/state/messagesActions"
import { IMessage } from "../state/messageTypes"
import { getCurrentUser } from "../../../shared/selectors/user"
import NameUser from "../../../shared/components/names/NameUser"
import { useLocation } from "react-router"
import { RoutePaths } from "../../../app/routes/Routes"
import history from "../../../app/routes/history"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"

const MessageNotification: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const {
    messagingNotificationsEnabled
  } = useContext(TenantConfigContext)
  const currentUser = useSelector(getCurrentUser)
  const notificationMessageId: string = useSelector((state: IAppState) => getChatMessagesUI(state))?.notificationMessageId
  const message: IMessage = useSelector((state: IAppState) => getMessageById(state, {messageId: notificationMessageId}))
  const dispatch = useDispatch()
  const updateNotificationMessageId = (messageId: string) => dispatch(updateNotificationMessageIdAction(messageId))
  const [isVisible, setIsVisible] = useState(false)
  const location = useLocation()

  const getExpiryTimestamp = (): Date => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + 7)
    return time
  }
  const {
    seconds,
    start,
    pause,
    restart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: getExpiryTimestamp()
  })

  useEffect(() => {
    if(
      messagingNotificationsEnabled &&
      message != null &&
      message.createdBy.id != currentUser.id &&
      location.pathname != RoutePaths.MESSAGES
    ) openMessage()
  }, [message?.id])

  useEffect(() => {
    if(seconds == 0) closeMessage()
  }, [seconds])

  const openMessage = () => {
    setIsVisible(true)
    restartTimer()
    start()
  }

  const closeMessage = () => {
    setIsVisible(false)
    setTimeout(() => {
      updateNotificationMessageId(null)
    }, 500)
  }

  const onClickNotification = () => {
    closeMessage()
    history.push(RoutePaths.MESSAGES)
  }

  const restartTimer = () => {
    restart(getExpiryTimestamp())
  }

  return (
    <div
      className={classNames(
        "absolute",
        "top-20",
        "shadow",
        "z-50",
        "border-l-4",
        "border-ch-purple-500",
        "transition-all",
        "duration-500",
        "ease-in-out",
        {
          "opacity-0": !isVisible,
          "opacity-100": isVisible,
          "-right-64": !isVisible,
          "right-5": isVisible,
        }
      )}
      onMouseEnter={pause}
      onMouseLeave={restartTimer}
    >
      <div className="w-full h-full p-4 border bg-ch-gray-200 border-r-ch-gray-400 relative">
        <a onClick={closeMessage} className="text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer absolute right-4 top-4">
          <IconClose className="fill-current w-4 h-4" title={t("shared:close")} />
        </a>
        <div className="cursor-pointer" onClick={onClickNotification}>
          <div className="flex pr-8">
            <div className="flex items-center mb-2">
              <IconMessage className="fill-current w-5 h-5 mr-2 text-ch-purple-500" title={t("message:shared:buttons:openChat")} />
              <h3 className="text-sm font-semibold truncate">
                <NameUser
                  firstName={message?.createdBy.firstName}
                  lastName={message?.createdBy.lastName}
                  professionalTitle={message?.createdBy.professionalTitle}
                />
              </h3>
            </div>
          </div>
          <div className="truncate max-h-24 max-w-sm text-sm text-ch-gray-500">
            {message?.body}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageNotification
