import React from "react"
import { useTranslation } from "react-i18next"

interface IProps{
  isPending: boolean
}

export const PendingLabel: React.FC<IProps> = (props: IProps): JSX.Element  => {
  const { t } = useTranslation(["user"])
  if(!props.isPending) return null
  return (
    <span className="text-sm text-white px-2 rounded bg-ch-orange-500 ml-1 uppercase">
      {t("user:pending")}
    </span>
  )
}
