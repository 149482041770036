import React from "react"
import { useTranslation } from "react-i18next"
import { IUser } from "../../../user/state/userTypes"
import { MockStore } from "redux-mock-store"

interface IPropsFromParent {
  store?: MockStore
  presenter: IUser
}

type IProps = IPropsFromParent

const LiveMeetingSharedViewBar: React.FC<IProps> = ({
  presenter,
}: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])

  const presentingUser = () => {
    return presenter ? (
      <span className="text-sm font-thin">
        &nbsp;|&nbsp;{t("liveMeeting:presenting")} {presenter.firstName}{" "}
        {presenter.lastName}{" "}
      </span>
    ) : null
  }
  return (
    <h2 className="bg-ch-blue-200 p-2">
      {t("liveMeeting:sharedView")}
      {presentingUser()}
    </h2>
  )
}

export default LiveMeetingSharedViewBar
