import React, { MutableRefObject, useRef, useState } from "react"
import { FormikProps, FormikValues } from "formik"

export interface ContextProps {
  formikFormRef: MutableRefObject<FormikProps<FormikValues>>
  forceUpdate: () => void
}

export const FormContext = React.createContext<Partial<ContextProps>>({})

FormContext.displayName = "FormContext"

interface Props {
  children?: JSX.Element
}

export const FormContextProvider: React.FC<Props> = (props: Props) => {
  // Note, can't add specific TS form values to useRef here because the form will change from page to page.
  const formikFormRef = useRef<FormikProps<FormikValues>>(null)
  const [refresher, setRefresher] = useState<number>(0)

  const store: ContextProps = {
    formikFormRef,
    // workaround to allow components to observe the ref changes like formikFormRef.current.dirty
    forceUpdate: () => setRefresher(refresher + 1),
  }

  return <FormContext.Provider value={store}>
    {props.children}
  </FormContext.Provider>
}
