import API from "../../../apis/contextmeeting/api"
import { fetchMeetingsSchema } from "../../../apis/contextmeeting/schema"
import { MeetingsActionTypes } from "./meetingsActionTypes"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { normalize } from "normalizr"
import { call, Effect, put, takeLeading, takeEvery } from "redux-saga/effects"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import queryString from "query-string"
import humps from "humps"

export function* fetchMeetingsAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/meetings?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [fetchMeetingsSchema])

    yield put({
      type: MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        appliedFilters: response.data.searchQuery.appliedFilters,
        orderBy: response.data.searchQuery.orderBy,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        searchTerm: response.data.searchQuery.searchTerm
      }
    })
  } catch (error) {
    yield put({
      type: MeetingsActionTypes.FETCH_MEETINGS_ASYNC_ERROR,
      payload: error
    })
    yield put(displayUserMessageAction({
      messageKey: "meetingsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchMeetingsAsync(): Generator {
  yield takeLeading(MeetingsActionTypes.FETCH_MEETINGS_ASYNC_PENDING, fetchMeetingsAsync)
}

export function* fetchMeetingsInDateRangeAsync(action: Effect): Generator {
  try {
    const { start, end } = action.payload
    const response: any = yield call(API.get, `/meetings/in_range/${start}/${end}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [fetchMeetingsSchema])

    yield put({
      type: MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
      }
    })
  } catch (error) {
    yield put({
      type: MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_ERROR,
      payload: error
    })
    yield put(displayUserMessageAction({
      messageKey: "meetingsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchMeetingsInDateRangeAsync(): Generator {
  yield takeEvery(MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_PENDING, fetchMeetingsInDateRangeAsync)
}
