import { UserActionTypes, IUserCreateUserAsyncAction, IUpdateUserAsyncAction, IFetchUserAsyncAction, IUserNewData, IUserEditData, ISetTenantAction, IUserCheckData, ICheckUserAction, IUserAddToTenantAction } from "./userTypes"
import { IBaseAction } from "../../../shared/types/baseActionTypes"

export const createUserAction = (user: IUserNewData): IUserCreateUserAsyncAction => {
  return {
    type: UserActionTypes.CREATE_USER_ASYNC_PENDING,
    payload: user
  }
}

export const addUserToCurrentTenantAction = (userId: string): IUserAddToTenantAction => {
  return {
    type: UserActionTypes.ADD_USER_TO_TENANT_ASYNC_PENDING,
    payload: { id: userId }
  }
}

export const fetchCurrentUserAction = (): IBaseAction => {
  return {
    type: UserActionTypes.FETCH_CURRENT_USER_ASYNC_PENDING
  }
}

export const fetchUserAction = (userId: string): IFetchUserAsyncAction => {
  return {
    type: UserActionTypes.FETCH_USER_ASYNC_PENDING,
    payload: userId
  }
}

export const updateUserAction = (userId: string, userData: IUserEditData): IUpdateUserAsyncAction => {
  return {
    type: UserActionTypes.UPDATE_USER_ASYNC_PENDING,
    payload: {
      id: userId,
      user: userData
    }
  }
}

export const setTenantAction = (tenantId: string, path?: string): ISetTenantAction => {
  return {
    type: UserActionTypes.SET_TENANT_ASYNC_PENDING,
    payload: {
      id: tenantId,
      path
    }
  }
}

export const checkUserAction = (userCheckData: IUserCheckData): ICheckUserAction => {
  return {
    type: UserActionTypes.CHECK_USER_ASYNC_PENDING,
    payload: {
      userCheckData: userCheckData
    }
  }
}

export const resetCheckUserAction = (): IBaseAction => {
  return {
    type: UserActionTypes.CHECK_USER_ASYNC_RESET
  }
}

export const fetchExternalAccountsAction = (): IBaseAction => {
  return {
    type: UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_PENDING
  }
}
