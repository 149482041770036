import { Form, Formik } from "formik"
import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import Button from "../../../../../shared/components/buttons/Button"
import FieldError from "../../../../../shared/components/forms/FieldError"
import Label from "../../../../../shared/components/forms/Label"
import SelectField from "../../../../../shared/components/forms/SelectField"
import { TSelectFieldOptions } from "../../../../../shared/components/forms/StandardSelectField"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../../../shared/components/label/UserOption"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { isGuest } from "../../../../../shared/helpers/userHelpers"
import { getCaseById } from "../../../../../shared/selectors/case"
import { getAllActiveUsersSortedFirstNameLastName } from "../../../../../shared/selectors/user"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IGuestUserInviteExistingFormData, IUser } from "../../../../user/state/userTypes"
import { fetchAllUsersAsync } from "../../../../users/state/usersActions"
import { updateCaseMembers } from "../../../state/caseActions"
import { ICase, ICaseUpdateData } from "../../../state/caseTypes"
import { caseGuestUserInviteExistingValidation } from "../../../state/validation/caseGuestUserInviteExistingValidation"

interface Props {
  caseId: string
  closeModal: () => void
}

const InviteExistingGuestUsersForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const dispatch = useDispatch()
  const users = useSelector((state: IAppState): IUser[] => getAllActiveUsersSortedFirstNameLastName(state))
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, { caseId: props.caseId }))
  const [userOptions, setUserOptions] = React.useState([])

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
  }, [])

  useEffect(() => {
    const availableGuestUsers = users.filter((user) => {
      return isGuest(user) && !theCase.members.includes(user.id)
    })
    const options: TSelectFieldOptions = buildUserOptions(Object.values(availableGuestUsers))
    setUserOptions(options)
  }, [users])

  const initialValues: IGuestUserInviteExistingFormData = {
    guestUserIds: []
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={caseGuestUserInviteExistingValidation}
      onSubmit={async (values, { setSubmitting }) => {
        const data: ICaseUpdateData = {
          id: theCase.id,
          memberIds: [...theCase.members, ...values.guestUserIds]
        }
        dispatch(updateCaseMembers(data))
        setSubmitting(false)
        props.closeModal()
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => {
        return (
          <Form>
            <div className="mb-4">
              <Label name="guestUserIds">
                {t("case:guestUsers:labels:chooseExistingGuestUsers")}
              </Label>
              <SelectField
                name="guestUserIds"
                options={userOptions}
                isMulti={true}
                closeMenuOnSelect={false}
                onChange={handleChange}
                onBlur={handleBlur}
                filterOption={filterOptionsBySearchValue}
              />
              <FieldError errorMessage={errors.guestUserIds as string} isVisible={(errors.guestUserIds && touched.guestUserIds) as boolean} />
            </div>

            <Buttons
              className="mb-2"
              buttons={[
                <Button
                  action={() => { props.closeModal() }}
                  variant={StyleVariants.BLUE_ALT}
                  key="1"
                >
                  {t("shared:cancel")}
                </Button>,
                <Button
                  isDisabled={isSubmitting}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                  key={2}
                >
                  {t("case:guestUsers:buttons:InviteGuestUsers")}
                </Button>
              ]}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default InviteExistingGuestUsersForm
