import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { toggleSplitScreenAction, changeYourViewTabAction } from "../../state/liveMeetingActions"
import Switch from "../../../../shared/components/forms/Switch"
import { IconUsers, IconSplitscreen } from "../../../../svg/icons"
import { YourViewTabs } from "../../state/liveMeetingTypes"
import { getIsSplitScreen, getSelectedYourViewTab } from "../../../../shared/selectors/ui"


const LiveMeetingViewControlsContainer: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const dispatch = useDispatch()
  const isSplitScreen = useSelector(getIsSplitScreen)
  const selectedYourViewTab = useSelector(getSelectedYourViewTab)
  const onToggleSplitScreen = (isSplitScreen: boolean) => dispatch(toggleSplitScreenAction(isSplitScreen))
  const onChangeYourViewTab = (selectedTab: number) => dispatch(changeYourViewTabAction(selectedTab))

  const renderYourViewTab = (): JSX.Element => {
    const isSelected = (selectedYourViewTab == YourViewTabs.YOUR_VIEW)
    return (
      <a
        href="#"
        onClick={() => onChangeYourViewTab(YourViewTabs.YOUR_VIEW)}
        className={classNames("flex", "flex-col", "items-center", "rounded-l-md", "py-1", "px-2", "whitespace-nowrap", {
          "bg-ch-blue-400": !isSelected,
          "hover:bg-ch-blue-300": !isSelected,
          "bg-white": isSelected,
        })}>
        <IconSplitscreen
          className={classNames("fill-current", "w-6", "h-6", {
            "text-white": !isSelected,
            "text-ch-blue-500": isSelected
          })}
          title={t("liveMeeting:yourView")}
        />
        <span>{t("liveMeeting:yourView")}</span>
      </a>
    )
  }

  const renderUsersViewTab = (): JSX.Element => {
    const isSelected = (selectedYourViewTab == YourViewTabs.USERS_VIEW)
    return (
      <a
        href="#"
        onClick={() => onChangeYourViewTab(YourViewTabs.USERS_VIEW)}
        className={classNames("flex", "flex-col", "items-center", "rounded-r-md", "py-1", "px-2", "whitespace-nowrap", {
          "bg-ch-blue-400": !isSelected,
          "hover:bg-ch-blue-300": !isSelected,
          "bg-white": isSelected,
        })}>
        <IconUsers
          className={classNames("fill-current", "w-6", "h-6", {
            "text-white": !isSelected,
            "text-ch-blue-500": isSelected
          })}
          title={t("liveMeeting:users")}
        />
        <span>{t("liveMeeting:users")}</span>
      </a>
    )
  }

  return (
    <div className="flex justify-between">
      <div className="items-center text-center">
        <Switch name="isSplitScreen" action={() => onToggleSplitScreen(!isSplitScreen)} value={isSplitScreen} />
        {t("liveMeeting:splitScreen")}
      </div>
      <div className="border-r-2 border-white border-dashed mx-2"></div>
      <ul className="flex">
        <li className="mr-px">
          {renderYourViewTab()}
        </li>
        <li>
          {renderUsersViewTab()}
        </li>
      </ul>
    </div>
  )
}

export default LiveMeetingViewControlsContainer
