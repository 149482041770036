import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react"
import { useFormikContext } from "formik"
import SelectField from "../../../shared/components/forms/SelectField"
import { TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import { IMdtTeamUserData } from "../../team/state/teamTypes"

interface ITeamUserSelectProps {
  handleChange: (e: ChangeEvent) => void
  handleBlur: (e: FocusEvent) => void
  userOptions: TSelectFieldUserOptions
  index: number
  teamUser: IMdtTeamUserData
}

const TeamUserSelectField: React.FC<ITeamUserSelectProps> = (props) => {
  const { values }: { values: { teamUsers: { userId: string }[] } } = useFormikContext()
  const [teamUserOptions, setTeamUserOptions] = useState(props.userOptions)

  useEffect(() => {
    const selectedIds = values.teamUsers.map((m) => m.userId)
    const currentValue = values.teamUsers[props.index].userId

    const newTeamUserOptions = props.userOptions.filter(
      (option) => (option.value == currentValue || !selectedIds.includes(option.value))
    )
    setTeamUserOptions(newTeamUserOptions)
  }, [values.teamUsers])

  return (
    <SelectField
      name={`teamUsers.${props.index}.userId`}
      options={teamUserOptions}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      filterOption={filterOptionsBySearchValue}
    />
  )
}

export default TeamUserSelectField
