import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconPathway } from "../../../svg/icons"
import DetailsCardRight from "../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../shared/components/layout/detailsCard/DetailsCardButtons"
import Button from "../../../shared/components/buttons/Button"
import { useSelector, useDispatch } from "react-redux"
import { getPathway } from "../../../shared/selectors/pathway"
import Card from "../../../shared/components/layout/Card"
import PathwayDetails from "./details/PathwayDetails"
import { StyleVariants, defaultModalStyle } from "../../../shared/state/sharedTypes"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import { Formik, Form } from "formik"
import { pathwayNewValidationSchema } from "../../pathwayNew/components/pathwayValidationSchema"
import { updatePathwayAction } from "../pathwayActions"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import SelectField from "../../../shared/components/forms/SelectField"
import { getAllCaseClassifications } from "../../../shared/selectors/caseClassification"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import { generateCaseClassificationOptions } from "../../../shared/helpers/selectOptionHelpers"
import { CaseTypes } from "../../case/state/caseTypes"
import humps from "humps"
import FieldError from "../../../shared/components/forms/FieldError"
import { fetchCaseClassificationAction } from "../../caseClassification/state/caseClassificationActions"
import Buttons from "../../../shared/components/layout/Buttons"
import DeletePathwayButton from "./DeletePathwayButton"

type IProps = {
  pathwayId: string
  hideButtons?: boolean
}

const PathwayHeader: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway = useSelector(getPathway(props))
  const dispatch = useDispatch()

  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false)
  const openEditModal = () => { setEditModalIsOpen(true) }
  const closeEditModal = () => { setEditModalIsOpen(false) }

  const [caseClassificationOptions, setCaseClassificationOptions] = useState([])
  const classifications = useSelector(getAllCaseClassifications)
  const [caseType, setCaseType] = useState<string>(pathway.caseType)

  const renderEditButtons = () => {
    if (!pathway.permissions?.canEdit || props.hideButtons) { return null }
    return (
      <DetailsCardRight isStretched={true}>
        <DetailsCardButtons buttons={[
          <DeletePathwayButton key="1" pathwayId={props.pathwayId} />,
          <Button key="2" variant={StyleVariants.PURPLE} action={openEditModal} >{t("pathway:buttons:editPathway")}</Button>
        ]} />
      </DetailsCardRight>
    )
  }

  useEffect(() => {
    dispatch(fetchCaseClassificationAction())
  }, [])

  useEffect(() => {
    if (!caseType) return
    setCaseClassificationOptionsForCaseType(caseType)
  }, [classifications])

  const setCaseClassificationOptionsForCaseType = (caseType: string) => {
    const options: TSelectFieldOptions = generateCaseClassificationOptions(classifications, caseType)
    setCaseClassificationOptions(options)
  }

  const onChangeCaseType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentCaseType = e.currentTarget.value
    setCaseType(currentCaseType)
    setCaseClassificationOptionsForCaseType(currentCaseType)
  }

  const caseTypeOptions: TSelectFieldOptions = Object.keys(CaseTypes)
    .filter((key) => key != "SuspectedCancer")
    .map((key) => {
      const translationKey = humps.camelize(CaseTypes[key])
      return {
        value: CaseTypes[key],
        label: t(`case:${translationKey}`)
      }
    })

  return (
    <div>
      <Card>
        <DetailsCard hasBorder={false}>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-4">
              <IconPathway className="fill-current w-6 h-6" title={t("shared:case")} />
            </IconCircleContainer>
            <PathwayDetails pathwayId={props.pathwayId} />
          </DetailsCardInner>
          {renderEditButtons()}
        </DetailsCard>
        <div className="mt-4 p-4 bg-ch-blue-100 rounded-md">
          <div><b>{t("pathway:summary")}:</b></div>
          <div>{pathway.description || t("pathway:noSummary")}</div>
        </div>
      </Card>

      <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeEditModal}>
          <h2 className="text-lg mb-3">
            {t("pathway:edit:editPathway")}
          </h2>

          <Formik
            initialValues={pathway}
            validationSchema={pathwayNewValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const data = pathwayNewValidationSchema().cast(values)
              dispatch(updatePathwayAction(data))
              setSubmitting(true)
              closeEditModal()
            }}
          >
            {({
              isSubmitting,
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isValid,
              dirty
            }) => {
              return (
                <Form className="w-full lg:w-2/3">
                  <DenotesRequiredMessage />
                  <div className="mb-2">
                    <Label name="name" required>
                      {t("pathway:name")}
                    </Label>
                    <InputField
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      autoComplete="off"
                      autoFocus={true}
                    />
                    <FieldError
                      errorMessage={errors.name as string}
                      isVisible={(errors.name && touched.name) as boolean}
                    />
                  </div>


                  <div className="md:flex">
                    <div className="mb-2 md:w-1/3">
                      <Label name="caseType" required>
                        {t("pathway:caseType")}
                      </Label>

                      <SelectField
                        name="caseType"
                        onChange={onChangeCaseType}
                        onBlur={handleBlur}
                        options={caseTypeOptions}
                      />

                      <FieldError
                        errorMessage={errors.caseType}
                        isVisible={errors.caseType && touched.caseType}
                      />
                    </div>

                    <div className="flex-1 mb-2 md:ml-2">
                      <Label name="caseClassificationId" required>
                        {t("shared:classification")}
                      </Label>

                      <SelectField
                        name="caseClassificationId"
                        options={caseClassificationOptions}
                        isDisabled={!values.caseType}
                        isGrouped={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <FieldError
                        errorMessage={errors.caseClassificationId as string}
                        isVisible={
                          errors.caseClassificationId &&
                          touched.caseClassificationId
                        }
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <Label name="description" required>
                      {t("pathway:summary")}
                    </Label>
                    <InputField
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      autoComplete="off"
                      as="textarea"
                      className={"h-32"}
                    />
                    <FieldError
                      errorMessage={errors.description as string}
                      isVisible={(errors.description && touched.description) as boolean}
                    />
                  </div>

                  <div className="flex justify-center">
                    <Buttons buttons={[
                      <Button
                        key="1"
                        isDisabled={isSubmitting || !isValid}
                        variant={StyleVariants.BLUE}
                        action={closeEditModal}
                      >
                        {t("shared:cancel")}
                      </Button>,
                      <Button
                        key="2"
                        isDisabled={!dirty || isSubmitting || !isValid}
                        variant={StyleVariants.PURPLE}
                        isSubmit={true}
                      >
                        {t("pathway:edit:updatePathway")}
                      </Button>
                    ]} />
                  </div>
                </Form>
              )
            }}
          </Formik>

        </ModalContainer>
      </Modal>
    </div>
  )
}

export default PathwayHeader
