import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import humps from "humps"
import { serialize } from "object-to-formdata"
import { fetchMessagesSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { ChatMessageActionTypes, ICreateChatMessageAsyncAction } from "../messageTypes"

export function* createChatMessageAsync(action: ICreateChatMessageAsyncAction): Generator {
  try {
    const chatId = action.payload.chatId
    const data = {
      message: action.payload.message
    }

    const decamelizedData = humps.decamelizeKeys(data)
    const formData = serialize(decamelizedData, { indices: true })

    const filesData: File[] = data.message.assetsAttributes?.map((a) => a.file)
    if (filesData) {
      filesData.forEach((fileData: File, index: number) => {
        formData.append(`message[assets_attributes][${index}][asset_attributes][file]`, fileData)
      })
    }

    const config = withAuthHeader()
    config.headers["Content-Type"] = "multipart/form-data"

    const response: any = yield call(API.post, `/chats/${chatId}/messages`, formData, config)
    const normalizedResponse = normalize(response.data, fetchMessagesSchema)
    yield put({
      type: ChatMessageActionTypes.CREATE_CHAT_MESSAGE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        chatId: chatId
      }
    })
  } catch (error) {
    yield put({
      type: ChatMessageActionTypes.CREATE_CHAT_MESSAGE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createMessageFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateChatMessageAsync(): Generator {
  yield takeLeading(ChatMessageActionTypes.CREATE_CHAT_MESSAGE_ASYNC_PENDING, createChatMessageAsync)
}
