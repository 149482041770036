import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, IUpdatePathwayStepAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* updatePathwayStepAsync(action: IUpdatePathwayStepAction): Generator {
  try {
    const pathwayId = action.payload.pathwayId
    const stepId = action.payload.values.id
    const data = action.payload.values

    const response: any = yield call(API.put, `/pathways/${pathwayId}/pathway_steps/${stepId}`, data, withAuthHeader())
    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({
      type: PathwayActionTypes.UPDATE_PATHWAY_STEP_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "updatePathwayStepSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: PathwayActionTypes.UPDATE_PATHWAY_STEP_ASYNC_ERROR,
    })

    yield put(displayUserMessageAction({
      messageKey: "updatePathwayStepFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdatePathwayStepAsync(): Generator {
  yield takeLeading(PathwayActionTypes.UPDATE_PATHWAY_STEP_ASYNC_PENDING, updatePathwayStepAsync)
}
