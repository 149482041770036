import React from "react"
import Button from "../../../shared/components/buttons/Button"
import { IUser } from "../../user/state/userTypes"
import { useDispatch } from "react-redux"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"

interface IPropsFromParent {
  user: IUser
}

const ClearMfaButton: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["user"])
  const dispatch = useDispatch()
  const clearMfa = () => {
    API.post(`/users/${props.user.id}/clear_mfa`, props.user, withAuthHeader()).then(() => {
      dispatch(displayUserMessageAction({
        messageKey: "clearMfaSuccess",
        type: UserMessageTypes.SUCCESS
      })
      )
    }).catch(() => {
      dispatch(displayUserMessageAction({
        messageKey: "clearMfaFail",
        type: UserMessageTypes.ERROR
      }))
    })
  }

  return (
    <Button variant={StyleVariants.ORANGE} action={() => clearMfa()}>
      {t("user:admin:buttons:clearMfa")}
    </Button>
  )
}

export default ClearMfaButton
