import { IUploadTenantLogoAsyncAction, IUploadTenantLogoFormValues, IUploadTermsFileFormValues } from "./adminTypes"
import { IUploadTermsFileAsyncAction } from "./adminTypes"
import { AdminActionTypes } from "./adminTypes"

export const uploadTermsFileAsync = (values: IUploadTermsFileFormValues): IUploadTermsFileAsyncAction => {
  return {
    type: AdminActionTypes.UPLOAD_TERMS_FILE_ASYNC_PENDING,
    payload: {
      values
    }
  }
}

export const uploadTenantLogoAsync = (values: IUploadTenantLogoFormValues): IUploadTenantLogoAsyncAction => {
  return {
    type: AdminActionTypes.UPLOAD_TENANT_LOGO_ASYNC_PENDING,
    payload: {
      values
    }
  }
}
