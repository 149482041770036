import React, { useContext, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { setTenantAction } from "../../../user/state/userActions"
import { IExternalUser } from "../../../externalUser/state/externalUserTypes"
import _ from "lodash"
import SwitchTenantListGroup from "./SwitchTenantListGroup"
import IconSwitchTenant from "../../../../svg/icons/IconSwitchTenant"
import { ExternalUsersContext } from "../../../../shared/contexts/ExternalUsersContextProvider"

interface IPropsFromParent {
  closeModal: () => void,
  isVisible: boolean
}

type Props = IPropsFromParent

const SwitchTenantList: React.FC<Props> = (props: Props): JSX.Element => {
  if (!props.isVisible) return null

  const { t } = useTranslation(["shared", "tenant"])
  const dispatch = useDispatch()
  const {
    fetchExternalUsers,
    groupedExternalUsers
  } = useContext(ExternalUsersContext)

  const setTenant = (id: string) => {
    dispatch(setTenantAction(id))
    props.closeModal()
  }

  useEffect(() => {
    fetchExternalUsers()
  }, [])

  const renderTenantListGroup = (email: string) => {
    const externalUsersGroup: IExternalUser[] = groupedExternalUsers[email]

    return (
      externalUsersGroup.map((externalUser: IExternalUser, index: number) => (
        <SwitchTenantListGroup
          key={`externalUser.id-${index}`}
          externalUser={externalUser}
          setTenant={setTenant}
        />
      ))
    )
  }

  return (
    <div>
      <h4 className="flex flex-row items-center justify-center mb-2 font-semibold">
        <span>
          <IconSwitchTenant className="w-6 h-6 mr-3" />
        </span>
        <span>{t("tenant:switchTenant")}</span>
      </h4>

      <div>
        {Object.keys(groupedExternalUsers).map((email: string) => (
          <div key={email}>
            <div className="text-ch-blue-500 mb-2">
              {email}
            </div>
            <div className="mb-4">
              {renderTenantListGroup(email)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SwitchTenantList
