import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getAllCaseAssetIds } from "../../../../shared/selectors/asset"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import CaseAssetListItem from "./CaseAssetListItem"
import { IUser } from "../../../user/state/userTypes"
import AssetTypeFilter from "./AssetTypeFilter"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { deleteAssetAction } from "../../../assets/state/assetsActions"

interface IProps {
  caseId: string
  meetingId?: string
  mentionableUsers: { [id: string]: IUser }
  openAssetModal: (assetId: string) => void
  isSelectingAssets?: boolean
  meetingCaseId?: string
}

const CaseAssetList: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const [assetTypeFilters, setAssetTypeFilters] = useState<IAssetTypeFilters>(null)
  const assetIds = useSelector((state: IAppState) => getAllCaseAssetIds(state, props))
  const dispatch = useDispatch()
  const deleteAsset = (caseId: string, assetId: string) => dispatch(deleteAssetAction(caseId, assetId))

  const deleteTheAsset = (assetId: string) => {
    deleteAsset(props.caseId, assetId)
  }

  if (!assetIds || assetIds.length == 0) return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t("case:noAssets")}
      </h3>

      <ListBackground />
    </div>
  )

  return (
    <div>
      <AssetTypeFilter setFilters={setAssetTypeFilters} />
      <ul>
        {assetIds.map((assetId: string) => {
          return (
            <CaseAssetListItem
              action={() => props.openAssetModal(assetId)}
              key={assetId}
              assetId={assetId}
              deleteAction={deleteTheAsset}
              assetTypeFilters={assetTypeFilters}
              mentionableUsers={props.mentionableUsers}
              caseId={props.caseId}
              meetingId={props.meetingId}
              meetingCaseId={props.meetingCaseId}
              isSelectingAssets={props.isSelectingAssets}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default CaseAssetList
