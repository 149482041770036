import React from "react"
import _ from "lodash"
import { Trans, useTranslation } from "react-i18next"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import AddUsersForm from "../../../../shared/components/user/AddUsersForm"
import { useAppSelector } from "../../../../shared/hooks"
import { getTeamById } from "../../../../shared/selectors/team"
import { IconTeam } from "../../../../svg/icons"
import TeamDetails from "../../../teams/components/details/TeamDetails"
import { ITeam } from "../../state/teamTypes"

interface IProps {
  teamId: string
  closeModal: VoidFunction
  createTeamUsers: (values: { users: string[] }, { setSubmitting }: { setSubmitting: VoidFunction }) => void
}

const AddTeamUsersModalContent: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared"])
  const team = useAppSelector((state): ITeam => getTeamById(state, props.teamId))
  return (
    <ModalContainer handleClose={props.closeModal}>
      <h2 className="text-lg mb-3">{t("team:inviteMembersIntro")}</h2>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconTeam className="fill-current w-6 h-6" />
          </IconCircleContainer>
          <TeamDetails teamId={team.id} userId={team.owner} />
        </DetailsCardInner>
      </DetailsCard>

      <div className="mb-3">
        <p className="text-ch-blue-600">{t("team:inviteMembersDesc1")}</p>
        <p className="text-ch-blue-600">{t("team:inviteMembersDesc2")}</p>
      </div>

      <div className="w-full">
        <AddUsersForm
          submitHandler={props.createTeamUsers}
          excludedUserIds={team.users}
          userLabel={t("shared:chooseUsers", {
            user: _.lowerCase(t("shared:member"))
          })}
          buttonText={
            <Trans
              i18nKey="shared:addUsers"
              values={{
                users: t("shared:members"),
                entity: t("shared:team")
              }}
            />
          }
          disableTeams
        />
      </div>
    </ModalContainer>
  )
}

export default AddTeamUsersModalContent
