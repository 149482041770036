export interface IFetchSpecialtyAsyncAction {
  type: string
}

export interface ISpecialty {
  id: string,
  name: string,
  children?: string[]
  parentId?: string
}

export enum SpecialtyActionTypes {
  FETCH_SPECIALTIES_ASYNC_PENDING = "@@specialties/FETCH_SPECIALTIES_ASYNC_PENDING",
  FETCH_SPECIALTIES_ASYNC_SUCCESS = "@@specialties/FETCH_SPECIALTIES_ASYNC_SUCCESS",
  FETCH_SPECIALTIES_ASYNC_ERROR = "@@specialties/FETCH_SPECIALTIES_ASYNC_ERROR",
}
