import { IUser } from "../../features/user/state/userTypes"

export const formatUserMentionString = (user: IUser): string => {
  return `@${user.firstName} ${user.lastName}`.trim()
}

export const formatPlainTextMentionsText = (text: string): string => {
  // Remove format around database @mentions
  // eg. repalce @[@George Clobney](d885ad40-b2b4-4b85-846b-1fe06225c1f6)
  // with @George Clobney
  const mentionRegEx = /@\[(.*?)]\(([A-Za-z0-9-]+)\)/gm
  return text.replace(mentionRegEx, (_a, b) => {
    return b
  })
}

export const toKebabCase = (text: string): string => {
  return text
    .replace(/ +/g, "-")
    .replace(":", "-")
    .toLowerCase()
}
