import * as Yup from "yup"
import i18n from "../../../app/i18n"

export const teamNewValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", { maxLength: 50 })),
    specialtyId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    ownerId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    teamUsers: Yup
      .array().min(1, i18n.t("shared:teamMembersSelectAtLeastOne"))
  })
}

export const mdtTeamNewValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", { maxLength: 50 })),
    specialtyId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    ownerId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    teamUsers: Yup
      .array()
      .of(Yup.object().shape({
        userId: Yup.string().required(i18n.t("shared:required")),
        role: Yup.string().required(i18n.t("shared:required")),
      }))
      .min(1, i18n.t("shared:teamMembersSelectAtLeastOne"))
  })
}
