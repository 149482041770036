import { takeLeading, put, call } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import history from "../../../../app/routes/history"
import { AccountActionTypes } from "../accountTypes"
import { AxiosError } from "axios"
import { IApiResponseData } from "../../../../shared/state/sharedTypes"

export function* setPasswordAsync(action): Generator {
  try {
    const setPasswordData = {
      user: action.payload
    }

    const response: any = yield call(API.patch, "/set_password", setPasswordData)

    yield put({
      type: AccountActionTypes.SET_PASSWORD_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "setPasswordSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, RoutePaths.SIGN_IN)
  } catch (error: unknown) {
    yield put({
      type: AccountActionTypes.SET_PASSWORD_ASYNC_ERROR,
      payload: error
    })

    const errorInstance = error as AxiosError
    const data = errorInstance.response?.data as IApiResponseData
    if (errorInstance.response?.status == 404 && data) {
      if (data.error == "token expired") {
        yield put(displayUserMessageAction({
          messageKey: "resetTokenExpired",
          type: UserMessageTypes.ERROR
        }))
      }
      if (data.error == "token invalid") {
        yield put(displayUserMessageAction({
          messageKey: "resetTokenInvalid",
          type: UserMessageTypes.ERROR
        }))
      }
    } else {
      yield put(displayUserMessageAction({
        messageKey: "setPasswordFail",
        type: UserMessageTypes.ERROR
      }))
    }
  }
}

export function* watchSetPasswordAsync(): Generator {
  yield takeLeading(AccountActionTypes.SET_PASSWORD_ASYNC_PENDING, setPasswordAsync)
}

export function* sendResetPasswordLinkAsync(action): Generator {
  try {
    const resetPasswordData = {
      user: action.payload
    }

    const response: any = yield call(API.post, "/send_reset_password_link", resetPasswordData)
    yield put({
      type: AccountActionTypes.SEND_RESET_PASSWORD_LINK_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "sendResetPasswordLinkSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, RoutePaths.SIGN_IN)
  } catch (error) {
    yield put({
      type: AccountActionTypes.SEND_RESET_PASSWORD_LINK_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "sendResetPasswordLinkFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchSendResetPasswordLinkAsync(): Generator {
  yield takeLeading(AccountActionTypes.SEND_RESET_PASSWORD_LINK_ASYNC_PENDING, sendResetPasswordLinkAsync)
}
