import React, { ReactNode } from "react"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { IDocumentAsset } from "../../state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IconPdf } from "../../../../svg/icons"
import { bytesToSize } from "../../../../shared/helpers/fileHelpers"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { IMessageDocumentAsset } from "../../../message/state/messageTypes"
import AssetSubHeader from "./AssetSubHeading"
import { useSelector } from "react-redux"

interface IProps {
  assetId: string
  action?: () => void
  isSelected?: boolean
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
}

const Pdf: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const pdf = asset.asset as IDocumentAsset | IMessageDocumentAsset

  return (
    <div
      onClick={props.action ? () => props.action() : null}
      className={classNames(
        "bg-white p-2 flex rounded-md border-2",
        {
          "border-ch-blue-200": !props.isSelected,
          "border-ch-blue-500": props.isSelected,
          "hover:border-ch-blue-500": props.action,
          "cursor-pointer": props.action,
          "cursor-default": !props.action,
        }
      )}
    >
      <div className="text-ch-blue-alt-400 mr-2 mt-1">
        <IconPdf className="w-5 h-5" title={pdf.name} />
      </div>

      <div className="flex-1 min-w-0">
        <h4 className="font-semibold text-lg">
          {`${pdf.name} (${bytesToSize(pdf.bytes)})`}
        </h4>
        <AssetSubHeader
          meetingCaseAsset={props.meetingCaseAsset}
          meetingId={props.meetingId}
          asset={asset}
        />
      </div>
      {props.children}
    </div>
  )
}

export default Pdf
