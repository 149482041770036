import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { LogoFull } from "../svg/branding"

const AccountLayout: React.FC = ({ children }): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const defaultClasses = "bg-ch-blue-alt-200 h-screen flex flex-col items-center p-3 md:p-12"

  return (
    <div className={defaultClasses}>
      <div className="bg-white shadow-md rounded-md border border-ch-blue-alt-300 p-8 w-full sm:w-2/3 xl:w-1/2 mb-10">
        <div className="flex items-center p-1 justify-center mb-6">
          <LogoFull
            className="w-64"
            title={t("shared:contextMeeting")}
          />
          <h1 className="text-ch-blue-600 hidden">
            {t("shared:contextMeeting")}
          </h1>
        </div>
        {children}
      </div>
      <div className="flex flex-col font-thin items-center">
        <span className="mb-4 text-xs">
          <Trans
            i18nKey="shared:termsAndPrivacyLinks"
            components={{
              TermsLink: <a href="https://www.contexthealth.com/legal/terms-and-conditions.pdf" target="_blank" rel="noreferrer" className="text-ch-blue-500 hover:text-ch-blue-600"></a>,
              PrivacyLink: <a href="https://www.contexthealth.com/legal/privacy-statement.pdf" target="_blank" rel="noreferrer" className="text-ch-blue-500 hover:text-ch-blue-600"></a>
            }}
          />
        </span>
        <span className="text-xs">
          {t("shared:copyright", { year: new Date().getFullYear() })}
        </span>
      </div>
    </div>
  )
}

export default AccountLayout
