import { AnyAction, combineReducers, Reducer } from "redux"
import { AddPathwayToCaseActionTypes, IAddPathwayToCaseUIState } from "../addPathwayToCaseTypes"

export const totalPathwaysCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.totalItems
    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.totalPages
    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = [], action: AnyAction): string[] => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.appliedFilters
    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = 50, action: AnyAction): number => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.resultsPerPage
    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = "updated_desc", action: AnyAction): string => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const resultIds: Reducer<string[]> = (state = [], action: AnyAction): string[] => {
  switch (action.type) {
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return action.payload.all.result
    default:
      return state
  }
}

export const addPathwayToCaseUIReducer: Reducer<IAddPathwayToCaseUIState> = combineReducers({
  totalPathwaysCount,
  totalPages,
  appliedFilters,
  resultsPerPage,
  searchTerm,
  orderBy,
  resultIds
})
