import React from "react"
import Modal from "react-modal"
import { useSelector, useDispatch } from "react-redux"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { deletePathwayAction } from "../pathwayActions"
import { getPathway } from "../../../shared/selectors/pathway"
import Button from "../../../shared/components/buttons/Button"
import { dialogueModalStyle, StyleVariants } from "../../../shared/state/sharedTypes"
import Buttons from "../../../shared/components/layout/Buttons"
import PathwayHeader from "./PathwayHeader"

interface Props {
  pathwayId: string
}

const DeletePathwayButton: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const pathway = useSelector(getPathway(props))
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  const { t } = useTranslation(["pathway"])
  const deletePathway = () => dispatch(deletePathwayAction(props.pathwayId))

  const openConfirm = (): void => {
    setConfirmIsOpen(true)
  }

  const closeConfirm = (): void => {
    setConfirmIsOpen(false)
  }

  if (!pathway.permissions?.canEdit) return null

  return (
    <div>
      <Button
        action={openConfirm}
        variant={StyleVariants.PURPLE}
      >
        {t("pathway:buttons:deletePathway")}
      </Button>

      <Modal
        isOpen={confirmIsOpen}
        onRequestClose={closeConfirm}
        style={dialogueModalStyle}
      >
        <ModalContainer handleClose={closeConfirm}>
          <h2 className="text-lg mb-3">
            {t("pathway:details:deletePathwayHeader")}:
          </h2>
          <div>
            <PathwayHeader pathwayId={props.pathwayId} hideButtons />
          </div>
          <div className="my-3">
            {t("pathway:details:deletePathwayAreYouSure")}
          </div>

          <Buttons
            buttons={[
              <Button
              action={closeConfirm}
              variant={StyleVariants.BLUE_ALT}
              key="1"
            >
              {t("shared:cancel")}
            </Button>,
            <Button
              action={() => deletePathway()}
              variant={StyleVariants.PURPLE}
              key="2"
            >
               {t("pathway:buttons:deletePathway")}
            </Button>
            ]}
          />
        </ModalContainer>
      </Modal>
    </div>

  )
}

export default DeletePathwayButton
