import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import queryString from "query-string"
import humps from "humps"
import history from "../../../../app/routes/history"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { patientsSchema } from "../../../../apis/contextmeeting/schema"
import { PatientNewActionTypes } from "../patientNewTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { RoutePaths } from "../../../../app/routes/Routes"

export function* checkPatientAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload.patientCheckData), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/patients/check?${query}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, [patientsSchema])

    yield put({
      type: PatientNewActionTypes.CHECK_PATIENT_ASYNC_FOUND,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: PatientNewActionTypes.CHECK_PATIENT_ASYNC_NOT_FOUND,
      payload: error
    })
  }
}

export function* watchCheckPatientAsync(): Generator {
  yield takeLeading(PatientNewActionTypes.CHECK_PATIENT_ASYNC_PENDING, checkPatientAsync)
}

export function* createPatientAsync(action: Effect): Generator {
  try {
    const data = {
      patient: action.payload.patient
    }

    const response: any = yield call(API.post, "/patients", data, withAuthHeader())

    const normalizedResponse = normalize(response.data, patientsSchema)
    yield put({
      type: PatientNewActionTypes.CREATE_PATIENT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createPatientSuccess",
      type: UserMessageTypes.SUCCESS
    }))
    yield history.push(RoutePaths.PATIENTS)
  } catch (error) {
    yield put({
      type: PatientNewActionTypes.CREATE_PATIENT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createPatientFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreatePatientAsync(): Generator {
  yield takeLeading(PatientNewActionTypes.CREATE_PATIENT_ASYNC_PENDING, createPatientAsync)
}
