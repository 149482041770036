import { normalize } from "normalizr"
import { takeLeading, put, call } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { CaseSharedAssetsActionTypes, IFetchCaseAssetsCanBeSharedWithUser } from "../caseSharedAssetsTypes"

export function* fetchCaseAssetsCanBeSharedWithUser(action: IFetchCaseAssetsCanBeSharedWithUser): Generator {
  try {
    const caseId = action.payload.caseId
    const userId = action.payload.userId
    const response: any = yield call(API.get, `/cases/${caseId}/shared_assets?user_id=${userId}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId,
        userId: userId
      }
    })
  } catch (error) {
    yield put(displayUserMessageAction({
      messageKey: "fetchCaseAssetsCanBeSharedWithUserError",
      type: UserMessageTypes.ERROR
    }))

    yield put({
      type: CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchCaseAssetsCanBeSharedWithUser(): Generator {
  yield takeLeading(CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_PENDING, fetchCaseAssetsCanBeSharedWithUser)
}
