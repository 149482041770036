import React from "react"
import { IconAlert } from "../../../svg/icons"
import DetailsCard from "../layout/detailsCard/DetailsCard"
import DetailsCardInner from "../layout/detailsCard/DetailsCardInner"

interface Props {
  children: React.ReactNode
}


const WarningBanner: React.FC<Props> = (props: Props): JSX.Element => {

  return (
    <DetailsCard>
      <DetailsCardInner width="w-full">
        <IconAlert className="text-ch-orange-500 w-6 h-4 mr-6 flex-shrink-0" />
        {props.children}
      </DetailsCardInner>
    </DetailsCard>
  )
}


export default WarningBanner
