import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import CasePathwayTaskListItem from "./CasePathwayTaskListItem"
import { getTasksById } from "../../../../../shared/selectors/task"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import { ITask } from "../../../../tasks/state/tasksTypes"

interface Props {
  taskIds: string[]
}

const CasePathwayTaskList: React.FC<Props> = (props: Props): JSX.Element => {
  const tasks: ITask[] = useSelector((state: IAppState) => getTasksById(state, props.taskIds))
  const sortedTasks = _.sortBy(tasks, ["casePathwayPosition"])

  const { t } = useTranslation(["pathway"])

  if (!props.taskIds) return (
    <h4 className="text-center">
      {t("pathway:addPathwayToCase:noTasks")}
    </h4>
  )

  return (
    <div>
      {sortedTasks.map((task: ITask) => {
        return (
          <CasePathwayTaskListItem key={task.id} taskId={task.id} casePathwayId={task.casePathwayId} />
        )
      })}
    </div>
  )
}

export default CasePathwayTaskList
