import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import NameUser from "../../../../shared/components/names/NameUser"
import { getTeamByHasTeamId } from "../../../../shared/selectors/team"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import { useTranslation } from "react-i18next"
import { TeamTypes } from "../../../team/state/teamTypes"

interface IProps {
  teamId: string
  userId: string
}

const TeamDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["team"])
  const team = useSelector((state: IAppState) => getTeamByHasTeamId(state, props))
  const teamOwnerUser = useSelector((state: IAppState) => getUserByHasUserId(state, props))

  const renderTeamName = () => {
    return (
      <div className="font-semibold">
        {team.teamType == TeamTypes.mdt ? `${t("team:mdt:mdt")}: ` : null}{team.name}
      </div>
    )
  }
  const renderOwner = () => {
    return (
      <>
        {team.teamType == TeamTypes.mdt ? t("team:mdt:mdtCoordinator") : t("team:teamOwner")}:&nbsp;
        <NameUser
          firstName={teamOwnerUser.firstName}
          lastName={teamOwnerUser.lastName}
          isPending={teamOwnerUser.pending}
        />
      </>
    )
  }
  const renderSpecialty = () => {
    return (
      <>
        {t("team:specialty")}:&nbsp;{team.specialtyName}
      </>
    )
  }
  const renderLastUpdated = () => {
    return (
      <>
        {t("team:lastUpdated")}:&nbsp;
        <TimeDefaultFormat utcTime={team.updatedAt} />
      </>
    )
  }
  const renderteamMemebrsLength = () => {
    return (
      <>
        {t("team:teamMembers")}:&nbsp;{team.users.length}
      </>
    )
  }
  if (!team || !teamOwnerUser) return null
  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        <li>{renderTeamName()}</li>
        <li>{renderSpecialty()}</li>
        <li>{renderOwner()}</li>
      </ul>
      <ul className="col-span-1">
        <li>{renderLastUpdated()}</li>
        <li>{renderteamMemebrsLength()}</li>
      </ul>
    </div>
  )
}

export default TeamDetails
