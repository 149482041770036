import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { Trans, useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import PageTitle, { TitleButtonFactory } from "../../shared/components/ui/PageTitle"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import IPatientsUIState, { PatientsOrderByOptions } from "../../features/patients/state/patientsTypes"
import { getPatientsUI } from "../../shared/selectors/ui"
import { fetchPatientsAsync } from "../../features/patients/state/patientsActions"
import PatientList from "../../features/patients/components/list/PatientList"
import OrderBy from "../../shared/components/search/OrderBy"
import SearchInput from "../../shared/components/search/SearchInput"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import { RoutePaths } from "../../app/routes/Routes"
import { getCurrentUser } from "../../shared/selectors/user"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"
import PatientsUploadModal from "../../features/patients/components/upload/PatientsUploadModal"

const PatientsPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState(false)
  const currentUser = useSelector((state: IAppState) => getCurrentUser(state))
  const UI: IPatientsUIState = useSelector((state: IAppState) => getPatientsUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchPatientsAsync)

  const openUploadModal = () => { setUploadModalIsOpen(true) }
  const closeUploadModal = () => { setUploadModalIsOpen(false) }

  const secondaryButton = () => {
    if (!currentUser.abilities?.patient.canUpload) return null
    return TitleButtonFactory(openUploadModal, t("patient:index:buttons:bulkUpload"), "2")
  }

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:patients")}
        buttonText={t("patient:index:buttons:addNewPatient")}
        buttonHref={RoutePaths.PATIENT_NEW}
        hasButtonPermission={currentUser.abilities?.patient.canCreate}
        secondaryButton={secondaryButton()}
      />
      <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
        <div className="row-start-2 md:row-start-1 self-center">
          <Trans
            i18nKey="patient:index:searchBy"
            components={{
              strong: <strong />
            }}
            values={{ careProviderIdLabel: careProviderIdLabel }}
          />
        </div>
        <div className="row-end-2 md:row-start-1 flex justify-end">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>

      <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        buttonText={t("patient:index:buttons:searchPatients")}
      />

      <h2 className="text-lg mb-2 text-center md:text-left">
        {UI.totalPatientsCount} {t("shared:patients")}
      </h2>

      <div className="grid grid-flow-row md:grid-flow-col">
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={PatientsOrderByOptions}
            useTranslation={"patient:index:orderBy"}
          />
        </div>
      </div>
      <PatientList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalPatientsCount}
        totalPages={UI.totalPages}
      />
      <PatientsUploadModal
        closeModal={closeUploadModal}
        isOpen={uploadModalIsOpen}
      />
    </div>
  )
}

export default PatientsPage
