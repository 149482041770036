import axios, { AxiosInstance, AxiosRequestTransformer, AxiosResponseTransformer } from "axios"
import axiosRetry from "axios-retry"
import humps from "humps"
import history from "../../app/routes/history"
import { RoutePaths } from "../../app/routes/Routes"
import { clearSession } from "../../app/state/appSessionStorage"

const API: AxiosInstance =
  axios.create({
    baseURL: process.env.API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    transformResponse: [
      ...axios.defaults.transformResponse as AxiosResponseTransformer[],
      data => humps.camelizeKeys(data)
    ],
    transformRequest: [
      data => {
        if (data instanceof FormData) { return data }
        return humps.decamelizeKeys(data)
      },
      ...axios.defaults.transformRequest as AxiosRequestTransformer[]
    ]
  })

API.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    if (error.response.headers["www-authenticate"] && error.response.headers["www-authenticate"].match(/MFA required/)) {
      history.push(RoutePaths.SETUP_MFA_INTRO)
      return Promise.reject(error)
    }
    clearSession()
    history.push(RoutePaths.SIGN_IN)
    return Promise.reject(error)
  }
  if (error.response.status === 451) {
    history.push(RoutePaths.TERMS)
    return new Promise(() => {
      // doing this to prevent the error bubbling up and then showing a flash message eg meetings not found
    })
  }
  return new Promise((_, reject) => { reject(error) })
})

axiosRetry(API, {
  retries: 3,
  retryCondition: (error) => {
    return error.response?.status == 429
  }
})

export default API

