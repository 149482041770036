import React, { ReactNode, useContext, useEffect, useState } from "react"
import _ from "lodash"
import { CableOptions, CableCar } from "../../@types/redux-cablecar"
import { store } from "../../../app/state/store"
import LoadingSpinner from "../ui/LoadingSpinner"
import { CableContext } from "./CableContext"
import { useAppSelector } from "../../hooks"
import { mfaSmsRequesting } from "../../selectors/userSession"

interface Props {
  channelName: string
  actionPrefix: string
  children?: ReactNode
  resourceId?: string
  awaitConnection?: boolean
  tenantId?: string
}

const CableConnection: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    apiToken,
    cableCarRoute
  } = useContext(CableContext)
  const [connection, setConnection] = useState(null)
  const requestingSms = useAppSelector(mfaSmsRequesting)

  const connectCable = async (channelName: string, actionPrefix: string, resourceId?: string, tenantId?: string): Promise<CableCar> => {
    let connectionResult: CableCar = null

    return new Promise((resolve, reject) => {
      const cableOptions: CableOptions = {
        params: {
          resource_id: resourceId,
          tenant_id: tenantId
        },
        permittedActions: [
          actionPrefix
        ],
        connected: () => {
          resolve(connectionResult)
        },
        disconnected: () => {
          console.error("disconnected ws connection")
          reject()
        },
        rejected: () => {
          console.error("rejected ws connection")
          reject()
        }
      }
      connectionResult = cableCarRoute.connect(
        store,
        channelName,
        cableOptions
      )
    })
  }

  useEffect(() => {
    if (!apiToken || apiToken.length == 0) {
      if (connection) connection.destroy()
      return
    }
    if (connection) { return }
    const init = async () => {
      setConnection(
        await connectCable(
          props.channelName,
          props.actionPrefix,
          props.resourceId,
          props.tenantId
        ) as React.SetStateAction<CableCar> // ???
      )
    }
    init()
  }, [apiToken, connection, requestingSms])

  useEffect(() => {
    if (!connection) return
    return () => {
      // setTimeout to kill cable connection after final webRTC
      // events have been sent eg. to show user offline
      setTimeout(() => {
        connection.destroy()
      })
    }
  }, [connection])

  if (props.awaitConnection && !connection && props.children) return <LoadingSpinner />
  if (!props.children) return null
  if (!apiToken || apiToken.length == 0 || !cableCarRoute) return null

  return (
    <div>
      {props.children}
    </div>
  )
}

export default CableConnection
