import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../shared/selectors/user"
import SetupMfaPage from "./SetupMfaPage"
import { IPhoneDetails } from "../../features/account/state/accountTypes"
import SetupMfaTotpPage from "./SetupMfaTotpPage"
import SetupMfaSmsPage from "./SetupMfaSmsPage"
import MfaTotpPage from "./MfaTotpPage"
import MfaSmsPage from "./MfaSmsPage"

enum MfaStage {
  INTRO,
  TOTP_SETUP,
  SMS_SETUP,
  TOTP_SETUP_COMPLETE,
  TOTP,
  SMS
}

interface IPropsFromParent {
  directRoute?: boolean
}

const MfaPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const [phoneDetails, setPhoneDetails] = useState<IPhoneDetails>(null)
  const currentUser = useSelector(getCurrentUser)
  const [mfaStage, setMfaStage] = useState(MfaStage.INTRO)

  const setupOtpAction = () => {
    setMfaStage(MfaStage.TOTP_SETUP)
  }

  const setupSmsAction = (phoneDetails: IPhoneDetails) => {
    setPhoneDetails(phoneDetails)
    setMfaStage(MfaStage.SMS_SETUP)
  }

  useEffect(() => {
    if (props.directRoute && !currentUser.mfaRequired) return
    if (currentUser.mfaMethods.length > 0) {
      if (currentUser.mfaMethods.includes("totp")) {
        setMfaStage(MfaStage.TOTP)
      } else {
        setMfaStage(MfaStage.SMS)
      }
    }
  }, [currentUser])

  switch (mfaStage) {
    case MfaStage.INTRO:
      return (<SetupMfaPage phoneDetails={phoneDetails} totpAction={setupOtpAction} smsAction={setupSmsAction} />)
    case MfaStage.TOTP_SETUP:
      return (<SetupMfaTotpPage phoneDetails={phoneDetails} backToIntro={() => setMfaStage(MfaStage.INTRO)} />)
    case MfaStage.SMS_SETUP:
      return (<SetupMfaSmsPage phoneDetails={phoneDetails} backToIntro={() => setMfaStage(MfaStage.INTRO)} />)
    case MfaStage.TOTP:
      return (<MfaTotpPage switchToSms={() => setMfaStage(MfaStage.SMS)} />)
    case MfaStage.SMS:
      return (<MfaSmsPage switchToTotp={() => setMfaStage(MfaStage.TOTP)} />)
    default:
      null
  }
}

export default MfaPage
