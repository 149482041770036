import { ICreatedBy } from "../../shared/state/sharedTypes"

export interface IPathwayPermissions {
  canEdit: boolean
}
export interface IPathway {
  id: string
  name: string
  description: string
  createdBy: ICreatedBy
  lastUpdatedBy: ICreatedBy
  caseClassification: { name: string }
  caseClassificationId: string
  caseType: string
  createdAt: string
  updatedAt: string
  steps: IPathwayStep[]
  users: string[]
  permissions: IPathwayPermissions
}

export interface IPathwayStep {
  id: string
  name: string
  position: number
  description: string
}

export interface IHavePathwayId {
  pathwayId: string
}

export enum PathwayActionTypes {
  FETCH_PATHWAY_ASYNC_PENDING = "@@pathways/FETCH_PATHWAY_ASYNC_PENDING",
  FETCH_PATHWAY_ASYNC_SUCCESS = "@@pathways/FETCH_PATHWAY_ASYNC_SUCCESS",
  FETCH_PATHWAY_ASYNC_ERROR = "@@pathways/FETCH_PATHWAY_ASYNC_ERROR",

  CREATE_PATHWAY_ASYNC_PENDING = "@@pathways/CREATE_PATHWAY_ASYNC_PENDING",
  CREATE_PATHWAY_ASYNC_SUCCESS = "@@pathways/CREATE_PATHWAY_ASYNC_SUCCESS",
  CREATE_PATHWAY_ASYNC_ERROR = "@@pathways/CREATE_PATHWAY_ASYNC_ERROR",

  UPDATE_PATHWAY_ASYNC_PENDING = "@@pathways/UPDATE_PATHWAY_ASYNC_PENDING",
  UPDATE_PATHWAY_ASYNC_SUCCESS = "@@pathways/UPDATE_PATHWAY_ASYNC_SUCCESS",
  UPDATE_PATHWAY_ASYNC_ERROR = "@@pathways/UPDATE_PATHWAY_ASYNC_ERROR",

  DELETE_PATHWAY_ASYNC_PENDING = "@@pathways/DELETE_PATHWAY_ASYNC_PENDING",
  DELETE_PATHWAY_ASYNC_SUCCESS = "@@pathways/DELETE_PATHWAY_ASYNC_SUCCESS",
  DELETE_PATHWAY_ASYNC_ERROR = "@@pathways/DELETE_PATHWAY_ASYNC_ERROR",

  CREATE_PATHWAY_STEP_ASYNC_PENDING = "@@pathways/CREATE_PATHWAY_STEP_ASYNC_PENDING",
  CREATE_PATHWAY_STEP_ASYNC_SUCCESS = "@@pathways/CREATE_PATHWAY_STEP_ASYNC_SUCCESS",
  CREATE_PATHWAY_STEP_ASYNC_ERROR = "@@pathways/CREATE_PATHWAY_STEP_ASYNC_ERROR",

  UPDATE_PATHWAY_STEP_ASYNC_PENDING = "@@pathways/UPDATE_PATHWAY_STEP_ASYNC_PENDING",
  UPDATE_PATHWAY_STEP_ASYNC_SUCCESS = "@@pathways/UPDATE_PATHWAY_STEP_ASYNC_SUCCESS",
  UPDATE_PATHWAY_STEP_ASYNC_ERROR = "@@pathways/UPDATE_PATHWAY_STEP_ASYNC_ERROR",

  DELETE_PATHWAY_STEP_ASYNC_PENDING = "@@pathways/DELETE_PATHWAY_STEP_ASYNC_PENDING",
  DELETE_PATHWAY_STEP_ASYNC_SUCCESS = "@@pathways/DELETE_PATHWAY_STEP_ASYNC_SUCCESS",
  DELETE_PATHWAY_STEP_ASYNC_ERROR = "@@pathways/DELETE_PATHWAY_STEP_ASYNC_ERROR",

  ADD_PATHWAY_USERS_ASYNC_PENDING = "@@pathways/ADD_PATHWAY_USERS_ASYNC_PENDING",
  ADD_PATHWAY_USERS_ASYNC_SUCCESS = "@@pathways/ADD_PATHWAY_USERS_ASYNC_SUCCESS",
  ADD_PATHWAY_USERS_ASYNC_ERROR = "@@pathways/ADD_PATHWAY_USERS_ASYNC_ERROR",

  REMOVE_PATHWAY_USER_ASYNC_PENDING = "@@pathways/REMOVE_PATHWAY_USER_ASYNC_PENDING",
  REMOVE_PATHWAY_USER_ASYNC_SUCCESS = "@@pathways/REMOVE_PATHWAY_USER_ASYNC_SUCCESS",
  REMOVE_PATHWAY_USER_ASYNC_ERROR = "@@pathways/REMOVE_PATHWAY_USER_ASYNC_ERROR",
}

export interface IFetchPathwayAction {
  type: string
  payload: string
}

export interface ICreatePathwayAction {
  type: string
  payload: IPathwayNewData
}

export interface IUpdatePathwayAction {
  type: string
  payload: IPathway
}


export interface IPathwayNewData {
  name: string
  description: string
  caseClassificationId: string
  caseType: string
  userIds: string[]
}

export interface ICreatePathwayStepAction {
  type: string
  payload: {
    pathwayId: string
    values: IPathwayStepNewData
  }
}

export interface ICreatePathwayStepActionPayload {
  pathwayId: string
  values: IPathwayStepNewData
}

export interface IDeletePathwayAction {
  type: string
  payload: {
    pathwayId: string
  }
}

export interface IUpdatePathwayStepAction {
  type: string
  payload: {
    pathwayId: string
    values: IPathwayStep
  }
}

export interface IPathwayStepNewData {
  name: string
  description: string
}

export interface IDeletePathwayStepAction {
  type: string
  payload: {
    pathwayId: string
    stepId: string
  }
}

export interface IAddPathwayUsersAction {
  type: string
  payload: {
    pathwayId: string
    users: string[]
  }
}

export interface IRemovePathwayUserAction {
  type: string
  payload: {
    pathwayId: string
    user: string
  }
}
