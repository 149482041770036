import React from "react"
import { IAuditEvent, IAuditEventCardData, AuditAssetComponentTypes, AuditEventDocumentTypes, AuditEventTypes, CardIconStyle, IAuditEventIconContent } from "../../audit/state/auditEventTypes"
import AuditEventUserEditedCard from "./auditEventCard/AuditEventUserEditedCard"
import AuditEventCaseEditedCard from "./auditEventCard/AuditEventCaseEditedCard"
import AuditEventMeetingEditedCard from "./auditEventCard/AuditEventMeetingEditedCard"
import AuditEventMeetingAssetDetail from "./details/AuditEventMeetingAssetDetail"
import AuditEventSpecialtyName from "./details/AuditEventSpecialtyName"
import AuditEventBodyContent from "./details/AuditEventBodyContent"
import AuditEventConsentOnBehalf from "./details/AuditEventConsentOnBehalf"
import AuditEventCaseName from "./details/AuditEventCaseName"

const getFileIconType = (fileType: string, color: string): IAuditEventIconContent => {
  switch (fileType) {
    case AuditEventDocumentTypes.PdfFile:
      return {
        iconName: "iconPdf",
        color: color
      }
    case AuditEventDocumentTypes.ImagePng:
    case AuditEventDocumentTypes.ImageJpeg:
      return {
        iconName: "iconImage",
        color: color
      }
  }
}

const getDocumentIconType = (fileType: string, eventType: string): IAuditEventIconContent => {
  switch (eventType) {
    case AuditAssetComponentTypes.documentAdded:
      return getFileIconType(fileType, CardIconStyle.purpleColor)
    case AuditAssetComponentTypes.documentRemoved:
      return getFileIconType(fileType, CardIconStyle.orangeColor)
  }
}

const getDocumentAddedHeaderText = (fileType: string): string => {
  return fileType.includes(AuditEventDocumentTypes.PdfFile) ? "pdfAdded" : "imageAdded"
}

const getDocumentRemovedHeaderText = (fileType: string): string => {
  return fileType.includes(AuditEventDocumentTypes.PdfFile) ? "pdfDeleted" : "imageDeleted"
}

const getDocumentAuditEventCardObj = (auditEvent: IAuditEvent, meetingId: string): IAuditEventCardData => {
  const documentAdded = auditEvent.eventType == AuditAssetComponentTypes.documentAdded
  const fileType = auditEvent.eventData?.data?.fileType
  if (auditEvent.eventData.data)
    return {
      iconContent: getDocumentIconType(fileType, auditEvent.eventType),
      headerText: documentAdded ? getDocumentAddedHeaderText(fileType) : getDocumentRemovedHeaderText(fileType),
      body: <AuditEventBodyContent header="title" content={auditEvent.eventData.data.name} />,
      meetingId: meetingId
    }
  else {
    return {
      iconContent: {
        iconName: "iconTask"
      },
      headerText: documentAdded ? "documentAdded" : "documentRemoved",
      meetingId: meetingId
    }
  }
}

const ignoreCard = {
  iconContent: null,
  headerUserId: null,
  byUserId: null,
  hideCard: true
}

export const getAuditEventsCardData = (auditEvent: IAuditEvent): IAuditEventCardData => {
  switch (auditEvent.eventType) {
    // Meeting auditEvents cards
    case AuditAssetComponentTypes.meetingCreated:
      return {
        iconContent: {
          iconName: "iconMeeting"
        }
      }
    case AuditAssetComponentTypes.meetingUserAdded:
      return {
        iconContent: {
          iconName: "iconUser"
        },
        headerUserId: auditEvent.eventData.data.userId
      }
    case AuditAssetComponentTypes.meetingUserRemoved:
      return {
        iconContent: {
          iconName: "iconUser",
          color: CardIconStyle.orangeColor
        },
        headerUserId: auditEvent.eventData.data.userId
      }
    case AuditAssetComponentTypes.meetingCaseAdded: {
      const meetingId = auditEvent.eventData.changes?.meetingId ? auditEvent.eventData.changes?.meetingId[1] : null
      return {
        iconContent: {
          iconName: "iconFolder"
        },
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.meetingCaseRemoved: {
      const meetingId = auditEvent.eventData.changes?.meetingId ? auditEvent.eventData.changes.meetingId as string : null
      return {
        iconContent: {
          iconName: "iconFolder",
          color: CardIconStyle.orangeColor
        },
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.meetingedited:
      return {
        iconContent: {
          iconName: "iconMeeting",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingEditedCard auditEvent={auditEvent} />
      }
    case AuditAssetComponentTypes.meetingInProgress:
      return {
        iconContent: {
          iconName: "iconVideoOn"
        }
      }
    case AuditAssetComponentTypes.meetingUserJoined:
      return {
        iconContent: {
          iconName: "iconUser"
        },
        headerUserId: auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingUserLeft:
      return {
        iconContent: {
          iconName: "iconUser",
          color: CardIconStyle.orangeColor
        },
        headerUserId: auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingPresenterAssigned:
      return {
        iconContent: {
          iconName: "iconPresenter"
        },
        headerUserId: auditEvent.eventData.subjectId,
        hideCard: !auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingPresenterAccepted:
      return {
        iconContent: {
          iconName: "iconPresenter"
        },
        headerUserId: auditEvent.createdById,
        byUserId: auditEvent.eventData.subjectId,
        hideCard: !auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingSelectedCase:
      return {
        iconContent: {
          iconName: "iconFolder"
        },
        body: <AuditEventCaseName meetingCaseId={auditEvent.eventData.subjectId} />
      }
    case AuditAssetComponentTypes.meetingScribeAssigned:
      return {
        iconContent: {
          iconName: "iconScribe"
        },
        headerUserId: auditEvent.eventData.subjectId,
        hideCard: !auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingScribeAccepted:
      return {
        iconContent: {
          iconName: "iconScribe"
        },
        headerUserId: auditEvent.createdById,
        byUserId: auditEvent.eventData.subjectId,
        hideCard: !auditEvent.eventData.subjectId
      }
    case AuditAssetComponentTypes.meetingEnded:
      return {
        iconContent: {
          iconName: "iconMeeting",
          color: CardIconStyle.grayColor
        }
      }
    case AuditAssetComponentTypes.meetingCancelled:
      return {
        iconContent: {
          iconName: "iconMeeting",
          color: CardIconStyle.orangeColor
        }
      }

    // MeetingCase auditEvent cards
    case AuditAssetComponentTypes.commentAdd: {
      const meetingId = auditEvent.eventData.changes.meetingId ? auditEvent.eventData.changes.meetingId[1] : null
      return {
        iconContent: {
          iconName: "iconComment"
        },
        body: <AuditEventBodyContent header="title" content={auditEvent.eventData.changes.title[1]} />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.commentRemove: {
      const changes = auditEvent.eventData.changes.title as string
      const meetingId = auditEvent.eventData.changes?.meetingId as string
      return {
        iconContent: {
          iconName: "iconComment",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventBodyContent header="title" content={changes} />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.decision: {
      const meetingId = auditEvent.eventData.changes.meetingId ? auditEvent.eventData.changes.meetingId[1] : null
      return {
        iconContent: {
          iconName: "iconHandshake"
        },
        body: <AuditEventBodyContent header="title" content={auditEvent.eventData.changes.title[1]} />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.documentAdded: {
      const meetingId = auditEvent.entityType == AuditEventTypes.CASE ? auditEvent.eventData.data?.meetingId as string : null
      return getDocumentAuditEventCardObj(auditEvent, meetingId)
    }
    case AuditAssetComponentTypes.documentRemoved: {
      const meetingId = auditEvent.entityType == AuditEventTypes.CASE ? auditEvent.eventData.data?.meetingId as string : null
      return getDocumentAuditEventCardObj(auditEvent, meetingId)
    }
    case AuditAssetComponentTypes.linkAdded: {
      const meetingId = auditEvent.eventData.changes.meetingId ? auditEvent.eventData.changes.meetingId[1] : null
      return {
        iconContent: {
          iconName: "iconLink"
        },
        body: <AuditEventBodyContent header="title" content={auditEvent.eventData.changes.title[1]} />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.linkRemoved: {
      const changes = auditEvent.eventData.changes.title as string
      const meetingId = auditEvent.eventData.changes.meetingId as string
      return {
        iconContent: {
          iconName: "iconLink",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventBodyContent header="title" content={changes} />,
        meetingId: meetingId
      }
    }
    // Meeting and Case AuditEvents
    case AuditAssetComponentTypes.recommendationSaved: {
      const meetingId = auditEvent.eventData.data?.meetingId ? auditEvent.eventData.data?.meetingId as string : null
      return {
        iconContent: {
          iconName: "iconRecommendation"
        },
        body: <AuditEventBodyContent header="status" content="complete" translateText />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.caseReportGenerated: {
      const meetingId = auditEvent.eventData.data?.meetingId ? auditEvent.eventData.data?.meetingId as string : null
      return {
        iconContent: {
          iconName: "iconPdf",
          color: CardIconStyle.blueColor
        },
        body: <AuditEventBodyContent header="title" content={auditEvent.eventData.data.name} />,
        meetingId: meetingId
      }
    }

    // Meeting AuditEvents cards --> assets from/To meetingAssets
    case AuditAssetComponentTypes.commentSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconComment"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} title />
      }
    }
    case AuditAssetComponentTypes.commentRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconComment",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} title />
      }
    }
    case AuditAssetComponentTypes.decisionSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconHandshake"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} title />
      }
    }
    case AuditAssetComponentTypes.decisionRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconHandshake",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} title />
      }
    }
    case AuditAssetComponentTypes.recommendationSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconRecommendation"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} title />
      }
    }
    case AuditAssetComponentTypes.recommendationRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconRecommendation",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} title />
      }
    }
    case AuditAssetComponentTypes.casereportSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconPdf"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} title />
      }
    }
    case AuditAssetComponentTypes.casereportRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconPdf",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} title />
      }
    }
    case AuditAssetComponentTypes.imageSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconImage"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} name />
      }
    }
    case AuditAssetComponentTypes.imageRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconImage",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} name />
      }
    }
    case AuditAssetComponentTypes.pdfSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconPdf"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} name />
      }
    }
    case AuditAssetComponentTypes.pdfRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconPdf",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} name />
      }
    }
    case AuditAssetComponentTypes.linkSelectedToMeetingAssets: {
      return {
        iconContent: {
          iconName: "iconLink"
        },
        body: <AuditEventMeetingAssetDetail assetId={auditEvent.eventData.changes.caseAssetId[1]} title />
      }
    }
    case AuditAssetComponentTypes.linkRemovedFromMeetingAssets: {
      const assetId = auditEvent.eventData.changes.caseAssetId as string
      return {
        iconContent: {
          iconName: "iconLink",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventMeetingAssetDetail assetId={assetId} title />
      }
    }

    // Meeting, MeetingCase and Case auditEvents cards
    case AuditAssetComponentTypes.recommendationAdded: {
      const meetingData = auditEvent.eventData.data
      const meetingId = auditEvent.eventData.changes?.meetingId ? auditEvent.eventData.changes.meetingId[1] : null
      const recommendationStatus = auditEvent.eventData?.changes?.recommendationStatus[1]
      if(auditEvent.entityType == AuditEventTypes.MEETING && meetingData.recommendationStatus as string == "pending") return ignoreCard
      const meetingCaseId = meetingData?.meetingCaseId

      if (meetingCaseId) return {
        iconContent: {
          iconName: "iconMeeting"
        },
        body: meetingCaseId ? <AuditEventCaseName meetingCaseId={meetingCaseId} /> : null,
        meetingId: meetingId
      }
      else return {
        iconContent: {
          iconName: "iconRecommendation"
        },
        body: <AuditEventBodyContent header="status" content={recommendationStatus} translateText />,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.recommendationApproved: {
      const meetingId = auditEvent.eventData.data?.meetingId ? auditEvent.eventData.data?.meetingId : null
      return {
        iconContent: {
          iconName: "iconAccept",
          color: CardIconStyle.greenColor
        },
        body: auditEvent.eventData.changes?.consentById ? <AuditEventConsentOnBehalf auditEvent={auditEvent} /> : null,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.recommendationRejected: {
      const meetingId = auditEvent.eventData.data?.meetingId ? auditEvent.eventData.data?.meetingId : null
      return {
        iconContent: {
          iconName: "iconReject",
          color: CardIconStyle.redColor
        },
        body: auditEvent.eventData.changes?.consentById ? <AuditEventConsentOnBehalf auditEvent={auditEvent} /> : null,
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.recommendationUpdated: {
      return {
        iconContent: {
          iconName: "iconMeeting"
        },
        body: <AuditEventBodyContent header="status" content="final" translateText />
      }
    }
    case AuditAssetComponentTypes.deferralAdded: {
      const meetingId = auditEvent.eventData.changes?.meetingId ? auditEvent.eventData.changes?.meetingId[1] : null
      return {
        iconContent: {
          iconName: "iconDeferral"
        },
        meetingId: meetingId
      }
    }
    case AuditAssetComponentTypes.meetingCaseDeferred: {
      const meetingCaseId = auditEvent.eventData.data?.meetingCaseId ? auditEvent.eventData.data?.meetingCaseId as string : null
      if(auditEvent.entityType == AuditEventTypes.CASE) return ignoreCard
      return {
        iconContent: {
          iconName: "iconDeferral"
        },
        body: <AuditEventCaseName meetingCaseId={meetingCaseId} />
      }
    }

    case AuditAssetComponentTypes.caseMemberAdded:
      return {
        iconContent: {
          iconName: "iconUser"
        },
        headerUserId: auditEvent.eventData.data.userId
      }
    case AuditAssetComponentTypes.caseMemberRemoved:
      return {
        iconContent: {
          iconName: "iconUser",
          color: CardIconStyle.orangeColor
        },
        headerUserId: auditEvent.eventData.data.userId
      }
    case AuditAssetComponentTypes.caseCreated:
      return {
        iconContent: {
          iconName: "iconFolder"
        }
      }
    case AuditAssetComponentTypes.caseUpdated:
      return {
        iconContent: {
          iconName: "iconFolder",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventCaseEditedCard auditEvent={auditEvent} />
      }
    case AuditAssetComponentTypes.caseLocked:
      return {
        iconContent: {
          iconName: "iconLock"
        }
      }
    case AuditAssetComponentTypes.caseUnlocked:
      return {
        iconContent: {
          iconName: "iconLock",
          color: CardIconStyle.orangeColor
        }
      }

    // User AuditEvent Cards
    case AuditAssetComponentTypes.userEdited: {
      return {
        iconContent: {
          iconName: "iconUser"
        },
        body: <AuditEventUserEditedCard auditEvent={auditEvent} />
      }
    }
    case AuditAssetComponentTypes.userAddSpecialty: {
      return {
        iconContent: {
          iconName: "iconUser"
        },
        body: <AuditEventSpecialtyName specialtyId={auditEvent.eventData.changes.specialtyId[1]} />
      }
    }
    case AuditAssetComponentTypes.userRemoveSpecialty: {
      const specialtyId = auditEvent.eventData.changes.specialtyId as string
      return {
        iconContent: {
          iconName: "iconUser",
          color: CardIconStyle.orangeColor
        },
        body: <AuditEventSpecialtyName specialtyId={specialtyId} />
      }
    }
    case AuditAssetComponentTypes.userCreated: {
      return {
        iconContent: {
          iconName: "iconUser",
          color: CardIconStyle.grayColor
        }
      }
    }
    case AuditAssetComponentTypes.userStatus: {
      const active = auditEvent.eventData.changes?.active[1]
      return {
        iconContent: {
          iconName: "iconUser",
          color: active ? CardIconStyle.purpleColor : CardIconStyle.orangeColor
        },
        headerText: active ? "userActivated" : "userDisabled"
      }
    }
    case AuditAssetComponentTypes.userActivated: {
      return {
        iconContent: {
          iconName: "iconUser"
        }
      }
    }

    default:
      return ignoreCard
  }
}
