import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { pickDirectStyles } from './pickStyles';
// many css-in-js libs process keyframes objects as the value for `animationName`
var defaultObjectPropsWhitelist = ['animationName'];

var defaultPropsDecorator = function defaultPropsDecorator(_ref) {
  var style = _ref.style,
      className = _ref.className;
  return _objectSpread(_objectSpread({}, style ? {
    style: pickDirectStyles(style, defaultObjectPropsWhitelist)
  } : {}), className ? {
    className: className
  } : {});
};

export default defaultPropsDecorator;