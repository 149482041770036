import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import DetailsCard from "../../../../../shared/components/layout/detailsCard/DetailsCard"
import { getTaskById } from "../../../../../shared/selectors/task"
import { ITask } from "../../../../tasks/state/tasksTypes"
import ResponseList from "../../taskList/responses/ResponseList"
import Card from "../../../../../shared/components/layout/Card"
import ButtonExpandCollapse from "../../../../../shared/components/buttons/ButtonExpandCollapse"
import { useTranslation } from "react-i18next"
import DetailsCardRight from "../../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import ContextMenu from "../../../../../shared/components/contextMenu/ContextMenu"
import AssignCasePathwayTaskModal from "./AssignCasePathwayTaskModal"
import CasePathwayTaskDetails from "./CasePathwayTaskDetails"
import PathwayTaskStatusLabel from "./PathwayTaskStatusLabel"
import DeleteCasePathwayTaskModal from "./DeleteCasePathwayTaskModal"
import { ICasePathway, ICasePathwayEditData } from "../../../../casePathways/state/casePathwayTypes"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { updateCasePathwayAction } from "../../../../casePathways/state/casePathwayActions"
import EditCasePathwayTaskModal from "./EditCasePathwayTaskModal"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import AddResponseForm from "../../taskList/responses/AddResponseForm"
import Modal from "react-modal"
import { getCaseById } from "../../../../../shared/selectors/case"

interface Props {
  taskId: string
  casePathwayId: string
}

const CasePathwayTaskListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const task: ITask = useSelector((state: IAppState) => getTaskById(state, props))
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))
  const theCase = useSelector((state: IAppState) => getCaseById(state, { caseId: casePathway.caseId }))
  const [responseListVisible, setResponseListVisible] = useState<boolean>(true)
  const dispatch = useDispatch()
  const { t } = useTranslation(["task", "case"])

  const [assignTaskModalOpen, setAssignTaskModalOpen] = useState<boolean>(false)
  const openAssignTaskModal = () => { setAssignTaskModalOpen(true) }
  const closeAssignTaskModal = () => { setAssignTaskModalOpen(false) }

  const [deleteTask, setDeleteTask] = useState<ITask>(null)
  const openDeleteTaskModal = (task: ITask) => { setDeleteTask(task) }
  const closeDeleteTaskModal = () => { setDeleteTask(null) }

  const [editTaskModalOpen, setEditTaskModalOpen] = useState<boolean>(false)
  const openEditTaskModal = () => { setEditTaskModalOpen(true) }
  const closeEditTaskModal = () => { setEditTaskModalOpen(false) }
  const [addResponseModalOpen, setAddResponseModalOpen] = useState<boolean>(false)
  const openAddResponseModal = () => { setAddResponseModalOpen(true) }
  const closeAddResponseModal = () => { setAddResponseModalOpen(false) }

  if (!task) return null

  const toggleReponsesButton = () => {
    if (!task.taskResponses?.length) return null
    return (<DetailsCardButtons
      className="items-center flex-none"
      buttons={[
        <ButtonExpandCollapse
          key="1"
          isExpanded={responseListVisible}
          action={() => setResponseListVisible(!responseListVisible)}
          expandText={t("showReponses")}
          collapseText={t("hideResponses")} />
      ]}
    />)
  }

  const changeStepPosition = (task: ITask, position: number) => {
    if (position > 0 && position <= casePathway.tasks.length) {
      task.casePathwayPosition = position
      const editData: ICasePathwayEditData = {
        casePathwayTask: task
      }
      task.casePathwayPosition = position
      dispatch(updateCasePathwayAction(casePathway.caseId, props.casePathwayId, editData))
    }
  }

  const renderAddResponseModal = () => {
    return (
      <Modal isOpen={addResponseModalOpen} onRequestClose={closeAddResponseModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeAddResponseModal}>
          <AddResponseForm
            caseId={task.caseId}
            taskId={task.id}
            closeAction={closeAddResponseModal}
          />
        </ModalContainer>
      </Modal>
    )
  }

  return (
    <Card>
      <DetailsCard className="bg-white rounded-md text-sm" key={task.casePathwayPosition + task.id} hasBorder={false}>
        <CasePathwayTaskDetails taskId={props.taskId} />
        <DetailsCardRight>
          <div className="flex flex-col justify-between h-max">
            <div className="flex flex-row items-start justify-end mb-3 mt-3">
              <PathwayTaskStatusLabel task={task} />

              {
                task.permissions?.canContribute && !theCase.locked ?
                  <ContextMenu
                    id={task.id}
                    actions={[
                      {
                        text: t("case:casePathways:buttons:addResponse"),
                        action: openAddResponseModal
                      },
                      {
                        text: t("case:casePathways:buttons:assignTask"),
                        action: openAssignTaskModal
                      },
                      {
                        text: t("task:deleteTask"),
                        action: () => openDeleteTaskModal(task)
                      },
                      {
                        text: t("case:casePathways:buttons:editTask"),
                        action: openEditTaskModal
                      },
                      {
                        text: t("case:casePathways:buttons:moveTaskUp"),
                        action: () => changeStepPosition(task, task.casePathwayPosition -= 1),
                        isDisabled: task.casePathwayPosition == 1
                      },
                      {
                        text: t("case:casePathways:buttons:moveTaskDown"),
                        action: () => changeStepPosition(task, task.casePathwayPosition += 1),
                        isDisabled: task.casePathwayPosition == casePathway.tasks.length
                      },
                    ]}
                  />
                  : null
              }
            </div>
            <div className="flex flex-row items-start justify-end mr-1">
              {toggleReponsesButton()}
            </div>
          </div>
        </DetailsCardRight>
      </DetailsCard>
      <div className="text-sm">
        <div className={responseListVisible ? "" : "hidden"}>
          <ResponseList {...props} />
        </div>
      </div>
      <AssignCasePathwayTaskModal isOpen={assignTaskModalOpen} closeModal={closeAssignTaskModal} casePathwayId={props.casePathwayId} taskId={props.taskId} />
      <DeleteCasePathwayTaskModal isOpen={!!deleteTask} closeModal={closeDeleteTaskModal} casePathwayId={props.casePathwayId} taskId={props.taskId} />
      <EditCasePathwayTaskModal isOpen={editTaskModalOpen} closeModal={closeEditTaskModal} casePathwayId={props.casePathwayId} taskId={props.taskId} />
      {renderAddResponseModal()}
    </Card>
  )
}

export default CasePathwayTaskListItem
