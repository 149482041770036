import React from "react"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import CaseAssetViewer from "./CaseAssetViewer"

interface Props {
  isOpen: boolean
  closeModal: () => void
  selectedAssetId: string
}

const ViewAssetModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <CaseAssetViewer assetId={props.selectedAssetId} />
      </ModalContainer>
    </Modal>
  )
}

export default ViewAssetModal
