import { Form, Formik } from "formik"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FormContext } from "../../../../../shared/contexts/FormContextProvider"
import Card from "../../../../../shared/components/layout/Card"
import { useDispatch, useSelector } from "react-redux"
import { IPatientCaseNewData } from "../../../state/patientEditTypes"
import { fetchAllUsersAsync } from "../../../../users/state/usersActions"
import { fetchCaseClassificationAction } from "../../../../caseClassification/state/caseClassificationActions"
import { useParams } from "react-router"
import { IHavePatientId, IHavePatientIdParam } from "../../../../patient/state/patientTypes"
import PatientCaseList from "../../shared/PatientCaseList"
import CaseFormCard from "../../../../patient/components/formCards/CaseFormCard"
import ConfirmModal from "./ConfirmModal"
import { IAppState } from "../../../../../app/appTypes"
import { getPatientById } from "../../../../../shared/selectors/patients"
import { addCaseForPatientAsync } from "../../../state/patientEditActions"
import SavedPatientDetailsFormCard from "../../../../patient/components/formCards/SavedPatientDetailsFormCard"
import UnsavedChangesPrompt from "../../../../../shared/components/forms/UnsavedChangesPrompt"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientCaseNewData
  setData: (data: IPatientCaseNewData) => void
}

type Props = IPropsFromParent & IHavePatientIdParam

const ReviewAndSaveForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)
  const { patientId } = useParams<IHavePatientId>()
  const patient = useSelector((state: IAppState) => getPatientById(state, { patientId: patientId }))
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    dispatch(fetchCaseClassificationAction())
  }, [])

  const openModal = (): void => {
    setIsModalOpen(true)
  }

  const closeModal = (): void => {
    formikFormRef.current.setSubmitting(false)
    setIsModalOpen(false)
  }

  const handleConfirm = (): void => {
    closeModal()
    addCaseForPatient()
  }

  const addCaseForPatient = (): void => {
    const data: IPatientCaseNewData = props.data
    dispatch(addCaseForPatientAsync(patientId, data))
  }

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full">
        <h4 className="flex mb-2 font-semibold">
          {t("patient:reviewAndSave:sectionTitle")}
        </h4>
        <p className="mb-2">
          {t("patient:reviewAndSave:intro")}
        </p>

        <div className="w-full">
          <Formik
            initialValues={props.data}
            onSubmit={() => {
              if (patient?.cases.length > 0) {
                openModal()
                return
              } else {
                addCaseForPatient()
              }
            }}
            innerRef={formikFormRef}
          >
            {({ submitCount }) => {
              return (
                <Form>
                  <UnsavedChangesPrompt isEnabled={submitCount < 1} />
                  <h4 className="flex mb-2 font-semibold">
                    {t("patient:details:sectionTitle")}
                  </h4>
                  <SavedPatientDetailsFormCard patientId={patientId} />

                  <h4 className="flex mb-2 font-semibold">
                    {t("patient:caseDetails:sectionTitle")}
                  </h4>
                  <CaseFormCard data={props.data} />
                  <PatientCaseList patientId={patientId} />

                  <ConfirmModal
                    handleConfirm={handleConfirm}
                    handleClose={closeModal}
                    isOpen={isModalOpen}
                  />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default ReviewAndSaveForm
