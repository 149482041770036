import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Card from "../../../../shared/components/layout/Card"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getUserById } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"
import { CardObjectTypes, IAuditEvent, IAuditEventData, IDateCardObject } from "../../state/auditEventTypes"
import { AuditEventDurationLabel } from "../details/AuditEventDurationLabel"
import AuditEventSubCard from "./AuditEventSubCard"

interface IProps {
  auditEvent: IAuditEvent
}

const AuditEventMeetingEditedCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])
  const eventData = props.auditEvent.eventData
  const changes = eventData.changes

  const coordinators = useSelector((state: IAppState): IUser[] => {
    if (changes.coordinatorId) {
      return [getUserById(state, changes.coordinatorId[0]), getUserById(state, changes.coordinatorId[1])]
    }
    return null
  })

  const getUserName = (user: IUser): string => {
    return `${user.firstName} ${user.lastName}`
  }

  const auditEventCardDateChangeObject = (eventData: IAuditEventData): IDateCardObject => {
    const startDate = eventData.changes.startDate
    const endDate = eventData.changes.endDate
    const currentStartDate = eventData.data.currentStartDate
    const currentEndDate = eventData.data.currentEndDate

    if (startDate == null) {
      return (
        {
          original: {
            startDate: currentStartDate,
            endDate: endDate[0]
          },
          changed: {
            startDate: CardObjectTypes.noChangeInTime,
            endDate: endDate[1]
          },
          duration: <AuditEventDurationLabel
            startTime={currentStartDate}
            endTime={endDate[1]}
          />
        }
      )
    }
    if (endDate == null) {
      return (
        {
          original: {
            startDate: startDate[0],
            endDate: currentEndDate
          },
          changed: {
            startDate: startDate[1],
            endDate: CardObjectTypes.noChangeInTime
          },
          duration: <AuditEventDurationLabel
            startTime={startDate[1]}
            endTime={currentEndDate}
          />
        }
      )
    }

    return (
      {
        original: {
          startDate: startDate[0],
          endDate: endDate[0]
        },
        changed: {
          startDate: startDate[1],
          endDate: endDate[1]
        },
        duration: <AuditEventDurationLabel
          startTime={startDate[1]}
          endTime={endDate[1]}
        />
      }
    )
  }

  if (!eventData.data) return null
  return (
    <>
      {changes.name ?
        <AuditEventSubCard
          title="titleChanged"
          originalProp={changes.name[0]}
          changedProp={changes.name[1]} />
        : null}

      {changes.specialtyId ?
        <AuditEventSubCard
          title="specialtyChanged"
          originalProp={eventData.data.previousSpecialtyName}
          changedProp={eventData.data.currentSpecialtyName} />
        : null}

      {changes.coordinatorId ?
        <AuditEventSubCard
          title="coordinatorChanged"
          originalProp={getUserName(coordinators[0])}
          changedProp={getUserName(coordinators[1])} />
        : null
      }
      {changes.startDate || changes.endDate ?
        <Card className="mt-2">
          <span className="font-semibold">{t("auditEvent:meetingDateTimeChange")}</span>
          <div className="my-3">
            <span className="font-semibold">{t("auditEvent:original")}</span>:&nbsp;
            <div className="flex">
              <span className="font-semibold">{t("auditEvent:StartTime")}</span>:&nbsp;
              {<TimeDefaultFormat utcTime={auditEventCardDateChangeObject(eventData).original.startDate} />}
            </div>
            <div className="flex">
              <span className="font-semibold">{t("auditEvent:EndTime")}</span>:&nbsp;
              {<TimeDefaultFormat utcTime={auditEventCardDateChangeObject(eventData).original.endDate} />}
            </div>
          </div>
          <div className="mb-3">
            <span className="font-semibold">{t("auditEvent:change")}</span>:&nbsp;
            <div className="flex">
              <span className="font-semibold">{t("auditEvent:StartTime")}</span>:&nbsp;
              {auditEventCardDateChangeObject(eventData).changed.startDate == CardObjectTypes.noChangeInTime ? <span>{t("auditEvent:noChanges")}</span>
                : <TimeDefaultFormat utcTime={auditEventCardDateChangeObject(eventData).changed.startDate} />}
            </div>
            <div className="flex">
              <span className="font-semibold">{t("auditEvent:EndTime")}</span>:&nbsp;
              {auditEventCardDateChangeObject(eventData).changed.endDate == CardObjectTypes.noChangeInTime ? <span>{t("auditEvent:noChanges")}</span>
                : <TimeDefaultFormat utcTime={auditEventCardDateChangeObject(eventData).changed.endDate} />}
            </div>
          </div>
          <div className="flex"><span className="font-semibold self-center">{t("auditEvent:duration")}</span>:&nbsp;
            {auditEventCardDateChangeObject(eventData).duration}</div>
        </Card>
        : null}
    </>
  )
}

export default AuditEventMeetingEditedCard
