import { useFormikContext } from "formik"
import _, { throttle } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getUserCheckUI } from "../../../../shared/selectors/ui"
import { IconAlert } from "../../../../svg/icons"
import InviteExistingGuestUserButton from "../../../case/components/users/guestUsers/InviteExistingGuestUserButton"
import UserListItem from "../../../users/components/list/UserListItem"
import { checkUserAction, resetCheckUserAction } from "../../state/userActions"
import { IUserCheckData, IUserCheckUIState } from "../../state/userTypes"
import AddUserToTenantButton from "./AddUserToTenantButton"

interface Props {
  caseId?: string
  canInviteAsGuest?: boolean
  closeModal?: () => void
}

const ExistingUserWarning: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const dispatch = useDispatch()
  const { values, errors } = useFormikContext<IUserCheckData>()
  const userCheckUI: IUserCheckUIState = useSelector((state: IAppState) => getUserCheckUI(state))

  const throttledUserCheck = useMemo(
    () => {
      const throttleOptions = {
        leading: false,
        trailing: true
      }
      return throttle(
        (userCheckData: IUserCheckData) =>
          dispatch(checkUserAction(userCheckData)), 1000, throttleOptions
      )
    }, [])

  useEffect(() => {
    dispatch(resetCheckUserAction())

    return () => {
      dispatch(resetCheckUserAction())
    }
  }, [])

  useEffect(() => {
    // only run the check if the email is present and valid, or when both first an( last name are present
    if ((values.email && !errors.email) || values.firstName && values.lastName && values.lastName.length > 1) {
      const userCheckData: IUserCheckData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email
      }
      throttledUserCheck(userCheckData)
    } else {
      dispatch(resetCheckUserAction())
    }
  }, [values.firstName, values.lastName, values.email])

  if (!userCheckUI?.existingUserIds?.length) return null

  const inviteButtonForUser = (userId: string) => {
    if (props.caseId) {
      return [
        <InviteExistingGuestUserButton
          key={userId}
          caseId={props.caseId}
          userId={userId}
          closeModal={props.closeModal}
        />
      ]
    }
    return [
      <AddUserToTenantButton
        key={userId}
        userId={userId}
        closeModal={props.closeModal}
      />
    ]
  }

  return (
    <div className="my-5 text-ch-gray-600 mb-3 p-3 rounded-md bg-ch-blue-100">
      <h3 className="flex font-semibold text-ch-blue-alt-500 mb-2">
        <IconAlert className="w-5 h-5 mr-2" /> <span>{t("user:userCheck:messages:existingUsersFound")}</span>
      </h3>
      <p className="mb-2">
        {t("user:userCheck:text:existingUserInfo")}
      </p>
      <ul>
        {userCheckUI?.existingUserIds?.map((userId: string) => {
          return (
            <UserListItem
              key={userId}
              userId={userId}
              buttons={inviteButtonForUser(userId)}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default ExistingUserWarning
