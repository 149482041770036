import { normalize } from "normalizr"
import { takeLeading, put, call } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { casePathwaySchema, tasksSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { CasePathwaysActionTypes, ICreateCasePathwayAsyncAction, IFetchCasePathwaysAsyncAction, IUpdateCasePathwayAsyncAction, IUpdateCasePathwayTaskAsyncAction } from "../casePathwayTypes"

export function* fetchCasePathwaysAsync(action: IFetchCasePathwaysAsyncAction): Generator {
  try {
    const caseId = action.payload
    const response: any = yield call(API.get, `/cases/${caseId}/case_pathways`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [casePathwaySchema])
    yield put({
      type: CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })
  } catch (error) {
    yield put({
      type: CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchCasePathwaysAsync(): Generator {
  yield takeLeading(CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_PENDING, fetchCasePathwaysAsync)
}

export function* createCasePathwayAsync(action: ICreateCasePathwayAsyncAction): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      casePathway: {
        pathwayId: action.payload.pathwayId
      }
    }

    const response: any = yield call(API.post, `/cases/${caseId}/case_pathways`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, casePathwaySchema)
    yield put({
      type: CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createCasePathwaySuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createCasePathwayFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateCasePathwayAsync(): Generator {
  yield takeLeading(CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_PENDING, createCasePathwayAsync)
}

export function* updateCasePathwayAsync(action: IUpdateCasePathwayAsyncAction): Generator {
  try {
    const caseId = action.payload.caseId
    const casePathwayId = action.payload.casePathwayId
    const data = {
      casePathway: action.payload.casePathway
    }
    const response: any = yield call(API.put, `/cases/${caseId}/case_pathways/${casePathwayId}`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, casePathwaySchema)

    yield put({
      type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCasePathwaySuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCasePathwayFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateCasePathwayAsync(): Generator {
  yield takeLeading(CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_PENDING, updateCasePathwayAsync)
}

export function* updateCasePathwayTaskAsync(action: IUpdateCasePathwayTaskAsyncAction): Generator {
  try {
    const taskId = action.payload.taskId
    const caseId = action.payload.caseId
    const data = {
      task: action.payload.task
    }
    const response: any = yield call(API.put, `/cases/${caseId}/tasks/${taskId}`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, tasksSchema)

    yield put({
      type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_TASK_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCasePathwayTaskSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_TASK_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateCasePathwayTaskFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateCasePathwayTaskAsync(): Generator {
  yield takeLeading(CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_TASK_ASYNC_PENDING, updateCasePathwayTaskAsync)
}
