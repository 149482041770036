import React, { useState } from "react"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import ListBackground from "../../../../../shared/components/layout/ListBackground"
import { getAllCaseTaskIds } from "../../../../../shared/selectors/task"
import TaskListItem from "./TaskListItem"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import AddResponseForm from "../responses/AddResponseForm"
import TaskFilter from "./TaskFilter"
import { ICaseTaskFilters } from "../../../../tasks/state/tasksTypes"

interface IProps {
  caseId: string;
  openAssetModal: (assetId: string) => void
}


const CaseTaskList = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["case"])
  const [isTaskResponseModalOpen, setTaskResponseModalIsOpen] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState(null)
  const [taskFilters, setTaskFilters] = useState<ICaseTaskFilters>(null)
  const taskIds = useSelector((state: IAppState) => getAllCaseTaskIds(state, props))

  const openTaskModal = (selectedTaskId: string): void => {
    setSelectedTaskId(selectedTaskId)
    setTaskResponseModalIsOpen(true)
  }

  const closeTaskModal = (): void => {
    setTaskResponseModalIsOpen(false)
  }

  const renderTaskModal = () => {
    return (
      <Modal isOpen={isTaskResponseModalOpen} onRequestClose={closeTaskModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeTaskModal}>
          <AddResponseForm
            caseId={props.caseId}
            taskId={selectedTaskId}
            closeAction={closeTaskModal}
          />
        </ModalContainer>
      </Modal>
    )
  }

  const renderContent = (): JSX.Element => {
    if (!taskIds || taskIds.length == 0) return (
      <div>
        <h3 className="text-center text-ch-blue-alt-400">
          {t("case:noTasks")}
        </h3>
        <ListBackground />
      </div>
    )

    return (
      <div>
        <TaskFilter
          setFilters={setTaskFilters}
          initialValues={{
            showOutstanding: true,
            showAll: false,
            showCompleted: false
          }}
        />
        {renderList()}
        {renderTaskModal()}
      </div>
    )
  }

  const renderList = (): JSX.Element => {
    return (
      <ul>
        {taskIds.map((taskId: string) => {
          return (
            <TaskListItem
              key={taskId}
              taskId={taskId}
              addResponseAction={() => openTaskModal(taskId)}
              taskFilters={taskFilters}
              openAssetModal={props.openAssetModal}
            />
          )
        })}
      </ul>
    )
  }

  return renderContent()
}

export default CaseTaskList
