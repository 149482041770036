import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAssetMenu({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M2 11.5h2.5c.688 0 1.25-.563 1.25-1.25v-2.5c0-.688-.563-1.25-1.25-1.25H2c-.688 0-1.25.563-1.25 1.25v2.5c0 .688.563 1.25 1.25 1.25zm0 6.25h2.5c.688 0 1.25-.563 1.25-1.25V14c0-.688-.563-1.25-1.25-1.25H2c-.688 0-1.25.563-1.25 1.25v2.5c0 .688.563 1.25 1.25 1.25zm0-12.5h2.5c.688 0 1.25-.563 1.25-1.25V1.5C5.75.812 5.187.25 4.5.25H2C1.312.25.75.813.75 1.5V4c0 .688.563 1.25 1.25 1.25zm6.25 6.25h12.5c.688 0 1.25-.563 1.25-1.25v-2.5c0-.688-.563-1.25-1.25-1.25H8.25C7.562 6.5 7 7.063 7 7.75v2.5c0 .688.563 1.25 1.25 1.25zm0 6.25h12.5c.688 0 1.25-.563 1.25-1.25V14c0-.688-.563-1.25-1.25-1.25H8.25C7.562 12.75 7 13.313 7 14v2.5c0 .688.563 1.25 1.25 1.25zM7 1.5V4c0 .688.563 1.25 1.25 1.25h12.5C21.438 5.25 22 4.687 22 4V1.5c0-.688-.563-1.25-1.25-1.25H8.25C7.562.25 7 .813 7 1.5z"
        />
        <path d="M-3-6h30v30H-3z" />
      </g>
    </svg>
  );
}

export default SvgIconAssetMenu;
