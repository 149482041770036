import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconTeam({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.295 13.972l-.278.005a5.785 5.785 0 00-4.264 1.632l-.15.17v3.22l.01.125a.96.96 0 00.279.542c.18.174.42.27.67.265h6.89l.124-.006a.938.938 0 00.548-.26.953.953 0 00.288-.665v-3.21l-.144-.181-.204-.19a5.717 5.717 0 00-4.047-1.442zm4.068-2.53l-.269.002c-.218 0-.436.013-.653.037a3.977 3.977 0 01-1.2 1.48c1.144.32 2.18.946 2.999 1.814l.15.169.323.375v1.638h1.363l.116-.007a1 1 0 00.884-.993v-2.82l-.126-.17-.196-.186a5.174 5.174 0 00-3.66-1.337zm-8.801.018l-.268.01a5.18 5.18 0 00-3.319 1.516l-.144.169v2.796l.007.116a1 1 0 00.993.884h1.447v-1.632l.33-.375.15-.175.217-.215a6.584 6.584 0 012.607-1.514 3.979 3.979 0 01-1.23-1.578h-.521l-.27-.002zm4.31-4.325c-1.48 0-2.68 1.21-2.68 2.701a2.69 2.69 0 002.68 2.702c1.481 0 2.681-1.21 2.681-2.702s-1.2-2.701-2.68-2.701zm-2.008-.653a2.442 2.442 0 00-2.977-.936 2.476 2.476 0 00-1.482 2.765 2.458 2.458 0 002.408 1.997v-.024H8l-.019-.208a3.907 3.907 0 011.884-3.594zm7.093-1.003a2.443 2.443 0 00-2.926 1.063 3.955 3.955 0 011.775 3.288c.008.14.008.279 0 .417.115.019.232.029.348.03h.114l.165-.014a2.462 2.462 0 002.132-2.1 2.474 2.474 0 00-1.608-2.684z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconTeam;
