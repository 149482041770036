import { ICreatedBy } from "../../../shared/state/sharedTypes"

export interface IAuditEvent {
  id: string
  entityType: string
  entityId: string
  eventType: string
  eventData: IAuditEventData
  createdBy?: ICreatedBy
  createdAt: string
  createdById?: string
  updatedAt?: string
}

export interface IAuditEventData {
  changes: IAuditEventChanges
  data: IAuditEventDataContent
  subjectId?: string
}

export interface IAuditEventDataContent {
  name: string,
  fileType: string
  userId?: string
  meetingCaseId?: string
  [key: string]: string
}

export type IAuditEventChanges = {
  [key: string]: string[] | string
}

export interface IFetchAuditEventsAsyncAction {
  type: string
  payload: {
    entityType: string,
    entityId: string
  }
}

export interface IDateCardObject {
  original: {
    startDate: string,
    endDate: string,
  },
  changed: {
    startDate: string,
    endDate: string
  },
  duration: JSX.Element
}

export interface IAuditEventCardData {
  iconContent: IAuditEventIconContent,
  headerUserId?: string,
  byUserId?: string,
  userName?: string,
  body?: JSX.Element,
  hideCard?: boolean,
  headerText?: string,
  meetingId?: string,
}

export interface IAuditEventIconContent {
  iconName: string,
  color?: string
}

export enum AuditEventsActionTypes {
  FETCH_AUDIT_EVENTS_ASYNC_PENDING = "@@assets/FETCH_AUDIT_EVENTS_ASYNC_PENDING",
  FETCH_AUDIT_EVENTS_ASYNC_SUCCESS = "@@assets/FETCH_AUDIT_EVENTS_ASYNC_SUCCESS",
  FETCH_AUDIT_EVENTS_ASYNC_ERROR = "@@assets/FETCH_AUDIT_EVENTS_ASYNC_ERROR"
}

// types of component rendered depending on audit-asset properties
export enum AuditAssetComponentTypes {
  commentAdd = "comment_added",
  commentRemove = "comment_removed",
  decision = "decision_added",
  deferral = "deferral",
  recommendationAdded = "recommendation_added",
  recommendationApproved = "recommendation_approved",
  recommendationRejected = "recommendation_rejected",
  recommendationSaved = "recommendation_saved",
  recommendationUpdated = "recommendation_updated",
  caseReportGenerated = "case_report_generated",
  documentAdded = "document_added",
  documentRemoved = "document_removed",
  linkAdded = "link_added",
  linkRemoved = "link_removed",
  commentSelectedToMeetingAssets = "comment_selected_to_meeting_assets",
  commentRemovedFromMeetingAssets = "comment_removed_from_meeting_assets",
  decisionSelectedToMeetingAssets = "decision_selected_to_meeting_assets",
  decisionRemovedFromMeetingAssets = "decision_removed_from_meeting_assets",
  recommendationSelectedToMeetingAssets = "recommendation_selected_to_meeting_assets",
  recommendationRemovedFromMeetingAssets = "recommendation_removed_from_meeting_assets",
  casereportSelectedToMeetingAssets = "casereport_selected_to_meeting_assets",
  casereportRemovedFromMeetingAssets = "casereport_removed_from_meeting_assets",
  imageSelectedToMeetingAssets = "image_selected_to_meeting_assets",
  imageRemovedFromMeetingAssets = "image_removed_from_meeting_assets",
  pdfSelectedToMeetingAssets = "pdf_selected_to_meeting_assets",
  pdfRemovedFromMeetingAssets = "pdf_removed_from_meeting_assets",
  linkSelectedToMeetingAssets = "link_selected_to_meeting_assets",
  linkRemovedFromMeetingAssets = "link_removed_from_meeting_assets",

  meetingCreated = "meeting_created",
  meetingUserAdded = "meeting_user_added",
  meetingUserRemoved = "meeting_user_removed",
  meetingCaseAdded = "meeting_case_added",
  meetingCaseRemoved = "meeting_case_removed",
  meetingedited = "meeting_edited",
  meetingInProgress = "meeting_in_progress",
  meetingUserJoined = "meeting_user_joined",
  meetingUserLeft = "meeting_user_left",
  meetingCoordinatorAssigned = "meeting_coordinator_assigned",
  meetingPresenterAssigned = "meeting_presenter_assigned",
  meetingScribeAssigned = "meeting_scribe_assigned",
  meetingPresenterAccepted = "meeting_presenter_accepted",
  meetingScribeAccepted = "meeting_scribe_accepted",
  meetingSelectedCase = "meeting_selected_case",
  meetingRecommendationPending = "meeting_recommendation_pending",
  meetingConsent = "meeting_consent",
  meetingConsentOnBehalf = "meeting_consent_on_behalf",
  meetingRecommendationSaved = "meeting_recommendation_saved",
  meetingRecommendationDeferred = "meeting_recommendation_deferred",
  meetingEnded = "meeting_ended",
  meetingCancelled = "meeting_cancelled",
  meetingCaseDeferred = "meeting_case_deferred",

  caseCreated = "case_created",
  caseMemberAdded = "case_member_added",
  caseMemberRemoved = "case_member_removed",
  caseUpdated = "case_updated",
  caseLocked = "case_locked",
  caseUnlocked = "case_unlocked",
  deferralAdded = "deferral_added",

  userEdited = "user_edited",
  userRemoveSpecialty = "user_remove_specialty",
  userAddSpecialty = "user_add_specialty",
  userCreated = "user_created",
  userStatus = "user_status",
  userActivated = "user_activated"
}

export enum AuditEventDocumentTypes {
  PdfFile = "application/pdf",
  ImagePng = "image/png",
  ImageJpeg = "image/jpeg"
}

export enum AuditEventTypes {
  MEETING_CASE = "MeetingCase",
  MEETING = "Meeting",
  CASE = "Case"
}

export enum AuditEventsWithTimeOnly {
  meeting_user_joined = "meeting_user_joined",
  meeting_user_left = "meeting_user_left",
}

export enum CardIconStyle {
  purpleColor = "text-ch-purple-500",
  orangeColor = "text-ch-orange-500",
  grayColor = "text-ch-gray-500",
  blueColor = "text-ch-blue-500",
  redColor = "text-ch-red-500",
  greenColor = "text-ch-green-500",
  imageStyle = "mr-2 mt-1 w-5 h-5"
}

export enum CardObjectTypes {
  noChangeInTime = "noChange"
}
