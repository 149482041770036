import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconPatient } from "../../../../svg/icons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import PatientDetails from "../../../patient/components/details/PatientDetails"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getPatientById } from "../../../../shared/selectors/patients"

interface IProps {
  patientId: string
}

const PatientListItem: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "patient"])
  const patient = useSelector((state: IAppState) => getPatientById(state, props))
  if (!patient) return null

  const patientDetailsUrl = generatePath(RoutePaths.PATIENT, {
    patientId: props.patientId
  })

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconPatient className="fill-current w-6 h-6" title={t("shared:patient")} />
          </IconCircleContainer>
          <PatientDetails patientId={props.patientId} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            <Button
              href={patientDetailsUrl}
              variant={StyleVariants.BLUE}
              key="1"
            >
              {t("shared:viewDetails")}
            </Button>
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default PatientListItem
