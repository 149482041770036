import React from "react"
import { ITenant } from "../../../user/state/userTypes"
import SwitchTenantListItem from "./SwitchTenantListItem"
import { IExternalUser } from "../../../externalUser/state/externalUserTypes"

interface Props {
  externalUser: IExternalUser
  setTenant: (tenantId: string) => void
}

const SwitchTenantListGroup: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <div>
      {props.externalUser.tenants.map((tenant: ITenant) => (
        <SwitchTenantListItem
          key={tenant.id}
          tenant={tenant}
          setTenant={props.setTenant}
          location={props.externalUser.location}
          email={props.externalUser.email}
        />
      ))}
    </div>
  )
}

export default SwitchTenantListGroup
