import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IUploadCaseFileFormValues } from "../../assets/state/assetsTypes"

export interface IHaveTaskResponseId {
  taskResponseId: string
}

export interface ITaskResponse {
  id: string
  response: string
  asset?: string
  caseId: string
  createdAt: string
  createdBy: ICreatedBy
}

interface ICaseAssetAttributes {
  assetType: string
  assetAttributes: IUploadCaseFileFormValues
}

export interface ITaskResponseNewData {
  response: string
  markedTaskAsComplete: boolean
  caseAssetAttributes: ICaseAssetAttributes
}

export interface ICreateCaseTaskResponseAsyncAction {
  type: string,
  payload: {
    caseId: string,
    taskId: string
    taskResponse: ITaskResponseNewData
  }
}

export enum TaskResponseActionTypes {
  CREATE_CASE_TASK_RESPONSE_ASYNC_PENDING = "@@taskResponses/CREATE_CASE_TASK_RESPONSE_ASYNC_PENDING",
  CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS = "@@taskResponses/CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS",
  CREATE_CASE_TASK_RESPONSE_ASYNC_ERROR = "@@taskResponses/CREATE_CASE_TASK_RESPONSE_ASYNC_ERROR",
}
