import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import { getCaseById, getMeetingsForCase } from "../../../../shared/selectors/case"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants, dialogueModalStyle } from "../../../../shared/state/sharedTypes"
import { toggleCaseLocked } from "../../state/caseActions"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { ICaseLockedData } from "../../state/caseTypes"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import _ from "lodash"

interface IProps {
  caseId: string
}

const LockCaseButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  const [caseHasScheduledMeetings, setCaseHasScheduledMeetings] = React.useState(false)
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const caseMeetings = useSelector((state: IAppState) => getMeetingsForCase(state, props))
  const { t } = useTranslation(["case"])
  const toggleLocked = (caseData: ICaseLockedData) => dispatch(toggleCaseLocked(caseData))

  useEffect(() => {
    if (!theCase || !caseMeetings) return
    // TODO: remove filtering here and only return meeting options from
    // the api that should be visible
    const nonEndedCaseMeetings = _.filter(caseMeetings, (meeting) => {
      // if the meeting is not available, we'll have to assume it is not ended
      if (!meeting || !meeting?.status) return true

      return meeting.status !== MeetingStatuses.Ended && meeting.status !== MeetingStatuses.Cancelled
    })
    setCaseHasScheduledMeetings(nonEndedCaseMeetings.length > 0 ? true : false)
  }, [caseMeetings])

  const renderCaseLockedMessage = (): JSX.Element => {
    if (!theCase.locked) return null
    return (
      <div className="text-ch-gray-alt-500">
        {t("case:caseIsLocked")}
      </div>
    )
  }

  const openConfirm = (): void => {
    setConfirmIsOpen(true)
  }

  const closeConfirm = (): void => {
    setConfirmIsOpen(false)
  }

  const confirmModalText = (): string => {
    if (theCase.locked) {
      return t("case:unlockCaseDesc")
    }
    else if (caseHasScheduledMeetings) {
      return t("case:cannotLockCaseWithScheduledMeetings")
    }
    else return t("case:lockCaseDesc")
  }

  const toggleLockHandler = () => {
    const caseData = {
      id: props.caseId,
      locked: !theCase.locked
    }
    toggleLocked(caseData)
    closeConfirm()
  }


  if (theCase.permissions?.canEdit) {
    return (
      <div>
        <Button
          action={openConfirm}
          variant={StyleVariants.PURPLE}
          key="1"
        >
          {theCase.locked ? t("case:unlockCase") : t("case:lockCase")}
        </Button>
        {renderCaseLockedMessage()}
        <Modal isOpen={confirmIsOpen} onRequestClose={closeConfirm} style={dialogueModalStyle} overlayClassName="ReactModal__Overlay flex justify-center">
          <ModalContainer handleClose={closeConfirm}>
            <h2 className="text-lg mb-3">
              {theCase.locked ? t("case:unlockCaseTitle") : t("case:lockCaseTitle")}
            </h2>

            <p className="text-ch-blue-600 mb-3">{confirmModalText()}</p>

            <div className="flex justify-center space-x-2">
              <Button
                action={closeConfirm}
                variant={StyleVariants.BLUE_ALT}
                key="1"
              >
                {t("shared:cancel")}
              </Button>
              <Button
                isDisabled={caseHasScheduledMeetings}
                action={() => toggleLockHandler()}
                variant={StyleVariants.PURPLE}
                key="2"
              >
                {theCase.locked ? t("case:unlockCase") : t("case:lockCase")}
              </Button>
            </div>
          </ModalContainer>
        </Modal>
      </div>

    )
  }
  else return renderCaseLockedMessage()
}

export default LockCaseButton
