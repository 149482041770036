import OT from "@opentok/client"

// User Stream
export const userStreamPublisherOptions: OT.PublisherProperties = {
  insertMode: "append",
  resolution: "320x240",
  frameRate: 7,
  width: "100%",
  height: "100%",
  fitMode: "cover",
  audioBitrate: 28000,
  publishAudio: false,
  publishVideo: false,
  showControls: false,
}

export const userStreamSubscriberOptions: OT.SubscriberProperties = {
  insertMode: "append",
  preferredResolution: {
    width: 320,
    height: 240
  },
  preferredFrameRate: 7,
  width: "100%",
  height: "100%",
  fitMode: "cover",
  showControls: false
}

export const currentUserStreamSubscriberOptions: OT.SubscriberProperties = {
  ...userStreamSubscriberOptions,
  subscribeToAudio: false
}

// Screen Share Stream
export const screenshareStreamPublisherOptions: OT.PublisherProperties = {
  insertMode: "append",
  resolution: "1280x720",
  frameRate: 15,
  width: "100%",
  height: "100%",
  fitMode: "contain",
  videoSource: "window",
  publishVideo: true,
  publishAudio: false,
  showControls: false
}

export const screenshareStreamSubscriberOptions: OT.SubscriberProperties = {
  insertMode: "append",
  preferredResolution: {
    width: 1280,
    height: 720
  },
  preferredFrameRate: 15,
  width: "100%",
  height: "100%",
  fitMode: "contain"
}
