import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, IDeletePathwayStepAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* deletePathwayStepAsync(action: IDeletePathwayStepAction): Generator {
  try {
    const pathwayId = action.payload.pathwayId
    const stepId = action.payload.stepId

    const response: any = yield call(API.delete, `/pathways/${pathwayId}/pathway_steps/${stepId}`, withAuthHeader())
    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({

      type: PathwayActionTypes.DELETE_PATHWAY_STEP_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "deletePathwayStepSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: PathwayActionTypes.DELETE_PATHWAY_STEP_ASYNC_ERROR,
    })

    yield put(displayUserMessageAction({
      messageKey: "deletePathwayStepFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDeletePathwayStepAsync(): Generator {
  yield takeLeading(PathwayActionTypes.DELETE_PATHWAY_STEP_ASYNC_PENDING, deletePathwayStepAsync)
}
