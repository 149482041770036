import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconDeferral({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M17.776.04a1.63 1.63 0 011.951 1.232l1.62 7.163a1.632 1.632 0 01-1.232 1.951L12.978 12a1.632 1.632 0 01-.72-3.183l3.17-.717a6.593 6.593 0 00-2.58-.532 6.592 6.592 0 00-6.585 6.584 6.592 6.592 0 006.585 6.585 1.632 1.632 0 110 3.263C7.418 24 3 19.582 3 14.152c0-5.43 4.418-9.848 9.848-9.848a9.87 9.87 0 014.46 1.07l-.764-3.382A1.631 1.631 0 0117.776.041zm-.453 19.29a1.652 1.652 0 110 3.303 1.652 1.652 0 010-3.304zm2.963-3.585a1.645 1.645 0 110 3.29 1.645 1.645 0 010-3.29z"
        />
      </g>
    </svg>
  );
}

export default SvgIconDeferral;
