import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getAddCaseToMeetingUI } from "../../../shared/selectors/ui"
import AddCaseToMeetingCaseListItem from "./AddCaseToMeetingCaseListItem"

interface IProps {
  meetingId: string
}

const AddCaseToMeetingCaseList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["case"])
  const caseIds = useSelector(getAddCaseToMeetingUI).resultIds

  if (!caseIds) return (
    <h3 className="text-lg font-bold text-center">
      {t("case:noCases")}
    </h3>
  )

  return (
    <ul>
      {caseIds.map((caseId: string) => {
        return (
          <AddCaseToMeetingCaseListItem
            key={caseId}
            caseId={caseId}
            meetingId={props.meetingId}
          />
        )
      })}
    </ul>
  )
}

export default AddCaseToMeetingCaseList
