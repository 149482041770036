import { takeLeading, put, call } from "redux-saga/effects"
import { WebRTCActionTypes } from "../webRTCTypes"
import { publishToSession, unpublishFromSession } from "../../../../apis/webRTC/webRTC"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"

export function* publishToWebRTCSessionAsync(action) {
  try {
    const publisher: OT.Publisher = yield call(publishToSession, action.payload.session, action.payload.containerRef, action.payload.options, action.payload.eventHandlers)

    yield put({
      type: WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_SUCCESS,
      payload: publisher
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "publishToWebRTCSessionError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchPublishToWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_PENDING, publishToWebRTCSessionAsync)
}

export function* unpublishFromWebRTCSession(action) {
  try {
    yield call(unpublishFromSession, action.payload.session, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_SUCCESS
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "unpublishFromWebRTCSessionError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUnpublishFromWebRTCSession() {
  yield takeLeading(WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_PENDING, unpublishFromWebRTCSession)
}
