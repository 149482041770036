import { Reducer, AnyAction } from "redux"
import { IUserMessageState, UserMessageActionTypes } from "../userMessageTypes"

export const initialState: IUserMessageState = {
  messageKey: null,
  type: null,
  isRead: false
}

export const userMessageReducer: Reducer<IUserMessageState> = (state: IUserMessageState = initialState, action: AnyAction): IUserMessageState => {
  switch (action.type) {
    case UserMessageActionTypes.DISPLAY_USER_MESSAGE:
      return {
        ...state,
        ...action.payload,
        isRead: false
      }
    case UserMessageActionTypes.MARK_USER_MESSAGE_READ:
      return initialState
    default:
      return { ...state }
  }
}
