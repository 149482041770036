import Modal from "react-modal"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Button from "../../../shared/components/buttons/Button"
import { dialogueModalStyle, StyleVariants } from "../../../shared/state/sharedTypes"
import ModalContainer from "../../../shared/components/modals/ModalContainer"

interface IPropsFromParent {
  modalIsOpen: boolean
  closeModal: () => void
  handleUserUpdate: () => void
  oldEmail: string
  newEmail: string
}

type IProps = IPropsFromParent
const EmailConfirmationModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])

  return(
    <Modal isOpen={props.modalIsOpen} onRequestClose={props.closeModal} style={dialogueModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3 text-ch-blue-600">{t("user:emailChangeConfirmation")}</h2>
        <div className="mb-3">
          <p>
            <Trans
              i18nKey="user:userEmailChangeDesc1"
              components={{
                strong: <strong />
              }}
              values={{
                oldEmail: props.oldEmail
              }}
            />
          </p>
        </div>
        <div className="mb-3">
          <p>
            <Trans
              i18nKey="user:userEmailChangeDesc2"
              components={{
                strong: <strong />
              }}
              values={{
                newEmail: props.newEmail
              }}
            />
          </p>
        </div>
        <div className="mb-3">
          <p>{t("user:doYouWantToContinue")}</p>
        </div>

        <div className="flex justify-center space-x-2">
          <Button
            action={() => props.closeModal()}
            variant={StyleVariants.BLUE_ALT}
            key="3"
          >
            <p className="uppercase">{t("shared:no")}</p>
          </Button>
          <Button
            action={() => props.handleUserUpdate()}
            variant={StyleVariants.PURPLE}
            key="4"
            isSubmit={true}
          >
            <p className="uppercase">{t("shared:yes")}</p>
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default EmailConfirmationModal
