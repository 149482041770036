import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import SelectField from "../../shared/components/forms/SelectField"
import countryCodeOptions from "./countryCodeOptions"
import InputField from "../../shared/components/forms/InputField"
import Label from "../../shared/components/forms/Label"
import { useTranslation } from "react-i18next"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useSelector } from "react-redux"
import { IPhoneDetails } from "../../features/account/state/accountTypes"
import { getCurrentUser } from "../../shared/selectors/user"
import i18n from "../../app/i18n"
import FieldError from "../../shared/components/forms/FieldError"

interface IPropsFromParent {
  totpAction: () => void
  smsAction: (phoneDetails: IPhoneDetails) => void
  phoneDetails: IPhoneDetails
}

const phoneDetailsValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    phoneNumber: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .matches(/^[0-9- ]+$/, i18n.t("account:mfaPhoneNumberFormatError"))
      .min(7)
      .max(15),
    countryCode: Yup.string()
      .required(i18n.t("shared:required"))
  })
}

const SetupMfaPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["account"])
  const currentUser = useSelector(getCurrentUser)

  if (!currentUser) return null

  const initialValues: IPhoneDetails = props.phoneDetails || {
    countryCode: currentUser.phoneCountryCode || "44",
    phoneNumber: currentUser.phoneNumber
  }

  return (
    <div>
      <div className="flex flex-col items-center mb-5">
        <div className="text-ch-blue-500 text-xl mb-3">
          {t("mfaSetupHeader")}
        </div>
        <div>
          {t("mfaSetupText1")}
        </div>

        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            onSubmit={props.smsAction}
            validationSchema={phoneDetailsValidationSchema}
            validateOnMount
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              errors,
              touched,
              isValid
            }) => (
              <Form>
                <div className="flex flex-col items-center space-x-2">
                  <div className="flex flex-wrap justify-center space-x-2">
                    <div className="w-full md:w-64">
                      <Label name="countryCode">
                        {t("countryCode")}
                      </Label>
                      <SelectField name="countryCode" options={countryCodeOptions} onChange={handleChange} onBlur={handleBlur} />
                      <FieldError errorMessage={errors.countryCode as string} isVisible={(errors.countryCode && touched.countryCode) as boolean} />
                    </div>

                    <div className="w-full md:w-64">
                      <Label name="phoneNumber">
                        {t("phoneNumber")}
                      </Label>
                      <InputField value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
                      <FieldError errorMessage={errors.phoneNumber as string} isVisible={(errors.phoneNumber && touched.phoneNumber) as boolean} />
                    </div>
                  </div>
                  <Button
                    key="1"
                    isDisabled={!isValid || isSubmitting}
                    variant={StyleVariants.BLUE}
                    className="min-w-48 mt-3"
                    isSubmit
                  >
                    {t("mfaRequestSms")}
                  </Button>
                </div>
                <div className="flex flex-col items-center mt-3">
                  <div className="flex-row my-3">
                    {t("mfaSetupText2")}
                  </div>
                  <Button
                    key="2"
                    isDisabled={isSubmitting}
                    variant={StyleVariants.BLUE}
                    action={() => props.totpAction()}
                  >
                    {t("mfaSetupTotp")}
                  </Button>
                </div>
              </Form>
            )
            }
          </Formik>
        </div>
      </div >
    </div>
  )
}

export default SetupMfaPage
