import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IPathway } from "../../pathway/pathwayTypes"
import { getPathway } from "../../../shared/selectors/pathway"
import Button from "../../../shared/components/buttons/Button"
import { createCasePathwayAsync } from "../../casePathways/state/casePathwayActions"
import { StyleVariants } from "../../../shared/state/sharedTypes"

type Props = {
  pathwayId: string
  caseId: string
  closeModal: () => void
}

export const AddPathwayToCaseButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector(getPathway(props))
  const dispatch = useDispatch()
  const addPathwayToCase = (caseId: string, pathwayId: string) => dispatch(createCasePathwayAsync(caseId, pathwayId))

  if (!pathway) return null

  const onClick = () => {
    addPathwayToCase(props.caseId, props.pathwayId)
    props.closeModal()
  }

  return (
    <Button
      action={onClick}
      variant={StyleVariants.ORANGE}
    >
      {t("pathway:addPathwayToCase:buttons:selectPathway")}
    </Button>
  )
}

export default AddPathwayToCaseButton
