import React, { useEffect } from "react"
import { getChatById } from "../../../../shared/selectors/message"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { fetchChatAsync } from "../../../chat/state/chatActions"
import { IChat } from "../../../chat/state/chatTypes"
import classNames from "classnames"
import { IconClose, IconMessage } from "../../../../svg/icons"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import MessageList from "../../../messages/components/list/MessageList"
import MessageNewForm from "../../../message/components/MessageNewForm"

interface Props {
  chatId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ChatView: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const dispatch = useDispatch()
  const fetchChat = (chatId: string) => dispatch(fetchChatAsync(chatId))
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  useEffect(() => {
    if (!props.chatId) return
    fetchChat(props.chatId)
  }, [props.chatId])

  const renderMessageList = () => {
    if (!chat) return <LoadingSpinner />
    return (
      <MessageList chatId={props.chatId} />
    )
  }

  const renderCloseButton = () => {
    return (
      <a onClick={() => props.setIsOpen(false)} className="text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer">
        <IconClose className="fill-current w-6 h-6" title={t("shared:close")} />
      </a>
    )
  }

  return (
    <div className={classNames(
      "hidden",
      "bg-white",
      "shadow",
      "h-full",
      "w-1/3",
      "overflow-y-scroll",
      "absolute",
      "z-30",
      "top-0",
      "right-0",
      {
        "md:block": props.isOpen,
      }
    )}>
      <div className="flex flex-1 flex-col h-full">
        <div className="flex justify-center p-4 border-b border-ch-gray-400 relative">
          <div className="absolute left-4">
            {renderCloseButton()}
          </div>
          <h2 className="flex items-center">
            <IconMessage className="fill-current w-4 h-4 text-ch-blue-alt-500 mr-2" title={t("message:meeting:buttons:openChat")} />
            <span className="text-sm font-semibold">{t("message:meeting:title")}</span>
          </h2>
        </div>
        <div className="flex-1 h-full overflow-hidden">
          {renderMessageList()}
        </div>
        <div className="p-4 border-t border-ch-gray-400">
          <MessageNewForm chatId={props.chatId} />
        </div>
      </div>
    </div>
  )
}

export default ChatView
