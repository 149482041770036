import { IAppState } from "../../../app/appTypes"
import { IHavePatientId, IHavePatientIdParam, IPatient } from "../../../features/patient/state/patientTypes"

export const getAllPatients = (state: IAppState): { [id: string]: IPatient } => state.entities.patients?.byId

export const getAllPatientIds = (state: IAppState): string[] => state.entities.patients?.allIds

export const getPatientById = (state: IAppState, ownProps: IHavePatientId): IPatient =>  state.entities?.patients?.byId[ownProps.patientId]

export const getPatientByParamId = (state: IAppState, ownProps: IHavePatientIdParam): IPatient => {
  const patientId = ownProps.match.params.patientId
  return state.entities.patients.byId[patientId]
}
