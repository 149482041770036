import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { ITaskResponse, TaskResponseActionTypes } from "../taskResponseTypes"
import { TasksActionTypes } from "../../../tasks/state/tasksTypes"

type TByIdState = { [id: string]: ITaskResponse }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
    case TasksActionTypes.FETCH_CASE_TASKS_ASYNC_SUCCESS:
    case TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.taskResponses || []
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    default:
      return state
  }
}

export const taskResponseEntityReducer: Reducer<INormalizedEntities<ITaskResponse>> = combineReducers({
  byId,
  allIds
})
