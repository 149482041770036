import { AssetsActionTypes, ICommentNewData, ICreateCaseCommentAsyncAction, IFetchCaseAssetsAsyncAction, ILinkNewData, IUploadCaseFileAsyncAction, IUploadCaseFileFormValues, IDeleteAssetAsyncAction, ICreateCaseLinkAsyncAction, IRecommendationNewData, ICreateCaseRecommendationAsyncAction, IAddConsentAction, ISaveRecommendationAction, IDicomLinkNewData, ICreateCaseDicomLinkAsyncAction } from "./assetsTypes"
import { ConsentResponse } from "../../liveMeeting/state/liveMeetingTypes"

export const fetchCaseAssetsAction = (caseId: string): IFetchCaseAssetsAsyncAction => {
  return {
    type: AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_PENDING,
    payload: caseId
  }
}

export const createCaseCommentAction = (caseId: string, comment: ICommentNewData, meetingCaseId?: string): ICreateCaseCommentAsyncAction => {
  return {
    type: AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_PENDING,
    payload: {
      caseId,
      comment,
      meetingCaseId
    }
  }
}

export const createCaseRecommendationAction = (caseId: string, recommendation: IRecommendationNewData, meetingCaseId?: string): ICreateCaseRecommendationAsyncAction => {
  return {
    type: AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_PENDING,
    payload: {
      caseId,
      recommendation,
      meetingCaseId
    }
  }
}

export const uploadCaseFileAction = (caseId: string, values: IUploadCaseFileFormValues, meetingCaseId: string): IUploadCaseFileAsyncAction => {
  return {
    type: AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_PENDING,
    payload: {
      caseId,
      values,
      meetingCaseId
    }
  }
}

export const deleteAssetAction = (caseId: string, assetId: string): IDeleteAssetAsyncAction => {
  return {
    type: AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      assetId: assetId
    }
  }
}

export const createCaseLinkAction = (caseId: string, link: ILinkNewData, meetingCaseId: string): ICreateCaseLinkAsyncAction => {
  return {
    type: AssetsActionTypes.CREATE_CASE_LINK_ASYNC_PENDING,
    payload: {
      caseId,
      link,
      meetingCaseId
    }
  }
}

export const createCaseDicomLinkAction = (caseId: string, radiologyLink: IDicomLinkNewData, meetingCaseId: string): ICreateCaseDicomLinkAsyncAction => {
  return {
    type: AssetsActionTypes.CREATE_CASE_DICOM_LINK_ASYNC_PENDING,
    payload: {
      caseId,
      radiologyLink,
      meetingCaseId
    }
  }
}

export const addConsentAction = (recommendationId: string, userId: string, response: ConsentResponse, comment: string): IAddConsentAction => (
  {
    type: AssetsActionTypes.ADD_CONSENT_ASYNC_PENDING,
    payload: {
      recommendationId,
      userId,
      response,
      comment
    }
  }
)

export const saveCaseRecommendationAction = (recommendationId: string): ISaveRecommendationAction => (
  {
    type: AssetsActionTypes.SAVE_CASE_RECOMMENDATION_ASYNC_PENDING,
    payload: {
      recommendationId: recommendationId
    }
  }
)
