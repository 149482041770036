import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getCaseById } from "../../../shared/selectors/case"
import { IAppState } from "../../../app/appTypes"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { createMeetingCaseAction, deleteMeetingCaseAction } from "../../meetingCase/state/meetingCaseActions"
import { getMeetingCaseByMeetingAndCaseId } from "../../../shared/selectors/meetingCase"

interface IProps {
  caseId: string
  meetingId: string
}

const AddCaseToMeetingButton: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "case", "meeting"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseByMeetingAndCaseId(state, props))
  const dispatch = useDispatch()
  const createMeetingCase = (meetingId: string, caseId: string) => dispatch(createMeetingCaseAction(meetingId, caseId))
  const deleteMeetingCase = (meetingId: string, caseId: string, meetingCaseId: string) => dispatch(deleteMeetingCaseAction(meetingId, caseId, meetingCaseId))

  if (theCase.locked) {
    return (
      <div className="text-ch-gray-alt-500">
        {t("case:caseIsLocked")}
      </div>
    )
  }

  if (meetingCase) {
    if (!meetingCase.permissions.canDelete) return null

    return (
      <Button
        action={() => deleteMeetingCase(props.meetingId, props.caseId, meetingCase.id)}
        variant={StyleVariants.PURPLE}
        className="self-center self-end"
        isChunky={true}
        key={`removeCase-${theCase.id}`}
      >
        {t("meeting:removeCaseFromMeeting")}
      </Button>
    )
  }

  return (
    <Button
      action={() => createMeetingCase(props.meetingId, props.caseId)}
      variant={StyleVariants.ORANGE}
      className="self-center self-end"
      isChunky={true}
      key={`addCase-${theCase.id}`}
    >
      {t("meeting:addCaseToMeeting")}
    </Button>
  )
}

export default AddCaseToMeetingButton
