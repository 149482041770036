export enum MeetingStatuses {
  Scheduled = "scheduled",
  InProgress = "in_progress",
  Ended = "ended",
  Cancelled = "cancelled"
}

export enum MeetingRoles {
  Coordinator = "coordinator",
  Invited = "invited"
}

// Models
export interface IMeetingPermissions {
  canEdit: boolean
  canJoin: boolean
  canInvite: boolean
  canUninvite: boolean
  canEnd: boolean
  canCancel: boolean
  canAddCase: boolean
  canTrackChanges: boolean
}

export interface IMeeting {
  id: string
  webRtcApiKey: string
  sessionId: string
  token: string
  name: string
  specialtyId: string
  specialtyName: string
  startDate: string
  endDate: string
  startDateActual?: string
  endDateActual?: string
  cancelledDate?: string
  users: string[]
  meetingCases: string[]
  meetingUsers: string[]
  coordinatorId: string
  status: MeetingStatuses
  chatId: string
  permissions: IMeetingPermissions
  mdtTeam?: {
    name: string
    teamUsers: {
      role: string
      userId: string
    }[]
  }
}
export interface IMeetingFormData {
  name: string
  mdtTeamId?: string
  specialtyId: string
  coordinator: string
  users: string[]
  startDate: Date
  startTime: string
  endTime: string
  teams: string[]
  meetingType: "mdt" | "general"
}

export interface IMeetingNewData {
  name: string
  specialtyId: string
  startDate: string
  endDate: string
  coordinatorId: string
  userIds: string[]
  teamIds?: string[]
  meetingType: "mdt" | "general"
}

// State
export interface IHaveMeetingId {
  meetingId: string
}

export interface IHaveMeetingIdParam {
  match?: {
    params: {
      meetingId: string
    }
  }
}

export interface IHaveMeetingIds {
  meetingIds: string[]
}

// Actions
export interface IFetchMeetingAsyncAction {
  type: string,
  payload: string
}

export interface IStartMeetingAsyncAction {
  type: string,
  payload: string
}

export interface ICreateMeetingAction {
  type: string,
  payload: IMeetingNewData
}

export interface IUpdateMeetingAction {
  type: string,
  payload: {
    id: string,
    meeting: IMeetingNewData
  }
}

export interface IEndMeetingAsyncAction {
  type: string,
  payload: string
}

export interface ICancelMeetingAsyncAction {
  type: string,
  payload: string
}

// Action Types
export enum MeetingActionTypes {
  FETCH_MEETING_ASYNC_PENDING = "@@meeting/FETCH_MEETING_ASYNC_PENDING",
  FETCH_MEETING_ASYNC_SUCCESS = "@@meeting/FETCH_MEETING_ASYNC_SUCCESS",
  FETCH_MEETING_ASYNC_ERROR = "@@meeting/FETCH_MEETING_ASYNC_ERROR",

  START_MEETING_ASYNC_PENDING = "@@meeting/START_MEETING_ASYNC_PENDING",
  START_MEETING_ASYNC_SUCCESS = "@@meeting/START_MEETING_ASYNC_SUCCESS",
  START_MEETING_ASYNC_ERROR = "@@meeting/START_MEETING_ASYNC_ERROR",

  CREATE_MEETING_ASYNC_PENDING = "@@meeting/CREATE_MEETING_ASYNC_PENDING",
  CREATE_MEETING_ASYNC_SUCCESS = "@@meeting/CREATE_MEETING_ASYNC_SUCCESS",
  CREATE_MEETING_ASYNC_ERROR = "@@meeting/CREATE_MEETING_ASYNC_ERROR",

  UPDATE_MEETING_ASYNC_PENDING = "@@meeting/UPDATE_MEETING_ASYNC_PENDING",
  UPDATE_MEETING_ASYNC_SUCCESS = "@@meeting/UPDATE_MEETING_ASYNC_SUCCESS",
  UPDATE_MEETING_ASYNC_ERROR = "@@meeting/UPDATE_MEETING_ASYNC_ERROR",

  END_MEETING_ASYNC_PENDING = "@@meeting/END_MEETING_ASYNC_PENDING",
  END_MEETING_ASYNC_SUCCESS = "@@meeting/END_MEETING_ASYNC_SUCCESS",
  END_MEETING_ASYNC_ERROR = "@@meeting/END_MEETING_ASYNC_ERROR",

  CANCEL_MEETING_ASYNC_PENDING = "@@meeting/CANCEL_MEETING_ASYNC_PENDING",
  CANCEL_MEETING_ASYNC_SUCCESS = "@@meeting/CANCEL_MEETING_ASYNC_SUCCESS",
  CANCEL_MEETING_ASYNC_ERROR = "@@meeting/CANCEL_MEETING_ASYNC_ERROR",
}

export const MeetingCablePrefix = "@@meetingCable/"
export const MeetingChannelName = "MeetingChannel"

export enum MeetingCableActionTypes {
  MEETING_CASES_CHANGED = "@@meetingCable/MEETING_CASES_CHANGED",
  MEETING_USERS_CHANGED = "@@meetingCable/MEETING_USERS_CHANGED"
}
