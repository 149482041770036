import { AnyAction, combineReducers, Reducer } from "redux"
import { UsersActionTypes } from "../usersActionTypes"
import IUsersUIState, { UserOrderByOptions } from "../usersTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalUsersCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.totalItems
    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.totalPages
    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage
    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = ["active"], action: AnyAction): string[] => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.appliedFilters
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = UserOrderByOptions.nameAsc, action: AnyAction): string => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const usersUIReducer: Reducer<IUsersUIState> = combineReducers({
  totalUsersCount,
  totalPages,
  searchTerm,
  resultsPerPage,
  appliedFilters,
  orderBy
})
