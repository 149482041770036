import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import PillSwitch from "../../../../shared/components/ui/PillSwitch"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import { IPathway } from "../../../pathway/pathwayTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import PathwayUserListItem from "./PathwayUserListItem"
import PathwayStep from "./PathwayStep"

type Props = {
  pathwayId: string
}

export const PathwayQuickViewTabs: React.FC<Props> = ({pathwayId}: Props) => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector((state: IAppState) => state.entities.pathways.byId[pathwayId])

  if (!pathway) return null
  const [currentTab, setCurrentTab] = useState(0)
  const stepsCount = pathway.steps && pathway.steps.length || 0
  const collaboratorsCount = pathway.users && pathway.users.length || 0
  const tabText1 = `${stepsCount} ${t("pathway:steps")}`
  const tabText2 = `${collaboratorsCount} ${t("pathway:collaborators")}`

  const renderStepsList = (): JSX.Element => {
    if (stepsCount == 0) return null
    return(
      <h1>
        {_.map(pathway.steps, (step) => {
          return (
            <DetailsCard className="bg-ch-gray-300" key={step.position + step.name}>
              <PathwayStep step={step} />
            </DetailsCard>
          )
        })}
      </h1>
    )
  }

  const renderCollarboratorsList = (): JSX.Element => {
    if (collaboratorsCount == 0) {
      return (
        <div>
          <h3 className="text-center text-ch-blue-alt-400">
            {t("pathway:noCollaborators")}
          </h3>
          <ListBackground />
        </div>
      )
    } else {
      return (
        <ul>
          {pathway.users.map((user) => {
            return <PathwayUserListItem userId={user} key={user} />
          })}
        </ul>
      )
    }
  }

  return (
    <div>
      <PillSwitch onClick={setCurrentTab} text1={tabText1} text2={tabText2} value={currentTab} className="mb-2" />

      <div className={classNames({
        "hidden": currentTab !== 0
      })}>
        {renderStepsList()}
      </div>

      <div className={classNames({
        "hidden": currentTab !== 1
      })}>
        {renderCollarboratorsList()}
      </div>
    </div>
  )
}

export default PathwayQuickViewTabs
