import React from "react"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import Button from "../../../../shared/components/buttons/Button"
import { useTranslation } from "react-i18next"
import { LogoFull } from "../../../../svg/branding"
import { IUser } from "../../../user/state/userTypes"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../../../shared/selectors/user"

interface IPropsFromParent {
  isCreateTenantOpen: boolean
  setIsCreateTenantOpen: (isCreateTenantOpen: boolean) => void
}

type Props = IPropsFromParent

const SwitchTenantHeader: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["tenant"])
  const user: IUser = useSelector(getCurrentUser)

  const renderCreateTenantButton = (): JSX.Element => {
    if(
      props.isCreateTenantOpen ||
      !user.abilities?.tenant.canCreate
    ) return null

    return (
      <Button
        className="absolute right-0"
        action={() => { props.setIsCreateTenantOpen(true) }}
        variant={StyleVariants.ORANGE}
        key="1"
      >
        {t("tenant:buttons:addNewTenant")}
      </Button>
    )
  }

  return (
     <div className="text-center">
      <div className="flex justify-center items-center mb-5 relative">
        <LogoFull className="h-8" />
        {renderCreateTenantButton()}
      </div>
    </div>
  )
}

export default SwitchTenantHeader
