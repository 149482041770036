import * as Yup from "yup"
import i18n from "../../../app/i18n"

const YourConsentValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    body: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(1500, i18n.t("shared:maxLengthExceeded", { maxLength: 1500 }))
  })
}
export default YourConsentValidation
