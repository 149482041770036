import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { fetchChatAssetsAsync } from "../../state/chatActions"
import ExportChatForm from "./ExportChatForm"
import ChatCaseDetails from "../header/ChatCaseDetails"

interface Props {
  chatId: string
  closeModal: () => void
  isOpen: boolean
}

const ExportChatModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const dispatch = useDispatch()

  useEffect(() => {
    if(!props.isOpen) return
    dispatch(fetchChatAssetsAsync(props.chatId))
  }, [props.isOpen])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("message:exportChat:modalTitle")}
        </h2>
        <p className="text-ch-blue-600 mb-2">
          {t("message:exportChat:info1")}
        </p>
        <p className="text-ch-blue-600 mb-2">
          {t("message:exportChat:info2")}
        </p>
        <ChatCaseDetails chatId={props.chatId} />
        <ExportChatForm
          chatId={props.chatId}
          closeModal={props.closeModal}
          isOpen={props.isOpen}
        />
      </ModalContainer>
    </Modal >
  )
}

export default ExportChatModal
