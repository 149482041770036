import { createSelector } from "reselect"
import _ from "lodash"
import { getLiveMeetingConfig } from ".."
import { ILiveMeetingUserConfig } from "../../../../features/liveMeeting/state/liveMeetingTypes"
import { IUser } from "../../../../features/user/state/userTypes"
import { getMeetingById, getMeetingUserIds } from "../../meeting"
import { getAllUsers, getUserByHasUserId } from "../../user"

export const getUserConfigs = createSelector(
  getLiveMeetingConfig,
  (liveMeetingConfig): { [userId: string]: ILiveMeetingUserConfig } => {
    return liveMeetingConfig.userConfigs
  }
)

export const getMeetingUsersWithConfig = createSelector(
  getMeetingUserIds,
  getAllUsers,
  getUserConfigs,
  (meetingUserIds, allUsers, userConfigs): { [id: string]: IUser } => {
    const usersWithConfig = _.map(meetingUserIds, (userId) => {
      return {
        ...allUsers[userId],
        config: userConfigs[userId]
      }
    })

    return _.keyBy(usersWithConfig, "id")
  }
)

export const getUserWithConfigById = createSelector(
  getUserByHasUserId,
  getUserConfigs,
  (user, userConfigs): IUser => {
    return {
      ...user,
      config: userConfigs[user.id]
    }
  }
)

export const getMeetingCoordinator = createSelector(
  getMeetingById,
  getMeetingUsersWithConfig,
  (meeting, meetingUsersWithConfig) => {
    return meetingUsersWithConfig[meeting.coordinatorId]
  }
)

export const getLiveMeetingPresenter = createSelector(
  getLiveMeetingConfig,
  getMeetingUsersWithConfig,
  (liveMeetingConfig, meetingUsersWithConfig) => {
    return meetingUsersWithConfig[liveMeetingConfig.presenterId]
  }
)

export const getLiveMeetingCoordinator = createSelector(
  getLiveMeetingConfig,
  getMeetingUsersWithConfig,
  (liveMeetingConfig, meetingUsersWithConfig) => {
    return meetingUsersWithConfig[liveMeetingConfig.coordinatorId]
  }
)

export const getLiveMeetingScribe = createSelector(
  getLiveMeetingConfig,
  getMeetingUsersWithConfig,
  (liveMeetingConfig, meetingUsersWithConfig) => {
    return meetingUsersWithConfig[liveMeetingConfig.scribeId]
  }
)
