const BlurRadius = require('@vonage/ml-transformers').BlurRadius;
const DefaultConfig = require('./defaultConfig');

const defaultBlurRadius = BlurRadius.High;

const getDefaultConfig = () => ({
  ...DefaultConfig.getConfig(),
  transformerType: 'BackgroundBlur',
  radius: defaultBlurRadius,
});

const isValidBlurStrength = blurStrength =>
  blurStrength === 'low' || blurStrength === 'high';

const toBlurRadius = blurStrength =>
  (blurStrength === 'low' ? BlurRadius.Low : BlurRadius.High);

const isValidConfig = (config) => {
  if (config?.type !== 'backgroundBlur') {
    return false;
  }

  // blurStrength is optional.  If defined, then make sure it's valid.
  const { blurStrength } = config;
  return blurStrength ? isValidBlurStrength(blurStrength) : true;
};

const BackgroundBlurConfig = {
  isValidConfig,
  getConfig: (opt = {}) => {
    const config = getDefaultConfig();
    const { blurStrength } = opt;

    if (isValidBlurStrength(blurStrength)) {
      config.radius = toBlurRadius(blurStrength);
    }

    return config;
  },
};

module.exports = BackgroundBlurConfig;
