import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconExclamation({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 51 51"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M35.294 5.527a2.076 2.076 0 00-1.472-.61H17.154a2.075 2.075 0 00-1.473.61L5.265 15.944a2.076 2.076 0 00-.61 1.473v16.666c0 .555.219 1.084.61 1.473l10.417 10.417a2.074 2.074 0 001.473.61H33.82a2.07 2.07 0 001.473-.61L45.71 35.556a2.074 2.074 0 00.61-1.473V17.417a2.076 2.076 0 00-.61-1.473L35.294 5.527zm-7.722 30.64h-4.167V32h4.166v4.167zm0-8.334h-4.167v-12.5h4.166v12.5z"
      />
    </svg>
  );
}

export default SvgIconExclamation;
