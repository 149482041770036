// State
export interface ISetPasswordData {
  password: string
  passwordConfirmation: string
  local: boolean
  token: string
}

export interface ITerms {
  version: number
  path: string
  createdAt: string
  filename: string
}

export interface IAcceptTermsData {
  version: number
}

export interface IPhoneDetails {
  countryCode: string
  phoneNumber: string
}

// Actions
export interface IAccountSetPasswordAsyncAction {
  type: string,
  payload: ISetPasswordData
}

export interface IAccountSendResetPasswordLinkAsyncAction {
  type: string,
  payload: {
    email: string
  }
}

export interface IAccountSignInUserAsyncAction {
  type: string
  payload: {
    email: string,
    password: string
  }
}

export interface IAccountSignOutUserAsyncAction {
  type: string
}

export interface IAccountSignInTypeAsyncAction {
  type: string,
  payload: string
}

export interface IFetchTermsAsyncAction {
  type: string
}

export interface IAcceptTermsAsyncAction {
  type: string,
  payload: IAcceptTermsData
}

export interface IPrepareTotpAction {
  type: string,
  payload: IPhoneDetails
}

export interface IVerifyTotpAction {
  type: string,
  payload: string
}


// Action Types
export enum AccountActionTypes {
  SET_PASSWORD_ASYNC_PENDING = "@@account/SET_PASSWORD_ASYNC_REQUEST",
  SET_PASSWORD_ASYNC_SUCCESS = "@@account/SET_PASSWORD_ASYNC_SUCCESS",
  SET_PASSWORD_ASYNC_ERROR = "@@account/SET_PASSWORD_ASYNC_ERROR",

  PREPARE_TOTP_ASYNC_PENDING = "@@account/SETUP_TOTP_ASYNC_REQUEST",
  PREPARE_TOTP_ASYNC_SUCCESS = "@@account/SETUP_TOTP_ASYNC_SUCCESS",
  PREPARE_TOTP_ASYNC_ERROR = "@@account/SETUP_TOTP_ASYNC_ERROR",

  VERIFY_MFA_ASYNC_PENDING = "@@account/VERIFY_MFA_ASYNC_REQUEST",
  VERIFY_MFA_ASYNC_SUCCESS = "@@account/VERIFY_MFA_ASYNC_SUCCESS",
  VERIFY_MFA_ASYNC_ERROR = "@@account/VERIFY_MFA_ASYNC_ERROR",

  SEND_RESET_PASSWORD_LINK_ASYNC_PENDING = "@@account/SEND_RESET_PASSWORD_LINK_ASYNC_REQUEST",
  SEND_RESET_PASSWORD_LINK_ASYNC_SUCCESS = "@@account/SEND_RESET_PASSWORD_LINK_ASYNC_SUCCESS",
  SEND_RESET_PASSWORD_LINK_ASYNC_ERROR = "@@account/SEND_RESET_PASSWORD_LINK_ASYNC_ERROR",

  SIGN_IN_USER_ASYNC_PENDING = "@@account/SIGN_IN_USER_ASYNC_REQUEST",
  SIGN_IN_USER_ASYNC_SUCCESS = "@@account/SIGN_IN_USER_ASYNC_SUCCESS",
  SIGN_IN_USER_ASYNC_ERROR = "@@account/SIGN_IN_USER_ASYNC_ERROR",

  SIGN_OUT_USER_ASYNC_PENDING = "@@account/SIGN_OUT_USER_ASYNC_REQUEST",
  SIGN_OUT_USER_ASYNC_SUCCESS = "@@account/SIGN_OUT_USER_ASYNC_SUCCESS",
  SIGN_OUT_USER_ASYNC_ERROR = "@@account/SIGN_OUT_USER_ASYNC_ERROR",

  USER_SIGN_IN_TYPE_ASYNC_PENDING = "@@account/USER_SIGN_IN_TYPE_ASYNC_PENDING",
  USER_SIGN_IN_TYPE_ASYNC_SUCCESS = "@@account/USER_SIGN_IN_TYPE_ASYNC_SUCCESS",
  USER_SIGN_IN_TYPE_ASYNC_ERROR = "@@account/USER_SIGN_IN_TYPE_ASYNC_ERROR",

  FETCH_TERMS_ASYNC_PENDING = "@@account/FETCH_TERMS_ASYNC_REQUEST",
  FETCH_TERMS_ASYNC_SUCCESS = "@@account/FETCH_TERMS_ASYNC_SUCCESS",
  FETCH_TERMS_ASYNC_ERROR = "@@account/FETCH_TERMS_ASYNC_ERROR",

  ACCEPT_TERMS_ASYNC_PENDING = "@@account/ACCEPT_TERMS_ASYNC_REQUEST",
  ACCEPT_TERMS_ASYNC_SUCCESS = "@@account/ACCEPT_TERMS_ASYNC_SUCCESS",
  ACCEPT_TERMS_ASYNC_ERROR = "@@account/ACCEPT_TERMS_ASYNC_ERROR",
}
