import { INavToggleIsMenuOpenAction, INavToggleIsQuickViewOpenAction, NavActionTypes } from "./navTypes"

export const toggleIsMenuOpen = (isMenuOpen: boolean): INavToggleIsMenuOpenAction => {
  return {
    type: NavActionTypes.TOGGLE_IS_MENU_OPEN,
    payload: isMenuOpen
  }
}

export const toggleIsQuickViewOpen = (quickViewResourceId: string, isQuickViewOpen: boolean): INavToggleIsQuickViewOpenAction => {
  return {
    type: NavActionTypes.TOGGLE_IS_QUICK_VIEW_OPEN,
    payload: {
      quickViewResourceId,
      isQuickViewOpen
    }
  }
}
