import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import _ from "lodash"
import { IAppState } from "../../../../app/appTypes"
import MeetingDateTime from "../header/MeetingDateTime"
import { MeetingStatuses } from "../../state/meetingTypes"
import TextLink from "../../../../shared/components/text/TextLink"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import NameUser from "../../../../shared/components/names/NameUser"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { getMeetingCoordinator } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import MeetingTimeToStart from "../header/MeetingTimeToStart"
import MeetingStatus from "./MeetingStatus"

interface IProps {
  store?: MockStore
  meetingId: string
  showCaseAndUserCount?: boolean
  hasLink?: boolean
  showMeetingDueIn?: boolean
}


const defaultProps = {
  hasLink: true,
  showCaseAndUserCount: true
}

const MeetingDetails: React.FC<IProps> = (props: IProps) => {
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const coordinator = useSelector((state: IAppState) => getMeetingCoordinator(state, props))
  if (!meeting) return null
  const { t } = useTranslation(["shared", "meeting"])

  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const renderMeetingName = (): JSX.Element => {
    const renderText = (): JSX.Element => {
      if (!props.hasLink) return (
        <span>{meeting.name}</span>
      )
      return (
        <TextLink href={meetingDetailsUrl}>
          {meeting.name}
        </TextLink>
      )
    }
    return (
      <li>
        <h4 className="font-semibold">
          {renderText()}
        </h4>
      </li>
    )
  }

  const renderCoordinatorDetails = (): JSX.Element => {
    if (!coordinator) return null
    return (
      <li>
        {t("meeting:Coordinator")}: <NameUser firstName={coordinator.firstName} lastName={coordinator.lastName} isPending={coordinator.pending} />
      </li>
    )
  }

  const renderCaseAndUserCount = (): JSX.Element => {
    if (!props.showCaseAndUserCount) return null
    return (
      <li>
        <span className="mr-3">
          {meeting.users?.length} {t("share:users")}
        </span>
        <span>
          {meeting.meetingCases?.length} {t("share:cases")}
        </span>
      </li>
    )
  }

  const renderMeetingTimeToStart = (): JSX.Element => {
    if (!props.showMeetingDueIn) return null
    if (meeting.status !== MeetingStatuses.Scheduled) return null
    return (
      <li className="text-ch-gray-alt-500">
        <MeetingTimeToStart
          startDate={meeting.startDate}
        />
      </li>
    )
  }

  const renderMeetingStatus = (): JSX.Element => {
    return <li><MeetingStatus meeting={meeting} /></li>
  }

  const renderMdtTeamName = (): JSX.Element => {
    if (!meeting.mdtTeam?.name) return null
    return (
      <li>
        {t("meeting:mdtMeeting:MDT")}: {meeting.mdtTeam?.name}
      </li>
    )
  }

  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        {renderMeetingName()}
        {renderMdtTeamName()}
        <li>
          {meeting.specialtyName}
        </li>
        <li>
          <MeetingDateTime
            startDate={meeting.startDate}
            endDate={meeting.endDate}
          />
        </li>
      </ul>

      <ul className="col-span-1">
        {renderCoordinatorDetails()}
        {renderCaseAndUserCount()}
        {renderMeetingStatus()}
        {renderMeetingTimeToStart()}
      </ul>
    </div>
  )
}

MeetingDetails.defaultProps = defaultProps

export default MeetingDetails
