import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { getCaseByMeetingCaseId } from "../../../../shared/selectors/case"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconDrag, IconFolder } from "../../../../svg/icons"
import MeetingCaseDetails from "../../../meetingCase/components/MeetingCaseDetails"

interface IProps {
  meetingCaseId: string
}


const MeetingCaseListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const theCase = useSelector((state: IAppState) => getCaseByMeetingCaseId(state, props))

  if (!theCase) return null
  const meetingCaseDetailsUrl = generatePath(RoutePaths.MEETING_CASE, {
    meetingCaseId: props.meetingCaseId
  })

  return (
    <li>
      <DetailsCard>
        <div className="mr-4 text-ch-blue-500 hidden">
          <IconDrag className="fill-current w-2" title={t("shared:clickToDrag")} />
        </div>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
          </IconCircleContainer>
          <MeetingCaseDetails meetingCaseId={props.meetingCaseId} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            <Button
              href={meetingCaseDetailsUrl}
              variant={StyleVariants.BLUE}
              key="1"
            >
              {t("shared:viewDetails")}
            </Button>
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default MeetingCaseListItem
