import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconMeeting } from "../../../../svg/icons"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import MeetingDetails from "../../../meeting/components/details/MeetingDetails"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"

interface IProps {
  meetingId: string
  showCaseAndUserCount?: boolean
}

const defaultProps = {
  showCaseAndUserCount: true
}

const MeetingListItem: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "meeting"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const dispatch = useDispatch()
  const onClickQuickView = (meetingId: string) => dispatch(toggleIsQuickViewOpen(meetingId, true))
  if (!meeting) return null

  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const liveMeetingUrl = generatePath(RoutePaths.MEETING_LIVE, {
    meetingId: props.meetingId
  })

  const inProgress = () => {
    return meeting.status == MeetingStatuses.InProgress
  }

  const renderJoinMeetingButton = (): JSX.Element => {
    if (!inProgress() || !meeting.permissions.canJoin) return null
    return (
      <Button
        href={liveMeetingUrl}
        variant={StyleVariants.ORANGE}
        key="1"
      >
        {t("meeting:joinMeeting")}
      </Button>
    )
  }

  return (
    <li>
      <DetailsCard isDark={!inProgress()}>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
          </IconCircleContainer>
          <MeetingDetails meetingId={meeting.id} showCaseAndUserCount={true} showMeetingDueIn={true} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            renderJoinMeetingButton(),
            <Button
              action={() => onClickQuickView(meeting.id)}
              variant={StyleVariants.BLUE_ALT}
              key="2"
              className="hidden md:block"
            >
              {t("shared:quickView")}
            </Button>,
            <Button
              href={meetingDetailsUrl}
              variant={StyleVariants.BLUE}
              key="3"
            >
              {t("meeting:viewMeeting")}
            </Button>
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

MeetingListItem.defaultProps = defaultProps

export default MeetingListItem
