import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../app/appTypes"
import { RoutePaths } from "../../../app/routes/Routes"
import Card from "../../../shared/components/layout/Card"
import { getCaseMemberUsers } from "../../../shared/selectors/case"
import { IUser } from "../../user/state/userTypes"
import AddCaseAssetPanel from "./addCaseAssetPanel/AddCaseAssetPanel"
import AddCaseTaskPanel from "./addCaseTaskPanel/AddCaseTaskPanel"
import CaseActivityPanel from "./activityPanel/CaseActivityPanel"
import AddCasePathwayPanel from "./addCasePathwayPanel/AddCasePathwayPanel"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import { getCurrentUser } from "../../../shared/selectors/user"
import { isGuest } from "../../../shared/helpers/userHelpers"

type Props = {
  caseId: string
}

const CaseActivity: React.FC<Props> = (props: Props): JSX.Element => {
  const currentUser = useSelector(getCurrentUser)
  const { pathwaysEnabled } = useContext(TenantConfigContext)
  const mentionableUsers: { [id: string]: IUser} = useSelector((state: IAppState) => getCaseMemberUsers(state, {caseId: props.caseId}))
  const generateTabPath = (currentTab: string): string => {
    return generatePath(RoutePaths.CASE, {
      caseId: props.caseId,
      currentTab: currentTab
    })
  }

  const renderCaseTaskPanel = () => {
    if(isGuest(currentUser)) return null

    return(
      <Card>
        <AddCaseTaskPanel
          caseId={props.caseId}
          mentionableUsers={mentionableUsers}
        />
      </Card>
    )
  }

  const renderCasePathwayPanel = () => {
    if(isGuest(currentUser) || !pathwaysEnabled) return null

    return(
      <Card>
        <AddCasePathwayPanel
          caseId={props.caseId}
        />
      </Card>
    )
  }

  return (
    <div className="lg:grid grid-cols-2 gap-1">
      <div>
        <Card>
          <AddCaseAssetPanel
            caseId={props.caseId}
            mentionableUsers={mentionableUsers}
          />
        </Card>

        {renderCaseTaskPanel()}
        {renderCasePathwayPanel()}
      </div>

      <div>
        <Card>
          <CaseActivityPanel
            caseId={props.caseId}
            mentionableUsers={mentionableUsers}
            generateTabPath={generateTabPath}
          />
        </Card>
      </div>
    </div>
  )
}

export default CaseActivity
