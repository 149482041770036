import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export interface IAddPathwayToCaseUIState {
  totalPathwaysCount: number
  totalPages: number
  appliedFilters: string[]
  orderBy: string
  resultsPerPage: number
  searchTerm: string
  resultIds: string[]
}

// Actions
export interface IFetchPathwaysAddableToCaseAsyncAction {
  type: string
  payload: ISearchQuery
}

// Action Types
export enum AddPathwayToCaseActionTypes {
  FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_PENDING = "@@addPathwayToCase/FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_PENDING",
  FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS = "@@addPathwayToCase/FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS",
  FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_ERROR = "@@addPathwayToCase/FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_ERROR",
}
