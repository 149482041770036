import React from "react"
import { useSelector } from "react-redux"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { ICasePathway } from "../../../../casePathways/state/casePathwayTypes"
import CasePathwayDetails from "./CasePathwayDetails"

type Props = {
  casePathwayId: string
  openAssetModal: (id: string) => void
}

export const CasePathwayListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  if (!casePathway) return null

  return (
    <li className="mb-2">
      <CasePathwayDetails {...props}/>
    </li>
  )
}

export default CasePathwayListItem
