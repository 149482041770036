import { CableActionMeta } from "../../../shared/@types/redux-cablecar"
import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IMessage } from "../../message/state/messageTypes"

// Cable
export const ChatChannelName = "ChatChannel"

export const chatCablePrefix = "@@chatCable/"

// Models
export interface IChatPermissions {
  canContribute: boolean
  canEnd: boolean
  canExport: boolean
}

export interface IChatOptions {
  muted: boolean
}

export interface IChat {
  id: string
  type: string
  latestMessage: IMessage
  endedAt: string
  users: string[]
  team?: string
  case?: string
  messages?: string[]
  messageAssets?: string[]
  createdAt: string
  createdBy: ICreatedBy
  unreadCount?: number
  usersTypingIds?: string[]
  options?: IChatOptions
  summary?: string
  permissions: IChatPermissions
}

export enum ChatTypes {
  TEAM = "TeamChat",
  MEETING = "MeetingChat"
}

export interface IExportChatData {
  assetIds: string[]
  summary: string
}

// State
export interface IHaveChatId {
  chatId: string
}

// Actions
export interface IFetchChatAsyncAction {
  type: string,
  payload: string
}

export interface IEndChatAsyncAction {
  type: string,
  payload: string
}

export interface IToggleMutedChatAsyncAction {
  type: string,
  payload: string
}

export interface IUpdateUserIsTypingAction {
  type: string
  payload: {
    chatId: string
    userId: string
    isTyping: boolean
  }
  meta: CableActionMeta
}

export interface IFetchChatAssetsAsyncAction {
  type: string,
  payload: string
}

export interface IExportChatAsyncAction {
  type: string,
  payload: {
    chatId: string
    exportChatData: IExportChatData
  }
}

export interface IMarkChatMessagesAsReadAction {
  type: string
  payload: {
    chatId: string
  }
}

export enum ChatActionTypes {
  FETCH_CHAT_ASYNC_PENDING = "@@chats/FETCH_CHAT_ASYNC_PENDING",
  FETCH_CHAT_ASYNC_SUCCESS = "@@chats/FETCH_CHAT_ASYNC_SUCCESS",
  FETCH_CHAT_ASYNC_ERROR = "@@chats/FETCH_CHAT_ASYNC_ERROR",

  END_CHAT_ASYNC_PENDING = "@@chats/END_CHAT_ASYNC_PENDING",
  END_CHAT_ASYNC_SUCCESS = "@@chats/END_CHAT_ASYNC_SUCCESS",
  END_CHAT_ASYNC_ERROR = "@@chats/END_CHAT_ASYNC_ERROR",

  TOGGLE_MUTED_CHAT_ASYNC_PENDING = "@@chats/TOGGLE_MUTED_CHAT_ASYNC_PENDING",
  TOGGLE_MUTED_CHAT_ASYNC_SUCCESS = "@@chats/TOGGLE_MUTED_CHAT_ASYNC_SUCCESS",
  TOGGLE_MUTED_CHAT_ASYNC_ERROR = "@@chats/TOGGLE_MUTED_CHAT_ASYNC_ERROR",

  FETCH_CHAT_ASSETS_ASYNC_PENDING = "@@chats/FETCH_CHAT_ASSETS_ASYNC_PENDING",
  FETCH_CHAT_ASSETS_ASYNC_SUCCESS = "@@chats/FETCH_CHAT_ASSETS_ASYNC_SUCCESS",
  FETCH_CHAT_ASSETS_ASYNC_ERROR = "@@chats/FETCH_CHAT_ASSETS_ASYNC_ERROR",

  EXPORT_CHAT_ASYNC_PENDING = "@@chats/EXPORT_CHAT_ASYNC_PENDING",
  EXPORT_CHAT_ASYNC_SUCCESS = "@@chats/EXPORT_CHAT_ASYNC_SUCCESS",
  EXPORT_CHAT_ASYNC_ERROR = "@@chats/EXPORT_CHAT_ASYNC_ERROR",
}

export enum ChatCableActionTypes {
  // INBOUND
  DELIVER_MESSAGE = "@@chatCable/message/DELIVER",
  UPDATE_MESSAGE = "@@chatCable/message/UPDATE",
  DELIVER_CHAT = "@@chatCable/chat/DELIVER",
  UPDATE_UNREAD_COUNT = "@@chatCable/chat/UPDATE_UNREAD_COUNT",
  UPDATE_TOTAL_UNREAD_COUNT = "@@chatCable/chats/UPDATE_TOTAL_UNREAD_COUNT",
  MARK_USER_START_TYPING = "@@chatCable/chat/MARK_USER_START_TYPING",
  MARK_USER_STOP_TYPING = "@@chatCable/chat/MARK_USER_STOP_TYPING",

  // OUTBOUND
  MARK_MESSAGE_AS_READ = "@@chatCable/message/MARK_AS_READ",
  MARK_CHAT_MESSAGES_AS_READ = "@@chatCable/chat/MARK_CHAT_MESSAGES_AS_READ",
  UPDATE_USER_IS_TYPING = "@@chatCable/chat/UPDATE_USER_IS_TYPING"
}
