import React from "react"
import { IconMessage } from "../../../../../svg/icons"

interface Props {
  summary: string
}

const ChatDetail: React.FC<Props> = (props: Props): JSX.Element => {

  if (!props.summary) return null

  return (
    <div className="flex items-center my-1">
      <div className="text-ch-purple-500 mr-2">
        <IconMessage className="w-5 h-5" title={props.summary} />
      </div>
      <div className="font-thin">
        {props.summary}
      </div>
      <div>
      </div>
    </div>
  )
}

export default ChatDetail
