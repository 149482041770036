import React from "react"
import classNames from "classnames"

interface IProps {
  className?: string;
  name: string;
  value: boolean;
  action: () => void;
}

const Switch: React.FC<IProps> = ({
  className,
  name,
  action,
  value,
}): JSX.Element => {
  const renderContent = (): JSX.Element => {
    const defaultClasses = ["flex", "justify-center", "items-center"]
    const propClasses = className ? className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    return (
      <div className={allClasses}>
        <div
          className={classNames(
            "relative",
            "rounded-full",
            "w-12",
            "h-6",
            "transition",
            "duration-200",
            "ease-linear",
            {
              "bg-white": value,
              "bg-ch-blue-300": !value,
            }
          )}
        >
          <label
            htmlFor={name}
            className={classNames(
              "absolute",
              "left-0",
              "border-2",
              "mb-2",
              "w-6",
              "h-6",
              "rounded-full",
              "transition",
              "transform duration-100",
              "ease-linear",
              "cursor-pointer",
              {
                "translate-x-full": value,
                "bg-ch-blue-500": value,
                "bg-white": !value,
                "border-white": value,
                "translate-x-0": !value,
                "border-ch-blue-300": !value,
              }
            )}
          ></label>
          <input
            type="checkbox"
            id={name}
            name={name}
            className="appearance-none w-full h-full active:outline-none focus:outline-none cursor-pointer"
            onClick={action}
          />
        </div>
      </div>
    )
  }

  return renderContent()
}

export default Switch
