import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import TaskListItem from "./TaskListItem"
import { getAllTaskIds } from "../../../../shared/selectors/task"

const TaskList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["task"])
  const taskIds = useSelector(getAllTaskIds)

  if (!taskIds.length) return (
    <h3 className="text-lg font-bold text-center">
      {t("task:noTasks")}
    </h3>
  )

  return (
    <ul>
      {taskIds.map((taskId: string) => {
        return (
          <TaskListItem key={taskId} taskId={taskId} />
        )
      })}
    </ul>
  )
}

export default TaskList
