import React from "react"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconStep } from "../../../svg/icons"
import { IPathwayStep } from "../pathwayTypes"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import StepDetails from "./details/StepDetails"

interface IProps {
  step: IPathwayStep
}

const PathwayStep: React.FC<IProps> = ({ step }: IProps) => {
  if (!step) return null
  return (
    <DetailsCardInner>
      <div className="flex flex-1 items-center" >
        <IconCircleContainer className="sm:mr-4" >
          <IconStep className="fill-current w-6 h-6" />
        </IconCircleContainer>
        <StepDetails step={step} />
      </div>
    </DetailsCardInner>
  )
}

export default PathwayStep
