import { createSelector } from "reselect"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { getCaseById } from "../case"
import { ITask, IHaveTaskId } from "../../../features/tasks/state/tasksTypes"
import { getAllUsers } from "../user"
import { IUser } from "../../../features/user/state/userTypes"
import { IHaveTaskResponseId, ITaskResponse } from "../../../features/taskResponse/state/taskResponseTypes"

export const getAllTaskIds = (state: IAppState): string[] => state.entities.tasks.allIds

export const getAllTasks = (state: IAppState): { [id: string]: ITask } =>
  state.entities.tasks.byId

export const getAllTaskResponses = (state: IAppState): { [id: string]: ITaskResponse } =>
  state.entities.taskResponses.byId

export const getTaskById = (state: IAppState, ownProps: IHaveTaskId): ITask =>
  state.entities.tasks.byId[ownProps.taskId]

export const getTasksById = (state: IAppState, taskIds: string[]): ITask[] => taskIds.map((id) => state.entities.tasks.byId[id])

export const getTaskResponseById = (state: IAppState, ownProps: IHaveTaskResponseId): ITaskResponse =>
  state.entities.taskResponses.byId[ownProps.taskResponseId]

export const getAllCaseTaskIds = createSelector(
  getCaseById,
  (caseData): string[] => {
    if(!caseData) return []
    return caseData.tasks
  }
)

export const getTaskUserIds = createSelector(
  getTaskById,
  (task): string[] => {
    if(!task) return []
    return task.users
  }
)

export const getTaskUsers = createSelector(
  getAllUsers,
  getTaskUserIds,
  (allUsers, taskUserIds): { [id: string]: IUser } => {
    if (!taskUserIds) return null
    return _.pick(allUsers, taskUserIds)
  }
)

export const getAllCaseTaskResponseIds = createSelector(
  getTaskById,
  (task): string[] => {
    if(!task) return []
    return task.taskResponses
  }
)

export const getLatestTaskResponse = createSelector(
  getTaskById,
  getAllTaskResponses,
  (task, allTaskResponses): ITaskResponse => {
    if(!task || !allTaskResponses) return null
    return allTaskResponses[task.taskResponses[0]]
  }
)
