import React from "react"
import classNames from "classnames"
import { StyleVariants } from "../../state/sharedTypes"
import { IconTick } from "../../../svg/icons"

interface IProps {
  className?: string
  variant?: string
  altVariant?: string
  isChunky?: boolean
  isOn: boolean
  action?: () => void
}

const ButtonFilter: React.FC<IProps> = ({ className, action, variant, altVariant, children, isChunky, isOn }): JSX.Element => {
  if(!variant) {
    variant = StyleVariants.GRAY
    altVariant = StyleVariants.BLUE
  }

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "flex",
      "items-center",
      `bg-${variant}-450`,
      `hover:bg-${variant}-500`,
      "text-white",
      "px-4",
      "rounded-full",
      "cursor-pointer"
    ]

    const chunkyClasses = [
      "font-semibold",
      "py-2"
    ]

    const thinClasses = [
      "font-light",
      "py-1"
    ]

    const additionalClasses = isChunky ? chunkyClasses : thinClasses

    const propClasses = className ? className.split(" ") : []
    const allClasses = [...defaultClasses, ...additionalClasses, ...propClasses].join(" ")

    if(isOn) {
      return (
        <a
          onClick={action}
          className={classNames(allClasses, [`bg-${altVariant}-800`, `hover:bg-${altVariant}-800`])}
        >
          <IconTick className="fill-current w-4 h-4 mr-2" />
          <div>{children}</div>
        </a>
      )
    } else {
      return (
        <a onClick={action} className={allClasses}>
          {children}
        </a>
      )
    }
  }

  return renderContent()
}

export default ButtonFilter
