import {
  PathwayActionTypes,
  IFetchPathwayAction,
  ICreatePathwayStepAction,
  IPathwayStepNewData,
  IDeletePathwayStepAction,
  IAddPathwayUsersAction,
  IRemovePathwayUserAction,
  IPathwayNewData,
  IUpdatePathwayAction,
  IUpdatePathwayStepAction,
  IPathway,
  ICreatePathwayAction,
  IPathwayStep,
  IDeletePathwayAction
} from "./pathwayTypes"

export const fetchPathwayAction = (id: string): IFetchPathwayAction => {
  return {
    type: PathwayActionTypes.FETCH_PATHWAY_ASYNC_PENDING,
    payload: id
  }
}

export const createPathwayAction = (pathwayData: IPathwayNewData): ICreatePathwayAction => {
  return {
    type: PathwayActionTypes.CREATE_PATHWAY_ASYNC_PENDING,
    payload: pathwayData
  }
}

export const updatePathwayAction = (payload: IPathway): IUpdatePathwayAction => {
  return {
    type: PathwayActionTypes.UPDATE_PATHWAY_ASYNC_PENDING,
    payload
  }
}

export const deletePathwayAction = (pathwayId: string): IDeletePathwayAction => {
  return {
    type: PathwayActionTypes.DELETE_PATHWAY_ASYNC_PENDING,
    payload: {
      pathwayId
    }
  }
}

export const createPathwayStepAction = (pathwayId: string, values: IPathwayStepNewData): ICreatePathwayStepAction => {
  return {
    type: PathwayActionTypes.CREATE_PATHWAY_STEP_ASYNC_PENDING,
    payload: {
      pathwayId,
      values
    }
  }
}

export const updatePathwayStepAction = (pathwayId: string, values: IPathwayStep): IUpdatePathwayStepAction => {
  return {
    type: PathwayActionTypes.UPDATE_PATHWAY_STEP_ASYNC_PENDING,
    payload: {
      pathwayId,
      values
    }
  }
}

export const deletePathwayStepAction = (pathwayId: string, stepId: string): IDeletePathwayStepAction => {
  return {
    type: PathwayActionTypes.DELETE_PATHWAY_STEP_ASYNC_PENDING,
    payload: {
      pathwayId,
      stepId
    }
  }
}

export const addPathwayUsersAction = (pathwayId: string, users: string[]): IAddPathwayUsersAction => {
  return {
    type: PathwayActionTypes.ADD_PATHWAY_USERS_ASYNC_PENDING,
    payload: {
      pathwayId,
      users
    }
  }
}

export const removePathwayUserAction = (pathwayId: string, user: string): IRemovePathwayUserAction => {
  return {
    type: PathwayActionTypes.REMOVE_PATHWAY_USER_ASYNC_PENDING,
    payload: {
      pathwayId,
      user
    }
  }
}


