import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import PageTitle from "../../shared/components/ui/PageTitle"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import ITasksUIState, { TasksOrderByOptions } from "../../features/tasks/state/tasksTypes"
import { getTasksUI } from "../../shared/selectors/ui"
import { fetchTasksAsync } from "../../features/tasks/state/tasksActions"
import TaskList from "../../features/tasks/components/list/TaskList"
import OrderBy from "../../shared/components/search/OrderBy"
import TaskFilter from "../../features/tasks/components/search/TaskFilter"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"

const TasksPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "task"])
  const dispatch = useDispatch()
  const UI: ITasksUIState = useSelector((state: IAppState) => getTasksUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchTasksAsync)

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
  }, [])

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:tasks")}
      />
      <div className="flex justify-end mb-2">
        <ResultsPerPage
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div className="flex flex-col items-center sm:flex-row sm:justify-between mb-2">
        <h2 className="text-lg text-center md:text-left">
          {t("task:tasksOutstanding")}: {UI.outstandingCount}
        </h2>
      </div>
      <div className="grid grid-flow-row md:grid-flow-col">
        <div>
          <TaskFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={TasksOrderByOptions}
            useTranslation={"task:orderBy"}
          />
        </div>
      </div>
      <TaskList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalTasksCount}
        totalPages={UI.totalPages}
      />
    </div>
  )
}

export default TasksPage
