import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { ITask, TasksActionTypes } from "../tasksTypes"
import { TaskResponseActionTypes } from "../../../taskResponse/state/taskResponseTypes"
import { CasePathwaysActionTypes } from "../../../casePathways/state/casePathwayTypes"

type TByIdState = { [id: string]: ITask }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
    case TasksActionTypes.FETCH_CASE_TASKS_ASYNC_SUCCESS:
    case TasksActionTypes.CREATE_CASE_TASK_ASYNC_SUCCESS:
    case CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_SUCCESS:
    case CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_SUCCESS:
    case CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.tasks || []
      }

    case TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.tasks)

    case CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_TASK_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.all.result]: action.payload.all.entities.tasks[action.payload.all.result] || {}
      }

    case TasksActionTypes.DELETE_CASE_TASK_ASYNC_SUCCESS:
      return action.payload.all.entities.tasks || []

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)
    default:
      return state
  }
}

export const taskEntityReducer: Reducer<INormalizedEntities<ITask>> = combineReducers({
  byId,
  allIds
})
