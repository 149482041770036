import { takeEvery, put, call } from "redux-saga/effects"
import { WebRTCActionTypes } from "../webRTCTypes"
import { subscribeToStream } from "../../../../apis/webRTC/webRTC"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"

export function* subscribeToWebRTCStreamAsync(action) {
  try {
    const subscriber: OT.Subscriber = yield call(subscribeToStream, action.payload.session, action.payload.stream, action.payload.containerRef, action.payload.options)

    yield put({
      type: WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_SUCCESS,
      payload: subscriber
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "subscribeToWebRTCStreamError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchSubscribeToWebRTCStreamAsync() {
  yield takeEvery(WebRTCActionTypes.SUBSCRIBE_TO_WEB_RTC_STREAM_ASYNC_PENDING, subscribeToWebRTCStreamAsync)
}
