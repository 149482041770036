import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Modal from "react-modal"
import { endChatAsync } from "../state/chatActions"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import { dialogueModalStyle, StyleVariants } from "../../../shared/state/sharedTypes"
import Buttons from "../../../shared/components/layout/Buttons"
import Button from "../../../shared/components/buttons/Button"

interface Props {
  chatId: string
  closeModal: () => void
  isOpen: boolean
}

const EndChatConfirmModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const dispatch = useDispatch()
  const endChat = () => dispatch(endChatAsync(props.chatId))

  const onClickEndChat = () => {
    endChat()
    props.closeModal()
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={dialogueModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("message:endChat:modalTitle")}
        </h2>
        <Buttons
          buttons={
            [
              <Button
                action={props.closeModal}
                key="1"
                variant={StyleVariants.BLUE_ALT}
              >
                {t("shared:cancel")}
              </Button>,
              <Button
                action={onClickEndChat}
                key="2"
                variant={StyleVariants.PURPLE}
              >
                {t("message:shared:buttons:endChat")}
              </Button>
            ]
          } />
      </ModalContainer>
    </Modal >
  )
}


export default EndChatConfirmModal
