const defaultStaticConfig = require('../../../helpers/StaticConfig')().onlyLocal();

// Returns URL of CDN on which ML assets are hosted
const getCdnUrl = (staticConfig) => {
  const { apiUrl } = staticConfig;
  const isProd = apiUrl.includes('anvil.opentok.com');
  const host = isProd ? 'https://static.opentok.com' : 'https://www.dev.tokbox.com';

  return `${host}/ml-transformers/v3.0.0`;
};

// Default configuration that may be shared by all ML transforms
const DefaultConfig = {
  getConfig: (deps = {}) => {
    const { staticConfig = defaultStaticConfig } = deps;
    const cdnUrl = getCdnUrl(staticConfig);

    return {
      mediapipeBaseAssetsUri: `${cdnUrl}`,
    };
  },
};

module.exports = DefaultConfig;
