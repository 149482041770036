import { ChatCableActionTypes } from "../../chat/state/chatTypes"
import { ChatMessageActionTypes, ICreateChatMessageAsyncAction, IMarkMessageAsReadAction, INewMessageData } from "./messageTypes"

export const createChatMessageAsync = (chatId: string, message: INewMessageData): ICreateChatMessageAsyncAction => {
  return {
    type: ChatMessageActionTypes.CREATE_CHAT_MESSAGE_ASYNC_PENDING,
    payload: {
      chatId: chatId,
      message: message
    }
  }
}

export const markMessageAsRead = (messageId: string): IMarkMessageAsReadAction => {
  return {
    type: ChatCableActionTypes.MARK_MESSAGE_AS_READ,
    payload: {
      messageId
    },
    meta: { isOptimistic: false }
  }
}
