import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import MeetingCaseList from "../caseList/MeetingCaseList"
import MeetingUserList from "../userList/MeetingUserList"
import PillSwitch from "../../../../shared/components/ui/PillSwitch"
import { getMeetingById } from "../../../../shared/selectors/meeting"

interface IProps {
  meetingId: string
}

const MeetingQuickViewTabs: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))

  if (!meeting) return null

  const [currentTab, setCurrentTab] = useState(0)

  const meetingsCasesCount = meeting.meetingCases && meeting.meetingCases.length || 0
  const meetingUsersCount = meeting.users && meeting.users.length || 0
  const tabText1 = `${meetingsCasesCount} ${t("shared:cases")}`
  const tabText2 = `${meetingUsersCount} ${t("shared:users")}`

  return (
    <div>
      <PillSwitch onClick={setCurrentTab} text1={tabText1} text2={tabText2} value={currentTab} className="mb-2" />

      <div className={classNames({
        "hidden": currentTab !== 0
      })}>
        <MeetingCaseList meetingId={props.meetingId} />
      </div>

      <div className={classNames({
        "hidden": currentTab !== 1
      })}>
        <MeetingUserList meetingId={props.meetingId} />
      </div>
    </div>
  )
}

export default MeetingQuickViewTabs
