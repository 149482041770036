import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { generatePath } from "react-router"
import { ICreateMeetingAction, IFetchMeetingAsyncAction, MeetingActionTypes, MeetingCableActionTypes } from "../meetingTypes"
import { fetchMeetingSchema } from "../../../../apis/contextmeeting/schema"
import history from "../../../../app/routes/history"
import { RoutePaths } from "../../../../app/routes/Routes"
import { fetchMeetingAction } from "../meetingActions"
import { checkCurrentUser, getNormalisedSingle, handleError, handleSuccess, patchNormalisedSingle, postNormalisedSingle } from "../../../../shared/helpers/sagaHelpers"

export function* meetingWatchers(): Generator {
  yield takeLeading(MeetingActionTypes.FETCH_MEETING_ASYNC_PENDING, fetchMeetingAsync)
  yield takeLeading(MeetingActionTypes.CREATE_MEETING_ASYNC_PENDING, createMeeting)
  yield takeLeading(MeetingActionTypes.UPDATE_MEETING_ASYNC_PENDING, updateMeeting)
  yield takeLeading(MeetingActionTypes.END_MEETING_ASYNC_PENDING, endMeetingAsync)
  yield takeLeading(MeetingActionTypes.CANCEL_MEETING_ASYNC_PENDING, cancelMeetingAsync)
}

export function* meetingChannelWatchers(): Generator {
  yield takeLeading(MeetingCableActionTypes.MEETING_CASES_CHANGED, reloadMeeting)
  yield takeLeading(MeetingCableActionTypes.MEETING_USERS_CHANGED, reloadMeeting)
}

export function* fetchMeetingAsync(action: IFetchMeetingAsyncAction): Generator {
  try {
    const meetingId = action.payload

    const normalizedData: any = yield call(getNormalisedSingle, `/meetings/${meetingId}`, fetchMeetingSchema)

    yield handleSuccess(MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS, normalizedData)
  } catch (error) {

    yield handleError(MeetingActionTypes.FETCH_MEETING_ASYNC_ERROR, error, "meetingNotFound")
  }
}

export function* createMeeting(action: ICreateMeetingAction): Generator {
  try {
    const meeting = {
      meeting: action.payload
    }

    const response = yield call(postNormalisedSingle, `/meetings`, meeting)

    yield handleSuccess(MeetingActionTypes.CREATE_MEETING_ASYNC_SUCCESS, response, "createMeetingSuccess")

    yield call(history.push, RoutePaths.MEETINGS)

  } catch (error) {
    yield handleError(MeetingActionTypes.CREATE_MEETING_ASYNC_ERROR, error, "createMeetingFail")
  }
}

export function* updateMeeting(action: Effect): Generator {
  try {
    const meeting = {
      meeting: action.payload.meeting
    }

    const response = yield call(patchNormalisedSingle, `/meetings/${action.payload.id}`, meeting, fetchMeetingSchema)

    yield handleSuccess(MeetingActionTypes.UPDATE_MEETING_ASYNC_SUCCESS, response, "updateMeetingSuccess")

    const viewMeetingUrl = generatePath(RoutePaths.MEETING, {
      meetingId: action.payload.id
    })
    yield call(history.push, viewMeetingUrl)

  } catch (error) {
    yield handleError(MeetingActionTypes.UPDATE_MEETING_ASYNC_ERROR, error, "updateMeetingFail")
  }
}

export function* endMeetingAsync(action: Effect): Generator {
  try {
    const meetingId = action.payload

    const response = yield call(postNormalisedSingle, `/meetings/${meetingId}/end`, {}, fetchMeetingSchema)

    yield handleSuccess(MeetingActionTypes.END_MEETING_ASYNC_SUCCESS, response, "endMeetingSuccess")

  } catch (error) {
    yield handleError(MeetingActionTypes.END_MEETING_ASYNC_ERROR, error, "endMeetingFail")
  }
}

export function* cancelMeetingAsync(action: Effect): Generator {
  try {
    const meetingId = action.payload

    const response = yield call(postNormalisedSingle, `/meetings/${meetingId}/cancel`, {}, fetchMeetingSchema)

    yield handleSuccess(MeetingActionTypes.CANCEL_MEETING_ASYNC_SUCCESS, response, "cancelMeetingSuccess")

  } catch (error) {
    yield handleError(MeetingActionTypes.CANCEL_MEETING_ASYNC_ERROR, error, "cancelMeetingFail")
  }
}

export function* reloadMeeting(action: Effect): Generator {
  if (yield checkCurrentUser(action.payload)) return
  yield put(fetchMeetingAction(action.payload.meetingId))
}
