import { Reducer, AnyAction } from "redux"
import _ from "lodash"
import { SharedActionTypes } from "./../../../shared/state/sharedTypes"
import { userConfigContextInitialState } from "./../contexts/UserConfigContext"
import { IUserConfigContextState, UserConfigActionTypes } from "./userConfigTypes"

export const initialState: IUserConfigContextState = userConfigContextInitialState

export const userConfigReducer: Reducer<IUserConfigContextState> = (state: IUserConfigContextState = initialState, action: AnyAction): IUserConfigContextState => {
  switch (action.type) {
    case SharedActionTypes.REHYDRATE:
      if(!action.payload?.userConfig) return { ...state }
      return _.merge({}, state, action.payload.userConfig)

    case UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_SUCCESS:
    case UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_SUCCESS:
      // only keeps state value if payload value is null
      return _.mergeWith(
        {}, state, action.payload,
        (stateValue, payloadValue) => payloadValue === null ? stateValue : undefined
      )
    default:
      return { ...state }
  }
}
