/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames"
import React from "react"
import { useContext } from "react"
import { FormContext } from "../../../contexts/FormContextProvider"
import Card from "../../layout/Card"
import { IWizardStep } from "./Wizard"

interface IPropsFromParent {
  steps: IWizardStep[]
  currentStepNumber: number
  goToStep: (number: number) => void
}

type IProps = IPropsFromParent;

const WizardHeader: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { forceUpdate } = useContext(FormContext)

  const goToStep = (stepNumber: number, stepIsComplete: boolean) => {
    if(stepIsComplete) { props.goToStep(stepNumber) }
    setTimeout(() => {
      forceUpdate()
    })
  }

  const renderProgressLine = (step: IWizardStep, index: number): JSX.Element => {
    if(!props.steps[index + 1]) return null
    return (
      <div
        className={classNames(
        "flex-1 w-full border-b",
        {
          "border-ch-gray-400": (props.currentStepNumber <= index),
          "border-ch-purple-500": (props.currentStepNumber > index)
        })}
      ></div>
    )
  }

  const renderStepLink = (step: IWizardStep, index: number): JSX.Element => {
    const Icon: React.FC<any> = step.icon
    const stepIsComplete = props.currentStepNumber > index
    const stepIsCurrent = props.currentStepNumber == index

    return (
      <li
      key={index}
      className={classNames(
        "flex justify-center items-center",
        {
          "flex-1": (index != (props.steps.length - 1))
        })
      }
      >
        <a
          className={classNames(
            "flex px-2 justify-center flex-col items-center",
            {
              "text-ch-gray-500": !stepIsComplete,
              "text-ch-purple-500": stepIsComplete || stepIsCurrent,
              "opacity-50": stepIsComplete,
              "cursor-pointer": stepIsComplete
            })
          }
          key={`wizard-header-step-${index}`}
          onClick={() => { goToStep(index, stepIsComplete) }}
        >
          <span className="text-sm">{index + 1}</span>
          <Icon className="w-6 h-6 mb-1" />
          <h5 className="text-xs">{step.name}</h5>
        </a>
        { renderProgressLine(step, index) }
      </li>
    )
  }
  return (
    <Card>
      <div className="flex flex-1 justify-center">
        <ul
          className={classNames(
            "flex justify-between",
            {
              "flex-1": (props.steps.length > 3),
              "w-1/2": (props.steps.length <= 3),
            })
          }
        >
          {props.steps.map((step: IWizardStep, index: number) => {
            return renderStepLink(step, index)
          })}
        </ul>
      </div>
    </Card>
  )
}

export default WizardHeader
