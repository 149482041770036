import React from "react"
import { Link } from "react-router-dom"
import { ILinkProps, StyleVariants } from "../../state/sharedTypes"


interface IProps {
  className?: string
  variant?: string
  href?: string
  linkProps?: ILinkProps
  targetBlank?: boolean
  children: React.ReactNode
  action?: () => void
}

const defaultProps = {
  variant: StyleVariants.BLUE,
  targetBlank: false
}

const TextLink: React.FC<IProps> = (props: IProps): JSX.Element => {

  const defaultClasses = [
    `text-${props.variant}-500`,
    `hover:text-${props.variant}-600`,
  ]
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  if (props.href && props.targetBlank)
    return (
      <a {...props.linkProps} href={props.href} className={allClasses} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    )

  if (props.href)
    return (
      <Link {...props.linkProps} to={props.href} className={allClasses}>
        {props.children}
      </Link>
    )

  if (props.action)
    return (
      <button type="button" onClick={props.action} className={allClasses}>
        {props.children}
      </button>
    )

  return (
    <button type="submit" className={allClasses}>
      {props.children}
    </button>
  )
}

TextLink.defaultProps = defaultProps

export default TextLink
