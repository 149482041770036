import React from "react"
import { IconMeeting } from "../../../../../svg/icons"
import TimeDefaultFormat from "../../../../../shared/components/time/TimeDefaultFormat"
import { IAssetMeeting } from "../../../state/assetsTypes"

interface IPropsFromParent {
  meeting: IAssetMeeting
}

type IProps = IPropsFromParent

const MeetingDetail: React.FC<IPropsFromParent> = ({ meeting }: IProps): JSX.Element => {
  if (meeting == null) return null

  return (
    <div className="flex items-center my-1">
      <div className="text-ch-purple-500 mr-2">
        <IconMeeting className="w-5 h-5" title={meeting.name} />
      </div>
      <div className="font-thin">
        {meeting.name} - {meeting.specialtyName} - <TimeDefaultFormat utcTime={meeting.startDate} />
      </div>
      <div>
      </div>
    </div>
  )
}

export default MeetingDetail
