import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import ButtonChangeTracking from "../../../../shared/components/buttons/ButtonChangeTracking"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import Breadcrumbs from "../../../../shared/components/ui/Breadcrumbs"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconPatient } from "../../../../svg/icons"
import PatientDetails from "../details/PatientDetails"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { getPatientById } from "../../../../shared/selectors/patients"
import { generatePath } from "react-router"

interface IProps {
  patientId: string
}

const PatientHeader: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["patient"])
  const patient = useSelector((state: IAppState) => getPatientById(state, props))

  const renderEditButton = (): JSX.Element => {
    if (!patient.permissions.canEdit) return null
    const editPatientDetailsUrl = generatePath(RoutePaths.PATIENT_EDIT_DETAILS, {
      patientId: props.patientId
    })

    return (
      <Button
        href={editPatientDetailsUrl}
        variant={StyleVariants.PURPLE}
        key="2"
      >
        {t("patient:details:buttons:editPatient")}
      </Button>
    )
  }

  const renderAddCaseButton = (): JSX.Element => {
    if (patient.permissions.canAddCase) {
      const newPatientCaseUrl = generatePath(RoutePaths.PATIENT_CASE_NEW, {
        patientId: props.patientId
      })

      return (
        <Button
          href={newPatientCaseUrl}
          variant={StyleVariants.ORANGE}
          key="3"
        >
          {t("patient:details:buttons:addCase")}
        </Button>
      )
    }
    else return null
  }

  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:patients"),
              href: RoutePaths.PATIENTS
            },
            {
              pageTitle: t("patient:details:sectionTitle")
            }
          ]}
        />
        <ButtonChangeTracking
          hasPermission={patient?.permissions?.canTrackChanges}
          action={() => { return null }}
        />
      </div>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconPatient className="fill-current w-6 h-6" title={t("shared:patient")} />
          </IconCircleContainer>
          <PatientDetails patientId={props.patientId} hasLink={false} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            renderEditButton(),
            renderAddCaseButton()
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </div>
  )
}

export default PatientHeader
