import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { smallModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import { endMeetingAction } from "../../state/meetingActions"
import Modal from "react-modal"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"

interface IProps {
  meetingId: string
  close: () => void
  isOpen: boolean
}

const MeetingEndModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const dispatch = useDispatch()
  const endMeeting = (meetingId: string) => dispatch(endMeetingAction(meetingId))
  if (!meeting) return null

  const { t } = useTranslation(["shared", "meeting"])

  const onClickEndMeeting = () => {
    endMeeting(meeting.id)
    props.close()
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.close} style={smallModalStyle} overlayClassName="ReactModal__Overlay flex justify-center">
      <ModalContainer handleClose={props.close}>
        <h2 className="text-lg mb-3">
          {t("meeting:endMeetingTitle")}
        </h2>

        <div className="mb-3">
          <p className="text-ch-blue-600">{t("meeting:endMeetingDesc")}</p>
        </div>

        <div className="flex justify-center space-x-2">
          <Button
            action={props.close}
            variant={StyleVariants.BLUE_ALT}
            key="1"
          >
            {t("shared:cancel")}
          </Button>
          <Button
            action={() => onClickEndMeeting()}
            variant={StyleVariants.PURPLE}
            key="2"
          >
            {t("meeting:endMeeting")}
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default MeetingEndModal
