import { put } from "redux-saga/effects"
import { UserMessageActionTypes, IDisplayUserMessageAction, IMarkUserMessageReadAction, IUserMessage, UserMessageTypes } from "./userMessageTypes"

export const displayUserMessageAction = (userMessage: IUserMessage): IDisplayUserMessageAction => {
  return {
    type: UserMessageActionTypes.DISPLAY_USER_MESSAGE,
    payload: userMessage
  }
}

export const markUserMessageRead = (): IMarkUserMessageReadAction => {
  return {
    type: UserMessageActionTypes.MARK_USER_MESSAGE_READ
  }
}

export const displayUserError = (messageKey: string) => {
  return put(displayUserMessageAction({
    messageKey,
    type: UserMessageTypes.ERROR
  }))
}

export const displayUserSuccess = (messageKey: string) => {
  return put(displayUserMessageAction({
    messageKey,
    type: UserMessageTypes.SUCCESS
  }))
}

export const displayUserMessage = (messageKey: string) => {
  return put(displayUserMessageAction({
    messageKey,
    type: UserMessageTypes.MESSAGE
  }))
}
