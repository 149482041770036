import { ISearchQuery } from "../../../shared/hooks/useSearch"

export interface IFetchMeetingsAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IFetchMeetingsInDateRangeAsyncAction {
  type: string
  payload: {
    start: string,
    end: string
  }
}

export interface IMeetingsToggleListViewAction {
  type: string
  payload: boolean
}

// Action Types
export enum MeetingsActionTypes {
  FETCH_MEETINGS_ASYNC_PENDING = "@@meetings/FETCH_MEETINGS_ASYNC_PENDING",
  FETCH_MEETINGS_ASYNC_SUCCESS = "@@meetings/FETCH_MEETINGS_ASYNC_SUCCESS",
  FETCH_MEETINGS_ASYNC_ERROR = "@@meetings/FETCH_MEETINGS_ASYNC_ERROR",
  TOGGLE_LIST_VIEW = "@@meetings/TOGGLE_LIST_VIEW",
  FETCH_MEETINGS_IN_RANGE_ASYNC_PENDING = "@@meetings/FETCH_MEETINGS_IN_RANGE_ASYNC_PENDING",
  FETCH_MEETINGS_IN_RANGE_ASYNC_ERROR = "@@meetings/FETCH_MEETINGS_IN_RANGE_ASYNC_ERROR",
  FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS = "@@meetings/FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS"
}
