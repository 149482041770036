import { normalize } from "normalizr"
import { takeLeading, put, call, Effect } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { tasksSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { TasksActionTypes } from "../tasksTypes"

export function* fetchCaseTasksAsync(action: Effect): Generator {
  try {
    const caseId = action.payload
    const response: any = yield call(API.get, `/cases/${caseId}/tasks`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [tasksSchema])
    yield put({
      type: TasksActionTypes.FETCH_CASE_TASKS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })
  } catch (error) {
    yield put({
      type: TasksActionTypes.FETCH_CASE_TASKS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchCaseTasksAsync(): Generator {
  yield takeLeading(TasksActionTypes.FETCH_CASE_TASKS_ASYNC_PENDING, fetchCaseTasksAsync)
}

export function* createTaskAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const casePathwayId = action.payload.casePathwayId
    const data = {
      task: action.payload.task
    }

    const response: any = yield call(API.post, `/cases/${caseId}/tasks`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, tasksSchema)
    yield put({
      type: TasksActionTypes.CREATE_CASE_TASK_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId,
        casePathwayId: casePathwayId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createTaskSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: TasksActionTypes.CREATE_CASE_TASK_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createTaskFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateTaskAsync(): Generator {
  yield takeLeading(TasksActionTypes.CREATE_CASE_TASK_ASYNC_PENDING, createTaskAsync)
}

export function* deleteCaseTaskAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const taskId = action.payload.taskId
    const casePathwayId = action.payload.casePathwayId

    const response: any = yield call(API.delete, `/cases/${caseId}/tasks/${taskId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [tasksSchema])

    yield put({
      type: TasksActionTypes.DELETE_CASE_TASK_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        taskId: taskId,
        casePathwayId: casePathwayId,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteTaskSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: TasksActionTypes.DELETE_CASE_TASK_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteTaskFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDeleteCaseTaskAsync(): Generator {
  yield takeLeading(TasksActionTypes.DELETE_CASE_TASK_ASYNC_PENDING, deleteCaseTaskAsync)
}
