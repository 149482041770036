import { Reducer, AnyAction, combineReducers } from "redux"
import { IWebRTCScreenShareState, WebRTCActionTypes } from "../webRTCTypes"

export const publisherInitialState: OT.Publisher = null

export const publisher: Reducer<OT.Publisher> = (state: OT.Publisher = publisherInitialState, action: AnyAction): OT.Publisher => {
  switch(action.type) {
    case WebRTCActionTypes.UPDATE_WEB_RTC_SCREEN_STREAM:
      if(!action.payload) return publisherInitialState
      return state
    case WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case WebRTCActionTypes.STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return publisherInitialState
    default:
      return state
  }
}

export const streamInitialState: OT.Stream = null

export const stream: Reducer<OT.Stream> = (state: OT.Stream = streamInitialState, action: AnyAction): OT.Stream => {
  switch(action.type) {
    case WebRTCActionTypes.UPDATE_WEB_RTC_SCREEN_STREAM:
      if(!action.payload) return streamInitialState
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export const isOnInitialState = false

export const isOn: Reducer<boolean> = (state: boolean = isOnInitialState, action: AnyAction): boolean => {
  switch(action.type) {
    case WebRTCActionTypes.UPDATE_WEB_RTC_SCREEN_STREAM:
      if(!action.payload) return isOnInitialState
      return state
    case WebRTCActionTypes.TOGGLE_WEB_RTC_SCREEN_SHARING_IS_ON:
      return action.payload
    case WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR:
      return isOnInitialState
    default:
      return state
  }
}

export const webRTCScreenShareReducer: Reducer<IWebRTCScreenShareState> = combineReducers({
  publisher,
  stream,
  isOn
})
