import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconFolder({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M24.528 9.542a1.188 1.188 0 00-1.007-.514H7.757a1.799 1.799 0 00-1.563 1.055.694.694 0 000 .098l-2.027 7.18v-12.5h4.507l1.812 2.493c.131.18.34.285.563.285h11.173a1.39 1.39 0 00-1.389-1.389h-9.43L9.799 4.042a1.389 1.389 0 00-1.125-.57H4.167a1.39 1.39 0 00-1.39 1.39v15.756c.023.52.46.925.98.91h17.312a.694.694 0 00.695-.5l2.91-10.486a1.14 1.14 0 00-.146-1z"
        />
        <path d="M0 0h25v25H0z" />
      </g>
    </svg>
  );
}

export default SvgIconFolder;
