import React, { ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../app/appTypes"
import { fetchTenantConfigAsync } from "../state/tenantConfigActions"
import { TenantConfigContext } from "./TenantConfigContext"

interface IProps {
  children?: ReactNode
}

const TenantConfigProvider: React.FC<IProps> = (props: IProps) => {
  const tenantConfig = useSelector((state: IAppState) => state.tenantConfig)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchTenantConfigAsync())
  }, [])

  return (
    <TenantConfigContext.Provider value={tenantConfig}>
      {props.children}
    </TenantConfigContext.Provider>
  )
}

export default TenantConfigProvider
