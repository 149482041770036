import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getCaseById } from "../../../../shared/selectors/case"
import CaseListItemContainer from "./CaseListItemContainer"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"

interface IProps {
  caseId: string
}

const CaseListItem: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "case"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const dispatch = useDispatch()
  const onClickQuickView = (caseId: string) => dispatch(toggleIsQuickViewOpen(caseId, true))
  if (!theCase) return null

  const caseDetailsUrl = generatePath(RoutePaths.CASE, {
    caseId: props.caseId
  })

  return (
    <CaseListItemContainer caseId={props.caseId} key={props.caseId}>
      <DetailsCardButtons buttons={[
        <Button
          action={() => onClickQuickView(theCase.id)}
          variant={StyleVariants.BLUE_ALT}
          key="1"
          className="hidden md:block"
        >
          {t("shared:quickView")}
        </Button>,
        <Button
          href={caseDetailsUrl}
          variant={StyleVariants.BLUE}
          key="2"
        >
          {t("shared:viewDetails")}
        </Button>
      ]} />
    </CaseListItemContainer>
  )
}

export default CaseListItem
