import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { IHaveCaseId, CaseChannelName, CaseCablePrefix } from "../../features/case/state/caseTypes"
import { IAppState } from "../../app/appTypes"
import { getCaseById } from "../../shared/selectors/case"
import { fetchCaseAction } from "../../features/case/state/caseActions"
import CaseHeader from "../../features/case/components/header/CaseHeader"
import { fetchCaseAssetsAction } from "../../features/assets/state/assetsActions"
import CaseMembers from "../../features/case/components/users/CaseMembers"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { ISearchQuery } from "../../shared/hooks/useSearch"
import { fetchMeetingsAsync } from "../../features/meetings/state/meetingsActions"
import CaseMeetingUsers from "../../features/case/components/users/CaseMeetingUsers"
import CaseActivity from "../../features/case/components/CaseActivity"
import { fetchCaseTasksAsync } from "../../features/tasks/state/tasksActions"
import { fetchCasePathwaysAsync } from "../../features/casePathways/state/casePathwayActions"
import { useBeforeunload } from "react-beforeunload"
import CableConnection from "../../shared/components/cable/CableConnection"
import { useSelector } from "react-redux"
import { isGuest } from "../../shared/helpers/userHelpers"
import { getCurrentUser } from "../../shared/selectors/user"

const CasePage: React.FC = (): JSX.Element => {
  const currentUser = useSelector(getCurrentUser)
  const { caseId } = useParams<IHaveCaseId>()
  const theCase = useSelector((state: IAppState) => getCaseById(state, { caseId: caseId }))
  const { t } = useTranslation(["shared", "case", "asset", "meeting", "caseReport", "userMessage"])
  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(0)
  const dispatch = useDispatch()
  const fetchCase = (caseId: string) => dispatch(fetchCaseAction(caseId))
  const fetchCaseAssets = (caseId: string) => dispatch(fetchCaseAssetsAction(caseId))
  const fetchCaseTasks = (caseId: string) => dispatch(fetchCaseTasksAsync(caseId))
  const fetchCasePathways = (caseId: string) => dispatch(fetchCasePathwaysAsync(caseId))
  // TODO = return only users needed from case and meeting endpoints
  const fetchAllUsers = () => dispatch(fetchAllUsersAsync())
  const fetchMeetings = (searchQuery: ISearchQuery) => dispatch(fetchMeetingsAsync(searchQuery))

  useBeforeunload(() => {
    setConnectionRefresherIndex(connectionRefresherIndex + 1)
  })

  useEffect(() => {
    fetchCase(caseId)
    fetchCaseAssets(caseId)
    fetchAllUsers()
    if (!isGuest(currentUser)) {
      fetchCaseTasks(caseId)
      fetchCasePathways(caseId)
      fetchMeetings(null)
    }
  }, [])

  if (!theCase) return <h3 className="text-lg">{t("case:caseNotFound")}</h3>

  return (
    <CableConnection
      resourceId={caseId}
      channelName={CaseChannelName}
      actionPrefix={CaseCablePrefix}
      key={connectionRefresherIndex}
    >
      <CaseHeader caseId={caseId} />
      <CaseMembers caseId={caseId} />
      {isGuest(currentUser) ? null : <CaseMeetingUsers caseId={caseId} />}
      <CaseActivity caseId={caseId} />
    </CableConnection>
  )
}

export default CasePage
