import React, { ErrorInfo, ReactNode } from "react"
import Button from "../shared/components/buttons/Button"
import Apm from "./apm"

interface IErrorBoundryProps {
  children: ReactNode
  errorMessage?: ReactNode
}

const genericErrorMessage = (
  <div className="text-xl space-y-4 flex flex-col justify-center items-center h-screen">
    <h1>Something went wrong. Please try reloading the page</h1>
    <p>If the error persists please go to the home page</p>
    <Button href="/">Home Page</Button>
  </div>
)

export class ErrorBoundary extends React.Component {
  state: { hasError: boolean } = { hasError: false }
  props: IErrorBoundryProps = null

  static defaultProps: IErrorBoundryProps = {
    children: null,
    errorMessage: genericErrorMessage
  }

  constructor(props: IErrorBoundryProps) {
    super(props)
    this.props = props
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo)
    Apm.captureError(error)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.errorMessage
    }
    return this.props.children
  }
}
