import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
  isStretched?: boolean
}

type IProps = IPropsFromParent

const defaultProps = {
  isStretched: false
}

const DetailsCardRight: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-wrap",
    "w-full",
    "justify-center",
    "lg:justify-end",
    "flex-col",
    "mb-2",
    "lg:w-1/4",
  ]

  const stretchedClass = props.isStretched ? "self-stretch" : []
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, stretchedClass, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      {props.children}
    </div>
  )
}

DetailsCardRight.defaultProps = defaultProps

export default DetailsCardRight
