import React, { useContext }  from "react"
import { dateOnly } from "../../helpers/timeHelpers"
import { UserConfigContext } from "../../../features/userConfig/contexts/UserConfigContext"

interface IProps {
  utcTime: string
  className?: string
}

const TimeDateOnly: React.FC<IProps> = ({
  utcTime,
  className,
}): JSX.Element => {
  const { timezone } = useContext(UserConfigContext)

  const renderContent = (): JSX.Element => {
    if (!utcTime) return null

    const formattedDate = dateOnly(utcTime, timezone)

    return (
      <span className={className}>
        {formattedDate}
      </span>
    )
  }

  return renderContent()
}

export default TimeDateOnly
