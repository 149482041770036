import React, { useContext }  from "react"
import { dateWithoutTimezone, dateWithTimezone } from "../../helpers/timeHelpers"
import { UserConfigContext } from "../../../features/userConfig/contexts/UserConfigContext"

interface IProps {
  utcTime: string
  className?: string
  withoutTZ?: boolean
}

const TimeDefaultFormat: React.FC<IProps> = ({
  utcTime,
  className,
  withoutTZ
}): JSX.Element => {
  const { timezone } = useContext(UserConfigContext)

  const renderContent = (): JSX.Element => {
    if (!utcTime) return null

    const formattedDate = withoutTZ ? dateWithoutTimezone(utcTime, timezone) :  dateWithTimezone(utcTime, timezone)

    return (
      <span className={className}>
        {formattedDate}
      </span>
    )
  }

  return renderContent()
}

export default TimeDefaultFormat
