import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import LiveMeetingUserAudioDeviceListItem from "./LiveMeetingUserAudioDeviceListItem"
import { getAudioDevicesAction, getAudioSourceAction } from "../../../webRTC/state/webRTCActions"
import { getAllWebRTCAudioDeviceIds, getWebRTCPublisher } from "../../../../shared/selectors/webRTC"
import { useSelector } from "react-redux"

const LiveMeetingUserAudioDeviceList: React.FC = () => {
  const { t } = useTranslation(["liveMeeting"])
  const audioDeviceIds = useSelector(getAllWebRTCAudioDeviceIds)
  const publisher = useSelector(getWebRTCPublisher)
  const dispatch = useDispatch()
  const getAudioDevices = () => dispatch(getAudioDevicesAction())
  const getAudioSource = (publisher: OT.Publisher) => dispatch(getAudioSourceAction(publisher))

  useEffect(() => {
    getAudioDevices()
    getAudioSource(publisher)
  }, [])

  const renderList = () => {
    if (!audioDeviceIds.length) return <span>No audio devices</span>
    return (
      <ul className="p-2 border-ch-gray-300 border">
        {
          audioDeviceIds.map((deviceId: string) => {
            return <LiveMeetingUserAudioDeviceListItem key={deviceId} deviceId={deviceId} />
          })
        }
      </ul>
    )
  }

  return (
    <div>
      <h2 className="mb-2">
        {t("liveMeeting:chooseAudioInput")}
      </h2>
      {renderList()}
    </div>
  )
}

export default LiveMeetingUserAudioDeviceList
