import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import NameUser from "../../../../shared/components/names/NameUser"
import TextLink from "../../../../shared/components/text/TextLink"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { getCaseById, getLeadCareUserByCaseId } from "../../../../shared/selectors/case"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconFolder } from "../../../../svg/icons"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import CaseQuickViewTabs from "./CaseQuickViewTabs"

interface IProps {
  caseId: string
}

const CaseQuickView: React.FC<IProps> = (props: IProps) => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const leadCareUser = useSelector((state: IAppState) => getLeadCareUserByCaseId(state, props))
  const { t } = useTranslation(["shared", "case"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  if (!theCase) return null

  const caseDetailsUrl = generatePath(RoutePaths.CASE, {
    caseId: props.caseId
  })

  const renderLeadCare = (): JSX.Element => {
    if (!leadCareUser) return (
      <span>
        {t("case:leadCare")}: -
      </span>
    )
    return (
      <span>
        {t("case:leadCare")}: <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          professionalTitle={leadCareUser.professionalTitle}
          isPending={leadCareUser.pending}
        />
      </span>
    )
  }

  return (
    <div>
      <div className="flex items-center flex-col mb-6">
        <IconCircleContainer className="mb-3">
          <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
        </IconCircleContainer>

        <dl className="text-center mb-3">
          <dt className="mb-1">
            <h5 className="font-semibold">
              <TextLink href={caseDetailsUrl}>
                <NameUser
                  firstName={theCase.firstName}
                  lastName={theCase.lastName}
                  title={theCase.title}
                  gender={theCase.gender}
                />
              </TextLink>
            </h5>
          </dt>
          <dd className="mb-3">
            {t("case:dateOfBirth")}: <TimeDateOfBirth utcTime={theCase.dateOfBirth} />
          </dd>

          <dd className="mb-1">
            {healthcareIdLabel}: {theCase.healthcareId || "-"}
          </dd>
          <dd className="mb-3">
            {careProviderIdLabel}: {theCase.careProviderId || "-"}
          </dd>

          <dd className="mb-1">
            {renderLeadCare()}
          </dd>
          <dd className="mb-3">
            {t("case:lastUpdated")}: <TimeDefaultFormat utcTime={theCase.updatedAt} />
          </dd>
        </dl>

        <Button href={caseDetailsUrl} variant={StyleVariants.BLUE}>
          {t("shared:viewDetails")}
        </Button>
      </div>

      <CaseQuickViewTabs caseId={props.caseId} />
    </div>
  )
}

export default CaseQuickView
