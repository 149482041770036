import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { MeetingActionTypes, IMeeting } from "../meetingTypes"
import { MeetingsActionTypes } from "../../../meetings/state/meetingsActionTypes"
import { MeetingUserActionTypes } from "../../../meetingUser/state/meetingUserTypes"
import { MeetingCaseActionTypes } from "../../../meetingCase/state/meetingCaseTypes"
import { CaseActionTypes } from "../../../case/state/caseTypes"
import { CasesActionTypes } from "../../../cases/state/casesActionTypes"

type TByIdState = { [id: string]: IMeeting }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.result]: {
          ...state[action.payload.result],
          ...action.payload.entities.meetings[action.payload.result]
        }
      }

    case MeetingActionTypes.UPDATE_MEETING_ASYNC_SUCCESS:
    case MeetingActionTypes.END_MEETING_ASYNC_SUCCESS:
    case MeetingActionTypes.START_MEETING_ASYNC_SUCCESS:
    case MeetingActionTypes.CANCEL_MEETING_ASYNC_SUCCESS:
    case MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_SUCCESS:
    case MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_SUCCESS:
    case MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_SUCCESS:
    case CaseActionTypes.FETCH_CASE_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.entities.meetings)
      )

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
    case CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.all.entities.meetings)
      )

    case MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetings || []
      }

    case MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_SUCCESS:
    case MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.meetingId]: {
          ...state[action.payload.meetingId],
          ...action.payload.all.entities.meetings[action.payload.meetingId]
        }
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  // the list of ids on the index page has been moved to the meetingsUI. This
  // should only containt the actual list of ALL meetings, not just the ones
  // that are being displayed.

  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.result))

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.all.result))

    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.all.result))
    default:
      return state
  }
}

export const meetingEntityReducer: Reducer<INormalizedEntities<IMeeting>> = combineReducers({
  byId,
  allIds
})
