import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { ICreatedBy } from "../../../shared/state/sharedTypes"

// State
export interface ITask {
  id: string
  action: string
  description: string
  dueDate?: string
  isComplete: boolean
  completedAt?: string
  meetingId?: string
  caseId: string
  createdAt: string
  createdBy: ICreatedBy
  users: string[]
  taskResponses?: string[]
  case: ITaskCase
  casePathwayId?: string
  casePathwayPosition?: number
  permissions: ITaskPermissions
}

export interface ITaskPermissions {
  canContribute: boolean
  canTrackChanges: boolean
}

export interface IHaveTaskId {
  taskId: string
}

export interface ITaskCase {
  id: string
  firstName: string
  lastName: string
  title: string
  gender: string
  locked: boolean
  outstandingTaskCount?: number
}

export interface ITaskNewData {
  action: string
  description: string
  dueDate: Date
  assigneeIds: string[]
  meetingCaseId?: string
  casePathwayId?: string
}

export interface ICasePathwayTaskUpdateData {
  dueDate?: Date
  userIds?: string[]
  action?: string
  description?: string
}

export default interface ITasksUIState {
  totalTasksCount: number
  outstandingCount: number
  totalPages: number
  resultsPerPage: number
  orderBy: string
  appliedFilters: string[]
}

export enum TasksOrderByOptions {
  CreatedAtDesc = "created_at_desc",
  CreatedAtAsc = "created_at_asc",
  UpdatedAtDesc = "updated_at_desc",
  DueDateAsc = "due_date_asc"
}

export interface ICaseTaskFilters {
  showAll: boolean
  showOutstanding: boolean
  showCompleted: boolean
}

// Actions
export interface IFetchTasksAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IFetchCaseTasksAsyncAction {
  type: string,
  payload: string
}

export interface ICreateCaseTaskAsyncAction {
  type: string,
  payload: {
    caseId: string
    casePathwayId?: string
    task: ITaskNewData
  }
}

export interface IDeleteCaseTaskAsyncAction {
  type: string,
  payload: {
    caseId: string,
    taskId: string,
    casePathwayId: string
  }
}

// Action Types
export enum TasksActionTypes {
  FETCH_CASE_TASKS_ASYNC_PENDING = "@@tasks/FETCH_CASE_TASKS_ASYNC_PENDING",
  FETCH_CASE_TASKS_ASYNC_SUCCESS = "@@tasks/FETCH_CASE_TASKS_ASYNC_SUCCESS",
  FETCH_CASE_TASKS_ASYNC_ERROR = "@@tasks/FETCH_CASE_TASKS_ASYNC_ERROR",

  CREATE_CASE_TASK_ASYNC_PENDING = "@@tasks/CREATE_CASE_TASK_ASYNC_PENDING",
  CREATE_CASE_TASK_ASYNC_SUCCESS = "@@tasks/CREATE_CASE_TASK_ASYNC_SUCCESS",
  CREATE_CASE_TASK_ASYNC_ERROR = "@@tasks/CREATE_CASE_TASK_ASYNC_ERROR",

  DELETE_CASE_TASK_ASYNC_PENDING = "@@tasks/DELETE_CASE_TASK_ASYNC_PENDING",
  DELETE_CASE_TASK_ASYNC_SUCCESS = "@@tasks/DELETE_CASE_TASK_ASYNC_SUCCESS",
  DELETE_CASE_TASK_ASYNC_ERROR = "@@tasks/DELETE_CASE_TASK_ASYNC_ERROR",

  FETCH_TASKS_ASYNC_PENDING = "@@tasks/FETCH_TASKS_ASYNC_PENDING",
  FETCH_TASKS_ASYNC_SUCCESS = "@@tasks/FETCH_TASKS_ASYNC_SUCCESS",
  FETCH_TASKS_ASYNC_ERROR = "@@tasks/FETCH_TASKS_ASYNC_ERROR"
}

export enum tasksCableActionTypes {
  // INBOUND
  UPDATE_OUTSTANDING_COUNT = "@@userCable/tasks/UPDATE_OUTSTANDING_COUNT",
}
