import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export interface IPathwaysUIState {
  totalPathwaysCount: number
  totalPages: number
  resultsPerPage: number
  searchTerm: string
  orderBy: string
  onPage: string[]
  appliedFilters: string[]
}

export enum PathwaysOrderByOptions {
  CreatedAtDesc = "created_at_desc",
  CreatedAtAsc = "created_at_asc",
  NameDesc = "name_desc",
  NameAsc = "name_asc",
}

// Actions
export interface IFetchPathwaysAction {
  type: string
  payload: ISearchQuery
}

export enum PathwaysActionTypes {
  FETCH_PATHWAYS_ASYNC_PENDING = "@@pathways/FETCH_PATHWAYS_ASYNC_PENDING",
  FETCH_PATHWAYS_ASYNC_SUCCESS = "@@pathways/FETCH_PATHWAYS_ASYNC_SUCCESS",
  FETCH_PATHWAYS_ASYNC_ERROR = "@@pathways/FETCH_PATHWAYS_ASYNC_ERROR",
}

