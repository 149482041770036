import React from "react"
import { IUser } from "../../../../user/state/userTypes"
import ConsentListItem from "./ConsentListItem"
import _ from "lodash"

interface IPropsFromParent {
  users: IUser[]
  readOnly?: boolean
}

const UserConsentList: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const sortedUsers = _.sortBy(props.users, ["lastName", "firstName"])
  return (
    <ul>
      {sortedUsers.map((user: IUser) => {
        // we were seeing a bug where sometimes a user could be blank. not sure what was causing that.
        if (user == undefined) { return null }
        return (
          <li key={user.id} className="bg-ch-gray-200 mb-1 p-2 rounded-md">
            <ConsentListItem user={user} />
          </li>
        )
      })}
    </ul>
  )
}


export default UserConsentList
