import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import _ from "lodash"
import { IAppState } from "../../../../app/appTypes"
import TextLink from "../../../../shared/components/text/TextLink"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { getMeetingCoordinator } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import classNames from "classnames"
import MeetingDateTime from "../../../meeting/components/header/MeetingDateTime"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import NameUser from "../../../../shared/components/names/NameUser"
import MeetingTimeToStart from "../../../meeting/components/header/MeetingTimeToStart"

interface IProps {
  store?: MockStore
  meetingId: string
  hasLink?: boolean
  showCoordinator?: boolean
  showMeetingStatus?: boolean
}

const defaultProps = {
  hasLink: true
}

const MeetingDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["meeting"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const coordinator = useSelector((state: IAppState) => getMeetingCoordinator(state, props))
  if (!meeting) return null

  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const renderMeetingName = (): JSX.Element => {
    const renderText = (): JSX.Element => {
      if (!props.hasLink) return (
        <span>{meeting.name}</span>
      )
      return (
        <TextLink href={meetingDetailsUrl}>
          {meeting.name}
        </TextLink>
      )
    }
    return (
      <li>
        <h4 className="font-semibold">
          {renderText()}
        </h4>
      </li>
    )
  }

  const renderCoordinatorDetails = (): JSX.Element => {
    if (!coordinator || !props.showCoordinator) return null
    return (
      <li>
        {t("meeting:Coordinator")}: <NameUser firstName={coordinator.firstName} lastName={coordinator.lastName} isPending={coordinator.pending} />
      </li>
    )
  }

  const renderMeetingTimeToStart = (): JSX.Element => {
    if (!props.showMeetingStatus) return null
    if (meeting.status !== MeetingStatuses.Scheduled) return null
    return (
      <li className="text-ch-gray-alt-500">
        <MeetingTimeToStart
          startDate={meeting.startDate}
        />
      </li>
    )
  }

  return (
    <div className={classNames(
      "lg:grid",
      "gap-1"
    )}
    >
      <ul className="col-span-1">
        {renderMeetingName()}
        <li>
          {meeting.specialtyName}
        </li>
        <li>
          <MeetingDateTime
            startDate={meeting.startDate}
            endDate={meeting.endDate}
          />
        </li>
        {renderCoordinatorDetails()}
        {renderMeetingTimeToStart()}
      </ul>
    </div>
  )
}

MeetingDetails.defaultProps = defaultProps

export default MeetingDetails
