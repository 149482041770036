import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconLock({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 21"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M8.5.083a5.214 5.214 0 00-5.208 5.209v3.125H2.25C1.1 8.417.167 9.349.167 10.5v8.333c0 1.15.932 2.084 2.083 2.084h12.5c1.15 0 2.083-.933 2.083-2.084V10.5c0-1.15-.932-2.083-2.083-2.083h-1.042V5.292A5.214 5.214 0 008.5.083zM5.375 5.292A3.129 3.129 0 018.5 2.167a3.129 3.129 0 013.125 3.125v3.125h-6.25V5.292zm4.167 11.17v2.371H7.458v-2.372a2.076 2.076 0 01.591-3.83 2.084 2.084 0 012.534 2.036 2.073 2.073 0 01-1.041 1.794z"
        />
        <path d="M-4-2h25v25H-4z" />
      </g>
    </svg>
  );
}

export default SvgIconLock;
