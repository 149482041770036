import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconComment({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M13 4C7.477 4 3 7.637 3 12.125c0 1.938.836 3.71 2.227 5.105-.489 1.97-2.122 3.723-2.141 3.743a.31.31 0 00-.059.34c.051.117.16.187.285.187 2.59 0 4.532-1.242 5.493-2.008A11.92 11.92 0 0013 20.25c5.523 0 10-3.637 10-8.125C23 7.637 18.523 4 13 4z"
        />
        <path d="M0 0h25v25H0z" />
      </g>
    </svg>
  );
}

export default SvgIconComment;
