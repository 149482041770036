import { useFormikContext } from "formik"
import React from "react"
import { IconAttachment } from "../../../../svg/icons"
import { INewMessageAssetData, INewMessageData } from "../../state/messageTypes"
import MessageAssetsFieldList from "./MessageAssetsFieldList"

const MessageAssetsField: React.FC = (): JSX.Element => {
  const {
    values,
    setFieldValue
  } = useFormikContext<INewMessageData>()

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.currentTarget.files)
    const assetsData: INewMessageAssetData[] = selectedFiles.map((file) => {
      return {
        assetType: "MessageDocument",
        file: file
      }
    })
    setFieldValue("assetsAttributes", assetsData)
  }

  const onRemoveFile = (file: File) => {
    const filteredAssetsData: INewMessageAssetData[] = values.assetsAttributes.filter((assetData: INewMessageAssetData) => {
      return assetData.file != file
    })
    setFieldValue("assetsAttributes", filteredAssetsData)
  }

  return (
    <div className="flex items-center justify-end">
       <MessageAssetsFieldList
        messageAssetsData={values.assetsAttributes}
        removeItem={onRemoveFile}
      />
      <label className="ml-1">
        <input type="file" id="file" multiple onChange={onChangeFiles} className="hidden" key={values.assetsAttributes.length} />
        <IconAttachment
          className="w-4 h-4 text-ch-blue-alt-500 hover:text-ch-blue-alt-600 cursor-pointer"
        />
      </label>
    </div>
  )
}

export default MessageAssetsField
