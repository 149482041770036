import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgLogoFull({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 247 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path fill="#006F74" d="M10.46 15.439v3.088l5.23-3.088-2.615-1.544z" />
        <path
          fill="#75A7A9"
          d="M10.46 6.176l5.23 3.087-2.615 1.544-2.615-1.544-2.615 1.544L5.23 9.263zm0-6.176L0 6.176l3.4 2.006 10.46-6.175z"
        />
        <path
          fill="#006F74"
          d="M17.52 8.182l3.4-2.007v6.176l-3.4 2.007V8.182zm-7.06 12.506v4.014l7.06-4.169 3.4-2.007v-4.014l-3.4 2.008-7.06 4.168z"
        />
        <path
          fill="#75A7A9"
          d="M15.69 3.088l5.23 3.088-3.4 2.007-5.23-3.088z"
        />
        <path
          fill="#A3C3C4"
          d="M10.46 15.439v3.088l-2.615-1.544-2.615-1.544V9.263l2.615 1.544v3.088z"
        />
        <path
          fill="#A3C3C4"
          d="M0 18.526l3.4 2.008 7.06 4.168v-4.014L3.4 16.52V8.183L0 6.176z"
        />
        <g fill="#006F74">
          <path d="M39.901 17.219a4.92 4.92 0 002.028-.415 4.64 4.64 0 001.587-1.148 5.446 5.446 0 001.147-2.05h3.927a8.869 8.869 0 01-2.353 4.48 8.6 8.6 0 01-2.816 1.906c-1.09.464-2.279.696-3.568.696-1.288 0-2.473-.232-3.555-.696a8.825 8.825 0 01-2.804-1.881 8.42 8.42 0 01-1.838-2.785A8.956 8.956 0 0131 11.893v-.049c0-1.221.222-2.366.668-3.432a8.875 8.875 0 011.85-2.81 8.598 8.598 0 012.815-1.906c1.09-.464 2.28-.696 3.568-.696 1.289 0 2.474.232 3.556.696a8.826 8.826 0 012.804 1.882c.787.79 1.4 1.718 1.837 2.784.227.553.394 1.126.504 1.72H44.65a5.19 5.19 0 00-1.158-2.024 5.05 5.05 0 00-1.611-1.161 4.8 4.8 0 00-2.029-.428c-.748 0-1.428.139-2.04.415a4.673 4.673 0 00-1.575 1.149 5.429 5.429 0 00-1.026 1.698 5.716 5.716 0 00-.37 2.064v.05c0 .732.123 1.424.37 2.076a5.177 5.177 0 001.05 1.71 5.08 5.08 0 001.599 1.16c.612.285 1.292.428 2.04.428" />
          <path
            fillRule="nonzero"
            d="M57.882 20.688c1.288 0 2.478-.232 3.567-.696a8.596 8.596 0 002.816-1.905 8.871 8.871 0 001.85-2.81 8.818 8.818 0 00.667-3.432v-.05a8.958 8.958 0 00-.656-3.432 8.43 8.43 0 00-1.837-2.785 8.826 8.826 0 00-2.804-1.88C60.403 3.232 59.218 3 57.93 3c-1.289 0-2.478.232-3.568.696a8.596 8.596 0 00-2.816 1.906 8.871 8.871 0 00-1.849 2.81 8.818 8.818 0 00-.668 3.432v.048c0 1.222.219 2.366.656 3.433a8.43 8.43 0 001.838 2.785 8.826 8.826 0 002.803 1.881c1.082.464 2.267.696 3.556.696zm.048-3.469c-.748 0-1.428-.142-2.04-.427a5.087 5.087 0 01-1.6-1.16 5.182 5.182 0 01-1.05-1.71 5.817 5.817 0 01-.37-2.077v-.05c0-.732.124-1.42.37-2.064a5.435 5.435 0 011.027-1.698 4.67 4.67 0 011.575-1.148c.612-.277 1.292-.415 2.04-.415a4.8 4.8 0 012.028.427c.62.286 1.157.672 1.61 1.16a5.182 5.182 0 011.05 1.711c.247.652.37 1.344.37 2.077v.048c0 .733-.123 1.421-.37 2.065a5.435 5.435 0 01-1.025 1.698 4.64 4.64 0 01-1.587 1.148 4.92 4.92 0 01-2.028.415zm13.792 3.176V9.524l8.09 10.871h3.125V3.294H79.31v10.53l-7.827-10.53h-3.388v17.101zm20.832 0V6.763h5.082V3.294h-13.84v3.47h5.083v13.631zm18.66 0v-3.347h-9.068v-3.591h7.875V10.11h-7.875V6.641h8.949V3.294h-12.6v17.101zm4.224 0l3.77-5.863 3.746 5.863h4.295l-5.822-8.746 5.584-8.355h-4.176l-3.532 5.546-3.507-5.546H111.5l5.584 8.404-5.822 8.697z"
          />
          <path
            fillRule="nonzero"
            d="M135.601 20.395V6.763h5.083V3.294h-13.84v3.47h5.082v13.631zm8.137 0V6.568l6.085 9.137h.096l6.085-9.162v13.852h1.885V3.294h-1.91l-6.084 9.332-6.085-9.332h-1.91v17.101zm29.112 0v-1.759h-10.308v-5.985h9.115v-1.76h-9.115V5.054h10.189v-1.76h-12.074v17.101zm14.031 0v-1.759h-10.308v-5.985h9.115v-1.76h-9.115V5.054h10.189v-1.76h-12.074v17.101zm8.161 0V5.077h5.608V3.294h-13.124v1.783h5.607v15.318zm9.235 0V3.294h-1.885v17.101zm4.773 0V6.372l10.785 14.023h1.503V3.294h-1.837v13.705L208.978 3.294h-1.766v17.101zm22.812.293c1.416 0 2.692-.256 3.83-.77 1.137-.512 2.095-1.127 2.875-1.844v-6.816h-6.896v1.735h5.082v4.25c-.572.473-1.28.876-2.123 1.21-.843.334-1.742.5-2.697.5-1.018 0-1.933-.178-2.744-.537a6.047 6.047 0 01-2.076-1.49 6.674 6.674 0 01-1.324-2.26c-.31-.871-.466-1.82-.466-2.846 0-.96.16-1.869.478-2.724a7.12 7.12 0 011.312-2.235 6.213 6.213 0 012.005-1.515 5.817 5.817 0 012.553-.562c.588 0 1.125.05 1.61.147.486.098.931.232 1.337.403a6.71 6.71 0 011.133.61c.35.237.684.502 1.002.795l1.217-1.466a11.73 11.73 0 00-1.276-.965 7.689 7.689 0 00-1.408-.72 8.2 8.2 0 00-1.623-.44 11.613 11.613 0 00-1.92-.147c-1.257 0-2.399.24-3.425.72a8.146 8.146 0 00-2.637 1.943 8.89 8.89 0 00-1.706 2.834 9.47 9.47 0 00-.608 3.371c0 1.222.195 2.37.584 3.445a8.186 8.186 0 001.695 2.81 7.932 7.932 0 002.672 1.88c1.042.456 2.223.684 3.544.684zm9.703-13.818V3.402h1.269V3h-2.97v.403h1.269V6.87zm2.04 0V3.74l1.377 2.068h.021l1.377-2.073v3.134h.427V3h-.432l-1.377 2.112L243.62 3h-.432v3.87z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoFull;
