import { getSession } from "../../app/state/appSessionStorage"

export interface AuthHeader {
  headers: Record<string, string>
}

export const withAuthHeader = (): Partial<AuthHeader> => {
  const { token, email } = getSession()
  if (token) {
    const headers = {
      "X-USER-TOKEN": `${token}`,
      "X-USER-EMAIL": `${email}`
    }
    return { headers }
  }
  return {}
}
