import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import { getTeamUserByTeamUserId } from "../../../../shared/selectors/team"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"

interface IPropsFromParent {
  teamUserId: string
}

const MdtTeamUserListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["team", "shared"])
  const teamUser = useSelector((state: IAppState) => getTeamUserByTeamUserId(state, props))
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, teamUser))

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <UserDetailsCard user={user} />
        </DetailsCardInner>

        <DetailsCardRight>
          {t("team:mdt:role")}: {teamUser.role}
        </DetailsCardRight>
      </DetailsCard>
    </li >
  )
}

export default MdtTeamUserListItem
