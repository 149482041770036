import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { WebRTCActionTypes, TWebRTCStreamByUserIdState, IWebRTCStreamState } from "../webRTCTypes"

export const byUserIdInitialState: TWebRTCStreamByUserIdState = {}

export const byUserId: Reducer<TWebRTCStreamByUserIdState> = (state: TWebRTCStreamByUserIdState = byUserIdInitialState, action: AnyAction): TWebRTCStreamByUserIdState => {
  switch(action.type) {
    case WebRTCActionTypes.ADD_WEB_RTC_STREAM:
      return {
        ...state,
        [action.payload.userId]: action.payload.stream
      }

    case WebRTCActionTypes.UPDATE_WEB_RTC_STREAM:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          ...action.payload.stream
        }
      }

    case WebRTCActionTypes.REMOVE_WEB_RTC_STREAM:
      return _.omit(state, action.payload.userId)

    case WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return byUserIdInitialState

    default:
      return { ...state }
  }
}

export const webRTCStreamReducer: Reducer<IWebRTCStreamState> = combineReducers({
  byUserId
})
