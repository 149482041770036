import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { cycleVideoSource, togglePublisherVideo } from "../../../../apis/webRTC/webRTC"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"

export function* toggleWebRTCPublishVideoAsync(action) {
  try {
    yield call(togglePublisherVideo, action.payload.publisher, action.payload.isSharingVideo)

    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_SUCCESS
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "publishWebRTCVideoError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchToggleWebRTCPublishVideoAsync() {
  yield takeLeading(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_VIDEO_PENDING, toggleWebRTCPublishVideoAsync)
}

// Publisher Video Source
export function* cycleWebRTCVideoSourceAsync(action) {
  try {
    yield call(cycleVideoSource, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_SUCCESS
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "cycleWebRTCVideoSourceError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCycleWebRTCVideoSourceAsync() {
  yield takeLeading(WebRTCActionTypes.CYCLE_WEB_RTC_VIDEO_SOURCE_PENDING, cycleWebRTCVideoSourceAsync)
}
