import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { generatePath } from "react-router"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import TextLink from "../../../../shared/components/text/TextLink"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconMeeting } from "../../../../svg/icons"
import MeetingDateTime from "../../../meeting/components/header/MeetingDateTime"
import { IMeeting, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import MeetingQuickViewTabs from "./MeetingQuickViewTabs"
import MeetingStatus from "../../../meeting/components/details/MeetingStatus"
import MeetingTimeToStart from "../../../meeting/components/header/MeetingTimeToStart"
import { fetchMeetingAction } from "../../../meeting/state/meetingActions"

interface IProps {
  store?: MockStore
  meetingId: string
}

const MeetingQuickView: React.FC<IProps> = (props: IProps) => {
  const meeting: IMeeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const { t } = useTranslation(["shared", "meeting"])
  const dispatch = useDispatch()
  useEffect(() => {
    if (!props.meetingId) return
    dispatch(fetchMeetingAction(props.meetingId))
  }, [props.meetingId])

  if (!meeting) return null

  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const renderMeetingTimeToStart = () => {
    if (meeting.status !== MeetingStatuses.Scheduled) return null
    return (
      <dd className="mb-1" >
        <MeetingTimeToStart startDate={meeting.startDate} />
      </dd>
    )
  }
  const renderJoinButton = (): JSX.Element => {
    if (meeting?.status == MeetingStatuses.InProgress && meeting.permissions?.canJoin) {
      const liveMeetingUrl = generatePath(RoutePaths.MEETING_LIVE, {
        meetingId: props.meetingId
      })
      return (
        <Button
          href={liveMeetingUrl}
          variant={StyleVariants.ORANGE}
          className="mt-2"
        >
          {t("meeting:joinMeeting")}
        </Button>
      )
    }

  }

  const renderMdtName = () => {
    if (!meeting.mdtTeam?.name) return null
    return (
      <dd>
        {t("meeting:mdtMeeting:MDT")}: {meeting.mdtTeam?.name}
      </dd>
    )
  }


  return (
    <div>
      <div className="flex items-center flex-col mb-6">
        <IconCircleContainer className="mb-3">
          <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
        </IconCircleContainer>

        <dl className="text-center mb-3">
          <dt className="mb-1">
            <h5 className="font-semibold">
              <TextLink href={meetingDetailsUrl}>
                {meeting.name}
              </TextLink>
            </h5>
          </dt>
          {renderMdtName()}
          <dd className="mb-1">
            {meeting.specialtyName}
          </dd>
          <dd className="mb-1">
            <MeetingDateTime
              startDate={meeting.startDate}
              endDate={meeting.endDate}
            />
          </dd>
          <dd className="mb-1">
            <MeetingStatus meeting={meeting} />
          </dd>
          {renderMeetingTimeToStart()}
        </dl>

        <Button href={meetingDetailsUrl} variant={StyleVariants.BLUE}>
          {t("meeting:viewMeeting")}
        </Button>
        {renderJoinButton()}
      </div>

      <MeetingQuickViewTabs meetingId={props.meetingId} />
    </div>
  )
}

export default MeetingQuickView
