import React, { useEffect, useState } from "react"
import { IExternalUser } from "../../features/externalUser/state/externalUserTypes"
import { useSelector } from "react-redux"
import { getAllExternalUserEntities } from "../selectors/externalUser"
import useExternalUserCookie from "../hooks/useExternalUserCookie"
import { Dictionary } from "lodash"
import { useDispatch } from "react-redux"
import { fetchExternalAccountsAction } from "../../features/user/state/userActions"
import _ from "lodash"

export interface ContextProps {
  fetchExternalUsers?: () => void
  externalUsers?: IExternalUser[]
  groupedExternalUsers?: Dictionary<IExternalUser[]>
  totalTenantCount?: number
}

export const ExternalUsersContext = React.createContext<Partial<ContextProps>>({})

ExternalUsersContext.displayName = "ExternalUsersContext"

interface Props {
  children?: React.ReactNode
}

export const ExternalUsersContextProvider: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const entityExternalUsers: IExternalUser[] = useSelector(getAllExternalUserEntities)
  const cookieExternalUsers: IExternalUser[] = useExternalUserCookie()
  const [externalUsers, setExternalUsers] = useState<IExternalUser[]>([])
  const [groupedExternalUsers, setGroupedExternalUsers] = useState<Dictionary<IExternalUser[]>>({})
  const [totalTenantCount, setTotalTenantCount] = useState<number>(0)

  useEffect(() => {
    fetchExternalUsers()
  }, [])

  useEffect(() => {
    const combinedExternalUsers: IExternalUser[] = [
      ...entityExternalUsers,
      ...cookieExternalUsers
    ]
    setExternalUsers(combinedExternalUsers)

    const combinedTotalTenantCount: number = _.sumBy(combinedExternalUsers, (externalUser) => externalUser.tenants.length)
    setTotalTenantCount(combinedTotalTenantCount)

    const _groupedExternalUsers: Dictionary<IExternalUser[]> = _.groupBy(combinedExternalUsers, "email")
    setGroupedExternalUsers(_groupedExternalUsers)
  }, [cookieExternalUsers, entityExternalUsers])

  const fetchExternalUsers = (): void => {
    dispatch(fetchExternalAccountsAction())
  }

  return (
    <ExternalUsersContext.Provider
      value={{
        fetchExternalUsers,
        externalUsers,
        groupedExternalUsers,
        totalTenantCount
      }}
    >
      {props.children}
    </ExternalUsersContext.Provider>
  )
}
