import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { specialtySchema } from "../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { SpecialtyActionTypes } from "./specialtyTypes"

function* fetchSpecialtiesAsync(_action): Generator {
  try {
    const response: any = yield call(API.get, "/specialties", withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [specialtySchema])

    yield put({
      type: SpecialtyActionTypes.FETCH_SPECIALTIES_ASYNC_SUCCESS,
      payload: normalizedResponse
    })
  } catch (error) {
    yield put({
      type: SpecialtyActionTypes.FETCH_SPECIALTIES_ASYNC_ERROR,
      payload: error
    })
  }
}

function* watchFetchSpecialtiesAsync(): Generator {
  yield takeLeading(SpecialtyActionTypes.FETCH_SPECIALTIES_ASYNC_PENDING, fetchSpecialtiesAsync)
}

export { watchFetchSpecialtiesAsync }
