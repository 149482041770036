import React from "react"
import _ from "lodash"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Modal from "react-modal"
import { IAppState } from "../../../../app/appTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import { ICase } from "../../state/caseTypes"
import AddUsersForm from "../../../../shared/components/user/AddUsersForm"
import { updateCaseMembers } from "../../state/caseActions"

interface Props {
  caseId: string
  closeModal: () => void
  isOpen: boolean
}

const CaseMemberAddModal: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "case"])
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, props))

  const updateUsers = (values, { setSubmitting }) => {
    const data = {
      id: theCase.id,
      memberIds: [...theCase.members, ...values.users],
      teamIds: values.teams
    }
    dispatch(updateCaseMembers(data))
    setSubmitting(false)
    props.closeModal()
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("case:inviteMembersIntro")}
        </h2>

        <div className="mb-3">
          <p className="text-ch-blue-600">{t("case:inviteMembersDesc1")}</p>
          <p className="text-ch-blue-600">{t("case:inviteMembersDesc2")}</p>
        </div>

        <div className="w-full">
          <AddUsersForm
            submitHandler={updateUsers}
            excludedUserIds={theCase.members}
            userLabel={t("shared:chooseUsers", { user: _.lowerCase(t("shared:member")) })}
            teamUserListHeader={t("case:teamUserListHeader")}
            includeGuests
            buttonText={
              <Trans
                i18nKey="shared:addUsers"
                values={{
                  users: t("shared:members"),
                  entity: t("shared:case")
                }}
              />}
          />
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default CaseMemberAddModal
