export type ICaseClassification = {
  id: string
  name: string
  caseType: string
  children?: string[]
  parentId?: string
}

export interface IFetchCaseClassificationAsyncAction {
  type: string
}

export enum CaseClassificationActionTypes {
  FETCH_CASE_CLASSIFICATION_ASYNC_PENDING = "@@caseClassification/FETCH_CASE_CLASSIFICATION_ASYNC_PENDING",
  FETCH_CASE_CLASSIFICATION_ASYNC_SUCCESS = "@@caseClassification/FETCH_CASE_CLASSIFICATION_ASYNC_SUCCESS",
  FETCH_CASE_CLASSIFICATION_ASYNC_ERROR = "@@caseClassification/FETCH_CASE_CLASSIFICATION_ASYNC_ERROR",
}
