import { Form, Formik, FormikProps } from "formik"
import * as KeyCode from "keycode-js"
import classNames from "classnames"
import React, { useEffect, useMemo, useRef } from "react"
import { useTimer } from "react-timer-hook"
import _, { throttle } from "lodash"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import MentionField from "../../../shared/components/forms/MentionField"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { createChatMessageAsync } from "../state/messageActions"
import { INewMessageData } from "../state/messageTypes"
import { messageNewValidation } from "../state/validation/messageValidation"
import { IChat } from "../../chat/state/chatTypes"
import { IAppState } from "../../../app/appTypes"
import { getChatById } from "../../../shared/selectors/message"
import { updateUserIsTyping } from "../../chat/state/chatActions"
import { getCurrentUser } from "../../../shared/selectors/user"
import MessageAssetsField from "./assetsField/MessageAssetsField"

interface Props {
  chatId: string
}

const MessageNewForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  const dispatch = useDispatch()
  const createChatMessage = (chatId: string, message: INewMessageData) => dispatch(createChatMessageAsync(chatId, message))

  const initialValues: INewMessageData = {
    body: "",
    assetsAttributes: []
  }
  const formikFormRef = useRef<FormikProps<INewMessageData>>(null)
  let pressedKeys = []

  const currentUser = useSelector(getCurrentUser)

  if(!props.chatId) return null

  const getExpiryTimestamp = (): Date => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + 3)
    return time
  }
  const {
    seconds,
    restart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: getExpiryTimestamp()
  })

  const broadcastIsTyping = (isTyping: boolean) => {
    dispatch(updateUserIsTyping(props.chatId, currentUser.id, isTyping))
  }

  const handleStartTyping = () => {
    if(!formikFormRef.current.values.body) return
    restart(getExpiryTimestamp())
    broadcastIsTyping(true)
  }

  const throttledUpdateUserIsTyping = useMemo(() => throttle(handleStartTyping, 1000, { leading: true }), [])

  useEffect(() => {
    if(seconds == 0) broadcastIsTyping(false)
  }, [seconds])

  const onMessageFieldKeyDown = (event: React.KeyboardEvent)=> {
    pressedKeys = _.uniq([...pressedKeys, event.key])
    if(
      _.includes(pressedKeys, KeyCode.VALUE_RETURN) &&
      !_.includes(pressedKeys, KeyCode.VALUE_SHIFT)
    ) {
      event.preventDefault()
      formikFormRef.current.submitForm()
      broadcastIsTyping(false)
    } else {
      if(
        !formikFormRef.current.values.body ||
        _.includes(pressedKeys, KeyCode.VALUE_RETURN)
      ) return
      throttledUpdateUserIsTyping()
    }
  }

  const onMessageFieldKeyUp = (event: React.KeyboardEvent)=> {
    const withoutKeyUp = pressedKeys.filter((key) => key != event.key)
    pressedKeys = _.uniq(withoutKeyUp)
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={messageNewValidation}
        validateOnChange
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createChatMessage(props.chatId, values)
          setSubmitting(false)
          resetForm()
        }}
        innerRef={formikFormRef}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          setFieldValue,
          isValid
        }) => {
          return (
            <Form className="w-full">
              <div className="mb-2 relative">
                <MentionField
                  name="body"
                  setValue={(description) => setFieldValue("body", description)}
                  onBlur={handleBlur}
                  onKeyDown={onMessageFieldKeyDown}
                  onKeyUp={onMessageFieldKeyUp}
                  users={null}
                  value={values.body}
                  height={62}
                  isDisabled={chat?.endedAt !== null}
                />
                <div className={classNames(
                  "", {
                    "relative": values.assetsAttributes.length,
                    "absolute": !values.assetsAttributes.length,
                    "right-2": !values.assetsAttributes.length,
                    "bottom-2": !values.assetsAttributes.length
                  }
                )}>
                  <MessageAssetsField />
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  isDisabled={isSubmitting || !isValid || chat?.endedAt !== null}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                  key="1"
                >
                  {t("message:new:buttons:send")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default MessageNewForm
