import React from "react"
import classNames from "classnames"

interface IPropsFromParent {
  buttons: JSX.Element[]
  className?: string
  reverse?: boolean
}

type IProps = IPropsFromParent

const Buttons: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-wrap",
    "flex-1",
    "justify-center",
  ]
  if (props.reverse) {
    defaultClasses.push("flex-row-reverse")
  }

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <ul className={allClasses}>
      {props.buttons.map((button: JSX.Element, index: number) => {
        return (
          <li
            className={
              classNames("mb-2", {
                "ml-2": index !== 0
              }
              )} key={index}>
            {button}
          </li>
        )
      })}
    </ul>
  )
}

export default Buttons
