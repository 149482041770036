import React from "react"
import { useTranslation } from "react-i18next"
import IconCalendarCircle from "../../../../shared/components/calendar/IconCalendarCircle"
import IconCalendarSquare from "../../../../shared/components/calendar/IconCalendarSquare"
import { MeetingRoles, MeetingStatuses } from "../../../meeting/state/meetingTypes"

const MeetingCalendarKey: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["meeting"])

  const renderContent = (): JSX.Element => {
    return (
      <dl className="flex flex-wrap lg:flex-col lg:mt-2 lg:ml-2 mb-2">
        <dt className="lg:mb-5">
          {t("meeting:calendarKey")}
        </dt>
        <dd className="flex items-center lg:mb-5">
          <IconCalendarCircle className="mx-2 h-3 w-3" colorKey={MeetingStatuses.Scheduled}></IconCalendarCircle>
          {t("meeting:scheduled")}
        </dd>
        <dd className="flex items-center lg:mb-5">
          <IconCalendarCircle className="mx-2 h-3 w-3" colorKey={MeetingStatuses.InProgress}></IconCalendarCircle>
          {t("meeting:inProgress")}
        </dd>
        <dd className="flex items-center lg:mb-5">
          <IconCalendarCircle className="mx-2 h-3 w-3" colorKey={MeetingStatuses.Ended}></IconCalendarCircle>
          {t("meeting:ended")}
        </dd>
        <dd className="flex items-center lg:mb-5">
          <IconCalendarSquare className="mx-2 h-3 w-3" colorKey={MeetingRoles.Coordinator}></IconCalendarSquare>
          {t("meeting:youAreCoordinator")}
        </dd>
        <dd className="flex items-center lg:mb-5">
          <IconCalendarSquare className="mx-2 h-3 w-3" colorKey={MeetingRoles.Invited}></IconCalendarSquare>
          {t("meeting:youAreInvited")}
        </dd>
      </dl>
    )
  }

  return renderContent()
}

export default MeetingCalendarKey
