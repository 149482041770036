/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import Label from "./Label"
import InputField from "./InputField"
import FieldError from "./FieldError"
import Button from "../buttons/Button"
import { StyleVariants } from "../../state/sharedTypes"
import SelectField from "./SelectField"
import { TSelectFieldOptions } from "./StandardSelectField"
import { ISelectFieldOption } from "./types"
import { useDispatch } from "react-redux"
import { displayUserMessageAction } from "../../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../features/userMessage/state/userMessageTypes"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"

interface IPostcodeLookupData {
  postcode: string
  address: string
}

interface IAddressResult {
  addressLine1: string
  addressLine2: string
  city: string
  county: string
  country: string
}

export interface AddressFieldNames {
  postcode: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  county?: string
  country?: string
}

interface IPropsFromParent {
  handleChange: (e: React.ChangeEvent<Element>) => void
  fieldNames: AddressFieldNames
}

type Props = IPropsFromParent

const PostcodeLookup: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared"])
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue
  } = useFormikContext<IPostcodeLookupData>()
  const [lookupResult, setLookupResult] = useState<IAddressResult[]>(null)
  const [resultOptions, setResultOptions] = useState<TSelectFieldOptions>(null)

  useEffect(() => {
    if (!lookupResult) return
    const formattedOptions: TSelectFieldOptions = lookupResult.map((address: IAddressResult, index: number) => {
      const label: string = _.reject(Object.values(address), _.isEmpty).join(", ")
      return {
        value: index.toString(),
        label: label
      } as ISelectFieldOption
    })

    setResultOptions(formattedOptions)
  }, [lookupResult])

  const onClickLookup = async () => {
    const postcode = values[props.fieldNames.postcode]
    await API.get(`/address?postcode=${postcode}`, withAuthHeader()).then((response) => {
      const addresses: IAddressResult[] = response.data.addresses
      setLookupResult(addresses)
    }).catch((_error) => {
      dispatch(displayUserMessageAction({
        messageKey: "noAddressesFound",
        type: UserMessageTypes.ERROR
      }))
    })
  }

  const onSelectAddress = (e: React.ChangeEvent<any>): void => {
    const selectedResult: IAddressResult = lookupResult[e.currentTarget.value]
    for (const [key, value] of Object.entries(selectedResult)) {
      const fieldName = props.fieldNames[key]
      if (fieldName) setFieldValue(fieldName, value)
    }
    props.handleChange(e)
  }

  const renderResultSelect = (): JSX.Element => {
    if (!resultOptions) return null

    return (
      <div className="mb-2 lg:w-1/2">
        <SelectField
          placeholder={t("shared:selectAddress")}
          name={`${props.fieldNames.postcode}Address`}
          options={resultOptions}
          onChange={onSelectAddress}
          onBlur={handleBlur}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="mb-2">
        <Label name={props.fieldNames.postcode}>
          {t("shared:postcode")}
        </Label>
        <div className="flex lg:w-1/2">
          <div className="flex flex-1">
            <InputField
              type="text"
              name={props.fieldNames.postcode}
              onChange={props.handleChange}
              onBlur={handleBlur}
              value={values[props.fieldNames.postcode]}
              autoComplete="off"
            />
          </div>
          <Button
            variant={StyleVariants.BLUE}
            className="ml-2"
            action={onClickLookup}
          >
            {t("shared:lookUp")}
          </Button>
        </div>

        <FieldError errorMessage={errors[props.fieldNames.postcode] as string} isVisible={(errors[props.fieldNames.postcode] && touched[props.fieldNames.postcode]) as boolean} />
      </div>

      {renderResultSelect()}
    </div>
  )
}

export default PostcodeLookup
