import { Reducer, AnyAction } from "redux"
import _ from "lodash"
import { SharedActionTypes } from "../../../shared/state/sharedTypes"
import { tenantConfigContextInitialState } from "../contexts/TenantConfigContext"
import {
  ITenantConfigContextState,
  TenantConfigActionTypes,
} from "./tenantConfigTypes"
import { AdminActionTypes } from "../../admin/state/adminTypes"

export const initialState: ITenantConfigContextState = tenantConfigContextInitialState

export const tenantConfigReducer: Reducer<ITenantConfigContextState> = (
  state: ITenantConfigContextState = initialState,
  action: AnyAction
): ITenantConfigContextState => {
  switch (action.type) {
    case SharedActionTypes.REHYDRATE:
      if(!action.payload?.tenantConfig) return { ...state }
      return  _.merge({}, state, action.payload.tenantConfig )

    case TenantConfigActionTypes.FETCH_TENANT_CONFIG_ASYNC_SUCCESS:
      return  _.merge({}, state, action.payload )

    case AdminActionTypes.UPLOAD_TENANT_LOGO_ASYNC_SUCCESS:
      return  _.merge({}, state, action.payload )

    default:
      return { ...state }
  }
}
