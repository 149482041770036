import { IAppState } from "../../../app/appTypes"
import { IChat, IHaveChatId } from "../../../features/chat/state/chatTypes"
import { IHaveMessageAssetId, IHaveMessageId, IMessage, IMessageAsset } from "../../../features/message/state/messageTypes"

export const getAllChatIds = (state: IAppState): string[] => state.entities.chats.allIds

export const getChatById = (state: IAppState, ownProps: IHaveChatId): IChat =>
  state.entities.chats.byId[ownProps.chatId]

export const getMessageById = (state: IAppState, ownProps: IHaveMessageId): IMessage =>
  state.entities.messages.byId[ownProps.messageId]

export const getMessageAssetById = (state: IAppState, ownProps: IHaveMessageAssetId): IMessageAsset =>
  state.entities.messageAssets.byId[ownProps.messageAssetId]
