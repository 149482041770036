/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { IChatNewFormData } from "../../state/chatNewTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import ChatNewSelectCaseModal from "./ChatNewSelectCaseModal"
import ChatNewSelectedCaseDetails from "./ChatNewSelectedCaseDetails"

interface Props {
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const ChatNewCaseField: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const closeModal = () => setIsModalOpen(false)
  const openModal = () => setIsModalOpen(true)

  const renderSelectCaseButton = (): JSX.Element => {
    if(props.values.caseId) return null

    return (
      <Button
        variant={StyleVariants.BLUE}
        action={openModal}
      >
        {t("message:new:buttons:selectCase")}
      </Button>
    )
  }
  return (
    <div className="flex flex-col items-center">
      <h3 className="text-sm text-ch-gray-500 italic mb-2">{t("message:new:selectCase")}</h3>
      <div className="flex justify-center">
        { renderSelectCaseButton() }
        <ChatNewSelectCaseModal
          closeModal={closeModal}
          isOpen={isModalOpen}
          values={props.values}
          setFieldValue={props.setFieldValue}
        />
        <ChatNewSelectedCaseDetails
          openModal={openModal}
          values={props.values}
          setFieldValue={props.setFieldValue}
        />
      </div>
    </div>
  )
}

export default ChatNewCaseField
