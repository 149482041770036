/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import CaseListItemContainer from "../../../cases/components/list/CaseListItemContainer"
import { IChatNewFormData } from "../../state/chatNewTypes"

interface Props {
  caseId: string
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  closeModal: () => void
}

const ChatNewCaseListItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "message"])
  if (!props.caseId) return null

  const onClickSelect = () => {
    props.setFieldValue("caseId", props.caseId)
    props.closeModal()
  }

  const renderSelectButton = (): JSX.Element => {
    if(props.values.caseId == props.caseId) return(
      <div className="text-ch-gray-alt-500">
        {t("shared:selected")}
      </div>
    )
    return (
      <Button
        action={onClickSelect}
        variant={StyleVariants.ORANGE}
        key={props.caseId}
      >
        {t("message:new:buttons:selectCase")}
      </Button>
    )
  }

  return (
    <CaseListItemContainer caseId={props.caseId} key={props.caseId}>
      <DetailsCardButtons buttons={[
        renderSelectButton()
      ]} />
    </CaseListItemContainer>
  )
}

export default ChatNewCaseListItem
