import React from "react"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { lookupLiveMeetingViewerForAsset } from "../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../shared/selectors/asset"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { updateSharedViewSelectedAssetAction } from "../../state/liveMeetingActions"

interface IProps {
  assetId: string
}

const LiveMeetingSharedViewAssetViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["asset"])
  const dispatch = useDispatch()
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const updateSharedViewSelectedAsset = (assetId: string, assetTypeFilters: IAssetTypeFilters) => dispatch(updateSharedViewSelectedAssetAction(assetId, assetTypeFilters))

  if (!asset) return <h3 className="text-lg font-bold text-center">{t("asset:assetNotFound")}</h3>

  const AssetViewer = lookupLiveMeetingViewerForAsset(asset)

  if (!AssetViewer) return <h3 className="text-lg font-bold text-center">{t("asset:couldNotDisplayAsset")}</h3>

  return (
    <AssetViewer assetId={props.assetId} onSelectAsset={updateSharedViewSelectedAsset} />
  )
}

export default LiveMeetingSharedViewAssetViewer
