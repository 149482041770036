import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconScribe({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M4.5 1.5C3.667 1.5 3 2.167 3 3v12a1.5 1.5 0 001.5 1.5h3v-1.432L9.068 13.5H4.5V12h6.068l1.5-1.5H4.5V9h9.068L15 7.567V6l-4.5-4.5h-6m5.25 1.125l4.125 4.125H9.75V2.625m5.363 7.125a.413.413 0 00-.3.12l-.766.765 1.568 1.56.765-.758a.43.43 0 000-.592l-.975-.975a.408.408 0 00-.292-.12m-1.508 1.328L9 15.69v1.56h1.56l4.612-4.613-1.567-1.56z"
        />
        <path d="M0 0h18v18H0z" />
      </g>
    </svg>
  );
}

export default SvgIconScribe;
