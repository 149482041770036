export enum UserMessageTypes {
  MESSAGE = "message",
  ERROR = "error",
  SUCCESS = "success"
}

// Models
export interface IUserMessage {
  messageKey: string
  type: string
  messageArgs?: unknown
}

// State
export interface IUserMessageState extends IUserMessage {
  isRead: boolean
}

// Actions
export interface IDisplayUserMessageAction {
  type: string
  payload: IUserMessage
}

export interface IMarkUserMessageReadAction {
  type: string
}

// Action Types
export enum UserMessageActionTypes {
  DISPLAY_USER_MESSAGE = "@@userMessage/DISPLAY_USER_MESSAGE",
  MARK_USER_MESSAGE_READ = "@@userMessage/MARK_USER_MESSAGE_READ"
}
