import { IBaseAction } from "../../../shared/types/baseActionTypes"

export enum AnalyticsActionTypes {
  FETCH_HOSPITAL_DATA_PENDING = "@@analytics/FETCH_HOSPITAL_DATA_PENDING",
  FETCH_HOSPITAL_DATA_SUCCESS = "@@analytics/FETCH_HOSPITAL_DATA_SUCCESS",
  FETCH_HOSPITAL_DATA_ERROR = "@@analytics/FETCH_HOSPITAL_DATA_ERROR",

  FETCH_USER_DATA_PENDING = "@@analytics/FETCH_USER_DATA_PENDING",
  FETCH_USER_DATA_SUCCESS = "@@analytics/FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_ERROR = "@@analytics/FETCH_USER_DATA_ERROR"
}

export interface IFetchHospitalDataAction extends IBaseAction {
  payload: number
}

export interface IFetchUserDataAction extends IBaseAction {
  payload: {
    userId: string
    year: number
  }
}

export type THospitalAnalyticsMeetingData = {
  name: string
  meetings: number
}

export type THospitalAnalyticsMontlyData = {
  month: string
  totalMeetings: number
  totalRecommendations: number
}

export type THospitalMetaData = {
  fromYear: number
}

export interface IHospitalAnalyticsState {
  meetings: THospitalAnalyticsMeetingData[]
  monthlyData: THospitalAnalyticsMontlyData[]
  metaData: THospitalMetaData
}

export type TUserMeetingData = {
  [name: string]: {
    name: string
    meetingData: {
      month: string,
      meetings: number
    }[]
  }
}

export type TUserMonthlySpecialtyMeetingData = {
  attended: number[]
  invited: number[]
  contributed: number[]
  mdtsAttended: number[]
  mdtsInvited: number[]
  mdtsContributed: number[]
}

export type TUserMonthlyMeetingData = {
  [name: string]: {
    name: string
    monthlyData: TUserMonthlySpecialtyMeetingData
  }
}

export type TUserMonthlyRecommendationData = {
  [name: string]: {
    name: string
    monthlyData: {
      live: number[]
      async: number[]
    }
  }
}

export type TUserMetaData = {
  fromYear: number
  months: string[]
}

export interface IUserAnalyticsState {
  mdtMeetings: TUserMeetingData
  monthlyMeetingData: TUserMonthlyMeetingData
  monthlyRecommendationData: TUserMonthlyRecommendationData
  metaData: TUserMetaData
}

export interface IAnalyticsState {
  hospital: IHospitalAnalyticsState
  user: IUserAnalyticsState
}
