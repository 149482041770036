import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getUserById } from "../../../../shared/selectors/user"

interface Props {
  userId: string
}

const ChatUserName: React.FC<Props> = (props: Props): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserById(state, props.userId))
  if (!user) return null

  return <span>{`${user.firstName} ${user.lastName}`}</span>
}

export default ChatUserName
