import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { RoutePaths } from "../../app/routes/Routes"
import SignInForm from "../../features/account/components/SignInForm"
import history from "../../app/routes/history"
import BrowserWarning from "../../shared/components/browser/BrowserWarning"
import { useAppDispatch } from "../../shared/hooks"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"

const SignInPage: React.FC = (): JSX.Element => {
  const apiToken = useSelector((state: IAppState) => state.userSession.apiToken)
  const dispatch = useAppDispatch()
  const search = window.location.search
  const params = new URLSearchParams(search)

  useEffect(() => {
    if (apiToken) {
      history.replace(RoutePaths.ROOT)
    }
  }, [apiToken])


  useEffect(() => {
    const userMessage = params.get("userMessage")
    if (!userMessage) return
    const message = {
      messageKey: userMessage,
      type: UserMessageTypes.SUCCESS
    }
    dispatch(displayUserMessageAction(message))
    setTimeout(() => history.replace(RoutePaths.SIGN_IN), 0)
  }, [])

  useEffect(() => {
    const userMessage = params.get("userError")
    if (!userMessage) return
    const message = {
      messageKey: userMessage,
      type: UserMessageTypes.ERROR
    }
    dispatch(displayUserMessageAction(message))
    setTimeout(() => history.replace(RoutePaths.SIGN_IN), 0)
  }, [])

  return (
    <div>
      <BrowserWarning />
      <SignInForm />
    </div>
  )
}


export default SignInPage
