import { AnyAction, combineReducers, Reducer } from "redux"
import IPatientsUIState, { PatientsActionTypes, PatientsOrderByOptions } from "../patientsTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalPatientsCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return action.payload.totalItems

    default:
      return state
  }
}
export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = PatientsOrderByOptions.UpdatedAtDesc, action: AnyAction): string => {
  switch (action.type) {
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const patientsUIReducer: Reducer<IPatientsUIState> = combineReducers({
  totalPatientsCount,
  totalPages,
  resultsPerPage,
  searchTerm,
  orderBy
})
