import { AnyAction, combineReducers, Reducer } from "redux"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"
import IChatsUIState, { ChatsActionTypes } from "../chatsTypes"

export const selectedChatId: Reducer<string> = (state = null, action: AnyAction): string => {
  switch (action.type) {
    case ChatsActionTypes.UPDATED_SELECTED_CHAT_ID:
      return action.payload

    default:
      return state
  }
}

export const totalItems: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return action.payload.totalItems
    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const chatsUIReducer: Reducer<IChatsUIState> = combineReducers({
  selectedChatId,
  totalItems,
  totalPages,
  resultsPerPage,
})
