import * as React from "react";
const SvgLogoSectra = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={151.281}
    height={29.469}
    viewBox="0 0 40.027 7.797"
    {...props}
  >
    <defs>
      <clipPath id="logo-sectra_svg__a">
        <path d="M425.871 757.781H445V780h-19.129zm0 0" />
      </clipPath>
      <clipPath id="logo-sectra_svg__b">
        <path d="M446 757.781h14V779h-14zm0 0" />
      </clipPath>
      <clipPath id="logo-sectra_svg__c">
        <path d="M461 757.781h20V780h-20zm0 0" />
      </clipPath>
      <clipPath id="logo-sectra_svg__d">
        <path d="M499 757.781h19V779h-19zm0 0" />
      </clipPath>
      <clipPath id="logo-sectra_svg__e">
        <path d="M518 757.781h20.582V779H518Zm0 0" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#logo-sectra_svg__a)"
      transform="matrix(.35278 0 0 .35278 -150.106 -267.196)"
    >
      <path
        fill="currentColor"
        d="M431.016 779.133c-1.684 0-2.961-.336-3.836-1.008-.871-.672-1.309-1.656-1.309-2.957v-3.816h8.414v1.484c0 .523.078.906.238 1.14.16.239.422.356.774.356.363 0 .629-.129.793-.379.164-.254.246-.672.246-1.258 0-.656-.078-1.117-.234-1.379-.157-.261-.547-.52-1.172-.773l-5.703-2.305c-1.309-.535-2.196-1.11-2.66-1.726-.465-.617-.696-1.461-.696-2.535v-2.254c0-1.293.438-2.274 1.309-2.938.875-.668 2.152-1.004 3.836-1.004h8.488c1.684 0 2.957.336 3.828 1.004.871.664 1.305 1.645 1.305 2.938v2.496h-8.352v-.266c0-.465-.09-.816-.273-1.058-.18-.239-.442-.36-.79-.36-.296 0-.534.094-.718.277-.18.188-.27.438-.27.747 0 .304.082.554.246.742.165.191.575.422 1.235.691l6.64 2.801c.844.363 1.438.871 1.774 1.527.34.653.508 1.645.508 2.97v2.331c0 1.547-.418 2.684-1.25 3.418-.832.73-2.125 1.094-3.883 1.094zm0 0" />
    </g>
    <g
      clipPath="url(#logo-sectra_svg__b)"
      transform="matrix(.35278 0 0 .35278 -150.106 -267.196)"
    >
      <path
        fill="currentColor"
        d="M446.41 778.98v-21.07h13.281v4.8h-5.054v2.31h5.054v4.8h-5.054v4.36h5.054v4.8zm0 0" />
    </g>
    <g
      clipPath="url(#logo-sectra_svg__c)"
      transform="matrix(.35278 0 0 .35278 -150.106 -267.196)"
    >
      <path
        fill="currentColor"
        d="M480.219 770.39v4.208c0 1.613-.406 2.773-1.211 3.476-.805.707-2.137 1.059-3.996 1.059h-8.594c-1.73 0-2.996-.363-3.8-1.094-.802-.734-1.204-1.879-1.204-3.441v-11.785c0-1.84.375-3.141 1.121-3.895.746-.758 2.043-1.137 3.883-1.137h8.594c1.957 0 3.316.375 4.074 1.117.754.743 1.133 2.047 1.133 3.915v2.773h-8.352v-1.531c0-.551-.082-.938-.246-1.172-.164-.23-.437-.348-.816-.348-.364 0-.63.121-.793.36-.164.242-.246.628-.246 1.16v8.78c0 .532.082.919.246 1.161.164.242.43.36.793.36.363 0 .629-.122.804-.368.172-.246.258-.629.258-1.152v-2.445zm0 0" />
    </g>
    <path
      fill="currentColor"
      d="M21.174 7.61V1.872H19.68V.178h5.869V1.87h-1.493v5.74zm0 0" />
    <g
      clipPath="url(#logo-sectra_svg__d)"
      transform="matrix(.35278 0 0 .35278 -150.106 -267.196)"
    >
      <path
        fill="currentColor"
        d="M507.375 762.71v2.981h.91c.43 0 .738-.113.918-.332.184-.218.274-.601.274-1.14 0-.567-.09-.957-.266-1.176-.176-.223-.488-.332-.926-.332zm2.102 16.27v-7.183c0-.559-.09-.95-.266-1.172-.176-.223-.488-.336-.926-.336h-.91v8.691h-8.227v-21.07h13.332c1.91 0 3.274.387 4.094 1.164.817.778 1.227 2.059 1.227 3.84 0 1.418-.262 2.5-.785 3.238-.524.739-1.395 1.262-2.61 1.563 1.215.289 2.09.883 2.621 1.789.535.902.801 2.254.801 4.055v5.421zm0 0" />
    </g>
    <g
      clipPath="url(#logo-sectra_svg__e)"
      transform="matrix(.35278 0 0 .35278 -150.106 -267.196)"
    >
      <path
        fill="currentColor"
        d="M527.547 769.77h1.734l-.847-7.16zm-9.262 9.21 3.711-21.07h12.875l3.711 21.07h-8.516l-.468-4.562h-2.356l-.469 4.562zm0 0" />
    </g>
  </svg>
);
export default SvgLogoSectra;

