const SvgIconDicom = (props) => (
  <svg
    width={48}
    height={50}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.803 3.18c0-.559 0-.948.025-1.252.024-.297.068-.469.133-.598a1.5 1.5 0 0 1 .672-.672c.13-.065.3-.11.598-.133C6.535.5 6.924.5 7.483.5h10.032v3.094c0 .258 0 .482.014.666.016.194.05.388.144.575.145.29.38.526.671.671.187.093.38.128.575.143.184.015.408.015.667.015h3.217V21.82c0 .559 0 .948-.025 1.252-.024.297-.068.469-.133.599a1.5 1.5 0 0 1-.672.67c-.13.066-.3.11-.598.134-.304.025-.693.025-1.252.025H7.483c-.559 0-.948 0-1.252-.025-.297-.024-.469-.068-.598-.133a1.5 1.5 0 0 1-.672-.671c-.065-.13-.11-.302-.133-.6a17.34 17.34 0 0 1-.025-1.251V3.18Z"
      stroke="#80A6A8"
    />
    <path
      d="M0 14.199a1 1 0 0 1 1-1h19.58a1 1 0 0 1 1 1v4.08a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V14.2Z"
      fill="#4E898C"
    />
    <path
      d="M0 14.199a1 1 0 0 1 1-1h19.58a1 1 0 0 1 1 1v4.08a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V14.2Z"
      stroke="#80A6A8"
    />
    <path
      d="M2.908 18.24H1.465v-4.073H2.92c.41 0 .763.081 1.058.244.296.162.523.395.682.698.16.304.241.667.241 1.09 0 .424-.08.789-.24 1.094-.16.305-.388.539-.687.702-.297.163-.652.244-1.066.244Zm-.582-.738h.547c.254 0 .468-.046.642-.136a.87.87 0 0 0 .394-.423c.089-.193.133-.44.133-.744 0-.301-.044-.547-.133-.738a.863.863 0 0 0-.392-.421 1.383 1.383 0 0 0-.642-.136h-.55v2.598ZM6.4 14.166v4.072h-.861v-4.073H6.4Zm4.302 1.425H9.83a.852.852 0 0 0-.098-.3.764.764 0 0 0-.188-.227.833.833 0 0 0-.267-.143 1.02 1.02 0 0 0-.324-.05.967.967 0 0 0-.551.158 1.003 1.003 0 0 0-.364.453c-.086.197-.13.438-.13.72 0 .29.044.534.13.732.088.197.21.346.366.447a.98.98 0 0 0 .543.151c.115 0 .222-.015.32-.045a.767.767 0 0 0 .455-.35.845.845 0 0 0 .108-.29l.87.003a1.626 1.626 0 0 1-.529 1.004c-.149.136-.328.243-.536.323-.207.078-.44.117-.702.117-.363 0-.688-.082-.974-.247a1.75 1.75 0 0 1-.677-.714c-.164-.311-.246-.688-.246-1.131 0-.444.083-.822.25-1.134.167-.311.394-.549.68-.712.287-.164.61-.246.967-.246.236 0 .455.033.656.1.203.066.383.162.54.29.156.126.283.28.381.463.1.183.163.392.19.628Zm4.36.61c0 .445-.084.823-.253 1.134a1.75 1.75 0 0 1-.684.714 1.932 1.932 0 0 1-.97.245c-.362 0-.687-.082-.974-.247a1.754 1.754 0 0 1-.683-.714c-.167-.311-.25-.688-.25-1.131 0-.444.083-.822.25-1.134.167-.311.395-.549.683-.712.287-.164.612-.246.974-.246.36 0 .683.082.97.246.29.164.517.4.684.712.169.312.253.69.253 1.134Zm-.873 0c0-.287-.043-.53-.13-.727a.985.985 0 0 0-.36-.45.968.968 0 0 0-.544-.152.968.968 0 0 0-.545.153.997.997 0 0 0-.362.45c-.085.197-.127.44-.127.727 0 .288.042.53.127.728a.997.997 0 0 0 .362.45.968.968 0 0 0 .545.152c.208 0 .39-.05.545-.153a.985.985 0 0 0 .36-.45c.086-.197.129-.44.129-.727Zm1.51-2.036h1.063l1.121 2.737h.048l1.122-2.736h1.062v4.072h-.836v-2.65h-.033l-1.054 2.63h-.57l-1.053-2.64h-.034v2.66H15.7v-4.073Z"
      fill="#fff"
    />
    <path stroke="#80A6A8" d="m17.683.404 5.241 5.089" />
  </svg>
);
export default SvgIconDicom;

