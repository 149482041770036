import React, { useEffect, useState } from "react"
import fetchBlob from "../../../helpers/fetchBlob"
import LoadingSpinner from "../LoadingSpinner"

type Props = {
  zoom: number
  posX: number
  posY: number
  imageSrc: string
  cursor?: string
  transitionDuration?: number
  imageRef?: React.MutableRefObject<HTMLImageElement>
  parentRef?: React.MutableRefObject<HTMLDivElement>
  attr?: {
    onWheel: React.MouseEventHandler<HTMLDivElement>
    onDoubleClick: React.MouseEventHandler<HTMLDivElement>
    onMouseDown: React.MouseEventHandler<HTMLDivElement>
    onMouseUp: React.MouseEventHandler<HTMLDivElement>
    onMouseLeave: React.MouseEventHandler<HTMLDivElement>
    onMouseMove: React.MouseEventHandler<HTMLDivElement>
  }
}

const ImageZoomImage: React.FC<Props> = (props: Props): JSX.Element => {
  const style = {
    cursor: props.cursor ?? "auto",
    transform: `translate3d(${props.posX}px, ${props.posY}px, 0)scale(${props.zoom})`,
    transition: `transform ease-out ${props.transitionDuration}s`,
    willChange: "transform"
  }

  const attr = {
    style: style,
    ...props.attr,
  }

  const [blobUrl, setBlobUrl] = useState("")

  useEffect(() => {
    fetchBlob(props.imageSrc, setBlobUrl)
  }, [props.imageSrc])

  if(!blobUrl) return <LoadingSpinner />

  return (
    <div className="flex w-full h-full overflow-hidden bg-ch-gray-200 p-2" ref={props.parentRef}>
      <div
       {...attr}
       className="flex flex-1 w-full h-full overflow-hidden justify-center items-center"
      >
        <img
          ref={props.imageRef}
          src={blobUrl}
          style={{
            transition: "transform ease-out 0.05s",
            objectFit: "contain",
            height: "100%",
            maxWidth: "100%"
          }}
        />
      </div>
    </div>
  )
}

export default ImageZoomImage
