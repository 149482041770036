import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import ButtonChangeTracking from "../../../../shared/components/buttons/ButtonChangeTracking"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { getMeetingById, getMeetingCaseById } from "../../../../shared/selectors/meeting"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconFolder, IconMeeting } from "../../../../svg/icons"
import Breadcrumbs from "../../../../shared/components/ui/Breadcrumbs"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import MeetingDetails from "../../../meeting/components/details/MeetingDetails"
import { IMeeting, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import MeetingCaseDetails from "../MeetingCaseDetails"
import DetailsCardWithNested from "../../../../shared/components/layout/detailsCard/DetailsCardWithNested"
import MeetingTimeToStart from "../../../meeting/components/header/MeetingTimeToStart"
import QuickView from "../../../../shared/components/ui/quickView/QuickView"
import MeetingCaseChangeTracking from "./MeetingCaseChangeTracking"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import { fetchAuditEventsAsync } from "../../../audit/state/auditEventActions"
import { IMeetingCase } from "../../state/meetingCaseTypes"

interface IPropsFromParent {
  meetingCaseId: string
  meetingId: string
  renderLockCase?: boolean
}

const MeetingCaseHeader = (props: IPropsFromParent): JSX.Element => {
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))
  const meetingCase = useSelector((state: IAppState): IMeetingCase => getMeetingCaseById(state, props))

  const { t } = useTranslation(["shared", "meeting"])
  const dispatch = useDispatch()

  if (!props.meetingCaseId || !meeting) return null
  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: meeting.id
  })

  const renderBackToMeetingButton = (): JSX.Element => {
    return (
      <Button
        href={meetingDetailsUrl}
        variant={StyleVariants.PURPLE}
        key="1"
      >
        {t("meeting:backToMeeting")}
      </Button>
    )
  }

  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:meetings"),
              href: RoutePaths.MEETINGS
            },
            {
              pageTitle: meeting.name,
              href: meetingDetailsUrl
            },
            {
              pageTitle: t("shared:meetingCaseDetail")
            }
          ]}
        />
        <ButtonChangeTracking
          hasPermission={meetingCase?.permissions?.canTrackChanges}
          action={() => {
            dispatch(fetchAuditEventsAsync("MeetingCase", props.meetingCaseId))
            dispatch(toggleIsQuickViewOpen(props.meetingCaseId, true))
          }}
        />
      </div>
      <DetailsCardWithNested className="flex-col">
        <DetailsCard hasBorder={false}>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-4">
              <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
            </IconCircleContainer>
            <MeetingDetails meetingId={meeting.id} hasLink={false} />
          </DetailsCardInner>

          <DetailsCardRight>
            <div className="text-center lg:text-right mb-2">
              {
                meeting.status == MeetingStatuses.Ended
                  ? t("meeting:meetingEnded")
                  : < MeetingTimeToStart startDate={meeting.startDate} />
              }
            </div>
            <DetailsCardButtons buttons={[
              renderBackToMeetingButton()
            ]} />
          </DetailsCardRight>
        </DetailsCard>

        <DetailsCard isDark={true}>
          <DetailsCardInner width={"w-full"}>
            <IconCircleContainer className="sm:mr-4">
              <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
            </IconCircleContainer>
            <MeetingCaseDetails meetingCaseId={props.meetingCaseId} renderLockCase={props.renderLockCase} />
          </DetailsCardInner>
        </DetailsCard>
      </DetailsCardWithNested>
      <QuickView>
        <MeetingCaseChangeTracking meetingCaseId={props.meetingCaseId} meeting={meeting} />
      </QuickView>
    </div>
  )
}

export default MeetingCaseHeader
