import React from "react"
import LiveMeetingUserGridItem from "./LiveMeetingUserGridItem"
import _ from "lodash"
import { useSelector } from "react-redux"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IPropsFromParent {
  userIds: string[]
}

const LiveMeetingUserGrid: React.FC<IPropsFromParent> = ({ userIds }: IPropsFromParent): JSX.Element => {
  const meetingConfig = useSelector(getLiveMeetingConfig)
  if (!userIds.length) return <span>No users</span>

  const sortedUserList = _.sortBy(userIds, (userId) => userId != meetingConfig.presenterId)
  return (
    <ul className="flex flex-wrap -mx-2">
      {
        sortedUserList.map((userId: string) => <LiveMeetingUserGridItem key={userId} userId={userId} />)
      }
    </ul>
  )
}

export default LiveMeetingUserGrid
