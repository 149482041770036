import { createSelector } from "reselect"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { ICase, ICaseMemberStatus, ICaseMemberStatusByUserId, IHaveCaseId, IHaveCaseIdParam } from "../../../features/case/state/caseTypes"
import { IHaveMeetingCaseId } from "../../../features/meetingCase/state/meetingCaseTypes"
import { IUser } from "../../../features/user/state/userTypes"
import { getAllUsers } from "../user"
import { IMeeting } from "../../../features/meeting/state/meetingTypes"
import { getAllMeetings } from "../meeting"
import { isGuest } from "../../helpers/userHelpers"

export const getAllCaseIds = (state: IAppState): string[] => state.entities.cases.allIds

export const getAllCases = (state: IAppState): { [id: string]: ICase } => state.entities.cases.byId

export const getCaseById = (state: IAppState, ownProps: IHaveCaseId): ICase =>
  state.entities.cases.byId[ownProps.caseId]

export const getCaseByParamId = (state: IAppState, ownProps: IHaveCaseIdParam): ICase => {
  const caseId = ownProps.match.params.caseId
  return state.entities.cases.byId[caseId]
}

export const getCaseMembersStatus = createSelector(
  getCaseById,
  (caseData): ICaseMemberStatusByUserId => {
    if (!caseData) return null

    const caseMemberStatusData: ICaseMemberStatusByUserId = {}

    caseData?.caseMembersStatus?.map((status: ICaseMemberStatus) => {
      caseMemberStatusData[status.userId] = status
    })

    return caseMemberStatusData
  }
)

export const getLeadCareUserByCaseId = createSelector(
  getCaseById,
  getAllUsers,
  (caseData, users): IUser => {
    if (!caseData) return null
    return users[caseData.leadCareUser]
  }
)

export const getCaseByMeetingCaseId = (state: IAppState, ownProps: IHaveMeetingCaseId): ICase => {
  const caseId = state.entities.meetingCases.byId[ownProps.meetingCaseId]?.case
  return state.entities.cases.byId[caseId] ?? null
}

export const getCaseMeetingIds = createSelector(
  getCaseById,
  (caseData): string[] => {
    if (!caseData) return []
    return caseData.meetings
  }
)

export const getMeetingsForCase = createSelector(
  getCaseById,
  getAllMeetings,
  (caseData, allMeetings): IMeeting[] => {
    if (!caseData) return []
    const meetings = _.pick(allMeetings, caseData.meetings)

    return Object.values(meetings)
  }
)

export const getUserIdsForCaseMeetings = createSelector(
  getCaseById,
  getAllMeetings,
  (caseData, allMeetings): string[] => {
    if (!caseData) return []
    return _(allMeetings).pick(caseData.meetings).flatMap((meeting) => meeting.users).uniq().value()
  }
)

export const getCaseMemberIds = createSelector(
  getCaseById,
  (caseData: ICase): string[] => {
    if (!caseData) return []
    return caseData.members
  }
)

// This will only return the case members that are not guests.
export const getCaseMemberUsers = createSelector(
  getAllUsers,
  getCaseMemberIds,
  (allUsers: { [id: string]: IUser }, caseMemberIds: string[]): { [id: string]: IUser } => {
    if (!caseMemberIds) return null
    return _(allUsers).pick(caseMemberIds).pickBy((user) => !isGuest(user)).value()
  }
)

