import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { ICreateTenantAsyncAction, TenantActionTypes } from "../tenantTypes"
import { userSchema } from "../../../../apis/contextmeeting/schema"

export function* createTenantAsync(action: ICreateTenantAsyncAction): Generator {
  try {
    const data = {
      tenant: action.payload.tenant
    }

    const response: any = yield call(API.post, "/tenants", data, withAuthHeader())

    // returns full user that includes tenants
    const normalizedResponse = normalize(response.data, userSchema)
    yield put({
      type: TenantActionTypes.CREATE_TENANT_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "createTenantSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: TenantActionTypes.CREATE_TENANT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createTenantFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateTenantAsync(): Generator {
  yield takeLeading(TenantActionTypes.CREATE_TENANT_ASYNC_PENDING, createTenantAsync)
}
