import { UserConfigActionTypes, IUserConfigFetchAsyncAction, IUserConfigEditData, IUpdateUserConfigAsyncAction } from "./userConfigTypes"

export const fetchUserConfigAsync = (userId: string): IUserConfigFetchAsyncAction => {
  return {
    type: UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_PENDING,
    payload: userId
  }
}

export const updateUserConfigAsync = (userId: string, userData: IUserConfigEditData): IUpdateUserConfigAsyncAction => {
  return {
    type: UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_PENDING,
    payload: {
      id: userId,
      user: userData
    }
  }
}
