import { call, takeLeading } from "redux-saga/effects";
import API from "../../../apis/contextmeeting/api";
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader";
import { handleError, handleSuccess } from "../../../shared/helpers/sagaHelpers";
import { AnalyticsActionTypes, IFetchHospitalDataAction, IFetchUserDataAction } from "./analyticsTypes";

export function* watchAnalyticsActions(): Generator {
  yield takeLeading(AnalyticsActionTypes.FETCH_HOSPITAL_DATA_PENDING, fetchHospitalData)
  yield takeLeading(AnalyticsActionTypes.FETCH_USER_DATA_PENDING, fetchUserData)
}

export function* fetchHospitalData(action: IFetchHospitalDataAction): Generator {
  try {
    const response: any = yield call(API.get, `/analytics/hospital/${action.payload}`, withAuthHeader())
    yield handleSuccess(AnalyticsActionTypes.FETCH_HOSPITAL_DATA_SUCCESS, response.data)
  } catch (error) {
    yield handleError(AnalyticsActionTypes.FETCH_HOSPITAL_DATA_ERROR, error)
  }
}

export function* fetchUserData(action: IFetchUserDataAction): Generator {
  const { userId, year } = action.payload
  try {
    const response: any = yield call(API.get, `/analytics/user/${userId}/${year}`, withAuthHeader())
    yield handleSuccess(AnalyticsActionTypes.FETCH_USER_DATA_SUCCESS, response.data)
  } catch (error) {

    yield handleError(AnalyticsActionTypes.FETCH_USER_DATA_ERROR, error)
  }
}
