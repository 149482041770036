import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconClose({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M18.75 17L17 18.75l-4.5-4.5-4.5 4.5L6.25 17l4.5-4.5L6.25 8 8 6.25l4.5 4.5 4.5-4.5L18.75 8l-4.501 4.5L18.75 17zM12.5 0C5.596 0 0 5.597 0 12.5 0 19.404 5.596 25 12.5 25 19.403 25 25 19.404 25 12.5 25.024 5.621 19.467.025 12.587 0H12.5z"
        />
        <path d="M-2-3h30v30H-2z" />
      </g>
    </svg>
  );
}

export default SvgIconClose;
