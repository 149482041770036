import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2"
import { useResizeDetector } from "react-resize-detector"
import { ISharedPdfState } from "../../../features/liveMeeting/state/liveMeetingTypes"
import { IconDownload, IconRotate } from "../../../svg/icons"
import Button from "../buttons/Button"
import LoadingSpinner from "./LoadingSpinner"
import Slider from "./Slider"

interface IProps {
  url: string
  filename?: string
  isDownloadable?: boolean
  hideRotateButton?: boolean
  rotateCallback?: (args: ISharedPdfState) => void
  rotation?: number
}

const defaultProps = {
  isDownloadable: true
}

const addExtensionToFilename = (filename: string) => {
  if (!filename) {
    return "document.pdf"
  } else if (!filename.toLowerCase().endsWith(".pdf")) {
    return filename + ".pdf"
  }
  return filename

}
const PdfViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const [numPages, setNumPages] = useState(null)
  const [pageWidth, setPageWidth] = useState(null)
  const [pageScale, setPageScale] = useState(0.8)
  const [rotationState, setRotationState] = useState(props.rotation || 0)

  const onResize = useCallback((width, _height) => {
    setPageWidth(width)
  }, [])

  const { ref } = useResizeDetector({ onResize })

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  // when hiding the rotate button, we are relying on the prop to control the
  // rotation, otherwise it's the component itself that maintains the state
  const rotation = props.hideRotateButton ? props.rotation : rotationState

  const renderPages = () => {
    return (
      Array.from(
        new Array(numPages),
        (_element, index) => {
          const pageNumber = index + 1
          return (
            <div key={`page_${pageNumber}`} className="relative flex flex-col items-center">
              <Page
                pageNumber={pageNumber}
                loading={<LoadingSpinner />}
                width={pageWidth}
                scale={pageScale}
              />
              <div
                className="absolute bottom-8 text-ch-gray-400"
                style={{
                  fontSize: `${pageScale}em`,
                  textShadow: "#FFF 1px 1px 1px"
                }}
              >
                {`Page ${pageNumber}`}
              </div>
            </div>
          )
        }
      )
    )
  }

  const renderDownloadButton = () => {
    if (!props.isDownloadable) return null

    return (
      <div className="sm:absolute right-0">
        <a href={props.url} download={addExtensionToFilename(props.filename)} className="text-ch-blue-500 hover:text-ch-blue-600 p-2 flex items-center">
          <IconDownload className="w-6 h-6" title={t("shared:download")} />
        </a>
      </div>
    )
  }

  const rotateButtonAction = () => {
    const newRotation = (rotation + 90) % 360
    setRotationState(newRotation)
    if (props.rotateCallback) props.rotateCallback({ rotation: newRotation })
  }

  const renderContent = (): JSX.Element => {
    if (!props.url) return <LoadingSpinner />

    return (
      <Document
        loading={<LoadingSpinner />}
        file={props.url}
        onLoadSuccess={onLoadSuccess}
        rotate={rotation}>
        {renderPages()}
      </Document>
    )
  }

  const renderRotateButton = () => {
    if (props.hideRotateButton) return null

    return (
      <Button action={rotateButtonAction} className="ml-2 bg-ch-gray-600 flex justify-center w-20">
        <IconRotate className="w-6 h-8" />
      </Button>
    )
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div
        ref={ref}
        className="flex overflow-auto w-full flex-1 justify-center bg-ch-gray-200 mb-3"
      >
        {renderContent()}
      </div>
      <div className="flex items-center justify-center relative">
        <div className="w-64">
          <Slider
            onChange={(value) =>
              setPageScale(value / 100)
            }
            value={(pageScale * 100)}
            min={30}
            max={200}
          />
        </div>
        {renderRotateButton()}
        {renderDownloadButton()}
      </div>
    </div>
  )
}

PdfViewer.defaultProps = defaultProps

export default PdfViewer
