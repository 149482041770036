import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { toggleIsQuickViewOpen } from "../../../../features/nav/state/navActions"
import { IconClose } from "../../../../svg/icons"
import { getIsQuickViewOpen } from "../../../selectors/ui"

interface IProps {
  store?: MockStore
  children: React.ReactNode
}

const QuickView: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const isQuickViewOpen = useSelector(getIsQuickViewOpen)
  const dispatch = useDispatch()
  const onClickClose = () => { dispatch(toggleIsQuickViewOpen(null, false)) }

  useEffect(() => {
    return (onClickClose)
  }, [])

  return (
    <div className={classNames(
      "py-8 px-3 bg-white shadow h-full sm:w-2/3 md:w-1/3 w-full overflow-y-scroll absolute z-30 top-0 right-0",
      {
        "hidden": !isQuickViewOpen
      }
    )}>
      <div className="flex justify-start">
        <a onClick={() => onClickClose()} className="text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer">
          <IconClose className="fill-current w-6 h-6" title={t("shared:close")} />
        </a>
      </div>
      {props.children}
    </div>
  )
}

export default QuickView
