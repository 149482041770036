import { createSelector } from "reselect"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { CommentTypes, IAsset, ICommentAsset, IHaveAssetId } from "../../../features/assets/state/assetsTypes"
import { getCaseById } from "../case"
import { getMeetingCaseById } from "../meeting"
import { IMeetingCaseAsset } from "../../../features/meetingCase/state/meetingCaseTypes"

export const getAllAssets = (state: IAppState): { [id: string]: IAsset } =>
  state.entities.assets.byId

export const getAssetById = (state: IAppState, ownProps: IHaveAssetId): IAsset =>
  state.entities.assets.byId[ownProps.assetId]

export const getAllMeetingCaseAssetIds = createSelector(
  getMeetingCaseById,
  (meetingCase): IMeetingCaseAsset[] => {
    if (!meetingCase) return []
    return meetingCase.meetingCaseAssets
  }
)

export const getAllCaseAssetIds = createSelector(
  getCaseById,
  (caseData): string[] => {
    if (!caseData) return []
    return caseData.assets
  }
)

export const getLatestRecommendationAssetId = createSelector(
  getCaseById,
  getAllAssets,
  (caseData, allAssets): string => {
    if (!caseData || !allAssets) return null
    return _.find(caseData.assets, (assetId: string) => {
      const comment = allAssets[assetId]?.asset as ICommentAsset
      return comment?.commentType == CommentTypes.Recommendation
    })
  }
)
