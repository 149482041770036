import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconThumbsUp({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M6.7 22H2v-9h2l2.7 9zM20 9h-6V5a3 3 0 00-3-3h-1v4L7.1 9.625A5.02 5.02 0 006 12.76V14l2.1 7h8.337a4 4 0 003.88-3.03l1.622-6.485A2 2 0 0020 9z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconThumbsUp;
