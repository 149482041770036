import { IFetchUsersAsyncAction, UsersActionTypes } from "./usersActionTypes"
import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { IBaseAction } from "../../../shared/types/baseActionTypes"

export const fetchUsersAsync = (searchQuery: ISearchQuery): IFetchUsersAsyncAction => {
  return {
    type: UsersActionTypes.FETCH_USERS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const fetchAllUsersAsync = (): IBaseAction => {
  return {
    type: UsersActionTypes.FETCH_ALL_USERS_ASYNC_PENDING
  }
}
