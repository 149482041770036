/* eslint-disable @typescript-eslint/no-explicit-any */
import * as CSS from "csstype"
import { Field } from "formik"
import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import { ISelectFieldOption, ISelectFieldUserOption } from "./types"

export type TSelectFieldOptions = ISelectFieldOption[]
export type TSelectFieldUserOptions = ISelectFieldUserOption[]

interface IProps {
  className?: string
  name: string
  options: TSelectFieldOptions
  value?: string | string[]
  type?: string
  isMulti?: boolean
  includeBlank?: boolean
  blankOptionsLabel?: string
  autoFocus?: boolean
  autoComplete?: string
  innerRef?: React.RefObject<HTMLInputElement>
  onChange: (e: React.FocusEvent<any>) => void
  onBlur: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEvent<any>) => void
  variant?: string
  isLight?: boolean
}

const defaultProps = {
  includeBlank: true,
  isMulti: false
}

const StandardSelectField: React.FC<IProps> = (props): JSX.Element => {
  let variant = props.variant
  if (!variant) {
    variant = StyleVariants.GRAY
  }

  const renderBlankOption = (): JSX.Element => {
    if (props.includeBlank === false) return
    return (
      <option value="">{props?.blankOptionsLabel}</option>
    )
  }

  const renderOptions = (): JSX.Element[] => {
    return props.options.map((option) => {
      return (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      )
    })
  }

  const fillColor = props.isLight ? "white" : "%23A3C3C4"

  const arrowSVG = `<svg xmlns='http://www.w3.org/2000/svg'><path fill='${fillColor}' viewBox='0 0 9 6' width='9px' height='6px' d='M.5 0a.5.5 0 00-.374.832l4 4.5a.5.5 0 00.748 0l4-4.5A.5.5 0 008.5 0h-8z' /></svg>`

  const backgroundPosition = "calc(100% + 0.5em) 1em"

  const iconStyle: CSS.Properties = {
    WebkitAppearance: "none",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;utf8,${arrowSVG}")`,
    backgroundSize: "2em",
    backgroundPosition: backgroundPosition,
    backgroundRepeat: "no-repeat"
  }

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "flex",
      "mb-1",
      "py-2",
      "px-3",
      "pr-8",
      "leading-tight",
      "focus:outline-none",
      "focus:shadow-outline",
      "appearance-none",
      "focus:ring-2",
      "ring-ch-blue-alt-300"
    ]

    const lightClasses = [
      "text-white",
      "rounded-none",
      `bg-${variant}-400`
    ]

    const normalClasses = [
      "text-ch-gray-600",
      "border-ch-blue-alt-300",
      "rounded",
      "border",
    ]
    const additionalClasses = props.isLight ? lightClasses : normalClasses
    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...additionalClasses, ...propClasses].join(" ")

    const _type = props.type ? props.type : "text"

    return (
      <Field
        as="select"
        innerRef={props.innerRef}
        autoFocus={props.autoFocus}
        type={_type}
        name={props.name}
        id={props.name}
        multiple={props.isMulti}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
        autoComplete={props.autoComplete}
        className={allClasses}
        style={iconStyle}
      >
        {renderBlankOption()}
        {renderOptions()}
      </Field>
    )
  }

  return renderContent()
}

StandardSelectField.defaultProps = defaultProps

export default StandardSelectField
