import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

export const languages = ["en", "fr", "it", "es", "de", "zh", "ja", "nl"]

export const translationFilenames = [
  "account",
  "admin",
  "asset",
  "case",
  "caseReport",
  "liveMeeting",
  "meeting",
  "notification",
  "shared",
  "user",
  "userMessage"
]

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: languages[0],
    fallbackLng: languages[0],
    whitelist: languages,
    interpolation: {
      escapeValue: false
    },
    preload: [languages[0]],
    react: {
      useSuspense: true
    }
  })

export default i18n
