import React, { useContext, useEffect, useRef, useState } from "react"
import { Form, Formik, FormikErrors } from "formik"
import Button from "../../../shared/components/buttons/Button"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { SignInType } from "../../user/state/userTypes"
import { useTranslation } from "react-i18next"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { getUserSessionSignInType } from "../../../shared/selectors/user"
import { signInUserAction, signOutUserAction, userSignInTypeAction } from "../state/accountActions"
import TextLink from "../../../shared/components/text/TextLink"
import ResetPasswordInstructions from "./ResetPasswordInstructions"
import ButtonLink from "../../../shared/components/buttons/ButtonLink"
import Buttons from "../../../shared/components/layout/Buttons"
import { QueryStringContext } from "../../../shared/contexts/QueryStringContextProvider"
import WarningBanner from "../../../shared/components/text/WarningBanner"
import ShowPasswordButton from "./ShowPasswordButton"

type TErrors = FormikErrors<{
  username: string
  password: string
}>

type TValues = {
  username: string
  password: string
}
// This is a temporary solution to allow an O'Driscoll to sign in
// It was taken from Yup: https://github.com/jquense/yup/blob/99aa25787a8ff15fe42e54db88ec3ed547357302/src/string.ts
// Ideally we'd just use a validation schema tha uses Yup directly.
const emailRegex =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const SignInForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "account"])
  const { email } = useContext(QueryStringContext)
  const userFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)
  const [showPasswordReset, updatePasswordReset] = useState(false)
  const dispatch = useDispatch()
  const signInType = useSelector(getUserSessionSignInType)
  const signOutUser = () => dispatch(signOutUserAction())
  const fetchUserSignInType = (email: string) => dispatch(userSignInTypeAction(email))
  const signInUser = (email: string, password: string) => dispatch(signInUserAction(email, password))
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    // TODO: If SignInType is 3party send user to redirect page
    if (signInType === SignInType.Local)
      passwordFieldRef && passwordFieldRef.current && passwordFieldRef.current?.focus()
  }, [signInType])

  useEffect(() => {
    signOutUser()
  }, [])

  const [signInCount, setSignInCount] = useState<{ [email: string]: number }>({})

  const togglePasswordReset = (value: boolean) => {
    updatePasswordReset(value)
  }

  const onSubmitPassword = (email, password) => {
    if (email && password) signInUser(email, password)
    const counts = { ...signInCount }
    counts[email] ||= 0
    counts[email] += 1
    setSignInCount(counts)
  }

  const validEmail = (email) => {
    return emailRegex.test(email)
  }

  const showPasswordField = (email) => {
    return signInType === SignInType.Local && validEmail(email)
  }

  const onSubmitEmail = (username) => {
    if (validEmail(username)) {
      fetchUserSignInType(username)
    }
  }

  const showNotRecognised = (username) => {
    return signInType === SignInType.NotFound && validEmail(username)
  }

  const renderEmailSubmitButton = (values: TValues, isSubmitting: boolean, errors: TErrors) => {
    if (showPasswordField(values.username)) return null
    return (
      <div className="flex justify-center">
        <Button
          action={() => onSubmitEmail(values.username)}
          isSubmit={true}
          isDisabled={isSubmitting || (errors.username !== "")}
          variant={StyleVariants.PURPLE}
        >
          {t("shared:next")}
        </Button>
      </div>
    )
  }

  const renderPasswordSubmitButton = (values: TValues, isSubmitting: boolean) => {
    return (
      <div>
        {renderWarning()}
        <Buttons
          reverse
          buttons={
            [
              <Button
                action={() => onSubmitPassword(values.username, values.password)}
                isSubmit
                key="1"
                isDisabled={isSubmitting}
                variant={StyleVariants.PURPLE}
              >
                {t("shared:next")}
              </Button>,
              <ButtonLink
                action={() => {
                  signOutUser()
                }}
                key="2"
                noPaddingY
                className="py-1"
              >
                {t("shared:back")}
              </ButtonLink>
            ]
          } />
      </div>
    )
  }

  const renderWarning = () => {
    if (!Object.values(signInCount).find(x => x > 4)) return
    return (
      <WarningBanner>
        <p>
          {t("account:accountLockedMessage")}
        </p>
      </WarningBanner >
    )
  }

  return (
    <div>
      <div className={classNames("flex justify-center", {
        hidden: showPasswordReset,
      })}>
        <Formik
          initialValues={{
            username: email,
            password: ""
          }}
          validate={(values) => {
            const errors: TErrors = { username: "" }

            if (!values.username) {
              errors.username = t("shared:required")
            } else if (!validEmail(values.username)) {
              errors.username = t("account:invalidEmail")
            }

            return errors
          }}
          onSubmit={(_values, _actions) => null}
          validateOnMount
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting
          }) => (
            <Form className="flex-1">
              <div className="flex justify-center">
                <div>
                  <h2 className="text-lg mb-4">
                    {t("account:signInTitle")}
                  </h2>
                  <div
                    className={classNames("flex justify-center", "text-ch-blue-alt-400", "mb-2", {
                      hidden: signInType !== SignInType.Local,
                    })}
                  >
                    {values.username}
                  </div>
                </div>
              </div>
              <div
                className={classNames({
                  hidden: signInType === SignInType.Local,
                })}
              >
                <Label name="email">
                  {t("account:email")}
                </Label>
                <InputField
                  innerRef={userFieldRef}
                  autoFocus
                  type="email"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  autoComplete="username"
                />
                <FieldError errorMessage={errors.username} isVisible={(errors.username && touched.username)} />

                <FieldError errorMessage={t("account:emailNotRecognised")} isVisible={showNotRecognised(values.username)} />

                <div className="mt-6">
                  {renderEmailSubmitButton(values, isSubmitting, errors)}
                </div>
              </div>

              <div
                className={classNames({
                  hidden: !showPasswordField(values.username),
                })}
              >
                <Label name="email">
                  {t("account:password")}
                </Label>
                <div className="flex relative items-center">
                  <InputField
                    innerRef={passwordFieldRef}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="current-password password"
                  />
                  <ShowPasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />
                </div>
                <FieldError errorMessage={errors.password} isVisible={(errors.password && touched.password)} />
                <TextLink action={() => togglePasswordReset(true)}>
                  {t("account:forgottenYourPassword")}
                </TextLink>
                <div className="mt-6 flex justify-center">
                  {renderPasswordSubmitButton(values, isSubmitting)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div
        className={classNames("flex justify-center", {
          hidden: !showPasswordReset,
        })}
      >
        <ResetPasswordInstructions usernameRef={userFieldRef} toggleView={togglePasswordReset} />
      </div>
    </div>
  )
}

export default SignInForm
