import { differenceInSeconds } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  startDate: string
}
const MeetingTimeToStart = (props: Props): JSX.Element => {
  const { t } = useTranslation(["meeting"])

  const calculateTimeLeft = (startDate, endDate) => {
    const timeLeftSeconds = differenceInSeconds(startDate, endDate)
    if (timeLeftSeconds < 0) return `0 ${t("minutes")}`

    const days = Math.floor(timeLeftSeconds / 60 / 60 / 24)
    const hours = Math.floor(timeLeftSeconds / 60 / 60) - days * 24
    const minutes = Math.round(timeLeftSeconds / 60) - days * 24 * 60 - hours * 60

    let dayText = ""
    if (days >= 1) {
      dayText = days + " " + t(days > 1 ? "days" : "day")
    }
    let hourText = ""
    if (hours >= 1) {
      hourText = hours + " " + t(hours > 1 ? "hours" : "hour")
    }
    let minuteText = ""
    if (minutes > 0) {
      minuteText = minutes + " " + t(minutes > 1 ? "minutes" : "minute")
    }
    if (dayText !== "") {
      dayText
      const andText = dayText !== "" && hourText !== "" ? ` ${t("and")} ` : ""
      return dayText + andText + hourText
    }
    const andText = hourText !== "" && minuteText !== "" ? ` ${t("and")} ` : ""
    return hourText + andText + minuteText
  }

  const startDate = new Date(props.startDate)
  const now = new Date()

  const timeLeft = calculateTimeLeft(startDate, now)
  const content = `${t("meeting:meetingDue")} ${timeLeft}`

  return <span>{content}</span>
}
export default MeetingTimeToStart
