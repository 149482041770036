import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"
import { getComponentTypeForAsset } from "../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../shared/selectors/asset"
import CaseAssetViewerHeading from "./CaseAssetViewerHeading"
import CaseImageViewer from "./CaseImageViewer"
import CasePdfViewer from "./CasePdfViewer"
import CaseRadiologyViewer from "./CaseRadiologyViewer"

interface IProps {
  assetId: string
}

const CaseAssetViewer: React.FC<IProps> = (props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const { t } = useTranslation(["asset"])

  if (!asset) return <h3 className="text-lg font-bold text-center">{t("asset:assetNotFound")}</h3>

  const assetComponetType = getComponentTypeForAsset(asset)
  const componentLookup = {
    [AssetComponentTypes.Pdf]: CasePdfViewer,
    [AssetComponentTypes.Image]: CaseImageViewer,
    [AssetComponentTypes.CaseReport]: CasePdfViewer,
    [AssetComponentTypes.RadiologyLink]: CaseRadiologyViewer
  }
  const AssetViewer = componentLookup[assetComponetType]

  if (!AssetViewer) return <h3 className="text-lg font-bold text-center">{t("asset:couldNotDisplayAsset")}</h3>

  return (
    <div className="flex flex-col w-full h-full">
      <div className="absolute w-full top-0 left-0 z-0">
        <CaseAssetViewerHeading assetId={props.assetId} />
      </div>
      <div className="flex-1 min-h-0">
        <AssetViewer
          assetId={props.assetId}
        />
      </div>
    </div>
  )
}

export default CaseAssetViewer
