import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import { ICreatedBy } from "../../../../shared/state/sharedTypes"
import { IChat } from "../../../chat/state/chatTypes"

interface Props {
  chatId: string
}

const ChatLatestMessage: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  if(!chat?.latestMessage) return null

  const renderAuthorText = (): JSX.Element => {
    if(chat.users.length <= 1) return null
    const authorUser: ICreatedBy = chat.latestMessage.createdBy
    return <span className="font-semibold">{authorUser.firstName} {authorUser.lastName}:</span>
  }

  const renderUnreadCount = (): JSX.Element => {
    if (!chat.unreadCount || chat.unreadCount == 0) return null

    const countText = chat.unreadCount < 99 ? chat.unreadCount.toString() : "9+"

    return <span className="bg-ch-blue-alt-500 text-white rounded-full w-5 h-5 text-sm flex justify-center items-center p-2">{countText}</span>
  }

  return (
    <div className="flex">
      <div className={classNames("flex-1 truncate text-xs italic", {
        "text-ch-gray-500": (chat.latestMessage.readAt != null),
        "text-ch-blue-alt-500": (chat.latestMessage.readAt == null),
      })}>
        {renderAuthorText()} {chat.latestMessage.body}
      </div>
      {renderUnreadCount()}
    </div>
  )
}

export default ChatLatestMessage
