import { useTranslation } from "react-i18next"
import React, { useEffect } from "react"
import { Formik, useFormikContext } from "formik"
import Label from "../forms/Label"
import SelectField, { TSelectFieldOptions } from "../forms/StandardSelectField"
import { StyleVariants } from "../../state/sharedTypes"
import { ResultsPerPageOptions } from "../../state/sharedTypes"
import { ISearchQuery, ISetSearchQueryFunction } from "../../hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
}

const ValueChangeListener = () => {
  const { submitForm, values } = useFormikContext()
  const value = values

  useEffect(() => {
    if (value) {
      submitForm()
    }
  }, [value, submitForm])

  return null
}

const ResultsPerPage: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const resultsPerPageOptions: TSelectFieldOptions = Object.keys(ResultsPerPageOptions).map((key) => {
    return {
      value: ResultsPerPageOptions[key],
      label: ResultsPerPageOptions[key]
    }
  })

  const initialValues = {
    resultsPerPage: props.searchQuery.resultsPerPage
  }

  const reload = (value) => {
    props.setSearchQuery({ resultsPerPage: value.resultsPerPage })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={reload}
    >
      {({
        values,
        handleChange,
        handleBlur
      }) => {
        return (
          <div className="flex flex-row items-center">
            <Label className="mr-2" name="resultsPerPage">
              {t("resultsPerPage")}
            </Label>
            <SelectField
              options={resultsPerPageOptions}
              type="text"
              name="resultsPerPage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.resultsPerPage.toString()}
              className="pt-2 pb-2 pl-2 pr-2 rounded-none text-ch-gray-100"
              autoComplete="off"
              includeBlank={false}
              variant={StyleVariants.BLUE_ALT}
              isLight={true}
            />
            <ValueChangeListener />
          </div>
        )
      }}
    </Formik>
  )
}

export default ResultsPerPage
