import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import NameUser from "../../../../shared/components/names/NameUser"
import { getCaseById } from "../../../../shared/selectors/case"
import { IconFolder } from "../../../../svg/icons"
import { ICase } from "../../../case/state/caseTypes"

interface Props {
  caseId: string
}

const ChatCaseTag: React.FC<Props> = (props: Props): JSX.Element => {
  const chatCase: ICase = useSelector((state: IAppState) => getCaseById(state, { caseId: props.caseId }))

  if(!chatCase) return null

  return (
    <div className="inline-flex border border-ch-gray-400 rounded-md p-1 mb-2 self-start">
      <IconFolder className="fill-current w-4 h-4 mr-2 text-ch-purple-500" />
      <NameUser
        className="text-xs"
        firstName={chatCase.firstName}
        lastName={chatCase.lastName}
        title={chatCase.title}
        gender={chatCase.gender}
      />
    </div>
  )
}


export default ChatCaseTag
