import { Reducer, AnyAction } from "redux"
import OT from "@opentok/client"
import { WebRTCActionTypes } from "../webRTCTypes"

export const initialState: OT.Publisher = null

export const webRTCPublisherReducer: Reducer<OT.Publisher> = (state: OT.Publisher = initialState, action: AnyAction): OT.Publisher => {
  switch(action.type) {
    case WebRTCActionTypes.PUBLISH_TO_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case WebRTCActionTypes.UPDATE_WEB_RTC_PUBLISHER:
      return {
        ...state,
        ...action.payload.publisher
      }

    case WebRTCActionTypes.UPDATE_WEB_RTC_SESSION:
      return Object.assign({}, state, { session: action.payload.session })

    case WebRTCActionTypes.UNPUBLISH_FROM_WEB_RTC_SESSION_SUCCESS:
      return initialState

    default:
      return state
  }
}

