import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { updateSelectedChatId } from "../../features/chats/state/chatsActions"
import IChatsUIState from "../../features/chats/state/chatsTypes"
import ChatList from "../../features/chats/components/list/ChatList"
import { getChatsUI } from "../../shared/selectors/ui"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import ChatView from "../../features/chat/components/ChatView"
import classNames from "classnames"
import ChatNewForm from "../../features/chatNew/components/ChatNewForm"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import PageTitle from "../../features/chats/components/ui/PageTitle"
import useIsMobileView from "../../shared/hooks/useIsMobileView"

const MessagesPage: React.FC = (): JSX.Element => {
  const {
    messagingEnabled
  } = useContext(TenantConfigContext)

  const dispatch = useDispatch()
  const updateSelectedChat = (chatId: string) => dispatch(updateSelectedChatId(chatId))

  const UI: IChatsUIState = useSelector((state: IAppState) => getChatsUI(state))

  const isMobileView = useIsMobileView(["sm", "md"])
  const [isNewChatFormOpen, setIsNewChatFormOpen] = useState(!isMobileView)

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    dispatch(fetchSpecialtiesAsync())
  }, [])

  useEffect(() => {
    if (UI.selectedChatId !== null) {
      setIsNewChatFormOpen(false)
    }
  }, [UI.selectedChatId])

  if (!messagingEnabled) return null

  return (
    <div className="flex flex-col h-full">
      <PageTitle
        selectedChatId={UI.selectedChatId}
        setSelectedChatId={updateSelectedChat}
        setIsNewChatFormOpen={setIsNewChatFormOpen}
        isNewChatFormOpen={isNewChatFormOpen}
      />

      <div className="flex flex-1 h-full bg-white border border-ch-gray-400 rounded-md overflow-hidden relative">
        <div
          className={classNames(
            "flex flex-col border-r border-ch-gray-400 bg-white w-full h-full absolute z-10 lg:w-1/3 lg:static",
            {
              "hidden": UI.selectedChatId !== null || (isMobileView && isNewChatFormOpen),
              "lg:flex": UI.selectedChatId !== null
            }
          )}
        >
          <ChatList
            selectedChatId={UI.selectedChatId}
            setSelectedChatId={updateSelectedChat}
          />
        </div>
        <div className={classNames("flex flex-1", {
          hidden: !isNewChatFormOpen
        })}>
          <ChatNewForm />
        </div>
        <div className={classNames("flex flex-1 overflow-hidden", {
          hidden: isNewChatFormOpen
        })}>
          <ChatView chatId={UI.selectedChatId} />
        </div>
      </div>
    </div>
  )
}

export default MessagesPage
