import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import Card from "../../../../shared/components/layout/Card"
import { useAppSelector } from "../../../../shared/hooks"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconHome } from "../../../../svg/icons"
import { ITenant, IUser } from "../../../user/state/userTypes"
import queryString from "query-string"

interface Props {
  tenant: ITenant
  setTenant: (tenantId: string) => void
  location: string
  email: string
}

const SwitchTenantListItem: React.FC<Props> = (props: Props) => {
  const currentUser: IUser = useAppSelector(getCurrentUser)
  const { t } = useTranslation(["shared", "tenant"])

  const goToTenant = (): void => {
    if(window.location.origin == props.location) {
      props.setTenant(props.tenant.id)
      return
    }

    const query = queryString.stringify({
      tenantId: props.tenant.id,
      email: props.email
    })

    window.location.href = `${props.location}/signin?${query}`
  }

  const renderButton = (): JSX.Element => {
    if(
      currentUser.currentTenantId == props.tenant.id &&
      currentUser.email == props.email &&
      window.location.origin == props.location
    ) return (
      <span>{t("tenant:currentTenant")}</span>
    )

    return (
      <Button
        variant={StyleVariants.ORANGE}
        action={goToTenant}
      >
        {t("tenant:selectTenant")}
      </Button>
    )
  }

  return (
    <div key={props.tenant.id}>
      <Card className="flex-col">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center">
            <IconCircleContainer className="sm:mr-4">
              <IconHome className="fill-current w-6 h-6" title={t("shared:case")} />
            </IconCircleContainer>
            <div className="flex-row">
              <h3 className="mr-4 mb-2 text-lg font-semibold text-ch-blue-500">
                {props.tenant.name}
              </h3>
              <h4 className="text-ch-gray-500">
                {props.location}
              </h4>
            </div>
          </div>
          {renderButton()}
        </div>
      </Card>
    </div>

  )
}

export default SwitchTenantListItem
