import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconLink({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M10.156 18.076l-.99.955a2.262 2.262 0 01-3.108 0 2.061 2.061 0 01-.638-1.485c0-.558.23-1.093.638-1.486l3.64-3.506c.753-.728 2.172-1.798 3.207-.801.303.314.758.442 1.187.334.43-.108.763-.435.871-.852a1.156 1.156 0 00-.352-1.152c-1.756-1.694-4.355-1.381-6.618.801l-3.64 3.508a4.351 4.351 0 000 6.308 4.686 4.686 0 003.26 1.3 4.679 4.679 0 003.26-1.3l.99-.956c.228-.219.357-.517.358-.83a1.156 1.156 0 00-.352-.83 1.24 1.24 0 00-1.713-.008zM20.642 4.41c-1.894-1.79-4.541-1.886-6.292-.23l-1.235 1.17c-.308.29-.43.716-.319 1.115.111.399.437.711.855.82.418.108.865-.004 1.173-.295l1.236-1.17c.906-.858 2.095-.503 2.873.23.411.39.639.909.639 1.46 0 .552-.228 1.07-.64 1.46l-3.893 3.678c-1.78 1.68-2.615.893-2.971.556a1.255 1.255 0 00-1.173-.295 1.188 1.188 0 00-.855.82c-.11.4.01.824.318 1.115.818.772 1.751 1.155 2.728 1.155 1.197 0 2.461-.574 3.665-1.711l3.894-3.678A4.252 4.252 0 0022 7.511a4.245 4.245 0 00-1.358-3.101z"
        />
        <path d="M0 0h25v25H0z" />
      </g>
    </svg>
  );
}

export default SvgIconLink;
