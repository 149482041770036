import React from "react"
import { useSelector } from "react-redux"
import { componentLookup, getComponentTypeForAsset } from "../../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../../shared/selectors/asset"
import { IAppState } from "../../../../../app/appTypes"
import { AssetComponentTypes } from "../../../../assets/state/assetsTypes"

interface IProps {
  assetId: string
  openAssetModal: (assetId: string) => void
}

const ResponseAsset: React.FC<IProps> = (props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  if (!props.assetId || !asset) return null
  type responseAssetType = AssetComponentTypes.Pdf | AssetComponentTypes.Image
  const assetComponetType = getComponentTypeForAsset(asset) as responseAssetType
  const Asset = componentLookup[assetComponetType]

  return (
    <Asset
      action={() => props.openAssetModal(props.assetId)}
      assetId={props.assetId}
    />
  )
}

export default ResponseAsset
