import { createSelector } from "reselect"
import { IAppState } from "../../../app/appTypes"
import { IAddPathwayToCaseUIState } from "../../../features/addPathwayToCase/state/addPathwayToCaseTypes"
import { IHavePathwayId, IPathway } from "../../../features/pathway/pathwayTypes"
import { getCaseById } from "../case"
import { getAddPathwayToCaseUI } from "../ui"


export const getPathway = (ownProps: IHavePathwayId) => (state: IAppState): IPathway =>
  state.entities.pathways.byId[ownProps.pathwayId]

export const getPathwayById = (state: IAppState, ownProps: IHavePathwayId): IPathway =>
  state.entities.pathways.byId[ownProps.pathwayId]

export const getPathwaysAddableToCaseIds = createSelector(
  getAddPathwayToCaseUI,
  (UI: IAddPathwayToCaseUIState): string[] => {
    if (!UI) return []
    return UI.resultIds
  }
)

export const getAllCasePathwayIds = createSelector(
  getCaseById,
  (caseData): string[] => {
    if(!caseData) return []
    return caseData.casePathways
  }
)
