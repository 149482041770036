import { useFormikContext } from "formik"
import React, { useContext, useEffect } from "react"
import { FormContext } from "../../../contexts/FormContextProvider"


const WizardForceUpdate: React.FC = (): JSX.Element => {
  const { values } = useFormikContext()
  const { forceUpdate } = useContext(FormContext)

  useEffect(() => {
    setTimeout(() => {
      forceUpdate()
    })
  }, [values])

  return <></>
}

export default WizardForceUpdate
