import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import { RoutePaths } from "../../../../app/routes/Routes"
import { generatePath } from "react-router"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"

interface IProps {
  userId: string
  hideButtons?: boolean
  buttons?: JSX.Element[]
}

const defaultProps = {
  buttons: []
}

const UserListItem: React.FC<IProps> = (props: IProps) => {
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))
  if (!user) return null
  const { t } = useTranslation(["shared", "user"])
  const editUserUrl = generatePath(RoutePaths.USER_EDIT, { userId: props.userId })

  const renderButtons = (): JSX.Element[] => {
    if(props.hideButtons) return null

    if(props.buttons.length) return props.buttons

    if(user?.permissions?.canEdit) return [
      <Button
        href={editUserUrl}
        variant={StyleVariants.BLUE}
        key={props.userId}
      >
        {t("shared:edit")}
      </Button>
    ]

  }

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner className="flex-1">
          <UserDetailsCard user={user} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={renderButtons()}
          />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

UserListItem.defaultProps = defaultProps

export default UserListItem
