import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { UserConfigActionTypes } from "./userConfigTypes"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import history from "../../../app/routes/history"
import { RoutePaths } from "../../../app/routes/Routes"

export function* fetchUserConfigAsync(action: Effect): Generator {
  try {
    const userId = action.payload
    const response: any = yield call(API.get, `/users/${userId}/user_config`, withAuthHeader())

    yield put({
      type: UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    yield put({
      type: UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_ERROR,
      payload: error,
    })
  }
}

export function* watchFetchUserConfigAsync(): Generator {
  yield takeLeading(
    UserConfigActionTypes.FETCH_USER_CONFIG_ASYNC_PENDING,
    fetchUserConfigAsync
  )
}

export function* updateUserConfigAsync(action: Effect): Generator {
  const user = {
    user: action.payload.user
  }

  try {
    const response: any = yield call(API.patch, `/users/${action.payload.id}/user_config`, user, withAuthHeader())

    yield put({
      type: UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "updateUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, RoutePaths.MEETINGS)
  } catch (error) {
    yield put({
      type: UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateUserConfigAsync(): Generator {
  yield takeLeading(UserConfigActionTypes.UPDATE_USER_CONFIG_ASYNC_PENDING, updateUserConfigAsync)
}
