import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { ChatsActionTypes } from "../../../chats/state/chatsTypes"
import { ChatActionTypes, ChatCableActionTypes, IChat } from "../chatTypes"
import { ChatMessagesActionTypes } from "../../../messages/state/messagesTypes"
import { ChatNewActionTypes } from "../../../chatNew/state/chatNewTypes"
import { normalize } from "normalizr"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"

type TByIdState = { [id: string]: IChat }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.chats)

    case ChatActionTypes.FETCH_CHAT_ASYNC_SUCCESS:
    case ChatActionTypes.END_CHAT_ASYNC_SUCCESS:
    case ChatActionTypes.TOGGLE_MUTED_CHAT_ASYNC_SUCCESS:
    case ChatActionTypes.EXPORT_CHAT_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.entities.chats)
      )

    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.chatId]: {
          ...state[action.payload.chatId],
          messages: _.uniq([
            ...state[action.payload.chatId].messages || [],
            ...action.payload.all.result
          ])
        }
      }

    case ChatNewActionTypes.CREATE_CHAT_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.chats)


    case ChatActionTypes.FETCH_CHAT_ASSETS_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }

      return {
        ...state,
        [action.payload.chatId]: {
          ...state[action.payload.chatId],
          messageAssets: action.payload.all.result
        }
      }

    case ChatCableActionTypes.DELIVER_CHAT:
      return _.merge({}, state, normalize(action.payload.chat, fetchChatsSchema).entities.chats)

    case ChatCableActionTypes.DELIVER_MESSAGE:
      return {
        ...state,
        [action.payload.message.chatId]: {
          ...state[action.payload.message.chatId],
          latestMessage: action.payload.message,
          unreadCount: action.payload.unreadCount,
          messages: _.uniq([
            action.payload.message.id,
            ...state[action.payload.message.chatId].messages || []
          ])
        }
      }

    case ChatCableActionTypes.UPDATE_MESSAGE:
      if (
        state[action.payload?.message?.chatId]?.latestMessage?.id !==
        action.payload.message.id
      ) return state
      return {
        ...state,
        [action.payload.message.chatId]: {
          ...state[action.payload.message.chatId],
          unreadCount: action.payload.unreadCount,
          latestMessage: action.payload.message
        }
      }

    case ChatCableActionTypes.UPDATE_UNREAD_COUNT:
      return {
        ...state,
        [action.payload.chatId]: {
          ...state[action.payload.chatId],
          unreadCount: action.payload.unreadCount
        }
      }

    case ChatCableActionTypes.MARK_USER_START_TYPING:
      if (!state[action.payload.chatId]) return state
      if (state[action.payload.chatId].options?.muted) return state

      return {
        ...state,
        [action.payload.chatId]: {
          ...state[action.payload.chatId],
          usersTypingIds: _.uniq([
            ...state[action.payload.chatId].usersTypingIds || [],
            action.payload.userId
          ])
        }
      }

    case ChatCableActionTypes.MARK_USER_STOP_TYPING:
      if (!state[action.payload.chatId]) return state

      return {
        ...state,
        [action.payload.chatId]: {
          ...state[action.payload.chatId],
          usersTypingIds: _.remove(state[action.payload.chatId].usersTypingIds, (userId: string) => {
            userId !== action.payload.userId
          })
        }
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return _.uniq([
        ...state || [],
        ...action.payload.all.result
      ])

    case ChatNewActionTypes.CREATE_CHAT_ASYNC_SUCCESS:
      return _.uniq(_.concat(action.payload.all.result, state))

    case ChatCableActionTypes.DELIVER_MESSAGE:
      return _.uniq(_.concat(action.payload.message.chatId, state))

    case ChatCableActionTypes.DELIVER_CHAT:
      return _.uniq(_.concat(action.payload.chat.id, state))

    default:
      return state
  }
}

export const chatEntityReducer: Reducer<INormalizedEntities<IChat>> = combineReducers({
  byId,
  allIds
})
