import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const messageNewValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    body: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
  })
}
