import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconUser({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.18 16.364c4.067 0 7.364 3.418 7.365 7.635v2.364c0 1.507-1.178 2.728-2.63 2.728H2.63C1.178 29.09 0 27.87 0 26.363V24c.001-4.216 3.297-7.633 7.363-7.635h.915a9.228 9.228 0 007.988 0h.915zM12.734 0a7.273 7.273 0 11-7.278 7.28v-.013A7.273 7.273 0 0112.733 0z"
      />
    </svg>
  );
}

export default SvgIconUser;
