import React from "react"
import { useTranslation } from "react-i18next"
import ButtonFilter from "../../../../shared/components/buttons/ButtonFilter"
import { ISearchQuery, ISetSearchQueryFunction } from "../../../../shared/hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
}

const UserFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["user"])

  const checkIsOn = (filter) => {
    return props.searchQuery.appliedFilters.includes(filter)
  }

  const resetFilters = () => {
    props.setSearchQuery({ appliedFilters: [] })
  }

  const updateFilters = (filter) => {
    resetFilters()
    props.setSearchQuery({
      appliedFilters: [filter]
    })
  }

  return (
    <ul className="flex flex-wrap">
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("active")}
          action={() => {
            updateFilters("active")
          }}
        >
          <p className="uppercase">{t("user:active")}</p>
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("pending")}
          action={() => {
            updateFilters("pending")
          }}
        >
          <p className="uppercase">{t("user:pending")}</p>
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("inactive")}
          action={() => {
            updateFilters("inactive")
          }}
        >
          <p className="uppercase">{t("user:inActive")}</p>
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("guest")}
          action={() => {
            updateFilters("guest")
          }}
        >
          <p className="uppercase">{t("user:guest")}</p>
        </ButtonFilter>
      </li>
    </ul>
  )
}

export default UserFilter
