/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors, FormikTouched } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IRecommendationNewData } from "../../../../../assets/state/assetsTypes"
import { IUser } from "../../../../../user/state/userTypes"
import { IAppState } from "../../../../../../app/appTypes"
import MentionField from "../../../../../../shared/components/forms/MentionField"
import Label from "../../../../../../shared/components/forms/Label"
import FieldError from "../../../../../../shared/components/forms/FieldError"
import RequestConsentItem from "./RequestConsentItem"
import DatePickerField from "../../../../../../shared/components/datePicker/DatePickerField"
import SvgIconTime from "../../../../../../svg/icons/IconTime"
import TimeInput from "../../../../../../shared/components/time/TimeInput"
import { getCaseById } from "../../../../../../shared/selectors/case"
import { useParams } from "react-router"
import { getMeetingCaseById, getMeetingById } from "../../../../../../shared/selectors/meeting"
import { IHaveMeetingCaseId } from "../../../../../meetingCase/state/meetingCaseTypes"
import { TenantConfigContext } from "../../../../../tenantConfig/contexts/TenantConfigContext"

interface IProps {
  mentionableUsers: { [id: string]: IUser }
  caseId: string
  errors: FormikErrors<IRecommendationNewData>
  touched: FormikTouched<IRecommendationNewData>
  values: IRecommendationNewData
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const RecommendationFields: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["shared", "case", "asset"])
  const tenantConfig = useContext(TenantConfigContext)
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const requestConsentList = props.values.requestConsentUserIds
  const currentCase = useSelector((state: IAppState) => getCaseById(state, props))

  const toggleEnabledForUser = (userId: string) => (
    () => {
      let newList: string[]
      if (requestConsentList.includes(userId)) {
        newList = requestConsentList.filter((id) => id != userId)
      } else {
        newList = requestConsentList.concat([userId])
      }
      props.setFieldValue("requestConsentUserIds", newList)
    }
  )

  let userList: string[]
  if (meetingCaseId) {
    const meetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, { meetingCaseId }))
    const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId: meetingCase.meeting }))
    userList = meeting.users
  } else {
    userList = currentCase.members
  }

  const approvalList = userList.map((userId: string) => {
    return (
      <div className="my-2" key={userId}>
        <RequestConsentItem userId={userId} key={userId} enabled={requestConsentList.includes(userId)} toggleEnabled={toggleEnabledForUser(userId)} />
      </div>
    )
  })

  const selectAll = () => {
    props.setFieldValue("requestConsentUserIds", userList)
  }

  return (
    <div>
      <div className="mb-3">
        <Label name="body" required={true}>
          {t("asset:recommendation")}
        </Label>
        <MentionField
          name="body"
          onBlur={props.handleBlur}
          users={props.mentionableUsers}
          value={props.values.body}
          setValue={(body: string) => { props.setFieldValue("body", body) }}
        />

        <FieldError errorMessage={props.errors.body as string} isVisible={props.errors.body && props.touched.body} />
      </div>

      <div className="mb-3">
        <div className="flex">
          <Label name="body" required={true} className={"flex-1"}>
            {tenantConfig.recommendationConsentHeader || t("asset:approvalRequiredFrom")}:
          </Label>
          <div onClick={selectAll} className="font-bold text-ch-blue-500 hover:text-ch-blue-600 uppercase inline-flex cursor-pointer">
            {t("shared:selectAll")}
          </div>
        </div>
        <div className="mb-2 py-2 px-3 rounded border border-ch-blue-alt-300 appearance-none">
          {approvalList}
          <FieldError errorMessage={props.errors.requestConsentUserIds as string} isVisible={(props.touched.requestConsentUserIds)} />
        </div>
      </div>

      <div className="mb-3">
        <div>
          <Label name="deadlineDate" required={true}>
            {t("asset:approvalDeadline")}:
          </Label>
          <div className="flex flex-wrap">
            <div className="flex mr-4 mb-1">
              <div className="flex flex-col">
                <DatePickerField name="deadlineDate" />
                <div className="lg:h-8 my-1 ml-7 sm:h-0">
                  <FieldError errorMessage={props.errors.deadlineDate as string} isVisible={(props.errors.deadlineDate && props.touched.deadlineDate) as boolean} />
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="flex space-between items-center">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <SvgIconTime className="text-ch-blue-500 mx-2 w-5 h-5" />
                    <TimeInput
                      name="deadlineTime"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </div>
                  <div className="h-8 my-1">
                    <FieldError errorMessage={props.errors.deadlineTime as string} isVisible={props.errors.deadlineTime && props.touched.deadlineTime} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default RecommendationFields
