import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { generatePath } from "react-router"
import { MeetingUserActionTypes } from "../meetingUserTypes"
import API from "../../../../apis/contextmeeting/api"
import { fetchMeetingSchema } from "../../../../apis/contextmeeting/schema"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import history from "../../../../app/routes/history"
import { RoutePaths } from "../../../../app/routes/Routes"

export function* createMeetingUser(action: Effect): Generator {
  try {
    const meetingUser = {
      meetingUser: action.payload
    }

    // responds with the full meeting
    const response: any = yield call(API.post, "/meeting_users", meetingUser, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "createMeetingUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewMeetingUrl = generatePath(RoutePaths.MEETING, {
      meetingId: action.payload.meetingId
    })

    yield call(history.push, viewMeetingUrl)
  } catch (error) {
    yield put({
      type: MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createMeetingUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateMeetingUser(): Generator {
  yield takeLeading(MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_PENDING, createMeetingUser)
}

export function* updateMeetingUser(action: Effect): Generator {
  try {
    const meetingUser = {
      meetingUser: action.payload.meetingUser
    }

    const response: any = yield call(API.patch, `/meeting_users/${action.payload.id}`, meetingUser, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "updateMeetingUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewMeetingUrl = generatePath(RoutePaths.MEETING, {
      meetingId: response.data.id
    })

    yield call(history.push, viewMeetingUrl)
  } catch (error) {
    yield put({
      type: MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateMeetingUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdateMeetingUser(): Generator {
  yield takeLeading(MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_PENDING, updateMeetingUser)
}

export function* destroyMeetingUser(action: Effect): Generator {
  try {
    const meetingUserId = action.payload

    const response: any = yield call(API.delete, `/meeting_users/${meetingUserId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "destroyMeetingUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewMeetingUrl = generatePath(RoutePaths.MEETING, {
      meetingId: response.data.id
    })

    yield call(history.push, viewMeetingUrl)
  } catch (error) {
    yield put({
      type: MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "destroyMeetingUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDestroyMeetingUser(): Generator {
  yield takeLeading(MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_PENDING, destroyMeetingUser)
}
