import React, { useState } from "react"
import classNames from "classnames"
import _ from "lodash"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import Card from "../../../../shared/components/layout/Card"
import CaseMemberListItem from "./CaseMemberListItem"
import { getCaseById } from "../../../../shared/selectors/case"
import { ICase } from "../../state/caseTypes"
import InviteButton from "../../../../shared/components/buttons/InviteButton"
import CaseMemberAddModal from "./CaseMemberAddModal"
import CaseGuestUserAddModal from "./guestUsers/CaseGuestUserAddModal"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import Button from "../../../../shared/components/buttons/Button"
import ShareAssetsModal from "../../../caseSharedAssets/components/ShareAssetsModal"
import { useDispatch } from "react-redux"
import { fetchCaseAction } from "../../state/caseActions"
import { useAppSelector } from "../../../../shared/hooks"
import { getTenantConfig } from "../../../../shared/selectors/tenantConfig"

interface Props {
  caseId: string
}

const CaseMembers: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const dispatch = useDispatch()
  const [isVisibleList, toggleList] = useState(false)
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, props))
  const [addMemberModalIsOpen, setAddMemberModalIsOpen] = useState(false)
  const [inviteGuestUserModalIsOpen, setInviteGuestUserModalIsOpen] = useState(false)
  const [shareAssetsModalIsOpen, setShareAssetsModalIsOpen] = useState(false)
  const [selectedGuestUser, setSelectedGuestUser] = useState(null)
  const { guestUsersEnabled } = useAppSelector(getTenantConfig)

  if (!theCase) return null

  const renderAddMembersButtons = () => {
    if (!theCase.permissions?.canInvite || theCase.locked) return null

    return (
      <div className="flex gap-2 flex-wrap">
        <InviteButton
          openModal={() => {
            setAddMemberModalIsOpen(true)
            toggleList(true)
          }}
          label={t("case:inviteMembers")}
        />
        {
          guestUsersEnabled ?
            <Button
              action={() => setInviteGuestUserModalIsOpen(true)}
              className="mb-2"
              variant={StyleVariants.BLUE_ALT}
            >
              {t("case:inviteGuest")}
            </Button>
            : null
        }
      </div>
    )
  }

  const onClickShareAssets = (userId: string) => {
    setSelectedGuestUser(userId)
    setShareAssetsModalIsOpen(true)
  }

  const onCloseShareAssetsModal = () => {
    dispatch(fetchCaseAction(props.caseId))
    setShareAssetsModalIsOpen(false)
  }

  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("case:members")} ({theCase.members?.length || 0})
          </h3>
          {renderAddMembersButtons()}
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans i18nKey="case:caseUsersIntro" components={{ strong: <strong /> }} />
          </p>
          <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("case:showUsers")} collapseText={t("case:hideUsers")} />
        </div>

        <ul className={classNames({ hidden: !isVisibleList })}>
          {_.map(theCase.members, (userId) => {
            return (
              <CaseMemberListItem
                key={userId}
                userId={userId}
                caseId={props.caseId}
                onClickShareAssets={onClickShareAssets}
              />
            )
          })}
        </ul>
      </Card>

      <CaseMemberAddModal
        caseId={props.caseId}
        isOpen={addMemberModalIsOpen}
        closeModal={() => setAddMemberModalIsOpen(false)}
      />

      <CaseGuestUserAddModal
        caseId={props.caseId}
        isOpen={inviteGuestUserModalIsOpen}
        closeModal={() => setInviteGuestUserModalIsOpen(false)}
      />

      <ShareAssetsModal
        caseId={props.caseId}
        userId={selectedGuestUser}
        isOpen={shareAssetsModalIsOpen}
        closeModal={onCloseShareAssetsModal}
      />
    </div>
  )
}

export default CaseMembers
