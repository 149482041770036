import React from "react"
import { Icon } from "@iconify/react"
import { IconifyIcon } from "@iconify/types"
import flagUnitedKingdom from "@iconify/icons-emojione/flag-for-united-kingdom"
import flagAustralia from "@iconify/icons-emojione/flag-for-australia"

interface IProps {
  flagKey: string
  className?: string
}

type TFlagLookup = {
  [flagKey: string]:  IconifyIcon
}

// TODO: Add lots more flags...
const flagLookup: TFlagLookup = {
  // Europe
  "Europe/London": flagUnitedKingdom,
  // Australia
  "Australia/Sydney": flagAustralia
}

const Flag: React.FC<IProps> = ({ flagKey, className }: IProps): JSX.Element => {
  const getFlag = (): IconifyIcon => {
    const defaultFlag: IconifyIcon = flagUnitedKingdom
    return flagLookup[flagKey] || defaultFlag
  }

  return <Icon icon={getFlag()} className={className} />
}

export default Flag
