import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconFiles } from "../../../../../svg/icons"
import { Formik, Form } from "formik"
import Label from "../../../../../shared/components/forms/Label"
import { uploadCaseFileAction } from "../../../../assets/state/assetsActions"
import InputField from "../../../../../shared/components/forms/InputField"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { IUploadCaseFileFormValues } from "../../../../assets/state/assetsTypes"
import FieldError from "../../../../../shared/components/forms/FieldError"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../meetingCase/state/meetingCaseTypes"
import { caseFileNewValidation } from "../../../state/validation/caseFileNewValidation"
import FileUploadField from "../../../../../shared/components/forms/FileUploadField"


interface IProps {
  caseId: string
  closeAction: () => void
  switchToNewAssetTab: () => void
}

const CaseFileNewForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "asset"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const dispatch = useDispatch()
  const uploadFile = (caseId: string, values: IUploadCaseFileFormValues, meetingCaseId: string) => dispatch(uploadCaseFileAction(caseId, values, meetingCaseId))

  const handleSubmit = (values: IUploadCaseFileFormValues, { setSubmitting }) => {
    uploadFile(props.caseId, values, meetingCaseId)
    setSubmitting(false)
    props.closeAction()
    props.switchToNewAssetTab()
  }

  const initialValues = {
    file: null,
    name: "",
    meetingCaseId
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconFiles className="w-6 h-6 mr-2 text-ch-purple-500" title={t("asset:uploadFile")} />
        {t("asset:uploadFile")}
      </h4>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={caseFileNewValidation}
      >
        {({ errors, setFieldValue, isSubmitting, values, handleChange, handleBlur, isValid }) => (
          <Form>
            <div>
              <div className="mb-2">
                <Label name="file">
                  {t("asset:selectFile")}:
                </Label>
                <FileUploadField
                  name="file"
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0])
                  }}
                />
                <FieldError
                  errorMessage={errors.file as string}
                  isVisible={errors.file != null}
                />
              </div>
              <div className="mb-5">
                <Label name="name">
                  {t("asset:fileName")}:
                </Label>
                <InputField
                  name={"name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}>
                </InputField>
                <FieldError
                  errorMessage={errors.name as string}
                  isVisible={errors.name != null}
                />
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    key="1"
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    key="2"
                    isDisabled={values.file == null || !isValid || isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                  >
                    {t("asset:uploadFile")}
                  </Button>
                ]} />
            </div>
          </Form>
        )
        }
      </Formik >
    </div >
  )
}


export default CaseFileNewForm
