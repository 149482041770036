import { createSelector } from "reselect"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { IHaveNotificationId, INotification } from "../../../features/notification/state/notificationTypes"
import { IUser } from "../../../features/user/state/userTypes"
import { getAllUsers } from "../user"

export const getAllNotificationIds = (state: IAppState): string[] => state.entities.notifications.allIds

export const getNotificationById = (state: IAppState, ownProps: IHaveNotificationId): INotification => {
  const notificationId = ownProps.notificationId
  return state.entities.notifications.byId[notificationId]
}

export const getNotificationUsers = createSelector(
  getNotificationById,
  getAllUsers,
  (notification, allUsers): { [id: string]: IUser } => {
    return _.pickBy(allUsers, (user: IUser) => {
      return _.includes(notification.users, user.id)
    })
  }
)

export const getNotificationSenderUser = createSelector(
  getNotificationById,
  getAllUsers,
  (notification, allUsers): IUser => {
    return allUsers[notification.senderId]
  }
)
