import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const tenantNewValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    name: Yup
      .string()
      .trim().required(i18n.t("shared:required"))
  })
}
