import { createSelector } from "reselect"
import { getCurrentUser } from "../user"
import { ITenant, IUser } from "../../../features/user/state/userTypes"

export const getAllUserTenants = createSelector(
  getCurrentUser,
  (user: IUser): ITenant[] => {
    if(!user?.tenantUsers) return []
    return user.tenantUsers.filter(({ active }) => active).map(({ tenant }) => tenant)
  }
)
