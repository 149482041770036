import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import API from "../../../apis/contextmeeting/api"
import { fetchCaseSchema } from "../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { CasesActionTypes } from "./casesActionTypes"
import queryString from "query-string"
import humps from "humps"

const normalizeResponseToPayload = (response: any) => {
  const normalizedResponse = normalize(response.data.all, [fetchCaseSchema])
  return {
    all: normalizedResponse,
    totalItems: response.data.totalItems,
    totalPages: response.data.totalPages,
    appliedFilters: response.data.searchQuery.appliedFilters,
    resultsPerPage: response.data.searchQuery.resultsPerPage,
    searchTerm: response.data.searchQuery.searchTerm,
    orderBy: response.data.searchQuery.orderBy
  }
}

export function* fetchCasesAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/cases?${query}`, withAuthHeader())
    const payload: any = normalizeResponseToPayload(response)

    yield put({
      type: CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS,
      payload: payload
    })
  } catch (error) {
    yield put({
      type: CasesActionTypes.FETCH_CASES_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "casesNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchCasesAsync(): Generator {
  yield takeLeading(CasesActionTypes.FETCH_CASES_ASYNC_PENDING, fetchCasesAsync)
}
