import classNames from "classnames"
import React, { useEffect, useState } from "react"
import ChatCaseTag from "./ChatCaseTag"
import ChatIcon from "../../../chat/components/header/ChatIcon"
import ChatLatestMessage from "./ChatLatestMessage"
import ChatLatestMessageTime from "./ChatLatestMessageTime"
import ChatTitle from "../../../chat/components/chatTitle/ChatTitle"
import { IChat } from "../../../chat/state/chatTypes"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import ChatClosedText from "../../../chat/components/ChatClosedText"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { useTranslation } from "react-i18next"
import ChatUserName from "../../../chat/components/shared/ChatUserName"
import ChatMutedText from "../../../chat/components/ChatMutedText"

interface Props {
  chatId: string
  setSelectedChatId: (selectedChatId: string) => void
  selectedChatId: string
}

const ChatListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const isSelected = props.selectedChatId == props.chatId
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const [filteredTypingUsers, setFilteredTypingUsers] = useState([])
  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    const filteredUsers = chat?.usersTypingIds?.filter((userId) => userId != currentUser.id)
    setFilteredTypingUsers(filteredUsers)
  }, [chat?.usersTypingIds])

  const renderTypingUsersText = (): JSX.Element => {
    return (
      <div className="flex-1 truncate text-xs italic text-ch-gray-500">
        {filteredTypingUsers.map((userId: string, index: number) => {
          return (
            <span key={`${index}-${userId}`}>
              {index > 0 && ", "}
              <ChatUserName userId={userId} />
            </span>
          )
        })}
        <span> {t("message:index:isTyping")}</span>
      </div>
    )
  }

  const renderLatestMessage = (): JSX.Element => {
    if(chat.endedAt !== null) return (
      <div className="self-end">
        <ChatClosedText chatId={props.chatId} />
      </div>
    )
    if(chat.options?.muted) return (
      <div className="self-end">
        <ChatMutedText chatId={props.chatId} />
      </div>
    )
    if(filteredTypingUsers?.length) return renderTypingUsersText()
    return (
      <div>
        <ChatLatestMessage chatId={props.chatId} />
        <ChatLatestMessageTime chatId={props.chatId} />
      </div>
    )
  }
  return (
    <div
      className={classNames(
        "flex",
        "flex-1",
        "w-full",
        "px-2",
        "py-4",
        "my-px",
        "border-b",
        "ring-ch-blue-alt-300",
        "hover:ring-2",
        "hover:rounded-md",
        "hover:border-white",
        {
          "ring-2": isSelected,
          "rounded-md": isSelected,
          "border-white": isSelected,
          "border-ch-blue-alt-300": !isSelected,
          "cursor-pointer": !isSelected,
          "cursor-default": isSelected
        }
      )}
      onClick={() => {props.setSelectedChatId(props.chatId)}}
    >
      <ChatIcon chatId={props.chatId} />
      <div className="flex flex-col flex-1 overflow-hidden mx-2">
        <ChatTitle chatId={props.chatId} />
        <ChatCaseTag caseId={chat.case} />
        {renderLatestMessage()}
      </div>
    </div>
  )
}

export default ChatListItem
