import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IAppState } from "../../../../app/appTypes"
import QuickViewCard from "../../../../shared/components/ui/quickView/QuickViewCard"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconMeeting } from "../../../../svg/icons"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import MeetingDetails from "../../../case/components/meetingList/MeetingDetails"
import { getCurrentUser } from "../../../../shared/selectors/user"

interface IProps {
  meetingId: string;
}

const CaseMeetingList: React.FC<IProps> = (props): JSX.Element => {
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const currentUser = useSelector(getCurrentUser)
  if (!props.meetingId || !meeting || !currentUser) return null

  const userInMeeting = _.includes(meeting.users, currentUser.id)

  return (
    <li>
      <QuickViewCard>
        <IconCircleContainer className="mr-4">
          <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
        </IconCircleContainer>

        <div className="flex-1">
          <MeetingDetails meetingId={props.meetingId} showCoordinator={true} hasLink={userInMeeting} showMeetingStatus={true} />
        </div>
      </QuickViewCard>
    </li>
  )
}

export default CaseMeetingList
