import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import { getCurrentUser } from "../../../../shared/selectors/user"
import ChatUserName from "../../../chat/components/shared/ChatUserName"
import { IChat } from "../../../chat/state/chatTypes"
import SpeechBubbleArrow from "./SpeechBubbleArrow"


interface Props {
  chatId: string
}

const CurrentlyTypingIndicator: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const currentUser = useSelector(getCurrentUser)
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const _filteredUsers = chat?.usersTypingIds?.filter((userId) => userId != currentUser.id)
    setFilteredUsers(_filteredUsers)
  }, [chat?.usersTypingIds])

  if(!filteredUsers?.length) return null

  return (
    <div className="px-4 pb-4 -mt-3">
      <div className="flex flex-col max-w-md mb-3">
        <div className="text-xs mb-1">
          {filteredUsers.map((userId: string, index: number) => {
            return (
              <span key={`${index}-${userId}`}>
                {index > 0 && ", "}
                <ChatUserName userId={userId} />
              </span>
            )
          })}
          <span> {t("message:index:isTyping")}</span>
        </div>

        <div className="flex mb-1 items-center ml-1 animate-pulse">
          <div className="px-4 py-3 rounded-md text-xs relative bg-ch-gray-400">
            <SpeechBubbleArrow isLeft />
            <div className="flex justify-between gap-1 animate-bounce p-1">
              <div className="w-2 h-2 rounded-full bg-ch-gray-450"></div>
              <div className="w-2 h-2 rounded-full bg-ch-gray-500"></div>
              <div className="w-2 h-2 rounded-full bg-ch-gray-600"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrentlyTypingIndicator
