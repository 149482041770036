import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { publishScreenToSession, stopPublishScreenToSession } from "../../../../apis/webRTC/webRTC"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"

// OpenTok API does not allow us to change videoSource after stream has been published, so we must publish and unpublish in order to toggle screen sharing
export function* publishScreenToWebRTCSessionAsync(action) {
  try {
    const publisher: OT.Publisher = yield call(publishScreenToSession, action.payload.session, action.payload.containerRef, action.payload.options, action.payload.eventHandlers)

    yield put({
      type: WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS,
      payload: publisher
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "publishScreenToWebRTCSessionError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchPublishScreenToWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING, publishScreenToWebRTCSessionAsync)
}

export function* stopPublishScreenToWebRTCSessionAsync(action) {
  try {
    const publisher: OT.Publisher = yield call(stopPublishScreenToSession, action.payload.session, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_SUCCESS,
      payload: publisher
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "stopPublishScreenToWebRTCSessionError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchStopPublishScreenToWebRTCSessionAsync() {
  yield takeLeading(WebRTCActionTypes.STOP_PUBLISH_SCREEN_TO_WEB_RTC_SESSION_ASYNC_PENDING, stopPublishScreenToWebRTCSessionAsync)
}
