import { Formik, Form } from "formik"
import _ from "lodash"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { formatISO } from "date-fns"
import Button from "../../../shared/components/buttons/Button"
import DatePickerField from "../../../shared/components/datePicker/DatePickerField"
import InputField from "../../../shared/components/forms/InputField"
import Label from "../../../shared/components/forms/Label"
import Buttons from "../../../shared/components/layout/Buttons"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { daysFromNowToDate, utcDateToDaysFromNow } from "../../../shared/helpers/timeHelpers"
import { UserConfigContext } from "../../userConfig/contexts/UserConfigContext"

interface IPropsFromParent {
  submitHandler: (values, options) => void
  cancelHandler: () => void
  dueDate: Date
}

const AssignDueDateForm: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const { timezone } = useContext(UserConfigContext)

  const initialValues = {
    dueDate: props.dueDate,
    daysFromNow: props.dueDate ? utcDateToDaysFromNow(formatISO(props.dueDate), timezone) : ""
  }

  return <Formik
    initialValues={initialValues}
    onSubmit={props.submitHandler}
  >
    {({
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      dirty,
      setFieldValue
    }) => {

      const onChangeDueDate = (val: string) => {
        const daysFromNow = utcDateToDaysFromNow(val, timezone) || ""
        setFieldValue("dueDate", val)
        setFieldValue("daysFromNow", daysFromNow)
      }

      const onChangeDaysFromNow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue("dueDate", daysFromNowToDate(e.target.value))
        handleChange(e)
      }

      return (
        <Form>
          <div className="mb-6 w-full">
            <div>
              <Label name="dueDate">
                {t("case:casePathways:dueDate")}:
              </Label>
              <div className="flex flex-wrap">
                <div className="flex mr-4 mb-1">
                  <div className="flex flex-col">
                    <DatePickerField name="dueDate" onChange={onChangeDueDate} />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-row items-center">
                    <Label className="mr-2" name="daysFromNow">
                      {t("OR")}
                    </Label>
                    <InputField
                      type="number"
                      name="daysFromNow"
                      onChange={onChangeDaysFromNow}
                      onBlur={handleBlur}
                      value={values.daysFromNow.toString()}
                      autoComplete="off"
                      className="lg:w-1/4 md:w-1/2"
                    />
                    <p className="ml-2">
                      {t("daysFromNow")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-6">
            <Buttons buttons={[
              <Button
                key="1"
                variant={StyleVariants.BLUE_ALT}
                action={() => props.cancelHandler()}
              >
                {t("shared:cancel")}
              </Button>,
              <Button
                key="2"
                isDisabled={!dirty || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}
              >
                {t("case:casePathways:buttons:assign")}
              </Button>]}
            />
          </div>
        </Form>
      )
    }}
  </Formik >
}

export default AssignDueDateForm
