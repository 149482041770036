import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { AnyAction } from "redux"
import { IAppState } from "../../../../app/appTypes"
import AssetListItem from "../assets/AssetListItem"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import AssetTypeFilter from "../../../case/components/assetList/AssetTypeFilter"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { IMeetingCase, IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { updateSharedViewHoveredAssetAction } from "../../state/liveMeetingActions"

interface IProps {
  selectAction: (assetId: string, assetTypeFilters: IAssetTypeFilters) => AnyAction | null
  setAssetTypeFilters: (filter: IAssetTypeFilters) => void
  assetTypeFilters: IAssetTypeFilters
  hoverAction?: (assetId: string) => AnyAction
}

const LiveMeetingAssetList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["case", "liveMeeting", "shared"])
  const dispatch = useDispatch()
  const updateSelectedAsset = (assetId: string) => dispatch(props.selectAction(assetId, props.assetTypeFilters))
  const selectedMeetingCase: IMeetingCase = useSelector((state: IAppState) => getSelectedMeetingCase(state))
  const updateHoveredAsset = _.debounce((assetId) => {
    dispatch(updateSharedViewHoveredAssetAction(assetId))
  }, 400)

  useEffect(() => {
    props.setAssetTypeFilters(null)
  }, [selectedMeetingCase?.case])

  if (!selectedMeetingCase?.meetingCaseAssets?.length) return (
    <h3 className="text-center text-ch-blue-alt-400">
      {t("liveMeeting:noAssetsOnMeetingCase")}
    </h3>
  )

  if (!selectedMeetingCase?.case) return null

  return (
    <div>
      <AssetTypeFilter initialValues={props.assetTypeFilters} setFilters={props.setAssetTypeFilters} />
      <ul>
        {selectedMeetingCase?.meetingCaseAssets.map((meetingCaseAsset: IMeetingCaseAsset) => {
          return (
            <AssetListItem
              action={() => updateSelectedAsset(meetingCaseAsset.caseAssetId)}
              key={meetingCaseAsset.caseAssetId}
              assetId={meetingCaseAsset.caseAssetId}
              caseId={selectedMeetingCase.case}
              assetTypeFilters={props.assetTypeFilters}
              onMouseEnter={() => updateHoveredAsset(meetingCaseAsset.caseAssetId)}
              onMouseLeave={() => updateHoveredAsset(null)}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default LiveMeetingAssetList
