import React from "react"
import { useTranslation } from "react-i18next"
import { IconMessage } from "../../../svg/icons"

const ChatNewHeader: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["message"])

  return (
    <div className="flex items-center mb-2">
      <div className="w-9 h-9 rounded-full bg-white flex items-center justify-center border border-ch-gray-500">
        <IconMessage className="text-ch-purple-500 w-4 h-4" />
      </div>
      <h2 className="font-semibold text-sm ml-2">{t("message:new:newMessageTitle")}</h2>
    </div>
  )
}

export default ChatNewHeader
