// State
export interface ITenantConfigContextState {
  tenantName: string
  tenantLogoPath: string
  healthcareIdLabel: string
  careProviderIdLabel: string
  healthcareIdRequired: boolean
  careProviderIdRequired: boolean
  signedOffByRequired: boolean
  messagingEnabled: boolean
  messagingNotificationsEnabled: boolean
  changeTrackingEnabled: boolean
  pathwaysEnabled: boolean
  analyticsEnabled: boolean
  radiologyEnabled: boolean
  recommendationConsentHeader?: string
  guestUsersEnabled?: boolean
  mdtRoles: string[]
  mdtTeamsEnabled?: boolean
}

// Actions
export interface ITenantConfigFetchAsyncAction {
  type: string
}

// Action Types
export enum TenantConfigActionTypes {
  FETCH_TENANT_CONFIG_ASYNC_PENDING = "@@tenantConfig/FETCH_TENANT_CONFIG_ASYNC_PENDING",
  FETCH_TENANT_CONFIG_ASYNC_SUCCESS = "@@tenantConfig/FETCH_TENANT_CONFIG_ASYNC_SUCCESS",
  FETCH_TENANT_CONFIG_ASYNC_ERROR = "@@tenantConfig/FETCH_TENANT_CONFIG_ASYNC_ERROR",
}
