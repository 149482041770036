import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Button from "../../../../../shared/components/buttons/Button"
import TextAuthorDetails from "../../../../../shared/components/text/TextAuthorDetails"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconPathway, IconPencil } from "../../../../../svg/icons"
import { ICasePathway } from "../../../../casePathways/state/casePathwayTypes"
import CasePathwayTaskList from "../taskList/CasePathwayTaskList"
import TimeDaysFromNow from "../../../../../shared/components/time/TimeDaysFromNow"
import { formatDatePicker } from "../../../../../shared/helpers/timeHelpers"
import AssignDueDateModal from "../modals/AssignDueDateModal"
import EditCasePathwayModal from "../modals/EditCasePathwayModal"
import CaseTaskNewForm from "../../addCaseTaskPanel/forms/CaseTaskNewForm"
import { CaseAssetTabNames } from "../../activityPanel/CaseActivityPanel"
import { IAppState } from "../../../../../app/appTypes"
import { getCaseById } from "../../../../../shared/selectors/case"
import ButtonExpandCollapse from "../../../../../shared/components/buttons/ButtonExpandCollapse"

type Props = {
  casePathwayId: string
  openAssetModal: (id: string) => void
}

export enum Tabs {
  TaskNew = "task_new",
}

export const CasePathwayDetails: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))
  const theCase = useSelector((state: IAppState) => getCaseById(state, { caseId: casePathway.caseId }))
  const [currentTab, setCurrentTab] = useState(null)
  const [taskListVisible, setTaskListVisible] = useState(false)

  if (!casePathway) return null

  const [assignDueDateModalOpen, setAssignDueDateModalOpen] = React.useState(false)

  const openAssignDueDateModal = () => { setAssignDueDateModalOpen(true) }
  const closeAssignDueDateModal = () => { setAssignDueDateModalOpen(false) }

  const [editCasePathwayModalOpen, setEditCasePathwayModalOpen] = React.useState(false)

  const openEditCasePathwayModal = () => { setEditCasePathwayModalOpen(true) }
  const closeEditCasePathwayModal = () => { setEditCasePathwayModalOpen(false) }

  const assignButtonText = () => {
    if (casePathway.dueDate) {
      return t("case:casePathways:buttons:editDate")
    } else {
      return t("case:casePathways:buttons:assignDate")
    }
  }

  const renderDueDate = () => {
    if (casePathway.dueDate) {
      return (
        <span>
          <span className="font-semibold">{t("case:casePathways:dueDate")}: </span>
          <span>{formatDatePicker(new Date(casePathway.dueDate))} </span>
          <span className="text-ch-gray-500 italic"><TimeDaysFromNow utcTime={casePathway.dueDate} /></span>
        </span>
      )
    } else {
      return (
        <span>
          <span className="font-semibold">{t("case:casePathways:dueDate")}: </span>
          <span className="text-ch-gray-500 italic">{t("case:casePathways:noDueDateAssigned")}</span>
        </span>
      )
    }
  }

  const renderEditButton = (): JSX.Element => {
    if (!casePathway.permissions.canEdit || theCase.locked) return null
    return (
      <a onClick={openEditCasePathwayModal} className="cursor-pointer">
        <IconPencil className="w-5 h-5 mr-1 text-ch-blue-500 hover:text-ch-blue-600" title={t("case:casePathways:buttons:editPathway")} />
      </a>
    )
  }

  const renderAssignDueDateButton = (): JSX.Element => {
    if (!casePathway.permissions.canEdit || theCase.locked) return null
    return (
      <Button
        action={() => openAssignDueDateModal()}
        variant={StyleVariants.PURPLE}
      >
        {assignButtonText()}
      </Button>
    )
  }

  const renderAddTaskButton = (): JSX.Element => {
    if (theCase.locked) return null
    return (
      <Button
        action={() => setCurrentTab(Tabs.TaskNew)}
        variant={StyleVariants.BLUE}
        key="1"
      >
        {t("case:casePathways:buttons:addTask")}
      </Button>
    )
  }

  const renderPathwayDetails = (): JSX.Element => {
    return (
      <div className="p-2 rounded-md bg-ch-blue-100">
        <div className="flex mb-2">
          <div className="text-ch-purple-500 mr-2 mt-1">
            <IconPathway className="w-5 h-5" title={casePathway.name} />
          </div>
          <div className="flex-1">
            <h4 className="font-semibold">
              {casePathway.name}
            </h4>
          </div>
          {renderEditButton()}
        </div>
        <p className="mb-2">
          <TextAuthorDetails
            firstName={casePathway.createdBy?.firstName}
            lastName={casePathway.createdBy?.lastName}
            createdAt={casePathway.createdAt}
          />
        </p>
        <p className="mb-2">
          {casePathway.description}
        </p>
        <div className="flex flex-wrap gap-2 items-center mb-2">
          {renderDueDate()}
          {renderAssignDueDateButton()}
        </div>
        <div className="flex flex-wrap gap-2 items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <span>
              <span className="font-semibold">{t("case:casePathways:tasks")} ({casePathway.tasks.length}): </span>
            </span>
            {renderAddTaskButton()}
          </div>
          <div className="flex mr-2">
            <ButtonExpandCollapse
              key="1"
              isExpanded={taskListVisible}
              action={() => setTaskListVisible(!taskListVisible)}
              expandText={t("case:casePathways:buttons:showTasks")}
              collapseText={t("case:casePathways:buttons:hideTasks")} />
          </div>
        </div>
        {
          taskListVisible ?
            <CasePathwayTaskList taskIds={casePathway.tasks} />
            : null
        }


        <AssignDueDateModal
          casePathwayId={props.casePathwayId}
          closeModal={closeAssignDueDateModal}
          isOpen={assignDueDateModalOpen}
        />

        <EditCasePathwayModal
          casePathwayId={props.casePathwayId}
          closeModal={closeEditCasePathwayModal}
          isOpen={editCasePathwayModalOpen}
        />
      </div >
    )
  }

  const renderTabContent = (): JSX.Element => {
    switch (currentTab) {
      case Tabs.TaskNew:
        return <CaseTaskNewForm
          caseId={casePathway.caseId}
          closeAction={() => setCurrentTab(null)}
          casePathwayId={props.casePathwayId}
          originTab={CaseAssetTabNames.PATHWAYS}
        />

      default:
        return renderPathwayDetails()
    }
  }

  return (
    <div>
      {renderTabContent()}
    </div>
  )
}

export default CasePathwayDetails
