/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors, FormikTouched } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { ICommentNewData } from "../../../../../assets/state/assetsTypes"
import { IUser } from "../../../../../user/state/userTypes"
import MentionField from "../../../../../../shared/components/forms/MentionField"
import Label from "../../../../../../shared/components/forms/Label"
import InputField from "../../../../../../shared/components/forms/InputField"
import FieldError from "../../../../../../shared/components/forms/FieldError"


interface IPropsFromParent {
  users: { [id: string]: IUser }
  errors: FormikErrors<ICommentNewData>
  touched: FormikTouched<ICommentNewData>
  values: ICommentNewData
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

type Props = IPropsFromParent

const CaseCommentFields: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case", "asset"])

  return (
    <div>
      <div className="mb-2">
        <Label name="title" required={true}>
          {t("asset:title")}
        </Label>
        <InputField
          type="text"
          name="title"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.title}
          autoComplete="off"
        />
        <FieldError errorMessage={props.errors.title as string} isVisible={(props.errors.title && props.touched.title) as boolean} />
      </div>

      <div className="mb-5">
        <Label name="body" required={true}>
          {t("asset:body")}
        </Label>
        <MentionField
          name="body"
          setValue={(body) => props.setFieldValue("body", body)}
          onBlur={props.handleBlur}
          users={props.users}
          value={props.values.body}
        />
        <FieldError errorMessage={props.errors.body as string} isVisible={(props.errors.body && props.touched.body) as boolean} />
      </div>
    </div>
  )
}



export default CaseCommentFields
