import React from "react"

interface IProps {
  count: number
  top?: string
}

const defaultProps = {
  top: "-3px"
}

const Counter: React.FC<IProps> = (props: IProps): JSX.Element => {
  if (!props.count) return null

  const countText = props.count < 99 ? props.count.toString() : "9+"

  return (
    <span
      className="absolute right-0 bg-ch-red-500 text-white rounded-full w-5 h-5 text-sm flex justify-center items-center p-2 -mt-2 -mr-2"
      style={{
        top: props.top
      }}
    >
      {countText}
    </span>
  )
}

Counter.defaultProps = defaultProps

export default Counter
