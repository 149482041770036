import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getCaseMeetingIds } from "../../../../shared/selectors/case"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import MeetingListItem from "../../../case/components/meetingList/MeetingListItem"

interface IProps {
  caseId: string;
}

const CaseMeetingList: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const meetingIds = useSelector((state: IAppState) => getCaseMeetingIds(state, props))

  if (!meetingIds || meetingIds.length == 0) return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t("case:noMeetings")}
      </h3>
      <ListBackground />
    </div>
  )

  return (
    <ul>
      {meetingIds.map((meetingId: string) => {
        return (
          <MeetingListItem
            key={meetingId}
            meetingId={meetingId}
          />
        )
      })}
    </ul>
  )
}

export default CaseMeetingList
