import React from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import { shareRecommendationAction, deferRecommendationAction } from "../../state/liveMeetingActions"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { CaseStage } from "../../state/liveMeetingTypes"
import RecommendationArea from "../shared/RecommendationArea"
import { useSelector } from "react-redux"

interface IPropsFromParent {
  meetingId: string
  buttonsOnly?: boolean
}

const ScribeRecommendation: React.FC<IPropsFromParent> = (props: IPropsFromParent) => {
  const { t } = useTranslation(["shared"])
  const dispatch = useDispatch()
  const currentCaseId = useSelector((state: IAppState) => state.liveMeetingConfig?.selectedMeetingCaseId)
  const currentUserConfig = useSelector((state: IAppState) => getCurrentUserWithConfig(state).config)
  const caseStage = useSelector((state: IAppState) => state.liveMeetingConfig?.caseStage)
  if (currentCaseId == undefined) return null
  if (caseStage != CaseStage.PRESENTING) return null

  const shareOrDefer = () => {
    if (!currentUserConfig.isPresenter) { return null }
    return (
      <div className="flex flex-wrap flex-1 space-x-2 justify-center">
        <Button variant={StyleVariants.PURPLE} action={() => dispatch(deferRecommendationAction())}>
          {t("shared:defer")}
        </Button>
        <Button variant={StyleVariants.BLUE} action={() => dispatch(shareRecommendationAction())}>
          {t("shared:shareAndRecommend")}
        </Button>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        "p-2",
        "rounded-md",
        "bg-ch-yellow-200",
        "mt-2",
        "text-ch-gray-600",
      )}
      key={currentCaseId}>
      {props.buttonsOnly ? null : <RecommendationArea meetingId={props.meetingId} readOnly={false} />}
      {shareOrDefer()}
    </div>
  )
}

export default ScribeRecommendation
