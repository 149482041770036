import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../app/appTypes"
import { getChatById } from "../../../shared/selectors/message"
import { IChat } from "../state/chatTypes"

interface Props {
  chatId: string
}

const ChatClosedText: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const { t } = useTranslation(["shared", "message"])

  if(!chat?.endedAt) return null

  return (
    <div className="text-sm text-white px-1 rounded bg-ch-gray-600 ml-2 flex content-center lowercase text-sm">
      {t("message:index:closed")}
    </div>
  )
}


export default ChatClosedText
