import { normalize } from "normalizr"
import queryString from "query-string"
import humps from "humps"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { patientsSchema } from "../../../apis/contextmeeting/schema"
import { PatientsActionTypes } from "./patientsTypes"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"

export function* fetchPatientsAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/patients?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [patientsSchema])

    yield put({
      type: PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        searchTerm: response.data.searchQuery.searchTerm,
        orderBy: response.data.searchQuery.orderBy
      }
    })
  } catch (error) {
    yield put({
      type: PatientsActionTypes.FETCH_PATIENTS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "patientsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchPatientsAsync(): Generator {
  yield takeLeading(PatientsActionTypes.FETCH_PATIENTS_ASYNC_PENDING, fetchPatientsAsync)
}

export function* fetchAllPatientsAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/patients?paged=false", withAuthHeader())

    const normalizedResponse = normalize(response.data, [patientsSchema])

    yield put({
      type: PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "patientsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchAllPatientsAsync(): Generator {
  yield takeLeading(PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_PENDING, fetchAllPatientsAsync)
}

const valuesToFormData = (values) => {
  const data = new FormData()
  data.append("patients[file]", values.file)
  return data
}

export function* uploadPatientsCSVAsync(action: Effect): Generator {
  try {
    const data = valuesToFormData(action.payload)

    const config = withAuthHeader()
    config.headers["Content-Type"] = "multipart/form-data"

    yield put(displayUserMessageAction({
      messageKey: "fileUploadStarted",
      type: UserMessageTypes.MESSAGE
    }))

    yield call(API.post, `/patients/upload_csv`, data, config)

    yield put({
      type: PatientsActionTypes.UPLOAD_PATIENTS_CSV_ASYNC_SUCCESS
    })

    yield put(displayUserMessageAction({
      messageKey: "csvUploadSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: PatientsActionTypes.UPLOAD_PATIENTS_CSV_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "fileUploadError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUploadPatientsCSVAsync(): Generator {
  yield takeLeading(PatientsActionTypes.UPLOAD_PATIENTS_CSV_ASYNC_PENDING, uploadPatientsCSVAsync)
}

