import { MeetingUserActionTypes, ICreateMeetingUserAction, IUpdateMeetingUserAction, IDestroyMeetingUserAction, IMeetingUsersNewData, IMeetingUser } from "./meetingUserTypes"

export const createMeetingUserAcion = (meetingData: IMeetingUsersNewData): ICreateMeetingUserAction => {
  return {
    type: MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_PENDING,
    payload: meetingData
  }
}

export const updateMeetingUserAction = (meetingUserId: string, meetingUserData: IMeetingUser): IUpdateMeetingUserAction => {
  return {
    type: MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_PENDING,
    payload: {
      id: meetingUserId,
      meetingUser: meetingUserData
    }
  }
}

export const destroyMeetingUserAction = (meetingUserId: string): IDestroyMeetingUserAction => {
  return {
    type: MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_PENDING,
    payload: meetingUserId
  }
}
