import { formatISO } from "date-fns"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import { zonedDateToUtc } from "../../../../../shared/helpers/timeHelpers"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import AssignDueDateForm from "../../../../casePathways/components/AssignDueDateForm"
import CasePathwayHeader from "../../../../casePathways/components/CasePathwayHeader"
import { updateCasePathwayAction } from "../../../../casePathways/state/casePathwayActions"
import { ICasePathway, ICasePathwayEditData } from "../../../../casePathways/state/casePathwayTypes"
import { UserConfigContext } from "../../../../userConfig/contexts/UserConfigContext"

interface Props {
  casePathwayId: string
  closeModal: () => void
  isOpen: boolean
}

const AssignDueDateModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const dispatch = useDispatch()
  const { timezone } = useContext(UserConfigContext)
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  const assignedDate = casePathway.dueDate ? new Date(casePathway.dueDate) : null

  const assignDueDate = (values) => {
    const dateWithEveningDeadline = formatISO(zonedDateToUtc(values.dueDate, timezone, "10:00pm"))
    const data: ICasePathwayEditData = {
      dueDate: dateWithEveningDeadline
    }
    dispatch(updateCasePathwayAction(
      casePathway.caseId,
      props.casePathwayId,
      data
    ))
    props.closeModal()
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("case:casePathways:assignADueDate")}
        </h2>

        <CasePathwayHeader casePathwayId={props.casePathwayId} />

        <AssignDueDateForm submitHandler={assignDueDate} cancelHandler={props.closeModal} dueDate={assignedDate} />
      </ModalContainer>
    </Modal >
  )
}

export default AssignDueDateModal
