import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconFurtherDetails({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 45"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 0a4.5 4.5 0 00-4.5 4.5V12h-3v3h3v15h-3v3h3v7.5A4.5 4.5 0 008 45h27a4.5 4.5 0 004.5-4.5v-36A4.5 4.5 0 0035 0H8zm7.5 15a6 6 0 1112 0 6 6 0 01-12 0zm-3 18a9 9 0 0118 0v3h-18v-3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconFurtherDetails;
