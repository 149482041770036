import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IconCog } from "../../../../svg/icons"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import LiveMeetingUserAudioDeviceList from "./LiveMeetingUserAudioDeviceList"
import LiveMeetingUserVideoDeviceSelect from "./LiveMeetingUserVideoDeviceSelect"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import { getWebRTCPublisher } from "../../../../shared/selectors/webRTC"
import { VideoFilter } from "@opentok/client"
import RadioCheckbox from "../../../../shared/components/forms/RadioCheckbox"
import { Form, Formik } from "formik"
import Label from "../../../../shared/components/forms/Label"

const LiveMeetingUserAudioSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const user = useSelector(getCurrentUserWithConfig)
  const publisher: OT.Publisher = useSelector(getWebRTCPublisher)
  const [
    audioSettingsModalIsOpen,
    setAudioSettingsModalIsOpen,
  ] = useState(false)
  const [blurEnabled, setBlurEnabled] = useState(false)
  useEffect(() => {
    if (!publisher) return
    if (blurEnabled) {
      const videoFilter = {
        type: "backgroundBlur",
        blurStrength: "low"
      } as VideoFilter
      publisher.applyVideoFilter(videoFilter)
    } else {
      publisher.clearVideoFilter()
    }
  }, [blurEnabled])

  const openAudioSettingsModal = (): void => {
    setAudioSettingsModalIsOpen(true)
  }

  const closeAudioSettingsModal = (): void => {
    setAudioSettingsModalIsOpen(false)
  }

  if (!user.config?.isOnline) return null

  const renderToggleBlur = () => {
    if (!OT.hasMediaProcessorSupport()) {
      return (
        <div>{t("blurNotAvailable")}</div>
      )
    }

    return (
      <div onClick={() => setBlurEnabled(!blurEnabled)}>
        <Formik initialValues={{ blur: blurEnabled }} onSubmit={null}>
          <Form className="flex space-x-2 items-center">
            <RadioCheckbox value="blur" name="blur" isSelected={blurEnabled} className="mb-2" />
            <Label name="blur">{t("blurEnabled")}</Label>
          </Form>
        </Formik>
      </div>
    )
  }

  return (
    <div>
      <a
        href="#"
        onClick={openAudioSettingsModal}
        className="text-ch-gray-600 hover:text-white"
      >
        <IconCog
          className="fill-current w-4 h-4"
          title={t("liveMeeting:audioSettings")}
        />
      </a>

      <Modal
        isOpen={audioSettingsModalIsOpen}
        onRequestClose={closeAudioSettingsModal}
        style={defaultModalStyle}
      >
        <ModalContainer handleClose={closeAudioSettingsModal} >
          <h2 className="text-lg text-ch-blue-600 mb-1">
            {t("liveMeeting:audioSettings")}
          </h2>
          <div className="mb-2">
            <LiveMeetingUserAudioDeviceList />
          </div>
          <h2 className="text-lg text-ch-blue-600 mb-1">
            {t("liveMeeting:videoSettings")}
          </h2>
          <div>
            <LiveMeetingUserVideoDeviceSelect />
          </div>
          <div className="mt-2">
            {renderToggleBlur()}
          </div>
        </ModalContainer>
      </Modal>
    </div >
  )
}

export default LiveMeetingUserAudioSettings
