import { AnyAction, combineReducers, Reducer } from "redux"
import _ from "lodash"
import { IExternalUser } from "../externalUserTypes"
import { UserActionTypes } from "../../../user/state/userTypes"
import { INormalizedEntities } from "../../../../app/appTypes"

type TByIdState = { [id: string]: IExternalUser }
type TAllIdsState = string[]

const byId: Reducer<TByIdState> = (state: TByIdState = {}, action: AnyAction): TByIdState => {
  switch (action.type) {
    case UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload)
    default:
      return state
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = [], action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.map((user: IExternalUser) => user.id)))
    default:
      return state
  }
}

export const externalUsersEntityReducer: Reducer<INormalizedEntities<IExternalUser>> = combineReducers({
  byId,
  allIds
})
