import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconSignOut({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M23.297 12.797l-7.875 7.875c-.703.703-1.922.21-1.922-.797v-4.5H7.125A1.122 1.122 0 016 14.25v-4.5c0-.623.502-1.125 1.125-1.125H13.5v-4.5c0-1.003 1.214-1.5 1.922-.797l7.875 7.875c.436.44.436 1.153 0 1.594zM9 20.437v-1.875A.564.564 0 008.437 18H4.5c-.83 0-1.5-.67-1.5-1.5v-9C3 6.67 3.67 6 4.5 6h3.938A.565.565 0 009 5.437V3.563A.564.564 0 008.437 3H4.5A4.501 4.501 0 000 7.5v9C0 18.984 2.016 21 4.5 21h3.938A.564.564 0 009 20.437z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconSignOut;
