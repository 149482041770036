import React, { useState } from "react"
import PathwayUserListItem from "./PathwayUserListItem"
import { useSelector, useDispatch } from "react-redux"
import { getPathway } from "../../../shared/selectors/pathway"
import Card from "../../../shared/components/layout/Card"
import { useTranslation, Trans } from "react-i18next"
import ButtonExpandCollapse from "../../../shared/components/buttons/ButtonExpandCollapse"
import classNames from "classnames"
import Modal from "react-modal"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import { defaultModalStyle } from "../../../shared/state/sharedTypes"
import InviteButton from "../../../shared/components/buttons/InviteButton"
import PathwayHeader from "./PathwayHeader"
import AddUsersForm from "../../../shared/components/user/AddUsersForm"
import { addPathwayUsersAction } from "../pathwayActions"

type Props = {
  pathwayId: string
}

const PathwayUserList: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const [isVisibleList, toggleList] = useState(false)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const pathway = useSelector(getPathway(props))
  const { t } = useTranslation(["shared"])

  const openModal = () => { setModalIsOpen(true) }
  const closeModal = () => { setModalIsOpen(false) }

  const handleAddUsers = (values: { users: string[] }) => {
    dispatch(addPathwayUsersAction(pathway.id, values.users))
    closeModal()
  }

  if (!pathway) return null
  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("pathway:collaborators")} ({pathway.users.length})
          </h3>
          {pathway.permissions?.canEdit ?
            <InviteButton
              openModal={openModal}
              label={t("pathway:details:addCollaborator")}
            />
            : null
          }
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans
              i18nKey="pathway:details:collaboratorsIntro"
              components={{
                strong: <strong />
              }}
            />
          </p>

          <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("meeting:showUsers")} collapseText={t("meeting:hideUsers")} />
        </div>

        <ul className={classNames({ hidden: !isVisibleList })}>
          {pathway.users.map((user) => {
            return <PathwayUserListItem {...props} userId={user} key={user} />
          })}
        </ul>
      </Card>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeModal}>
          <h2 className="text-lg mb-3">
            {t("pathway:details:wouldYouLikeToAddACollaborator")}
          </h2>

          <PathwayHeader pathwayId={props.pathwayId} />

          <div className="mb-3">
            <p className="text-ch-blue-600">{t("pathway:details:chooseCollaborators1")}</p>
            <p className="text-ch-blue-600">{t("pathway:details:chooseCollaborators2")}</p>
          </div>
          <AddUsersForm disableTeams submitHandler={handleAddUsers} userLabel={t("pathway:collaborators")} excludedUserIds={pathway.users} buttonText={t("pathway:details:addCollaboratorsToPathway")} />
        </ModalContainer>
      </Modal>
    </div>
  )
}

export default PathwayUserList
