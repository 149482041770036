import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { IAsset } from "../../state/assetsTypes"

interface Props {
  meetingCaseAsset: IMeetingCaseAsset
  meetingId: string
}

const MeetingCaseAssetSelectedByText: React.FC<Props> = (props: Props): JSX.Element => {
  if(!props.meetingCaseAsset) return null

  const caseAsset: IAsset = useSelector((state: IAppState) => getAssetById(state, {assetId: props.meetingCaseAsset.caseAssetId}))

  // do not render selected text if original meeting asset from the current meeting
  if(caseAsset?.asset?.meetingId == props.meetingId) return null

  const { t } = useTranslation(["shared"])

  return (
    <span className="block">
      <span className="font-semibold text-ch-blue-alt-500 ">
        { t("shared:selectedBy") }
      </span>
      &nbsp;
      <span className="font-thin">
        <span className="uppercase">{ props.meetingCaseAsset.createdBy.lastName }</span>, { props.meetingCaseAsset.createdBy.firstName }&nbsp;
        { t("shared:on") }&nbsp;
        <TimeDefaultFormat utcTime={props.meetingCaseAsset.createdAt} />
      </span>
    </span>
  )
}

export default MeetingCaseAssetSelectedByText
