import { takeLeading, put, call, Effect } from "redux-saga/effects"
import _ from "lodash"
import { LiveMeetingActionTypes, LiveMeetingCableActionTypes } from "./liveMeetingTypes"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { fetchMeetingAction } from "../../meeting/state/meetingActions"
import { fetchCaseAssetsAction } from "../../assets/state/assetsActions"
import { fetchMeetingCaseAction } from "../../meetingCase/state/meetingCaseActions"
import { handleError, handleSuccess } from "../../../shared/helpers/sagaHelpers"

export function* liveMeetingWatchers(): Generator {
  yield takeLeading(LiveMeetingActionTypes.FETCH_LIVE_MEETING_CONFIG_ASYNC_PENDING, fetchLiveMeetingConfigAsync)
  yield takeLeading(LiveMeetingCableActionTypes.UPDATE_MEETING_CASES, receiveCableUpdateMeetingCases)
  yield takeLeading(LiveMeetingCableActionTypes.UPDATE_CASE_ASSETS, receiveCableUpdateCaseAssets)
  yield takeLeading(LiveMeetingCableActionTypes.UPDATE_MEETING, receiveCableUpdateMeeting)
}

export function* fetchLiveMeetingConfigAsync(action: Effect): Generator {
  try {
    const meetingId = action.payload
    const response: any = yield call(API.get, `/meetings/${meetingId}/live_meeting_config`, withAuthHeader())

    response.data.userConfigs = _.mapKeys(response.data.userConfigs, "id")

    yield handleSuccess(LiveMeetingActionTypes.FETCH_LIVE_MEETING_CONFIG_ASYNC_SUCCESS, response.data)
  } catch (error) {
    yield handleError(LiveMeetingActionTypes.FETCH_LIVE_MEETING_CONFIG_ASYNC_ERROR, error)
  }
}

export function* receiveCableUpdateMeetingCases(action: Effect): Generator {
  yield put(fetchMeetingAction(action.payload.meetingId))
  yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
  yield put(fetchCaseAssetsAction(action.payload.caseId))
}

export function* receiveCableUpdateCaseAssets(action: Effect): Generator {
  const { caseId, meetingCaseId } = action.payload
  yield put(fetchCaseAssetsAction(caseId))
  yield put(fetchMeetingCaseAction(meetingCaseId))
}

export function* receiveCableUpdateMeeting(action: Effect): Generator {
  const { meetingId } = action.payload
  yield put(fetchMeetingAction(meetingId))
}
