/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from "formik"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import _ from "lodash"
import Label from "../../../../shared/components/forms/Label"
import InputField from "../../../../shared/components/forms/InputField"
import FieldError from "../../../../shared/components/forms/FieldError"
import { IPatientCaseDetailsData } from "../../state/patientNewTypes"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../../shared/components/forms/StandardSelectField"
import { CaseTypes } from "../../../case/state/caseTypes"
import SelectField from "../../../../shared/components/forms/SelectField"
import { generateCaseClassificationOptions, generateCaseSourceOptions } from "../../../../shared/helpers/selectOptionHelpers"
import { IAppState } from "../../../../app/appTypes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import DatePickerField from "../../../../shared/components/datePicker/DatePickerField"
import { useSelector } from "react-redux"
import { getAllActiveUsersSortedFirstNameLastName } from "../../../../shared/selectors/user"
import { getAllCaseClassifications } from "../../../../shared/selectors/caseClassification"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../../shared/components/label/UserOption"
import { getAllCaseSources } from "../../../../shared/selectors/caseSource"

const CaseFields: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient", "case"])
  const [userOptions, setUserOptions] = React.useState([])
  const [caseClassificationOptions, setCaseClassificationOptions] = React.useState([])
  const [caseSourceOptions, setCaseSourceOptions] = React.useState([])
  const { signedOffByRequired } = useContext(TenantConfigContext)
  const users = useSelector((state: IAppState) => getAllActiveUsersSortedFirstNameLastName(state))
  const caseClassifications = useSelector(getAllCaseClassifications)
  const caseSources = useSelector(getAllCaseSources)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue
  } = useFormikContext<IPatientCaseDetailsData>()

  const caseTypeOptions: TSelectFieldOptions = Object.keys(CaseTypes).map((key) => {
    const translationKey = humps.camelize(CaseTypes[key])
    return {
      value: CaseTypes[key],
      label: t(`case:${translationKey}`)
    }
  })

  const setCaseClassificationOptionsForCaseType = (caseType: string) => {
    const options: TSelectFieldOptions = generateCaseClassificationOptions(caseClassifications, caseType)
    setCaseClassificationOptions(options)
  }

  const onChangeCaseType = (e: React.ChangeEvent<any>) => {
    setCaseClassificationOptionsForCaseType(e.currentTarget.value)
    setFieldValue("caseClassificationId", "")
    setFieldValue("leadCareUserId", "")
    handleChange(e)
  }

  const onChangeClassification = (e: React.ChangeEvent<any>) => {
    setFieldValue("leadCareUserId", "")
    handleChange(e)
  }

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(users))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    setCaseClassificationOptionsForCaseType(values.caseType)
  }, [caseClassifications])

  useEffect(() => {
    const options: TSelectFieldOptions = generateCaseSourceOptions(caseSources)
    setCaseSourceOptions(options)
  }, [caseSources])

  const renderSignedOffByField = (): JSX.Element => {
    if (!signedOffByRequired) return null

    return (
      <div className="mb-2">
        <Label name="signedOffBy" required={signedOffByRequired}>
          {t("patient:caseDetails:details:signedOffBy")}
        </Label>
        <InputField
          type="text"
          name="signedOffBy"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.signedOffBy}
          autoComplete="off"
          autoFocus={false}
        />
        <FieldError
          errorMessage={errors.signedOffBy as string}
          isVisible={errors.signedOffBy && touched.signedOffBy}
        />
      </div>
    )
  }

  const renderTargetDateField = (): JSX.Element => {
    return (
      <div className="mb-2">
        <Label name="targetDate">
          {t("patient:caseDetails:details:targetDate")}
        </Label>
        <DatePickerField name="targetDate" />
        <FieldError
          errorMessage={errors.targetDate as string}
          isVisible={errors.targetDate && touched.targetDate}
        />
      </div>
    )
  }

  const renderSourceField = (): JSX.Element => {
    return (
      <div className="mb-2">
        <Label name="source" required={true}>
          {t("case:source")}
        </Label>
        <SelectField
          name="sourceId"
          options={caseSourceOptions}
          isGrouped={true}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <FieldError
          errorMessage={errors.sourceId as string}
          isVisible={errors.sourceId && touched.sourceId}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="md:flex">
        <div className="mb-2 md:w-1/3">
          <Label name="caseType" required={true}>
            {t("patient:caseDetails:details:caseType")}
          </Label>

          <SelectField
            name="caseType"
            onChange={onChangeCaseType}
            onBlur={handleBlur}
            options={caseTypeOptions}
            placeholder={t("patient:caseDetails:chooseCaseType")}
          />

          <FieldError
            errorMessage={errors.caseType}
            isVisible={errors.caseType && touched.caseType}
          />
        </div>

        <div className="flex-1 mb-2 md:ml-2">
          <Label name="caseClassificationId" required={true}>
            {t("patient:caseDetails:details:classification")}
          </Label>

          <SelectField
            name="caseClassificationId"
            options={caseClassificationOptions}
            isDisabled={!values.caseType}
            isGrouped={true}
            onChange={onChangeClassification}
            onBlur={handleBlur}
          />

          <FieldError
            errorMessage={errors.caseClassificationId as string}
            isVisible={
              errors.caseClassificationId &&
              touched.caseClassificationId
            }
          />
        </div>
      </div>

      <div className="mb-2">
        <Label name="leadCareUserId" required={true}>
          {t("case:leadCare")}
        </Label>
        <SelectField
          name="leadCareUserId"
          options={userOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          filterOption={filterOptionsBySearchValue}
        />
        <FieldError
          errorMessage={errors.leadCareUserId as string}
          isVisible={errors.leadCareUserId && touched.leadCareUserId}
        />
      </div>

      <div className="md:flex ">
        <div className="md:flex flex-wrap md:w-1/3">
          {renderTargetDateField()}
        </div>
        <div className="md:w-2/3">
          {renderSourceField()}
        </div>
      </div>

      {renderSignedOffByField()}
    </div>
  )
}

export default CaseFields
