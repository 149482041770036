import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconList({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 7.257h29.032V0H0zm0 8.871h29.032V8.87H0zM0 25h29.032v-7.258H0z"
      />
    </svg>
  );
}

export default SvgIconList;
