import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import { IChat } from "../../../chat/state/chatTypes"
import TimeAgo from "../../../../shared/components/time/TimeAgo"

interface Props {
  chatId: string
}

const ChatLatestMessageTime: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  if(!chat?.latestMessage) return null

  return (
    <TimeAgo
      className={classNames("text-xs", {
        "text-ch-gray-500": (chat.latestMessage.readAt != null),
        "text-ch-blue-alt-500": (chat.latestMessage.readAt == null),
      })}
      utcTime={chat.latestMessage.createdAt}
    />
  )
}

export default ChatLatestMessageTime
