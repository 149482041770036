import React, { useContext, Suspense } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import { isAdmin } from "../../shared/helpers/userHelpers"
import { getCurrentUser } from "../../shared/selectors/user"
const AnalyticsMain = React.lazy(() => import("../../features/analytics/components/AnalyticsMain"))
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"

const AnalyticsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["analytics"])
  const { analyticsEnabled } = useContext(TenantConfigContext)
  const currentUser = useSelector(getCurrentUser)

  if (!analyticsEnabled || !isAdmin(currentUser)) return (
    <h3 className="text-lg font-bold text-center">
      {t("analytics:text:noPermission")}
    </h3>
  )

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className="w-full h-full">
        <AnalyticsMain />
      </div>
    </Suspense>
  )
}

export default AnalyticsPage
