import { TeamUserActionTypes, IDestroyTeamUserAction, ITeamUsersNewData, ICreateTeamUserAction } from "./teamUserTypes"

export const createTeamUser = (teamUserData: ITeamUsersNewData): ICreateTeamUserAction => {
  return {
    type: TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_PENDING,
    payload: teamUserData
  }
}

export const destroyTeamUser = (teamUserId: string): IDestroyTeamUserAction => {
  return {
    type: TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_PENDING,
    payload: teamUserId
  }
}
