import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconScreenShareOff({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M28.293 24.027l2.667 2.666H32v-2.666h-3.707zm1.027-2.667l.013-13.333a2.667 2.667 0 00-2.666-2.667H9.627l6.973 6.973c.24-.053.48-.093.733-.133V9.36l5.334 4.973-2.107 1.96 7.387 7.387a2.643 2.643 0 001.373-2.32zM3.187 2.307L1.48 4l2.053 2.053a2.645 2.645 0 00-.866 1.974V21.36a2.667 2.667 0 002.666 2.667H0v2.666h24.173l3.614 3.614 1.693-1.694L3.187 2.307zm6.146 17.72c.414-1.974 1.227-3.934 2.76-5.414l2.12 2.12c-2.053.507-3.6 1.574-4.88 3.294z"
        />
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconScreenShareOff;
