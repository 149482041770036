import { ISearchQuery } from "../../../shared/hooks/useSearch"

export interface IFetchUsersAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IFetchAllUsersAsyncAction {
  type: string
}

// Action Types
export enum UsersActionTypes {
  FETCH_USERS_ASYNC_PENDING = "@@users/FETCH_USERS_ASYNC_PENDING",
  FETCH_USERS_ASYNC_SUCCESS = "@@users/FETCH_USERS_ASYNC_SUCCESS",
  FETCH_USERS_ASYNC_ERROR = "@@users/FETCH_USERS_ASYNC_ERROR",

  FETCH_ALL_USERS_ASYNC_PENDING = "@@user/FETCH_ALL_USERS_ASYNC_REQUEST",
  FETCH_ALL_USERS_ASYNC_SUCCESS = "@@user/FETCH_ALL_USERS_ASYNC_SUCCESS",
  FETCH_ALL_USERS_ASYNC_ERROR = "@@user/FETCH_ALL_USERS_ASYNC_ERROR"
}
