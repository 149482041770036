import _ from "lodash"
import { IUser } from "../../features/user/state/userTypes"

export const isAdmin = (user: IUser) => {
  return _.includes(user?.roles, "admin")
}

export const isGuest = (user: IUser) => {
  return user.tenantGuest
}
