import { Form, Formik } from "formik"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { IPatientCaseDetailsData, IPatientNewData } from "../../state/patientNewTypes"
import Card from "../../../../shared/components/layout/Card"
import PatientDetailsFormCard from "../../../patient/components/formCards/PatientDetailsFormCard"
import CaseFields from "./CaseFields"
import { caseNewValidationSchema } from "../../../caseNew/state/caseNewValidation"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { useDispatch } from "react-redux"
import { fetchAllUsersAsync } from "../../../users/state/usersActions"
import { fetchCaseClassificationAction } from "../../../caseClassification/state/caseClassificationActions"
import WizardForceUpdate from "../../../../shared/components/ui/wizard/WizardForceUpdate"
import { fetchCaseSourcesAction } from "../../../sources/state/caseSourceActions"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientNewData
  setData: (data: IPatientNewData) => void
}

type Props = IPropsFromParent

const CaseDetailsForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "patient", "case"])
  const { formikFormRef } = useContext(FormContext)
  const { signedOffByRequired } = useContext(TenantConfigContext)

  const initialValues = {
    caseType: "",
    caseClassificationId: "",
    leadCareUserId: "",
    signedOffBy: "",
    targetDate: undefined,
    isSignedOffByRequired: signedOffByRequired,
  }

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    dispatch(fetchCaseClassificationAction())
    dispatch(fetchCaseSourcesAction())
  }, [])

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full">
        <h4 className="flex mb-2 font-semibold">
          {t("patient:caseDetails:sectionTitle")}
        </h4>
        <p className="mb-2">
          {t("patient:caseDetails:intro")}
        </p>
        <ul className="mb-3 list-disc list-inside">
          <li>
            {t("patient:caseDetails:instructions1")}
          </li>
          <li>
            {t("patient:caseDetails:instructions2")}
          </li>
        </ul>

        <PatientDetailsFormCard data={props.data} />

        <div className="w-full lg:w-2/3">
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={caseNewValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const data: IPatientCaseDetailsData = {
                caseType: values.caseType,
                caseClassificationId: values.caseClassificationId,
                leadCareUserId: values.leadCareUserId,
                signedOffBy: values.signedOffBy,
                targetDate: values.targetDate,
                sourceId: values.sourceId
              }

              props.setData({
                ...props.data,
                casesAttributes: [data]
              })

              setSubmitting(false)
              props.goToNextStep()
            }}
            innerRef={formikFormRef}
          >
            {() => {
              return (
                <Form>
                  <WizardForceUpdate />
                  <DenotesRequiredMessage />
                  <CaseFields />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default CaseDetailsForm
