import React, { ReactNode } from "react"
import classNames from "classnames"
import { useAppSelector } from "../../../../shared/hooks"
import { IUser } from "../../state/userTypes"
import { MockStore } from "redux-mock-store"
import NameUser from "../../../../shared/components/names/NameUser"
import { getCurrentUser, getUserByHasUserId } from "../../../../shared/selectors/user"
import TimeAgo from "../../../../shared/components/time/TimeAgo"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { isGuest } from "../../../../shared/helpers/userHelpers"

interface IPropsFromParent {
  store?: MockStore;
  userId: string;
  nameLabel?: ReactNode;
  label?: ReactNode
}

const UserDetails: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const user = useAppSelector((state): IUser => getUserByHasUserId(state, props))
  const currentUser = useAppSelector(getCurrentUser)

  const renderContent = (): JSX.Element => {
    const { t } = useTranslation(["user"])

    if (!user) return (<LoadingSpinner />)

    const renderProfessionalTitle = () => {
      if (!user.professionalTitle) return null

      if (user.organisation) return (
        <li>
          {user.professionalTitle} ({user.organisation})
        </li>
      )

      return (
        <li>
          {user.professionalTitle}
        </li>
      )
    }

    const renderEmail = () => {
      if (isGuest(currentUser)) return null

      return (
        <li className="truncate">
          {user.email}
        </li>
      )
    }

    return (
      <div className="flex-1 lg:grid grid-cols-2 gap-3">
        <ul className="col-span-1">
          <li>
            <NameUser
              firstName={user.firstName}
              lastName={user.lastName}
              className={classNames("font-semibold", { "text-ch-gray-500": !user.activeInCurrentTenant })}
              label={props.nameLabel}
              isPending={user.pending}
              isGuest={isGuest(user)}
            />
          </li>
          {renderProfessionalTitle()}
        </ul>
        <ul className="col-span-1">
          {renderEmail()}
          <li className="text-ch-gray-alt-500 italic">
            {t("user:lastOnline")}: <TimeAgo utcTime={user.lastSeenInCurrentTenant} />
          </li>
          <li>
            {props.label}
          </li>
        </ul>
      </div>
    )
  }

  return renderContent()
}

export default UserDetails
