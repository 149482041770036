import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconPathway({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 38"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M33.965 31.22c0 1.799-.746 3.523-2.072 4.794A7.232 7.232 0 0126.89 38a7.233 7.233 0 01-5.002-1.986c-1.326-1.271-2.072-2.995-2.072-4.794.003-.84.165-1.672.478-2.457l-8.153-5.017a7.164 7.164 0 01-3.618 1.907 7.36 7.36 0 01-4.113-.355 7.02 7.02 0 01-3.206-2.495A6.581 6.581 0 010 19.017c0-1.348.42-2.666 1.205-3.785a7.022 7.022 0 013.206-2.495 7.36 7.36 0 014.113-.355 7.164 7.164 0 013.619 1.907l8.152-5.017a6.692 6.692 0 01-.478-2.458 6.624 6.624 0 011.607-4.325 7.153 7.153 0 014.109-2.36 7.362 7.362 0 014.737.683 6.906 6.906 0 013.203 3.414 6.532 6.532 0 01.208 4.587 6.834 6.834 0 01-2.883 3.666 7.334 7.334 0 01-4.658 1.074 7.215 7.215 0 01-4.307-2.01l-8.152 5.017a6.565 6.565 0 010 4.915l8.152 5.017a7.167 7.167 0 013.617-1.898 7.36 7.36 0 014.106.358 7.021 7.021 0 013.2 2.49 6.583 6.583 0 011.209 3.779z"
      />
    </svg>
  );
}

export default SvgIconPathway;
