import { IAddCaseForPatientAsyncAction, IPatientCaseNewData, IPatientEditData, IPatientEditFurtherDetailsData, IUpdatePatientAsyncAction, PatientEditActionTypes } from "./patientEditTypes"

export const updatePatientAsync = (patientId: string, patient: IPatientEditData | IPatientCaseNewData | IPatientEditFurtherDetailsData): IUpdatePatientAsyncAction => {
  return {
    type: PatientEditActionTypes.UPDATE_PATIENT_ASYNC_PENDING,
    payload: {
      id: patientId,
      patient: patient
    }
  }
}

export const addCaseForPatientAsync = (patientId: string, patient: IPatientCaseNewData): IAddCaseForPatientAsyncAction => {
  return {
    type: PatientEditActionTypes.ADD_CASE_FOR_PATIENT_ASYNC_PENDING,
    payload: {
      id: patientId,
      patient: patient
    }
  }
}
