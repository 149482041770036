import _ from "lodash"
import { createSelector } from "reselect"
import { IAppState } from "../../../app/appTypes"
import { IHaveCaseId } from "../../../features/case/state/caseTypes"
import { IHaveMeetingId } from "../../../features/meeting/state/meetingTypes"
import { IMeetingCase } from "../../../features/meetingCase/state/meetingCaseTypes"
import { getAllMeetingCases } from "../meeting"

const getCaseIdFromProps = (state: IAppState, ownProps: IHaveCaseId): string =>
  ownProps.caseId

const getMeetingIdFromProps = (state: IAppState, ownProps: IHaveMeetingId): string =>
  ownProps.meetingId

export const getMeetingCaseByMeetingAndCaseId = createSelector(
  getAllMeetingCases,
  getCaseIdFromProps,
  getMeetingIdFromProps,
  (allMeetingCases, caseId, meetingId): IMeetingCase => {
    return _.find(allMeetingCases, (meetingCase: IMeetingCase) => {
      return meetingCase.case == caseId && meetingCase.meeting == meetingId
    })
  }
)
