import React, { useState } from "react"
import Modal from "react-modal"
import { useSelector, useDispatch } from "react-redux"
import { getPathway } from "../../../shared/selectors/pathway"
import Card from "../../../shared/components/layout/Card"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import DetailsCardRight from "../../../shared/components/layout/detailsCard/DetailsCardRight"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import PathwayHeader from "./PathwayHeader"
import { defaultModalStyle, dialogueModalStyle, StyleVariants } from "../../../shared/state/sharedTypes"
import Button from "../../../shared/components/buttons/Button"
import { Formik, Form } from "formik"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { createPathwayStepAction, deletePathwayStepAction, updatePathwayStepAction } from "../pathwayActions"
import { IPathwayStepNewData, IPathwayStep } from "../pathwayTypes"
import ContextMenu from "../../../shared/components/contextMenu/ContextMenu"
import PathwayStep from "./PathwayStep"
import Buttons from "../../../shared/components/layout/Buttons"
import EditPathwayStep from "./EditPathwayStep"

type Props = {
  pathwayId: string
}

const PathwaySteps: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const pathway = useSelector(getPathway(props))
  const steps = _.sortBy(pathway.steps, "position")
  const { t } = useTranslation(["shared", "pathway"])

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
  const openCreateModal = () => { setCreateModalIsOpen(true) }
  const closeCreateModal = () => { setCreateModalIsOpen(false) }

  const [editStep, setEditStep] = useState<IPathwayStep>(null)
  const openEditStepModal = (step: IPathwayStep) => { setEditStep(step) }
  const closeEditStepModal = () => { setEditStep(null) }

  const [deleteStep, setDeleteStep] = useState<IPathwayStep>(null)
  const openDeleteStepModal = (step: IPathwayStep) => { setDeleteStep(step) }
  const closeDeleteStepModal = () => { setDeleteStep(null) }

  const confirmDelete = (step: IPathwayStep) => {
    dispatch(deletePathwayStepAction(pathway.id, step.id))
    closeDeleteStepModal()
  }

  const changeStepPosition = (step: IPathwayStep, position: number) => {
    if(position > 0 && position <= pathway.steps.length) {
      step.position = position
      dispatch(updatePathwayStepAction(props.pathwayId, step))
    }
  }

  const initialValues: IPathwayStepNewData = {
    name: "",
    description: ""
  }

  if (!pathway) return null

  return (
    <Card>
      <div className="flex items-center flex-wrap">
        <h3 className="mr-4 mb-2 text-lg">
          {t("pathway:steps")} ({steps.length})
        </h3>
        {
          pathway.permissions?.canEdit ?
            <Button action={openCreateModal} variant={StyleVariants.BLUE}>
              {t("pathway:details:addStep")}
            </Button>
            : null
        }
      </div>
      <div className="flex mb-4 items-end">
        <p className="flex-1 pr-10 lg:pr-24">
          {t("pathway:details:stepsIntro")}
        </p>
      </div>

      {steps.length < 1 ?
        <div className="mt-4 p-4 bg-ch-blue-100 rounded-md italic">
          {t("pathway:details:pleaseAddSteps")}
        </div>
        : null}
      {steps.map((step) => {
        return (
          <DetailsCard className="bg-ch-gray-300" key={step.position + step.name}>
            <PathwayStep step={step} />
            <DetailsCardRight>
              <div className="flex mt-2 flex-wrap flex-1 justify-center lg:justify-end">
                {
                  pathway.permissions?.canEdit ?
                    <ContextMenu
                      id={step.id}
                      actions={[
                      {
                        text: t("pathway:details:editStep"),
                        action: () => { openEditStepModal(step)}
                      }, {
                        text: t("pathway:details:deleteStep"),
                        action: () => openDeleteStepModal(step)
                      },
                      {
                        text: t("pathway:details:moveStepUp"),
                        action: () => changeStepPosition(step, step.position -= 1),
                        isDisabled: step.position == 1
                      },
                      {
                        text: t("pathway:details:moveStepDown"),
                        action: () => changeStepPosition(step, step.position += 1),
                        isDisabled: step.position == pathway.steps.length
                      }]}
                    />
                    : null
                }
              </div>
            </DetailsCardRight>
          </DetailsCard>
        )
      })}

      <Modal
        isOpen={!!deleteStep}
        onRequestClose={closeDeleteStepModal}
        style={dialogueModalStyle}>
        <ModalContainer handleClose={closeDeleteStepModal}>
          <h2 className="text-lg mb-3">
            {t("pathway:details:deleteStepHeader")}:
          </h2>
          <div>
            <PathwayHeader pathwayId={props.pathwayId} hideButtons />
          </div>
          <div className="my-3">
            {t("pathway:details:deleteStepDetails")}:
          </div>
          <DetailsCard className="bg-ch-gray-300" >
            <PathwayStep step={deleteStep} />
          </DetailsCard>
          <div className="my-3">
            {t("pathway:details:deleteAreYouSure")}
          </div>
          <Buttons buttons={[
            <Button key="1" action={() => closeDeleteStepModal()} variant={StyleVariants.BLUE}>{t("shared:cancel")}</Button>,
            <Button key="2" action={() => confirmDelete(deleteStep)} variant={StyleVariants.PURPLE}>{t("pathway:details:deleteStep")} </Button>
          ]} />

        </ModalContainer>
      </Modal>

      <Modal isOpen={!!editStep} onRequestClose={closeEditStepModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeEditStepModal}>
          <EditPathwayStep step={editStep} setStep={setEditStep} pathwayId={pathway.id} />
        </ModalContainer>
      </Modal>
      <Modal isOpen={createModalIsOpen} onRequestClose={closeCreateModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeCreateModal}>
          <h2 className="text-lg mb-3">
            {t("pathway:details:addStepHeader")}
          </h2>

          <PathwayHeader pathwayId={props.pathwayId} hideButtons />

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(createPathwayStepAction(pathway.id, values))
              setSubmitting(true)
              closeCreateModal()
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              handleChange,
              handleBlur,
              dirty
            }) => {
              return (
                <Form className="w-full lg:w-2/3">
                  <DenotesRequiredMessage />

                  <div className="mb-2">
                    <Label name="name" required={true}>
                      {t("pathway:details:stepName")}:
                    </Label>
                    <InputField
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoFocus={true}
                    />
                    <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
                  </div>

                  <div className="mb-2">
                    <Label name="description" required={true}>
                      {t("pathway:details:stepDescription")}:
                    </Label>
                    <InputField
                      as="textarea"
                      name="description"
                      className="h-24"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <FieldError errorMessage={errors.description as string} isVisible={(errors.description && touched.description)} />
                  </div>

                  <div className="flex justify-center mb-6">
                    <Button
                      isDisabled={!dirty || isSubmitting}
                      variant={StyleVariants.PURPLE}
                      isSubmit={true}
                    >
                      {t("pathway:details:addStep")}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </ModalContainer>
      </Modal>
    </Card >)
}

export default PathwaySteps
