import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { UsersViewTabs } from "../../state/liveMeetingTypes"
import { IconGrid, IconList } from "../../../../svg/icons"
import { changeUsersViewTabAction } from "../../state/liveMeetingActions"
import { getSelectedUserViewTab } from "../../../../shared/selectors/ui"

const LiveMeetingUsersViewSwitch: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const selectedUsersViewTab = useSelector(getSelectedUserViewTab)
  const dispatch = useDispatch()
  const onChangeUsersViewTab = (selectedTab: number) => dispatch(changeUsersViewTabAction(selectedTab))

  const renderUsersViewListTab = (): JSX.Element => {
    const isSelected = (selectedUsersViewTab == UsersViewTabs.LIST)
    return (
      <a
        href="#"
        onClick={() => onChangeUsersViewTab(UsersViewTabs.LIST)}
        className={classNames({
          "text-ch-blue-500": isSelected,
          "text-white": !isSelected
        })}>
        <IconList
          className="fill-current h-6"
          title={t("liveMeeting:usersList")}
        />
      </a>
    )
  }

  const renderUsersViewGridTab = (): JSX.Element => {
    const isSelected = (selectedUsersViewTab == UsersViewTabs.GRID)
    return (
      <a
        href="#"
        onClick={() => onChangeUsersViewTab(UsersViewTabs.GRID)}
        className={classNames({
          "text-ch-blue-500": isSelected,
          "text-white": !isSelected
        })}>
        <IconGrid
          className="fill-current h-6"
          title={t("liveMeeting:userGrid")}
        />
      </a>
    )
  }

  return (
    <ul className="flex">
      <li className="mr-3">
        {renderUsersViewListTab()}
      </li>
      <li>
        {renderUsersViewGridTab()}
      </li>
    </ul>
  )
}

export default LiveMeetingUsersViewSwitch
