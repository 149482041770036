import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import QuickViewCard from "../../../../shared/components/ui/quickView/QuickViewCard"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"

interface IProps {
  userId: string
}

const CaseMemberListItem: React.FC<IProps> = (props): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))
  if (!props.userId || !user) return null

  return (
    <li>
      <QuickViewCard>
        <UserDetailsCard user={user} />
      </QuickViewCard>
    </li>
  )
}

export default CaseMemberListItem
