import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ChatMembersModal from "./ChatMembersModal"
import { useDispatch, useSelector } from "react-redux"
import { toggleMutedChatAsync } from "../../state/chatActions"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import { ChatTypes, IChat } from "../../state/chatTypes"
import EndChatConfirmModal from "../EndChatConfirmModal"
import ExportChatModal from "../export/ExportChatModal"
import ContextMenu from "../../../../shared/components/contextMenu/ContextMenu"

interface Props {
  chatId: string
}

const ChatMenu: React.FC<Props> = (props: Props): JSX.Element => {
  if (!props.chatId) return null

  const { t } = useTranslation(["shared", "message"])
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false)
  const closeMembersModal = () => setIsMembersModalOpen(false)
  const openMembersModal = () => setIsMembersModalOpen(true)

  const [confirmEndModalIsOpen, setConfirmEndModalIsOpen] = useState(false)

  const [exportChatModalIsOpen, setExportChatModalIsOpen] = useState(false)

  const dispatch = useDispatch()
  const toggleMuteChat = () => dispatch(toggleMutedChatAsync(props.chatId))

  const renderMuteButton = () => {
    if (!chat || chat?.endedAt) return null
    const textKey = chat.options?.muted ? "unmute" : "mute"
    return (
      {
        text: t(`message:index:buttons:${textKey}`),
        action: toggleMuteChat
      }
    )
  }

  const renderEndChatButton = () => {
    if (
      !chat?.permissions.canEnd ||
      chat?.endedAt !== null ||
      chat?.type == ChatTypes.MEETING
    ) return null

    return (
      {
        text: t("message:shared:buttons:endChat"),
        action: () => setConfirmEndModalIsOpen(true)
      }
    )
  }

  const renderExportChatButton = () => {
    if (
      !chat?.permissions.canExport ||
      chat?.endedAt !== null ||
      chat?.case == null
    ) return null

    return ({
      text: t("message:exportChat:buttons:exportAndClose"),
      action: () => setExportChatModalIsOpen(true)
    })
  }
  const actions = [
    {
      text: t("message:index:buttons:viewMembers"),
      action: openMembersModal
    },
    renderMuteButton(),
    renderEndChatButton(),
    renderExportChatButton()
  ].filter((a) => a) // to remove the possible `null` elements


  return (
    <div className="ml-2">
      <ContextMenu id={props.chatId} actions={actions} />
      <ChatMembersModal
        closeModal={closeMembersModal}
        isOpen={isMembersModalOpen}
        chatId={props.chatId}
      />
      <EndChatConfirmModal
        chatId={props.chatId}
        isOpen={confirmEndModalIsOpen}
        closeModal={() => setConfirmEndModalIsOpen(false)}
      />
      <ExportChatModal
        chatId={props.chatId}
        isOpen={exportChatModalIsOpen}
        closeModal={() => setExportChatModalIsOpen(false)}
      />
    </div >
  )
}

export default ChatMenu
