import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { IAsset } from "../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { getComponentTypeForAsset, componentLookup, isAssetFiltered, clickableComponentAction } from "../../../../shared/helpers/assetHelpers"
import { IUser } from "../../../user/state/userTypes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { IMeeting, IHaveMeetingId, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import DeleteAssetButton from "../../../../shared/components/buttons/DeleteAssetButton"
import { IMeetingCase } from "../../../meetingCase/state/meetingCaseTypes"

interface IProps {
  assetId: string
  meetingId?: string
  action?: () => void
  deleteAction?: (assetId: string) => void
  isSelected?: boolean
  caseId: string
  mentionableUsers: { [id: string]: IUser }
  assetTypeFilters: IAssetTypeFilters,
  isSelectingAssets?: boolean
  meetingCaseId?: string
  readonly asset?: IAsset
  readonly meeting?: IMeeting
  readonly meetingCase?: IMeetingCase
}

const CaseAssetListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props as IHaveMeetingId))

  const shouldRender = () => {
    // this logic is all to not show any assets in a meeting case that were added after the corresponding meeting was ended
    // so if there's no current meeting (ie we are looking at the case, not a meeting case), we should always render
    if (!props.meetingId) return true
    if (!meeting) return true
    // if the current meeting is not ended or cancelled, we should render
    if (!(meeting.status == MeetingStatuses.Ended || meeting.status == MeetingStatuses.Cancelled)) return true
    // if the asset belongs to the current meeting (eg in case a case report was generated after the meeting was ended), always render
    if (asset?.asset && asset.asset.meetingId == props.meetingId) return true
    // if the asset was create before the meeting was ended or cancelled, render
    if (new Date(asset.createdAt) < new Date(meeting.endDateActual)) return true
    if (new Date(asset.createdAt) < new Date(meeting.cancelledDate)) return true
    // if non of the above applied, we shouldn't render.
    return false
  }

  if (!asset) return null
  if (!shouldRender()) return null

  const assetComponentType = getComponentTypeForAsset(asset)
  const Asset = componentLookup[assetComponentType]
  const action = clickableComponentAction(props.action, assetComponentType)

  // we're hiding the component, not returning null, because this way the
  // image components don't need to reload their images.
  const hidden = isAssetFiltered(Asset, props.assetTypeFilters)

  const renderDeleteButton = (): JSX.Element => {
    if (!props.deleteAction || !asset.permissions.canDelete) return null
    return (
      <DeleteAssetButton deleteAction={props.deleteAction} assetId={props.assetId} />
    )
  }

  return (
    <li className={classNames("mb-1", { hidden })}>
      <Asset
        assetId={props.assetId}
        action={props.isSelectingAssets ? null : action}
        isSelected={props.isSelected}
        caseId={props.caseId}
        mentionableUsers={props.mentionableUsers}
      >
        <div className="flex flex-wrap content-center ml-2 justify-contents-end">
          {renderDeleteButton()}
        </div>
      </Asset>
    </li>
  )
}

export default CaseAssetListItem
