import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import InputField from "../../shared/components/forms/InputField"
import Buttons from "../../shared/components/layout/Buttons"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentUser } from "../../shared/selectors/user"
import history from "../../app/routes/history"
import { RoutePaths } from "../../app/routes/Routes"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import { fetchCurrentUserAction } from "../../features/user/state/userActions"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import _ from "lodash"
import { requestMfaSmsAction } from "../../features/mfa/state/mfaActions"
import { useAppSelector } from "../../shared/hooks"
import { mfaSmsRequesting } from "../../shared/selectors/userSession"

interface IPropsFromParent {
  switchToTotp: () => void
}

const MfaSmsPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["account"])
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const currentUser = useSelector(getCurrentUser)

  const smsSent = !useAppSelector(mfaSmsRequesting)

  const verifyCode = async (code: string, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true)
    API.post("/user/mfa/verify_sms", { code }, withAuthHeader()).then(() => {
      dispatch(fetchCurrentUserAction())
      history.push(RoutePaths.MEETINGS)
    }).catch(() => {
      setSubmitting(false)
      dispatch(
        displayUserMessageAction({
          messageKey: "mfaInvalidCode",
          type: UserMessageTypes.ERROR
        })
      )
      inputRef.current.focus()
    })
  }

  const requestSms = () => {
    dispatch(requestMfaSmsAction())
  }

  useEffect(() => {
    if (!currentUser) return
    if (!currentUser.mfaRequired) {
      history.push(RoutePaths.ROOT)
    }
  }, [currentUser])

  useEffect(() => {
    requestSms()
  }, [])

  useEffect(() => {
    if (!smsSent) return
    inputRef.current.focus()
  }, [smsSent])

  const form =
    <Formik initialValues={{ code: "" }} onSubmit={() => (null)}>
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        setSubmitting,
      }) => (
        <Form>
          <div className="flex flex-col items-center justify-center overflow-hidden">
            <div className="m-auto w-full md:w-1/3 mb-3">
              <InputField className="text-xl text-center" innerRef={inputRef} name="code" onChange={handleChange} onBlur={handleBlur} />
            </div>
            <div className="mb-5 text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer" onClick={requestSms}>
              {t("mfaSmsResendCode")}
            </div>
            {currentUser.mfaMethods.includes("totp") ?
              <div className="mb-5 text-sm">
                If you prefer to authenticate with a TOTP code, please click
                <a onClick={props.switchToTotp} className="whitespace-pre text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer"> here </a>
              </div>
              : null}
          </div>

          <Buttons buttons={[
            <Button
              key="1"
              isDisabled={isSubmitting}
              variant={StyleVariants.PURPLE}
              action={() => { verifyCode(values.code, setSubmitting) }}
            >
              {t("submit")}
            </Button>
          ]} />
        </Form>
      )
      }
    </Formik>

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden">
      <div className="text-ch-blue-500 text-xl mb-5">
        {t("mfaRequiredHeader", { email: currentUser.email })}
      </div>
      <div className="mb-5">
        {smsSent
          ? <div><div className="mb-5">{t("mfaSmsSent")}</div>{form}</div>
          : (
            <div>
              <div>
                {t("mfaSmsSending")}
                <LoadingSpinner />
              </div>
            </div>
          )}
      </div>
    </div >
  )
}

export default MfaSmsPage
