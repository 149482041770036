import React from "react"
import { IconCollapseUp, IconExpandDown } from "../../../svg/icons"
import { StyleVariants } from "../../state/sharedTypes"
import { useTranslation } from "react-i18next"

interface IProps {
  className?: string
  variant?: string
  action: () => void
  isExpanded: boolean
  expandText?: string
  collapseText?: string
}

const ButtonExpandCollapse: React.FC<IProps> = ({ className, action, variant, isExpanded, expandText, collapseText }): JSX.Element => {
  const { t } = useTranslation(["shared"])

  if (!variant) {
    variant = StyleVariants.BLUE
  }

  if (!collapseText) {
    collapseText = t("shared:collapse")
  }

  if (!expandText) {
    expandText = t("shared:expand")
  }

  const renderIcon = (): JSX.Element => {
    if (isExpanded) {
      return <IconCollapseUp className="fill-current w-4 h-4" title={collapseText} />
    } else {
      return <IconExpandDown className="fill-current w-4 h-4" title={expandText} />
    }
  }

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "flex",
      "items-center",
      `text-${variant}-500`,
      `hover:text-${variant}-600`,
    ]

    const propClasses = className ? className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    return (
      <a href="#" onClick={action} className={allClasses}>
        <span className="mr-2">{isExpanded ? t("shared:collapse") : t("shared:expand")}</span> {renderIcon()}
      </a>
    )
  }

  return renderContent()
}

export default ButtonExpandCollapse
