import { Form, Formik } from "formik"
import _ from "lodash"
import { format, formatISO } from "date-fns"
import React, { useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import SvgIconTime from "../../../svg/icons/IconTime"
import { IUser } from "../../user/state/userTypes"
import { DurationLabel } from "../../meetingNew/components/DurationLabel"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { IMeetingNewData, IMeeting } from "../../meeting/state/meetingTypes"
import { getAllUsers } from "../../../shared/selectors/user"
import { updateMeetingAction } from "../../meeting/state/meetingActions"
import { IAppState } from "../../../app/appTypes"
import { startEndDates } from "../../../shared/helpers/timeHelpers"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import { ISpecialty } from "../../specialty/state/specialtyTypes"
import { meetingNewValidationSchema } from "../../meetingNew/state/meetingNewValidation"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import { UserConfigContext } from "../../userConfig/contexts/UserConfigContext"
import { getMeetingById } from "../../../shared/selectors/meeting"
import { getMeetingCoordinator } from "../../../shared/selectors/liveMeetingConfig/userConfig"
import { formatTimeString, utcDateToZonedTime } from "../../../shared/helpers/timeHelpers"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import SelectField from "../../../shared/components/forms/SelectField"
import DatePickerField from "../../../shared/components/datePicker/DatePickerField"
import TimeInput from "../../../shared/components/time/TimeInput"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import Card from "../../../shared/components/layout/Card"

interface IPropsFromParent {
  store?: MockStore
  meetingId: string
}

const MeetingEditForm = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["meeting"])
  const { timezone } = useContext(UserConfigContext)
  const [userOptions, setUserOptions] = React.useState([])
  const [specialtiesOptions, setSpecialtiesOptions] = React.useState([])

  const users = useSelector((state: IAppState): { [id: string]: IUser } => getAllUsers(state))
  const specialties = useSelector((state: IAppState): { [id: string]: ISpecialty } => getAllSpecialties(state))
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))
  const coordinator = useSelector((state: IAppState): IUser => getMeetingCoordinator(state, props))
  const dispatch = useDispatch()
  const meetingStartDate = utcDateToZonedTime(meeting.startDate, timezone)
  const meetingEndDate = utcDateToZonedTime(meeting.endDate, timezone)


  const initialValues = {
    name: meeting.name,
    specialtyId: meeting.specialtyId,
    coordinator: coordinator.id,
    users: meeting.users.filter(id => coordinator.id !== id),
    startDate: new Date(meetingStartDate),
    startTime: format(new Date(meetingStartDate), formatTimeString),
    endTime: format(new Date(meetingEndDate), formatTimeString)
  }

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(users))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(specialties)
    setSpecialtiesOptions(options)
  }, [specialties])

  return (
    <Card className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={meetingNewValidationSchema(timezone)}
        onSubmit={(values, { setSubmitting }) => {
          const dates = startEndDates(values.startDate, values.startTime, values.endTime, timezone)

          values = meetingNewValidationSchema(timezone).cast(values)
          const data = {
            name: values.name,
            specialtyId: values.specialtyId,
            startDate: formatISO(dates.start),
            endDate: formatISO(dates.end),
            coordinatorId: values.coordinator,
            userIds: meeting.users
          } as IMeetingNewData

          dispatch(updateMeetingAction(props.meetingId, data))
          setSubmitting(false)
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          dirty
        }) => {
          return (
            <Form className="w-full lg:w-2/3">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="name" required={true}>
                  {t("meeting:title")}
                </Label>
                <InputField
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="off"
                  autoFocus={true}
                />
                <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="specialtyId" required={true}>
                  {t("meeting:specialty")}
                </Label>
                <SelectField
                  name="specialtyId"
                  options={specialtiesOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isGrouped={true}
                />
                <FieldError errorMessage={errors.specialtyId as string} isVisible={(errors.specialtyId && touched.specialtyId) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="coordinator" required={true}>
                  {t("meeting:coordinator")}
                </Label>
                <SelectField
                  name="coordinator"
                  options={userOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  filterOption={filterOptionsBySearchValue}
                />
                <FieldError errorMessage={errors.coordinator as string} isVisible={(errors.coordinator && touched.coordinator) as boolean} />
              </div>

              <div>
                <Label name="startDate" required={true}>
                  {t("meeting:startDateAndTime")}
                </Label>
                <div className="flex flex-wrap">
                  <div className="flex mr-4 mb-1">
                    <div className="flex flex-col">
                      <DatePickerField name="startDate" />
                      <div className="lg:h-8 my-1 ml-7 sm:h-0">
                        <FieldError errorMessage={errors.startDate as string} isVisible={(errors.startDate && touched.startDate) as boolean} />
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex space-between items-center">
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                          <TimeInput
                            name="startTime"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mx-2">-</span>
                        </div>
                        <div className="h-8 my-1">
                          <FieldError errorMessage={errors.startTime as string} isVisible={(errors.startTime && touched.startTime) as boolean} />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                          <TimeInput
                            name="endTime"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="self-center mx-1 flex">
                            <SvgIconTime className="text-ch-blue-500 mx-2 w-5 h-5" />
                            <div className="self-center">
                              <DurationLabel
                                startTime={values.startTime}
                                endTime={values.endTime}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="h-8 my-1">
                          <FieldError errorMessage={errors.endTime as string} isVisible={(errors.endTime && touched.endTime) as boolean} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  isDisabled={!dirty || isSubmitting}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                >
                  {t("updateMeeting")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default MeetingEditForm
