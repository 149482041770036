// Models
export interface IMeetingUser {
  id: string
  userId: string
  acceptanceResponse?: AcceptanceResponses
  meetingId?: string
  joinedCount?: number
  mdtRole?: string
  teamUserId?: string
}

export enum AcceptanceResponses {
  Accepted = "accepted",
  Rejected = "rejected",
  Pending = "pending"
}

export interface IMeetingUsersNewData {
  meetingId: string
  userIds: string[]
}

export interface IHaveMeetingUserId {
  meetingUserId: string
}

// Actions
export interface ICreateMeetingUserAction {
  type: string,
  payload: IMeetingUsersNewData
}

export interface IUpdateMeetingUserAction {
  type: string,
  payload: {
    id: string,
    meetingUser: IMeetingUser
  }
}

export interface IDestroyMeetingUserAction {
  type: string,
  payload: string
}

// Action Types
export enum MeetingUserActionTypes {
  CREATE_MEETING_USER_ASYNC_PENDING = "@@meetingUser/CREATE_MEETING_USER_ASYNC_PENDING",
  CREATE_MEETING_USER_ASYNC_SUCCESS = "@@meetingUser/CREATE_MEETING_USER_ASYNC_SUCCESS",
  CREATE_MEETING_USER_ASYNC_ERROR = "@@meetingUser/CREATE_MEETING_USER_ASYNC_ERROR",

  UPDATE_MEETING_USER_ASYNC_PENDING = "@@meetingUser/UPDATE_MEETING_USER_ASYNC_PENDING",
  UPDATE_MEETING_USER_ASYNC_SUCCESS = "@@meetingUser/UPDATE_MEETING_USER_ASYNC_SUCCESS",
  UPDATE_MEETING_USER_ASYNC_ERROR = "@@meetingUser/UPDATE_MEETING_USER_ASYNC_ERROR",

  DESTROY_MEETING_USER_ASYNC_PENDING = "@@meetingUser/DESTROY_MEETING_USER_ASYNC_PENDING",
  DESTROY_MEETING_USER_ASYNC_SUCCESS = "@@meetingUser/DESTROY_MEETING_USER_ASYNC_SUCCESS",
  DESTROY_MEETING_USER_ASYNC_ERROR = "@@meetingUser/DESTROY_MEETING_USER_ASYNC_ERROR",
}
