import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconSwitchTenant({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 69 59"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M34.594 5.386c-7.322 0-13.87 3.395-18.197 8.73a2.372 2.372 0 01-.71.626 2.332 2.332 0 01-1.83.21 2.346 2.346 0 01-.829-.45 2.394 2.394 0 01-.59-.743 2.434 2.434 0 01.322-2.697 28.301 28.301 0 0113.204-9.097 27.842 27.842 0 0115.94-.393 28.237 28.237 0 0113.621 8.434 29.03 29.03 0 016.986 14.589h-4.799c-1.089-5.423-3.985-10.296-8.197-13.796a23.345 23.345 0 00-14.92-5.413zM11.476 34.2a24.165 24.165 0 006.084 11.804 23.508 23.508 0 0011.33 6.685 23.203 23.203 0 0013.087-.492 23.59 23.59 0 0010.815-7.52c.193-.254.434-.467.71-.625a2.33 2.33 0 012.658.24c.244.205.445.458.591.743a2.434 2.434 0 01-.322 2.697 28.302 28.302 0 01-13.204 9.097 27.841 27.841 0 01-15.94.394 28.236 28.236 0 01-13.621-8.435A29.032 29.032 0 016.678 34.2h4.798z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M36.213 13.927a3.01 3.01 0 00-1.866-.646 3.01 3.01 0 00-1.865.646l-10.27 8.116c-.349.276-.63.624-.823 1.02a2.9 2.9 0 00-.293 1.271v14.727c0 .78.314 1.526.873 2.077.559.551 1.317.86 2.108.86h3.313c.791 0 1.55-.309 2.109-.86.559-.55.873-1.298.873-2.077v-8.486a.97.97 0 01.291-.692c.186-.184.44-.287.703-.287h5.963a1 1 0 01.703.287.972.972 0 01.291.692v8.486c0 .78.314 1.526.873 2.077.56.551 1.318.86 2.109.86h3.313c.79 0 1.55-.309 2.108-.86.56-.55.874-1.298.874-2.077V24.334a2.92 2.92 0 00-1.116-2.29l-10.27-8.117zM8.35 23.378a1 1 0 011.7 0l6.175 9.997a1 1 0 01-.85 1.525H3.024a1 1 0 01-.85-1.525l6.174-9.997zm52.186 12.484a1 1 0 01-1.702 0l-6.174-9.997a1 1 0 01.85-1.526h12.35a1 1 0 01.85 1.526l-6.174 9.997z"
      />
    </svg>
  );
}

export default SvgIconSwitchTenant;
