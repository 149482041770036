import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import MeetingListItem from "./MeetingListItem"
import { getAllMeetingIdsUiIndexPage } from "../../../../shared/selectors/meeting"

const MeetingList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["meeting"])
  const meetingIds = useSelector(getAllMeetingIdsUiIndexPage)

  if (!meetingIds.length) return (
    <h3 className="text-lg font-bold text-center">
      {t("meeting:noMeetings")}
    </h3>
  )

  return (
    <ul>
      {meetingIds.map((meetingId: string) =>
        <MeetingListItem key={meetingId} meetingId={meetingId} />)
      }
    </ul>
  )
}

export default MeetingList
