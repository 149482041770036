import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import Card from "../../../../shared/components/layout/Card"
import { IPatientNewData } from "../../../patientNew/state/patientNewTypes"
import { IPatientEditFurtherDetailsData } from "../../../patientEdit/state/patientEditTypes"

interface Props {
  data: IPatientNewData | IPatientEditFurtherDetailsData
}

const FurtherDetailsFormCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])

  const renderPatientAddress = (): JSX.Element => {
    if(!props.data.furtherDetailsAttributes.addressLine1) return null
    const fullAddress: string = _.reject([
      props.data.furtherDetailsAttributes.addressLine1,
      props.data.furtherDetailsAttributes.addressLine2,
      props.data.furtherDetailsAttributes.city,
      props.data.furtherDetailsAttributes.county,
      props.data.furtherDetailsAttributes.country
    ], _.isEmpty).join(", ")
    return (
      <dd>
        {t("patient:details:furtherDetails:address")}: {fullAddress}
      </dd>
    )
  }

  const renderGPAddress = (): JSX.Element => {
    if(!props.data.furtherDetailsAttributes.gpAddressLine1) return null
    const fullAddress: string = _.reject([
      props.data.furtherDetailsAttributes.gpAddressLine1,
      props.data.furtherDetailsAttributes.gpAddressLine2,
      props.data.furtherDetailsAttributes.gpCity,
      props.data.furtherDetailsAttributes.gpCounty,
      props.data.furtherDetailsAttributes.gpCountry
    ], _.isEmpty).join(", ")
    return (
      <dd>
        {t("patient:details:furtherDetails:gpAddress")}: {fullAddress}
      </dd>
    )
  }

  const renderDetail = (name: string): JSX.Element => {
    const detail = props.data.furtherDetailsAttributes[name]
    if(!detail) return null
    return (
      <dd>
        {t(`patient:details:furtherDetails:${name}`)}: {detail}
      </dd>
    )
  }

  return (
    <div>
      <Card className="flex-col">
        <div className="flex-1 lg:grid grid-cols-2 gap-3">
          <dl className="col-span-1">
            <dt>
              <h4 className="font-semibold">{ t("patient:furtherDetails:patientSectionTitle") }</h4>
            </dt>
            { renderPatientAddress() }
            { renderDetail("postcode") }
            { renderDetail("email") }
            { renderDetail("phoneNumber") }
            { renderDetail("phoneNumberAlt") }
          </dl>

          <dl className="col-span-1">
            <dt>
              <h4 className="font-semibold">{ t("patient:furtherDetails:gPSectionTitle") }</h4>
            </dt>
            { renderDetail("gpName") }
            { renderGPAddress() }
            { renderDetail("gpPostcode") }
            { renderDetail("gpEmail") }
            { renderDetail("gpPhoneNumber") }
          </dl>
        </div>
      </Card>
    </div>
  )
}

export default FurtherDetailsFormCard
