import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconUsers({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="currentColor">
        <path d="M7.13 10.14A8.67 8.67 0 00.7 12.66l-.24.28v5.28a1 1 0 001 1h3.08v-2.7l.55-.62.25-.29a11 11 0 014.71-2.86A6.59 6.59 0 018 10.14h-.87zm20.21 2.49a8.67 8.67 0 00-6.43-2.52 10.47 10.47 0 00-1.09.06 6.59 6.59 0 01-2 2.45 10.91 10.91 0 015 3l.25.28.54.62v2.71h2.94a1 1 0 001-1v-5.32l-.21-.28zM7.1 8.19h.31a6.45 6.45 0 013.11-6.29A4.09 4.09 0 107.1 8.23v-.04zm13.33-.75c.012.23.012.46 0 .69.192.03.386.047.58.05h.19A4.09 4.09 0 1017.47 2a6.53 6.53 0 012.96 5.44z" />
        <circle cx={13.87} cy={7.45} r={4.47} />
        <path d="M7 17l-.25.28v5.33a1.57 1.57 0 001.6 1.54h11.49a1.57 1.57 0 001.6-1.54V17.3l-.24-.3a9.58 9.58 0 00-7.09-2.7A9.69 9.69 0 007 17z" />
      </g>
    </svg>
  );
}

export default SvgIconUsers;
