import React from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconChangeTracking, IconUser } from "../../../svg/icons"
import NameUser from "../../../shared/components/names/NameUser"
import { IAppState } from "../../../app/appTypes"
import { IUser } from "../../user/state/userTypes"
import { getUserById } from "../../../shared/selectors/user"
import { useSelector } from "react-redux"
import { AuditEventList } from "../../audit/component/auditEventList/AuditEventList"

interface IPropsFromParent {
    userId: string
}

const UserChangeTracking: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const user = useSelector((state: IAppState): IUser => getUserById(state, props.userId))

  return (
    <div>
      <div className="flex items-center flex-col mb-6">
        <IconCircleContainer className="mb-3">
          <IconUser className="fill-current w-6 h-6" />
        </IconCircleContainer>
        {user ? <dl className="text-center mb-3">
          <dt className="mb-1">
            <h5>
              <NameUser
                firstName={user.firstName}
                lastName={user.lastName}
                className={classNames("font-semibold", { "text-center mb-3": !user.activeInCurrentTenant })}
                isPending={user.pending}
              />
            </h5>
            <h5 className={classNames({ hidden: !user.professionalTitle || user.professionalTitle.length == 0 })}>
              {user.professionalTitle}
            </h5>
          </dt>
          <dd className="col-span-1">
            <h5 className="truncate">
              {user.email}
            </h5>
          </dd>
        </dl> : null}
      </div>
      <div className="flex">
        <IconChangeTracking className="fill-current w-6 h-6 md:ml-2" title={t("shared:changeTracking")} /> {t("shared:changeTracking")}
      </div>
      <AuditEventList currentUserId = {props.userId} entityId={props.userId}/>
    </div>
  )
}
export default UserChangeTracking
