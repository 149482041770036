import React from "react"
import _ from "lodash"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
  isDark?: boolean
  hasBorder?: boolean
}

type IProps = IPropsFromParent

const defaultProps = {
  isDark: false,
  hasBorder: true
}

const DetailsCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-1",
    "mb-2",
    "items-center",
    "flex-wrap",
    "justify-center",
    "lg:justify-between",
    "md:flex-nowrap"
  ]

  const borderClasses = [
    "border",
    "border-ch-gray-400",
    "rounded-md",
    "p-4",
  ]

  const backgroundColourClass = props.isDark ? "bg-ch-gray-300" : "bg-white"
  const propClasses = props.className ? props.className.split(" ") : []
  let allClasses = [...defaultClasses, backgroundColourClass, ...propClasses]
  if(props.hasBorder) {
    allClasses = [...allClasses, ...borderClasses]
  }


  return (
    <div className={allClasses.join(" ")}>
      { props.children }
    </div>
  )
}

DetailsCard.defaultProps = defaultProps

export default DetailsCard
