import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const caseDicomLinkNewValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(100, i18n.t("shared:maxLengthExceeded", { maxLength: 100 }))
  })
}
