import React, { useEffect, useState } from "react"
import { getChatById } from "../../../../shared/selectors/message"
import { useSelector } from "react-redux"
import { IChat } from "../../state/chatTypes"
import { IAppState } from "../../../../app/appTypes"
import { getCurrentUser } from "../../../../shared/selectors/user"
import ChatUserName from "../shared/ChatUserName"

interface Props {
  chatId: string
}

const ChatTitleUserList: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const currentUser = useSelector(getCurrentUser)
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const _filteredUsers = chat.users.filter((userId) => userId != currentUser.id)
    setFilteredUsers(_filteredUsers)
  }, [chat.users])

   return (
    <span>
      {filteredUsers.map((userId: string, index: number) => {
        return (
          <span key={`${index}-${userId}`}>
            {index > 0 && ", "}
            <ChatUserName userId={userId} />
          </span>
        )
      })}
    </span>
  )
}

export default ChatTitleUserList
