import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import { IconAccept } from "../../../svg/icons"
import { Field } from "formik"
import classNames from "classnames"

interface IProps {
  className?: string
  variant?: string
  name: string
  value?: boolean
}

const defaultProps = {
  variant: StyleVariants.BLUE
}

const CheckmarkToggle: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div className="py-2">
      <div
        className={classNames(
          "cursor-pointer",
          `hover:text-${props.variant}-600`,
          {
            "text-ch-gray-500": !props.value,
            [`text-${props.variant}-500`]: props.value
          }
        )}
      >
        <IconAccept className="fill-current w-6 h-6" />
      </div>

      <div className="hidden">
        <Field type="checkbox" name={props.name} id={props.name} />
      </div>
    </div>
  )
}

CheckmarkToggle.defaultProps = defaultProps

export default CheckmarkToggle
