import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconNotification } from "../../../../svg/icons"
import NotificationDetails from "../../../notification/components/details/NotificationDetails"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { getNotificationById } from "../../../../shared/selectors/notification"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { markNotificationAsRead, markNotificationAsUnread } from "../../state/notificationsActions"
import history from "../../../../app/routes/history"

interface IProps {
  store?: MockStore
  notificationId: string
  refreshNotifications: () => void
}

const NotificationListItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "notification"])
  const notification = useSelector((state: IAppState) => getNotificationById(state, props))
  const dispatch = useDispatch()
  const markAsRead = (notificationId: string) => dispatch(markNotificationAsRead(notificationId))
  const markAsUnread = (notificationId: string) => dispatch(markNotificationAsUnread(notificationId))
  const parent = useRef(null)

  if (!notification) return null

  const onView = () => {
    markAsRead(props.notificationId)
    history.push(notification.url)
  }

  return (
    <li ref={parent}>
      <DetailsCard isDark={!!notification.readAt}>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconNotification className="fill-current w-6 h-6" title={t("shared:notification")} />
          </IconCircleContainer>
          <NotificationDetails notificationId={notification.id} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            notification.readAt ?
              <Button
                action={() => {
                  markAsUnread(props.notificationId)
                  setTimeout(() => {
                    props.refreshNotifications()
                  }, 100)
                }}
                variant={StyleVariants.GRAY}
                key="1"
              >
                {t("notification:markUnread")}
              </Button>

              : (
                <Button
                  action={() => {
                    markAsRead(props.notificationId)
                    setTimeout(() => {
                      props.refreshNotifications()
                    }, 100)
                  }}
                  variant={StyleVariants.BLUE_ALT}
                  key="1"
                >
                  {t("notification:markRead")}
                </Button>
              )
            ,
            <Button
              action={() => { onView() }}
              variant={StyleVariants.BLUE}
              key="1"
            >
              {t("shared:viewDetails")}
            </Button>
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default NotificationListItem
