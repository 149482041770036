import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getPatientById } from "../../../../shared/selectors/patients"
import PatientCaseListItem from "./PatientCaseListItem"

interface IPropsFromParent {
  patientId: string
  isDark?: boolean
}

type Props = IPropsFromParent

const PatientCaseList = (props: Props): JSX.Element => {
  const patient = useSelector((state: IAppState) => getPatientById(state, props))

  if (!patient?.cases.length) return null


  return (
    <ul>
      {patient.cases.map((caseId: string) => {
        return (
          <PatientCaseListItem
            key={caseId}
            caseId={caseId}
            isDark={props.isDark}
          />
        )
      })}
    </ul>
  )
}

export default PatientCaseList
