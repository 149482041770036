/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useField } from "formik"
import { IAppState } from "../../../../app/appTypes"
import SvgIconTick from "../../../../svg/icons/IconTick"
import MentionField from "../../../../shared/components/forms/MentionField"
import { getMeetingUsers } from "../../../../shared/selectors/meeting"

interface IProps {
  meetingId: string
  serverText: string
  handleChange: (e: React.ChangeEvent<Element>) => void
  handleBlur: (e: React.FocusEvent<Element>) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleUpdateRecommendation: (text: string) => void
}

const RecommendationInputField: React.FC<IProps> = (props: IProps) => {
  const [isDirty, setIsDirty] = useState(false)
  const [field] = useField("recommendation")
  const currentRecommendationBlank = props.serverText == "" || props.serverText == undefined
  const mentionableUsers = useSelector((state: IAppState) => getMeetingUsers(state, props))

  useEffect(() => {
    if (field.value == props.serverText) {
      setIsDirty(false)
    } else {
      setIsDirty(true)
    }
  }, [field.value, props.serverText])

  const renderCheckMarkIcon = (): JSX.Element => {
    if (isDirty || currentRecommendationBlank) return null
    return (
      <div className="absolute right-2 bottom-2 p-2 bg-ch-green-300 z-10 rounded-full">
        <SvgIconTick className="text-white w-3 h-3" />
      </div>
    )
  }

  return (
    <div className="mb-2 relative">
      <MentionField
        maxLength={5000}
        height={140}
        name="recommendation"
        onChange={(_e, newValue) => {
          setIsDirty(true)
          props.setFieldValue("recommendation", newValue)
          props.handleUpdateRecommendation(newValue)
        }}
        onBlur={props.handleBlur}
        users={mentionableUsers}
        value={field.value == undefined ? props.serverText : field.value}
        setValue={(body: string) => props.setFieldValue("body", body)}
      />
      {renderCheckMarkIcon()}
    </div >
  )
}

export default RecommendationInputField
