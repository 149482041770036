import React from "react"
import LiveMeetingUserListItem from "./LiveMeetingUserListItem"

interface IPropsFromParent {
  userIds: string[]
}

type IProps = IPropsFromParent

const LiveMeetingUserList: React.FC<IProps> = ({ userIds }: IProps): JSX.Element => {
  if (!userIds.length) return <span>No users</span>
  return (
    <ul>
      {
        userIds.map((userId: string) => <LiveMeetingUserListItem key={userId} userId={userId} />)
      }
    </ul>
  )
}

export default LiveMeetingUserList
