import React from "react"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import { IMeetingUser } from "../../../meetingUser/state/meetingUserTypes"
import { getMeetingUserByMeetingUserId } from "../../../../shared/selectors/meeting"
import QuickViewCard from "../../../../shared/components/ui/quickView/QuickViewCard"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"
import { MeetingUserAttendanceLabel } from "../../../meeting/components/users/MeetingUserAttendanceLabel"

interface IPropsFromParent {
  store?: MockStore
  meetingUserId: string
  meetingEnded: boolean
}

const MeetingUserListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const meetingUser = useSelector((state: IAppState): IMeetingUser => getMeetingUserByMeetingUserId(state, props))
  const user = useSelector((state: IAppState): IUser => getUserByHasUserId(state, meetingUser))
  const meetingUserAttendanceLabel = props.meetingEnded ? <MeetingUserAttendanceLabel meetingJoinedCount={meetingUser.joinedCount} /> : null

  return (
    <li>
      <QuickViewCard>
        <UserDetailsCard user={user} label={meetingUserAttendanceLabel} />
      </QuickViewCard>
    </li>
  )
}

export default MeetingUserListItem
