import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getCaseById } from "../../../../shared/selectors/case"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconFolder } from "../../../../svg/icons"
import PatientCaseDetails from "./PatientCaseDetails"

interface IProps {
  caseId: string
}

const PatientCaseListItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "patient"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  if (!theCase) return null

  const renderViewDetailsButton = (): JSX.Element => {
    if (theCase.permissions?.canRead) {
      const caseDetailsUrl = generatePath(RoutePaths.CASE, {
        caseId: props.caseId
      })

      return (
        <Button
          href={caseDetailsUrl}
          variant={StyleVariants.BLUE}
          key={`button-${props.caseId}`}
        >
          {t("patient:cases:buttons:viewCase")}
        </Button>
      )
    }
    else return (
      <span className="font-thin text-ch-gray-500">
        {t("case:noAccess")}
      </span>
    )
  }

  return (
    <DetailsCard>
      <DetailsCardInner>
        <IconCircleContainer className="sm:mr-4">
          <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
        </IconCircleContainer>
        <PatientCaseDetails caseId={props.caseId} />
      </DetailsCardInner>

      <DetailsCardRight>
        <DetailsCardButtons buttons={[
          renderViewDetailsButton()
        ]} />
      </DetailsCardRight>
    </DetailsCard>
  )
}

export default PatientCaseListItem
