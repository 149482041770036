import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { CasePathwaysActionTypes, ICasePathway } from "../casePathwayTypes"
import { TasksActionTypes } from "../../../tasks/state/tasksTypes"

export type TByIdState = { [id: string]: ICasePathway }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_SUCCESS:
    case CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_SUCCESS:
    case CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.casePathways || []
      }

    case TasksActionTypes.CREATE_CASE_TASK_ASYNC_SUCCESS:
      if (_.isUndefined(action.payload.casePathwayId)) return { ...state }
      return {
        ...state,
        [action.payload.casePathwayId]: {
          ...state[action.payload.casePathwayId],
          tasks: [
            ...state[action.payload.casePathwayId].tasks || [],
            (Object.keys(action.payload.all.entities.tasks)[0] || {}),
          ]
        }
      }

    case TasksActionTypes.DELETE_CASE_TASK_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.casePathwayId]: {
          ...state[action.payload.casePathwayId],
          tasks: _.without(state[action.payload.casePathwayId].tasks, action.payload.taskId)
        }
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    default:
      return state
  }
}

export const casePathwaysEntityReducer: Reducer<INormalizedEntities<ICasePathway>> = combineReducers({
  byId,
  allIds
})
