import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import { PendingLabel } from "../label/PendingLabel"
import { GuestLabel } from "../label/GuestLabel"

interface IProps {
  firstName: string
  lastName: string
  title?: string
  gender?: string
  professionalTitle?: string
  className?: string
  disabled?: boolean
  label?: ReactNode
  isPending?: boolean
  isGuest?: boolean
}

const NameUser: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "user", "team"])

  const renderTitle = (): string => {
    if (!props.title) return null
    const title = t(`shared:${props.title}`)
    return `(${title})`
  }

  const renderGender = (): string => {
    if (!props.gender) return null
    const translationKey = humps.camelize(props.gender)
    return (t(`shared:${translationKey}`))
  }

  const renderProfessionalTitle = (): string => {
    if (!props.professionalTitle) return null
    const professionalTitleText = t(`shared:${props.professionalTitle}`)
    return (` - ${professionalTitleText}`)
  }

  const renderDisabled = (): string => {
    if (!props.disabled) return null
    return `(${t("user:inactive")})`
  }

  const renderContent = (): JSX.Element => {
    return (
      <span className={props.className}>
        <span>
          <span className="uppercase">{props.lastName}</span>, {props.firstName}
        </span>
        {
          [renderTitle(), renderGender(), renderProfessionalTitle(), renderDisabled()]
            .filter((el) => el != null)
            .map((el, i) => (<span key={i} > {el}</span>))
        }
        {props.label}
        <PendingLabel isPending={props.isPending} />
        <GuestLabel isGuest={props.isGuest} />
      </span>
    )
  }

  return renderContent()
}

export default NameUser
