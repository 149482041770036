import React, { useContext } from "react"
import { IPatientEditData } from "../../state/patientEditTypes"
import { IPatient } from "../../../patient/state/patientTypes"
import { useTranslation } from "react-i18next"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"

interface IPropsFromParent {
  data: IPatientEditData
  patient: IPatient
  attributeName: string
  type: "original" | "amended"
}

type Props = IPropsFromParent

const AmendmentText: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  const attributeNameText = ():string => {
    if(props.attributeName == "healthcareId") return healthcareIdLabel
    if(props.attributeName == "careProviderId") return careProviderIdLabel
    return t(`patient:details:${props.attributeName}`)
  }

  const renderValue = (): string => {
    if(props.type == "original") return props.patient[props.attributeName]
    if(props.type == "amended") return props.data[props.attributeName]
  }

  return (
    <span>
      { `${attributeNameText()} (${t(`patient:reasonForChange:${props.type}`)}): ${renderValue()}` }
    </span>
  )
}

export default AmendmentText
