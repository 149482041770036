const TOKEN_KEY_NAME = "session_token"
const EMAIL_KEY_NAME = "session_key"
const ID_KEY_NAME = "session_user_id"

export interface IUserSessionToken {
  userSession: {
    id: string,
    apiToken: string
  }
}
export const loadSession = (): IUserSessionToken | Partial<IUserSessionToken> => {
  try {
    const session = getSession()
    if (!session.token || !session.userId) {
      return {}
    }
    return {
      userSession: {
        id: session.userId,
        apiToken: session.token
      }
    }
  } catch (err) {
    console.error("there was an issue with the session", err)
    return {}
  }
}

export const setSession = (token: string, id: string, email: string): void => {
  setItem(TOKEN_KEY_NAME, token)
  setItem(EMAIL_KEY_NAME, email)
  setItem(ID_KEY_NAME, id)
}

export const getSession = () => {
  return {
    token: getItem(TOKEN_KEY_NAME),
    email: getItem(EMAIL_KEY_NAME),
    userId: getItem(ID_KEY_NAME)
  }
}

export const getToken = (): string => {
  return getItem(TOKEN_KEY_NAME)
}

export const getApiToken = (): string => {
  return localStorage.getItem(TOKEN_KEY_NAME)
}

export const clearSession = (): void => {
  localStorage.removeItem(TOKEN_KEY_NAME)
  localStorage.removeItem(EMAIL_KEY_NAME)
  localStorage.removeItem(ID_KEY_NAME)
}

const setItem = (key, value) => localStorage.setItem(key, value)
const getItem = (key) => localStorage.getItem(key)

