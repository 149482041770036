import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { IAppState } from "../../app/appTypes"
import TeamHeader from "../../features/team/components/header/TeamHeader"
import MdtTeamUsers from "../../features/team/components/users/MdtTeamUsers"
import TeamUsers from "../../features/team/components/users/TeamUsers"
import { fetchTeamAsync } from "../../features/team/state/teamActions"
import { IHaveTeamId, TeamTypes } from "../../features/team/state/teamTypes"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { getTeamById } from "../../shared/selectors/team"

const TeamPage: React.FC = (): JSX.Element => {
  const { teamId } = useParams<IHaveTeamId>()
  const { t } = useTranslation(["shared", "team"])
  const team = useSelector((state: IAppState) => getTeamById(state, teamId))
  const dispatch = useDispatch()
  const fetchTeam = (teamId: string) => dispatch(fetchTeamAsync(teamId))
  const fetchUsers = () => dispatch(fetchAllUsersAsync())

  useEffect(() => {
    fetchTeam(teamId)
    fetchUsers()
  }, [])

  if (!team) return <h3 className="text-lg">{t("team:teamNotFound")}</h3>

  return (
    <div>
      <TeamHeader teamId={teamId} />
      {team.teamType == TeamTypes.mdt ? <MdtTeamUsers teamId={teamId} /> : <TeamUsers teamId={teamId} />}
    </div>
  )
}

export default TeamPage
