import React from "react"
import { useTranslation } from "react-i18next"
import ListBackground from "../../../../shared/components/layout/ListBackground"

interface IProps {
  messageKey: string
}

export const AddCaseAssetPanelDisabled: React.FC<IProps> = ({ messageKey }: IProps) => {
  const { t } = useTranslation(["shared", "asset"])

  return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t(messageKey)}
      </h3>
      <ListBackground />
    </div>
  )
}
