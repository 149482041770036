import { takeLatest, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { CaseSharedAssetsActionTypes, ICreateCaseSharedAssetAsyncAction } from "../caseSharedAssetsTypes"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"

export function* createCaseSharedAssetAsync(action: ICreateCaseSharedAssetAsyncAction): Generator {
  try {
    const caseId = action.payload.caseId
    const caseAssetId = action.payload.caseAssetId
    const userId = action.payload.userId

    const data = {
      userSharedAsset: {
        caseAssetId,
        userId
      }
    }

    const response: any = yield call(API.post, `/cases/${caseId}/shared_assets`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId,
        userId: userId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createCaseSharedAssetSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createCaseSharedAssetFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateCaseSharedAssetAsync(): Generator {
  yield takeLatest(CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_PENDING, createCaseSharedAssetAsync)
}
