import _ from "lodash"
import { CaseTypes } from "../../features/case/state/caseTypes"
import { ICaseClassification } from "../../features/caseClassification/state/caseClassificationTypes"
import { ICaseSource } from "../../features/sources/state/caseSourceTypes"
import { ISpecialty } from "../../features/specialty/state/specialtyTypes"
import { ITeam } from "../../features/team/state/teamTypes"
import { TSelectFieldOptions } from "../components/forms/StandardSelectField"
import { ISelectFieldOption } from "../components/forms/types"

export type TGroupableOption = ICaseClassification | ISpecialty

export const groupOptions = (optionsById: { [id: string]: TGroupableOption }): TSelectFieldOptions => {
  const groupedClassificationOptions: TSelectFieldOptions = []
  Object.values(optionsById).forEach((option: TGroupableOption) => {
    if (option.parentId) return

    const parentOption: ISelectFieldOption = {
      value: option.id,
      label: option.name,
      options: [{
        value: option.id,
        label: option.name
      }]
    }

    if (option.children.length > 0) {
      const childOptions: TSelectFieldOptions = option.children.map((optionChildId: string) => {
        const optionChild: TGroupableOption = optionsById[optionChildId]
        return {
          value: optionChild.id,
          label: optionChild.name
        }
      })

      parentOption.options = [parentOption, ...childOptions]
    }

    groupedClassificationOptions.push(parentOption)
  })

  return groupedClassificationOptions
}

export const generateSpecialtyOptions = (specialties: { [id: string]: ISpecialty }): TSelectFieldOptions => {
  return groupOptions(specialties)
}

export const generateCaseClassificationOptions = (caseClassifications: { [id: string]: ICaseClassification }, caseType: string): TSelectFieldOptions => {
  // Suspected Cancer case type will show same classifications as Cancer
  const fitlerCaseType = (caseType == CaseTypes.SuspectedCancer) ? CaseTypes.Cancer : caseType
  const classifications = _.pickBy(caseClassifications, (caseClassification: ICaseClassification) => fitlerCaseType == caseClassification.caseType)
  return groupOptions(classifications)
}

export const generateCaseSourceOptions = (sources: { [id: string]: ICaseSource }): TSelectFieldOptions => {
  const groupedSourceOptions: TSelectFieldOptions = []
  const groupedSources = _.groupBy(sources, "regionalTeam")
  Object.entries(groupedSources).forEach(group => {
    const parentOption: ISelectFieldOption = {
      value: group[0],
      label: group[0],
      options: group[1].map((option) => {
        return {
          value: option.id,
          label: option.name
        }
      })
    }
    groupedSourceOptions.push(parentOption)
  })

  return groupedSourceOptions
}

export const generateTeamOptions = (teams: ITeam[]) => {
  return teams.map((team: ITeam) => {
    return {
      value: team.id,
      label: team.name
    }
  })
}
