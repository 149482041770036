import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { MockStore } from "redux-mock-store"
import OT from "@opentok/client"
import { publishScreenToWebRTCSessionAction, stopPublishScreenToWebRTCSessionAction, updateWebRTCScreenStreamAction } from "../state/webRTCActions"
import { publisherEventHandlers, webRTCEvent } from "../state/webRTCTypes"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { toggleIsSharingScreenAction } from "../../liveMeeting/state/liveMeetingActions"
import { getWebRTCScreenPublisher, getWebRTCScreenShareIsOn, getWebRTCSession } from "../../../shared/selectors/webRTC"
import { useSelector } from "react-redux"

interface IProps {
  store?: MockStore,
  publisherOptions: OT.PublisherProperties
}

const WebRTCScreenPublisher: React.FC<IProps> = (props: IProps): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const screenPublisher = useSelector(getWebRTCScreenPublisher)
  const session = useSelector(getWebRTCSession)
  const isScreenShareOn = useSelector(getWebRTCScreenShareIsOn)
  const connection = screenPublisher?.session.connection
  const displayUserMessage = (messageKey: string, type: string) => {
    dispatch(displayUserMessageAction({ messageKey,
type }))
  }

  const eventHandlers: publisherEventHandlers = {
    streamCreated: (event: webRTCEvent) => {
      const userId = getStreamUserId(event.stream)
      dispatch(updateWebRTCScreenStreamAction(event.stream))
      dispatch(toggleIsSharingScreenAction(userId, true))
    },
    streamDestroyed: (event: webRTCEvent) => {
      const userId = getStreamUserId(event.stream)
      dispatch(updateWebRTCScreenStreamAction(null))
      dispatch(toggleIsSharingScreenAction(userId, false))
    },
    accessDenied: (_event: webRTCEvent) => {
      displayUserMessage("webRTCAccessDenied", UserMessageTypes.ERROR)
    }
  }

  useEffect(() => {
    // Publish screen to session after connection has been established
    // and if screen share is turned on by user
    if (!session?.connection) return
    if (isScreenShareOn && !screenPublisher) {
      dispatch(publishScreenToWebRTCSessionAction(session, containerRef, props.publisherOptions, eventHandlers))
    } else if (!isScreenShareOn && screenPublisher) {
      dispatch(stopPublishScreenToWebRTCSessionAction(session, screenPublisher))
    }
  }, [session, isScreenShareOn])

  useEffect(() => {
    // If connection to session if destoryed unpublish screen
    // Streams will then in turn be destroyed
    if (!connection) return
    return () => {
      dispatch(stopPublishScreenToWebRTCSessionAction(session, screenPublisher))
    }
  }, [connection])

  return (
    <div ref={containerRef} className="hidden"></div>
  )
}

export default WebRTCScreenPublisher
