import React, { ReactNode, useEffect } from "react"
import { useDispatch } from "react-redux"
import i18next from "i18next"
import { IAppState } from "./../../../app/appTypes"
import { fetchUserConfigAsync } from "./../state/userConfigActions"
import { UserConfigContext } from "./UserConfigContext"
import { useSelector } from "react-redux"

interface IProps {
  children?: ReactNode
}

const UserConfigProvider: React.FC<IProps> = (props: IProps) => {
  const userConfig = useSelector((state: IAppState) => state.userConfig)
  const userId = useSelector((state: IAppState) => state.userSession.id)
  const dispatch = useDispatch()
  const fetchUserConfig = (userId: string) => { dispatch(fetchUserConfigAsync(userId)) }

  useEffect(() => {
    fetchUserConfig(userId)
  }, [userId])

  useEffect(() => {
    i18next.changeLanguage(userConfig.locale)
  }, [userConfig.locale])

  return (
    <UserConfigContext.Provider value={userConfig}>
      {props.children}
    </UserConfigContext.Provider>
  )
}

export default UserConfigProvider
