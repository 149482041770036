import React from "react"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { IconFolder } from "../../../../svg/icons"
import { selectMeetingCaseAction } from "../../state/liveMeetingActions"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import MeetingCaseDetails from "../../../meetingCase/components/MeetingCaseDetails"
import { getMeetingCaseById } from "../../../../shared/selectors/meeting"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

interface IProps {
  meetingCaseId: string
  closeModal: () => void
}

const LiveMeetingCaseSelectItem: React.FC<IProps> = ({ closeModal, meetingCaseId }): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const dispatch = useDispatch()
  const onMeetingCaseSelected = (meetingCaseId: string) => dispatch(selectMeetingCaseAction(meetingCaseId))
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, { meetingCaseId }))

  const onClickSelectMeetingCase = (meetingCaseId: string): void => {
    closeModal()
    onMeetingCaseSelected(meetingCaseId)
  }

  return (
    <li className="flex flex-1 mb-2 bg-ch-gray-300 rounded-md p-4 justify-between">
      <IconCircleContainer className="mr-4">
        <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
      </IconCircleContainer>

      <div className="flex-1 mr-4">
        <MeetingCaseDetails meetingCaseId={meetingCaseId} />
      </div>

      <Button action={() => onClickSelectMeetingCase(meetingCase.id)} className="self-center self-end" variant={StyleVariants.BLUE} isChunky={true}>
        {t("shared:select")}
      </Button>
    </li>
  )
}

export default LiveMeetingCaseSelectItem
