import React from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import CasePathwayHeader from "../../../../casePathways/components/CasePathwayHeader"
import { ICasePathwayTaskUpdateData, ITask } from "../../../../tasks/state/tasksTypes"
import { useTranslation } from "react-i18next"
import { getTaskById } from "../../../../../shared/selectors/task"
import EditCasePathwayTaskForm from "./EditCasePathwayTaskForm"
import { updateCasePathwayTaskAction } from "../../../../casePathways/state/casePathwayActions"

interface IProps {
  taskId: string
  casePathwayId: string
  isOpen: boolean
  closeModal: () => void
}

const EditCasePathwayTaskModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const task: ITask = useSelector((state: IAppState) => getTaskById(state, props))
  if (!task) return null

  const dispatch = useDispatch()

  const { t } = useTranslation("pathway")

  const submitHandler = (taskData: ICasePathwayTaskUpdateData) => {
    dispatch(updateCasePathwayTaskAction(task.caseId, props.taskId, taskData))
    props.closeModal()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={defaultModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("task:editTaskHeader")}:
        </h2>
        <div>
          <CasePathwayHeader casePathwayId={props.casePathwayId} />
        </div>
        <EditCasePathwayTaskForm taskId={props.taskId} cancelHandler={props.closeModal} submitHandler={submitHandler} />
      </ModalContainer>
    </Modal>
  )
}

export default EditCasePathwayTaskModal
