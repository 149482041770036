import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import NameUser from "../../../../shared/components/names/NameUser"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById, getLeadCareUserByCaseId } from "../../../../shared/selectors/case"
import TextCaseType from "../../../../shared/components/text/TextCaseType"
import TargetDate from "../../../case/components/details/TargetDate"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"

interface IProps {
  caseId: string
}

const PatientCaseDetails: React.FC<IProps> = (props): JSX.Element => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const leadCareUser = useSelector((state: IAppState) => getLeadCareUserByCaseId(state, props))
  if (!theCase) return null

  const { t } = useTranslation(["shared", "case"])

  const renderCaseType = (): JSX.Element => {
    return (
      <li>
        {t("case:type")}: <TextCaseType caseType={theCase.caseType} />
      </li>
    )
  }

  const renderLeadCare = (): JSX.Element => {
    if (!leadCareUser) return (
      <li>
        {t("case:leadCare")}: -
      </li>
    )
    return (
      <li>
        {t("case:leadCare")}: <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          professionalTitle={leadCareUser.professionalTitle}
          isPending={leadCareUser.pending}
        />
      </li>
    )
  }

  const renderClassification = (): JSX.Element => {
    return (
      <li>
        {t("shared:classification")}: {theCase.classification}
      </li>
    )
  }
  const renderSourceName = (): JSX.Element => {
    return (
      <li>
        {t("case:source")}: {theCase.sourceName}
      </li>
    )
  }

  const renderCreatedBy = (): JSX.Element => {
    return (
      <li>
        {t("shared:createdBy")}: <NameUser
          firstName={theCase.createdBy.firstName}
          lastName={theCase.createdBy.lastName}
        />
      </li>
    )
  }

  const renderCreatedAt = (): JSX.Element => {
    return (
      <li>
        {t("shared:createdAt")}: <TimeDefaultFormat utcTime={theCase.createdAt} />
      </li>
    )
  }
  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        {renderLeadCare()}
        {renderClassification()}
        {renderSourceName()}
      </ul>

      <ul className="col-span-1">
        {renderCaseType()}
        {renderCreatedBy()}
        <TargetDate caseId={props.caseId} />
        {renderCreatedAt()}
      </ul>
    </div>
  )
}

export default PatientCaseDetails
