import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* fetchPathwayAsync(action: Effect): Generator {
  try {
    const pathwayId = action.payload

    const response: any = yield call(API.get, `/pathways/${pathwayId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({
      type: PathwayActionTypes.FETCH_PATHWAY_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: PathwayActionTypes.FETCH_PATHWAY_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "pathwayNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchPathwayAsync(): Generator {
  yield takeLeading(PathwayActionTypes.FETCH_PATHWAY_ASYNC_PENDING, fetchPathwayAsync)
}

