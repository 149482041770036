import React from "react"

interface IProps {
  className?: string
}

const LoadingSpinnerSmall: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div className="flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: "block",
          margin: "auto",
        }}
        width="15px"
        height="15px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className={props.className || "text-ch-blue-500"}
      >
        <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
    </div>
  )
}

export default LoadingSpinnerSmall
