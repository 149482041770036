import React from "react"
import { useSelector } from "react-redux"
import NameUser from "../../../../shared/components/names/NameUser"
import { IAppState } from "../../../../app/appTypes"
import LiveMeetingUserOnlineStatus from "./LiveMeetingUserOnlineStatus"
import LiveMeetingUserRoleControls from "./LiveMeetingUserRoleControls"
import TextCoordinatorUser from "../../../../shared/components/text/TextCoordinatorUser"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import LiveMeetingUserMicrophoneStatus from "./LiveMeetingUserMicrophoneStatus"
import LiveMeetingUserCameraStatus from "./LiveMeetingUserCameraStatus"

interface IProps {
  userId: string;
}

const LiveMeetingUserListItem: React.FC<IProps> = (props): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, props))

  return (
    <li className="bg-ch-gray-200 mb-1 p-2 rounded-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <LiveMeetingUserOnlineStatus userId={props.userId} />
          <h3 className="mr-1">
            <NameUser
              firstName={user.firstName}
              lastName={user.lastName}
              professionalTitle={user.professionalTitle}
            />
          </h3>
          <h4 className="text-sm font-thin uppercase">
            <TextCoordinatorUser userId={props.userId} />
          </h4>
          <LiveMeetingUserMicrophoneStatus userId={props.userId} />
          <LiveMeetingUserCameraStatus userId={props.userId} />
        </div>
        <LiveMeetingUserRoleControls userId={props.userId} />
      </div>
    </li>
  )
}

export default LiveMeetingUserListItem
