import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { AssetsActionTypes, IUploadCaseFileFormValues } from "../assetsTypes"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { fetchMeetingCaseAction } from "../../../meetingCase/state/meetingCaseActions"

const valuesToFormData = (values: IUploadCaseFileFormValues) => {
  const data = new FormData()
  // we need to manually ensure the case is correct, as the FormData object doesn't get
  // de-camelized automatically
  data.append("document[file]", values.file)
  data.append("document[name]", values.name)
  if (values.meetingCaseId) {
    data.append("document[meeting_case_id]", values.meetingCaseId)
  }
  return data
}

export function* uploadFileAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const data = valuesToFormData(action.payload.values)
    const config = withAuthHeader()
    // the upload can't be done with JSON, it needs this content type.
    config.headers["Content-Type"] = "multipart/form-data"

    yield put(displayUserMessageAction({
      messageKey: "fileUploadStarted",
      type: UserMessageTypes.MESSAGE
    }))

    const response: any = yield call(API.post, `/cases/${caseId}/documents`, data, config)
    const normalizedResponse = normalize(response.data, assetsSchema)

    yield put({
      type: AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

    yield put(displayUserMessageAction({
      messageKey: "fileUploadSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "fileUploadError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUploadFileAsync(): Generator {
  yield takeLeading(AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_PENDING, uploadFileAsync)
}
