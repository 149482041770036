import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IAuditEvent } from "../../audit/state/auditEventTypes"

// Models
export interface IPatient {
  id: string
  firstName: string
  lastName: string
  title: string
  gender: string
  dateOfBirth: string
  healthcareId: string
  careProviderId: string
  casesCount?: number
  furtherDetails?: IPatientFurtherDetails
  patientAmendments?: IPatientAmendment[]
  permissions: IPatientPermissions
  createdBy: ICreatedBy
  createdAt: string
  cases?: string[]
}

export interface IPatientFurtherDetails {
  id: string
  postcode: string
  addressLine1: string
  addressLine2: string
  city: string
  county: string
  country: string
  email: string
  phoneNumber: string
  phoneNumberAlt: string
  gpPostcode: string
  gpPracticeName: string
  gpAddressLine1: string
  gpAddressLine2: string
  gpCity: string
  gpCounty: string
  gpCountry: string
  gpEmail: string
  gpPhoneNumber: string
  gpName: string
}

export interface IPatientPermissions {
  canEdit: boolean
  canManage: boolean
  canAddCase: boolean
  canTrackChanges: boolean
}

export enum AmendmentTypes {
  ErrorCorrection = "error_correction",
  RecordUpdate = "record_update",
}

export interface IPatientAmendment {
  reason: string
  amendmentType: AmendmentTypes
  auditEvent: IAuditEvent
}

// State
export interface IHavePatientId {
  patientId: string
}

export interface IHavePatientIdParam {
  match?: {
    params: {
      patientId: string
    }
  }
}

export enum PatientTitles {
  Mr = "mr",
  Mrs = "mrs",
  Ms = "ms",
  Miss = "miss",
  Mx = "mx",
  Dr = "dr",
  Prof= "prof"
}

export enum PatientGenders {
  Unknown = "unknown",
  Unspecified = "unspecified",
  Male = "male",
  Female = "female",
  OtherSpecific = "other_specific"
}

// Actions
export interface IFetchPatientAsyncAction {
  type: string,
  payload: string
}

export enum PatientActionTypes {
  FETCH_PATIENT_ASYNC_PENDING = "@@case/FETCH_PATIENT_ASYNC_PENDING",
  FETCH_PATIENT_ASYNC_SUCCESS = "@@case/FETCH_PATIENT_ASYNC_SUCCESS",
  FETCH_PATIENT_ASYNC_ERROR = "@@case/FETCH_PATIENT_ASYNC_ERROR",
}
