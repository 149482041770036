import { ICreateTenantAsyncAction, ITenantNewData, TenantActionTypes } from "./tenantTypes"

export const createTenantAsync = (tenant: ITenantNewData): ICreateTenantAsyncAction => {
  return {
    type: TenantActionTypes.CREATE_TENANT_ASYNC_PENDING,
    payload: {
      tenant: tenant
    }
  }
}
