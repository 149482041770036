// Models
export interface ITenantNewData {
  name: string
}

// Actions
export interface ICreateTenantAsyncAction {
  type: string,
  payload: {
    tenant: ITenantNewData
  }
}

export enum TenantActionTypes {
  CREATE_TENANT_ASYNC_PENDING = "@@tenant/CREATE_TENANT_ASYNC_PENDING",
  CREATE_TENANT_ASYNC_SUCCESS = "@@tenant/CREATE_TENANT_ASYNC_SUCCESS",
  CREATE_TENANT_ASYNC_ERROR = "@@tenant/CREATE_TENANT_ASYNC_ERROR",
}
