import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { MeetingActionTypes } from "./../../../meeting/state/meetingTypes"
import { MeetingsActionTypes } from "../../../meetings/state/meetingsActionTypes"
import { CasesActionTypes } from "../../../cases/state/casesActionTypes"
import { CaseActionTypes, ICase } from "../caseTypes"
import { AssetsActionTypes } from "../../../assets/state/assetsTypes"
import { MeetingCaseActionTypes } from "../../../meetingCase/state/meetingCaseTypes"
import { AddCaseToMeetingActionTypes } from "../../../addCaseToMeeting/state/addCaseToMeetingTypes"
import { TasksActionTypes } from "../../../tasks/state/tasksTypes"
import { TaskResponseActionTypes } from "../../../taskResponse/state/taskResponseTypes"
import { PatientActionTypes } from "../../../patient/state/patientTypes"
import { ChatsActionTypes } from "../../../chats/state/chatsTypes"
import { PatientsActionTypes } from "../../../patients/state/patientsTypes"
import { CasePathwaysActionTypes } from "../../../casePathways/state/casePathwayTypes"

export type TByIdState = { [id: string]: ICase }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const latestTaskResponseAssetId = (payload: any): number[] => {
  const allTaskResponses = payload.all.entities.taskResponses
  const allTasks = payload.all.entities.tasks
  const task = allTasks[payload.taskId]
  const latestTaskResponseId = task.taskResponses[0]
  const latestTaskResponse = allTaskResponses[latestTaskResponseId]
  return latestTaskResponse.asset ? [latestTaskResponse.asset] : []
}

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {

    case CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
    case AddCaseToMeetingActionTypes.FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_SUCCESS:
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.all.entities.cases)
      )

    case CaseActionTypes.FETCH_CASE_ASYNC_SUCCESS: {
      const newState = _.merge({}, state, action.payload.entities.cases)
      return {
        ...newState,
        [action.payload.result]: {
          ...newState[action.payload.result],
          members: action.payload.entities.cases[action.payload.result].members
        }
      }
    }

    case CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_SUCCESS:
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.entities.cases)
      )


    case AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_SUCCESS:
      // This is similar to the CREATE_CASE_COMMENT and _RECOMMENDATION, but
      // when this result is empty, we need to set it, as is is always correct,
      // even when empty

      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          assets: action.payload.all.result
        }
      }

    case AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_RECOMMENDATION_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          assets: action.payload.all.result
        }
      }

    case AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          assets: action.payload.all.result
        }
      }

    case AssetsActionTypes.UPLOAD_CASE_FILE_ASYNC_SUCCESS:
    case AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          assets: [
            action.payload.all.result,
            ...state[action.payload.caseId].assets || []
          ]
        }
      }

    case TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          outstandingTaskCount: action.payload.all.entities.tasks[action.payload.taskId].case.outstandingTaskCount,
          assets: [
            ...latestTaskResponseAssetId(action.payload),
            ...state[action.payload.caseId].assets || [],
          ]
        }
      }

    case TasksActionTypes.FETCH_CASE_TASKS_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          tasks: action.payload.all.result
        }
      }

    case TasksActionTypes.CREATE_CASE_TASK_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          outstandingTaskCount: action.payload.all.entities.tasks[action.payload.all.result].case.outstandingTaskCount,
          tasks: [
            action.payload.all.result,
            ...state[action.payload.caseId].tasks || []
          ]
        }
      }

    case TasksActionTypes.DELETE_CASE_TASK_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          tasks: action.payload.all.result
        }
      }

    case CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          casePathways: action.payload.all.result
        }
      }

    case CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.result)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          casePathways: [
            action.payload.all.result,
            ...state[action.payload.caseId].casePathways || []
          ],
          tasks: _.uniq(_.concat(
            (Object.keys(action.payload.all.entities.tasks || {})),
            state[action.payload.caseId].tasks || []
          ))
        }
      }

    case CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_SUCCESS:
    case CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          members: action.payload.all.entities.cases[action.payload.caseId].members
        }
      }

    case MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_SUCCESS:
    case PatientActionTypes.FETCH_PATIENT_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.entities.cases)
      )

    case MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_SUCCESS:
      if (_.isEmpty(action.payload.all.entities.cases)) return { ...state }
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          meetings: action.payload.all.entities.cases[action.payload.caseId].meetings
        }
      }

    case MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.caseId]: {
          ...state[action.payload.caseId],
          meetings: _.remove(state[action.payload.caseId].meetings, (meetingId: string) => {
            return meetingId !== action.payload.meetingId
          })
        }
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case CaseActionTypes.FETCH_CASE_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.result))

    case CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)

    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.cases || [])))

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.cases || [])))

    default:
      return state
  }
}

export const caseEntityReducer: Reducer<INormalizedEntities<ICase>> = combineReducers({
  byId,
  allIds
})
