import React, { ReactNode } from "react"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { CaseReportStatuses, ICaseReportAsset } from "../../state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IconPdf } from "../../../../svg/icons"
import { useTranslation } from "react-i18next"
import { bytesToSize } from "../../../../shared/helpers/fileHelpers"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import AssetSubHeader from "./AssetSubHeading"
import { useSelector } from "react-redux"
import LoadingSpinnerSmall from "../../../../shared/components/ui/LoadingSpinnerSmall"

interface Props {
  store?: MockStore
  assetId: string
  action?: () => void
  isSelected?: boolean
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
}

const CaseReport: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const caseReport = asset.asset as ICaseReportAsset

  const { t } = useTranslation(["asset", "shared"])

  const isClickable = props.action && caseReport.status == CaseReportStatuses.DONE

  const renderPendingLabel = (): JSX.Element => {
    if (caseReport.status == CaseReportStatuses.DONE) return null
    return (
      <div className="font-bold text-ch-blue-600 uppercase">
        {t("asset:generating")}
        <LoadingSpinnerSmall />
      </div>
    )
  }

  const renderTitle = (): JSX.Element => {
    if (caseReport.status == CaseReportStatuses.PENDING) return (
      <h4 className="flex-1 font-semibold text-lg">
        {caseReport.name}
      </h4>
    )

    return (
      <h4 className="flex-1 font-semibold text-lg">
        {`${caseReport.name} (${bytesToSize(caseReport.bytes)})`}
      </h4>
    )
  }

  return (
    <div
      onClick={isClickable ? () => props.action() : null}
      className={classNames(
        "bg-ch-green-200",
        "p-2",
        "flex",
        "rounded-md",
        "border-2",
        {
          "border-ch-blue-200": !props.isSelected,
          "border-ch-blue-500": props.isSelected,
          "hover:border-ch-blue-500": isClickable,
          "cursor-pointer": isClickable,
          "cursor-default": !isClickable,
        }
      )}
    >
      <div className="text-ch-blue-alt-400 mr-2 mt-1">
        <IconPdf className="w-5 h-5" title={caseReport.name} />
      </div>

      <div className="flex-1 min-w-0">
        <div className="flex">
          {renderTitle()}
          {renderPendingLabel()}
        </div>
        <AssetSubHeader
          meetingCaseAsset={props.meetingCaseAsset}
          meetingId={props.meetingId}
          asset={asset} />
      </div>

      {props.children}
    </div>
  )
}

export default CaseReport
