/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from "react"

export interface ICableContextState {
  apiToken: string
  cableCarRoute: any
}

export const cableContextInitialState: ICableContextState = {
  apiToken: null,
  cableCarRoute: {}
}

export const CableContext = createContext(cableContextInitialState)
