import { useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Label from "../../../../shared/components/forms/Label"
import InputField from "../../../../shared/components/forms/InputField"
import FieldError from "../../../../shared/components/forms/FieldError"
import { IPatientFurtherDetailsData } from "../../state/patientNewTypes"
import PostcodeLookup from "../../../../shared/components/forms/PostcodeLookup"

const PatientFields: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange
  } = useFormikContext<IPatientFurtherDetailsData>()

  return (
    <div>
      <PostcodeLookup
        handleChange={handleChange}
        fieldNames={{
          postcode: "postcode",
          addressLine1: "addressLine1",
          addressLine2: "addressLine2",
          city: "city",
          county: "county",
          country: "country",
        }}
      />

      <div className="mb-2">
        <Label name="addressLine1">
          {t("patient:details:furtherDetails:addressLine1")}
        </Label>
        <InputField
          type="text"
          name="addressLine1"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.addressLine1}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.addressLine1 as string} isVisible={(errors.addressLine1 && touched.addressLine1) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="addressLine2">
          {t("patient:details:furtherDetails:addressLine2")}
        </Label>
        <InputField
          type="text"
          name="addressLine2"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.addressLine2}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.addressLine2 as string} isVisible={(errors.addressLine2 && touched.addressLine2) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="city">
          {t("patient:details:furtherDetails:city")}
        </Label>
        <InputField
          type="text"
          name="city"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.city as string} isVisible={(errors.city && touched.city) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="county">
          {t("patient:details:furtherDetails:county")}
        </Label>
        <InputField
          type="text"
          name="county"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.county}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.county as string} isVisible={(errors.county && touched.county) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="country">
          {t("patient:details:furtherDetails:country")}
        </Label>
        <InputField
          type="text"
          name="country"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.country}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.country as string} isVisible={(errors.country && touched.country) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="email">
          {t("patient:details:furtherDetails:email")}
        </Label>
        <InputField
          type="email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.email as string} isVisible={(errors.email && touched.email) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="phoneNumber">
          {t("patient:details:furtherDetails:phoneNumber")}
        </Label>
        <InputField
          type="tel"
          name="phoneNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.phoneNumber as string} isVisible={(errors.phoneNumber && touched.phoneNumber) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="phoneNumberAlt">
          {t("patient:details:furtherDetails:phoneNumberAlt")}
        </Label>
        <InputField
          type="tel"
          name="phoneNumberAlt"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumberAlt}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.phoneNumberAlt as string} isVisible={(errors.phoneNumberAlt && touched.phoneNumberAlt) as boolean} />
      </div>
    </div>
  )
}

export default PatientFields
