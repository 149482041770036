import React, { useEffect, useRef } from "react"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { getComponentTypeForAsset, componentLookup, isAssetFiltered } from "../../../../shared/helpers/assetHelpers"
import { getSelectedMeetingCase } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import classNames from "classnames"
import { useSelector } from "react-redux"

interface IProps {
  caseId: string
  store?: MockStore
  assetId: string
  action?: () => void
  deleteAction?: (assetId: string) => void
  isSelected?: boolean
  isHovered?: boolean
  assetTypeFilters?: IAssetTypeFilters
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const AssetListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const meetingCase = useSelector(getSelectedMeetingCase)
  const ref = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (props.isSelected && ref.current) {
      ref.current.scrollIntoView(
        {
          behavior: "smooth",
          block: "start"
        }
      )
    }
  })

  if (!asset) return null

  const assetComponentType = getComponentTypeForAsset(asset)
  const Asset = componentLookup[assetComponentType]
  const action = (assetComponentType !== AssetComponentTypes.Link) ? props.action : undefined
  // we're hiding the component, not ruturning null, because this way the
  // image components don't need to reload their images.
  const hidden = isAssetFiltered(Asset, props.assetTypeFilters)
  return (
    <li className={classNames("mb-1", { hidden })} ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <Asset
        assetId={props.assetId}
        action={action}
        isSelected={props.isSelected || props.isHovered}
        caseId={meetingCase.case}
      />
    </li>
  )
}

export default AssetListItem
