import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconQuestion({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 46 46"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M25.95 2.138a4.176 4.176 0 00-5.906 0L1.724 20.462a4.17 4.17 0 000 5.901L20.05 44.691a4.169 4.169 0 005.9 0l18.327-18.328a4.17 4.17 0 000-5.9L25.95 2.137zm-9.998 15.745a.664.664 0 01-.675-.695c.267-4.767 4.168-6.43 7.734-6.43 3.927 0 7.515 2.054 7.515 6.3 0 3.038-1.786 4.484-3.498 5.787-2.073 1.572-2.841 2.16-2.841 4.18v.295a.703.703 0 01-.703.703h-2.278a.703.703 0 01-.703-.692l-.012-.61c-.107-2.608 1.392-4.214 3.285-5.59 1.66-1.248 2.714-2.07 2.714-3.856 0-2.32-1.766-3.285-3.695-3.285-2.259 0-3.524 1.345-3.775 3.19-.05.385-.36.703-.748.703h-2.32zm6.539 18.122c-1.643 0-2.838-1.108-2.838-2.607 0-1.553 1.195-2.644 2.84-2.644 1.714 0 2.892 1.091 2.892 2.644 0 1.499-1.181 2.607-2.894 2.607z"
      />
    </svg>
  );
}

export default SvgIconQuestion;
