import React from "react"
import { Form, Formik } from "formik"
import _ from "lodash"
import { formatISO } from "date-fns"
import { useContext, useState } from "react"
import { useDispatch } from "react-redux"
import { IMeetingFormData, IMeetingNewData } from "../../meeting/state/meetingTypes"
import { startEndDates } from "../../../shared/helpers/timeHelpers"
import { mdtMeetingNewValidationSchema, meetingNewValidationSchema } from "../state/meetingNewValidation"
import { UserConfigContext } from "./../../userConfig/contexts/UserConfigContext"
import Card from "../../../shared/components/layout/Card"
import { createMeetingAction } from "../../meeting/state/meetingActions"
import MeetingNewFormContent from "./MeetingNewFormContent"
import ToggleField from "../../../shared/components/forms/ToggleField"
import MdtMeetingNewFormContent from "./MdtMeetingNewFormContent"
import { useAppSelector } from "../../../shared/hooks"
import { getTenantConfig } from "../../../shared/selectors/tenantConfig"

const MeetingNewForm: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const createMeeting = (meeting: IMeetingNewData) => dispatch(createMeetingAction(meeting))
  const { mdtTeamsEnabled } = useAppSelector(getTenantConfig)
  const [_typeManuallySet, setTypeManuallySet] = useState(false)
  const { timezone } = useContext(UserConfigContext)
  const [isMdtMeeting, setIsMdtMeeting] = useState(false)

  const initialValues: IMeetingFormData = {
    name: "",
    mdtTeamId: "",
    specialtyId: "",
    coordinator: "",
    users: [""],
    startDate: undefined,
    startTime: "",
    endTime: "",
    teams: [],
    meetingType: null
  }

  // this is commented out now because it still is a bit in flux. For the moment
  // we have decided that we want to default to General meetings, so this logic
  // is not used, but I'm not convinced that is going to stay that way, so
  // that's why I have left it commented - Niels
  //
  // useEffect(() => {
  //   if (Object.values(teams).length == 0) return
  //   const mdtTeams: ITeam[] = Object.values(teams).filter((team) => team.teamType == TeamTypes.mdt) || []
  //   if (!typeManuallySet && mdtTeamsEnabled && mdtTeams && mdtTeams.length > 0) {
  //     setIsMdtMeeting(true)
  //   }
  // }, [teamIds])


  const handlerA = () => {
    setIsMdtMeeting(false)
    setTypeManuallySet(true)
  }

  const handlerB = () => {
    setIsMdtMeeting(true)
    setTypeManuallySet(true)
  }


  return (
    <Card className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={isMdtMeeting ? mdtMeetingNewValidationSchema(timezone) : meetingNewValidationSchema(timezone)}
        onSubmit={(values, { setSubmitting }) => {
          const dates = startEndDates(values.startDate, values.startTime, values.endTime, timezone)
          values = meetingNewValidationSchema(timezone).cast(values)
          const data = {
            name: values.name,
            specialtyId: values.specialtyId,
            startDate: formatISO(dates.start),
            endDate: formatISO(dates.end),
            coordinatorId: values.coordinator,
            userIds: values.users,
            teamIds: values.teams,
            mdtTeamId: values.mdtTeamId,
            meetingType: values.meetingType,
          } as IMeetingNewData
          createMeeting(data)
          setSubmitting(false)
        }}
      >
        <Form className="w-full lg:w-2/3">
          {mdtTeamsEnabled ?
            <ToggleField
              handlerA={handlerA}
              handlerB={handlerB}
              translationNamespace="meeting"
              AActive={!isMdtMeeting}
              textAKey="General Meeting"
              textBKey="MDT Meeting"
            />
            : null}
          {
            isMdtMeeting ?
              <MdtMeetingNewFormContent /> :
              <MeetingNewFormContent />
          }
        </Form>
      </Formik>
    </Card>
  )
}

export default MeetingNewForm
