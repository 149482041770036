/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react"
import { FormContextProvider } from "../../../contexts/FormContextProvider"
import WizardFooter from "./WizardFooter"
import WizardHeader from "./WizardHeader"

export interface IWizardStepComponentProps {
  goToNextStep: () => void
  data: any
  setData: (data: any) => void
}

export interface IWizardStep {
  name: string
  icon: React.FC<any>
  component: React.FC<IWizardStepComponentProps>
  nextButtonText?: string
  previousButtonText?: string
  hasSkip?: boolean
  updateRequiredBeforeNext?: boolean
}

interface IPropsFromParent {
  steps: IWizardStep[]
  data: any
  setData: (data: any) => void
}

type IProps = IPropsFromParent;

const Wizard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [currentStepNumber, setCurrentStepNumber] = useState(0)

  const goToNextStep = () => {
    goToStep(currentStepNumber + 1)
  }

  const goToPreviousStep = () => {
    goToStep(currentStepNumber - 1)
  }

  const goToStep = (number: number) => {
    setCurrentStepNumber(number)
  }

  const renderCurrentStep = (): JSX.Element => {
    const StepComponent = props.steps[currentStepNumber].component

    return (
      <StepComponent
        goToNextStep={goToNextStep}
        data={props.data}
        setData={props.setData}
      />
    )
  }

  return (
    <FormContextProvider>
      <div className="flex flex-col h-full">
        <WizardHeader
          steps={props.steps}
          currentStepNumber={currentStepNumber}
          goToStep={goToStep}
        />
        {renderCurrentStep()}
        <WizardFooter
          steps={props.steps}
          currentStepNumber={currentStepNumber}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      </div>
    </FormContextProvider>
  )
}

export default Wizard
