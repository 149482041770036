import { Form, Formik } from "formik"
import _ from "lodash"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { IUserConfigEditData, UserLanguages, UserTimezones } from "../../userConfig/state/userConfigTypes"
import Label from "../../../shared/components/forms/Label"
import { updateUserConfigAsync } from "../../userConfig/state/userConfigActions"
import { IAppState } from "../../../app/appTypes"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import SelectField from "../../../shared/components/forms/SelectField"
import { getUserByHasUserId } from "../../../shared/selectors/user"
import Card from "../../../shared/components/layout/Card"
import ButtonExpandCollapse from "../../../shared/components/buttons/ButtonExpandCollapse"
import { UserConfigContext } from "../../userConfig/contexts/UserConfigContext"

interface IProps {
  userId: string
}

const UserConfigEditForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["user"])
  const [isVisibleList, toggleList] = useState(false)
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))

  const dispatch = useDispatch()
  const updateUserConfig = (userId: string, user: IUserConfigEditData) => { dispatch(updateUserConfigAsync(userId, user)) }

  const { locale, timezone } = useContext(UserConfigContext)

  const initialValues: IUserConfigEditData = {
    locale: locale,
    timezone: timezone
  }

  const localeOptions: TSelectFieldOptions = Object.keys(UserLanguages).map((key) => {
    return {
      value: UserLanguages[key],
      label: key
    }
  })

  const timezoneOptions: TSelectFieldOptions = Object.keys(UserTimezones).map((key) => {
    return {
      value: UserTimezones[key],
      label: key
    }
  })

  if (!user) return null
  return (
    <div className="flex justify-left pl-4">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.timezone == UserTimezones.Default) {
            values.timezone = null
          }
          updateUserConfig(props.userId, values)
          setSubmitting(false)
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur
        }) => {
          return (
            <Form className="w-full lg:w-2/3">
              <Card className="flex-col mb-6">
                <div className="flex items-center flex-wrap">
                  <h3 className="mr-4 mb-2 text-lg">
                    {t("user:systemPreferences")}
                  </h3>
                </div>
                <div className="flex mb-2 items-end">
                  <p className="flex-1 pr-10 lg:pr-24">
                    {t("user:systemPreferencesDesc")}
                  </p>
                  <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("user:showSystemPreferences")} collapseText={t("user:hideSystemPreferences")} />
                </div>

                <div className={classNames({ hidden: !isVisibleList })}>
                  <div className="mb-2">
                    <Label name="locale">
                      {t("user:language")}
                    </Label>
                    <SelectField
                      name="locale"
                      options={localeOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="mb-6">
                    <Label name="timezone">
                      {t("user:timezone")}
                    </Label>
                    <p className="mb-2">
                      {t("user:timezoneDesc")}
                    </p>
                    <SelectField
                      name="timezone"
                      options={timezoneOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex justify-center">
                    <Button
                      isDisabled={isSubmitting}
                      variant={StyleVariants.PURPLE}
                      isSubmit={true}
                    >
                      {t("user:updateSystemPreferences")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default UserConfigEditForm
