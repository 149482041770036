import API from "../../../apis/contextmeeting/api"
import { fetchTeamSchema } from "../../../apis/contextmeeting/schema"
import { TeamsActionTypes } from "./teamsTypes"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { normalize } from "normalizr"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import humps from "humps"
import queryString from "query-string"

export function* fetchTeamsAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/teams?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [fetchTeamSchema])

    yield put({
      type: TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        appliedFilters: response.data.searchQuery.appliedFilters,
        orderBy: response.data.searchQuery.orderBy,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        searchTerm: response.data.searchQuery.searchTerm
      }
    })

  } catch (error) {
    yield put({
      type: TeamsActionTypes.FETCH_TEAMS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "teamsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchTeamsAsync(): Generator {
  yield takeLeading(TeamsActionTypes.FETCH_TEAMS_ASYNC_PENDING, fetchTeamsAsync)
}

export function* fetchAllTeamsAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/teams", withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [fetchTeamSchema])
    yield put({
      type: TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_SUCCESS,
      payload: normalizedResponse
    })
  } catch (error) {
    yield put({
      type: TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "teamsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchAllTeamsAsync(): Generator {
  yield takeLeading(TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_PENDING, fetchAllTeamsAsync)
}
