import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../app/appTypes"
import { getUserWithConfigById } from "../../selectors/liveMeetingConfig/userConfig"

interface IProps {
  userId: string
}

const TextCoordinatorUser: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["user"])
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, props))

  if (!user.config?.isCoordinator) return null

  return (
    <span className="uppercase">
      ({t("user:coordinator")})
    </span>
  )
}

export default TextCoordinatorUser
