import React from "react"
import _ from "lodash"
import { Trans, useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import Card from "../../../../shared/components/layout/Card"
import InviteButton from "../../../../shared/components/buttons/InviteButton"
import { getTeamById, getTeamUsersByTeamId } from "../../../../shared/selectors/team"
import { ITeam } from "../../../team/state/teamTypes"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import { createTeamUser } from "../../../teamUser/state/teamUserActions"
import MdtTeamUserListItem from "./MdtTeamUserListItem"
import MdtManageUsersModalContent from "./MdtManageUsersModalContent"
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks"
import { getTenantConfig } from "../../../../shared/selectors/tenantConfig"

interface IPropsFromParent {
  teamId: string
}

const MdtTeamUsers: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "team"])
  const dispatch = useAppDispatch()
  const team = useAppSelector((state: IAppState): ITeam => getTeamById(state, props.teamId))
  const teamUsers = useAppSelector((state) => getTeamUsersByTeamId(state, props.teamId))
  const { mdtRoles } = useAppSelector(getTenantConfig)
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const createTeamUsers = (values: { users: string[] }, { setSubmitting }) => {
    const teamUser = {
      teamId: team.id,
      userIds: values.users
    }
    dispatch(createTeamUser(teamUser))
    setSubmitting(false)
    closeModal()
  }

  if (!team || !team.users) return null
  const sortedTeamUsers = _.sortBy(teamUsers, (tu) => mdtRoles.indexOf(tu.role))
  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("team:members")} ({team.users.length})
          </h3>
          {team.permissions && team.permissions.canInvite ? (
            <InviteButton openModal={openModal} label={t("team:mdt:manageUsers")} />
          ) : null}
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans i18nKey="shared:teamsIntro" />
          </p>
        </div>

        <ul>
          {_.map(sortedTeamUsers, teamUser =>
            <MdtTeamUserListItem key={teamUser.id} teamUserId={teamUser.id} />
          )}
        </ul>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={defaultModalStyle}
      >
        <MdtManageUsersModalContent closeModal={closeModal} teamId={team.id} createTeamUsers={createTeamUsers} />
      </Modal>
    </div>
  )
}

export default MdtTeamUsers
