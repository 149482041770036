import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconPresenter({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M15.16 4.781c-.739-.014-1.344.605-1.344 1.345V9h-.263V3.192c0-.739-.605-1.358-1.344-1.344a1.318 1.318 0 00-1.293 1.318V9h-.264V1.907c0-.74-.605-1.358-1.344-1.344A1.318 1.318 0 008.016 1.88V9h-.264V3.225c0-.739-.605-1.358-1.344-1.344A1.318 1.318 0 005.115 3.2v7.778L4.072 9.543a1.318 1.318 0 10-2.132 1.55l4.14 5.693c.297.41.773.651 1.279.651h6.512c.734 0 1.372-.505 1.54-1.22l.874-3.712a6.32 6.32 0 00.168-1.45V6.1c0-.72-.576-1.305-1.292-1.319z"
        />
        <path d="M0 0h18v18H0z" />
      </g>
    </svg>
  );
}

export default SvgIconPresenter;
