import React, { ReactNode } from "react"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { ICommentAsset } from "../../state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import { getAllUsers } from "../../../../shared/selectors/user"
import { IconDeferral } from "../../../../svg/icons"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import AssetSubHeader from "./AssetSubHeading"
import { useSelector } from "react-redux"

interface Props {
  store?: MockStore
  assetId: string
  action?: () => void
  isSelected?: boolean
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
}

const Deferral: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const users = useSelector(getAllUsers)
  const comment = asset.asset as ICommentAsset

  return (
    <div
      onClick={props.action ? () => props.action() : null}
      className={classNames(
        "p-2",
        "flex",
        "rounded-md",
        "border-2",
        "bg-ch-blue-200",
        {
          "border-ch-blue-200": !props.isSelected,
          "border-ch-blue-500": props.isSelected,
          "hover:border-ch-blue-500": props.action,
          "cursor-pointer": props.action,
          "cursor-default": !props.action,
        }
      )}
    >
      <div className="text-ch-purple-500 mr-2 mt-1">
        <IconDeferral className="w-5 h-5" title={comment.title} />
      </div>
      <div className="flex-1">
        <h4 className="font-semibold text-lg">
          {comment.title}
        </h4>
        <AssetSubHeader
          meetingCaseAsset={props.meetingCaseAsset}
          meetingId={props.meetingId}
          asset={asset}
        />
        <MentionFieldReadOnly
          value={comment.body}
          users={users}
        />
      </div>
      {props.children}
    </div>
  )
}

export default Deferral
