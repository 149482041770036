import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import PdfViewer from "../../../../shared/components/ui/PdfViewer"
import fetchBlob from "../../../../shared/helpers/fetchBlob"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IDocumentAsset } from "../../../assets/state/assetsTypes"

interface IProps {
  assetId: string
}

const CasePdfViewer: React.FC<IProps> = (props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const pdf = asset.asset as IDocumentAsset
  const [blobUrl, setBlobUrl] = useState(null)

  useEffect(() => {
    fetchBlob(pdf.path, setBlobUrl)
  }, [pdf.path])

  if (!blobUrl) return <LoadingSpinner />

  return <PdfViewer
    url={blobUrl}
    filename={`${pdf.name}`}
  />
}

export default CasePdfViewer
