/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import _ from "lodash"

export interface ISearchQuery {
  page?: number
  appliedFilters?: string[]
  orderBy?: string
  resultsPerPage?: number
  searchTerm?: string
}

export type ISetSearchQueryFunction = (searchQuery: ISearchQuery) => void

type useSearchResult = [
  ISearchQuery,
  (newSearchQuery: ISearchQuery) => void
]

const useSearch = (
  initialSearchQuery: ISearchQuery,
  searchAction: any
): useSearchResult => {
  const dispatch = useDispatch()
  const [searchQuery, _setSearchQuery] = useState(initialSearchQuery)

  const setSearchQuery = (newSearchQuery: ISearchQuery) => {
    const updatedSearchQuery = {
      ...searchQuery,
      ...newSearchQuery
    }

    if (_.isEqual(searchQuery, updatedSearchQuery)) { return }
    _setSearchQuery(updatedSearchQuery)
  }

  useEffect(() => {
    // New query resets to page 1
    const newSearchQuery = {
      ...searchQuery,
      page: 1
    }
    setSearchQuery(newSearchQuery)
    dispatch(searchAction(newSearchQuery))
  }, [
    searchQuery.appliedFilters,
    searchQuery.orderBy,
    searchQuery.resultsPerPage,
    searchQuery.searchTerm
  ])

  useEffect(() => {
    dispatch(searchAction(searchQuery))
  }, [searchQuery.page])


  return [
    searchQuery,
    setSearchQuery
  ]
}

export default useSearch
