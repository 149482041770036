import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"
import { getComponentTypeForAsset } from "../../../../shared/helpers/assetHelpers"
import { IMessageAsset } from "../../../message/state/messageTypes"
import { getMessageAssetById } from "../../../../shared/selectors/message"
import MessageAssetViewerHeading from "./MessageAssetViewerHeading"
import MessageAssetPdfViewer from "./MessageAssetPdfViewer"
import MessageAssetImageViewer from "./MessageAssetImageViewer"

interface Props {
  messageAssetId: string
}

const MessageAssetViewer: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["asset"])

  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  if (!messageAsset) return <h3 className="text-lg font-bold text-center">{t("asset:assetNotFound")}</h3>

  const assetComponetType = getComponentTypeForAsset(messageAsset)
  const componentLookup = {
    [AssetComponentTypes.Pdf]: MessageAssetPdfViewer,
    [AssetComponentTypes.Image]: MessageAssetImageViewer,
  }
  const AssetViewer = componentLookup[assetComponetType]

  if (!AssetViewer) return <h3 className="text-lg font-bold text-center">{t("asset:couldNotDisplayAsset")}</h3>

  return (
    <div className="flex flex-col w-full h-full">
      <div className="absolute w-full top-0 left-0 z-0">
        <MessageAssetViewerHeading messageAssetId={props.messageAssetId} />
      </div>
      <div className="flex-1 min-h-0">
        <AssetViewer
          messageAssetId={props.messageAssetId}
        />
      </div>
    </div>
  )
}

export default MessageAssetViewer
