import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { IAuditEventChanges } from "../../../audit/state/auditEventTypes"
import { IPatientAmendment } from "../../state/patientTypes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"

interface IPropsFromParent {
  amendment: IPatientAmendment
}

type Props = IPropsFromParent

const PatientAmendmentListItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "patient"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  if (!props.amendment) return null

  const renderAmendmentDate = (): JSX.Element => {
    return (
      <li>
        {t("patient:reasonForChange:details:amendmentDate")}: <TimeDefaultFormat utcTime={props.amendment.auditEvent.createdAt} />
      </li>
    )
  }

  const renderReason = (): JSX.Element => {
    return (
      <li>
        {t("patient:reasonForChange:details:reason")}: {props.amendment.reason}
      </li>
    )
  }

  const renderAmendedBy = (): JSX.Element => {
    return (
      <li>
       {t("patient:reasonForChange:details:amendedBy")}: <NameUser
          firstName={props.amendment.auditEvent.createdBy.firstName}
          lastName={props.amendment.auditEvent.createdBy.lastName}
        />
      </li>
    )
  }

  const renderTypeOfChange = (): JSX.Element => {
    const translationKey = humps.camelize(props.amendment.amendmentType)
    return (
      <li>
        {t("patient:reasonForChange:details:amendmentType")}: {t(`patient:reasonForChange:details:${translationKey}`)}
      </li>
    )
  }

  const renderAmendments = (): JSX.Element => {
    const changes: IAuditEventChanges = props.amendment.auditEvent.eventData.changes
     return (
      <ul>
        {Object.keys(changes).map((attributeName) => {
          if(!changes[attributeName]) return
          return renderAmendment(changes[attributeName] as string[], attributeName)
        })}
      </ul>
    )
  }

  const attributeNameText = (attributeName: string):string => {
    if(attributeName == "healthcareId") return healthcareIdLabel
    if(attributeName == "careProviderId") return careProviderIdLabel
    return t(`patient:details:${attributeName}`)
  }

  const renderAmendment = (change: string[], attributeName:string) => {
    return (
      <div key={attributeName}>
        {attributeNameText(attributeName)} ({t("patient:reasonForChange:previous")}): {change[0]}
      </div>
    )
  }

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <div className="flex-1 lg:grid grid-cols-2 gap-3">
            <ul className="col-span-1">
              { renderAmendmentDate() }
              { renderAmendments() }
              { renderTypeOfChange() }
            </ul>
            <ul className="col-span-1">
              { renderAmendedBy() }
              { renderReason() }
            </ul>
          </div>
        </DetailsCardInner>
      </DetailsCard>
    </li>
  )
}

export default PatientAmendmentListItem
