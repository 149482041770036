import React from "react"
import { useTranslation } from "react-i18next"

interface IPropsFromParent {
  header: string,
  content: string,
  translateText?: boolean
}

const AuditEventBodyContent = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])

  return (
    <div>
      <span className="font-semibold">
        {t(`auditEvent:${props.header}`)}:&nbsp;
      </span>
      <span className="break-all">
        {props.translateText ? t(`auditEvent:${props.content}`) : props.content}
      </span>
    </div>
  )
}

export default AuditEventBodyContent
