import React from "react"
import { Form, Formik } from "formik"
import Button from "../../../shared/components/buttons/Button"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ISetPasswordData } from "../state/accountTypes"
import { setPasswordAction } from "../state/accountActions"
import { setPasswordValidationSchema } from "../state/setPasswordValidation"
import ButtonLink from "../../../shared/components/buttons/ButtonLink"

interface IPropsFromDispatch {
  setPassword: (setPasswordData) => void
}

interface IPropsFromParent {
  token: string
}

type Props = IPropsFromDispatch & IPropsFromParent

const SetPasswordForm = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "account"])

  const initialValues: ISetPasswordData = {
    password: "",
    passwordConfirmation: "",
    local: true,
    token: props.token
  }

  return (
    <div className="flex justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={setPasswordValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // I don't really understand why we need to set the token here again
          // on the values,, the token should be coming from the initial values,
          // but, after having worked for a year or so, it suddenly broke.
          values.token = props.token
          props.setPassword(values)
          setSubmitting(false)
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur
        }) => (
          <Form className="flex-1">
            <div className="flex justify-center">
              <div>
                <h2 className="text-lg mb-4">
                  {t("account:setPasswordTitle")}
                </h2>
              </div>
            </div>
            <div className="mb-2">
              {t("account:passwordRulesHeader")}:
              <ul className="list-disc ml-5 text-sm">
                <li> {t("account:passwordRuleSpecial")} </li>
                <li> {t("account:passwordRuleNumber")} </li>
                <li> {t("account:passwordRuleUpper")} </li>
                <li> {t("account:passwordRuleLower")} </li>
              </ul>
            </div>

            <div className="mb-2">
              <Label name="password">
                {t("account:password")}
              </Label>
              <InputField
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="off"
                autoFocus={true}
              />
              <FieldError errorMessage={errors.password as string} isVisible={(errors.password && touched.password) as boolean} />
            </div>

            <div className="mb-6">
              <Label name="passwordConfirmation">
                {t("account:passwordConfirmation")}
              </Label>
              <InputField
                type="password"
                name="passwordConfirmation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                autoComplete="off"
              />
              <FieldError errorMessage={errors.passwordConfirmation as string} isVisible={(errors.passwordConfirmation && touched.passwordConfirmation) as boolean} />
            </div>

            <div className="flex justify-center">
              <ButtonLink href="/signin">
                {t("account:signIn")}
              </ButtonLink>
              <Button variant={StyleVariants.PURPLE} isSubmit={true}>
                {t("account:setPassword")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapDispatchToProps = (dispatch): IPropsFromDispatch => {
  return {
    setPassword: (setPasswordData: ISetPasswordData) => dispatch(setPasswordAction(setPasswordData)),
  }
}

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(SetPasswordForm)
