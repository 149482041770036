import { Form, Formik, FormikProps } from "formik"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IChat, IExportChatData } from "../../state/chatTypes"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import Buttons from "../../../../shared/components/layout/Buttons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { exportChatValidation } from "../../state/validation/exportChatValidation"
import Label from "../../../../shared/components/forms/Label"
import InputField from "../../../../shared/components/forms/InputField"
import FieldError from "../../../../shared/components/forms/FieldError"
import ExportChatAssetsSelect from "./ExportChatAssetsSelect"
import { exportChatAsync } from "../../state/chatActions"

interface Props {
  chatId: string
  closeModal: () => void
  isOpen: boolean
}

const ExportChatForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  const dispatch = useDispatch()
  const exportChat = (chatId: string, exportChatData: IExportChatData) => dispatch(exportChatAsync(chatId, exportChatData))

  const formikFormRef = useRef<FormikProps<IExportChatData>>(null)

  useEffect(() => {
    if(!formikFormRef?.current) return

    formikFormRef.current.resetForm()
  }, [chat.messageAssets, props.isOpen])

  if(!chat.messageAssets) return (
    <h3 className="font-bold text-center">
       {t("message:exportChat:noChatAssets")}
    </h3>
  )

  const initialValues: IExportChatData = {
    assetIds: chat.messageAssets,
    summary: ""
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={exportChatValidation}
        onSubmit={(values, { setSubmitting }) => {
          exportChat(props.chatId, values)
          setSubmitting(false)
          props.closeModal()
        }}
        innerRef={formikFormRef}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          touched,
          errors,
          isValid
        }) => {
          return (
            <Form className="w-full">
               <div className="mb-2">
                <Label name="summary" required={true}>
                  {t("message:exportChat:attributes:summary")}
                </Label>
                <InputField
                  type="text"
                  name="summary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.summary}
                  autoComplete="off"
                  autoFocus={true}
                />
                <FieldError errorMessage={errors.summary as string} isVisible={(errors.summary && touched.summary) as boolean} />
              </div>

              <div className="mb-4">
                <Label name="assetIds" required={true}>
                  {t("message:exportChat:attributes:assetIds")}
                </Label>
                <ExportChatAssetsSelect
                  assetIds={values.assetIds}
                  setFieldValue={setFieldValue}
                />
                <FieldError errorMessage={errors.assetIds as string} isVisible={(errors.assetIds && touched.assetIds) as boolean} />
              </div>

              <Buttons
                buttons={
                  [
                    <Button
                      action={props.closeModal}
                      key="1"
                      variant={StyleVariants.BLUE_ALT}
                    >
                      {t("shared:cancel")}
                    </Button>,
                    <Button
                      isSubmit={true}
                      isDisabled={isSubmitting || !isValid || chat?.endedAt !== null}
                      key="2"
                      variant={StyleVariants.PURPLE}
                    >
                      {t("message:exportChat:buttons:exportAndClose")}
                    </Button>
                  ]
                } />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ExportChatForm
