import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { IPathway } from "../../pathwayTypes"

type Props = {
  pathwayId: string
}

export const PathwayDetails: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector((state: IAppState) => state.entities.pathways.byId[props.pathwayId])

  if (!pathway) return null

  const creatorOrUpdater = () => {
    if (pathway.lastUpdatedBy) {
      return <li>{t("shared:lastUpdatedBy")}{pathway.lastUpdatedBy.firstName} {pathway.lastUpdatedBy.lastName}</li>
    }
    return <li>{t("shared:createdBy")}: {pathway.createdBy.firstName} {pathway.createdBy.lastName}</li>
  }

  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        <li>
          <b>{pathway.name}</b>
        </li>
        <li>
          {t("shared:classification")}: {pathway.caseClassification.name}
        </li>
        {creatorOrUpdater()}
      </ul>
      <ul className="col-span-1">
        <li>
          {t("shared:lastUpdated")}: <TimeDefaultFormat utcTime={pathway.updatedAt || pathway.createdAt} />
        </li>
        <li>
          {t("pathway:steps")}: {pathway.steps.length}
        </li>
      </ul>
    </div>
  )
}

export default PathwayDetails
