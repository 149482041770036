import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconPencil({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M6.53 14.78l6.97-6.97-3.31-3.31-6.97 6.97a.752.752 0 00-.198.348L2.25 15.75l3.931-.773a.751.751 0 00.35-.197zm9.22-9.22a1.5 1.5 0 000-2.12l-1.19-1.19a1.5 1.5 0 00-2.12 0l-1.19 1.19 3.31 3.31 1.19-1.19z"
        />
        <path d="M0 0h18v18H0z" />
      </g>
    </svg>
  );
}

export default SvgIconPencil;
