import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { IconMeeting } from "../../../../svg/icons"
import { IMeeting } from "../../../meeting/state/meetingTypes"

interface IPropsFromParent {
  meetingId: string
}

const AuditEventMeetingDetail: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))

  if (!meeting) return null
  return (
    <div className="flex items-center my-1">
      <div className="text-ch-purple-500 mr-2">
        <IconMeeting className="w-5 h-5" title={meeting.name} />
      </div>
      <div className="font-thin">
        {meeting.name} - {meeting.specialtyName} - <TimeDefaultFormat utcTime={meeting.startDate} />
      </div>
    </div>
  )
}

export default AuditEventMeetingDetail
