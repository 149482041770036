import React, { useEffect } from "react"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import SvgIconTime from "../../../svg/icons/IconTime"
import { DurationLabel } from "./DurationLabel"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { IMeetingFormData } from "../../meeting/state/meetingTypes"
import { getAllActiveUsersSortedFirstNameLastName } from "../../../shared/selectors/user"
import { IAppState } from "../../../app/appTypes"
import { addHour } from "../../../shared/helpers/timeHelpers"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import { generateSpecialtyOptions, generateTeamOptions } from "../../../shared/helpers/selectOptionHelpers"
import SelectField from "../../../shared/components/forms/SelectField"
import DatePickerField from "../../../shared/components/datePicker/DatePickerField"
import TimeInput from "../../../shared/components/time/TimeInput"
import { TeamTypes } from "../../team/state/teamTypes"
import { getAllTeams } from "../../../shared/selectors/team"
import TeamMemberList from "../../../shared/components/user/TeamMemberList"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import { useSelector } from "react-redux"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"

const MdtMeetingNewFormContent: React.FC = () => {
  const users = useSelector((state: IAppState) => getAllActiveUsersSortedFirstNameLastName(state))
  const keyedUsers = _.keyBy(users, "id")
  const specialties = useSelector(getAllSpecialties)
  const teams = useSelector(getAllTeams)
  const { t } = useTranslation(["meeting", "team"])
  const [userOptions, setUserOptions] = React.useState([])
  const [specialtiesOptions, setSpecialtiesOptions] = React.useState([])
  const [mdtTeamOptions, setMdtTeamOptions] = React.useState([])
  const [teamOptions, setTeamOptions] = React.useState([])

  const {
    isSubmitting,
    setFieldValue,
    errors,
    values,
    touched,
    handleChange,
    handleBlur
  } = useFormikContext<IMeetingFormData>()

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(keyedUsers))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(specialties)
    setSpecialtiesOptions(options)
  }, [specialties])

  useEffect(() => {
    const allTeams = Object.values(teams).filter((team) => values.mdtTeamId != team.id)
    const options: TSelectFieldOptions = generateTeamOptions(allTeams)
    setTeamOptions(options)
  }, [teams, values.mdtTeamId])

  useEffect(() => {
    const mdtTeams = Object.values(teams).filter((team) => team.teamType == TeamTypes.mdt)
    const options: TSelectFieldOptions = generateTeamOptions(mdtTeams)
    setMdtTeamOptions(options)
  }, [teams])

  useEffect(() => {
    const team = teams[values.mdtTeamId]
    if (!team) return
    setFieldValue("name", team.name)
    setFieldValue("coordinator", team.owner)
    setFieldValue("specialtyId", team.specialtyId)
  }, [values.mdtTeamId])

  useEffect((() => {
    setFieldValue("meetingType", "mdt")
  }), [])

  const renderTeamsField = () => {
    if (Object.keys(teams).length == 0) return null
    return (
      <div className="mb-2">
        <Label name="users">
          {t("meeting:mdtMeeting:additionalTeams")}
        </Label>
        <SelectField
          name="teams"
          options={teamOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          isMulti={true}
        />
        <FieldError errorMessage={errors.users as string} isVisible={(errors.users && touched.users) as boolean} />
        <TeamMemberList teamIds={values.teams} header={t("meeting:teamUserListHeader")} />
      </div>

    )
  }

  return (
    <>
      <DenotesRequiredMessage />

      <div className="mb-2">
        <Label name="mdtTeamId" required>
          {t("team:teamTypes:mdtTeam")}
        </Label>
        <SelectField
          name="mdtTeamId"
          options={mdtTeamOptions}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FieldError errorMessage={errors.users as string} isVisible={(errors.users && touched.users) as boolean} />
        <TeamMemberList teamIds={values.mdtTeamId ? [values.mdtTeamId] : []} header={t("meeting:teamUserListHeader")} />
      </div>

      <div className="mb-2">
        <Label name="name" required>
          {t("meeting:title")}
        </Label>
        <InputField
          type="text"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="specialtyId" required={true}>
          {t("meeting:specialty")}
        </Label>
        <SelectField
          name="specialtyId"
          options={specialtiesOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          isGrouped={true}
        />
        <FieldError errorMessage={errors.specialtyId as string} isVisible={(errors.specialtyId && touched.specialtyId) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="coordinator" required={true}>
          {t("meeting:coordinator")}
        </Label>
        <SelectField
          name="coordinator"
          options={userOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          filterOption={filterOptionsBySearchValue}
        />
        <FieldError errorMessage={errors.coordinator as string} isVisible={(errors.coordinator && touched.coordinator) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="users">
          {t("meeting:mdtMeeting:additionalParticipants")}
        </Label>
        <SelectField
          name="users"
          options={userOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          isMulti={true}
          closeMenuOnSelect={false}
          filterOption={filterOptionsBySearchValue}
        />
        <FieldError errorMessage={errors.users as string} isVisible={(errors.users && touched.users) as boolean} />
      </div>

      {renderTeamsField()}

      <div>
        <Label name="startDate" required={true}>
          {t("meeting:startDateAndTime")}
        </Label>
        <div className="flex flex-wrap">
          <div className="flex mr-4 mb-1">
            <div className="flex flex-col">
              <DatePickerField name="startDate" />
              <div className="lg:h-8 my-1 ml-7 sm:h-0">
                <FieldError errorMessage={errors.startDate as string} isVisible={(errors.startDate && touched.startDate) as boolean} />
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="flex space-between items-center">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <TimeInput
                    name="startTime"
                    onChange={(e) => {
                      if (!values.endTime) {
                        setFieldValue("endTime", addHour(e.currentTarget.value, 1))
                      }
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">-</span>
                </div>
                <div className="h-8 my-1">
                  <FieldError errorMessage={errors.startTime as string} isVisible={(errors.startTime && touched.startTime) as boolean} />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <TimeInput
                    name="endTime"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="self-center mx-1 flex">
                    <SvgIconTime className="text-ch-blue-500 mx-2 w-5 h-5" />
                    <div className="self-center">
                      <DurationLabel
                        startTime={values.startTime}
                        endTime={values.endTime}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-8 my-1">
                  <FieldError errorMessage={errors.endTime as string} isVisible={(errors.endTime && touched.endTime) as boolean} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <Button
          isDisabled={isSubmitting}
          variant={StyleVariants.PURPLE}
          isSubmit={true}
        >
          {t("createMeeting")}
        </Button>
      </div>
    </>
  )
}
export default MdtMeetingNewFormContent
