import React from "react"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { IconFolder } from "../../../../svg/icons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import MeetingCaseDetails from "../../../meetingCase/components/MeetingCaseDetails"
import QuickViewCard from "../../../../shared/components/ui/quickView/QuickViewCard"

interface IProps {
  store?: MockStore
  meetingCaseId: string
}

const MeetingCaseListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])

  return (
    <li>
      <QuickViewCard>
        <IconCircleContainer className="mr-4">
          <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
        </IconCircleContainer>

        <div className="flex-1">
          <MeetingCaseDetails meetingCaseId={props.meetingCaseId} isCondensed={true} hasLink={true} />
        </div>
      </QuickViewCard>
    </li>
  )
}

export default MeetingCaseListItem
