import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import MeetingCaseListItem from "./MeetingCaseListItem"
import { getMeetingCaseIds } from "../../../../shared/selectors/meeting"

interface IProps {
  store?: MockStore;
  meetingId: string;
}

const MeetingCaseList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["case"])
  const meetingCaseIds = useSelector((state: IAppState) => getMeetingCaseIds(state, props))

  if (!meetingCaseIds || meetingCaseIds.length == 0) return <span>{t("case:noCases")}</span>

  return (
    <ul>
      {meetingCaseIds.map((meetingCaseId: string) => {
        return (
          <MeetingCaseListItem
            key={meetingCaseId}
            meetingCaseId={meetingCaseId}
          />
        )
      })}
    </ul>
  )
}

export default MeetingCaseList
