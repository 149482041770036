import { takeLeading, put, Effect } from "redux-saga/effects"
import { AssetsActionTypes } from "../assetsTypes"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { fetchMeetingCaseAction } from "../../../meetingCase/state/meetingCaseActions"
import { handleError, handleSuccess, postNormalisedSingle } from "../../../../shared/helpers/sagaHelpers"

export function* watchCreateLinkAsync(): Generator {
  yield takeLeading(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_PENDING, createLinkAsync)
  yield takeLeading(AssetsActionTypes.CREATE_CASE_DICOM_LINK_ASYNC_PENDING, createDicomLinkAsync)
}
export function* createLinkAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      link: action.payload.link,
      meetingCaseId: action.payload.meetingCaseId
    }

    const normalizedResponse = yield postNormalisedSingle(`/cases/${caseId}/links`, data, assetsSchema)
     const payload =  {
        all: normalizedResponse,
        caseId: caseId
      }
    yield handleSuccess(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS, payload, "createLinkSuccess")

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

  } catch (error) {
    yield handleError(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_ERROR, error, "createLinkFail")
  }
}


export function* createDicomLinkAsync(action: Effect): Generator {
  try {
    console.log('saga', action.payload)
    const caseId = action.payload.caseId
    const data = {
      radiologyLink: action.payload.radiologyLink
    }

    const normalizedResponse = yield postNormalisedSingle(`/cases/${caseId}/radiology_links`, data, assetsSchema)
     const payload =  {
        all: normalizedResponse,
        caseId: caseId
      }
    yield handleSuccess(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_SUCCESS, payload, "createLinkSuccess")

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }

  } catch (error) {
    yield handleError(AssetsActionTypes.CREATE_CASE_LINK_ASYNC_ERROR, error, "createLinkFail")
  }
}
