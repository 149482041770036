import { useFormikContext } from "formik"
import React, { FC, useState } from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import FieldError from "../../../shared/components/forms/FieldError"
import InputField from "../../../shared/components/forms/InputField"
import Label from "../../../shared/components/forms/Label"
import SelectField from "../../../shared/components/forms/SelectField"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import { isGuest } from "../../../shared/helpers/userHelpers"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import { getAllActiveUsersSortedFirstNameLastName } from "../../../shared/selectors/user"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ITeamNewData } from "../../team/state/teamTypes"
import MdtTeamUsersFieldArray from "./MdtTeamUsersFieldArray"

const MdtTeamNewFormContent: FC = () => {
  const {
    isSubmitting,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue
  } = useFormikContext<ITeamNewData>()

  const [userOptions, setUserOptions] = useState<TSelectFieldUserOptions>([])
  const [specialtiesOptions, setSpecialtiesOptions] = useState([])
  const users = useSelector(getAllActiveUsersSortedFirstNameLastName)
  const specialties = useSelector(getAllSpecialties)
  const { t } = useTranslation(["team"])

  useEffect(() => {
    const filteredUsers = users.filter((u) => !isGuest(u))
    const options: TSelectFieldUserOptions = buildUserOptions(filteredUsers)
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(
      specialties
    )
    setSpecialtiesOptions(options)
  }, [specialties])

  useEffect(() => {
    if (values.teamUsers && values.teamUsers.length == 0)
      setFieldValue("teamUsers", [{
        userId: null,
        role: null,
        id: ""
      }])

  }, [values.teamUsers])

  return (
    <>
      <div className="mb-2">
        <Label name="name" required={true}>
          {t("team:mdt:mdtName")}
        </Label>
        <InputField
          type="text"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          autoComplete="off"
          autoFocus={true}
        />
        <FieldError
          errorMessage={errors.name as string}
          isVisible={(errors.name && touched.name) as boolean}
        />
      </div>

      <div className="mb-2">
        <Label name="specialtyId" required={true}>
          {t("team:specialty")}
        </Label>
        <SelectField
          name="specialtyId"
          options={specialtiesOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          isGrouped={true}
        />
        <FieldError
          errorMessage={errors.specialtyId as string}
          isVisible={
            (errors.specialtyId && touched.specialtyId) as boolean
          }
        />
      </div>

      <div className="mb-2">
        <Label name="ownerId" required={true}>
          {t("team:mdt:mdtCoordinator")}
        </Label>
        <SelectField
          name="ownerId"
          options={userOptions}
          onChange={handleChange}
          filterOption={filterOptionsBySearchValue}
          onBlur={handleBlur}
        />
        <FieldError
          errorMessage={errors.ownerId as string}
          isVisible={(errors.ownerId && touched.ownerId) as boolean}
        />
      </div>

      <div className="mb-2">
        <MdtTeamUsersFieldArray userOptions={userOptions} />
      </div >

      <div className="flex justify-center">
        <Button
          isDisabled={isSubmitting}
          variant={StyleVariants.PURPLE}
          isSubmit={true}
        >
          {t("Create Team")}
        </Button>
      </div>
    </>
  )
}

export default MdtTeamNewFormContent
