import React from "react"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { IHavePatientId } from "../../../../patient/state/patientTypes"
import { smallModalStyle, StyleVariants } from "../../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import PatientCaseList from "../../shared/PatientCaseList"
import Button from "../../../../../shared/components/buttons/Button"
import Buttons from "../../../../../shared/components/layout/Buttons"

interface IPropsFromParent {
  handleConfirm: () => void
  handleClose: () => void
  isOpen: boolean
}

type Props = IPropsFromParent

const ConfirmModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { patientId } = useParams<IHavePatientId>()

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.handleClose} style={smallModalStyle}>
      <ModalContainer handleClose={props.handleClose}>
        <h2 className="text-lg mb-3 text-ch-blue-alt-500">
          {t("patient:caseDetails:caseAlreadyExistsTitle")}:
        </h2>

        <div className="mb-8">
          <PatientCaseList patientId={patientId} />
        </div>

        <p className="mb-2">
          {t("patient:caseDetails:caseAlreadyExistsInfo")}
        </p>

        <p className="mb-2">
          {t("patient:caseDetails:areYouSure")}
        </p>

        <Buttons
          className="mb-2"
          buttons={[
            <Button
              action={props.handleClose}
              variant={StyleVariants.PURPLE}
              key="1"
            >
              {t("shared:cancel")}
            </Button>,
            <Button
              action={props.handleConfirm}
              variant={StyleVariants.BLUE_ALT}
              key="2"
            >
              {t("patient:caseDetails:buttons:createCase")}
            </Button>
          ]}
        />
      </ModalContainer>
    </Modal>
  )
}

export default ConfirmModal
