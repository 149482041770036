import React from "react"
import { IUser } from "../../../features/user/state/userTypes"
import { PendingLabel } from "./PendingLabel"
import { ISelectFieldUserOption } from "../forms/types"
import { Option } from "react-select/src/filters"
import { GuestLabel } from "./GuestLabel"
import { isGuest } from "../../helpers/userHelpers"

interface IProps {
  user: IUser
}

type Props = IProps

const escapeSpecialSymbols = (text: string) => {
  return text.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
}

export const filterOptionsBySearchValue = (option: Option, text: string): boolean => {
  const regexp = new RegExp(escapeSpecialSymbols(text), "i")
  if (option.data.searchValue.match(regexp)) return true
  return false
}

export const buildUserOptions = (users: IUser[]): ISelectFieldUserOption[] =>
  users.map((user: IUser) => {
    return {
      value: user.id,
      label: <UserOption user={user} />,
      searchValue: formatUserOptionsString(user)
    }
  })


const formatUserOptionsString = (user: IUser): string => {
  if (user.professionalTitle && user.professionalTitle.length > 0) {
    return `${user.firstName} ${user.lastName} - ${user.professionalTitle}`.trim()
  } else {
    return `${user.firstName} ${user.lastName}`.trim()
  }
}

const UserOption: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <span>{formatUserOptionsString(props.user)}</span>
      <PendingLabel isPending={props.user.pending} />
      <GuestLabel isGuest={isGuest(props.user)} />
    </>
  )
}
