import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconImage({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="currentColor">
        <path d="M49.2 1.5c-3 .7-9.2 3.2-14 5.5-12.5 6.2-22.4 16.3-28.5 29.1-2.5 5.2-5 11.8-5.6 14.7-1.5 7.5-1.5 402.9 0 410.4.6 2.9 3.1 9.5 5.6 14.7 3.7 7.7 5.9 10.8 12.2 17.2 6.4 6.3 9.5 8.5 17.2 12.2 5.2 2.5 11.8 5 14.7 5.6 7.5 1.5 402.9 1.5 410.4 0 2.9-.6 9.5-3.1 14.7-5.6 13.3-6.4 23-16.1 29.4-29.4 2.5-5.2 5-11.8 5.6-14.7 1.5-7.5 1.5-402.9 0-410.4-.6-2.9-3.1-9.5-5.6-14.7-6.4-13.3-16.1-23-29.4-29.4-5.2-2.5-11.8-5-14.7-5.6-8.3-1.7-404.9-1.4-412 .4zm409.5 31.6c8 3 17.2 12.2 20.2 20.2l2.1 5.8-.2 109.1-.3 109.1-35.6-43.9c-19.6-24.2-37.2-45.3-39.1-47-8.7-7.6-21.1-11.2-32.5-9.4-14.5 2.3-11-.7-89.3 77.6L212.5 326l-2.5-2.4c-7.3-7-52.2-43.1-56.1-45.1-12.2-6.2-29.1-5.2-40.3 2.3-2.2 1.5-21.5 17.7-43 35.9L31.5 350l-.3-145.5L31 59.1l2.2-5.8c2.9-8 11.2-16.5 19.3-19.8l6-2.5h394.4l5.8 2.1zM433 268.5l47.9 59 .1 62.7c0 61.6 0 62.8-2.1 68.5-3 8-12.2 17.2-20.2 20.2l-5.8 2.1H59.1l-5.8-2.1c-8-3-17.2-12.2-20.2-20.2-2-5.4-2.1-7.6-2.1-36.5l.1-30.7 49.4-42c27.2-23.1 50.8-42.6 52.4-43.2 2.1-.9 3.8-1 5.6-.3 1.4.5 9.2 6.5 17.3 13.3 53.5 44.8 53.7 45 62.5 42.7 3.8-1 12.1-9.1 80.2-77.5 53.8-54.2 76.7-76.5 78.5-76.8 1.4-.2 3.7.2 5.3.7 1.9.8 18.1 20 50.7 60.1z" />
        <path d="M143.5 64.6c-3.3.8-10.3 3.5-15.5 6.1-7.9 3.9-10.9 6.1-18 13.3-9.8 9.8-14 16.5-18.3 28.8-2.8 8-3 9.9-3.1 22.2-.1 11.5.3 14.6 2.2 21 7.9 25.1 26 43.1 50.7 50.2 6.2 1.7 10.6 2.3 19.5 2.3 14.1 0 19.6-1.3 32.9-7.7 9-4.3 11.6-6.2 18.5-13.2 14.4-14.4 20.5-28.5 21.3-48.6.7-17.7-3.2-31.7-12.7-45.5-9.6-14-25.9-24.9-43.2-29-8-1.9-26.3-1.8-34.3.1zm34.8 33.1c11.5 5.4 20.1 15.6 23.3 27.8 7.6 28.2-17.6 56-46.6 51.4-8.9-1.3-16.5-5.2-23.1-11.8-21.9-22-13.9-57.6 15.5-68.8 5.1-1.9 7.6-2.3 15.1-2 7.7.2 10 .7 15.8 3.4z" />
      </g>
    </svg>
  );
}

export default SvgIconImage;
