import React from "react"
import { useTranslation } from "react-i18next"
import { IconEye } from "../../../../svg/icons"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import TextLink from "../../../../shared/components/text/TextLink"

interface IProps {
  className?: string;
  variant?: string;
  caseId: string
}

const defaultProps = {
  variant: StyleVariants.BLUE
}

const CaseWatchButton: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])

  const toggleWatchCase = (isWatchingCase: boolean) => { console.log(`toggle watch case: ${isWatchingCase}`) }
  // TODO: Get from case
  const isWatchingCase = false

  const renderWatchButton = (): JSX.Element => {
    return (
      <TextLink
        action={() => toggleWatchCase(false)}
        className={props.className}
        variant={props.variant}
      >
        {t("case:stopWatchingCase")} <IconEye className="w-6 h-6 ml-3" />
      </TextLink>
    )
  }

  const renderStopWatchingButton = (): JSX.Element => {
    return (
      <TextLink
        action={() => toggleWatchCase(true)}
        className={props.className}
        variant={props.variant}
      >
        {t("case:watchCase")} <IconEye className="w-6 h-6 ml-3" />
      </TextLink>
    )
  }

  return isWatchingCase ? renderStopWatchingButton() : renderWatchButton()
}

CaseWatchButton.defaultProps = defaultProps

export default CaseWatchButton
