import { AnyAction, combineReducers, Reducer } from "redux"
import { IHospitalAnalyticsState, AnalyticsActionTypes, THospitalAnalyticsMeetingData, THospitalAnalyticsMontlyData, THospitalMetaData } from "./analyticsTypes"

export function meetings(state: THospitalAnalyticsMeetingData[] = [], action: AnyAction): THospitalAnalyticsMeetingData[] {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_HOSPITAL_DATA_SUCCESS:
      return [...action.payload.hospitalMeetings]
    default:
      return [...state]
  }
}

export function monthlyData(state: THospitalAnalyticsMontlyData[] = [], action: AnyAction): THospitalAnalyticsMontlyData[] {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_HOSPITAL_DATA_SUCCESS:
      return [...action.payload.monthlyData]
    default:
      return [...state]
  }
}

export function metaData(state: THospitalMetaData = { fromYear: (new Date()).getFullYear() }, action: AnyAction): THospitalMetaData {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_HOSPITAL_DATA_SUCCESS:
      return { ...action.payload.meta }
    default:
      return { ...state }
  }
}

export const hospitalAnalyticsReducer: Reducer<IHospitalAnalyticsState> = combineReducers({
  meetings,
  monthlyData,
  metaData
})
