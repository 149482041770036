import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import Button from "../../../../../shared/components/buttons/Button"
import CheckmarkToggle from "../../../../../shared/components/forms/CheckmarkToggle"
import DenotesRequiredMessage from "../../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../../shared/components/forms/FieldError"
import FileUploadField from "../../../../../shared/components/forms/FileUploadField"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import MentionField from "../../../../../shared/components/forms/MentionField"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { getTaskById } from "../../../../../shared/selectors/task"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconTask } from "../../../../../svg/icons"
import { createCaseTaskResponseAsync } from "../../../../taskResponse/state/taskResponseActions"
import { ITaskResponseNewData } from "../../../../taskResponse/state/taskResponseTypes"
import { caseTaskResponseNewValidation } from "../../../../taskResponse/state/validation/caseTaskResponseNewValidation"

interface Props {
  caseId: string
  taskId: string
  closeAction: () => void
}

const AddResponseForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "task"])
  const dispatch = useDispatch()
  const createTaskRespose = (caseId: string, taskId: string, taskResponse: ITaskResponseNewData) => dispatch(createCaseTaskResponseAsync(caseId, taskId, taskResponse))
  const task = useSelector((state: IAppState) => getTaskById(state, { taskId: props.taskId }))
  const initialValues: ITaskResponseNewData = {
    response: "",
    markedTaskAsComplete: false,
    caseAssetAttributes: {
      assetType: "Document",
      assetAttributes: {
        file: null,
        name: ""
      }
    }
  }

  return (
    <div>
      <h2 className="text-lg mb-3">
        {t("task:respondToTask")}
      </h2>

      <div className="flex mb-2">
        <div className="text-ch-purple-500 mr-2 mt-1">
          <IconTask className="w-5 h-5" title={task.action} />
        </div>
        <h4 className="font-semibold">
          {task.action}
        </h4>
      </div>

      <h3 className="text-ch-blue-600 mb-3">
        {t("task:responseIntro:main")}:
      </h3>

      <ul className="text-ch-blue-600 mb-3 list-disc list-inside">
        <li>{t("task:responseIntro:comment")}</li>
        <li>{t("task:responseIntro:file")}</li>
        <li>{t("task:responseIntro:markComplete")}</li>
      </ul>

      <Formik
        initialValues={initialValues}
        validationSchema={caseTaskResponseNewValidation}
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          createTaskRespose(props.caseId, props.taskId, values)
          setSubmitting(false)
          props.closeAction()
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="response" required={true}>
                  {t("task:response")}
                </Label>
                <MentionField
                  name="response"
                  setValue={(description) => setFieldValue("response", description)}
                  onBlur={handleBlur}
                  users={null}
                  value={values.response}
                />
                <FieldError errorMessage={errors.response as string} isVisible={(errors.response && touched.response) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="caseAssetAttributes.assetAttributes.file">
                  {t("task:fileUpload")}:
                </Label>
                <FileUploadField
                  name="caseAssetAttributes.assetAttributes.file"
                  onChange={(event) => {
                    setFieldValue("caseAssetAttributes.assetAttributes.file", event.currentTarget.files[0])
                  }}
                />
                <FieldError errorMessage={errors?.caseAssetAttributes?.assetAttributes?.file as string} isVisible={(errors?.caseAssetAttributes?.assetAttributes?.file && touched.caseAssetAttributes?.assetAttributes?.file) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="caseAssetAttributes.assetAttributes.name">
                  {t("task:fileTitle")}:
                </Label>
                <InputField
                  name={"caseAssetAttributes.assetAttributes.name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.caseAssetAttributes?.assetAttributes.name}>
                </InputField>
                <FieldError errorMessage={errors?.caseAssetAttributes?.assetAttributes?.name as string} isVisible={(errors?.caseAssetAttributes?.assetAttributes?.name && touched.caseAssetAttributes?.assetAttributes?.name) as boolean} />
              </div>

              <div className="my-6">
                <Label name="markedTaskAsComplete" className="flex items-center cursor-pointer p-2 rounded-md bg-ch-blue-100 justify-center">
                  <span className="mr-2 font-semibold">
                    {t("task:markTaskAsComplete")}:
                  </span>
                  <CheckmarkToggle name="markedTaskAsComplete" value={values.markedTaskAsComplete} />
                </Label>
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("task:addResponse")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddResponseForm
