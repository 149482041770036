import { normalize } from "normalizr"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { fetchMeetingSchema } from "../../../../apis/contextmeeting/schema"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { MeetingActionTypes } from "../meetingTypes"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"

export function* startMeetingAsync(action: Effect): Generator {
  try {
    const meetingId = action.payload
    const response: any = yield call(API.post, `/meetings/${meetingId}/start`, {}, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: MeetingActionTypes.START_MEETING_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "notPossibleToStartMeeting",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchStartMeetingAsync(): Generator {
  yield takeLeading(MeetingActionTypes.START_MEETING_ASYNC_PENDING, startMeetingAsync)
}
