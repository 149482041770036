import React, { createContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { getIsYourViewAssetListOpen, getYourViewSelectedAssetId } from "../../../../shared/selectors/ui"
import { updateYourViewSelectedAssetAction } from "../../state/liveMeetingActions"
import LiveMeetingAssetSelectHeading from "../assets/select/LiveMeetingAssetSelectHeading"
import LiveMeetingAssetsContainer from "../assets/LiveMeetingAssetsContainer"
import LiveMeetingAssetViewerCloseButton from "../assets/viewer/LiveMeetingAssetViewerCloseButton"
import LiveMeetingSelectedAssetHeading from "../assets/viewer/LiveMeetingSelectedAssetHeading"
import LiveMeetingAssetList from "../assets/LiveMeetingAssetList"
import LiveMeetingYourViewAssetViewer from "./LiveMeetingYourViewAssetViewer"
import ScribeRecommendation from "../shared/ScribeRecommendation"
import { getSelectedMeetingCaseId } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IAppState } from "../../../../app/appTypes"
import { doesAssetUseViewer } from "../../../../shared/helpers/assetHelpers"

interface IProps {
  meetingId: string
}

export const YourViewAssetTypeFilter = createContext<IAssetTypeFilters>(null)

const LiveMeetingYourViewAssets: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const dispatch = useDispatch()
  const [assetTypeFilters, setAssetTypeFilters] = React.useState<IAssetTypeFilters>(null)
  const isAssetListOpen = useSelector(getIsYourViewAssetListOpen)
  const selectedAssetId = useSelector(getYourViewSelectedAssetId)
  const selectedMeetingCaseId = useSelector(getSelectedMeetingCaseId)
  const selectedAsset = useSelector((state: IAppState) => getAssetById(state, { assetId: selectedAssetId }))
  const meetingCaseId = useSelector(getSelectedMeetingCaseId)
  const clearYourViewSelectedAsset = () => { dispatch(updateYourViewSelectedAssetAction(null)) }

  useEffect(clearYourViewSelectedAsset, [selectedMeetingCaseId])

  const renderAssetSelect = (): JSX.Element => (
    <LiveMeetingAssetsContainer hasDarkBackground={isAssetListOpen}>
      <div className="flex justify-between mb-2">
        <LiveMeetingAssetSelectHeading
          openText={t("liveMeeting:selectItemToView")}
        />
      </div>
      <div className="flex-1 overflow-y-scroll pr-4">
        <LiveMeetingAssetList
          selectAction={updateYourViewSelectedAssetAction}
          assetTypeFilters={assetTypeFilters}
          setAssetTypeFilters={setAssetTypeFilters} />
      </div>
      <ScribeRecommendation meetingId={props.meetingId} />
    </LiveMeetingAssetsContainer>
  )

  const renderAssetViewer = (): JSX.Element => {
    return (
      <LiveMeetingAssetsContainer>
        <div className="flex justify-end mb-2">
          <LiveMeetingSelectedAssetHeading assetId={selectedAssetId} />
          <LiveMeetingAssetViewerCloseButton clearSelectedAsset={clearYourViewSelectedAsset} />
        </div>
        <div className="flex-1 overflow-y-scroll pr-4">
          <LiveMeetingYourViewAssetViewer assetId={selectedAssetId} />
        </div>
      </LiveMeetingAssetsContainer>
    )
  }

  const selectedAssetUsesViewer = doesAssetUseViewer(selectedAsset)

  const renderAssetOrSelect = (): JSX.Element => {
    if (!meetingCaseId) {
      return <div className="p-2 w-full">{t("liveMeeting:yourViewAssetsInstructions")}</div>
    }
    if (selectedAsset && selectedAssetUsesViewer) { return renderAssetViewer() }
    return renderAssetSelect()
  }

  return (
    <div className="flex flex-1 overflow-hidden">
      <YourViewAssetTypeFilter.Provider value={assetTypeFilters}>
        {renderAssetOrSelect()}
      </YourViewAssetTypeFilter.Provider>
    </div>
  )
}

export default LiveMeetingYourViewAssets
