import _ from "lodash"
import React, { useState } from "react"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import { getCaseById } from "../../../../shared/selectors/case"
import CaseMeetingUserMeetingListItem from "./CaseMeetingUserMeetingListItem"
import { getMeetingsByIdsAsArray } from "../../../../shared/selectors/meeting"
import TextLink from "../../../../shared/components/text/TextLink"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"

interface IProps {
  userId: string
  caseId: string
  meetingIds: string[]
}

const CaseMeetingUserListItem: React.FC<IProps> = (props): JSX.Element => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const meetings = useSelector((state: IAppState) => getMeetingsByIdsAsArray(state, props))

  const [isFullList, toggleIsFullList] = useState(false)
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))

  const userMeetings = meetings.filter(meeting => theCase.meetings.includes(meeting.id) &&
    meeting.users &&
    meeting.users.includes(props.userId))

  const maxDisplayLength = 1

  if (!user || !theCase || !meetings) return null
  const renderMeetingList = (): JSX.Element => {
    if (userMeetings.length <= maxDisplayLength) {
      return (
        <ul>
          {_.map(userMeetings, (meeting) => {
            return <CaseMeetingUserMeetingListItem key={meeting.id} meetingId={meeting.id} />
          })}
        </ul>
      )
    } else {
      const meetingsToRender = isFullList ? userMeetings : userMeetings.slice(0, maxDisplayLength)
      const transKey = isFullList ? "case:hideMeetings" : "case:viewMoreMeetings"

      return (
        <ul>
          {_.map(meetingsToRender, (meeting) => {
            return <CaseMeetingUserMeetingListItem key={meeting.id} meetingId={meeting.id} />
          })}
          <div className="text-center mt-2 lg:text-left lg:ml-8">
            <TextLink
              action={() => toggleIsFullList(!isFullList)}
              className={"inline-flex"}
              variant={StyleVariants.BLUE}
            >
              <Trans
                i18nKey={transKey}
                values={{
                  number: userMeetings.length - maxDisplayLength
                }}
              />
            </TextLink>
          </div>
        </ul>
      )
    }
  }

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <UserDetailsCard user={user} />
        </DetailsCardInner>

        <DetailsCardRight className="md:w-1/2">
          {renderMeetingList()}
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default CaseMeetingUserListItem
