import React, { useState } from "react"
import classNames from "classnames"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import Card from "../../../../shared/components/layout/Card"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { getPatientById } from "../../../../shared/selectors/patients"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"

interface IProps {
  patientId: string
}

const FurtherDetails: React.FC<IProps> = (props): JSX.Element => {
  const patient = useSelector((state: IAppState) => getPatientById(state, props))
  const [isVisible, toggleIsVisible] = useState(false)
  const { t } = useTranslation(["shared", "patient"])

  if (!patient) return null
  const renderPatientAddress = (): JSX.Element => {
    if (!patient.furtherDetails?.addressLine1) return (
      <dd>
        {t("patient:details:furtherDetails:address")}: -
      </dd>
    )
    const fullAddress: string = _.reject([
      patient.furtherDetails.addressLine1,
      patient.furtherDetails.addressLine2,
      patient.furtherDetails.city,
      patient.furtherDetails.county,
      patient.furtherDetails.country
    ], _.isEmpty).join(", ")
    return (
      <dd>
        {t("patient:details:furtherDetails:address")}: {fullAddress}
      </dd>
    )
  }

  const renderGPAddress = (): JSX.Element => {
    if (!patient.furtherDetails?.gpAddressLine1) return (
      <dd>
        {t("patient:details:furtherDetails:gpAddress")}: -
      </dd>
    )
    const fullAddress: string = _.reject([
      patient.furtherDetails.gpAddressLine1,
      patient.furtherDetails.gpAddressLine2,
      patient.furtherDetails.gpCity,
      patient.furtherDetails.gpCounty,
      patient.furtherDetails.gpCountry
    ], _.isEmpty).join(", ")
    return (
      <dd>
        {t("patient:details:furtherDetails:gpAddress")}: {fullAddress}
      </dd>
    )
  }

  const renderDetail = (name: string): JSX.Element => {
    const furtherDetails = patient.furtherDetails || {}
    if (!furtherDetails[name]) return (
      <dd>
        {t(`patient:details:furtherDetails:${name}`)}: -
      </dd>
    )
    return (
      <dd>
        {t(`patient:details:furtherDetails:${name}`)}: {furtherDetails[name]}
      </dd>
    )
  }

  const renderEditButton = (): JSX.Element => {
    if (!patient.permissions.canEdit) return null
    const editPatientFurtherDetailsUrl = generatePath(RoutePaths.PATIENT_EDIT_FURTHER_DETAILS, {
      patientId: props.patientId
    })

    return (
      <Button
        href={editPatientFurtherDetailsUrl}
        variant={StyleVariants.PURPLE}
        key="2"
        className="mb-2"
      >
        {t("shared:editDetails")}
      </Button>
    )
  }

  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("patient:furtherDetails:sectionTitle")}
          </h3>
          {renderEditButton()}
        </div>
        <div className="flex mb-2 justify-end">
          <p className="flex-1 pr-10 lg:pr-24">
            {t("patient:furtherDetails:info")}
          </p>
          <ButtonExpandCollapse isExpanded={isVisible} action={() => toggleIsVisible(!isVisible)} expandText={t("patient:furtherDetails:show")} collapseText={t("patient:furtherDetails:hide")} />
        </div>

        <div className={classNames({ hidden: !isVisible })}>
          <div className="flex-1 lg:grid grid-cols-2 gap-3">
            <dl className="col-span-1">
              <dt>
                <h4 className="font-semibold">{t("patient:furtherDetails:patientSectionTitle")}</h4>
              </dt>
              {renderPatientAddress()}
              {renderDetail("postcode")}
              {renderDetail("email")}
              {renderDetail("phoneNumber")}
              {renderDetail("phoneNumberAlt")}
            </dl>

            <dl className="col-span-1">
              <dt>
                <h4 className="font-semibold">{t("patient:furtherDetails:gPSectionTitle")}</h4>
              </dt>
              {renderDetail("gpName")}
              {renderGPAddress()}
              {renderDetail("gpPostcode")}
              {renderDetail("gpEmail")}
              {renderDetail("gpPhoneNumber")}
            </dl>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default FurtherDetails
