import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { ChatActionTypes, IFetchChatAssetsAsyncAction } from "../chatTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { messageAssetsSchema } from "../../../../apis/contextmeeting/schema"

export function* fetchChatAssetsAsync(action: IFetchChatAssetsAsyncAction): Generator {
  try {
    const chatId = action.payload

    const response: any = yield call(API.get, `/chats/${chatId}/assets`, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [messageAssetsSchema])

    yield put({
      type: ChatActionTypes.FETCH_CHAT_ASSETS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        chatId: chatId
      }
    })

  } catch (error) {
    yield put({
      type: ChatActionTypes.FETCH_CHAT_ASSETS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "chatAssetsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchChatAssetsAsync(): Generator {
  yield takeLeading(ChatActionTypes.FETCH_CHAT_ASSETS_ASYNC_PENDING, fetchChatAssetsAsync)
}
