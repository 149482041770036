// State
export interface IUploadTermsFileFormValues {
  file: File
}

export interface IUploadTenantLogoFormValues {
  file: File
}

// Actions
export interface IUploadTermsFileAsyncAction {
  type: string,
  payload: {
    values: IUploadTermsFileFormValues
  }
}

export interface IUploadTenantLogoAsyncAction {
  type: string,
  payload: {
    values: IUploadTenantLogoFormValues
  }
}

// Action Types
export enum AdminActionTypes {
  UPLOAD_TERMS_FILE_ASYNC_PENDING = "@@admin/UPLOAD_TERMS_FILE_ASYNC_PENDING",
  UPLOAD_TERMS_FILE_ASYNC_SUCCESS = "@@admin/UPLOAD_TERMS_FILE_ASYNC_SUCCESS",
  UPLOAD_TERMS_FILE_ASYNC_ERROR = "@@admin/UPLOAD_TERMS_FILE_ASYNC_ERROR",

  UPLOAD_TENANT_LOGO_ASYNC_PENDING = "@@admin/UPLOAD_TENANT_LOGO_ASYNC_PENDING",
  UPLOAD_TENANT_LOGO_ASYNC_SUCCESS = "@@admin/UPLOAD_TENANT_LOGO_ASYNC_SUCCESS",
  UPLOAD_TENANT_LOGO_ASYNC_ERROR = "@@admin/UPLOAD_TENANT_LOGO_ASYNC_ERROR",
}
