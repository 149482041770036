import React from "react"
import ChatHeader from "./header/ChatHeader"
import MessageList from "../../messages/components/list/MessageList"
import MessageNewForm from "../../message/components/MessageNewForm"
import { useTranslation } from "react-i18next"

interface Props {
  chatId: string
}

const ChatView: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])

  if(!props.chatId) return null

  const renderMessageList = (): JSX.Element => {
    if(!props.chatId) return (
      <span className="flex justify-center text-ch-blue-alt-300 italic text-sm p-4">
        {t("message:index:selectAChat")}
      </span>
    )
    return <MessageList chatId={props.chatId} key={props.chatId} />
  }

  return (
    <div className="flex flex-1 flex-col h-full overflow-hidden">
      <div className="px-4 pt-4 pb-2 border-b border-ch-gray-400">
        <ChatHeader
          chatId={props.chatId}
          key={props.chatId}
        />
      </div>
      <div className="flex-1 h-full overflow-hidden">
        { renderMessageList() }
      </div>
      <div className="p-4 border-t border-ch-gray-400">
        <MessageNewForm chatId={props.chatId} key={props.chatId} />
      </div>
    </div>
  )
}

export default ChatView
