import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { acceptTermsAction, fetchTermsAction, signOutUserAction } from "../../features/account/state/accountActions"
import TermsPdfViewer from "../../features/account/components/TermsPdfViewer"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import { getCurrentUser, getTerms } from "../../shared/selectors/user"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { RoutePaths } from "../../app/routes/Routes"
import history from "../../app/routes/history"
import { useSelector } from "react-redux"

const TermsPage = (): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const terms = useSelector(getTerms)
  const currentUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const fetchTerms = () => dispatch(fetchTermsAction())
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const acceptTerms = (version: number) => dispatch(acceptTermsAction({ version: version }))

  useEffect(() => {
    fetchTerms(),
      fetchUsers()
  }, [])

  const onClickSignOut = () => {
    dispatch(signOutUserAction())
    history.push(RoutePaths.SIGN_IN)
  }

  if (!terms || !currentUser) return null
  return (
    <React.Fragment>
      <div>
        <h4 className="text-center text-ch-blue-alt-400">
          {t("shared:acceptTheTermsAndConditions")}
        </h4>
        <h4 className="text-center text-ch-blue-alt-400 pb-2">
          {currentUser.email}
        </h4>
      </div>
      <div className="flex-1 overflow-hidden mb-6">
        <TermsPdfViewer terms={terms} />
      </div>
      <div className="flex justify-center">
        <div>
          <Button
            action={() => acceptTerms(terms.version)}
            variant={StyleVariants.PURPLE}
            isChunky={true}
            key="1"
          >
            {t("shared:acceptTerms")}
          </Button>
        </div>
        <div className="flex items-center">
          <div className="pl-1 pr-1">
            {t("shared:or")}
          </div>
          <a
            className="text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer -mr-2"
            onClick={() => onClickSignOut()}
          >
            {t("shared:signout")}
          </a>
        </div>
      </div>
    </ React.Fragment>
  )
}

export default TermsPage
