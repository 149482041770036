import { IAppState } from "../../../app/appTypes"
import { IAddCaseToMeetingUIState } from "../../../features/addCaseToMeeting/state/addCaseToMeetingTypes"
import ICasesUIState from "../../../features/cases/state/casesTypes"
import IChatsUIState from "../../../features/chats/state/chatsTypes"
import { IMeetingsUIState } from "../../../features/meetings/state/meetingsTypes"
import IChatMessagesUIState from "../../../features/messages/state/messagesTypes"
import INotificationsUIState from "../../../features/notifications/state/notificationsTypes"
import { IPatientNewUIState } from "../../../features/patientNew/state/patientNewTypes"
import IPatientsUIState from "../../../features/patients/state/patientsTypes"
import { IScrollUIState } from "../../../features/scroll/state/scrollTypes"
import ITasksUIState from "../../../features/tasks/state/tasksTypes"
import ITeamsUIState from "../../../features/teams/state/teamsTypes"
import IUsersUIState from "../../../features/users/state/usersTypes"
import { IPathwaysUIState } from "../../../features/pathways/state/pathwaysTypes"
import { IAddPathwayToCaseUIState } from "../../../features/addPathwayToCase/state/addPathwayToCaseTypes"
import { IUserCheckUIState } from "../../../features/user/state/userTypes"

export const getSelectedUserViewTab = (state: IAppState): number => state.ui.liveMeeting.selectedUsersViewTab

export const getSelectedYourViewTab = (state: IAppState): number => state.ui.liveMeeting.selectedYourViewTab

export const getIsSplitScreen = (state: IAppState): boolean => state.ui.liveMeeting.isSplitScreen

export const getIsYourViewAssetListOpen = (state: IAppState): boolean => state.ui.liveMeeting.isYourViewAssetListOpen

export const getYourViewSelectedAssetId = (state: IAppState): string => state.ui.liveMeeting.yourViewSelectedAssetId

export const getIsMenuOpen = (state: IAppState): boolean => state.ui.nav?.isMenuOpen

export const getIsQuickViewOpen = (state: IAppState): boolean => state.ui.nav?.isQuickViewOpen

export const getQuickViewResourceId = (state: IAppState): string => state.ui.nav?.quickViewResourceId

export const getMeetingsUI = (state: IAppState): IMeetingsUIState => state.ui.meetings

export const getCasesUI = (state: IAppState): ICasesUIState => state.ui.cases

export const getUsersUI = (state: IAppState): IUsersUIState => state.ui.users

export const getTeamsUI = (state: IAppState): ITeamsUIState => state.ui.teams

export const getNotificationsUI = (state: IAppState): INotificationsUIState => state.ui.notifications

export const getTasksUI = (state: IAppState): ITasksUIState => state.ui.tasks

export const getPatientsUI = (state: IAppState): IPatientsUIState => state.ui.patients

export const getPathwaysUI = (state: IAppState): IPathwaysUIState => state.ui.pathways

export const getAddPathwayToCaseUI = (state: IAppState): IAddPathwayToCaseUIState => state.ui.addPathwayToCase

export const getChatsUI = (state: IAppState): IChatsUIState => state.ui.chats

export const getChatMessagesUI = (state: IAppState): IChatMessagesUIState => state.ui.messages

export const getAddCaseToMeetingUI = (state: IAppState): IAddCaseToMeetingUIState => state.ui.addCaseToMeeting

export const getPatientNewUI = (state: IAppState): IPatientNewUIState => state.ui.patientNew

export const getUserCheckUI = (state: IAppState): IUserCheckUIState => state.ui.userCheck

export const getScrollUI = (state: IAppState): IScrollUIState => state.ui.scroll
