import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { CaseActionTypes } from "../caseTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { fetchCaseSchema } from "../../../../apis/contextmeeting/schema"
import { ICreateCaseGuestUserAction } from "../caseTypes"

export function* createCaseGuestUserAsync(action: ICreateCaseGuestUserAction): Generator {
  try {
    const caseId = action.payload.caseId
    const data = {
      user: action.payload.guestUserData
    }
    const response: any = yield call(API.post, `/cases/${caseId}/guest_users`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchCaseSchema)

    yield put({
      type: CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createCaseGuestUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createCaseGuestUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateCaseGuestUserAsync(): Generator {
  yield takeLeading(CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_PENDING, createCaseGuestUserAsync)
}
