import React, { useState, useEffect, useRef } from "react"
import * as Yup from "yup"
import i18n from "../../app/i18n"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import InputField from "../../shared/components/forms/InputField"
import Buttons from "../../shared/components/layout/Buttons"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useDispatch } from "react-redux"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import { IPhoneDetails } from "../../features/account/state/accountTypes"
import history from "../../app/routes/history"
import { RoutePaths } from "../../app/routes/Routes"
import { fetchCurrentUserAction } from "../../features/user/state/userActions"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"
import classNames from "classnames"

interface IPropsFromParent {
  phoneDetails: IPhoneDetails
  backToIntro: () => void
}

const codeValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    code: Yup.string()
      .required(i18n.t("shared:required"))
      .min(6)
  })
}

const SetupMfaTotpPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["account"])
  const [smsSent, setSmsSent] = useState<boolean>(false)
  const inputRef = useRef(null)
  const dispatch = useDispatch()

  const submitCode = (code: string, isSubmitting: boolean, setSubmitting: (state: boolean) => void) => {
    if (isSubmitting) return
    API.post("/user/mfa/verify_sms", { code }, withAuthHeader()).then(() => {
      history.push(RoutePaths.SETUP_MFA_SMS_COMPLETE)
      dispatch(fetchCurrentUserAction())
    }).catch(() => {
      setSubmitting(false)
      inputRef.current.focus()
      dispatch(
        displayUserMessageAction({
          messageKey: "mfaInvalidCode",
          type: UserMessageTypes.ERROR
        })
      )
    })
  }

  const requestSms = () => {
    setSmsSent(false)
    API.post("/user/mfa/register_sms", { user: props.phoneDetails }, withAuthHeader())
      .then(() => {
        setTimeout(() => setSmsSent(true), 1000)
      })
      .catch((error) => {
        props.backToIntro()
        if (error.status == 400) {
          dispatch(
            displayUserMessageAction({
              messageKey: "mfaPhoneNumberAlreadyInUse",
              type: UserMessageTypes.ERROR
            })
          )
        } else {
          dispatch(
            displayUserMessageAction({
              messageKey: "mfaErrorSendingSms",
              type: UserMessageTypes.ERROR
            })
          )
        }
      })
  }

  useEffect(() => {
    if (smsSent) return
    const timeout = setTimeout(requestSms, 100)
    return () => { clearTimeout(timeout) }
  }, [])

  useEffect(() => {
    if (!smsSent) return
    inputRef.current.focus()
  }, [smsSent])

  return (
    <div className="flex flex-col items-center justify-center text-center ">
      <div className="text-ch-blue-500 text-xl mb-3">
        {t("mfaSmsSetupHeader", props.phoneDetails)}
      </div>
      <div className="mb-3 w-full  md:w-1/2 ">
        {t("mfaSmsSetupExplanation")}
      </div>

      <div className={classNames("mb-3 w-full md:w-1/2 mx-auto", { hidden: !smsSent })}>
        <Formik
          initialValues={{ code: "" }}
          onSubmit={null}
          validationSchema={codeValidationSchema}
          validateOnMount
        >
          {({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            setSubmitting,
            isValid
          }) => (
            <Form>
              <div className="m-auto w-full md:w-1/3 mb-5">
                <InputField className="text-xl text-center" innerRef={inputRef} autoComplete="off" name="code" onChange={handleChange} onBlur={handleBlur} />
              </div>

              <Buttons
                className="flex-row-reverse space-x-reverse space-x-2"
                buttons={[
                  <Button
                    key="2"
                    isDisabled={values.code == "" || !isValid || isSubmitting}
                    variant={StyleVariants.BLUE}
                    action={() => { submitCode(values.code, isSubmitting, setSubmitting) }}
                  >
                    {t("submit")}
                  </Button>,
                  <Button
                    key="1"
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    action={props.backToIntro}
                  >
                    {t("back")}
                  </Button>
                ]} />
            </Form>
          )
          }
        </Formik>
      </div>
      <div className={classNames({ hidden: smsSent })}>
        {t("mfaSmsSending")}
        <LoadingSpinner />
      </div>
    </div >
  )
}

export default SetupMfaTotpPage
