import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconGrid({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 7.257h6.452V0H0zm8.871 0h6.452V0H8.87zm8.872 0h6.45V0h-6.45zM0 16.128h6.452V8.87H0zm8.871.001h6.452V8.871H8.87zm8.872-.001h6.45V8.87h-6.45zM0 25h6.452v-7.258H0zm8.871 0h6.452v-7.258H8.87zm8.872 0h6.45v-7.258h-6.45z"
      />
    </svg>
  );
}

export default SvgIconGrid;
