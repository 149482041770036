import React, { useState } from "react"
import { useSelector } from "react-redux"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../shared/components/layout/detailsCard/DetailsCardButtons"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconPathway } from "../../../svg/icons"
import { useTranslation } from "react-i18next"
import { IPathway } from "../../pathway/pathwayTypes"
import PathwayDetails from "../../pathway/components/details/PathwayDetails"
import { getPathway } from "../../../shared/selectors/pathway"
import AddPathwayToCaseButton from "./AddPathwayToCaseButton"
import ButtonExpandCollapse from "../../../shared/components/buttons/ButtonExpandCollapse"
import classNames from "classnames"
import AddPathwayToCaseStepList from "./AddPathwayToCaseStepList"

type Props = {
  pathwayId: string
  caseId: string
  closeModal: () => void
}

export const AddPathwayToCaseListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector(getPathway(props))
  const [isExpanded, setIsExpanded] = useState(false)

  if (!pathway) return null

  return <li>
    <div className="border border-ch-gray-400 rounded-md p-4 mb-2">
      <div className="flex items-center flex-wrap justify-center lg:justify-between md:flex-nowrap mb-2">
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconPathway className="fill-current w-6 h-6" />
          </IconCircleContainer>
        <PathwayDetails pathwayId={props.pathwayId} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[
              <AddPathwayToCaseButton
                key="1"
                pathwayId={props.pathwayId}
                caseId={props.caseId}
                closeModal={props.closeModal}
              />,
              <ButtonExpandCollapse
                key="2"
                isExpanded={isExpanded}
                action={() => setIsExpanded(!isExpanded)}
                expandText={t("pathway:addPathwayToCase:buttons:showSteps")}
                collapseText={t("pathway:addPathwayToCase:buttons:hideSteps")}
                className="ml-2"
              />
            ]}
          />
        </DetailsCardRight>
      </div>
      <div className={classNames({
        "hidden": !isExpanded
      })}>
        <AddPathwayToCaseStepList steps={pathway.steps} />
      </div>
    </div>
  </li>
}

export default AddPathwayToCaseListItem
