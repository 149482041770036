import React from "react"
import { useTranslation } from "react-i18next"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import { IconStep } from "../../../svg/icons"
import StepDetails from "../../pathway/components/details/StepDetails"
import { IPathwayStep } from "../../pathway/pathwayTypes"

interface Props {
  steps: IPathwayStep[]
}

const AddPathwayToCaseStepList = (props: Props): JSX.Element => {
  const { t } = useTranslation(["pathway"])

  if (!props.steps) return (
    <h3 className="text-lg font-bold text-center">
      { t("pathway:addPathwayToCase:noSteps") }
    </h3>
  )

  return (
    <div>
      {props.steps.map((step: IPathwayStep) => {
        return (
          <DetailsCard className="bg-ch-gray-300 rounded-md p-4 text-sm" key={step.position + step.name} hasBorder={false}>
            <DetailsCardInner>
              <div className="flex flex-1 items-center">
                <IconCircleContainer className="sm:mr-4" sizeClasses={["w-7", "h-7"]}>
                  <IconStep className="fill-current w-7 h-7" />
                </IconCircleContainer>
                <StepDetails step={step} />
              </div>
            </DetailsCardInner>
          </DetailsCard>
        )
      })}
    </div>
  )
}

export default AddPathwayToCaseStepList
