import React from "react"
import { useTranslation } from "react-i18next"
import { LogoFull } from "../svg/branding"

const TermsLayout: React.FC = ({ children }): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const defaultClasses = "flex bg-ch-blue-alt-200 h-screen justify-center p-3 md:p-12"

  return (
    <div className={defaultClasses}>
      <div className="bg-white shadow-md rounded-md border border-ch-blue-alt-300 p-8 w-full sm:w-4/5 h-full justify-between relative flex flex-col">
        <div className="flex items-center p-1 justify-center mb-2">
          <LogoFull
            className="w-64"
            title={t("shared:contextMeeting")}
          />
          <h1 className="text-ch-blue-600 hidden">
            {t("shared:contextMeeting")}
          </h1>
        </div>

        {children}

      </div>
    </div>
  )
}

export default TermsLayout
