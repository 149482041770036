import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { IAuditEvent } from "../../../audit/state/auditEventTypes"
import AuditEventSubCard from "./AuditEventSubCard"

interface IProps {
  auditEvent: IAuditEvent
}

const AuditEventUserEditedCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])

  const getTitle = () => {
    const title = props.auditEvent.eventData.changes.title

    if (!title) return null
    return (<AuditEventSubCard
      title={t("auditEvent:titleChanged")}
      originalProp={title[0]}
      changedProp={title[1]} />
    )
  }

  const getProfessionalTitle = () => {
    const professionalTitle = props.auditEvent.eventData.changes.professionalTitle

    if (!professionalTitle) return null
    return (<AuditEventSubCard
      title={t("auditEvent:professionalTitleChanged")}
      originalProp={professionalTitle[0]}
      changedProp={professionalTitle[1]} />
    )
  }

  const getFirstName = () => {
    const firstName = props.auditEvent.eventData.changes.firstName

    if (!firstName) return null
    return (<AuditEventSubCard
      title={t("auditEvent:firstNameChanged")}
      originalProp={firstName[0]}
      changedProp={firstName[1]} />
    )
  }

  const getLastName = () => {
    const lastName = props.auditEvent.eventData.changes.lastName

    if (!lastName) return null
    return (<AuditEventSubCard
      title={t("auditEvent:lastNameChanged")}
      originalProp={lastName[0]}
      changedProp={lastName[1]} />
    )
  }

  const getEmail = () => {
    const email = props.auditEvent.eventData.changes.email

    if (!email) return null
    return (<AuditEventSubCard
      title={t("auditEvent:emailChaged")}
      originalProp={email[0]}
      changedProp={email[1]} />
    )
  }

  const getRoles = () => {
    const roles = props.auditEvent.eventData.changes.roles
    const getRolesString = (roles: string) => {
      const translatedRoles = _.map(roles, (role) => {
        return t(`auditEvent:${role}`)
      })

      return _.join(translatedRoles, ", ")
    }

    if (!roles) return null
    return (<AuditEventSubCard
      title={t("auditEvent:rolesChaged")}
      originalProp={getRolesString(roles[0])}
      changedProp={getRolesString(roles[1])} />
    )
  }

  return (
    <>
      {getTitle()}
      {getProfessionalTitle()}
      {getFirstName()}
      {getLastName()}
      {getEmail()}
      {getRoles()}
    </>
  )
}

export default AuditEventUserEditedCard
