/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import _ from "lodash"
import { ISelectFieldOption, ISelectFieldUserOption } from "./types"
import Select from "react-select"
import { Field } from "formik"
import { SelectComponentsProps } from "react-select/src/Select"
import { Option } from "react-select/src/filters"

type Props = {
  name: string
  options: ISelectFieldOption[] | ISelectFieldUserOption[]
  isDisabled?: boolean
  isMulti?: boolean
  isGrouped?: boolean
  isClearable?: boolean
  closeMenuOnSelect?: boolean
  placeholder?: string
  filterOption?: ((option: Option, text: string) => boolean) | null
  onChange: (e: React.ChangeEvent<any>) => void
  onBlur: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEvent<any>) => void
  formatOptionLabel?: (props: any) => JSX.Element
  components?: any
  value?: any
}

const defaultProps = {
  isDisabled: false,
  isMulti: false,
  isGrouped: false,
  isClearable: false,
  closeMenuOnSelect: true
}

const SelectField = (props: Props): JSX.Element => {
  return (
    <Field
      component={ReactSelectField}
      name={props.name}
      id={props.name}
      options={props.options}
      isGrouped={props.isGrouped}
      isClearable={props.isClearable}
      closeMenuOnSelect={props.closeMenuOnSelect}
      isDisabled={props.isDisabled}
      isMulti={props.isMulti}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      formatOptionLabel={props.formatOptionLabel}
      components={props.components}
      filterOption={props.filterOption}
      value={props.value}
    />
  )
}

SelectField.defaultProps = defaultProps

export default SelectField

const ReactSelectField: React.FC<SelectComponentsProps> = (props: SelectComponentsProps): JSX.Element => {

  // Add custom styles here
  const customStyles = {
    option: (provided, _state) => ({
      ...provided
    }),
    control: (provided, _state) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "2px",
      marginBottom: "0.25rem"
    })
  }

  const flattenGroupedOptions = (options) => {
    return _.flatten(_.map(options, "options"))
  }

  const onChange = (option: Option | Option[]) => {
    const value = (props.isMulti)
      ? (option as Option[]).map((item: Option) => item.value)
      : (option as Option)?.value

    props.form.setFieldValue(props.field.name, value)

    // No React.ChangeEvent
    // https://github.com/JedWatson/react-select/issues/1631
    props.onChange({
      currentTarget: {
        name: props.field.name,
        value: value
      }
    })
  }

  const getValue = () => {

    let options = props.options
    if (options?.length) {
      if (props.isGrouped) {
        options = flattenGroupedOptions(props.options)
      }
      return props.isMulti
        ? options.filter(option => props.field.value.indexOf(option.value) >= 0)
        : (options.find(option => option.value === props.field.value) || "")
    } else {
      return props.isMulti ? [] : ("" as any)
    }
  }

  return (
    <Select
      options={props.options}
      inputId={props.field.name}
      name={props.field.name}
      value={getValue()}
      isClearable={props.isClearable}
      closeMenuOnSelect={props.closeMenuOnSelect}
      isDisabled={props.isDisabled}
      isMulti={props.isMulti}
      placeholder={props.placeholder}
      onChange={onChange}
      onBlur={props.field.onBlur}
      onFocus={props.onFocus}
      styles={customStyles}
      components={props.components}
      filterOption={props.filterOption}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#E0E0E0",
          primary50: "#ADADAD",
          primary: "#A3C3C4",
          neutral20: "#A3C3C4",
          neutral30: "#75A7A9",
          danger: "#C20000",
          dangerLight: "#FAE8D8"
        },
      })}
    />
  )
}
