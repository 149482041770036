// Models

export interface IChatNewUserData {
  userId: string
}

export interface IChatNewMessageData {
  body: string
}

export interface IChatNewFormData {
  message: string
  userIds?: string[]
  meetingId?: string
  teamId?: string
  caseId?: string
  isTeamChat: boolean
}

export interface IChatNewData {
  type: string
  messagesAttributes: IChatNewMessageData[]
  chatUsersAttributes?: IChatNewUserData[]
  meetingId?: string
  teamId?: string
  caseId?: string
}

// Actions
export interface ICreateChatAsyncAction {
  type: string,
  payload: {
    chat: IChatNewData
  }
}

export enum ChatNewActionTypes {
  CREATE_CHAT_ASYNC_PENDING = "@@chats/CREATE_CHAT_ASYNC_PENDING",
  CREATE_CHAT_ASYNC_SUCCESS = "@@chats/CREATE_CHAT_ASYNC_SUCCESS",
  CREATE_CHAT_ASYNC_ERROR = "@@chats/CREATE_CHAT_ASYNC_ERROR",
}
