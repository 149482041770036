import React, { ReactNode } from "react"

interface IProps {
  className?: string
  name: string
  required?: boolean
  children: ReactNode
}

const Label: React.FC<IProps> = (props): JSX.Element => {
  const renderContent = (): JSX.Element => {
    const defaultClasses = ["block", "text-ch-gray-600", "mb-1"]
    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    const required = props.required ? (
      <span className="text-ch-purple-500">*</span>
    ) : null

    return (
      <label htmlFor={props.name} className={allClasses}>
        {props.children}
        {required}
      </label>
    )
  }

  return renderContent()
}

export default Label
