// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require("tailwindcss/defaultTheme")

module.exports = {
  purge: {
    content: ["./src/**/*.{js,jsx,ts,tsx,html}"],
    options: {
      safelist: [
        // whitelist dynamic colours from being purged
        /ch-/
      ]
    }
  },
  theme: {
    minWidth: {
      "48": "12rem",
      "36": "9rem"
    },
    extend: {
      fontFamily: {
        "sans": ["'Open Sans'", ...defaultTheme.fontFamily.sans]
      },
      colors: {
        "ch-gray": {
          "200": "#F7F7F7",
          "300": "#F0F0F0",
          "400": "#E0E0E0",
          "450": "#CECECE",
          "500": "#ADADAD",
          "600": "#515151"
        },
        "ch-gray-alt": {
          "500": "#848484", // ended meeting status text, switch tenant button (ch-gray-500 was too light )
          "600": "#4f4f4f"  // ended meeting status text, switch tenant button
        },
        "ch-blue": {
          "100": "#ECF5F5",
          "200": "#D0E2E3",
          "300": "#C0E9EB",
          "400": "#84D6DA",
          "500": "#13B7BF", // default
          "600": "#0B9298", // headings
          "700": "#587475", // nav link background
          "800": "#455354" // nav background
        },
        "ch-blue-alt": {
          "200": "#F2F8F8", // main app body background
          "300": "#A3C3C4", // text input border
          "400": "#75A7A9", // Filter select boxes, highlighted text
          "500": "#006F74", // quick view buttons
          "600": "#01494C" // not in design
        },
        "ch-orange": {
          "500": "#E2781F", // default
          "600": "#BF5C09"
        },
        "ch-purple": {
          "500": "#730B4D", // default
          "600": "#540537"
        },
        "ch-red": {
          "500": "#C20000"
        },
        "ch-green": {
          "200": "#C1DBA2", // bg for reports
          "300": "#98C465", // recommended labels
          "500": "#10B981",
          "600": "#059669"

        },
        "ch-yellow": {
          "200": "#FAF2DE",
          "500": "#D1B239"
        },
        "sectra-blue": {
          "500": "#2960a8",
          "600": "#1f487e",
        }
      }
    }
  },
  variants: {
    extend: {
      ringWidth: ["hover"],
      borderRadius: ["hover"]
    }
  },
  plugins: [
    require("@tailwindcss/line-clamp"),
  ]
}
