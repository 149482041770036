import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconTask({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="currentColor">
        <path d="M4 4a2 2 0 012-2h8a2 2 0 012 2v9.883l-2.495 2.52-.934-.953a1.5 1.5 0 10-2.142 2.1l.441.45H6a2 2 0 01-2-2V4zm5 5.5a.5.5 0 00.5.5h4a.5.5 0 000-1h-4a.5.5 0 00-.5.5zM9.5 5a.5.5 0 000 1h4a.5.5 0 000-1h-4zM9 13.5a.5.5 0 00.5.5h4a.5.5 0 000-1h-4a.5.5 0 00-.5.5zm-2-3a1 1 0 100-2 1 1 0 000 2zm1-5a1 1 0 10-2 0 1 1 0 002 0zm-1 9a1 1 0 100-2 1 1 0 000 2z" />
        <path d="M17.855 14.852a.5.5 0 00-.71-.704l-3.643 3.68-1.645-1.678a.5.5 0 10-.714.7l1.929 1.968a.6.6 0 00.855.002l3.928-3.968z" />
      </g>
    </svg>
  );
}

export default SvgIconTask;
