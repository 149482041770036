import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { fetchNotificationsAsync, markAllNotififcationsAsRead } from "../../features/notifications/state/notificationsActions"
import INotificationsUIState from "../../features/notifications/state/notificationsTypes"
import { useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import NotificationList from "../../features/notifications/components/list/NotificationList"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { getNotificationsUI } from "../../shared/selectors/ui"
import PageTitle from "../../shared/components/ui/PageTitle"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import Button from "../../shared/components/buttons/Button"
import NotificationsFilter from "../../features/notifications/components/list/NotificationsFilter"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"

const NotificationsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "notification"])
  const dispatch = useDispatch()
  const UI: INotificationsUIState = useSelector((state: IAppState) => getNotificationsUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: ["unread"],
    resultsPerPage: UI.resultsPerPage,
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchNotificationsAsync)

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
  }, [])

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:notifications")}
      />

      <div className="grid grid-flow-row md:grid-flow-col">
        <div className="flex mb-2">
          <NotificationsFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end mb-2">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>

      <div className="flex flex-col items-center sm:flex-row sm:justify-between mb-2">
        <h2 className="text-lg text-center md:text-left">
          {UI.unreadCount} {t("notification:unreadNotifications")}
        </h2>
        <div>
          <Button
            action={() => { dispatch(markAllNotififcationsAsRead()) }}
            variant={StyleVariants.ORANGE}
            isDisabled={!UI.unreadCount}
          >
            {t("notification:markAllAsRead")}
          </Button>
        </div>
      </div>
      <NotificationList refreshNotifications={() => dispatch(fetchNotificationsAsync(searchQuery))} />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalNotificationsCount}
        totalPages={UI.totalPages}
      />
    </div>
  )
}

export default NotificationsPage
