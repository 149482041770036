import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import humps from "humps"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import NameUser from "../../../../shared/components/names/NameUser"
import TextLink from "../../../../shared/components/text/TextLink"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { getPatientById } from "../../../../shared/selectors/patients"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"

interface IProps {
  patientId: string
  hasLink?: boolean
}

const defaultProps = {
  hasLink: true
}

const PatientDetails: React.FC<IProps> = (props: IProps) => {
  const patient = useSelector((state: IAppState) => getPatientById(state, props))
  const { t } = useTranslation(["shared", "patient"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(TenantConfigContext)

  const patientDetailsUrl = generatePath(RoutePaths.PATIENT, {
    patientId: patient.id
  })

  const renderName = (): JSX.Element => {
    const nameText = (
      <NameUser
        firstName={patient.firstName}
        lastName={patient.lastName}
        title={patient.title}
      />
    )

    const renderText = (): JSX.Element => {
      if (!props.hasLink) return nameText
      return (
        <TextLink href={patientDetailsUrl}>
          {nameText}
        </TextLink>
      )
    }

    return (
      <li>
        <h4 className="font-semibold">
          {renderText()}
        </h4>
      </li>
    )
  }

  const renderGender = (): JSX.Element => {
    const translationKey = humps.camelize(patient.gender)
    return (
      <li>
        {t("patient:details:gender")}: {t(`shared:${translationKey}`)}
      </li>
    )
  }

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("patient:details:dateOfBirth")}: <TimeDateOfBirth utcTime={patient.dateOfBirth} />
      </li>
    )
  }

  const renderCreatedBy = (): JSX.Element => {
    return (
      <li>
        {t("shared:createdBy")}: <NameUser
          firstName={patient.createdBy.firstName}
          lastName={patient.createdBy.lastName}
        />
      </li>
    )
  }

  const renderHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {patient.healthcareId || "-"}
      </li>
    )
  }

  const renderCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {patient.careProviderId || "-"}
      </li>
    )
  }

  const renderCasesAssigned = (): JSX.Element => {
    return (
      <li>
        {t("patient:details:casesAssigned")}: {patient.casesCount || 0}
      </li>
    )
  }

  const renderCreatedAt = (): JSX.Element => {
    return (
      <li>
        {t("shared:createdAt")}: <TimeDefaultFormat utcTime={patient.createdAt} />
      </li>
    )
  }

  if (!(patient)) return null
  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        {renderName()}
        {renderGender()}
        {renderDateOfBirth()}
        {renderCreatedBy()}
      </ul>

      <ul className="col-span-1">
        {renderHealthcareId()}
        {renderCareProviderId()}
        {renderCasesAssigned()}
        {renderCreatedAt()}
      </ul>
    </div>
  )
}

PatientDetails.defaultProps = defaultProps

export default PatientDetails
