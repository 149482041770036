import * as Yup from "yup"
import i18n from "../../../../app/i18n"
import { isFilesSizeUnderMaximum, isFileAcceptedType, fileUploadOptions } from "../../../../shared/helpers/fileHelpers"

export const caseFileNewValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(100, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    file: Yup.mixed()
      .test("fileSizeMaximum", i18n.t("shared:fileTooLarge", { maxFileSize: fileUploadOptions.maxFileSizeMB }), (value) => {
        return isFilesSizeUnderMaximum(value)
      })
      .test("acceptedFileType", i18n.t("shared:fileWrongType",  { acceptedFileTypes: fileUploadOptions.acceptedFileTypes }), (value) => {
        return isFileAcceptedType(value)
      })
  })
}
