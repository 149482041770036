import React, { useState } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IAppState } from "../../../../../app/appTypes"
import { getTaskById, getTaskUsers } from "../../../../../shared/selectors/task"
import { IconAccept, IconPathway, IconTask } from "../../../../../svg/icons"
import classNames from "classnames"
import TextAuthorDetails from "../../../../../shared/components/text/TextAuthorDetails"
import { useTranslation } from "react-i18next"
import AssigneesList from "../../../../tasks/components/shared/AssigneesList"
import TimeDefaultFormat from "../../../../../shared/components/time/TimeDefaultFormat"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import Button from "../../../../../shared/components/buttons/Button"
import ResponseList from "../responses/ResponseList"
import Buttons from "../../../../../shared/components/layout/Buttons"
import AssignCasePathwayTaskModal from "../../casePathwayList/taskList/AssignCasePathwayTaskModal"

interface IProps {
  taskId: string
  addResponseAction: () => void
  openAssetModal: (assetId: string) => void
}

const TaskDetails: React.FC<IProps> = (props: IProps) => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const taskAssignees = useSelector((state: IAppState) => getTaskUsers(state, props))

  const [assignTaskModalOpen, setAssignTaskModalOpen] = useState<boolean>(false)
  const openAssignTaskModal = () => { setAssignTaskModalOpen(true) }
  const closeAssignTaskModal = () => { setAssignTaskModalOpen(false) }
  if (!task) return null

  const { t } = useTranslation(["task", "case"])
  const Icon = task.casePathwayId ? IconPathway : IconTask

  const renderButtons = (): JSX.Element => {
    if (task.isComplete) return (
      <span className="text-green-500">
        <IconAccept className="fill-current w-6 h-6" />
      </span>
    )
    if (!task.permissions.canContribute) return null

    const respondButton = (
      <Button
        variant={StyleVariants.BLUE}
        action={props.addResponseAction}
      >
        {t("task:addResponse")}
      </Button>)

    const renderAssignButton = Object.keys(taskAssignees).length < 1 && task.casePathwayId
    let assignButton = null
    let assignModal = null

    if (renderAssignButton) {
      assignButton = <Button action={openAssignTaskModal} variant={StyleVariants.ORANGE}>
        {t("case:casePathways:buttons:assignTask")}
      </Button>

      assignModal = <AssignCasePathwayTaskModal
        isOpen={assignTaskModalOpen}
        closeModal={closeAssignTaskModal}
        casePathwayId={task.casePathwayId}
        taskId={props.taskId}
      />
    }

    return (
      <>
        {assignModal}
        <Buttons buttons={[respondButton, assignButton]} />
      </>
    )
  }

  const renderCompletedTimestamp = (): JSX.Element => {
    if (!task.isComplete) return null
    return (
      <span className="font-semibold">
        {t("task:completedOn")}: <TimeDefaultFormat utcTime={task.completedAt} />
      </span>
    )
  }

  return (
    <div
      className={classNames(
        "p-2",
        "rounded-md",
        {
          "bg-ch-blue-100": !task.isComplete,
          "bg-ch-blue-200": task.isComplete
        }
      )}
    >
      <div className="flex mb-2">
        <div className="text-ch-purple-500 mr-2 mt-1">
          <Icon className="w-5 h-5" title={task.action} />
        </div>
        <div className="flex-1">
          <h4 className="font-semibold">
            {task.action}
          </h4>
          <TextAuthorDetails
            firstName={task.createdBy.firstName}
            lastName={task.createdBy.lastName}
            createdAt={task.createdAt}
          />
          <p>
            {task.description}
          </p>
          <p>
            {t("task:assignedTo")}: <AssigneesList taskId={props.taskId} />
          </p>
          <p>
            {t("task:dueDate")}: {
              task.dueDate ?
                <TimeDefaultFormat utcTime={task.dueDate} />
                : <em>{t("task:dueDateNotSet")}</em>
            }
          </p>
          {renderCompletedTimestamp()}
        </div>
        <div className="flex flex-wrap content-center ml-2">
          {renderButtons()}
        </div>
      </div>
      <ResponseList
        taskId={props.taskId}
        openAssetModal={props.openAssetModal}
      />
    </div>
  )
}

export default TaskDetails
