import React from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"

interface Props {
  caseType: string
}

const TextCaseType: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["case"])

  const translationKey = humps.camelize(props.caseType)
  return (
    <span>
      {t(`case:${translationKey}`)}
    </span>
  )
}

export default TextCaseType
