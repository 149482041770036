import React from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"

interface IProps {
  userId: string;
}

const LiveMeetingUserOnlineStatus: React.FC<IProps> = (props: IProps): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, props))
  const isOnline: boolean = user.config?.isOnline
  return (
    <div className={classNames("w-4", "h-4", "mr-2", "rounded-full", {
      "bg-ch-blue-500": isOnline,
      "bg-ch-gray-400": !isOnline
    })}>
    </div>
  )
}

export default LiveMeetingUserOnlineStatus
