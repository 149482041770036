import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { IconVideoOn, IconVideoOff, IconMicOn, IconMicOff, IconScreenShareOn, IconScreenShareOff } from "../../../../svg/icons"
import { toggleWebRTCPublishAudioAction, toggleWebRTCPublishVideoAction, toggleWebRTCScreenShareIsOnAction } from "../../../webRTC/state/webRTCActions"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import { getWebRTCPublisher } from "../../../../shared/selectors/webRTC"
import LoadingSpinnerSmall from "../../../../shared/components/ui/LoadingSpinnerSmall"
import { useSelector } from "react-redux"

const LiveMeetingUserStreamControls: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserWithConfig)
  const publisher = useSelector(getWebRTCPublisher)

  const onToggleAudio = (userId: string, publisher: OT.Publisher, isSharingAudio: boolean) => {
    dispatch(toggleWebRTCPublishAudioAction(userId, publisher, isSharingAudio))
  }
  const onToggleVideo = (userId: string, publisher: OT.Publisher, isSharingVideo: boolean) => {
    dispatch(toggleWebRTCPublishVideoAction(userId, publisher, isSharingVideo))
  }
  const onToggleScreenShare = (isSharingScreen: boolean) => {
    dispatch(toggleWebRTCScreenShareIsOnAction(isSharingScreen))
  }
  const iconClasses = "fill-current lg:w-6 lg:h-6 w-4 h-4 mb-1"
  const linkClassesWhite = "flex flex-col items-center text-sm lg:text-base text-white hover:text-ch-gray-600"
  const linkClassesGray = "flex flex-col items-center text-sm lg:text-base text-ch-gray-600 hover:text-white"
  const renderVideoControl = (): JSX.Element => {
    if (user.config.isSharingVideo) {
      return (
        <a
          href="#"
          className={linkClassesWhite}
          onClick={() => onToggleVideo(user.id, publisher, false)}
        >
          <IconVideoOn
            className={iconClasses}
            title={t("liveMeeting:stopVideo")}
          />
          <span>{t("liveMeeting:video")}</span>
        </a>
      )
    } else {
      return (
        <a
          href="#"
          className={linkClassesGray}
          onClick={() => onToggleVideo(user.id, publisher, true)}
        >
          <IconVideoOff
            className={iconClasses}
            title={t("liveMeeting:startVideo")}
          />
          <span>{t("liveMeeting:video")}</span>
        </a>
      )
    }
  }

  const renderAudioControl = (): JSX.Element => {
    if (user.config.isSharingAudio) {
      return (
        <a
          href="#"
          className={linkClassesWhite}
          onClick={() => onToggleAudio(user.id, publisher, false)}
        >
          <IconMicOn
            className={iconClasses}
            title={t("liveMeeting:muteAudio")}
          />
          <span>{t("liveMeeting:mic")}</span>
        </a>
      )
    } else {
      return (
        <a
          href="#"
          className={linkClassesGray}
          onClick={() => onToggleAudio(user.id, publisher, true)}
        >
          <IconMicOff
            className={iconClasses}
            title={t("liveMeeting:unmuteAudio")}
          />
          <span>{t("liveMeeting:mic")}</span>
        </a>
      )
    }
  }

  const renderScreenShareControl = (): JSX.Element => {
    if (user.config.isSharingScreen) {
      return (
        <a
          href="#"
          className={linkClassesWhite}
          onClick={() => onToggleScreenShare(false)}
        >
          <IconScreenShareOn
            className={iconClasses}
            title={t("liveMeeting:startShareScreen")}
          />
          <span>{t("liveMeeting:startShareScreen")}</span>
        </a>
      )
    } else {
      if (user.config.isPresenter) {
        return (
          <a
            href="#"
            className={linkClassesGray}
            onClick={() => onToggleScreenShare(true)}
          >
            <IconScreenShareOff
              className={iconClasses}
              title={t("liveMeeting:startShareScreen")}
            />
            <span>{t("liveMeeting:screen")}</span>
          </a>
        )
      } else {
        return (
          <div
            className="flex flex-col items-center text-ch-blue-600"
          >
            <IconScreenShareOff
              className={iconClasses}
              title={t("liveMeeting:mustBePresenterToShareScreen")}
            />
            <span>{t("liveMeeting:screen")}</span>
          </div>
        )
      }
    }
  }

  if (!publisher) return (
    <div className="flex justify-end">
      <LoadingSpinnerSmall className="text-white mr-3" />
      <div className="ml-1">{t("liveMeeting:connecting")}</div>
    </div>
  )

  return (
    <div className="flex justify-around">
      {renderVideoControl()}
      {renderAudioControl()}
      {renderScreenShareControl()}
    </div>
  )

}

export default LiveMeetingUserStreamControls
