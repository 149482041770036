import React from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import IconVideoOn from "../../../../svg/icons/IconVideoOn"

interface IProps {
  userId: string;
}

const LiveMeetingUserMicrophoneStatus: React.FC<IProps> = (props: IProps): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, props))
  if (!user.config?.isOnline) { return null }
  if (user.config?.isSharingVideo) {
    return (
      <IconVideoOn className={classNames("w-4", "h-4", "mx-1", "rounded-full", "text-ch-blue-500")} />
    )
  } else {
    return (
      <IconVideoOn className={classNames("w-4", "h-4", "mx-1", "rounded-full", "text-ch-red-500")} />
    )
  }
}

export default LiveMeetingUserMicrophoneStatus
