import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"
import { toggleIsMenuOpen } from "../state/navActions"
import { MockStore } from "redux-mock-store"

interface IPropsFromParent {
  className?: string;
  to?: string
  action?: () => void
  children: React.ReactNode
  store?: MockStore
}

interface IPropsFromDispatch {
  onClickLink: () => void
}

type Props = IPropsFromParent & IPropsFromDispatch

const MenuLink: React.FC<Props> = (props: Props): JSX.Element => {
  const location = useLocation()
  const isSelected = (props.to === location.pathname)

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "py-3",
      "px-4",
      "rounded-md",
      "flex",
      "w-full"
    ]
    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    const allClassesWithConditions = classNames(allClasses, {
      "text-ch-gray-500": !isSelected,
      "text-white": isSelected,
      "bg-ch-blue-700": isSelected,
      "hover:bg-ch-blue-700": !isSelected,
      "hover:text-white": !isSelected,
    })

    if(props.action) {
      return (
        <button
          onClick={() => {
            props.onClickLink()
            props.action()
          }}
          className={allClassesWithConditions}
        >
          {props.children}
        </button>
      )
    }
    return(
      <Link
        to={props.to}
        className={allClassesWithConditions}
        onClick={() => props.onClickLink()}
      >
        {props.children}
      </Link>
    )
  }

  return renderContent()
}

export const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => {
  return {
    onClickLink: () => {
      dispatch(toggleIsMenuOpen(false))
    }
  }
}

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(MenuLink)
