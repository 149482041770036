import { IGuestUserNewData } from "../../user/state/userTypes"
import { CaseActionTypes, ICreateCaseAction, ICaseNewData, IFetchCaseAsyncAction, ICaseUpdateData, IUpdateCaseAction, IToggleCaseLockedAction, ICaseLockedData, ICreateCaseGuestUserAction } from "./caseTypes"

export const fetchCaseAction = (caseId: string): IFetchCaseAsyncAction => {
  return {
    type: CaseActionTypes.FETCH_CASE_ASYNC_PENDING,
    payload: caseId
  }
}

export const createCase = (caseData: ICaseNewData): ICreateCaseAction => {
  return {
    type: CaseActionTypes.CREATE_CASE_ASYNC_PENDING,
    payload: caseData
  }
}

export const updateCase = (caseData: ICaseUpdateData): IUpdateCaseAction => {
  return {
    type: CaseActionTypes.UPDATE_CASE_ASYNC_PENDING,
    payload: caseData
  }
}

export const updateCaseMembers = (caseData: ICaseUpdateData): IUpdateCaseAction => {
  return {
    type: CaseActionTypes.UPDATE_CASE_MEMBERS_ASYNC_PENDING,
    payload: caseData
  }
}

export const createCaseGuestUser = (caseId: string, guestUserData: IGuestUserNewData): ICreateCaseGuestUserAction => {
  return {
    type: CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      guestUserData: guestUserData
    }
  }
}

export const toggleCaseLocked = (caseData: ICaseLockedData): IToggleCaseLockedAction => {
  return {
    type: CaseActionTypes.TOGGLE_CASE_LOCKED_ASYNC_PENDING,
    payload: caseData
  }
}
