import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import TextLink from "../../../../shared/components/text/TextLink"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IPathway } from "../../../pathway/pathwayTypes"
import PathwayQuickViewTabs from "./PathwayQuickViewTabs"
import { IconPathway } from "../../../../svg/icons"

type Props = {
  pathwayId: string
}

export const PathwayQuickView: React.FC<Props> = ({pathwayId}: Props) => {

  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector((state: IAppState) => state.entities.pathways.byId[pathwayId])

  if (!pathway) return null

  const pathwayDetailsUrl = generatePath(RoutePaths.PATHWAY, {
    pathwayId: pathwayId
  })

  const renderLastUpdatedBy = () => {
    const firstName = pathway.lastUpdatedBy ? pathway.lastUpdatedBy.firstName : pathway.createdBy.firstName
    const lastName = pathway.lastUpdatedBy ? pathway.lastUpdatedBy.lastName : pathway.createdBy.lastName
    return (
      <dd className="mb-3">
        {t("shared:lastUpdatedBy")}: {firstName} {lastName}
      </dd>
    )
  }

  return (
    <div>
      <div className="flex items-center flex-col">
        <IconCircleContainer className="mb-3">
          <IconPathway className="fill-current w-6 h-6" title={t("shared:pathway")}/>
        </IconCircleContainer>
        <dl className="text-center mb-1">
          <dt className="mb-1">
            <h5 className="font-semibold">
              <TextLink href={pathwayDetailsUrl}>
                {pathway.name}
              </TextLink>
            </h5>
          </dt>
          <dd className="mb-1">
            {t("shared:classification")}: {pathway.caseClassification.name}
          </dd>
          {renderLastUpdatedBy()}
        </dl>
      </div>

      <div className="mb-4 p-4 bg-ch-blue-100 rounded-md">
        <div><b>{t("pathway:summary")}:</b></div>
        <div>{pathway.description || t("pathway:noSummary")}</div>
      </div>

      <div className="flex items-center flex-col mb-6">
        <Button href={pathwayDetailsUrl} variant={StyleVariants.BLUE}>
          {t("shared:viewDetails")}
        </Button>
      </div>

      <PathwayQuickViewTabs pathwayId={pathwayId} />
    </div>
  )
}

export default PathwayQuickView
