import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import Button from "./Button"


interface IPropsFromParent {
  label: string
  canRemove: boolean
  action: () => void
  submitting?: boolean
}

type Props = IPropsFromParent

const RemoveUserButton: React.FC<Props> = (props: Props) => {
  if (props.canRemove) {
    return (
      <Button
        action={props.action}
        variant={StyleVariants.BLUE_ALT}
        key="2"
        isDisabled={props.submitting}
      >
        {props.label}
      </Button>
    )
  } else {
    return (
      <Button
        isDisabled={true}
        className={"invisible"}
      >
        {props.label}
      </Button>
    )
  }
}

export default RemoveUserButton
