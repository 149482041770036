import * as Types from "./liveMeetingTypes"
import IAssetTypeFilters from "../../../shared/types/AssetTypeFilters"
import { IBaseAction } from "../../../shared/types/baseActionTypes"

export const fetchLiveMeetingConfigAsync = (meetingId: string): Types.ILiveMeetingFetchLiveMeetingConfigAsyncAction => {
  return {
    type: Types.LiveMeetingActionTypes.FETCH_LIVE_MEETING_CONFIG_ASYNC_PENDING,
    payload: meetingId
  }
}

export const toggleSplitScreenAction = (isSplitScreen: boolean): Types.ILiveMeetingToggleSplitScreenAction => {
  return {
    type: Types.LiveMeetingActionTypes.TOGGLE_SPLIT_SCREEN,
    payload: isSplitScreen
  }
}

export const toggleYourViewAssetListOpenAction = (isYourViewAssetListOpen: boolean): Types.ILiveMeetingToggleIsYourViewAssetListOpenAction => {
  return {
    type: Types.LiveMeetingActionTypes.TOGGLE_YOUR_VIEW_ASSET_LIST_OPEN,
    payload: isYourViewAssetListOpen
  }
}

export const changeYourViewTabAction = (selectedTab: number): Types.ILiveMeetingChangeYourViewTabAction => {
  return {
    type: Types.LiveMeetingActionTypes.CHANGE_YOUR_VIEW_TAB,
    payload: selectedTab
  }
}

export const changeUsersViewTabAction = (selectedTab: number): Types.ILiveMeetingChangeUsersViewTabAction => {
  return {
    type: Types.LiveMeetingActionTypes.CHANGE_USERS_VIEW_TAB,
    payload: selectedTab
  }
}

export const selectMeetingCaseAction = (meetingCaseId: string): Types.ILiveMeetingSelectCaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.SELECT_MEETING_CASE,
    payload: meetingCaseId,
    meta: { isOptimistic: true }
  }
}

export const toggleIsOnlineAction = (userId: string, isOnline: boolean): Types.ILiveMeetingToggleIsOnlineAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.TOGGLE_IS_ONLINE,
    payload: {
      userId: userId,
      isOnline: isOnline
    },
    meta: { isOptimistic: true }
  }
}

export const toggleIsSharingAudioAction = (userId: string, isSharingAudio: boolean): Types.ILiveMeetingToggleIsSharingAudioAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_AUDIO,
    payload: {
      userId: userId,
      isSharingAudio: isSharingAudio
    },
    meta: { isOptimistic: true }
  }
}

export const toggleIsSharingVideoAction = (userId: string, isSharingVideo: boolean): Types.ILiveMeetingToggleIsSharingVideoAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_VIDEO,
    payload: {
      userId: userId,
      isSharingVideo: isSharingVideo
    },
    meta: { isOptimistic: true }
  }
}

export const toggleIsSharingScreenAction = (userId: string, isSharingScreen: boolean): Types.ILiveMeetingToggleIsSharingScreenAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.TOGGLE_IS_SHARING_SCREEN,
    payload: {
      userId: userId,
      isSharingScreen: isSharingScreen
    },
    meta: { isOptimistic: true }
  }
}

export const updateSharedViewHoveredAssetAction = (assetId: string): Types.ILiveMeetingUpdateSharedViewHoveredAssetAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.UPDATE_SHARED_VIEW_HOVERED_ASSET,
    payload: {
      assetId: assetId,
    },
    meta: { isOptimistic: false }
  }
}

export const updateSharedViewSelectedAssetAction = (assetId: string, assetTypeFilters: IAssetTypeFilters): Types.ILiveMeetingUpdateSharedViewSelectedAssetAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.UPDATE_SHARED_VIEW_SELECTED_ASSET,
    payload: {
      assetId: assetId,
      assetTypeFilters: assetTypeFilters
    },
    meta: { isOptimistic: true }
  }
}

export const updateYourViewSelectedAssetAction = (assetId: string): Types.ILiveMeetingUpdateYourViewSelectedAssetAction => {
  return {
    type: Types.LiveMeetingActionTypes.UPDATE_YOUR_VIEW_SELECTED_ASSET,
    payload: assetId
  }
}

export const updateSharedImageZoomStateAction = (shareImageZoomState: Types.ISharedImageZoomState): Types.ILiveMeetingUpdateSharedImageZoomStateAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.UPDATE_SHARED_IMAGE_ZOOM_STATE,
    payload: shareImageZoomState,
    meta: { isOptimistic: true }
  }
}

export const updateSharedPdfStateAction = (sharedPdfState: Types.ISharedPdfState): Types.ILiveMeetingUpdateSharedPdfState => {
  return {
    type: Types.LiveMeetingCableActionTypes.UPDATE_SHARED_PDF_STATE,
    payload: sharedPdfState,
    meta: { isOptimistic: true }
  }
}

export const presenterTransferAction = (userId: string): Types.ITransferRole => {
  return {
    type: Types.LiveMeetingCableActionTypes.TRANSFER_PRESENTER,
    payload: userId
  }
}

export const scribeTransferAction = (userId: string): Types.ITransferRole => {
  return {
    type: Types.LiveMeetingCableActionTypes.TRANSFER_SCRIBE,
    payload: userId
  }
}

export const updateRecommendationAction = (text: string, caseId: string): Types.IUpdateRecommendation => {
  return {
    type: Types.LiveMeetingCableActionTypes.UPDATE_RECOMMENDATION,
    payload: {
      text,
      caseId
    }
  }
}

export const deferRecommendationAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.DEFER_RECOMMENDATION
  }
}

export const deferCurrentCaseAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.SAVE_DEFER_RECOMMENDATION
  }
}

export const shareRecommendationAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.SHARE_RECOMMENDATION
  }
}

export const requestApprovalAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.REQUEST_APPROVAL_RECOMMENDATION
  }
}

export const saveRecommendationAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.SAVE_RECOMMENDATION
  }
}

export const cancelRecommendationAction = (): IBaseAction => {
  return {
    type: Types.LiveMeetingCableActionTypes.CANCEL_RECOMMENDATION
  }
}

export const giveConsentAction = (response: Types.ConsentResponse, comment: string): Types.IGiveConsent => {
  return {
    type: Types.LiveMeetingCableActionTypes.ADD_CONSENT,
    payload: {
      response,
      comment
    }
  }
}

export const giveConsentOnBehalfAction = (response: Types.ConsentResponse, userId: string): Types.IGiveConsentOnBehalf => {
  return {
    type: Types.LiveMeetingCableActionTypes.ADD_CONSENT_ON_BEHALF,
    payload: {
      response,
      userId
    }
  }
}
