import React, { useState, useRef, useEffect } from "react"
import { ISharedImageZoomState } from "../../../../features/liveMeeting/state/liveMeetingTypes"
import Slider from "../Slider"
import { getNewPosition, move } from "./ImageZoomHelpers"
import ImageZoomImage from "./ImageZoomImage"

interface IPos {
  posX: number
  posY: number
}

interface IShift {
  x: number
  y: number
}

interface IState {
  zoom: number
  posX: number
  posY: number
  cursor: string
  transitionDuration: number
  lastRequestAnimationId: number
  lastCursor: IPos
  lastShift: IShift
}

interface IPropsFromParent {
  readonly imageSrc: string,
  readonly updateCallback?: (sharedImageZoomState: ISharedImageZoomState) => void
}

type Props = IPropsFromParent

const ImageZoom: React.FC<Props> = (props: Props): JSX.Element => {
  const defaultState = {
    zoom: 1,
    posX: 0,
    posY: 0,
  } as IState

  const [state, setState] = useState(defaultState)
  const [throttle, setThrottle] = useState(0)
  const [prevState, setPrevState] = useState({} as IState)

  const defaultValues = {
    zoom: 5,
    minZoom: 1,
    maxZoom: 5,
    scrollVelocity: 0.2,
  }

  const imageRef = useRef<HTMLImageElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // reset server state when loading for first time or when page reloads
    if (!props.updateCallback) return
    props.updateCallback(defaultState)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setThrottle((s) => s + 1)
    }, 1500)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (props.imageSrc && prevState !== state) {
      if (props.updateCallback && imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect()
        const relativePosX = state.posX / rect.width
        const relativePosY = state.posY / rect.height
        const updateSharedImageZoomState: ISharedImageZoomState = {
          zoom: state.zoom,
          posX: relativePosX,
          posY: relativePosY,
        }
        props.updateCallback(updateSharedImageZoomState)
      }

      setPrevState(state)
    } else if (!props.imageSrc && state !== defaultState) {
      setState(defaultState)
    }
  }, [throttle])

  const handleMouseWheel = (event) => {
    const { zoom } = state
    const { scrollVelocity, maxZoom, minZoom } = defaultValues

    // Determine if we are increasing or decreasing the zoom
    const increaseZoom = event.deltaY < 0

    let newZoom: number
    if (increaseZoom) {
      newZoom =
        zoom + scrollVelocity < maxZoom ? zoom + scrollVelocity : maxZoom
    } else {
      newZoom =
        zoom - scrollVelocity > minZoom ? zoom - scrollVelocity : minZoom
    }

    const newPos =
      newZoom !== zoom
        ? getNewPosition(
          {
            posX: event.pageX,
            posY: event.pageY,
          },
          newZoom,
          state,
          imageRef.current.getBoundingClientRect()
        )
        : {
          posX: state.posX,
          posY: state.posY
        }

    setState({
      ...state,
      ...newPos,
      zoom: newZoom,
      transitionDuration: 0.5,
    })
  }

  const onDoubleClick = (event) => {
    if (state.zoom !== defaultValues.minZoom) {
      setState({
        ...state,
        zoom: 1,
        posX: 0,
        posY: 0,
      })
      return
    }

    const newPos = getNewPosition(
      {
        posX: event.pageX,
        posY: event.pageY,
      },
      defaultValues.zoom,
      {
        zoom: state.zoom,
        posX: state.posX,
        posY: state.posY ?? 0,
      },
      imageRef.current.getBoundingClientRect()
    )

    setState({
      ...state,
      zoom: defaultValues.zoom,
      ...newPos,
    })
  }

  const handleMouseStart = (event) => {
    event.preventDefault()

    if (state.lastRequestAnimationId) {
      cancelAnimationFrame(state.lastRequestAnimationId)
    }

    const lastCursor = {
      posX: event.pageX,
      posY: event.pageY
    }

    setState({
      ...state,
      cursor: "move",
      lastCursor
    })
  }

  const handleMouseStop = (event) => {
    event.preventDefault()
    setState({
      ...state,
      cursor: "auto",
      lastCursor: null,
      lastShift: null
    })
  }

  const handleMouseMove = (event) => {
    event.preventDefault()

    if (!state.lastCursor) {
      return
    }

    const lastCursor = {
      posX: event.pageX,
      posY: event.pageY
    }

    const lastShift = {
      x: lastCursor.posX - state.lastCursor.posX,
      y: lastCursor.posY - state.lastCursor.posY,
    }

    const rects = {
      img: imageRef.current.getBoundingClientRect(),
      parent: parentRef.current.getBoundingClientRect(),
    }

    const moveState = move(lastShift, state, rects)
    setState({
      ...state,
      ...moveState,
      lastShift,
      lastCursor,
      transitionDuration: 0,
    })
  }

  const handleSliderChange = (value: number) => {
    const newZoom = value / 100

    const newPosX = (newZoom === defaultValues.minZoom) ? 0 : state.posX
    const newPosY = (newZoom === defaultValues.minZoom) ? 0 : state.posY

    setState({
      ...state,
      zoom: newZoom,
      posX: newPosX,
      posY: newPosY
    })
  }

  const attr = {
    zoom: state.zoom,
    posX: state.posX,
    posY: state.posY,
    cursor: state.cursor,
    transitionDuration: state.transitionDuration,
    imageRef: imageRef,
    parentRef: parentRef,
    imageSrc: props.imageSrc,
    attr: {
      onWheel: handleMouseWheel,
      onDoubleClick: onDoubleClick,
      onMouseDown: handleMouseStart,
      onMouseUp: handleMouseStop,
      onMouseLeave: handleMouseStop,
      onMouseMove: handleMouseMove,
    },
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex-1 mb-3 overflow-hidden">
        <ImageZoomImage {...attr} />
      </div>
      <div className="flex items-center justify-center relative">
        <div className="w-64">
          <Slider
            onChange={handleSliderChange}
            value={state.zoom * 100}
            min={defaultValues.minZoom * 100}
            max={defaultValues.maxZoom * 100}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageZoom
