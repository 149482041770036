import { AnyAction, combineReducers, Reducer } from "redux"
import { IPathwaysUIState, PathwaysActionTypes, PathwaysOrderByOptions } from "../pathwaysTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalPathwaysCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.totalItems

    default:
      return state
  }
}
export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = [], action: AnyAction): string[] => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.appliedFilters
    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = PathwaysOrderByOptions.NameAsc, action: AnyAction): string => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const onPage: Reducer<string[]> = (state = [], action: AnyAction): string[] => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return action.payload.all.result
    default:
      return state
  }
}

export const pathwaysUIReducer: Reducer<IPathwaysUIState> = combineReducers({
  totalPathwaysCount,
  totalPages,
  appliedFilters,
  resultsPerPage,
  searchTerm,
  orderBy,
  onPage
})
