import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../../shared/components/ui/LoadingSpinner"
import PdfViewer from "../../../shared/components/ui/PdfViewer"
import fetchBlob from "../../../shared/helpers/fetchBlob"
import { ITerms } from "../state/accountTypes"

interface IPropsFromParent {
  terms: ITerms
}

type Props = IPropsFromParent

const TermsPdfViewer: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const pdf = props.terms
  const [blobUrl, setBlobUrl] = useState(null)

  useEffect(() => {
    fetchBlob(pdf.path, setBlobUrl)
  }, [pdf.path])

  const renderContent = (): JSX.Element => {
    if(!blobUrl) return <LoadingSpinner />

    return <PdfViewer
      url={blobUrl}
      filename={`${t("shared:termsOfUse")} v${pdf.version}.pdf`}
    />
  }

  return renderContent()
}

export default TermsPdfViewer
