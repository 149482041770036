import React, { useEffect, useState } from "react"
import { Route, RouteComponentProps, useParams } from "react-router-dom"
import { IAppState } from "../appTypes"
import UserConfigProvider from "../../features/userConfig/contexts/UserConfigProvider"
import history from "./history"
import TenantConfigProvider from "../../features/tenantConfig/contexts/TenantConfigProvider"
import { getCurrentUser } from "../../shared/selectors/user"
import { fetchCurrentUserAsync } from "../../features/user/state/sagas/userSagas"
import MfaPage from "../../pages/account/MfaPage"
import AccountLayout from "../../layouts/AccountLayout"
import { RoutePaths } from "./Routes"
import { useSelector } from "react-redux"
import SwitchToTenantModal from "./SwitchToTenantModal"
import { ExternalUsersContextProvider } from "../../shared/contexts/ExternalUsersContextProvider"
import { checkSessionAction } from "../state/appActions"
import { useAppDispatch } from "../../shared/hooks"
import _ from "lodash"

interface IProps {
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined
  layout?: React.FC | string
  overrideMfaIntercept?: boolean
}

const defaultProps = {
  layout: "div"
}

interface IHasTenantId {
  tenantId?: string
}

const AuthenticatedRoute: React.FC<IProps> = (props: IProps): JSX.Element => {
  const apiToken = useSelector((state: IAppState) => state.userSession.apiToken)
  const currentUser = useSelector(getCurrentUser)
  const { tenantId } = useParams<IHasTenantId>()
  const [renderSwitchToTenant, setRenderSwitchTenant] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchCurrentUserAsync()
  })

  useEffect(() => {
    if (!tenantId || !currentUser) {
      setRenderSwitchTenant(false)
      return
    }
    if (currentUser.currentTenantId != tenantId) {
      setRenderSwitchTenant(true)
    }
  }, [currentUser, tenantId])

  useEffect(() => {
    if (!apiToken) {
      history.replace(RoutePaths.SIGN_IN)
    }
  }, [apiToken])

  const throttledCheckSessionAction = _.throttle(() => {
    dispatch(checkSessionAction())
  }, 60 * 1000)

  const checkSession = (): void => {
    if (!apiToken) return
    throttledCheckSessionAction()
  }

  // in testing this, I found that neither event are reliably triggered, but
  // one or the other generally is. So I thought to just add the listener on
  // both. The throttling of the action anyway takes care of it not being called
  // more often then neccessary
  //
  // The actions on keydown and pointermove are all to chech if a user is still
  // using the application, in which case we trigger the same event, as this  will
  // also reset the validity of the session token.
  useEffect(() => {
    document.addEventListener("keydown", checkSession)
    document.addEventListener("pointermove", checkSession)
    document.addEventListener("visibilitychange", checkSession)
    window.addEventListener("focus", checkSession)
    // Calls onFocus when the window first loads
    return () => {
      document.removeEventListener("keydown", checkSession)
      document.removeEventListener("pointermove", checkSession)
      document.removeEventListener("visibilitychange", checkSession)
      window.removeEventListener("focus", checkSession)
    }
  }, [])


  if (!currentUser || currentUser.mfaRequired == undefined) return null

  if (currentUser.mfaRequired && !props.overrideMfaIntercept) {
    const component = MfaPage
    return (
      <TenantConfigProvider>
        <UserConfigProvider>
          <AccountLayout>
            <Route component={component} path={props.path} />
          </AccountLayout>
        </UserConfigProvider>
      </TenantConfigProvider>
    )
  }
  const Layout = props.layout

  return (
    <TenantConfigProvider>
      <UserConfigProvider>
        <ExternalUsersContextProvider>
          <Layout>
            {renderSwitchToTenant ? <SwitchToTenantModal tenantId={tenantId} /> : null}
            <Route component={props.component} path={props.path} />
          </Layout>
        </ExternalUsersContextProvider>
      </UserConfigProvider>
    </TenantConfigProvider>
  )
}

AuthenticatedRoute.defaultProps = defaultProps

export default AuthenticatedRoute
