import { AnyAction, Reducer } from "redux"
import { IUserCheckUIState, UserActionTypes, UserCheckStatuses } from "../userTypes"

export const checkUserInitialState: IUserCheckUIState = {
  existingUserIds: null,
  status: null
}

export const userCheckUIReducer: Reducer<IUserCheckUIState> = (state = checkUserInitialState, action: AnyAction): IUserCheckUIState => {
  switch (action.type) {
    case UserActionTypes.CHECK_USER_ASYNC_FOUND:
      return {
        ...state,
        existingUserIds: action.payload.result,
        status: UserCheckStatuses.Found
      }

    case UserActionTypes.CHECK_USER_ASYNC_NOT_FOUND:
      return {
        ...state,
        existingUserIds: null,
        status: UserCheckStatuses.Not_Found
      }

    case UserActionTypes.CHECK_USER_ASYNC_PENDING:
      return {
        ...state,
        status: UserCheckStatuses.Pending
      }

    case UserActionTypes.CHECK_USER_ASYNC_RESET:
      return {
        ...state,
        existingUserIds: null,
        status: null
      }

    default:
      return state
  }
}
