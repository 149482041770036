import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { UsersViewTabs } from "../../state/liveMeetingTypes"
import LiveMeetingUserList from "./LiveMeetingUserList"
import LiveMeetingUserGrid from "./LiveMeetingUserGrid"
import { getSelectedUserViewTab } from "../../../../shared/selectors/ui"
import _ from "lodash"
import { getMeetingUsersWithConfig } from "../../../../shared/selectors/liveMeetingConfig/userConfig"

interface IProps {
  meetingId: string
}

const LiveMeetingUsersView: React.FC<IProps> = (props: IProps): JSX.Element => {
  // this is done in this way (ie, setting components to hidden, rather then not
  // rendering them) because we must always render the grid element, in order to
  // subsribe to the WebRTC streams that are tied to them
  const selectedUsersViewTab = useSelector(getSelectedUserViewTab)
  const users = useSelector((state: IAppState) => getMeetingUsersWithConfig(state, props))
  const sortedUserIds = _.sortBy(users, [(u) => !u.config?.isOnline, "lastName", "firstName"])
    .filter((u) => u.activeInCurrentTenant)
    .map((u) => u.id)

  return (
    <div>
      <div className={selectedUsersViewTab == UsersViewTabs.GRID ? "" : "hidden"}>
        <LiveMeetingUserGrid userIds={sortedUserIds} />
      </div>
      <div className={selectedUsersViewTab == UsersViewTabs.LIST ? "" : "hidden"}>
        <LiveMeetingUserList userIds={sortedUserIds} />
      </div>
    </div>
  )
}

export default LiveMeetingUsersView
