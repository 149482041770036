import { AnyAction, combineReducers, Reducer } from "redux"
import INotificationsUIState, { NotificationsActionTypes, NotificationsCableActionTypes } from "../notificationsTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalNotificationsCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
      return action.payload.totalItems

    default:
      return state
  }
}

export const unreadCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
    case NotificationsCableActionTypes.UPDATE_UNREAD_COUNT:
    case NotificationsCableActionTypes.DELIVER_NOTIFICATION:
      return action.payload.unreadCount

    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const notificationsUIReducer: Reducer<INotificationsUIState> = combineReducers({
  totalNotificationsCount,
  unreadCount,
  totalPages,
  resultsPerPage,
})
