interface IShift {
  x: number
  y: number
}

interface IPos {
  posX: number
  posY: number
}

interface IState {
  zoom: number
  posX: number
  posY: number
}

interface IRect {
  left: number
  right: number
  top: number
  bottom: number
  width: number
  height: number
}

interface IRects {
  img: IRect
  parent: IRect
}

const calculateNewPos = (pageX: number, pageY: number, prevData: IState, zoom: number, rect: IRect): IPos => {
  // Get container coordinates

  // Retrieve rectangle dimensions and mouse position
  const [centerX, centerY] = [rect.width / 2, rect.height / 2]
  const [relativeX, relativeY] = [
    pageX - rect.left - window.pageXOffset,
    pageY - rect.top - window.pageYOffset
  ]

  // If we are zooming down, we must try to center to mouse position
  const [absX, absY] = [
    (centerX - relativeX) / prevData.zoom,
    (centerY - relativeY) / prevData.zoom
  ]
  const ratio = zoom - prevData.zoom
  return {
    posX: prevData.posX + absX * ratio,
    posY: prevData.posY + absY * ratio
  }
}

const getNewPosition = (newPos: IPos, zoom: number, prevData: IState, rect: IRect): IPos => {
  if (zoom === 1) {
    return {
      posX: 0,
      posY: 0
    }
  }

  if (zoom > prevData.zoom) {
    return calculateNewPos(newPos.posX, newPos.posY, prevData, zoom, rect)
  } else {
    // If we are zooming down, we shall re-center the element
    return {
      posX: (prevData.posX * (zoom - 1)) / (prevData.zoom - 1),
      posY: (prevData.posY * (zoom - 1)) / (prevData.zoom - 1)
    }
  }
}

const move = (shift: IShift, state: IPos, rects: IRects): IPos => {
  let { posX, posY } = state

  const { img, parent } = rects

  const canMoveOnX =
    shift.x > 0 && (img.left - parent.left) < 0 ||
    shift.x < 0 && (img.right - parent.right) > 0

  if (canMoveOnX) {
    posX += shift.x
  }

  const canMoveOnY =
    shift.y > 0 && img.top - parent.top < 0 ||
    shift.y < 0 && img.bottom - parent.bottom > 0

  if (canMoveOnY) {
    posY += shift.y
  }

  return {
    posX,
    posY
  }
}

export { getNewPosition, move }
