import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { getTeamByHasTeamId } from "../../../../shared/selectors/team"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconTeam } from "../../../../svg/icons"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import TeamDetails from "../details/TeamDetails"

interface IProps {
  teamId: string
}

const TeamListItem: React.FC<IProps> = (props) => {
  const team = useSelector((state: IAppState) => getTeamByHasTeamId(state, props))
  const { t } = useTranslation(["shared", "team"])
  const teamDetailsUrl = generatePath(RoutePaths.TEAM, {
    teamId: props.teamId
  })

  if (!team) return null
  const renderViewButton = (): JSX.Element => {
    return (
      <Button href={teamDetailsUrl} variant={StyleVariants.BLUE} key="2">
        {t("shared:viewDetails")}
      </Button>
    )
  }

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconTeam className="fill-current w-6 h-6" />
          </IconCircleContainer>
          <TeamDetails teamId={team.id} userId={team.owner} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[renderViewButton()]}
          />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default TeamListItem
