import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconMeeting } from "../../../../svg/icons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import MeetingDetails from "./MeetingDetails"
import { getCurrentUser } from "../../../../shared/selectors/user"
import _ from "lodash"

interface IProps {
  meetingId: string
}

const MeetingListItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "meeting"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const currentUser = useSelector(getCurrentUser)
  if (!meeting || !currentUser) return null
  const userInMeeting = _.includes(meeting.users, currentUser.id)

  const meetingDetailsUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const liveMeetingUrl = generatePath(RoutePaths.MEETING_LIVE, {
    meetingId: props.meetingId
  })

  const inProgress = () => {
    return meeting.status == MeetingStatuses.InProgress
  }

  const renderJoinMeetingButton = (): JSX.Element => {
    if (!userInMeeting || !inProgress()) return null
    return (
      <Button
        href={liveMeetingUrl}
        variant={StyleVariants.ORANGE}
        key="1"
      >
        {t("meeting:joinMeeting")}
      </Button>
    )
  }

  const renderViewDetailsButton = (): JSX.Element => {
    if (!userInMeeting) return null
    return (
      <Button
        href={meetingDetailsUrl}
        variant={StyleVariants.BLUE}
        key="3"
      >
        {t("meeting:viewMeeting")}
      </Button>
    )
  }

  return (
    <li>
      <DetailsCard isDark={!inProgress()}>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
          </IconCircleContainer>
          <MeetingDetails meetingId={props.meetingId} hasLink={userInMeeting} showMeetingStatus={true} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            renderJoinMeetingButton(),
            renderViewDetailsButton()
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default MeetingListItem
