import API from "../../../apis/contextmeeting/api"
import { fetchChatsSchema } from "../../../apis/contextmeeting/schema"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import queryString from "query-string"
import humps from "humps"
import { ChatsActionTypes, IFetchChatsAsyncAction } from "./chatsTypes"

export function* fetchChatsAsync(action: IFetchChatsAsyncAction): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/chats?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [fetchChatsSchema])

    yield put({
      type: ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        resultsPerPage: response.data.searchQuery.resultsPerPage
      }
    })
  } catch (error) {
    yield put({
      type: ChatsActionTypes.FETCH_CHATS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "chatsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchChatsAsync(): Generator {
  yield takeLeading(ChatsActionTypes.FETCH_CHATS_ASYNC_PENDING, fetchChatsAsync)
}
