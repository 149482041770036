/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"
import PillSwitch from "../../../shared/components/ui/PillSwitch"
import { getAllActiveUsersSortedFirstNameLastName, getCurrentUser } from "../../../shared/selectors/user"
import { ITeam } from "../../team/state/teamTypes"
import { getAllTeams } from "../../../shared/selectors/team"
import SelectField from "../../../shared/components/forms/SelectField"
import { IChatNewFormData } from "../state/chatNewTypes"
import { isGuest } from "../../../shared/helpers/userHelpers"
import { fetchAllTeamsAsync } from "../../teams/state/teamsActions"
import { useAppDispatch } from "../../../shared/hooks"

interface Props {
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
}

const ChatNewUsersField: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const currentUser = useSelector(getCurrentUser)
  const [userOptions, setUserOptions] = React.useState([])
  const [teamOptions, setTeamOptions] = React.useState([])
  const users = useSelector(getAllActiveUsersSortedFirstNameLastName)
  const teams = useSelector(getAllTeams)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchAllTeamsAsync())
  }, [])

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      return user.id != currentUser.id && !isGuest(user)
    })
    const sortedUsers = _.keyBy(filteredUsers, "id")
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(sortedUsers))
    setUserOptions(options)
  }, [users])

  useEffect(() => {
    const options: TSelectFieldOptions = Object.values(teams).map((team: ITeam) => {
      return {
        value: team.id,
        label: team.name
      }
    })
    setTeamOptions(options)
  }, [teams])

  const renderSelect = (): JSX.Element => {
    if (props.values.isTeamChat) return (
      <SelectField
        name="teamId"
        options={teamOptions}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
      />
    )
    return (
      <SelectField
        name="userIds"
        options={userOptions}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        isMulti={true}
        closeMenuOnSelect={false}
        filterOption={filterOptionsBySearchValue}
      />
    )
  }

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-sm text-ch-gray-500 italic mb-2">{t("message:new:selectUsersOrTeam")}</h3>
      <PillSwitch
        onClick={(value: number) => {
          props.setFieldValue("userIds", [])
          props.setFieldValue("teamId", "")
          props.setFieldValue("isTeamChat", Boolean(value))
        }}
        text1={t("shared:users")}
        text2={t("shared:teams")}
        value={Number(props.values.isTeamChat)}
        className="mb-2 uppercase"
      />

      <div className="mb-2 w-full">
        {renderSelect()}
      </div>
    </div>
  )
}

export default ChatNewUsersField
