import React, { useContext, useEffect, useState } from "react"
import { TenantConfigContext } from "../../../features/tenantConfig/contexts/TenantConfigContext"
import fetchBlob from "../../helpers/fetchBlob"

interface Props {
  className?: string
}

const TenantLogo: React.FC<Props> = (props: Props): JSX.Element => {
  const [blobUrl, setBlobUrl] = useState("")

  const { tenantName, tenantLogoPath } = useContext(
    TenantConfigContext
  )

  useEffect(() => {
    if(!tenantLogoPath) return

    fetchBlob(tenantLogoPath, setBlobUrl)
  }, [tenantLogoPath])

  if(!blobUrl) return null

  const defaultClasses = [
    "flex"
  ]

  const propClasses = props.className ? props.className.split(" ") : []

  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      <img
        className="max-h-full"
        src={blobUrl}
        title={tenantName}
      />
    </div>
  )
}

export default TenantLogo
