import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router"
import { setTenantAction } from "../../features/user/state/userActions"
import Button from "../../shared/components/buttons/Button"
import Buttons from "../../shared/components/layout/Buttons"
import ModalContainer from "../../shared/components/modals/ModalContainer"
import { smallModalStyle, StyleVariants } from "../../shared/state/sharedTypes"
import { LogoFull } from "../../svg/branding"
import history from "./history"
import { RoutePaths } from "./Routes"

interface IProps {
  tenantId: string
}

const SwitchToTenantModal: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch()
  const route = useRouteMatch()
  const { t } = useTranslation(["shared"])

  const setTenant = () => {
    const newPath = history.location.pathname.replace(route.url, "")
    dispatch(setTenantAction(props.tenantId, newPath))
  }

  const stayInCurrentTenant = () => {
    history.replace(RoutePaths.MEETINGS)
  }
  const switchTenantButton =
    <Button key="1" action={setTenant} variant={StyleVariants.BLUE_ALT} >
      {t("resourceInTenant.switchTenantButtonText")}
    </Button>

  const stayInTenantButton =
    <Button key="2" action={stayInCurrentTenant} variant={StyleVariants.PURPLE} >
      {t("resourceInTenant.stayButtonText")}
    </Button>

  return (
    <Modal isOpen={true} style={smallModalStyle} >
      <ModalContainer>
        <div className="flex flex-col mt-10 justify-between">
          <div className="text-center">
            <div className="flex justify-center items-center mb-5 relative">
              <LogoFull className="h-8" />
            </div>
          </div>
          <div className="flex justify-center items-center mb-5 relative">
            {t("resourceInTenant.message")}
          </div>
          <div className="flex justify-center items-center mb-5 relative">
            <Buttons buttons={[stayInTenantButton, switchTenantButton]} />
          </div>
        </div>
      </ModalContainer>
    </Modal >
  )
}

export default SwitchToTenantModal
