import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconRejectThin({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M12.844 2.188c-2.925-2.917-7.725-2.917-10.65 0-2.925 2.918-2.925 7.706 0 10.624a7.482 7.482 0 0010.575 0c2.925-2.918 3-7.706.075-10.624zm-3.225 8.454l-2.1-2.095-2.1 2.095-1.05-1.047 2.1-2.095-2.1-2.095 1.05-1.047 2.1 2.095 2.1-2.095 1.05 1.047-2.1 2.095 2.1 2.095-1.05 1.047z"
        />
        <path d="M0 0h15v15H0z" />
      </g>
    </svg>
  );
}

export default SvgIconRejectThin;
