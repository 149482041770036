import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconPatient } from "../../../../svg/icons"
import { IPatient } from "../../../patient/state/patientTypes"
import { useDispatch, useSelector } from "react-redux"
import { fetchPatientAsync } from "../../../patient/state/patientActions"
import { IAppState } from "../../../../app/appTypes"
import { getPatientById } from "../../../../shared/selectors/patients"
import DetailsCardWithNested from "../../../../shared/components/layout/detailsCard/DetailsCardWithNested"
import PatientCaseList from "../shared/PatientCaseList"

interface Props {
  patientId: string
}

const PatientWithCasesDetailsCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "patient"])
  const dispatch = useDispatch()
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  const patient: IPatient = useSelector((state: IAppState) => getPatientById(state, props))

  useEffect(() => {
    dispatch(fetchPatientAsync(props.patientId))
  }, [])

  const renderName = (): JSX.Element => {
    return (
      <li>
        <h4 className="font-semibold">
          <NameUser
            firstName={patient.firstName}
            lastName={patient.lastName}
            title={patient.title}
          />
        </h4>
      </li>
    )
  }

  const renderGender = (): JSX.Element => {
    const translationKey = humps.camelize(patient.gender)
    return (
      <li>
        {t("patient:details:gender")}: {t(`shared:${translationKey}`)}
      </li>
    )
  }

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("patient:details:dateOfBirth")}: <TimeDateOfBirth utcTime={patient.dateOfBirth} />
      </li>
    )
  }

  const renderHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {patient.healthcareId || "-"}
      </li>
    )
  }

  const renderCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {patient.careProviderId || "-"}
      </li>
    )
  }

  return (
    <DetailsCardWithNested className="flex-col">
       <DetailsCard hasBorder={false}>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconPatient className="fill-current w-6 h-6" title={t("shared:patient")} />
          </IconCircleContainer>
          <div className="flex-1 lg:grid grid-cols-2 gap-3">
            <ul className="col-span-1">
              { renderName() }
              { renderGender() }
              { renderDateOfBirth() }
            </ul>

            <ul className="col-span-1">
              { renderHealthcareId() }
              { renderCareProviderId() }
            </ul>
          </div>
        </DetailsCardInner>
      </DetailsCard>
      <PatientCaseList patientId={props.patientId} isDark={true} />
    </DetailsCardWithNested>
  )
}

export default PatientWithCasesDetailsCard
