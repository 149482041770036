import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getMeetingCaseById } from "../../../../shared/selectors/meeting"
import { IMeetingCase } from "../../../meetingCase/state/meetingCaseTypes"
import { ICase } from "../../../case/state/caseTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import NameUser from "../../../../shared/components/names/NameUser"

interface IProps {
  meetingCaseId: string
}

const AuditEventCaseName = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const selectedMeetingCase: IMeetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, props))
  const caseData: ICase = useSelector((state: IAppState) => {
    const caseId = selectedMeetingCase?.case
    if (caseId) {
      return getCaseById(state, { caseId })
    }
    return null
  })

  if(!caseData) return null
  return (
    <div className="font-semibold">{t("shared:case")}:&nbsp;
      <NameUser
        firstName={caseData.firstName}
        lastName={caseData.lastName}
        title={caseData.title}
        gender={caseData.gender}
      />
    </div>
  )
}

export default AuditEventCaseName
