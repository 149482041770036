import API from "../../../apis/contextmeeting/api"
import { notificationsSchema } from "../../../apis/contextmeeting/schema"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { normalize } from "normalizr"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import queryString from "query-string"
import humps from "humps"
import { NotificationsActionTypes } from "./notificationsTypes"

export function* fetchNotificationsAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/notifications?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [notificationsSchema])

    yield put({
      type: NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        unreadCount: response.data.unreadCount
      }
    })
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "notificationsNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchNotificationsAsync(): Generator {
  yield takeLeading(NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_PENDING, fetchNotificationsAsync)
}
