import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { IHaveMeetingCaseId, MeetingCaseCablePrefix, MeetingCaseChannelName } from "../../features/meetingCase/state/meetingCaseTypes"
import { IAppState } from "../../app/appTypes"
import { getMeetingCaseById } from "../../shared/selectors/meeting/index"
import MeetingCaseHeader from "../../features/meetingCase/components/header/MeetingCaseHeader"
import { fetchMeetingCaseAction } from "../../features/meetingCase/state/meetingCaseActions"
import { fetchCaseAssetsAction } from "../../features/assets/state/assetsActions"
import MeetingCaseActivity from "../../features/meetingCase/components/activity/MeetingCaseActivity"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { getLatestRecommendationAssetId, getAssetById } from "../../shared/selectors/asset"
import { fetchCaseTasksAsync } from "../../features/tasks/state/tasksActions"
import CableConnection from "../../shared/components/cable/CableConnection"
import { useBeforeunload } from "react-beforeunload"
import { fetchCaseAction } from "../../features/case/state/caseActions"


const MeetingCasePage: React.FC = (): JSX.Element => {
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, { meetingCaseId }))
  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(0)
  const { t } = useTranslation(["shared", "case", "asset", "meeting", "caseReport"])
  const dispatch = useDispatch()
  // TODO = return only users needed from case and meeting endpoints

  useBeforeunload(() => {
    setConnectionRefresherIndex(connectionRefresherIndex + 1)
  })


  useEffect(() => {
    dispatch(fetchMeetingCaseAction(meetingCaseId))
    dispatch(fetchAllUsersAsync())
  }, [])

  useEffect(() => {
    if (!meetingCase?.case) return
    dispatch(fetchCaseAction(meetingCase.case))
    dispatch(fetchCaseAssetsAction(meetingCase.case))
    dispatch(fetchCaseTasksAsync(meetingCase.case))
  }, [meetingCase?.case])

  const latestRecommendationId = useSelector((state: IAppState) => getLatestRecommendationAssetId(state, { caseId: meetingCase?.case }))
  const latestRecommendation = useSelector((state: IAppState) => getAssetById(state, { assetId: latestRecommendationId }))

  useEffect(() => {
    dispatch(fetchMeetingCaseAction(meetingCaseId))
  }, [latestRecommendation])

  if (!meetingCase) return <h3 className="text-lg">{t("meeting:meetingCaseNotFound")}</h3>
  return (
    <CableConnection
      resourceId={meetingCase.id}
      channelName={MeetingCaseChannelName}
      actionPrefix={MeetingCaseCablePrefix}
      key={connectionRefresherIndex}
    >
      <div>
        <div className="flex-1 mr-4">
          <MeetingCaseHeader meetingId={meetingCase.meeting} meetingCaseId={meetingCase.id} renderLockCase />
          <MeetingCaseActivity
            meetingCaseId={meetingCase.id}
            meetingId={meetingCase.meeting}
            caseId={meetingCase.case}
          />
        </div>
      </div>
    </CableConnection>
  )
}

export default MeetingCasePage
