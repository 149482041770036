import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconComments({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M18.857 7.286c0 1.24-.42 2.388-1.259 3.442-.84 1.053-1.984 1.886-3.435 2.497-1.45.612-3.03.918-4.734.918-.768 0-1.554-.072-2.358-.214a11.264 11.264 0 01-3.723 1.714c-.321.08-.705.152-1.152.214h-.04a.418.418 0 01-.274-.107.442.442 0 01-.154-.281.274.274 0 01-.014-.087.53.53 0 01.007-.087.284.284 0 01.027-.08l.033-.068.047-.073.054-.067.06-.067.054-.06a9.36 9.36 0 01.308-.335c.16-.17.276-.302.348-.395.071-.094.172-.224.301-.389a3.39 3.39 0 00.335-.515c.094-.179.185-.375.275-.59-1.108-.643-1.978-1.433-2.612-2.37C.317 9.348 0 8.348 0 7.286c0-1.241.42-2.389 1.259-3.442.84-1.054 1.984-1.886 3.435-2.498C6.145.734 7.724.429 9.43.429c1.705 0 3.283.305 4.734.917 1.45.612 2.596 1.444 3.435 2.498.84 1.053 1.26 2.2 1.26 3.442zM24 10.714c0 1.072-.317 2.074-.95 3.007-.635.933-1.505 1.721-2.613 2.364.09.214.181.41.275.59.094.178.205.35.335.515.13.165.23.294.301.388.072.094.188.226.348.395a9.36 9.36 0 01.362.395c.027.032.047.054.06.067a.57.57 0 01.054.067.604.604 0 01.047.074l.033.067.027.08.007.087-.014.087a.496.496 0 01-.174.295.404.404 0 01-.294.094 11.26 11.26 0 01-1.152-.215 11.264 11.264 0 01-3.723-1.714 13.45 13.45 0 01-2.358.214c-2.42 0-4.526-.589-6.321-1.767.518.035.91.053 1.179.053 1.437 0 2.817-.2 4.138-.603 1.321-.401 2.5-.977 3.536-1.727 1.116-.822 1.973-1.768 2.571-2.84a6.874 6.874 0 00.59-5.437c1.151.634 2.062 1.429 2.732 2.384.67.955 1.004 1.982 1.004 3.08z"
        />
        <path d="M0-3h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconComments;
