import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, ICreatePathwayStepAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* createPathwayStepAsync(action: ICreatePathwayStepAction): Generator {
  try {
    const pathwayId = action.payload.pathwayId

    const response: any = yield call(API.post, `/pathways/${pathwayId}/pathway_steps`, action.payload.values, withAuthHeader())

    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({
      type: PathwayActionTypes.CREATE_PATHWAY_STEP_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "createPathwayStepSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: PathwayActionTypes.CREATE_PATHWAY_STEP_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createPathwayStepFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreatePathwayStepAsync(): Generator {
  yield takeLeading(PathwayActionTypes.CREATE_PATHWAY_STEP_ASYNC_PENDING, createPathwayStepAsync)
}
