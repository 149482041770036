import React, { ReactNode } from "react"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IconLink } from "../../../../svg/icons"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { useSelector } from "react-redux"
import { ILinkAsset } from "../../state/assetsTypes"
import AssetSubHeader from "./AssetSubHeading"

interface Props {
  store?: MockStore
  assetId: string
  action?: () => void
  isSelected?: boolean
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
  noAction?: boolean
}

const Link: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const link = asset.asset as ILinkAsset
  const linkProps = {
    href: props.noAction ? null : link.link,
    target: "_blank",
    rel: "noreferrer"
  }

  return (
    <div className={classNames(
      "bg-white",
      "p-2",
      "flex",
      "rounded-md",
      "border-2",
      "hover:border-ch-blue-500",
      {
        "border-ch-blue-200": !props.isSelected,
        "border-ch-blue-500": props.isSelected,
      }
    )}>
      <a
        {...linkProps}
        onClick={props.action ? () => props.action() : null}
        className={classNames(
          "flex",
          "flex-grow",
          {
            "hover:border-ch-blue-500": linkProps.href,
            "cursor-pointer": linkProps.href,
            "cursor-default": !linkProps.href,
          }
        )}
      >
        <div className="text-ch-blue-alt-400 mr-2 mt-1">
          <IconLink className="w-5 h-5" title={link.title} />
        </div>

        <div className="flex-1 min-w-0 grid">
          <h4 className="font-semibold text-lg">
            {link.title}
          </h4>
          <AssetSubHeader
            meetingCaseAsset={props.meetingCaseAsset}
            meetingId={props.meetingId}
            asset={asset}
          />
          <span
            className="block overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {link.link}
          </span>
        </div>
      </a>
      {props.children}
    </div>
  )
}

export default Link
