import React, { useState, useEffect } from "react"
import ButtonFilter from "../../../../shared/components/buttons/ButtonFilter"
import Buttons from "../../../../shared/components/layout/Buttons"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { useTranslation } from "react-i18next"

interface IProps {
  setFilters: (filters: IAssetTypeFilters) => void
  initialValues?: IAssetTypeFilters
}

const AssetTypeFilter: React.FC<IProps> = ({ setFilters, initialValues }: IProps): JSX.Element => {
  const { t } = useTranslation(["asset"])
  const [showPdfs, setShowPdfs] = useState(initialValues?.showPdfs || false)
  const [showImages, setShowImages] = useState(initialValues?.showImages || false)
  const [showLinks, setShowLinks] = useState(initialValues?.showLinks || false)
  const [showComments, setShowComments] = useState(initialValues?.showComments || false)
  const [showDecisions, setShowDecisions] = useState(initialValues?.showDecisions || false)
  const [showRecommendations, setShowRecommendations] = useState(initialValues?.showRecommendations || false)

  const setShowAll = () => {
    setShowPdfs(false)
    setShowImages(false)
    setShowLinks(false)
    setShowComments(false)
    setShowDecisions(false)
    setShowRecommendations(false)
  }

  const showAll = (!(showPdfs || showImages || showLinks || showComments || showDecisions || showRecommendations))

  const filters: IAssetTypeFilters = {
    showAll,
    showPdfs,
    showImages,
    showLinks,
    showComments,
    showDecisions,
    showRecommendations
  }

  useEffect(() => {
    setFilters(filters)
  }, [showPdfs, showImages, showLinks, showComments, showDecisions, showRecommendations])

  return (
    <Buttons
      className="text-sm"
      buttons={
        [
          <ButtonFilter key={0} isOn={showAll} action={() => (setShowAll())}>
            {t("filterAll")}
          </ButtonFilter>,
          <ButtonFilter key={1} isOn={showPdfs} action={() => (setShowPdfs(!showPdfs))}>
            {t("filterPdfs")}
          </ButtonFilter>,
          <ButtonFilter key={2} isOn={showImages} action={() => (setShowImages(!showImages))}>
            {t("filterImages")}
          </ButtonFilter>,
          <ButtonFilter key={3} isOn={showLinks} action={() => (setShowLinks(!showLinks))}>
            {t("filterLinks")}
          </ButtonFilter>,
          <ButtonFilter key={4} isOn={showComments} action={() => (setShowComments(!showComments))}>
            {t("filterComments")}
          </ButtonFilter>,
          <ButtonFilter key={5} isOn={showDecisions} action={() => (setShowDecisions(!showDecisions))}>
            {t("filterDecisions")}
          </ButtonFilter>,
          <ButtonFilter key={6} isOn={showRecommendations} action={() => (setShowRecommendations(!showRecommendations))}>
            {t("filterRecommendations")}
          </ButtonFilter>
        ]
      }>
    </Buttons >
  )
}

export const initialTypeFilterState = {
  showAll: true,
  showPdfs: true,
  showImages: true,
  showLinks: true,
  showComments: true,
  showDecisions: true,
  showRecommendations: true
}

export default AssetTypeFilter
