import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import useIsMobileView from "../../../../shared/hooks/useIsMobileView"
import { getChatById } from "../../../../shared/selectors/message"
import CaseListItemContainer from "../../../cases/components/list/CaseListItemContainer"
import ChatCaseTag from "../../../chats/components/list/ChatCaseTag"
import { IChat } from "../../state/chatTypes"

interface Props {
  chatId: string
}

const ChatCaseDetails: React.FC<Props> = (props: Props) => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  const isMobileView = useIsMobileView(["sm", "md"])

  if (!chat?.case) return null

  if(isMobileView) return (
    <ChatCaseTag caseId={chat.case} />
  )

  return (
    <ul>
      <CaseListItemContainer
        caseId={chat.case}
        hasSmallText
      />
    </ul>
  )
}

export default ChatCaseDetails
