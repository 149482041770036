import * as Yup from "yup"
import i18n from "../../../app/i18n"

export const caseUpdateValidationSchema = (): Yup.AnyObjectSchema => {

  return Yup.object().shape({
    caseType: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    caseClassificationId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    leadCareUserId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    sourceId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    signedOffBy: Yup
      .string()
      .when("isSignedOffByRequired", {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
  })
}
