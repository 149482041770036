import _ from "lodash"
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../shared/state/sharedTypes"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import SvgIconCalendar from "../../../svg/icons/IconCalendar"
import MeetingDateTime from "../../meeting/components/header/MeetingDateTime"
import { getMeetingById } from "../../../shared/selectors/meeting"
import { IAppState } from "../../../app/appTypes"
import { IAddCaseToMeetingUIState } from "../state/addCaseToMeetingTypes"
import { getAddCaseToMeetingUI } from "../../../shared/selectors/ui"
import AddCaseToMeetingCaseList from "./AddCaseToMeetingCaseList"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import ResultsPerPage from "../../../shared/components/search/ResultsPerPage"
import SearchInput from "../../../shared/components/search/SearchInput"
import Pagination from "../../../shared/components/search/Pagination"
import { fetchCasesAddableToMeetingsAsync } from "../state/addCaseToMeetingActions"
import { ScrollActionTypes } from "../../scroll/state/scrollTypes"
import useSearch, { ISearchQuery } from "../../../shared/hooks/useSearch"

interface Props {
  meetingId: string
  handleClose: () => void
  isOpen: boolean
}

const AddCaseToMeetingModal = (props: Props): JSX.Element  => {
  const { t } = useTranslation(["shared", "meeting"])
  const { careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  const UI: IAddCaseToMeetingUIState = useSelector((state: IAppState) => getAddCaseToMeetingUI(state))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId: props.meetingId }))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchCasesAddableToMeetingsAsync)

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.handleClose} style={defaultModalStyle}>
      <ModalContainer handleClose={props.handleClose}>
        <h2 className="text-lg mb-3">
          {t("meeting:addCaseIntro")}
        </h2>

        <div className="md:flex md:text-lg flex-row font-semibold mb-3">
          <SvgIconCalendar className="text-ch-purple-500 self-center mr-1 w-5 h-5" />
          <p>
            {meeting.name} {t("shared:on")}&nbsp;
            </p>
          <MeetingDateTime
            startDate={meeting.startDate}
            endDate={meeting.endDate}
          />
        </div>

        <div className="mb-3">
          <p className="text-ch-blue-600">{t("meeting:addCaseDesc1")}</p>
          <p className="text-ch-blue-600">{t("meeting:addCaseDesc2")}</p>
        </div>

        <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
          <div className="row-start-2 md:row-start-1 self-center">
            <Trans
              i18nKey="case:searchBy"
              components={{
                strong: <strong />
              }}
              values={{ careProviderIdLabel: careProviderIdLabel }}
            />
          </div>
          <div className="row-end-2 md:row-start-1 flex justify-end">
            <ResultsPerPage
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          buttonText={t("case:searchCases")}
        />

        <h2 className="text-lg mb-2">
          {UI.totalCasesCount} {t("shared:cases")}
        </h2>

        <AddCaseToMeetingCaseList meetingId={props.meetingId} />

        <Pagination
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          totalItems={UI.totalCasesCount}
          totalPages={UI.totalPages}
          scrollActionType={ScrollActionTypes.UPDATE_MODAL_SCROLL_TO_POSITION}
        />

      </ModalContainer>
    </Modal>
  )
}

export default AddCaseToMeetingModal
