import { Form, Formik } from "formik"
import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { IUserNewData, UserTitles } from "../../user/state/userTypes"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import { createUserAction } from "../../user/state/userActions"
import { IAppState } from "../../../app/appTypes"
import { TSelectFieldOptions } from "../../../shared/components/forms/StandardSelectField"
import { userNewValidationSchema } from "../state/userNewValidation"
import { ISpecialty } from "../../specialty/state/specialtyTypes"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import SelectField from "../../../shared/components/forms/SelectField"
import { getAllUsers } from "../../../shared/selectors/user"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import Card from "../../../shared/components/layout/Card"
import ExistingUserWarning from "../../user/components/existingUserWarning/ExistingUserWarning"

const UserNewForm = (): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const specialties: { [id: string]: ISpecialty } = useSelector((state: IAppState) => getAllSpecialties(state))
  const dispatch = useDispatch()
  const [specialtiesOptions, setSpecialtiesOptions] = React.useState([])

  const initialValues: IUserNewData = {
    title: "",
    professionalTitle: "",
    firstName: "",
    lastName: "",
    email: "",
    specialtyIds: [],
    local: true
  }

  const titleOptions: TSelectFieldOptions = Object.keys(UserTitles).map((key) => {
    return {
      value: UserTitles[key],
      label: key
    }
  })

  const usersEmail = _.map(useSelector((state: IAppState) => getAllUsers(state)), "email")

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(specialties)
    setSpecialtiesOptions(options)
  }, [specialties])

  return (
    <Card className="flex justify-left pl-4">
      <Formik
        initialValues={initialValues}
        validationSchema={userNewValidationSchema(usersEmail)}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          dispatch(createUserAction(values as IUserNewData))
          setSubmitting(false)
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          isValid
        }) => {
          return (
            <Form className="w-full lg:w-2/3">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="title">
                  {t("user:title")}
                </Label>
                <SelectField
                  name="title"
                  options={titleOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="professionalTitle">
                  {t("user:professionalTitle")}
                </Label>
                <InputField
                  type="text"
                  name="professionalTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.professionalTitle}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.professionalTitle as string} isVisible={(errors.professionalTitle && touched.professionalTitle) as boolean} />
              </div>

              <ExistingUserWarning />

              <div className="mb-2">
                <Label name="firstName" required={true}>
                  {t("user:firstName")}
                </Label>
                <InputField
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.firstName as string} isVisible={(errors.firstName && touched.firstName) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="lastName" required={true}>
                  {t("user:lastName")}
                </Label>
                <InputField
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.lastName as string} isVisible={(errors.lastName && touched.lastName) as boolean} />
              </div>


              <div className="mb-2">
                <Label name="email" required={true}>
                  {t("user:email")}
                </Label>
                <InputField
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.email as string} isVisible={(errors.email && touched.email) as boolean} />
              </div>

              <div className="mb-6">
                <Label name="specialtyIds">
                  {t("user:specialties")}
                </Label>
                <SelectField
                  name="specialtyIds"
                  options={specialtiesOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isMulti
                  isGrouped
                  closeMenuOnSelect={false}
                />
                <FieldError errorMessage={errors.specialtyIds as string} isVisible={(errors.specialtyIds && touched.specialtyIds) as boolean} />
              </div>

              <div className="flex justify-center">
                <Button
                  isDisabled={isSubmitting || !isValid}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                >
                  {t("user:createUser")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default UserNewForm
