import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import LiveMeetingCaseSelectItem from "./LiveMeetingCaseSelectItem"
import { getMeetingCaseIds } from "../../../../shared/selectors/meeting"

interface IProps {
  meetingId: string;
  closeModal: () => void;
}

const LiveMeetingCaseSelect: React.FC<IProps> = ({ meetingId, closeModal }): JSX.Element => {
  const { t } = useTranslation(["case"])
  const meetingCaseIds = useSelector((state: IAppState) => getMeetingCaseIds(state, { meetingId }))

  if (!meetingCaseIds.length) return <span>{t("case:noCases")}</span>

  return (
    <ul className="p-2 border-ch-gray-300 border">
      {meetingCaseIds.map((meetingCaseId: string) => {
        return (
          <LiveMeetingCaseSelectItem
            key={meetingCaseId}
            closeModal={closeModal}
            meetingCaseId={meetingCaseId}
          />
        )
      })}
    </ul>
  )
}

export default LiveMeetingCaseSelect
