/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { fileUploadOptions } from "../../helpers/fileHelpers"

interface IProps {
  className?: string
  name: string
  value?: string
  onChange: (e: React.ChangeEvent<any>) => void
  isDisabled?: boolean
  acceptedFileTypes?: string
}

const defaultProps = {
  isDisabled: false,
  acceptedFileTypes: fileUploadOptions.acceptedFileTypes.join(", ")
}

const FileUploadField: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <input
      className="cursor-pointer"
      id="file" name="file"
      type="file"
      disabled={props.isDisabled}
      accept={props.acceptedFileTypes}
      onChange={props.onChange}
    />
  )
}

FileUploadField.defaultProps = defaultProps

export default FileUploadField
