import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconReject({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.157 11.24L17.4 15l3.762 3.762a.726.726 0 01.001 1.027l-1.373 1.368a.727.727 0 01-1.027.002l-.002-.002-3.76-3.757-3.763 3.762a.726.726 0 01-1.027.002v-.002L8.842 19.79a.725.725 0 010-1.027v-.001L12.6 15l-3.762-3.762a.724.724 0 010-1.026v-.002l1.367-1.367a.726.726 0 011.028 0L15 12.6l3.761-3.762a.728.728 0 011.028-.001h.001l1.367 1.368a.726.726 0 01.001 1.027v.009zM15 27.1C8.317 27.1 2.9 21.682 2.9 15 2.9 8.318 8.317 2.9 15 2.9c6.682 0 12.1 5.418 12.1 12.1.004 6.679-5.407 12.096-12.086 12.1H15zM15 0C6.716 0 0 6.716 0 15c0 8.285 6.716 15 15 15 8.284 0 15-6.715 15-15 0-8.284-6.716-15-15-15z"
      />
    </svg>
  );
}

export default SvgIconReject;
