import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import queryString from "query-string"
import humps from "humps"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { ICheckUserAction, UserActionTypes } from "../userTypes"
import { userSchema } from "../../../../apis/contextmeeting/schema"

export function* checkUserAsync(action: ICheckUserAction): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload.userCheckData), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/users/check?${query}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, [userSchema])

    yield put({
      type: UserActionTypes.CHECK_USER_ASYNC_FOUND,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: UserActionTypes.CHECK_USER_ASYNC_NOT_FOUND,
      payload: error
    })
  }
}

export function* watchCheckUserAsync(): Generator {
  yield takeLeading(UserActionTypes.CHECK_USER_ASYNC_PENDING, checkUserAsync)
}
