import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import _ from "lodash"
import humps from "humps"
import { IAppState } from "../../../../app/appTypes"
import TextLink from "../../../../shared/components/text/TextLink"
import { getNotificationById, getNotificationSenderUser, getNotificationUsers } from "../../../../shared/selectors/notification"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"

interface IProps {
  store?: MockStore
  notificationId: string
}

const NotificationDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "notification"])
  const notification = useSelector((state: IAppState) => getNotificationById(state, props))
  const notificationUsers = useSelector((state: IAppState) => getNotificationUsers(state, props))
  const senderUser = useSelector((state: IAppState) => getNotificationSenderUser(state, props))

  if (!notification) return null


  const renderHeading = (): JSX.Element => {
    const translationKey: string = humps.camelize(notification.type)

    return (
      <h4 className="font-semibold mb-1">
        <TextLink href={notification.url}>
          {t(`notification:${translationKey}`, { sender: senderUser })}
        </TextLink>
      </h4>
    )
  }

  const renderLabel = (): JSX.Element => {
    if (!notification.label) return null

    return (
      <h5 className="font-semibold mb-1">
        {notification.label}
      </h5>
    )
  }

  const renderPreviewTitle = (): JSX.Element => {
    if (!notification.title) return null

    return (
      <span className="whitespace-nowrap font-semibold mr-1">
        {`${notification.title}:`}
      </span>
    )
  }

  const renderPreviewBody = (): JSX.Element => {
    if (!notification.body) return null

    const body = `${notification.body}`.replace(/\n/g, " ")

    return (
      <div className="min-w-0 w-0 flex-1">
        <MentionFieldReadOnly
          value={body}
          users={notificationUsers}
          isSingleLine={true}
        />
      </div>
    )
  }

  const renderPreview = (): JSX.Element => {
    if (!notification.title && !notification.body) return null

    return (
      <div className="flex flex-wrap mb-1">
        {renderPreviewTitle()}
        {renderPreviewBody()}
      </div>
    )
  }

  const renderTimestamp = (): JSX.Element => {
    return (
      <TimeDefaultFormat
        className="text-ch-gray-500"
        utcTime={notification.createdAt}
      />
    )
  }

  return (
    <div className="flex-1">
      {renderHeading()}
      {renderLabel()}
      {renderPreview()}
      {renderTimestamp()}
    </div>
  )
}

export default NotificationDetails
