import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
}

type IProps = IPropsFromParent

const QuickViewCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-1",
    "mb-2",
    "bg-ch-gray-300",
    "rounded-md",
    "p-4",
    "justify-between",
    "items-center",
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      { props.children }
    </div>
  )
}

export default QuickViewCard
