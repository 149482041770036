import { MeetingActionTypes, IFetchMeetingAsyncAction, ICreateMeetingAction, IStartMeetingAsyncAction, IEndMeetingAsyncAction, IUpdateMeetingAction, IMeetingNewData, ICancelMeetingAsyncAction } from "./meetingTypes"

export const fetchMeetingAction = (meetingId: string): IFetchMeetingAsyncAction => {
  return {
    type: MeetingActionTypes.FETCH_MEETING_ASYNC_PENDING,
    payload: meetingId
  }
}

export const startMeetingAction = (meetingId: string): IStartMeetingAsyncAction => {
  return {
    type: MeetingActionTypes.START_MEETING_ASYNC_PENDING,
    payload: meetingId
  }
}

export const createMeetingAction = (meetingData: IMeetingNewData): ICreateMeetingAction => {
  return {
    type: MeetingActionTypes.CREATE_MEETING_ASYNC_PENDING,
    payload: meetingData
  }
}

export const updateMeetingAction = (meetingId: string, meetingData: IMeetingNewData): IUpdateMeetingAction => {
  return {
    type: MeetingActionTypes.UPDATE_MEETING_ASYNC_PENDING,
    payload: {
      id: meetingId,
      meeting: meetingData
    }
  }
}

export const endMeetingAction = (meetingId: string): IEndMeetingAsyncAction => {
  return {
    type: MeetingActionTypes.END_MEETING_ASYNC_PENDING,
    payload: meetingId
  }
}

export const cancelMeetingAction = (meetingId: string): ICancelMeetingAsyncAction => {
  return {
    type: MeetingActionTypes.CANCEL_MEETING_ASYNC_PENDING,
    payload: meetingId
  }
}
