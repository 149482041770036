import React, { useEffect, useRef } from "react"
import MessageListItem from "./MessageListItem"
import { useSelector } from "react-redux"
import { IChat } from "../../../chat/state/chatTypes"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"
import useSearch, { ISearchQuery } from "../../../../shared/hooks/useSearch"
import IChatMessagesUIState from "../../state/messagesTypes"
import { getChatMessagesUI } from "../../../../shared/selectors/ui"
import { fetchChatMessagesAsync } from "../../state/messagesActions"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import InfiniteScroll from "react-infinite-scroll-component"
import { useTranslation } from "react-i18next"
import CurrentlyTypingIndicator from "./CurrentlyTypingIndicator"

interface Props {
  chatId: string
}

const MessageList: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const selectedChat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const UI: IChatMessagesUIState = useSelector((state: IAppState) => getChatMessagesUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    resultsPerPage: UI.resultsPerPage,
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, () => fetchChatMessagesAsync(props.chatId, searchQuery))
  const messageListContainerRef = useRef<HTMLDivElement>(null)

  const hasMore = (): boolean => {
    if(searchQuery.page < UI.totalPages) return true
    return false
  }

  const loadMore = (): void => {
    setSearchQuery({
      page: searchQuery.page + 1
    })
  }

  useEffect(() => {
    // load enough messages to fill the scrolling area
    if(!selectedChat.messages) return
    if(
      hasMore &&
      messageListContainerRef.current.scrollHeight <= messageListContainerRef.current.clientHeight
    ) loadMore()
  }, [selectedChat.messages])

  if(
    !selectedChat.messages &&
    UI.isLoading
  ) return <LoadingSpinner />

  return (
    <div
      id="messageListContainer"
      ref={messageListContainerRef}
      className="flex h-full overflow-y-scroll flex-col-reverse"
    >
      <CurrentlyTypingIndicator chatId={props.chatId} />
      <InfiniteScroll
        dataLength={selectedChat.messages?.length || 0}
        next={loadMore}
        className="flex flex-col-reverse p-4"
        inverse={true}
        hasMore={hasMore()}
        loader={
          <div className="flex justify-center p-10">
            <LoadingSpinner />
          </div>
        }
        scrollableTarget="messageListContainer"
        endMessage={
          <div className="flex justify-center text-ch-gray-400 italic text-sm py-2">
            {t("message:index:endOfMessages")}
          </div>
        }
      >
        {(selectedChat.messages || []).map((messageId: string) => (
          <MessageListItem
            key={messageId}
            messageId={messageId}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}


export default MessageList
