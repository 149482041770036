import React from "react"
import Button from "../../../shared/components/buttons/Button"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { sendResetPasswordLinkAction } from "../state/accountActions"
import TextLink from "../../../shared/components/text/TextLink"
import { MockStore } from "redux-mock-store"

interface IPropsFromParent {
  store?: MockStore
  usernameRef: React.MutableRefObject<HTMLInputElement>
  toggleView: (value: boolean) => void
}

interface IPropsFromDispatch {
  sendResetLink: (email: string) => void
}

type Props = IPropsFromParent & IPropsFromDispatch

const ResetPasswordInstructions = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "account"])

  const username =
    props.usernameRef && props.usernameRef.current && props.usernameRef.current.defaultValue ?
      props.usernameRef.current.defaultValue : ""

  const onSubmitPasswordReset = () => {
    props.sendResetLink(username)
  }

  return (
    <div className="flex-1">
      <div className="flex justify-center">
        <div>
          <h2 className="text-lg mb-4">
            {t("account:forgottenYourPassword")}
          </h2>
          <div className="flex justify-center text-ch-blue-alt-400 mb-2">
            {username}
          </div>
        </div>
      </div>
      <div>
        <p className="mx-4">
          {t("account:sendResetPasswordInstructions")}
        </p>
        <div className="flex justify-center mt-6">
          <Button
            isDisabled={!username || username == ""}
            action={() => onSubmitPasswordReset()}
            variant={StyleVariants.PURPLE}
          >
            {t("account:sendResetLink")}
          </Button>
        </div>
        <div className="flex justify-center mt-6">
          <TextLink action={() => props.toggleView(false)}>
            {t("account:backToLogin")}
          </TextLink>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch): IPropsFromDispatch => {
  return {
    sendResetLink: (email: string) => dispatch(sendResetPasswordLinkAction(email)),
  }
}

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(ResetPasswordInstructions)
