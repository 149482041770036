import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { RoutePaths } from "../../app/routes/Routes"
import MeetingNewForm from "../../features/meetingNew/components/MeetingNewForm"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { fetchAllTeamsAsync } from "../../features/teams/state/teamsActions"

const MeetingNewPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "meeting"])
  const dispatch = useDispatch()
  const fetchSpecialties = () => dispatch(fetchSpecialtiesAsync())
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const fetchAllTeams = () => dispatch(fetchAllTeamsAsync())

  useEffect(() => {
    fetchSpecialties()
    fetchUsers()
    fetchAllTeams()
  }, [])

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:meetings"),
            href: RoutePaths.MEETINGS
          },
          {
            pageTitle: t("meeting:addANewMeeting")
          }
        ]}
      />
      <MeetingNewForm />
    </div>
  )
}

export default MeetingNewPage
