import { normalize } from "normalizr"
import { serialize } from "object-to-formdata"
import humps from "humps"
import { takeLeading, put, call, Effect } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { tasksSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { TaskResponseActionTypes } from "../taskResponseTypes"

export function* createTaskResponseAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const taskId = action.payload.taskId

    const data = {
      taskResponse: action.payload.taskResponse
    }

    const fileData = data.taskResponse.caseAssetAttributes.assetAttributes.file

    if (!fileData) {
      // Remove asset attributes if no file attached
      delete data.taskResponse.caseAssetAttributes
    } else {
      // We have to delete and add file data later as decamelizeKeys seems to destory the data
      delete data.taskResponse.caseAssetAttributes.assetAttributes.file
    }

    const decamelizedData = humps.decamelizeKeys(data)
    const formData = serialize(decamelizedData)

    if (fileData) {
      formData.append("task_response[case_asset_attributes][asset_attributes][file]", fileData)
    }

    const config = withAuthHeader()
    config.headers["Content-Type"] = "multipart/form-data"

    const response: any = yield call(API.post, `/cases/${caseId}/tasks/${taskId}/task_responses`, formData, config)

    const normalizedResponse = normalize(response.data, tasksSchema)

    yield put({
      type: TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId,
        taskId: taskId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "createTaskResponseSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createTaskResponseFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateTaskResponseAsync(): Generator {
  yield takeLeading(TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_PENDING, createTaskResponseAsync)
}
