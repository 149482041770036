import { Form, Formik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import * as Yup from "yup"
import { IAppState } from "../../../../../app/appTypes"
import i18n from "../../../../../app/i18n"
import Button from "../../../../../shared/components/buttons/Button"
import DatePickerField from "../../../../../shared/components/datePicker/DatePickerField"
import FieldError from "../../../../../shared/components/forms/FieldError"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import SelectField from "../../../../../shared/components/forms/SelectField"
import { TSelectFieldUserOptions } from "../../../../../shared/components/forms/StandardSelectField"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../../../shared/components/label/UserOption"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { daysFromNowToDate, utcDateToDaysFromNow } from "../../../../../shared/helpers/timeHelpers"
import { getCaseMemberUsers } from "../../../../../shared/selectors/case"
import { getTaskById } from "../../../../../shared/selectors/task"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { ICasePathwayTaskUpdateData } from "../../../../tasks/state/tasksTypes"
import { IUser } from "../../../../user/state/userTypes"
import { UserConfigContext } from "../../../../userConfig/contexts/UserConfigContext"

interface IAssignTaskProps {
  taskId: string
  cancelHandler: () => void
  submitHandler: (values: ICasePathwayTaskUpdateData) => void
}

const validationSchema = Yup.object().shape(
  {
    userIds: Yup.array()
      .of(Yup.string())
      .nullable()
      .min(1, () => i18n.t("shared:required")),
    dueDate: Yup.string()
      .nullable()
      .required(i18n.t("shared:required")),
    daysFromNow: Yup.number()
  }
)

const AssignCasePathwayTaskForm: React.FC<IAssignTaskProps> = (props: IAssignTaskProps): JSX.Element => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const { timezone } = useContext(UserConfigContext)
  const { t } = useTranslation(["shared", "task"])
  const [userOptions, setUserOptions] = useState([])
  const mentionableUsers: { [id: string]: IUser} = useSelector((state: IAppState) => getCaseMemberUsers(state, {caseId: task.caseId}))

  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(mentionableUsers))
    setUserOptions(options)
  }, [mentionableUsers])


  const initialValues = {
    userIds: task.users,
    daysFromNow: utcDateToDaysFromNow(task.dueDate || new Date().toDateString(), timezone),
    dueDate: task.dueDate ? new Date(task.dueDate) : new Date()
  }

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(true)
      props.submitHandler(values)
    }}
  >
    {({
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      dirty,
      setFieldValue,
      errors,
      touched,
      isValid
    }) => {
      const onChangeDueDate = (val: string) => {
        const daysFromNow = val ? utcDateToDaysFromNow(val, timezone) : ""
        setFieldValue("dueDate", val)
        setFieldValue("daysFromNow", daysFromNow)
      }

      const onChangeDaysFromNow = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ? daysFromNowToDate(e.target.value) : ""
        setFieldValue("dueDate", value)
        handleChange(e)
      }

      return (
        <Form>
          <div className="mb-6 w-full">
            <div className="mb-2">
              <Label name="userIds">
                {t("task:assignees")}
              </Label>
              <SelectField
                name="userIds"
                options={userOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                isMulti={true}
                closeMenuOnSelect={false}
                filterOption={filterOptionsBySearchValue}
              />
              <FieldError
                errorMessage={errors.userIds as string}
                isVisible={(!!errors.userIds)}
              />
            </div>
            <div>
              <Label name="dueDate">
                {t("case:casePathways:dueDate")}:
              </Label>
              <div className="flex flex-wrap">
                <div className="flex mr-4 mb-1">
                  <div className="flex flex-col">
                    <DatePickerField name="dueDate" onChange={onChangeDueDate} />
                    <FieldError
                      errorMessage={errors.dueDate as string}
                      isVisible={(errors.dueDate && touched.dueDate) as boolean}
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-row items-center">
                    <Label className="mr-2" name="daysFromNow">
                      {t("OR")}
                    </Label>
                    <InputField
                      type="number"
                      name="daysFromNow"
                      onChange={onChangeDaysFromNow}
                      onBlur={handleBlur}
                      value={values.daysFromNow.toString()}
                      autoComplete="off"
                      className="lg:w-1/4 md:w-1/2"
                    />
                    <p className="ml-2">
                      {t("daysFromNow")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-6">
            <Buttons buttons={[
              <Button
                key="1"
                variant={StyleVariants.BLUE_ALT}
                action={() => props.cancelHandler()}
              >
                {t("shared:cancel")}
              </Button>,
              <Button
                key="2"
                isDisabled={!dirty || !isValid || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}
              >
                {t("case:casePathways:buttons:assignTask")}
              </Button>]}
            />
          </div>
        </Form>
      )
    }}
  </Formik >
}

export default AssignCasePathwayTaskForm
