import React from "react"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { IconMeeting } from "../../../svg/icons"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../shared/components/layout/detailsCard/DetailsCardRight"
import MeetingDetails from "../../meeting/components/details/MeetingDetails"

interface IPropsFromParent {
  store?: MockStore
  meetingId: string
  children: React.ReactNode
}

type Props = IPropsFromParent

const MeetingListItemContainer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "meeting"])

  const renderContent = (): JSX.Element => {
    return (
      <li>
        <DetailsCard>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-4">
              <IconMeeting className="fill-current w-6 h-6" title={t("shared:meeting")} />
            </IconCircleContainer>
            <MeetingDetails meetingId={props.meetingId} />
          </DetailsCardInner>

          <DetailsCardRight>
            {props.children}
          </DetailsCardRight>
        </DetailsCard>
      </li>
    )
  }
  return renderContent()
}

export default MeetingListItemContainer
