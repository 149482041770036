import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { caseSourcesSchema } from "../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { CaseSourceActionTypes } from "./caseSourceTypes"

export function* fetchCaseSourceAsync(_action): Generator {
  try {
    const response: any = yield call(API.get, "/sources/", withAuthHeader())

    const normalizedResponse = normalize(response.data, [caseSourcesSchema])

    yield put({
      type: CaseSourceActionTypes.FETCH_CASE_SOURCES_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: CaseSourceActionTypes.FETCH_CASE_SOURCES_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchCaseSourceAsync(): Generator {
  yield takeLeading(CaseSourceActionTypes.FETCH_CASE_SOURCES_ASYNC_PENDING, fetchCaseSourceAsync)
}
