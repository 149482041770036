import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import { getComponentTypeForAsset } from "../../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../../shared/selectors/asset"
import { AssetComponentTypes, ICommentAsset, IDocumentAsset } from "../../../../assets/state/assetsTypes"

interface IProps {
  assetId: string
}

const LiveMeetingSelectedAssetHeading: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  if (!asset) return null

  const assetComponetType = getComponentTypeForAsset(asset)
  const headingLookup = {
    [AssetComponentTypes.Comment]: (asset.asset as ICommentAsset).title,
    [AssetComponentTypes.Decision]: (asset.asset as ICommentAsset).title,
    [AssetComponentTypes.Deferral]: (asset.asset as ICommentAsset).title,
    [AssetComponentTypes.Recommendation]: (asset.asset as ICommentAsset).title,
    [AssetComponentTypes.Pdf]: (asset.asset as IDocumentAsset).name,
    [AssetComponentTypes.Image]: (asset.asset as IDocumentAsset).name,
  }

  // we need to set some fallback content for the heading, otherwise the close button will end up in the wrong place
  const headingText: string = headingLookup[assetComponetType] || "\u00A0" // that's a nbsp

  return (
    <h3 className="flex items-center justify-center flex-1 font-thin">
      {headingText}
    </h3>
  )
}

export default LiveMeetingSelectedAssetHeading
