import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAssets({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <g fill="currentColor">
          <path d="M20.667 6.667h-12A.667.667 0 008 7.333V22c0 .368.298.667.667.667h12a.667.667 0 00.666-.667V7.333a.667.667 0 00-.666-.666zm-2 10.666h-8V16h8v1.333zm0-2.666h-8v-1.334h8v1.334zm0-2.667h-8v-1.333h8V12z" />
          <path d="M4 16V2.667h12V2a.667.667 0 00-.667-.667h-12A.667.667 0 002.667 2v14.667c0 .368.298.666.666.666H4V16z" />
          <path d="M6.667 18.667V5.333h12v-.666A.667.667 0 0018 4H6a.667.667 0 00-.667.667v14.666c0 .369.299.667.667.667h.667v-1.333z" />
        </g>
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconAssets;
