import React from "react"
import { Formik, Form } from "formik"
import { IPathwayStep } from "../pathwayTypes"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import Buttons from "../../../shared/components/layout/Buttons"
import { updatePathwayStepAction } from "../pathwayActions"
import { useDispatch } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { useTranslation } from "react-i18next"
import PathwayHeader from "./PathwayHeader"

interface IProps {
  step: IPathwayStep
  pathwayId: string
  setStep: (step: IPathwayStep) => void
}

const EditPathwayStep: React.FC<IProps> = ({ step, pathwayId, setStep }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "pathway"])

  return (
    <>
      <h2 className="text-lg mb-3">
        {t("pathway:details:editStepHeader")}:
      </h2>
      <PathwayHeader pathwayId={pathwayId} hideButtons />
      <Formik
        initialValues={step}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          dispatch(updatePathwayStepAction(pathwayId, values))
          setStep(null)
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          dirty,
          values
        }) => {
          if (!step) return null
          return (
            <Form className="w-full lg:w-2/3 mt-4">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="name" required={true}>
                  {t("pathway:details:stepName")}:
                    </Label>
                <InputField
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  autoFocus={true}
                  value={values.name}
                />
                <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="description" required={true}>
                  {t("pathway:details:stepDescription")}:
                    </Label>
                <InputField
                  as="textarea"
                  name="description"
                  className="h-24"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  value={values.description}
                />
                <FieldError errorMessage={errors.description as string} isVisible={(errors.description && touched.description)} />
              </div>

              <div className="flex justify-center mb-6">
                <Buttons buttons={[
                  <Button
                    key="1"
                    variant={StyleVariants.BLUE_ALT}
                    action={() => setStep(null)}
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    key="2"
                    isDisabled={!dirty || isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                  >
                    {t("pathway:details:updateStep")}
                  </Button>]} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default EditPathwayStep
