import React from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import { IPatientEditData } from "../../../patientEdit/state/patientEditTypes"
import AmendmentList from "../../../patientEdit/components/reasonForChange/AmendmentList"

interface Props {
  data: IPatientEditData
  patientId: string
}

const AmendmentDetailsFormCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "patient"])

  const renderReason = (): JSX.Element => {
    return (
      <li>
        {t("patient:reasonForChange:details:reason")}: {props.data.patientAmendmentsAttributes[0].reason}
      </li>
    )
  }

  const renderTypeOfChange = (): JSX.Element => {
    const translationKey = humps.camelize(props.data.patientAmendmentsAttributes[0].amendmentType)
    return (
      <li>
        {t("patient:reasonForChange:details:amendmentType")}: {t(`patient:reasonForChange:details:${translationKey}`)}
      </li>
    )
  }

  return (
    <DetailsCard>
      <DetailsCardInner>
        <div className="flex-1 lg:grid grid-cols-2 gap-3">
          <AmendmentList data={props.data} patientId={props.patientId} className="col-span-1" />

          <ul className="col-span-1">
            { renderReason() }
            { renderTypeOfChange() }
          </ul>
        </div>
      </DetailsCardInner>
    </DetailsCard>
  )
}

export default AmendmentDetailsFormCard
