import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { generatePath } from "react-router"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import ButtonChangeTracking from "../../../../shared/components/buttons/ButtonChangeTracking"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconMeeting } from "../../../../svg/icons"
import { startMeetingAction } from "../../state/meetingActions"
import { IMeeting } from "../../state/meetingTypes"
import MeetingDetails from "../details/MeetingDetails"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import Breadcrumbs from "../../../../shared/components/ui/Breadcrumbs"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import MeetingEndModal from "../modals/MeetingEndModal"
import MeetingCancelModal from "../modals/MeetingCancelModal"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import QuickView from "../../../../shared/components/ui/quickView/QuickView"
import { fetchAuditEventsAsync } from "../../../audit/state/auditEventActions"
import MeetingChangeTracking from "./MeetingChangeTracking"

interface IPropsFromParent {
  meetingId: string
}

const MeetingHeader = (props: IPropsFromParent): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "meeting"])
  const [cancelModalIsOpen, setCancelModalIsOpen] = React.useState(false)
  const [endModalIsOpen, setEndModalIsOpen] = React.useState(false)

  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, { meetingId: props.meetingId }))
  const liveMeetingUrl = generatePath(RoutePaths.MEETING_LIVE, {
    meetingId: props.meetingId
  })

  if (!meeting) return null
  const renderStartOrJoinButton = (): JSX.Element => {
    if (meeting?.status == MeetingStatuses.InProgress && meeting.permissions?.canJoin) {
      return (
        <Button
          href={liveMeetingUrl}
          variant={StyleVariants.ORANGE}
          key="2"
        >
          {t("meeting:joinMeeting")}
        </Button>
      )
    }
    else if (meeting?.status == MeetingStatuses.Scheduled) {
      return (
        <Button
          action={() => dispatch(startMeetingAction(props.meetingId))}
          variant={StyleVariants.ORANGE}
          key="2"
        >
          {t("meeting:startMeeting")}
        </Button>
      )
    }
    else return null
  }

  const renderEditButton = (): JSX.Element => {
    if (meeting?.status != MeetingStatuses.Scheduled || !meeting.permissions?.canEdit) return null
    const editMeetingUrl = generatePath(RoutePaths.MEETING_EDIT, {
      meetingId: props.meetingId
    })

    return (
      <Button
        href={editMeetingUrl}
        variant={StyleVariants.PURPLE}
        key="1"
      >
        {t("meeting:editMeeting")}
      </Button>
    )
  }

  const renderEndButton = (): JSX.Element => {
    if (meeting?.status == MeetingStatuses.InProgress && meeting.permissions?.canEnd) return (
      <Button
        action={() => setEndModalIsOpen(true)}
        variant={StyleVariants.PURPLE}
        key="1"
      >
        {t("meeting:endMeeting")}
      </Button>
    ); else return null
  }

  const renderCancelButton = (): JSX.Element => {
    if (
      meeting.permissions?.canCancel &&
      meeting?.status == MeetingStatuses.Scheduled
    ) return (
      <Button
        action={() => setCancelModalIsOpen(true)}
        variant={StyleVariants.PURPLE}
        key="1"
      >
        {t("meeting:cancelMeeting")}
      </Button>
    ); else return null
  }

  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:meetings"),
              href: RoutePaths.MEETINGS
            },
            {
              pageTitle: meeting.name
            }
          ]}
        />
        <ButtonChangeTracking
          hasPermission={meeting?.permissions?.canTrackChanges}
          action={() => {
            dispatch(fetchAuditEventsAsync("Meeting", props.meetingId))
            dispatch(toggleIsQuickViewOpen(props.meetingId, true))
          }}
        />
      </div>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconMeeting className="fill-current w-6 h-6" title={meeting.name} />
          </IconCircleContainer>
          <MeetingDetails meetingId={props.meetingId} hasLink={false} showMeetingDueIn />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            renderCancelButton(),
            renderEditButton(),
            renderEndButton(),
            renderStartOrJoinButton()
          ]} />

          {meeting.status == MeetingStatuses.Ended &&
            <div className="text-right">
              {t("meeting:meetingEnded")}
            </div>
          }
        </DetailsCardRight>
      </DetailsCard>

      <MeetingEndModal
        meetingId={props.meetingId}
        isOpen={endModalIsOpen}
        close={() => setEndModalIsOpen(false)}
      />

      <MeetingCancelModal
        meetingId={props.meetingId}
        isOpen={cancelModalIsOpen}
        close={() => setCancelModalIsOpen(false)}
      />
      <QuickView>
        <MeetingChangeTracking meetingCaseId={props.meetingId} meeting={meeting} />
      </QuickView>
    </div>
  )
}

export default MeetingHeader
