import { createSelector } from "reselect"
import { IAppState } from "../../../app/appTypes"
import { IExternalUser } from "../../../features/externalUser/state/externalUserTypes"

export const getAllExternalUsers = (state: IAppState): { [id: string]: IExternalUser } => state.entities.externalUsers.byId

export const getAllExternalUserEntities = createSelector(
  getAllExternalUsers,
  (externalUsers: {[id: string]: IExternalUser}): IExternalUser[] => {
    if (!externalUsers) return []

    return Object.values(externalUsers)
  }
)
