import React from "react"
import { useTranslation } from "react-i18next"
import TimeDefaultFormat from "../time/TimeDefaultFormat"

interface Props {
  firstName: string
  lastName: string
  createdAt: string
}

const TextAuthorDetails: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  return (
    <span className="font-thin">
      {t("shared:by")}&nbsp;
      <span className="uppercase">{props.lastName}</span>, {props.firstName}&nbsp;
      {t("shared:on")}&nbsp;
      <TimeDefaultFormat utcTime={props.createdAt} />
    </span>
  )
}

export default TextAuthorDetails
