import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getMessageAssetById } from "../../../../shared/selectors/message"
import { IMessageAsset, IMessageDocumentAsset } from "../../../message/state/messageTypes"

interface Props {
  messageAssetId: string
}

const MessageAssetViewerHeading: React.FC<Props> = (props: Props): JSX.Element => {
  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  if(!messageAsset) return null

  const headingText: string = (messageAsset.asset as IMessageDocumentAsset)?.name

  if(!headingText) return null

  return (
    <h3 className="flex items-center justify-center flex-1 font-thin">
      {headingText}
    </h3>
  )
}

export default MessageAssetViewerHeading
