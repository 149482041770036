import React from "react"

interface IPropsFromParent {
  openText: string
  closedText?: string
}

type Props = IPropsFromParent

const LiveMeetingAssetSelectHeading: React.FC<Props> = (props: Props): JSX.Element => {
  const renderOpenText = (): JSX.Element => {
    return (
      <span>
        {props.openText}
      </span>
    )
  }

  return (
    <h3 className="flex items-center">
      {renderOpenText()}
    </h3>
  )
}

export default LiveMeetingAssetSelectHeading
