import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAccept({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.48 10.779l-1.363-1.374a.726.726 0 00-1.026 0l-8.55 8.48-3.616-3.645a.726.726 0 00-1.026 0L6.524 15.6a.726.726 0 000 1.026l5.49 5.535a.726.726 0 001.027 0l10.435-10.352a.726.726 0 000-1.026l.004-.004zM15 2.899c6.683 0 12.1 5.419 12.1 12.1 0 6.683-5.417 12.1-12.1 12.1S2.9 21.683 2.9 15C2.895 8.324 8.303 2.906 14.98 2.9H15zM15 0C6.716 0 0 6.717 0 15c0 8.286 6.716 15 15 15 8.285 0 15-6.714 15-15 0-8.283-6.715-15-15-15z"
      />
    </svg>
  );
}

export default SvgIconAccept;
