import { normalize } from "normalizr"
import queryString from "query-string"
import humps from "humps"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"
import { AddPathwayToCaseActionTypes } from "../addPathwayToCaseTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"

export function* fetchPathwaysAddableToCaseAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/pathways?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [pathwaysSchema])

    yield put({
      type: AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        searchTerm: response.data.searchQuery.searchTerm,
        appliedFilters: response.data.searchQuery.appliedFilters,
        orderBy: response.data.searchQuery.orderBy
      }
    })
  } catch (error) {
    yield put({
      type: AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "pathwaysNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchPathwaysAddableToCase(): Generator {
  yield takeLeading(AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_PENDING, fetchPathwaysAddableToCaseAsync)
}
