import { IAsset } from "../../assets/state/assetsTypes"

export interface ISharedAsset extends IAsset {
  userSharedAssetId?: string
}

// State
export type TSharedAssetByCasedId = {
  [caseId: string]: string[]
}

export type TSharedAssetByUserId = {
  [userId: string]: TSharedAssetByCasedId
}

export interface ISharedAssetEntityState {
  byId: { [id: string]: ISharedAsset }
  canBeSharedWithUserByUserId: TSharedAssetByUserId
}

// Actions
export interface IFetchCaseAssetsCanBeSharedWithUser {
  type: string
  payload: {
    caseId: string
    userId: string
  }
}

export interface ICreateCaseSharedAssetAsyncAction {
  type: string
  payload: {
    caseId: string
    caseAssetId: string
    userId: string
  }
}

export interface IDeleteCaseSharedAssetAsyncAction {
  type: string
  payload: {
    caseId: string,
    userSharedAssetId: string
    userId: string
  }
}

export enum CaseSharedAssetsActionTypes {
  FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_PENDING = "@@caseSharedAssets/FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_PENDING",
  FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_SUCCESS = "@@caseSharedAssets/FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_SUCCESS",
  FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_ERROR = "@@caseSharedAssets/FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_ERROR",

  CREATE_CASE_SHARED_ASSET_ASYNC_PENDING = "@@caseSharedAssets/CREATE_CASE_SHARED_ASSET_ASYNC_PENDING",
  CREATE_CASE_SHARED_ASSET_ASYNC_SUCCESS = "@@caseSharedAssets/CREATE_CASE_SHARED_ASSET_ASYNC_SUCCESS",
  CREATE_CASE_SHARED_ASSET_ASYNC_ERROR = "@@caseSharedAssets/CREATE_CASE_SHARED_ASSET_ASYNC_ERROR",

  DELETE_CASE_SHARED_ASSET_ASYNC_PENDING = "@@caseSharedAssets/DELETE_CASE_SHARED_ASSET_ASYNC_PENDING",
  DELETE_CASE_SHARED_ASSET_ASYNC_SUCCESS = "@@caseSharedAssets/DELETE_CASE_SHARED_ASSET_ASYNC_SUCCESS",
  DELETE_CASE_SHARED_ASSET_ASYNC_ERROR = "@@caseSharedAssets/DELETE_CASE_SHARED_ASSET_ASYNC_ERROR"
}
