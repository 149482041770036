export const fileUploadOptions = {
  acceptedFileTypes: ["image/jpeg", "image/png", "application/pdf"],
  acceptedCSVTypes: ["text/csv"],
  maxFileSizeMB: 15,
  maxFileSize: 15 * 1024 * 1024,
  maxNameLength: 50
}

export const bytesToSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes == 0) return "n/a"
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
  if (i == 0) return bytes + " " + sizes[i]
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
}

export const isFilesSizeUnderMaximum = (value: File): boolean => {
  if(!value) return true
  return value.size < fileUploadOptions.maxFileSize
}

export const isFileAcceptedType = (value: File): boolean => {
  if(!value) return true
  return value.type && fileUploadOptions.acceptedFileTypes.includes(value.type)
}

export const isCSVAcceptedType = (value: File): boolean => {
  if(!value) return true
  return value.type && fileUploadOptions.acceptedCSVTypes.includes(value.type)
}
