import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import NameUser from "../../../../shared/components/names/NameUser"
import { getUserById } from "../../../../shared/selectors/user"
import { IconChangeTracking, IconMeeting } from "../../../../svg/icons"
import MeetingStatus from "../../../meeting/components/details/MeetingStatus"
import MeetingDateTime from "../../../meeting/components/header/MeetingDateTime"
import MeetingTimeToStart from "../../../meeting/components/header/MeetingTimeToStart"
import { IMeeting, MeetingStatuses } from "../../../meeting/state/meetingTypes"
import { IUser } from "../../../user/state/userTypes"
import _ from "lodash"
import { AuditEventList } from "../../../audit/component/auditEventList/AuditEventList"

interface IPropsFromParent {
  meetingCaseId: string
  meeting: IMeeting
}

const MeetingCaseChangeTracking: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "meeting"])
  const meetingCoordinator = useSelector((state: IAppState): IUser => getUserById(state, props.meeting.coordinatorId))

  const renderMeetingTimeToStart = () => {
    if (props.meeting.status !== MeetingStatuses.Scheduled) return null
    return (
      <dd className="mb-1" >
        <MeetingTimeToStart startDate={props.meeting.startDate} />
      </dd>
    )
  }
  return (
    <div>
      <div className="flex items-center flex-col mb-6">
        <IconCircleContainer className="mb-3">
          <IconMeeting className="fill-current w-6 h-6" title={props.meeting.name} />
        </IconCircleContainer>

        <dl className="text-center mb-3">
          <dt className="mb-1">
            <h5 className="font-semibold">
              {props.meeting.name}
            </h5>
          </dt>
          <dd className="mb-1">
            <MeetingDateTime
              startDate={props.meeting.startDate}
              endDate={props.meeting.endDate}
            />
          </dd>
          <dd className="mb-1">
            {t("meeting:Coordinator")}: <NameUser firstName={meetingCoordinator.firstName} lastName={meetingCoordinator.lastName} />
          </dd>
          <dd className="mb-1">
            <MeetingStatus meeting={props.meeting} />
          </dd>
          {renderMeetingTimeToStart()}
        </dl>
      </div>
      <div className="flex">
        <IconChangeTracking className="fill-current w-6 h-6 md:ml-2" title={t("shared:changeTracking")} /> {t("shared:changeTracking")}
      </div>
      <AuditEventList entityId={props.meetingCaseId}/>
    </div>
  )
}

export default MeetingCaseChangeTracking
