import * as Yup from "yup"
import i18n from "../../../app/i18n"

export const pathwayNewValidationSchema = (): Yup.AnyObjectSchema => {
  const maxNameLength = 100
  const maxDescriptionLength = 500
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(maxNameLength, i18n.t("shared:maxLengthExceeded", { maxLength: maxNameLength })),
    description: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(maxDescriptionLength, i18n.t("shared:maxLengthExceeded", { maxLength: maxDescriptionLength })),
    caseClassificationId: Yup.string()
      .required(i18n.t("shared:required"))
  })
}
