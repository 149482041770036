import React, { useState, useEffect, useRef } from "react"
import * as Yup from "yup"
import i18n from "../../app/i18n"
import { useTranslation } from "react-i18next"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import { Form, Formik } from "formik"
import Label from "../../shared/components/forms/Label"
import InputField from "../../shared/components/forms/InputField"
import Buttons from "../../shared/components/layout/Buttons"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useDispatch } from "react-redux"
import LoadingSpinner from "../../shared/components/ui/LoadingSpinner"
import { IPhoneDetails } from "../../features/account/state/accountTypes"
import history from "../../app/routes/history"
import { RoutePaths } from "../../app/routes/Routes"
import { fetchCurrentUserAction } from "../../features/user/state/userActions"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"

interface IPropsFromParent {
  phoneDetails: IPhoneDetails
  backToIntro: () => void
}

const codeValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    code: Yup.string()
      .required(i18n.t("shared:required"))
      .min(6)
  })
}

const SetupMfaTotpPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["account", "shared"])
  const [qrCodeUrl, setQrCodeUrl] = useState<string>(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const inputRef = useRef(null)
  const dispatch = useDispatch()

  const submitCode = (code: string, setSubmitting: (state: boolean) => void) => {
    API.post("/user/mfa/confirm_totp", { code }, withAuthHeader()).then(() => {
      history.replace(RoutePaths.SETUP_MFA_TOTP_COMPLETE)
      dispatch(fetchCurrentUserAction())
    }).catch(() => {
      setSubmitting(false)
      dispatch(
        displayUserMessageAction({
          messageKey: "mfaInvalidCode",
          type: UserMessageTypes.ERROR
        })
      )
    })
  }

  useEffect(() => {
    if (qrCodeUrl) return
    API.post("/user/mfa/qr_code", { user: props.phoneDetails }, withAuthHeader())
      .then((response) => {
        setQrCodeUrl(response.data.qrCode)
      })
      .catch((error) => {
        props.backToIntro()
        if (error.status == 400) {
          dispatch(
            displayUserMessageAction({
              messageKey: "mfaPhoneNumberAlreadyInUse",
              type: UserMessageTypes.ERROR
            })
          )
        } else {
          dispatch(
            displayUserMessageAction({
              messageKey: "mfaErrorGettingQrCode",
              type: UserMessageTypes.ERROR
            })
          )
        }
      })
  }, [])

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div className="flex flex-col items-center">
      <div className="text-ch-blue-500 text-xl mb-3">
        {t("mfaTotpSetupHeader")}
      </div>
      <div className="mb-3">
        {t("account:mfaTotp1OpenApp")}
      </div>
      <div className="">
        {t("mfaTotp2ScanQrCode")}
      </div>
      {qrCodeUrl
        ? <img src={qrCodeUrl} onLoad={() => setImageLoaded(true)} />
        : null
      }
      {imageLoaded ? null : <LoadingSpinner />}

      <Formik
        initialValues={{ code: "" }}
        onSubmit={null}
        validationSchema={codeValidationSchema}
        validateOnMount
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          setSubmitting
        }) => (
          <Form>
            <div className="flex flex-col items-center leading-tight mb-5">
              <Label name="code" className="mb-3">
                {t("mfaTotp3EnterCode")}
              </Label>
              <div className="m-auto w-full md:w-1/3 mb-5">
                <InputField className="text-xl text-center" innerRef={inputRef} autoComplete="off" name="code" onChange={handleChange} onBlur={handleBlur} />
              </div>
            </div>

            <Buttons
              className="flex-row-reverse space-x-reverse space-x-2"
              buttons={[
                <Button
                  key="2"
                  isDisabled={!isValid || isSubmitting}
                  variant={StyleVariants.BLUE}
                  action={() => { submitCode(values.code, setSubmitting) }}
                >
                  {t("submit")}
                </Button>,
                <Button
                  key="1"
                  isDisabled={isSubmitting}
                  variant={StyleVariants.PURPLE}
                  action={props.backToIntro}
                >
                  {t("back")}
                </Button>
              ]} />
          </Form>
        )
        }
      </Formik>
    </div>
  )
}

export default SetupMfaTotpPage
