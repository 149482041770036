import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { ChatMessagesActionTypes, IFetchChatMessagesAsyncAction, IUpdateNotificationMessage } from "./messagesTypes"

export const fetchChatMessagesAsync = (chatId: string, searchQuery: ISearchQuery): IFetchChatMessagesAsyncAction => {
  return {
    type: ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_PENDING,
    payload: {
      chatId,
      searchQuery
    }
  }
}

export const updateNotificationMessageIdAction = (notificationMessageId: string): IUpdateNotificationMessage => {
  return {
    type: ChatMessagesActionTypes.UPDATE_NOTIFICATION_MESSAGE,
    payload: notificationMessageId
  }
}
