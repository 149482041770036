import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import TimeDefaultFormat from "../../../shared/components/time/TimeDefaultFormat"
import { getCasePathwayById } from "../../../shared/selectors/casePathway"
import { ICasePathway } from "../state/casePathwayTypes"

type Props = {
  casePathwayId: string
}

export const CasePathwayDetails: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  if (!casePathway) return null

  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3">
      <ul className="col-span-1">
        <li>
          <b>{casePathway.name}</b>
        </li>
        <li>
          {t("shared:createdBy")}: {casePathway.createdBy.firstName} {casePathway.createdBy.lastName}
        </li>
      </ul>
      <ul className="col-span-1">
        <li>
          {t("shared:lastUpdated")}: <TimeDefaultFormat utcTime={casePathway.updatedAt || casePathway.createdAt} />
        </li>
        <li>
          {t("case:casePathways:tasks")}: {casePathway.tasks.length}
        </li>
      </ul>
    </div>
  )
}

export default CasePathwayDetails
