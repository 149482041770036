import {
  IFetchAllTeamsAsyncAction,
  IFetchTeamsAsyncAction,
  TeamsActionTypes
} from "./teamsTypes"
import { ISearchQuery } from "../../../shared/hooks/useSearch"

export const fetchTeamsAsync = (searchQuery: ISearchQuery): IFetchTeamsAsyncAction => {
  return {
    type: TeamsActionTypes.FETCH_TEAMS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const fetchAllTeamsAsync = (): IFetchAllTeamsAsyncAction => {
  return {
    type: TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_PENDING
  }
}
