import React, { useState } from "react"
import MessageAssetListItem from "./MessageAssetListItem"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import MessageAssetViewer from "../viewer/MessageAssetViewer"

interface Props {
  messageAssetIds: string[]
}

const MessageAssetList = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedAssetId, setSelectedAssetId] = React.useState(null)

  const closeModal = (): void => {
    setIsModalOpen(false)
  }

  const openModal = (selectedAssetId: string): void => {
    setSelectedAssetId(selectedAssetId)
    setIsModalOpen(true)
  }

  return (
    <div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeModal}>
          <MessageAssetViewer messageAssetId={selectedAssetId} />
        </ModalContainer>
      </Modal>

      {props.messageAssetIds.map((messageAssetId: string) => {
        return (
          <MessageAssetListItem
            key={messageAssetId}
            messageAssetId={messageAssetId}
            openAssetModal={openModal}
          />
        )
      })}
    </div>
  )
}

export default MessageAssetList
