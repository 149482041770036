import { AnyAction, combineReducers, Reducer } from "redux"
import { INormalizedAuditEntities } from "../../../../app/appTypes"
import { AuditEventsActionTypes, IAuditEvent } from "../auditEventTypes"
import _ from "lodash"

type TByIdState = { [id: string]: IAuditEvent }
type TAllIdsByEntityIdState = { [id: string]: string[] }

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsByEntityIdState = {}

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.auditEvents)
    default:
      return { ...state }
  }
}

export const allIdsByEntityId: Reducer<TAllIdsByEntityIdState> = (state: TAllIdsByEntityIdState = allIdsInitialState, action: AnyAction): TAllIdsByEntityIdState => {
  switch (action.type) {
    case AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_SUCCESS:
      return _.merge({}, state, { [action.payload.entityId]: action.payload.all.result })
    default:
      return state
  }
}

export const auditEventsEntityReducer: Reducer<INormalizedAuditEntities<IAuditEvent>> = combineReducers({
  byId,
  allIdsByEntityId
})
