import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconSelected({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={18} cy={18} r={18} fill="currentColor" />
      <path
        fill="currentColor"
        d="M26.546 11.191a1.87 1.87 0 00-2.642.284l-6.626 8.3a2 2 0 01-2.94.203l-2.652-2.517a1.722 1.722 0 00-2.372 2.498l5.457 5.183a2 2 0 002.925-.183l9.116-11.133a1.87 1.87 0 00-.266-2.635z"
      />
    </svg>
  );
}

export default SvgIconSelected;
