import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAcceptThin({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827v-.001z"
        />
        <path d="M0 0h15v15H0z" />
      </g>
    </svg>
  );
}

export default SvgIconAcceptThin;
