import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { generatePath, useParams } from "react-router"
import { IAppState } from "../../app/appTypes"
import { RoutePaths } from "../../app/routes/Routes"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import { fetchTeamAsync } from "../../features/team/state/teamActions"
import { IHaveTeamId } from "../../features/team/state/teamTypes"
import TeamEditForm from "../../features/teamEdit/components/TeamEditForm"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import Card from "../../shared/components/layout/Card"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { getTeamById } from "../../shared/selectors/team"

const TeamEditPage: React.FC = (): JSX.Element => {
  const { teamId } = useParams<IHaveTeamId>()
  const { t } = useTranslation(["shared", "team"])
  const team = useSelector((state: IAppState) => getTeamById(state, teamId))
  const dispatch = useDispatch()
  const fetchSpecialties = () => dispatch(fetchSpecialtiesAsync())
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const fetchTeam = (teamId: string) => dispatch(fetchTeamAsync(teamId))

  useEffect(() => {
    fetchSpecialties()
    fetchUsers()
    fetchTeam(teamId)
  }, [])
  const viewTeamUrl = generatePath(RoutePaths.TEAM, {
    teamId: teamId
  })

  const renderContent = (): JSX.Element => {
    if (!team) return <h3 className="text-lg">{t("team:teamNotFound")}</h3>
    if (!team.permissions.canEdit) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    return (
      <Card>
        <h3 className="mb-2">{t("team:editTeam")}</h3>
        <TeamEditForm teamId={teamId} />
      </Card>
    )
  }

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:team"),
            href: viewTeamUrl
          },
          {
            pageTitle: t("team:editTeam")
          }
        ]}
      />
      {renderContent()}
    </div>
  )
}

export default TeamEditPage
