import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../../shared/components/buttons/Button"
import DenotesRequiredMessage from "../../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../../shared/components/forms/FieldError"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconLink } from "../../../../../svg/icons"
import { createCaseDicomLinkAction } from "../../../../assets/state/assetsActions"
import { IDicomLinkNewData } from "../../../../assets/state/assetsTypes"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../meetingCase/state/meetingCaseTypes"
import { useAppDispatch } from "../../../../../shared/hooks"
import { caseDicomLinkNewValidation } from "../../../state/validation/caseDicomLinkNewValidation"

interface IProps {
  caseId: string
  closeAction: () => void
  switchToNewAssetTab: () => void
}

const CaseDicomLinkNewForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const dispatch = useAppDispatch()
  const createLink = (caseId: string, link: IDicomLinkNewData, meetingCaseId: string) => {
    dispatch(createCaseDicomLinkAction(caseId, link, meetingCaseId))
  }

  const initialValues: IDicomLinkNewData = {
    title: "",
    meetingCaseId: meetingCaseId
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconLink className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:addNewDicomLink")} />
        {t("case:addNewDicomLink")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={caseDicomLinkNewValidation}
        onSubmit={(values, { setSubmitting }) => {
          createLink(props.caseId, values, meetingCaseId)
          setSubmitting(false)
          props.closeAction()
          props.switchToNewAssetTab()
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />
              <div className="mb-5">
                <Label name="title" required={true}>
                  {t("asset:title")}
                </Label>
                <InputField
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.title as string} isVisible={(errors.title && touched.title) as boolean} />
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("case:addLink")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CaseDicomLinkNewForm
