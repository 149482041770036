import React from "react"
import { useTranslation } from "react-i18next"
import TextAuthorDetails from "../../../../shared/components/text/TextAuthorDetails"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { IUser } from "../../../user/state/userTypes"
import { AuditEventsWithTimeOnly, IAuditEvent } from "../../state/auditEventTypes"

interface IProps {
  auditEvent: IAuditEvent
  user: IUser
}

const AuditEventByUserNameAndDate = (props: IProps): JSX.Element => {
  const textAuthorDetailsWithoutName: boolean = props.auditEvent.eventType in AuditEventsWithTimeOnly
  const { t } = useTranslation(["shared"])

  return (
    <div>
      {textAuthorDetailsWithoutName ?
        <>
          <span className="capitalize">
            {t("shared:on")}
          </span>&nbsp;
          <TimeDefaultFormat utcTime={props.auditEvent.createdAt} />
        </>
        :
        props.user ?
          <TextAuthorDetails
            firstName={props.user.firstName}
            lastName={props.user.lastName}
            createdAt={props.auditEvent.createdAt}
          /> : null}
    </div>
  )
}

export default AuditEventByUserNameAndDate
