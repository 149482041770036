import { takeLeading, put, call } from "redux-saga/effects"
import { IDeletePathwayAction, PathwayActionTypes } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import history from "../../../../app/routes/history"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { RoutePaths } from "../../../../app/routes/Routes"

export function* deletePathwayAsync(action: IDeletePathwayAction): Generator {
  try {
    const pathwayId = action.payload.pathwayId

    yield call(API.delete, `/pathways/${pathwayId}`, withAuthHeader())

    yield put({
      type: PathwayActionTypes.DELETE_PATHWAY_ASYNC_SUCCESS,
      payload: {
        pathwayId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "deletePathwaySuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, RoutePaths.PATHWAYS)
  } catch (error) {
    yield put({
      type: PathwayActionTypes.DELETE_PATHWAY_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "deletePathwayFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDeletePathwayAsync(): Generator {
  yield takeLeading(PathwayActionTypes.DELETE_PATHWAY_ASYNC_PENDING, deletePathwayAsync)
}
