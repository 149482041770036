import { ICreatedBy } from "../../../shared/state/sharedTypes"

// Models
export enum MeetingCaseRecommendationStatus {
  DEFERRED = "deferred",
  RECOMMENDED = "recommended",
  RECOMMENDATION_PENDING = "pending"
}

export interface IMeetingCaseAssetPermissions {
  canDelete: boolean
}

export interface IMeetingCaseAsset {
  id: string
  caseAssetId: string
  createdBy: ICreatedBy
  createdAt: string
  permissions: IMeetingCaseAssetPermissions
  decisionId?: string
}

export interface IMeetingCase {
  id: string
  case: string
  meeting: string
  recommendationStatus: MeetingCaseRecommendationStatus
  permissions: IMeetingCasePermissions
  meetingCaseAssets?: IMeetingCaseAsset[]
}

export interface IHaveMeetingCaseId {
  meetingCaseId: string
}

export interface IMeetingCaseNewData {
  meetingId: string
  caseId: string
  redirectPath?: string
}

export interface IMeetingCaseDeleteData {
  meetingId: string
  caseId: string
  meetingCaseId: string
}

export interface IHaveMeetingCaseIdParam {
  match?: {
    params: {
      meetingCaseId: string
    }
  }
}

export interface IMeetingCasePermissions {
  canContribute: boolean
  canEdit: boolean
  canDelete: boolean
  canTrackChanges: boolean
}

// Actions
export interface ICreateMeetingCaseAction {
  type: string,
  payload: IMeetingCaseNewData
}

export interface IFetchMeetingCaseAsyncAction {
  type: string,
  payload: string
}

export interface IDeleteMeetingCaseAction {
  type: string,
  payload: IMeetingCaseDeleteData
}

// Action Types
export enum MeetingCaseActionTypes {
  FETCH_MEETING_CASE_ASYNC_PENDING = "@@meetingCase/FETCH_MEETING_CASE_ASYNC_PENDING",
  FETCH_MEETING_CASE_ASYNC_SUCCESS = "@@meetingCase/FETCH_MEETING_CASE_ASYNC_SUCCESS",
  FETCH_MEETING_CASE_ASYNC_ERROR = "@@meetingCase/FETCH_MEETING_CASE_ASYNC_ERROR",

  CREATE_MEETING_CASE_ASYNC_PENDING = "@@meeting/CREATE_MEETING_CASE_ASYNC_PENDING",
  CREATE_MEETING_CASE_ASYNC_SUCCESS = "@@meeting/CREATE_MEETING_CASE_ASYNC_SUCCESS",
  CREATE_MEETING_CASE_ASYNC_ERROR = "@@meeting/CREATE_MEETING_CASE_ASYNC_ERROR",

  DELETE_MEETING_CASE_ASYNC_PENDING = "@@meeting/DELETE_MEETING_CASE_ASYNC_PENDING",
  DELETE_MEETING_CASE_ASYNC_SUCCESS = "@@meeting/DELETE_MEETING_CASE_ASYNC_SUCCESS",
  DELETE_MEETING_CASE_ASYNC_ERROR = "@@meeting/DELETE_MEETING_CASE_ASYNC_ERROR",
}
export enum MeetingCaseCableActionTypes {
  ASSETS_CHANGED = "@@meetingCaseCable/ASSETS_CHANGED"
}

export const MeetingCaseChannelName = "MeetingCaseChannel"
export const MeetingCaseCablePrefix = "@@meetingCaseCable/"
