import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IconAccept, IconReject, IconNa } from "../../../../../svg/icons"
import { IUser } from "../../../../user/state/userTypes"
import { IAppState } from "../../../../../app/appTypes"
import { CaseStage, ConsentResponse } from "../../../state/liveMeetingTypes"
import { giveConsentOnBehalfAction } from "../../../state/liveMeetingActions"
import { getAllUsers, getCurrentUser } from "../../../../../shared/selectors/user"
import LiveMeetingUserOnlineStatus from "../../users/LiveMeetingUserOnlineStatus"
import NameUser from "../../../../../shared/components/names/NameUser"
import { getUserConsent, } from "../../../../../shared/selectors/liveMeetingConfig/consents"
import { getUserWithConfigById } from "../../../../../shared/selectors/liveMeetingConfig/userConfig"
import MentionFieldReadOnly from "../../../../../shared/components/forms/MentionFieldReadOnly"
import { getLiveMeetingConfig } from "../../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  user: IUser
  readOnly?: boolean
}

const ConsentListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "liveMeeting"])
  const dispatch = useDispatch()
  const caseStage = useSelector(getLiveMeetingConfig).caseStage
  const consent = useSelector((state: IAppState) => getUserConsent(state, props.user.id))
  const currentUser = useSelector((state: IAppState) => getUserWithConfigById(state, { userId: getCurrentUser(state).id }))
  const users = useSelector(getAllUsers)
  const consentOnBehalf = (response: ConsentResponse, userId: string) => dispatch(giveConsentOnBehalfAction(response, userId))
  const currentConsent = consent ? consent.response : ""
  const currentApproved = currentConsent == ConsentResponse.APPROVE
  const currentRejected = currentConsent == ConsentResponse.REJECT
  const currentNa = currentConsent == ConsentResponse.NA

  const approveColor = currentApproved
    ? "text-green-500"
    : "text-ch-gray-400"
  const rejectColor = currentRejected
    ? "text-red-500"
    : "text-ch-gray-400"
  const naColor = currentNa
    ? "text-ch-gray-600"
    : "text-ch-gray-400"

  const cannotConsentOnBehalf =
    caseStage != CaseStage.APPROVING ||
    !currentUser.config.isPresenter ||
    props.readOnly ||
    (consent && consent.consentById != currentUser.id)

  const handler = (consent: ConsentResponse) => {
    if (cannotConsentOnBehalf) { return }
    consentOnBehalf(consent, props.user.id)
  }

  const pickApprove = () => handler(ConsentResponse.APPROVE)
  const pickReject = () => handler(ConsentResponse.REJECT)
  const pickNa = () => handler(ConsentResponse.NA)

  const renderComment = (): JSX.Element => {
    const comment = consent?.comment
    if (!comment) return null

    return (
      <div>
        <h2 className="font-semibold">
          {t("shared:consentComment")}
        </h2>
        <div>
          <MentionFieldReadOnly
            value={comment}
            users={users}
          />
        </div>
      </div>
    )
  }

  const responseOnBehalf = () => {
    if (!props.user || !consent) { return }
    if (props.user?.id != consent?.consentById) {
      return (
        <h4 className="text-sm font-thin uppercase">
          ({t("liveMeeting:consentOnBehalf")})
        </h4>
      )
    }
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <LiveMeetingUserOnlineStatus userId={props.user.id} />
        <div className="flex flex-col">
          <div className="flex items-center">
            <h3 className="mr-1">
              <NameUser
                firstName={props.user.firstName}
                lastName={props.user.lastName}
                professionalTitle={props.user.professionalTitle}
              />
            </h3>
            {responseOnBehalf()}
          </div>
          {renderComment()}
        </div>
      </div>
      <div className="flex">
        {cannotConsentOnBehalf && !currentApproved ? null :
          <IconAccept
            className={`fill-current w-8 h-8 ml-3 ${approveColor} cursor-pointer`}
            onClick={pickApprove}
          />
        }
        {cannotConsentOnBehalf && !currentRejected ? null :
          <IconReject
            className={`fill-current w-8 h-8 ml-3 ${rejectColor} cursor-pointer`}
            onClick={pickReject}
          />
        }
        {cannotConsentOnBehalf && !currentNa ? null :
          <IconNa
            className={`fill-current w-8 h-8 ml-3 ${naColor} cursor-pointer`}
            onClick={pickNa}
          />
        }
      </div>
    </div>
  )
}

export default ConsentListItem
