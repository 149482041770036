import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import { IconAcceptThin, IconRejectThin } from "../../../../svg/icons"
import { getMeetingById, getMeetingUserByMeetingUserId } from "../../../../shared/selectors/meeting"
import { IMeeting, MeetingStatuses } from "../../state/meetingTypes"
import { IMeetingUser } from "../../../meetingUser/state/meetingUserTypes"
import { destroyMeetingUserAction } from "../../../meetingUser/state/meetingUserActions"
import { AcceptanceResponses } from "../../../meetingUser/state/meetingUserTypes"
import RemoveUserButton from "../../../../shared/components/buttons/RemoveUserButton"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"
import { MeetingUserAttendanceLabel } from "./MeetingUserAttendanceLabel"

interface IPropsFromParent {
  store?: MockStore
  meetingId: string
  meetingUserId: string
}

const MeetingUserListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))
  const meetingUser = useSelector((state: IAppState): IMeetingUser => getMeetingUserByMeetingUserId(state, props))
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, meetingUser))

  const { t } = useTranslation(["meeting", "shared"])
  const dispatch = useDispatch()

  const renderMeetingResponse = (): JSX.Element => {
    if (meetingUser.acceptanceResponse == AcceptanceResponses.Accepted) {
      return renderAccepted()
    } else if (meetingUser.acceptanceResponse == AcceptanceResponses.Rejected) {
      return renderRejected()
    } else {
      return (
        <span className="invisible flex uppercase font-thin text-ch-green-300 items-center" >
          <span className="invisible text-ch-gray-600">{t("meeting:accepted")}</span>
          <IconAcceptThin className="fill-current w-4 h-4 ml-2" />
        </span>
      )
    }
  }

  const renderMeetingUserButton = (): JSX.Element => {
    if (meetingUser.teamUserId) {
      return (
        <div>
          {t("meeting:mdtMeeting:mdtMember")}
        </div>
      )
    }
    if (meeting.coordinatorId == meetingUser.userId) return null
    if (meeting.permissions.canUninvite) {
      return (
        <RemoveUserButton
          action={removeUserFromMeeting}
          label={t("shared:remove")}
          canRemove={
            meeting.permissions.canUninvite
          }
        />
      )
    }
  }

  const renderAccepted = (): JSX.Element => {
    return (
      <span className="flex uppercase font-thin text-ch-green-300 items-center" >
        <span className="text-ch-gray-600">{t("meeting:accepted")}</span>
        <IconAcceptThin className="fill-current w-4 h-4 ml-2" />
      </span>
    )
  }

  const renderRejected = (): JSX.Element => {
    return (
      <span className="flex uppercase font-thin text-ch-red-500 items-center" >
        <span className="text-ch-gray-600">{t("meeting:rejected")}</span>
        <IconRejectThin className="fill-current w-4 h-4 ml-2" />
      </span>
    )
  }

  const removeUserFromMeeting = () => {
    dispatch(destroyMeetingUserAction(props.meetingUserId))
  }

  const renderMeetingUserAttendanceLabel = () => {
    if (meeting.status == MeetingStatuses.Ended) return <MeetingUserAttendanceLabel meetingJoinedCount={meetingUser.joinedCount} />
    return null
  }

  const coordinatorLabel = () => {
    if (meeting.coordinatorId != meetingUser.userId) return null
    return (
      <span className="text-sm text-white px-2 rounded bg-ch-blue-alt-500 ml-1 uppercase">
        {t("user:coordinator")}
      </span>
    )
  }

  const mdtRole = () => {
    if (!meetingUser.mdtRole) return null
    return `${t("meeting:mdtMeeting:mdtRole")}: ${meetingUser.mdtRole}`
  }

  if (!meetingUser || !meeting) return null
  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <UserDetailsCard
            user={user}
            nameLabel={coordinatorLabel()}
            label={mdtRole()} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[
              renderMeetingResponse(),
              renderMeetingUserButton(),
              renderMeetingUserAttendanceLabel()
            ]}
          />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default MeetingUserListItem
