import { Form, Formik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { IPatientFurtherDetailsData, IPatientNewData } from "../../state/patientNewTypes"
import PatientFields from "./PatientFields"
import GPFields from "./GPFields"
import Card from "../../../../shared/components/layout/Card"
import { useParams } from "react-router"
import { IHavePatientId, IHavePatientIdParam, IPatient } from "../../../patient/state/patientTypes"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getPatientById } from "../../../../shared/selectors/patients"
import WizardForceUpdate from "../../../../shared/components/ui/wizard/WizardForceUpdate"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientNewData
  setData: (data: IPatientNewData) => void
}

type Props = IPropsFromParent & IHavePatientIdParam

const FurtherDetailsForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)
  const { patientId } = useParams<IHavePatientId>()
  const patient: IPatient = useSelector((state: IAppState) => getPatientById(state, {patientId: patientId}))

  const initialValues: IPatientFurtherDetailsData = {
    id: patient?.furtherDetails?.id,
    postcode: props.data.furtherDetailsAttributes.postcode || "",
    addressLine1: props.data.furtherDetailsAttributes.addressLine1 || "",
    addressLine2: props.data.furtherDetailsAttributes.addressLine2 || "",
    city: props.data.furtherDetailsAttributes.city || "",
    county: props.data.furtherDetailsAttributes.county || "",
    country: props.data.furtherDetailsAttributes.country || "",
    email: props.data.furtherDetailsAttributes.email || "",
    phoneNumber: props.data.furtherDetailsAttributes.phoneNumber || "",
    phoneNumberAlt: props.data.furtherDetailsAttributes.phoneNumberAlt || "",
    gpPostcode: props.data.furtherDetailsAttributes.gpPostcode || "",
    gpPracticeName: props.data.furtherDetailsAttributes.gpPracticeName || "",
    gpAddressLine1: props.data.furtherDetailsAttributes.gpAddressLine1 || "",
    gpAddressLine2: props.data.furtherDetailsAttributes.gpAddressLine2 || "",
    gpCity: props.data.furtherDetailsAttributes.gpCity || "",
    gpCounty: props.data.furtherDetailsAttributes.gpCounty || "",
    gpCountry: props.data.furtherDetailsAttributes.gpCountry || "",
    gpEmail: props.data.furtherDetailsAttributes.gpEmail || "",
    gpPhoneNumber: props.data.furtherDetailsAttributes.gpPhoneNumber || "",
    gpName: props.data.furtherDetailsAttributes.gpName  || ""
  }

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full lg:w-2/3">
        <h4 className="flex mb-2 font-semibold">
          { t("patient:furtherDetails:sectionTitle") }
        </h4>
        <p className="mb-2">
          { t("patient:furtherDetails:intro") }
        </p>

        <Formik
          validateOnMount
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const data: IPatientFurtherDetailsData = {
              id: patient?.furtherDetails?.id,
              postcode: values.postcode.toUpperCase(),
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              county: values.county,
              country: values.country,
              email: values.email,
              phoneNumber: values.phoneNumber,
              phoneNumberAlt: values.phoneNumberAlt,
              gpPostcode: values.gpPostcode.toUpperCase(),
              gpPracticeName: values.gpPracticeName,
              gpAddressLine1: values.gpAddressLine1,
              gpAddressLine2: values.gpAddressLine2,
              gpCity: values.gpCity,
              gpCounty: values.gpCounty,
              gpCountry: values.gpCountry,
              gpEmail: values.gpEmail,
              gpPhoneNumber: values.gpPhoneNumber,
              gpName: values.gpName
            }

            props.setData({
              ...props.data,
              furtherDetailsAttributes: data
            })

            setSubmitting(false)
            props.goToNextStep()
          }}
          innerRef={formikFormRef}
        >
          {() => {
            return (
              <Form>
                <WizardForceUpdate />
                <DenotesRequiredMessage />

                <div className="border-b-2 border-ch-gray-400 pb-5 mb-5">
                  <h4 className="flex mb-2 font-semibold">
                    { t("patient:furtherDetails:patientSectionTitle") }
                  </h4>
                  <PatientFields />
                </div>
                <div>
                  <h4 className="flex mb-2 font-semibold">
                    { t("patient:furtherDetails:gPSectionTitle") }
                  </h4>
                  <GPFields />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Card>
  )
}

export default FurtherDetailsForm
