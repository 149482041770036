import React from "react"
import { INewMessageAssetData } from "../../state/messageTypes"
import MessageAssetsFieldListItem from "./MessageAssetsFieldListItem"

interface Props {
  messageAssetsData:  INewMessageAssetData[]
  removeItem: (file: File) => void
}

const MessageAssetsFieldList: React.FC<Props> = (props: Props): JSX.Element => {
  if(!props.messageAssetsData) return null

  return (
    <div className="flex gap-1">
      {props.messageAssetsData.map((data: INewMessageAssetData, index: number) => {
        return (
          <MessageAssetsFieldListItem
            key={index}
            file={data.file}
            removeItem={props.removeItem}
          />
        )
      })}
    </div>
  )
}

export default MessageAssetsFieldList
