import React, { useState } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import CaseGuestUserNewForm from "./CaseGuestUserNewForm"
import InviteExistingGuestUsersForm from "./InviteExistingGuestUsersForm"
import PillSwitch from "../../../../../shared/components/ui/PillSwitch"

interface Props {
  caseId: string
  closeModal: () => void
  isOpen: boolean
}

const CaseGuestUserAddModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const [formToUse, setFormToUse] = useState(0)

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("case:guestUsers:titles:inviteGuestUser")}
        </h2>

        <PillSwitch text1={t("case:guestUsers:buttons:InviteGuestUsers")} text2={t("case:guestUsers:buttons:createGuestUser")} onClick={(n: number) => setFormToUse(n)} value={formToUse} />

        {formToUse == 0 ?

          <div className="mb-4">
            <h4 className="text-ch-blue-600 mb-2">
              {t("case:guestUsers:text:inviteExistingGuestUserInfo")}
            </h4>

            <InviteExistingGuestUsersForm
              caseId={props.caseId}
              closeModal={props.closeModal}
            />
          </div>
          :

          <div>
            <h4 className="text-ch-blue-600 mb-2">
              {t("case:guestUsers:text:addNewGuestUserInfo")}
            </h4>

            <div className="w-full">
              <CaseGuestUserNewForm
                caseId={props.caseId}
                closeModal={props.closeModal}
              />
            </div>
          </div>
        }

      </ModalContainer>
    </Modal>
  )
}

export default CaseGuestUserAddModal
