import React from "react"

const LoadingSpinner: React.FC = (): JSX.Element => {

  return (
    <div className="flex p-10 justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: "block",
          margin: "auto",
        }}
        width="35px"
        height="35px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="50" cy="50" fill="none" stroke="#13b7bf" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
    </div>
  )
}

export default LoadingSpinner
