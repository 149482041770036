import React from "react"
import { useDispatch, useSelector } from "react-redux"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconPathway } from "../../../../svg/icons"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IPathway } from "../../../pathway/pathwayTypes"
import PathwayDetails from "../../../pathway/components/details/PathwayDetails"
import { IAppState } from "../../../../app/appTypes"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"

type Props = {
  pathwayId: string
}

export const PathwayListItem: React.FC<Props> = ( {pathwayId}: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const pathway: IPathway = useSelector((state: IAppState) => state.entities.pathways.byId[pathwayId])
  const dispatch = useDispatch()

  if (!pathway)
    return null

  const onClickQuickView = (pathwayId: string) => dispatch(toggleIsQuickViewOpen(pathwayId, true))

  const pathwayDetailsUrl = generatePath(RoutePaths.PATHWAY, {
    pathwayId: pathwayId
  })

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconPathway className="fill-current w-6 h-6" />
          </IconCircleContainer>
        <PathwayDetails pathwayId={pathwayId} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[
              <Button
                action={() => onClickQuickView(pathwayId)}
                variant={StyleVariants.BLUE_ALT}
                key="1"
                className="hidden md:block"
              >
                {t("shared:quickView")}
              </Button>,
              <Button
                variant={StyleVariants.BLUE}
                href={pathwayDetailsUrl}
                key="2"
              >
                {t("shared:viewDetails")}
              </Button>
            ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default PathwayListItem
