import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { patientsSchema } from "../../../apis/contextmeeting/schema"
import { PatientActionTypes } from "./patientTypes"

export function* fetchPatientAsync(action: Effect): Generator {
  try {
    const patientId = action.payload

    const response: any = yield call(API.get, `/patients/${patientId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, patientsSchema)

    yield put({
      type: PatientActionTypes.FETCH_PATIENT_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: PatientActionTypes.FETCH_PATIENT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "patientNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchPatientAsync(): Generator {
  yield takeLeading(PatientActionTypes.FETCH_PATIENT_ASYNC_PENDING, fetchPatientAsync)
}
