import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { createCaseSharedAssetAction } from "../../state/caseSharedAssetsActions"

interface Props {
  assetId: string
  caseId: string
  userId: string
}

const AddSharedAssetButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["case"])
  const [clicked, setClicked] = useState(false)
  const dispatch = useDispatch()

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (!clicked) {
      setClicked(true)
      dispatch(createCaseSharedAssetAction(
        props.caseId,
        props.assetId,
        props.userId
      ))
    }
  }

  return (
    <Button action={onClick} isChunky={false} variant={StyleVariants.BLUE} className="h-8">
      {t("case:selectAsset")}
    </Button>
  )
}

export default AddSharedAssetButton
