import { useTranslation } from "react-i18next"
import humps from "humps"
import { IAuditEvent } from "../../state/auditEventTypes"
import { IUser } from "../../../user/state/userTypes"

interface IProps {
  auditEvent: IAuditEvent
  user?: IUser
  headerText?: string
}

const AuditEventCardHeader = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])
  const headerText = props.headerText ? props.headerText : humps.camelize(props.auditEvent.eventType)

  if (props.user) {
    return t(`auditEvent:${headerText}`, { user: `${props.user.firstName} ${props.user.lastName}` })
  }
  return t(`auditEvent:${headerText}`)
}

export default AuditEventCardHeader
