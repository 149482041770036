import { takeLatest, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { generatePath } from "react-router"
import API from "../../../../apis/contextmeeting/api"
import { fetchTeamSchema } from "../../../../apis/contextmeeting/schema"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import history from "../../../../app/routes/history"
import { TeamUserActionTypes } from "../teamUserTypes"
import { RoutePaths } from "../../../../app/routes/Routes"

export function* createTeamUser(action: Effect): Generator {
  try {
    const teamUser = {
      teamUser: action.payload
    }
    // responds with the full team
    const response: any = yield call(API.post, "/team_users", teamUser, withAuthHeader())
    const normalizedResponse = normalize(response.data, fetchTeamSchema)
    yield put({
      type: TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "createTeamUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewTeamUrl = generatePath(RoutePaths.TEAM, {
      teamId: action.payload.teamId
    })

    yield call(history.push, viewTeamUrl)
  } catch (error) {
    yield put({
      type: TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createTeamUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateTeamUser(): Generator {
  yield takeLatest(TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_PENDING, createTeamUser)
}

export function* destroyTeamUser(action: Effect): Generator {
  try {
    const teamUserId = action.payload

    const response: any = yield call(API.delete, `/team_users/${teamUserId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchTeamSchema)

    yield put({
      type: TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "destroyTeamUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewTeamUrl = generatePath(RoutePaths.TEAM, {
      teamId: response.data.id
    })

    yield call(history.push, viewTeamUrl)
  } catch (error) {
    yield put({
      type: TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "destroyTeamUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDestroyTeamUser(): Generator {
  yield takeLatest(TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_PENDING, destroyTeamUser)
}
