import { Form, Formik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import Card from "../../../../shared/components/layout/Card"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { IHavePatientId, IHavePatientIdParam } from "../../../patient/state/patientTypes"
import PatientDetailsFormCard from "../../../patient/components/formCards/PatientDetailsFormCard"
import { updatePatientAsync } from "../../state/patientEditActions"
import { IPatientEditData } from "../../state/patientEditTypes"
import AmendmentDetailsFormCard from "../../../patient/components/formCards/AmendmentDetailsFormCard"
import UnsavedChangesPrompt from "../../../../shared/components/forms/UnsavedChangesPrompt"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientEditData
  setData: (data: IPatientEditData) => void
}

type Props = IPropsFromParent & IHavePatientIdParam

const ReviewAndSaveForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)
  const { patientId } = useParams<IHavePatientId>()

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full">
        <h4 className="flex mb-2 font-semibold">
          { t("patient:reviewAndSave:sectionTitle") }
        </h4>
        <p className="mb-2">
          { t("patient:reviewAndSave:intro") }
        </p>

        <div className="w-full">
          <Formik
            initialValues={props.data}
            onSubmit={(_values, { setSubmitting }) => {
              const data: IPatientEditData = props.data
              dispatch(updatePatientAsync(patientId, data))
              setSubmitting(false)
            }}
            innerRef={formikFormRef}
          >
            {({submitCount}) => {
              return (
                <Form>
                  <UnsavedChangesPrompt isEnabled={submitCount < 1} />
                  <h4 className="flex mb-2 font-semibold">
                    { t("patient:details:sectionTitle") }
                  </h4>
                  <PatientDetailsFormCard data={props.data} />

                  <h4 className="flex mb-2 font-semibold">
                    { t("patient:reasonForChange:amendmentDetailsTitle") }
                  </h4>
                  <AmendmentDetailsFormCard data={props.data} patientId={patientId} />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default ReviewAndSaveForm
