import { IAppState } from "../../../app/appTypes"
import { ISharedAsset } from "../../../features/caseSharedAssets/state/caseSharedAssetsTypes"

export const getCaseAssetsCanBeSharedWithUser = (state: IAppState, caseId: string, userId: string): string[] => {
  if(!state.entities.sharedAssets.canBeSharedWithUserByUserId[userId]) return []
  return state.entities.sharedAssets.canBeSharedWithUserByUserId[userId][caseId]
}

export const getSharedAssetById = (state: IAppState, assetId: string): ISharedAsset =>
state.entities.sharedAssets.byId[assetId]
