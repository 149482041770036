import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { CaseCablePrefix, CaseChannelName } from "../../../case/state/caseTypes"
import { getSelectedMeetingCaseDetails } from "../../../../shared/selectors/liveMeetingConfig/meetingCase"
import CableConnection from "../../../../shared/components/cable/CableConnection"
import { useBeforeunload } from "react-beforeunload"

const LiveMeetingSelectedCaseDetails: React.FC = (): JSX.Element => {
  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(0)
  const { t } = useTranslation(["case"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(TenantConfigContext)

  const caseData = useSelector(getSelectedMeetingCaseDetails)
  useBeforeunload(() => {
    setConnectionRefresherIndex(connectionRefresherIndex + 1)
  })

  if (!caseData) return null
  return (
    <CableConnection
      resourceId={caseData.id}
      channelName={CaseChannelName}
      actionPrefix={CaseCablePrefix}
      key={connectionRefresherIndex}
    >
      <div className="grid md:grid-cols-2 gap-3">
        <ul className="md:col-span-1">
          <li>
            <NameUser
              firstName={caseData.firstName}
              lastName={caseData.lastName}
              title={caseData.title}
              gender={caseData.gender}
              className="font-bold"
            />
          </li>
          <li>
            {t("case:dateOfBirth")}:{" "}
            <TimeDateOfBirth utcTime={caseData.dateOfBirth} />
          </li>
        </ul>

        <ul className="md:col-span-1">
          <li>
            {healthcareIdLabel}: {caseData.healthcareId}
          </li>
          <li>
            {careProviderIdLabel}: {caseData.careProviderId}
          </li>
        </ul>
      </div>
    </CableConnection>
  )
}

export default LiveMeetingSelectedCaseDetails
