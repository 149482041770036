import { ISearchQuery } from "../../../shared/hooks/useSearch"

export interface IFetchCasesAsyncAction {
  type: string
  payload: ISearchQuery
}

// Action Types
export enum CasesActionTypes {
  FETCH_CASES_ASYNC_PENDING = "@@cases/FETCH_CASES_ASYNC_PENDING",
  FETCH_CASES_ASYNC_SUCCESS = "@@cases/FETCH_CASES_ASYNC_SUCCESS",
  FETCH_CASES_ASYNC_ERROR = "@@cases/FETCH_CASES_ASYNC_ERROR",
}
