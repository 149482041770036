import { Form, Formik } from "formik"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { IPatientNewData } from "../../state/patientNewTypes"
import Card from "../../../../shared/components/layout/Card"
import { useDispatch } from "react-redux"
import PatientDetailsFormCard from "../../../patient/components/formCards/PatientDetailsFormCard"
import { createPatientAction } from "../../state/patientNewActions"
import FurtherDetailsFormCard from "../../../patient/components/formCards/FurtherDetailsFormCard"
import CaseFormCard from "../../../patient/components/formCards/CaseFormCard"
import { fetchAllUsersAsync } from "../../../users/state/usersActions"
import { fetchCaseClassificationAction } from "../../../caseClassification/state/caseClassificationActions"
import { fetchCaseSourcesAction } from "../../../sources/state/caseSourceActions"
import UnsavedChangesPrompt from "../../../../shared/components/forms/UnsavedChangesPrompt"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientNewData
  setData: (data: IPatientNewData) => void
}

type Props = IPropsFromParent

const ReviewAndSaveForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)

  useEffect(() => {
    dispatch(fetchAllUsersAsync())
    dispatch(fetchCaseClassificationAction())
    dispatch(fetchCaseSourcesAction())
  }, [])

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full">
        <h4 className="flex mb-2 font-semibold">
          {t("patient:reviewAndSave:sectionTitle")}
        </h4>
        <p className="mb-2">
          {t("patient:reviewAndSave:intro")}
        </p>

        <div className="w-full">
          <Formik
            validateOnMount
            initialValues={props.data}
            onSubmit={(_values, { setSubmitting }) => {
              const data: IPatientNewData = props.data
              dispatch(createPatientAction(data))
              setSubmitting(false)
            }}
            innerRef={formikFormRef}
          >
            {({ submitCount }) => {
              return (
                <Form>
                  <UnsavedChangesPrompt isEnabled={submitCount < 1} />
                  <h4 className="flex mb-2 font-semibold">
                    {t("patient:details:sectionTitle")}
                  </h4>
                  <PatientDetailsFormCard data={props.data} />

                  <h4 className="flex mb-2 font-semibold">
                    {t("patient:furtherDetails:sectionTitle")}
                  </h4>
                  <FurtherDetailsFormCard data={props.data} />

                  <h4 className="flex mb-2 font-semibold">
                    {t("patient:caseDetails:sectionTitle")}
                  </h4>
                  <CaseFormCard data={props.data} />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default ReviewAndSaveForm
