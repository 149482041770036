import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconPdf({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 37"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M26.972 7.08l-6.118-6.06A3.516 3.516 0 0018.382 0H3.5C1.568.007 0 1.56 0 3.475v30.057C0 35.447 1.568 37 3.5 37h21c1.932 0 3.5-1.553 3.5-3.468V9.537a3.47 3.47 0 00-1.028-2.456zm-2.756 2.175h-5.55V3.757l5.55 5.498zM3.5 33.532V3.475h11.667v7.514c0 .961.78 1.734 1.75 1.734H24.5v20.809h-21zm18.244-10.383c-.89-.867-3.427-.628-4.696-.47-1.254-.758-2.093-1.806-2.683-3.344.284-1.164.736-2.934.393-4.047-.306-1.893-2.756-1.705-3.106-.426-.32 1.163-.03 2.782.51 4.848-.729 1.727-1.815 4.046-2.58 5.376-1.46.744-3.428 1.893-3.72 3.338-.24 1.141 1.896 3.988 5.55-2.255 1.633-.534 3.412-1.192 4.987-1.452 1.378.737 2.99 1.229 4.069 1.229 1.86 0 2.041-2.038 1.276-2.797zM7.299 28.771c.372-.99 1.786-2.132 2.217-2.53-1.386 2.19-2.217 2.58-2.217 2.53zm5.95-13.772c.54 0 .489 2.32.131 2.948-.32-1.004-.313-2.948-.131-2.948zm-1.78 9.87c.708-1.221 1.313-2.673 1.802-3.952.605 1.09 1.378 1.965 2.195 2.565-1.517.31-2.837.946-3.996 1.387zm9.597-.361s-.365.433-2.72-.564c2.56-.188 2.982.39 2.72.564z"
      />
    </svg>
  );
}

export default SvgIconPdf;
