import { useDispatch, useSelector } from "react-redux"
import { ISpecialty } from "../../specialty/state/specialtyTypes"
import { ITeam, ITeamUpdateData } from "../../team/state/teamTypes"
import { IUser } from "../../user/state/userTypes"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import { Form, Formik } from "formik"
import Label from "../../../shared/components/forms/Label"
import InputField from "../../../shared/components/forms/InputField"
import FieldError from "../../../shared/components/forms/FieldError"
import SelectField from "../../../shared/components/forms/SelectField"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { TSelectFieldOptions, TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import { generateSpecialtyOptions } from "../../../shared/helpers/selectOptionHelpers"
import { teamNewValidationSchema } from "../../teamNew/state/AddNewTeamValidation"
import { IAppState } from "../../../app/appTypes"
import { getAllUsers } from "../../../shared/selectors/user"
import { getAllSpecialties } from "../../../shared/selectors/specialties"
import { getTeamById } from "../../../shared/selectors/team"
import { updateTeamAsync } from "../../team/state/teamActions"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../shared/components/label/UserOption"

interface IPropsFromParent {
  teamId: string
}

const TeamEditForm = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["team"])
  const [userOptions, setUserOptions] = React.useState([])
  const [specialtiesOptions, setSpecialtiesOptions] = React.useState([])
  const team = useSelector((state: IAppState): ITeam => getTeamById(state, props.teamId))
  const users = useSelector((state: IAppState): { [id: string]: IUser } => getAllUsers(state))
  const specialties = useSelector((state: IAppState): { [id: string]: ISpecialty } => getAllSpecialties(state))
  const dispatch = useDispatch()

  const initialValues: ITeamUpdateData = {
    name: team.name,
    specialtyId: team.specialtyId,
    ownerId: team.owner
  }
  useEffect(() => {
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(users))
    setUserOptions(options)
  }, [team.users])

  useEffect(() => {
    const options: TSelectFieldOptions = generateSpecialtyOptions(specialties)
    setSpecialtiesOptions(options)
  }, [specialties])

  return (
    <div className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={teamNewValidationSchema()}
        onSubmit={(values, { setSubmitting }) => {
          const data = teamNewValidationSchema().cast(values)

          dispatch(updateTeamAsync(props.teamId, data))
          setSubmitting(false)
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          dirty
        }) => {
          return (
            <Form className="w-full lg:w-2/3">
              <DenotesRequiredMessage />

              <div className="mb-2">
                <Label name="name" required={true}>
                  {t("team:teamName")}
                </Label>
                <InputField
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="off"
                  autoFocus={true}
                />
                <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="specialtyId" required={true}>
                  {t("team:specialty")}
                </Label>
                <SelectField
                  name="specialtyId"
                  options={specialtiesOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isGrouped={true}
                />
                <FieldError errorMessage={errors.specialtyId as string} isVisible={(errors.specialtyId && touched.specialtyId) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="ownerId" required={true}>
                  {t("team:owner")}
                </Label>
                <SelectField
                  name="ownerId"
                  options={userOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  filterOption={filterOptionsBySearchValue}
                />
                <FieldError errorMessage={errors.ownerId as string} isVisible={(errors.ownerId && touched.ownerId) as boolean} />
              </div>

              <div className="flex justify-center">
                <Button
                  isDisabled={!dirty || isSubmitting}
                  variant={StyleVariants.PURPLE}
                  isSubmit={true}
                >
                  {t("updateTeam")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default TeamEditForm
