/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import Pagination from "../../../../shared/components/search/Pagination"
import ResultsPerPage from "../../../../shared/components/search/ResultsPerPage"
import SearchInput from "../../../../shared/components/search/SearchInput"
import useSearch, { ISearchQuery } from "../../../../shared/hooks/useSearch"
import { getCasesUI } from "../../../../shared/selectors/ui"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import { fetchCasesAsync } from "../../../cases/state/casesActions"
import ICasesUIState from "../../../cases/state/casesTypes"
import { ScrollActionTypes } from "../../../scroll/state/scrollTypes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { IChatNewFormData } from "../../state/chatNewTypes"
import ChatNewCaseList from "./ChatNewCaseList"

interface IPropsFromParent {
  closeModal: () => void
  isOpen: boolean
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

type Props = IPropsFromParent

const ChatNewSelectCaseModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const { careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  const UI: ICasesUIState = useSelector((state: IAppState) => getCasesUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchCasesAsync)

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">{t("message:new:selectCaseTitle")}</h2>

        <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
          <div className="row-start-2 md:row-start-1 self-center">
            <Trans
              i18nKey="case:searchBy"
              components={{
                strong: <strong />
              }}
              values={{ careProviderIdLabel: careProviderIdLabel }}
            />
          </div>
          <div className="row-end-2 md:row-start-1 flex justify-end">
            <ResultsPerPage
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          buttonText={t("case:searchCases")}
        />

        <h2 className="text-lg mb-2">
          {UI.totalCasesCount} {t("shared:cases")}
        </h2>

        <ChatNewCaseList
          values={props.values}
          setFieldValue={props.setFieldValue}
          closeModal={props.closeModal}
        />

        <Pagination
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          totalItems={UI.totalCasesCount}
          totalPages={UI.totalPages}
          scrollActionType={ScrollActionTypes.UPDATE_MODAL_SCROLL_TO_POSITION}
        />
      </ModalContainer>
    </Modal >
  )
}

export default ChatNewSelectCaseModal
