import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { IconPencil, IconUser } from "../../../../svg/icons"
import ButtonLink from "../../../../shared/components/buttons/ButtonLink"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { getCurrentUser } from "../../../../shared/selectors/user"

const CurrentUserSessionDetails: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const currentUser = useSelector(getCurrentUser)
  if (!currentUser) return null

  const editUserUrl = generatePath(RoutePaths.USER_EDIT, {
    userId: currentUser.id
  })

  const renderDetails = (): JSX.Element => {
    return (
      <dl className="text-ch-gray-500 text-center w-full overflow-hidden">
        <dt className="text-white font-semibold text-lg">
          {currentUser.firstName} {currentUser.lastName}
        </dt>
        <dd className="truncate">
          {currentUser.email}
        </dd>
      </dl>
    )
  }

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center mb-3">
        <IconUser className="text-ch-purple-500 w-6 h-6" />
      </div>
      {renderDetails()}
      <ButtonLink href={editUserUrl} className="uppercase font-semibold text-sm" variant={StyleVariants.BLUE}>
        {t("shared:edit")} <IconPencil className="w-4 h-4 ml-1" title={t("shared:edit")} />
      </ButtonLink>
    </div>
  )
}

export default CurrentUserSessionDetails
