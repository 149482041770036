/* eslint-disable @typescript-eslint/no-explicit-any */
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"

type UrlCallback = (url: string) => void
const fetchBlob = (path: string, setBlobUrlCallback: UrlCallback): void => {
  if (path === undefined || path === "") {
    setBlobUrlCallback("")
    return
  }
  const url = process.env.API_URL + path
  fetch(url, withAuthHeader())
    .then(response => {
      if (!response.body) return
      const reader = response.body.getReader()
      return new ReadableStream({
        start(controller) {
          return pump()
          function pump(): any {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close()
                return
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value)
              return pump()
            })
          }
        }
      })
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => {
      setBlobUrlCallback(url)
    })
    .catch(err => console.error(err))
}
// Ideally, we'd wrap the above function in a self contained function that can
// just be called to get the blob url, but donin
// the below caused React to re-render some elements all the time and thus blow up.
// If someone would like to have a go at getting this to work that would be great!

// const fetchResource = (path:string, ctx) =>{
//   const [blobUrl, setBlobUrl] = useState("")
//   if (path === undefined || path === "") {
//     setBlobUrl("")
//     return
//   };
//   useEffect.call(this, () => {
//     fetchBlob(path, setBlobUrl)
//   }, [path])
//   return blobUrl
// }
export default fetchBlob
