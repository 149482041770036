import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconArrowSmallDown({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 6"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M.5 0a.5.5 0 00-.374.832l4 4.5a.5.5 0 00.748 0l4-4.5A.5.5 0 008.5 0h-8z"
      />
    </svg>
  );
}

export default SvgIconArrowSmallDown;
