import { addDays } from "date-fns"
import React from "react"
import { differenceInSeconds } from "date-fns"
import { useTranslation } from "react-i18next"

type Props = {
  startTime: string,
  endTime: string,
}
export const AuditEventDurationLabel = ({ startTime, endTime }: Props): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])

  const duration = () => {
    const
      start = new Date(startTime),
      endDate = new Date(endTime),
      end =
        endDate <= start ? addDays(endDate, 1) : endDate

    const diff = differenceInSeconds(end, start)
    const hours = Math.floor(diff / 3600)
    const minutes = Math.floor((diff - 3600 * hours) / 60)

    let str = ""
    if (hours > 0) str = hours + t("h")
    if (hours > 0 && minutes > 0) str += " "
    if (minutes > 0) str += minutes + t("min")

    return str
  }

  if (!startTime || !endTime) return null
  return (
    <div>
      {duration()}
    </div>
  )
}
