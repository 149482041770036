import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import { IUser } from "../../../user/state/userTypes"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import AssetTypeFilter from "../../../case/components/assetList/AssetTypeFilter"
import MeetingCaseAssetListItem from "./MeetingCaseAssetListItem"
import { getMeetingCaseById } from "../../../../shared/selectors/meeting"
import { IMeetingCaseAsset } from "../../state/meetingCaseTypes"
import { deleteAssetAction } from "../../../assets/state/assetsActions"

interface IProps {
  meetingCaseId: string
  caseId: string
  mentionableUsers: { [id: string]: IUser }
  openAssetModal: (assetId: string) => void
}


const MeetingCaseAssetList = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["case"])
  const dispatch = useDispatch()
  const deleteTheAsset = (assetId: string) => dispatch(deleteAssetAction(props.caseId, assetId))

  const meetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, props))
  const meetingCaseAssets = meetingCase.meetingCaseAssets

  const [assetTypeFilters, setAssetTypeFilters] = React.useState<IAssetTypeFilters>(null)

  if (!meetingCaseAssets || meetingCaseAssets.length == 0) return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t("case:noAssets")}
      </h3>

      <ListBackground />
    </div>
  )

  const renderList = (): JSX.Element => {
    return (
      <ul>
        {meetingCaseAssets.map((meetingCaseAsset: IMeetingCaseAsset) => {
          return (
            <MeetingCaseAssetListItem
              action={() => props.openAssetModal(meetingCaseAsset.caseAssetId)}
              key={meetingCaseAsset.id}
              meetingCaseAsset={meetingCaseAsset}
              assetId={meetingCaseAsset.caseAssetId}
              assetTypeFilters={assetTypeFilters}
              deleteAction={deleteTheAsset}
              mentionableUsers={props.mentionableUsers}
              caseId={props.caseId}
              meetingCaseId={props.meetingCaseId}
              meetingId={meetingCase.meeting}
            />
          )
        })}
      </ul>
    )
  }

  return (
    <div>
      <AssetTypeFilter setFilters={setAssetTypeFilters} />
      {renderList()}
    </div>
  )
}

export default MeetingCaseAssetList
