import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import NewMessageButton from "./NewMessageButton"

interface Props {
  setSelectedChatId: (selectedChatId: string) => void
  selectedChatId: string
  setIsNewChatFormOpen: (isNewChatFormOpen: boolean) => void
  isNewChatFormOpen: boolean
}

const PageTitle: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])

  const onClick = () => {
    props.setSelectedChatId(null)
    props.setIsNewChatFormOpen(false)
  }

  const renderButton = (): JSX.Element => {
    if (
      props.selectedChatId == null &&
      !props.isNewChatFormOpen
    ) return null

    return (
      <div className="lg:hidden">
        <Button
          action={onClick}
          className="ml-5"
          variant={StyleVariants.ORANGE}
        >
          {t("shared:back")}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <h2 className="text-lg py-1">
        {t("shared:messages")}
      </h2>
      {renderButton()}
      <NewMessageButton
        selectedChatId={props.selectedChatId}
        setSelectedChatId={props.setSelectedChatId}
        setIsNewChatFormOpen={props.setIsNewChatFormOpen}
      />
    </div>
  )
}

export default PageTitle
