import _ from "lodash"
import { AnyAction, combineReducers, Reducer } from "redux"
import { INormalizedEntities } from "../../../../app/appTypes"
import { CaseClassificationActionTypes, ICaseClassification } from "../caseClassificationTypes"

type TByIdState = { [id: string]: ICaseClassification }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []


export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch(action.type) {
    case CaseClassificationActionTypes.FETCH_CASE_CLASSIFICATION_ASYNC_SUCCESS:
      return  {
        ...state,
        ...action.payload.entities.caseClassifications
      }
    default:
      return {...state}
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch(action.type) {
    case CaseClassificationActionTypes.FETCH_CASE_CLASSIFICATION_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.result))
    default:
      return state
  }
}

export const caseClassificationReducer: Reducer<INormalizedEntities<ICaseClassification>> = combineReducers({
  byId,
  allIds
})
