import React from "react"
import { useTranslation } from "react-i18next"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import { IconPatient } from "../../../../svg/icons"
import PatientDetails from "../details/PatientDetails"

interface IPropsFromParent {
  patientId: string
}

type Props = IPropsFromParent

const SavedPatientDetailsFormCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])

  return (
    <DetailsCard>
      <DetailsCardInner>
        <IconCircleContainer className="sm:mr-4">
          <IconPatient className="fill-current w-6 h-6" title={t("shared:patient")} />
        </IconCircleContainer>
        <PatientDetails patientId={props.patientId} hasLink={false} />
      </DetailsCardInner>
    </DetailsCard>
  )
}

export default SavedPatientDetailsFormCard
