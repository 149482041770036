import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { IAppState } from "../../../app/appTypes"
import { getCaseById } from "../../../shared/selectors/case"
import { getMeetingCaseByMeetingAndCaseId } from "../../../shared/selectors/meetingCase"
import { createMeetingCaseAction, deleteMeetingCaseAction } from "../../meetingCase/state/meetingCaseActions"

interface IProps {
  caseId: string
  meetingId: string
}

const AddMeetingToCaseButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation(["shared", "case", "meeting"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseByMeetingAndCaseId(state, props))
  const dispatch = useDispatch()
  const createMeetingCase = (meetingId, caseId) => dispatch(createMeetingCaseAction(meetingId, caseId))
  const deleteMeetingCase = (meetingId, caseId, meetingCaseId) => dispatch(deleteMeetingCaseAction(meetingId, caseId, meetingCaseId))

  if (meetingCase) {
    if (!meetingCase.permissions.canDelete) return null

    return (
      <Button
        action={() => deleteMeetingCase(props.meetingId, props.caseId, meetingCase.id)}
        variant={StyleVariants.PURPLE}
        className="self-center self-end"
        isChunky={true}
        key={`removeCase-${props.meetingId}`}
      >
        {t("meeting:removeCaseFromMeeting")}
      </Button>
    )
  }

  return (
    <Button
      action={() => createMeetingCase(props.meetingId, theCase.id)}
      variant={StyleVariants.ORANGE}
      className="self-center self-end"
      isChunky={true}
      key={`addCase-${props.meetingId}`}
    >
      {t("meeting:addCaseToMeeting")}
    </Button>
  )
}

export default AddMeetingToCaseButton
