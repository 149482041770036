import React from "react"
import TextAuthorDetails from "../../../../shared/components/text/TextAuthorDetails"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import { IAsset } from "../../state/assetsTypes"
import ChatDetail from "./association/ChatDetail"
import MeetingDetail from "./association/MeetingDetail"
import TaskDetail from "./association/TaskDetail"
import MeetingCaseAssetSelectedByText from "./MeetingCaseAssetSelectedByText"

interface Props {
  meetingCaseAsset: IMeetingCaseAsset
  meetingId: string
  asset: IAsset

}

const AssetSubHeader: React.FC<Props> = (props: Props) => {
  return (
    <>
      <TextAuthorDetails
        firstName={props.asset.createdBy.firstName}
        lastName={props.asset.createdBy.lastName}
        createdAt={props.asset.createdAt} />
      <MeetingDetail meeting={props.asset.asset.meeting} />
      <TaskDetail taskId={props.asset.taskId} />
      <ChatDetail summary={props.asset.asset.summary} />
      <MeetingCaseAssetSelectedByText
        meetingCaseAsset={props.meetingCaseAsset}
        meetingId={props.meetingId} />
    </>
  )
}

export default AssetSubHeader
