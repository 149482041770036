import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getAuditEventById } from "../../../../shared/selectors/audit"
import { getUserById } from "../../../../shared/selectors/user"
import { CardIconStyle, IAuditEvent, IAuditEventCardData } from "../../state/auditEventTypes"
import { getAuditEventsCardData } from "../AuditEventAllCards"
import AuditEventByUserNameAndDate from "../details/AuditEventByUserNameAndDate"
import AuditEventCardHeader from "../details/AuditEventCardHeader"
import AuditEventCardIcon from "../details/AuditEventCardIcon"
import AuditEventMeetingDetail from "../details/AuditEventMeetingDetail"

interface IPropsFromParent {
  auditEventId: string
  currentUserId?: string
}

export const AuditEventListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const auditEvent = useSelector((state: IAppState): IAuditEvent => getAuditEventById(state, props.auditEventId))
  const cardData: IAuditEventCardData = getAuditEventsCardData(auditEvent)

  const headerUser = useSelector((state: IAppState) => {
    if (cardData.headerUserId) {
      return getUserById(state, cardData.headerUserId)
    }
    return null
  })

  const byUser = useSelector((state: IAppState) => {
    const userId = cardData.byUserId || auditEvent.createdById
    if (userId) {
      return getUserById(state, userId)
    }
    return null
  })

  if (cardData.hideCard) return null
  return (
    <div className="my-2">
      <div
        className={classNames(
          "p-2",
          "flex",
          "rounded-md",
          "border-2",
          "bg-ch-blue-100",
        )}
      >
        <AuditEventCardIcon icon={cardData.iconContent.iconName} color={cardData.iconContent.color || CardIconStyle.purpleColor} />
        <div className="flex-1">
          <div className="font-semibold">
            {<AuditEventCardHeader auditEvent={auditEvent} user={headerUser} headerText={cardData.headerText} />}
          </div>
          <AuditEventByUserNameAndDate auditEvent={auditEvent} user={byUser} />
          {cardData.body ? cardData.body : null}
          <AuditEventMeetingDetail meetingId={cardData.meetingId} />
        </div>
      </div>
    </div>
  )
}
