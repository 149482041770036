import { AnyAction, combineReducers, Reducer } from "redux"
import _ from "lodash"
import { CaseSharedAssetsActionTypes, ISharedAsset, ISharedAssetEntityState, TSharedAssetByUserId } from "../caseSharedAssetsTypes"

type TByIdState = { [id: string]: ISharedAsset }

export const byId: Reducer<TByIdState> = (state: TByIdState = {}, action: AnyAction): TByIdState => {
  switch (action.type) {
    case CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_SUCCESS:
    case CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_SUCCESS:
    case CaseSharedAssetsActionTypes.DELETE_CASE_SHARED_ASSET_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.assets)
    default:
      return state
  }
}

export const canBeSharedWithUserByUserId: Reducer<TSharedAssetByUserId> = (state: TSharedAssetByUserId = {}, action: AnyAction): TSharedAssetByUserId => {
  switch (action.type) {
    case CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_SUCCESS:
    case CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_SUCCESS:
    case CaseSharedAssetsActionTypes.DELETE_CASE_SHARED_ASSET_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.userId] : {
          ...state[action.payload.userId],
          [action.payload.caseId]: action.payload.all.result
        }
      }
    default:
      return state
  }
}

export const sharedAssetEntityReducer: Reducer<ISharedAssetEntityState> = combineReducers({
  byId,
  canBeSharedWithUserByUserId
})
