export type ICaseSource = {
  id: string
  name: string
  regionalTeam: string
}

export interface IFetchCaseSourceAsyncAction {
  type: string
}

export enum CaseSourceActionTypes {
  FETCH_CASE_SOURCES_ASYNC_PENDING = "@@caseSource/FETCH_CASE_SOURCES_ASYNC_PENDING",
  FETCH_CASE_SOURCES_ASYNC_SUCCESS = "@@caseSource/FETCH_CASE_SOURCES_ASYNC_SUCCESS",
  FETCH_CASE_SOURCES_ASYNC_ERROR = "@@caseSource/FETCH_CASE_SOURCE_ASYNC_ERROR",
}
