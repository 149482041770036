import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import { ICase } from "../../state/caseTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import Buttons from "../../../../shared/components/layout/Buttons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconTask } from "../../../../svg/icons"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import CaseTaskNewForm from "./forms/CaseTaskNewForm"
import { IUser } from "../../../user/state/userTypes"
import { CaseAssetTabNames } from "../activityPanel/CaseActivityPanel"

type Props = {
  store?: MockStore
  caseId: string
  meetingId?: string // meeting case page only
  mentionableUsers: { [id: string]: IUser }
}

export enum Tabs {
  TaskNew = "task_new",
}

const AddCaseTaskPanel: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "case"])
  const [currentTab, setCurrentTab] = useState(null)
  const theCase: ICase = useSelector((state: IAppState) => getCaseById(state, {caseId: props.caseId}))

  const renderTabContent = (): JSX.Element => {
    switch (currentTab) {
      case Tabs.TaskNew:
        return <CaseTaskNewForm
          caseId={props.caseId}
          closeAction={() => setCurrentTab(null)}
          originTab={CaseAssetTabNames.TASKS}
        />

      default:
        return null
    }
  }

  const renderButtons = (): JSX.Element => {
    if (currentTab !== null) return null

    if (theCase.locked) return (
      <div>
        <h3 className="text-center text-ch-blue-alt-400">
          {t("case:addTasksLocked")}
        </h3>
        <ListBackground />
      </div>
    )

    return (
      <div>
        <h3 className="flex mb-2">
          <IconTask className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:tasksTitle")} />
          {t("case:tasksTitle")}
        </h3>

        <Buttons
          className="mb-2"
          buttons={[
            <Button
              action={() => setCurrentTab(Tabs.TaskNew)}
              variant={StyleVariants.BLUE_ALT}
              key="1"
            >
              {t("case:addTask")}
            </Button>
          ]}
        />
      </div>
    )
  }

  return (
    <div>
      {renderButtons()}
      {renderTabContent()}
    </div>
  )
}

export default AddCaseTaskPanel
