import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { cycleVideoSourceAction } from "../../../webRTC/state/webRTCActions"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { getWebRTCPublisher } from "../../../../shared/selectors/webRTC"

const LiveMeetingUserVideoDeviceSelect: React.FC = () => {
  const { t } = useTranslation(["liveMeeting"])
  const dispatch = useDispatch()
  const onClickSwitchVideoInput = (publisher: OT.Publisher) => dispatch(cycleVideoSourceAction(publisher))
  const publisher = useSelector(getWebRTCPublisher)

  return (
    <div>
      <h2 className="mb-2">
        {t("liveMeeting:chooseVideoInput")}
      </h2>
      <div className="p-2 border-ch-gray-300 border">
        <p className="mb-2">
          {t("liveMeeting:cycleVideoSource")}
        </p>
        <Button action={() => onClickSwitchVideoInput(publisher)} className="self-center self-end" variant={StyleVariants.BLUE} isChunky={true}>
          {t("liveMeeting:switchCamera")}
        </Button>
      </div>
    </div>
  )
}

export default LiveMeetingUserVideoDeviceSelect
