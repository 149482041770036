import { AnyAction, combineReducers, Reducer } from "redux"
import ITasksUIState, { TasksActionTypes, tasksCableActionTypes, TasksOrderByOptions } from "../tasksTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalTasksCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return action.payload.totalItems

    default:
      return state
  }
}

export const outstandingCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case tasksCableActionTypes.UPDATE_OUTSTANDING_COUNT:
      return action.payload.outstandingCount

    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = ["outstanding"], action: AnyAction): string[] => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return action.payload.appliedFilters

    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = TasksOrderByOptions.DueDateAsc, action: AnyAction): string => {
  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const tasksUIReducer: Reducer<ITasksUIState> = combineReducers({
  totalTasksCount,
  outstandingCount,
  totalPages,
  resultsPerPage,
  appliedFilters,
  orderBy
})
