import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const caseGuestUserInviteExistingValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    guestUserIds: Yup.array()
      .required(i18n.t("shared:required"))
      .min(1, i18n.t("shared:mustSelectAtLeastOne")),
  })
}
