import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { IMeetingUser } from "../meetingUserTypes"
import { MeetingActionTypes } from "../../../meeting/state/meetingTypes"
import { MeetingsActionTypes } from "../../../meetings/state/meetingsActionTypes"
import { MeetingUserActionTypes } from "../../../meetingUser/state/meetingUserTypes"

type TByIdState = { [id: string]: IMeetingUser }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
    case MeetingActionTypes.UPDATE_MEETING_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetingUsers
      }

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.meetingUsers
      }

    case MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetingUsers
      }

    case MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetingUsers
      }

    case MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetingUsers
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.meetingUsers || [])))

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.meetingUsers || [])))

    case MeetingUserActionTypes.CREATE_MEETING_USER_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.meetingUsers || [])))

    case MeetingUserActionTypes.UPDATE_MEETING_USER_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.meetingUsers || [])))

    case MeetingUserActionTypes.DESTROY_MEETING_USER_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.meetingUsers || [])))

    default:
      return state
  }
}

export const meetingUserEntityReducer: Reducer<INormalizedEntities<IMeetingUser>> = combineReducers({
  byId,
  allIds
})
