import { ICreateCaseTaskResponseAsyncAction, ITaskResponseNewData, TaskResponseActionTypes } from "./taskResponseTypes"

export const createCaseTaskResponseAsync = (caseId: string, taskId: string, taskResponse: ITaskResponseNewData): ICreateCaseTaskResponseAsyncAction => {
  return {
    type: TaskResponseActionTypes.CREATE_CASE_TASK_RESPONSE_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      taskId: taskId,
      taskResponse: taskResponse
    }
  }
}
