import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { IFetchAllPatientsAsyncAction, IFetchPatientsAsyncAction, PatientsActionTypes } from "./patientsTypes"

export const fetchPatientsAsync = (searchQuery: ISearchQuery): IFetchPatientsAsyncAction => {
  return {
    type: PatientsActionTypes.FETCH_PATIENTS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const fetchAllPatientsAsync = (): IFetchAllPatientsAsyncAction => {
  return {
    type: PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_PENDING
  }
}

export const uploadPatientsCSVAsync = (csvFile) => {
  return {
    type: PatientsActionTypes.UPLOAD_PATIENTS_CSV_ASYNC_PENDING,
    payload: csvFile
  }
}
