import React from "react"
import { useTranslation } from "react-i18next"
import { ITask } from "../../../../tasks/state/tasksTypes"

interface IProps {
  task: ITask
}

const buildTaskStatusLabel = (text: string, colour: string): JSX.Element => {
  return (
    <div className={`text-sm text-white px-1 rounded mr-2 flex content-center ${colour}`} >
      {text}
    </div >
  )
}

const PathwayTaskStatusLabel: React.FC<IProps> = ({ task }: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  if (task.completedAt) {
    return (buildTaskStatusLabel(t("completed"), "bg-ch-green-300"))
  }
  if (!task.users?.length) {
    return (buildTaskStatusLabel(t("unassigned"), "bg-ch-blue-alt-300"))
  }
  return (buildTaskStatusLabel(t("assigned"), "bg-ch-blue-alt-500"))
}

export default PathwayTaskStatusLabel
