import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { IMeetingCaseDeleteData, IMeetingCaseNewData, MeetingCaseActionTypes, MeetingCaseCableActionTypes } from "../meetingCaseTypes"
import API from "../../../../apis/contextmeeting/api"
import { fetchMeetingCaseSchema, fetchMeetingSchema } from "../../../../apis/contextmeeting/schema"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import history from "../../../../app/routes/history"
import { fetchCaseAssetsAction } from "../../../assets/state/assetsActions"
import { fetchMeetingCaseAction } from "../meetingCaseActions"

export function* fetchMeetingCaseAsync(action: Effect): Generator {
  try {
    const meetingCaseId = action.payload

    const response: any = yield call(API.get, `/meeting_cases/${meetingCaseId}`, withAuthHeader()
    )

    const normalizedResponse = normalize(response.data, fetchMeetingCaseSchema)

    yield put({
      type: MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "meetingCaseNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchMeetingCaseAsync(): Generator {
  yield takeLeading(MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_PENDING, fetchMeetingCaseAsync)
}


export function* createMeetingCase(action: Effect): Generator {
  const payload: IMeetingCaseNewData = action.payload

  try {
    const meetingCaseData = {
      meetingCase: payload
    }

    // responds with the full meeting
    const response: any = yield call(API.post, "/meeting_cases", meetingCaseData, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: payload.caseId,
        meetingId: payload.meetingId
      }
    })

    // TODO: Remove this redirect?! Not sure what it is for
    if (payload.redirectPath) yield call(history.push, payload.redirectPath)
  } catch (error) {
    yield put({
      type: MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchCreateMeetingCase(): Generator {
  yield takeLeading(MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_PENDING, createMeetingCase)
}

export function* deleteMeetingCase(action: Effect): Generator {
  const payload: IMeetingCaseDeleteData = action.payload

  try {
    const meetingCaseId = payload.meetingCaseId

    // responds with the full meeting
    const response: any = yield call(API.delete, `meeting_cases/${meetingCaseId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchMeetingSchema)

    yield put({
      type: MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: payload.caseId,
        meetingId: payload.meetingId,
        meetingCaseId: meetingCaseId
      }
    })
  } catch (error) {
    yield put({
      type: MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchDeleteMeetingCase(): Generator {
  yield takeLeading(MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_PENDING, deleteMeetingCase)
}

export function* receiveMeetingCaseCableUpdateCaseAssets(action: Effect): Generator {
  yield put(fetchCaseAssetsAction(action.payload.caseId))
  yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
}

export function* watchReceiveMeetingCaseCableUpdateCaseAssets(): Generator {
  yield takeLeading(MeetingCaseCableActionTypes.ASSETS_CHANGED, receiveMeetingCaseCableUpdateCaseAssets)
}
