import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { ChatNewActionTypes, ICreateChatAsyncAction } from "../chatNewTypes"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"
import { ChatsActionTypes } from "../../../chats/state/chatsTypes"

export function* createChatAsync(action: ICreateChatAsyncAction): Generator {
  try {
    const data = {
      chat: action.payload.chat
    }
    const response: any = yield call(API.post, "/chats", data, withAuthHeader())
    const normalizedResponse = normalize(response.data, fetchChatsSchema)
    yield put({
      type: ChatNewActionTypes.CREATE_CHAT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })
    yield put({
      type: ChatsActionTypes.UPDATED_SELECTED_CHAT_ID,
      payload: normalizedResponse.result
    })
  } catch (error) {
    yield put({
      type: ChatNewActionTypes.CREATE_CHAT_ASYNC_ERROR,
      payload: error
    })
    yield put(displayUserMessageAction({
      messageKey: "createChatFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateChatAsync(): Generator {
  yield takeLeading(ChatNewActionTypes.CREATE_CHAT_ASYNC_PENDING, createChatAsync)
}
