import React from "react"
import { useTranslation } from "react-i18next"
import { Prompt } from "react-router-dom"
import { useBeforeunload } from "react-beforeunload"

interface Props {
  isEnabled?: boolean;
}

const UnsavedChangesPrompt: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const message = t("shared:unsavedChangesPrompt")

  useBeforeunload((event: BeforeUnloadEvent) => {
    if(props.isEnabled) {
      event.preventDefault()
    }
  })

  return (
    <Prompt
      message={message}
      when={props.isEnabled}
    />
  )
}

export default UnsavedChangesPrompt
