import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconEye({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path d="M0-1h30v21H0z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M15.032 0c3.369 0 6.407.834 9.115 2.503S28.806 6.338 30 9c-1.194 2.662-3.145 4.828-5.853 6.497C21.44 17.166 18.401 18 15.032 18c-3.369 0-6.418-.834-9.147-2.503C3.155 13.828 1.194 11.662 0 9c1.194-2.662 3.156-4.828 5.885-6.497C8.615.834 11.663 0 15.032 0zm0 15.019c1.876 0 3.475-.59 4.797-1.772 1.322-1.181 1.983-2.597 1.983-4.247s-.66-3.066-1.983-4.247c-1.322-1.181-2.92-1.772-4.797-1.772-1.876 0-3.486.59-4.83 1.772C8.86 5.934 8.189 7.35 8.189 9s.671 3.066 2.015 4.247c1.343 1.181 2.953 1.772 4.829 1.772zM15 5.4c1.13 0 2.1.347 2.91 1.04.81.694 1.216 1.548 1.216 2.56s-.405 1.866-1.216 2.56c-.81.693-1.78 1.04-2.91 1.04-1.13 0-2.09-.347-2.878-1.04-.79-.694-1.184-1.548-1.184-2.56s.395-1.866 1.184-2.56C12.91 5.748 13.87 5.4 15 5.4z"
        />
      </g>
    </svg>
  );
}

export default SvgIconEye;
