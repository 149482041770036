import { takeLeading, put, call, Effect } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { clearSession, setSession } from "../../../../app/state/appSessionStorage"
import { AccountActionTypes } from "../accountTypes"
import { userSchema } from "../../../../apis/contextmeeting/schema"
import { normalize } from "normalizr"
import { AxiosError } from "axios"

export function* signInUserAsync(action): Generator {
  try {
    const body = {
      user: {
        email: action.payload.email,
        password: action.payload.password
      },
      client: 'browser'
    }

    const response: any = yield call(API.post, "/signin/", body)
    const normalizedResponse = normalize(response.data.user, userSchema)

    const token = response.headers.authorization.split(" ")[1]
    const user = response.data.user
    setSession(token, user.id, user.email)
    yield put({
      type: AccountActionTypes.SIGN_IN_USER_ASYNC_SUCCESS,
      payload: {
        session: {
          id: response.data.id,
          apiToken: token,
        },
        user: normalizedResponse
      }
    })

    // ToDo:
    // If details are missing: send user to "Complete account page",
    // If T&C not agreed: send user to "T&C page"
    // Else: send user to "MEETINGS page" (Dashboard)
  } catch (error) {
    console.error(error)
    yield put({
      type: AccountActionTypes.SIGN_IN_USER_ASYNC_ERROR,
      payload: error
    })
    const errorInstance = error as AxiosError
    if (errorInstance.response.status === 401) {
      yield put(displayUserMessageAction({
        messageKey: "unauthorizedSignInAttempt",
        type: UserMessageTypes.ERROR
      }))
    }
  }
}

export function* watchSignInUserAsync(): Generator {
  yield takeLeading(AccountActionTypes.SIGN_IN_USER_ASYNC_PENDING, signInUserAsync)
}

export function* signOutUserAsync(_action: Effect): Generator {
  try {
    clearSession()

    yield put({
      type: AccountActionTypes.SIGN_OUT_USER_ASYNC_SUCCESS,
    })
  } catch (_error) {
    yield put({
      type: AccountActionTypes.SIGN_OUT_USER_ASYNC_ERROR,
    })
  }
}

export function* watchSignOutUserAsync(): Generator {
  yield takeLeading(AccountActionTypes.SIGN_OUT_USER_ASYNC_PENDING, signOutUserAsync)
}

export function* userSignInTypeAsync(action: Effect): Generator {
  try {
    const response: any = yield API.post("/user/type", { email: action.payload })

    yield put({
      type: AccountActionTypes.USER_SIGN_IN_TYPE_ASYNC_SUCCESS,
      payload: {
        signInType: response.data.signInType
      }
    })
  } catch (error) {

    yield put({
      type: AccountActionTypes.USER_SIGN_IN_TYPE_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchUserSignInTypeAsync(): Generator {
  yield takeLeading(AccountActionTypes.USER_SIGN_IN_TYPE_ASYNC_PENDING, userSignInTypeAsync)
}
