import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import NotificationListItem from "./NotificationListItem"
import { getAllNotificationIds } from "../../../../shared/selectors/notification"

interface IProps {
  refreshNotifications: () => void
}

const NotificationList: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["notification"])
  const notificationIds = useSelector(getAllNotificationIds)

  if (!notificationIds.length) return (
    <h3 className="text-lg font-bold text-center">
      {t("notification:noNotifications")}
    </h3>
  )

  return (
    <ul>
      {notificationIds.map((notificationId: string) => {
        return (
          <NotificationListItem
            key={notificationId}
            notificationId={notificationId}
            refreshNotifications={props.refreshNotifications}
          />
        )
      })}
    </ul>
  )
}

export default NotificationList
