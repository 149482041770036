import { AnyAction, combineReducers, Reducer } from "redux"
import ITeamsUIState, { TeamsActionTypes, TeamsOrderByOptions } from "../teamsTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

export const totalItems: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.totalItems
    default:
      return state
  }
}
export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.totalPages
    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = ["isMember", "isOwner"], action: AnyAction): string[] => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.appliedFilters
    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage
    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = TeamsOrderByOptions.UpdatedDesc, action: AnyAction): string => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const teamsSearchUIReducer: Reducer<ITeamsUIState> = combineReducers({
  totalItems,
  totalPages,
  appliedFilters,
  resultsPerPage,
  searchTerm,
  orderBy
})
