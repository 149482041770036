import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IAsset, IDocumentAsset, ILinkAsset } from "../../../assets/state/assetsTypes"

interface IPropsFromParent {
  assetId: string,
  title?: boolean,
  name?: boolean
}

const AuditEventMeetingAssetDetail = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])
  const meetingCaseAsset = useSelector((state: IAppState): IAsset => getAssetById(state, props))

  const getAssetTitle = () => {
    const asset = meetingCaseAsset.asset as ILinkAsset
    return asset.title
  }

  const getAssetName = () => {
    const asset = meetingCaseAsset.asset as IDocumentAsset
    return asset.name
  }

  return (
    <div>
      <span className="font-semibold">
        {t("auditEvent:title")}:&nbsp;
      </span>
      <span className="break-all">
        {props.title ? getAssetTitle() : getAssetName()}
      </span>
    </div>
  )
}

export default AuditEventMeetingAssetDetail
