import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconFiles({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 22"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <g fill="currentColor">
          <path d="M18.667 5.667h-12A.667.667 0 006 6.333V21c0 .368.298.667.667.667h12a.667.667 0 00.666-.667V6.333a.667.667 0 00-.666-.666zm-2 10.666h-8V15h8v1.333zm0-2.666h-8v-1.334h8v1.334zm0-2.667h-8V9.667h8V11z" />
          <path d="M2 15V1.667h12V1a.667.667 0 00-.667-.667h-12A.667.667 0 00.667 1v14.667c0 .368.298.666.666.666H2V15z" />
          <path d="M4.667 17.667V4.333h12v-.666A.667.667 0 0016 3H4a.667.667 0 00-.667.667v14.666c0 .369.299.667.667.667h.667v-1.333z" />
        </g>
        <path d="M-2-1h24v24H-2z" />
      </g>
    </svg>
  );
}

export default SvgIconFiles;
