import React from "react"
import classNames from "classnames"
import { StyleVariants } from "../../state/sharedTypes"

export interface ITab {
  label: string
  value: string
  count?: number
  isCountHighlighted?: boolean
}

interface Props {
  className?: string
  tabs: ITab[]
  value: string
  onClick: (value: string) => void
  variant?: string
}

const defaultProps = {
  variant: StyleVariants.BLUE
}

const Tabs: React.FC<Props> = (props: Props): JSX.Element => {
  let variant = props.variant
  if(!variant) {
    variant = StyleVariants.BLUE
  }

  const renderTabCount = (tab: ITab): JSX.Element => {
    if(!tab.count) return null
    if(tab.isCountHighlighted) return (
      <span className="bg-ch-orange-500 text-white rounded-full w-5 h-5 text-sm flex justify-center items-center p-2 ml-1 absolute right-0">{tab.count}</span>
    )
    return <span className="ml-1">({tab.count})</span>
  }

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "flex"
    ]

    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    return(
      <ul className={allClasses}>
        {props.tabs.map((tab: ITab, index: number) => {
          const isSelected = tab.value == props.value

          return (
            <li
            className={
              classNames(
                "mb-2",
                "text-ch-gray-500",
                {
                  "ml-2": index !== 0
                }
            )} key={index}>
              <a
                onClick={() => props.onClick(tab.value)}
                className={classNames(
                  "font-semibold",
                  "p-1",
                  "uppercase",
                  "relative",
                  "flex",
                  "items-center",
                  {
                    "pr-6": (tab.isCountHighlighted && tab.count > 0),
                    [`text-${props.variant}-500`]: isSelected,
                    [`border-${props.variant}-500`]: isSelected,
                    "border-b-4": isSelected,
                    [`hover:text-${props.variant}-500`]: !isSelected,
                    "cursor-pointer": !isSelected,
                  }
                )}
              >
                {tab.label} {renderTabCount(tab)}
              </a>
            </li>
            )
        })}
      </ul>
    )
  }

  return renderContent()
}

Tabs.defaultProps = defaultProps

export default Tabs
