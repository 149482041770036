import React from "react"
import { Link } from "react-router-dom"
import { StyleVariants } from "./../../../shared/state/sharedTypes"

interface Props {
  className?: string
  variant?: StyleVariants
  href?: string
  external?: boolean
  isChunky?: boolean
  isDisabled?: boolean
  isSubmit?: boolean
  action?: (e?: React.MouseEvent) => void
  children: React.ReactNode
  customColour?: string
}

const Button: React.FC<Props> = (props: Props): JSX.Element => {
  let variant = props.variant
  if (!variant) {
    variant = StyleVariants.GRAY
  }

  const renderContent = (): JSX.Element => {
    const defaultClasses = [
      "block",
      "text-white",
      "uppercase",
      "px-4",
      "rounded-full"
    ]

    const chunkyClasses = [
      "font-semibold",
      "text-sm",
      "py-2"
    ]

    const thinClasses = [
      "font-light",
      "py-1"
    ]

    let stateClasses = [
      `bg-${variant}-500`,
      `hover:bg-${variant}-600`,
    ]

    if (props.customColour) {
      stateClasses = [
        `bg-${props.customColour}-500`,
        `hover:bg-${props.customColour}-600`,
      ]
    }

    if (props.isDisabled) {
      stateClasses = [
        "bg-ch-gray-450",
        "hover:bg-ch-gray-450",
        "cursor-not-allowed"
      ]
    }

    const additionalClasses = props.isChunky ? chunkyClasses : thinClasses

    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...additionalClasses, ...propClasses, ...stateClasses].join(" ")

    if (props.href) {
      if (props.external) {
        return (
          <a href={props.href} target="_blank" rel="noreferrer" className={allClasses}>
            {props.children}
          </a>
        )
      }
      return (
        <Link to={props.href} className={allClasses}>
          {props.children}
        </Link>
      )
    }

    const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
      className: allClasses
    }

    buttonProps.type = props.isSubmit ? "submit" : "button"

    if (props.action)
      buttonProps.onClick = props.action

    return (
      <button
        {...buttonProps}
        disabled={props.isDisabled}
      >
        {props.children}
      </button>
    )
  }

  return renderContent()
}

export default Button
