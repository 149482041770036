import * as Yup from "yup"
import i18n from "../../../../app/i18n"
import { isFilesSizeUnderMaximum, fileUploadOptions, isCSVAcceptedType } from "../../../../shared/helpers/fileHelpers"

export const patientsUploadValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    file: Yup.mixed()
      .test("fileSizeMaximum", i18n.t("shared:fileTooLarge", { maxFileSize: fileUploadOptions.maxFileSizeMB }), (value) => {
        return isFilesSizeUnderMaximum(value)
      })
      .test("acceptedFileType", i18n.t("shared:csvWrongType",  { acceptedFileTypes: fileUploadOptions.acceptedCSVTypes }), (value) => {
        return isCSVAcceptedType(value)
      })
  })
}
