import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { IconScribe, IconPresenter } from "../../../../svg/icons"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import { presenterTransferAction, scribeTransferAction } from "../../state/liveMeetingActions"

interface IProps {
  userId: string
  isOverlay?: boolean
}

const LiveMeetingUserRoleControls: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, props))
  const currentUser = useSelector(getCurrentUserWithConfig)
  const onHandoverScribeControl = (userId: string) => dispatch(scribeTransferAction(userId))
  const onHandoverPresenterControl = (userId: string) => dispatch(presenterTransferAction(userId))

  const { t } = useTranslation(["liveMeeting"])

  const roleActiveClassNames = classNames({
    "text-ch-gray-600": !props.isOverlay,
    "text-white": props.isOverlay
  })

  const roleTransferableClassNames = classNames("text-ch-gray-500", {
    "hover:text-ch-gray-600": !props.isOverlay,
    "hover:text-white": props.isOverlay
  })

  if (!user.config || !currentUser.config) { return null }

  const renderScribeControl = (): JSX.Element => {
    if (!user.config?.isOnline) return null
    if (user.config?.isScribe) {
      return (
        <span className={roleActiveClassNames}>
          <IconScribe
            className="fill-current w-6 h-6"
            title={t("user:scribe")}
          />
        </span>
      )
    }
    return (
      <a
        href="#"
        className={roleTransferableClassNames}
        onClick={() => onHandoverScribeControl(props.userId)}>
        <IconScribe
          className="fill-current w-6 h-6"
          title={t("liveMeeting:handoverScribeControl")}
        />
      </a>
    )
  }

  const renderPresenterControl = (): JSX.Element => {
    if (!user.config?.isOnline) return null
    if (user.config?.isPresenter) {
      return (
        <span className={roleActiveClassNames}>
          <IconPresenter
            className="fill-current w-6 h-6"
            title={t("user:presenter")}
          />
        </span>
      )
    }
    return (
      <a
        href="#"
        className={roleTransferableClassNames}
        onClick={() => onHandoverPresenterControl(props.userId)}>
        <IconPresenter
          className="fill-current w-6 h-6"
          title={t("liveMeeting:handoverPresenterControl")}
        />
      </a>
    )
  }

  return (
    <ul className="flex">
      <li className="ml-2">
        {renderPresenterControl()}
      </li>
      <li className="ml-2">
        {renderScribeControl()}
      </li>
    </ul>
  )
}

export default LiveMeetingUserRoleControls
