import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ButtonFilter from "../../../../../shared/components/buttons/ButtonFilter"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { ICaseTaskFilters } from "../../../../tasks/state/tasksTypes"

interface IProps {
  setFilters: (filters: ICaseTaskFilters) => void
  initialValues?: ICaseTaskFilters
}

const TaskFilter: React.FC<IProps> = ({ setFilters, initialValues }: IProps): JSX.Element => {
  const { t } = useTranslation(["task"])
  const [showOutstanding, setShowOutstanding] = useState(initialValues?.showOutstanding || false)
  const [showCompleted, setShowCompleted] = useState(initialValues?.showCompleted || false)

  const setShowAll = () => {
    setShowOutstanding(false)
    setShowCompleted(false)
  }

  const showAll = (!(showOutstanding || showCompleted))

  const filters: ICaseTaskFilters = {
    showAll,
    showOutstanding,
    showCompleted
  }

  useEffect(() => {
    setFilters(filters)
  }, [showOutstanding, showCompleted])

  return (
    <Buttons
      className="text-sm"
      buttons={
        [
          <ButtonFilter key={0} isOn={showAll} action={() => (setShowAll())}>
            {t("task:filter:all")}
          </ButtonFilter>,
          <ButtonFilter key={1} isOn={showOutstanding} action={() => (setShowOutstanding(!showOutstanding))}>
            {t("task:filter:outstanding")}
          </ButtonFilter>,
          <ButtonFilter key={2} isOn={showCompleted} action={() => (setShowCompleted(!showCompleted))}>
            {t("task:filter:completed")}
          </ButtonFilter>
        ]
      }>
    </Buttons >
  )
}

export default TaskFilter
