import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Modal from "react-modal"
import SelectSharedAssetsList from "./list/SelectSharedAssetsList"
import { IAppState } from "../../../app/appTypes"
import { IUser } from "../../user/state/userTypes"
import { getUserById } from "../../../shared/selectors/user"
import { bottomModalStyle } from "../../../shared/state/sharedTypes"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import UserDetailsCard from "../../../shared/components/user/UserDetailsCard"
import ViewAssetModal from "../../case/components/assetViewer/ViewAssetModal"

interface Props {
  caseId: string
  userId: string
  closeModal: () => void
  isOpen: boolean
}

const ShareAssetsModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const user = useSelector((state: IAppState): IUser => getUserById(state, props.userId))
  const [isViewAssetModalOpen, setViewAssetModalIsOpen] = React.useState(false)
  const [selectedAssetId, setSelectedAssetId] = React.useState(null)

  const openViewAssetModal = (selectedAssetId: string): void => {
    setSelectedAssetId(selectedAssetId)
    setViewAssetModalIsOpen(true)
  }

  const closeViewAssetModal = (): void => {
    setViewAssetModalIsOpen(false)
  }

  return (
    <>
      <ViewAssetModal
        isOpen={isViewAssetModalOpen}
        closeModal={closeViewAssetModal}
        selectedAssetId={selectedAssetId}
      />
      <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={bottomModalStyle}>
        <ModalContainer handleClose={props.closeModal}>
          <h2 className="text-lg mb-3">
            {t("case:guestUsers:titles:shareAssets")}
          </h2>
          <p className="text-ch-blue-600 mb-3">
          {t("case:guestUsers:text:selectAssetsToShare")}
          </p>
          <DetailsCard className="mb-6">
            <DetailsCardInner>
              <UserDetailsCard user={user} />
            </DetailsCardInner>
          </DetailsCard>
          <SelectSharedAssetsList
            caseId={props.caseId}
            userId={props.userId}
            openAssetModal={openViewAssetModal}
          />
        </ModalContainer>
      </Modal>
    </>
  )
}

export default ShareAssetsModal
