import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { IAuditEvent } from "../../state/auditEventTypes"
import { IUser } from "../../../user/state/userTypes"
import { getUserById } from "../../../../shared/selectors/user"

interface IProps {
  auditEvent: IAuditEvent
}

const AuditEventConsentOnBehalf = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])
  const user: IUser = useSelector((state: IAppState): IUser => getUserById(state, props.auditEvent.eventData.changes.userId[1]))

  if (props.auditEvent.eventData.changes.consentById[1] === props.auditEvent.eventData.changes.userId[1]) return null
  return (
    <div>
      <span className="font-semibold">
        {t("auditEvent:onBehalfOf")}
      </span>:&nbsp;{user.firstName}&nbsp;{user.lastName}
    </div>
  )
}

export default AuditEventConsentOnBehalf
