export default interface IUsersUIState {
  totalUsersCount: number
  totalPages: number
  searchTerm: string
  resultsPerPage: number
  appliedFilters: string[]
  orderBy: string
}

export enum UserOrderByOptions {
  nameAsc = "last_name_asc",
  nameDesc = "last_name_desc",
  dateAddedAsc = "created_at_asc",
  dateAddedDesc = "created_at_desc",
  lastSeenDesc = "last_seen_date_desc"
}
