import { createSelector } from "reselect"
import { IAppState } from "../../../../app/appTypes"
import { getSelectedMeetingCaseId } from "../meetingCase"

export const getRecommendations = (state: IAppState): { [id: string]: string } => state.liveMeetingConfig?.recommendations

export const getCurrentCaseRecommendation = createSelector(
  getRecommendations,
  getSelectedMeetingCaseId,
  (recommendations, selectedMeetingCaseId): string => {
    return recommendations[selectedMeetingCaseId] || ""
  }
)
