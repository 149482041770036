import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
  isDark?: boolean
  noBorder?: boolean
}

type IProps = IPropsFromParent

const defaultProps = {
  isDark: false
}

const DetailsCardWithNested: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "flex-col",
    "mb-2",
    "rounded-md",
    "p-4",
    { "border border-ch-gray-400": !props.noBorder }
  ]

  const backgroundColourClass = props.isDark ? "bg-ch-gray-300" : "bg-white"
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, backgroundColourClass, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      {props.children}
    </div>
  )
}

DetailsCardWithNested.defaultProps = defaultProps

export default DetailsCardWithNested
