import _ from "lodash"
import { AnyAction, combineReducers, Reducer } from "redux"
import { INormalizedEntities } from "../../../../app/appTypes"
import { ISpecialty, SpecialtyActionTypes } from "../specialtyTypes"

type TByIdState = { [id: string]: ISpecialty }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch(action.type) {
    case SpecialtyActionTypes.FETCH_SPECIALTIES_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.specialties
      }
    default:
      return {...state}
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch(action.type) {
    case SpecialtyActionTypes.FETCH_SPECIALTIES_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.result))
    default:
      return state
  }
}

export const specialtiesReducer: Reducer<INormalizedEntities<ISpecialty>> = combineReducers({
  byId,
  allIds
})
