import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconSplitscreen({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 19.617h29V0H0v19.617zm16.206-2.559h9.382V5.118h-9.382v11.94zm-12.794 0h9.383V5.118H3.412v11.94z"
      />
    </svg>
  );
}

export default SvgIconSplitscreen;
