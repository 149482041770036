import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { userStreamSubscriberOptions, currentUserStreamSubscriberOptions } from "../../../webRTC/state/webRTCStreamProperties"
import WebRTCSubscriber from "../../../webRTC/components/WebRTCSubscriber"
import LiveMeetingUserOnlineStatus from "./LiveMeetingUserOnlineStatus"
import NameUser from "../../../../shared/components/names/NameUser"
import TextCoordinatorUser from "../../../../shared/components/text/TextCoordinatorUser"
import LiveMeetingUserRoleControls from "./LiveMeetingUserRoleControls"
import { getUserWithConfigById } from "../../../../shared/selectors/liveMeetingConfig/userConfig"
import { getCurrentUser } from "../../../../shared/selectors/user"
import LiveMeetingUserMicrophoneStatus from "./LiveMeetingUserMicrophoneStatus"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  userId: string;
}

const LiveMeetingUserGridItem: React.FC<IProps> = ({ userId }: IProps): JSX.Element => {
  const user = useSelector((state: IAppState) => getUserWithConfigById(state, { userId }))
  const currentUser = useSelector(getCurrentUser)
  const speakers = useSelector(getLiveMeetingConfig).speaking

  const subscriberOptions = (currentUser.id == userId) ? currentUserStreamSubscriberOptions : userStreamSubscriberOptions

  const border = speakers[user.id] ? "border-solid border-2 rounded border-ch-blue-500" : "border-solid border-2 rounded border-current"

  return (
    <li className="h-40 xl:h-56 mb-2 px-1 w-full overflow-hidden sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3">
      <div className={`relative bg-ch-gray-600 w-full h-full z-0 ${border}`}>
        <div className={"absolute w-full h-full z-10 "} >
          <div className="absolute p-2 left-0 top-0 flex">
            <LiveMeetingUserOnlineStatus userId={userId} />
            <LiveMeetingUserMicrophoneStatus userId={userId} />
          </div>
          <div className="absolute p-2 right-0 top-0">
            <LiveMeetingUserRoleControls userId={userId} isOverlay={true} />
          </div>
          <div className="absolute p-2 left-0 bottom-0 text-white">
            <h3 className="mr-1">
              <NameUser
                firstName={user.firstName}
                lastName={user.lastName}
                professionalTitle={user.professionalTitle}
              />
            </h3>
            <h4 className="text-sm font-thin uppercase">
              <TextCoordinatorUser userId={userId} />
            </h4>
          </div>
        </div >
        <div className="absolute w-full h-full">
          <WebRTCSubscriber userId={user.id} subscriberOptions={subscriberOptions} />
        </div>
      </div>
    </li>
  )
}

export default LiveMeetingUserGridItem
