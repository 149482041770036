import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../app/appTypes"
import NameUser from "../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import { MockStore } from "redux-mock-store"
import { IMeetingCase, MeetingCaseRecommendationStatus } from "../state/meetingCaseTypes"
import { getMeetingCaseById } from "../../../shared/selectors/meeting"
import { getCaseById, getLeadCareUserByCaseId } from "../../../shared/selectors/case"
import TextCaseType from "../../../shared/components/text/TextCaseType"
import TextLink from "../../../shared/components/text/TextLink"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../app/routes/Routes"
import TimeDefaultFormat from "../../../shared/components/time/TimeDefaultFormat"
import TargetDate from "../../case/components/details/TargetDate"
import { IconLock } from "../../../svg/icons"
import LockCaseButton from "../../case/components/header/LockCaseButton"
import LoadingSpinner from "../../../shared/components/ui/LoadingSpinner"
import { useSelector } from "react-redux"

interface IProps {
  store?: MockStore
  isCondensed?: boolean
  meetingCaseId: string
  hasLink?: boolean
  renderLockCase?: boolean
}

const MeetingCaseDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])

  const meetingCase: IMeetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, props))
  const caseData = useSelector((state: IAppState) => getCaseById(state, { caseId: meetingCase.case }))
  const leadCareUser = useSelector((state: IAppState) => getLeadCareUserByCaseId(state, { caseId: meetingCase.case }))

  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  const meetingCaseDetailsUrl = generatePath(RoutePaths.MEETING_CASE, {
    meetingCaseId: props.meetingCaseId
  })

  if (!caseData) {
    return (<LoadingSpinner />)
  }

  const renderPatientName = (): JSX.Element => {
    const patientNameText = (
      <NameUser
        firstName={caseData.firstName}
        lastName={caseData.lastName}
        title={caseData.title}
        gender={caseData.gender}
      />
    )
    const renderText = (): JSX.Element => {
      if (!props.hasLink) return patientNameText
      return (
        <TextLink href={meetingCaseDetailsUrl}>
          {patientNameText}
        </TextLink>
      )
    }
    const renderLockIcon = (): JSX.Element => {
      if (!caseData.locked) return null
      return (
        <div className="text-ch-purple-500 ml-2">
          <IconLock className="w-4 h-4" title={t("case:lockedIconTitle")} />
        </div>
      )
    }
    const renderRecommendationLabel = (): JSX.Element => {
      const status = meetingCase.recommendationStatus
      if (!status) return null

      if (status == MeetingCaseRecommendationStatus.RECOMMENDED)
        return (
          <div className="text-sm text-white px-1 rounded bg-ch-green-300 ml-2 flex content-center">
            {t("case:recommended")}
          </div>
        )
      if (status == MeetingCaseRecommendationStatus.DEFERRED)
        return (
          <div className="text-sm text-white px-1 rounded bg-ch-blue-500 ml-2 flex content-center">
            {t("case:deferred")}
          </div>
        )
      if (status == MeetingCaseRecommendationStatus.RECOMMENDATION_PENDING)
        return (
          <div className="text-sm text-white px-1 rounded bg-ch-yellow-500 ml-2 flex content-center">
            {t("case:recommendationPending")}
          </div>
        )
      return null
    }
    return (
      <li className="flex flex-row items-baseline">
        <h4 className="font-semibold">
          {renderText()}
        </h4>
        {renderLockIcon()}
        {renderRecommendationLabel()}

      </li>
    )
  }

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("case:dateOfBirth")}:{" "}
        <TimeDateOfBirth utcTime={caseData.dateOfBirth} />
      </li>
    )
  }

  const renderPatientHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {caseData.healthcareId}
      </li>
    )
  }

  const renderPatientCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {caseData.careProviderId}
      </li>
    )
  }

  const renderCaseType = (): JSX.Element => {
    return (
      <li>
        {t("case:type")}:{" "}
        <TextCaseType caseType={caseData.caseType} />
      </li>
    )
  }

  const renderLeadCare = (): JSX.Element => {
    return (
      <li>
        {t("case:leadCare")}:{" "}
        <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          isPending={leadCareUser.pending}
        />
      </li>
    )
  }

  const renderClassification = (): JSX.Element => {
    return (
      <li>
        {t("shared:classification")}: {caseData.classification}
      </li>
    )
  }

  const renderSourceName = (): JSX.Element => {
    return (
      <li>
        {t("case:source")}: {caseData.sourceName}
      </li>
    )
  }

  const renderSignedOffBy = (): JSX.Element => {
    if (!caseData.signedOffBy) return null
    return (
      <li>
        {t("case:signedOffBy")}: {caseData.signedOffBy}
      </li>
    )
  }

  const renderLastUpdated = (): JSX.Element => {
    return (
      <li>
        {t("case:lastUpdated")}: <TimeDefaultFormat utcTime={caseData.updatedAt} />
      </li>
    )
  }

  const renderCondensedDetails = (): JSX.Element => {
    return (
      <ul>
        {renderPatientName()}
        {renderDateOfBirth()}
        {renderPatientHealthcareId()}
        {renderPatientCareProviderId()}
      </ul>
    )
  }

  const renderFullDetails = (): JSX.Element => {
    return (
      <div className="flex w-full">
        <div className="flex-grow flex-1 lg:grid grid-cols-2 gap-3">
          <ul className="col-span-1">
            {renderPatientName()}
            {renderDateOfBirth()}
            {renderPatientHealthcareId()}
            {renderPatientCareProviderId()}
            {renderCaseType()}
          </ul>

          <ul className="col-span-1">
            {renderLeadCare()}
            {renderClassification()}
            {renderSourceName()}
            {renderLastUpdated()}
            <TargetDate caseId={caseData.id} />
            {renderSignedOffBy()}
          </ul>
        </div>
        {props.renderLockCase ? <LockCaseButton caseId={meetingCase.case} /> : null}
      </div>
    )
  }

  return props.isCondensed ? renderCondensedDetails() : renderFullDetails()
}

export default MeetingCaseDetails
