import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { IAppState } from "../../app/appTypes"
import PatientAmendments from "../../features/patient/components/amendments/PatientAmendments"
import PatientCases from "../../features/patient/components/cases/PatientCases"
import FurtherDetails from "../../features/patient/components/details/FurtherDetails"
import PatientHeader from "../../features/patient/components/header/PatientHeader"
import { fetchPatientAsync } from "../../features/patient/state/patientActions"
import { IHavePatientId } from "../../features/patient/state/patientTypes"
import { getPatientById } from "../../shared/selectors/patients"

const PatientPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { patientId } = useParams<IHavePatientId>()
  const patient = useSelector((state: IAppState) => getPatientById(state, { patientId }))
  const dispatch = useDispatch()
  const fetchPatient = (patientId: string) => dispatch(fetchPatientAsync(patientId))

  useEffect(() => {
    fetchPatient(patientId)
  }, [])

  if (!patient) return <h3 className="text-lg">{t("patient:messages:patientNotFound")}</h3>

  return (
    <div>
      <PatientHeader patientId={patientId} />
      <FurtherDetails patientId={patientId} />
      <PatientCases patientId={patientId} />
      <PatientAmendments patientId={patientId} />
    </div>
  )
}

export default PatientPage
