import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import ButtonLink from "../buttons/ButtonLink"
import { StyleVariants } from "../../state/sharedTypes"
import { ScrollActionTypes } from "../../../features/scroll/state/scrollTypes"
import { scrollUpdatePosition } from "../../../features/scroll/state/scrollActions"
import { ISearchQuery, ISetSearchQueryFunction } from "../../hooks/useSearch"

interface IPropsFromParent {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
  totalPages: number
  totalItems: number
  scrollActionType?: ScrollActionTypes
}

interface IPropsFromDispatch {
  scrollUpdatePosition: (type: ScrollActionTypes, position: number) => void
}

type Props = IPropsFromParent & IPropsFromDispatch

const defaultProps = {
  scrollActionType: ScrollActionTypes.UPDATE_MAIN_SCROLL_TO_POSITION
}

const MaxPageElements = 9
const MaxPageOffset = Math.floor(MaxPageElements / 2)

const Pagination = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  if (!props.totalItems || props.totalPages < 2) return null

  const onChangePage = (page: number) => {
    props.setSearchQuery({ page: page })

    if (props.scrollActionType) props.scrollUpdatePosition(props.scrollActionType, 0)
  }

  const renderFirstButton = (): JSX.Element => {
    if (props.searchQuery.page <= 1) return null
    return (
      <li>
        <ButtonLink
          className="uppercase font-semibold text-sm px-2"
          variant={StyleVariants.BLUE}
          action={() => onChangePage(1)}
          noPaddingX
        >
          {" << "} {t("shared:first")}
        </ButtonLink>
      </li>
    )
  }
  const renderLastButton = (): JSX.Element => {
    if (props.searchQuery.page >= props.totalPages) return null
    return (
      <li>
        <ButtonLink
          className="uppercase font-semibold text-sm px-2"
          variant={StyleVariants.BLUE}
          action={() => onChangePage(props.totalPages)}
          noPaddingX
        >
          {t("shared:last")}{" >> "}
        </ButtonLink>
      </li>
    )
  }


  const renderPreviousButton = (): JSX.Element => {
    if (props.searchQuery.page <= 1) return null
    return (
      <li>
        <ButtonLink
          className="uppercase font-semibold text-sm pl-2 pr-4"
          variant={StyleVariants.BLUE}
          action={() => onChangePage(props.searchQuery.page - 1)}
          noPaddingX
        >
          {" < "} {t("shared:previous")}
        </ButtonLink>
      </li>
    )
  }

  const renderNextButton = (): JSX.Element => {
    if (props.searchQuery.page >= props.totalPages) return null
    return (
      <li>
        <ButtonLink
          className="uppercase font-semibold text-sm pl-4 pr-2"
          variant={StyleVariants.BLUE}
          action={() => onChangePage(props.searchQuery.page + 1)}
          noPaddingX
        >
          {t("shared:next")}{" > "}
        </ButtonLink>
      </li>
    )
  }

  const renderPageLink = (pageNumber: number): JSX.Element => {
    if (props.searchQuery.page == pageNumber) return (
      <li key={pageNumber}>
        <ButtonLink
          className="uppercase font-semibold text-sm"
          variant={StyleVariants.GRAY}
          noHover
        >
          {pageNumber}
        </ButtonLink>
      </li>
    )

    return (
      <li key={pageNumber}>
        <ButtonLink
          className="uppercase font-semibold text-sm"
          variant={StyleVariants.BLUE}
          action={() => onChangePage(pageNumber)}
        >
          {pageNumber}
        </ButtonLink>
      </li>
    )
  }

  const elipsis = <li className="text-ch-blue-500">&hellip;</li>
  const pageNumbers: number[] = Array.from({ length: MaxPageElements }, (_, i) => props.searchQuery.page - MaxPageOffset + i)
    .filter((i) => i > 0 && i <= props.totalPages)

  return (
    <ul className="flex justify-center">
      {renderFirstButton()}
      {renderPreviousButton()}
      {!pageNumbers.includes(1) ? elipsis : null}

      {pageNumbers.map((pageNumber) => {
        return renderPageLink(pageNumber)
      })}
      {!pageNumbers.includes(props.totalPages) ? elipsis : null}
      {renderNextButton()}
      {renderLastButton()}
    </ul>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => {
  return {
    scrollUpdatePosition: (type: ScrollActionTypes, position: number) => dispatch(scrollUpdatePosition(type, position))
  }
}

Pagination.defaultProps = defaultProps

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(Pagination)
