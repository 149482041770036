import React, { useContext } from "react"
import MenuButton from "./MenuButton"
import SignOutButton from "../../../shared/components/buttons/SignOutButton"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import TenantLogo from "../../../shared/components/images/TenantLogo"
import SwitchTenantButton from "../../tenant/components/switchTenant/SwitchTenantButton"
import HelpButton from "../../../shared/components/buttons/HelpButton"

const Header: React.FC = (): JSX.Element => {
  const { tenantName } = useContext(
    TenantConfigContext
  )

  const renderTenantName = (): JSX.Element => {
    if (!tenantName) return null

    return (
      <h1 className="text-ch-gray-600 uppercase mr-2">
        {tenantName}
      </h1>
    )
  }

  return (
    <div className="px-5 pt-2 pb-2 bg-white shadow relative z-40">
      <div className="flex justify-between items-center">
        <div className="flex items-center p-1 justify-center">
          <TenantLogo
            className="h-10 mr-2"
          />
          {renderTenantName()}
          <SwitchTenantButton />
        </div>
        <div className="block md:hidden">
          <MenuButton />
        </div>
        <div className="hidden md:flex space-x-3">
          <HelpButton />
          <SignOutButton />
        </div>
      </div>
    </div>
  )
}

export default Header
