import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../app/appTypes"
import { ScrollActionTypes } from "../../../features/scroll/state/scrollTypes"
import { IconClose } from "../../../svg/icons"
import { getScrollUI } from "../../selectors/ui"
import { scrollUpdatePosition } from "../../../features/scroll/state/scrollActions"

interface Props {
  handleClose?: () => void
  children: React.ReactNode
}

const ModalContainer: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const mainScrollToPosition = useSelector((state: IAppState) => getScrollUI(state).mainScrollToPosition)
  const dispatch = useDispatch()
  const resetScrollPosition = () => dispatch(scrollUpdatePosition(ScrollActionTypes.UPDATE_MAIN_SCROLL_TO_POSITION, null))
  const scrollElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollElementRef.current.scrollTo({
      top: mainScrollToPosition,
      behavior: "smooth"
    })
    resetScrollPosition()
  }, [mainScrollToPosition])

  const renderCloseButton = () => {
    if (!props.handleClose) { return null }
    return (
      <a onClick={props.handleClose} className="text-ch-blue-500 hover:text-ch-blue-600 self-end mb-3 lg:mb-5 z-50 cursor-pointer">
        <IconClose className="fill-current w-6 h-6" title={t("shared:close")} />
      </a>
    )
  }

  const renderContent = (): JSX.Element => {
    return (
      <div className="flex w-full h-full flex-col relative">
        {renderCloseButton()}

        <div ref={scrollElementRef} className="w-full h-full flex-1 overflow-y-auto overflow-x-hidden mb-5 pl-3 pr-3 lg:pl-14 lg:pr-14">
          {props.children}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default ModalContainer
