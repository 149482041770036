import _ from "lodash"
import { AnyAction, combineReducers, Reducer } from "redux"
import { INormalizedEntities } from "../../../../app/appTypes"
import { IPatient, PatientActionTypes } from "../../../patient/state/patientTypes"
import { PatientNewActionTypes } from "../../../patientNew/state/patientNewTypes"
import { PatientsActionTypes } from "../patientsTypes"

type TByIdState = { [id: string]: IPatient }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []


export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch(action.type) {
    case PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_SUCCESS:
    case PatientNewActionTypes.CHECK_PATIENT_ASYNC_FOUND:
      return _.merge({}, state, action.payload.entities.patients)

    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.patients)

    case PatientActionTypes.FETCH_PATIENT_ASYNC_SUCCESS:
      return (
        _.merge({}, state, action.payload.entities.patients)
      )

    default:
      return {...state}
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch(action.type) {
    case PatientsActionTypes.FETCH_ALL_PATIENTS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.patients)))
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)
    default:
      return state
  }
}

export const patientsReducer: Reducer<INormalizedEntities<IPatient>> = combineReducers({
  byId,
  allIds
})
