import React from "react"
import { differenceInYears } from "date-fns"
import { formatDateString } from "../../../shared/helpers/timeHelpers"
import { formatInTimeZone } from "date-fns-tz"

interface IProps {
  utcTime: string
  className?: string
}

const TimeDateOfBirth: React.FC<IProps> = ({ utcTime, className }): JSX.Element => {
  if (!utcTime) return null
  const dob = new Date(utcTime)
  const formatTime = formatInTimeZone(dob, "UTC", formatDateString)
  const age = differenceInYears(new Date(), dob)

  return (
    <span className={className}>
      {formatTime} ({age})
    </span>
  )

}

export default TimeDateOfBirth
