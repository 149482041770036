import React from "react"
import { getUserByHasUserId } from "../../../shared/selectors/user"
import { IAppState } from "../../../app/appTypes"
import { useSelector, useDispatch } from "react-redux"
import UserDetailsCard from "../../../shared/components/user/UserDetailsCard"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../shared/components/layout/detailsCard/DetailsCardRight"
import Button from "../../../shared/components/buttons/Button"
import DetailsCardButtons from "../../../shared/components/layout/detailsCard/DetailsCardButtons"
import LoadingSpinner from "../../../shared/components/ui/LoadingSpinner"
import { useTranslation } from "react-i18next"
import { removePathwayUserAction } from "../pathwayActions"
import { getPathway } from "../../../shared/selectors/pathway"
import { StyleVariants } from "../../../shared/state/sharedTypes"

type Props = {
  pathwayId: string
  userId: string
}

const PathwayUserListItem: React.FC<Props> = (props: Props) => {
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, props))
  const pathway = useSelector(getPathway(props))
  const { t } = useTranslation(["shared"])
  const dispatch = useDispatch()

  const handleRemove = () => {
    dispatch(removePathwayUserAction(props.pathwayId, props.userId))
  }
  const renderButton = () => {
    if (props.userId == pathway.createdBy.id) return null
    return <Button key="1" action={handleRemove} variant={StyleVariants.BLUE_ALT} > {t("shared:remove")}</Button >
  }

  if (!user) { return <LoadingSpinner /> }
  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <UserDetailsCard user={user} />
        </DetailsCardInner>
        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[renderButton()]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )

}

export default PathwayUserListItem
