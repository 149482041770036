import { createSelector } from "reselect"
import { IAppState } from "../../../../app/appTypes"
import _ from "lodash"
import { AssetTypes, IAsset } from "../../../../features/assets/state/assetsTypes"
import { ICase } from "../../../../features/case/state/caseTypes"
import { IMeetingCase } from "../../../../features/meetingCase/state/meetingCaseTypes"
import { getAllAssets } from "../../asset"
import { getAllCases } from "../../case"
import { getAllMeetingCases } from "../../meeting"

export const getSelectedMeetingCaseId = (state: IAppState): string =>
  state.liveMeetingConfig.selectedMeetingCaseId

export const getSelectedMeetingCase = createSelector(
  getAllMeetingCases,
  getSelectedMeetingCaseId,
  (allMeetingCases, selectedMeetingCaseId): IMeetingCase => {
    return allMeetingCases[selectedMeetingCaseId]
  }
)

export const getSelectedMeetingCaseDetails = createSelector(
  getAllCases,
  getSelectedMeetingCase,
  (allCases, selectedMeetingCase): ICase => {
    if (!selectedMeetingCase) return null
    return allCases[selectedMeetingCase.case]
  }
)

export const getSelectedMeetingCaseAssetIds = createSelector(
  getSelectedMeetingCaseDetails,
  (selectedMeetingCaseDetails): string[] => {
    selectedMeetingCaseDetails as ICase
    if (!selectedMeetingCaseDetails) return []
    return selectedMeetingCaseDetails.assets
  }
)

export const getSelectedMeetingCaseCommentAssetIds = createSelector(
  getAllAssets,
  getSelectedMeetingCase,
  (allAssetsById, selectedMeetingCase): string[] => {
    const selectedMeetingCaseAssetIds = _.map(selectedMeetingCase.meetingCaseAssets,
      (meetingCaseAsset) => meetingCaseAsset.caseAssetId)
    return _.reject(selectedMeetingCaseAssetIds, (assetId) => {
      const asset: IAsset = allAssetsById[assetId]
      return asset?.assetType != AssetTypes.Comment
    })
  }
)
