import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { WebRTCActionTypes, TWebRTCDeviceByIdState, IWebRTCDeviceState, TWebRTCDeviceAllIdsState } from "../webRTCTypes"

export const selectedIdInitialState: string = null
export const byIdInitialState: TWebRTCDeviceByIdState = {}
export const allIdsInitialState: TWebRTCDeviceAllIdsState = []

export const selectedId: Reducer<string> = (state: string = selectedIdInitialState, action: AnyAction): string => {
  switch (action.type) {
    case WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_SUCCESS:
      return action.payload.device.deviceId
    case WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_SUCCESS:
      return action.payload.deviceId
    default:
      return state
  }
}

export const byId: Reducer<TWebRTCDeviceByIdState> = (state: TWebRTCDeviceByIdState = byIdInitialState, action: AnyAction): TWebRTCDeviceByIdState => {
  switch(action.type) {
    case WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_SUCCESS:
      return _.keyBy(action.payload.devices, "deviceId")

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TWebRTCDeviceAllIdsState> = (state: TWebRTCDeviceAllIdsState = allIdsInitialState, action: AnyAction): TWebRTCDeviceAllIdsState => {
  switch (action.type) {
    case WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_SUCCESS:
      return _.map(action.payload.devices, "deviceId")

    default:
      return state
  }
}

export const webRTCAudioDeviceReducer: Reducer<IWebRTCDeviceState> = combineReducers({
  selectedId,
  byId,
  allIds
})
