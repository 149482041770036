import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconNa({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.13 22.869A11.13 11.13 0 015.88 8.618l15.5 15.499a11.129 11.129 0 01-14.25-1.248zM22.87 7.13a11.13 11.13 0 011.25 14.253l-15.5-15.5a11.13 11.13 0 0114.253 1.25l-.003-.002zM14.999 0C6.717 0 0 6.716 0 15c0 8.285 6.717 15 15 15 8.285 0 15-6.715 15-15 0-8.284-6.715-15-15-15z"
      />
    </svg>
  );
}

export default SvgIconNa;
