import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconOptions({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 37"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M17.506 36.234c9.665 0 17.5-7.996 17.5-17.859 0-9.863-7.835-17.86-17.5-17.86S.006 8.513.006 18.376s7.835 17.86 17.5 17.86zm-7.5-15.308a2.475 2.475 0 01-1.768-.747 2.578 2.578 0 01-.732-1.804c0-.677.263-1.326.732-1.804a2.475 2.475 0 011.768-.747c.663 0 1.299.268 1.768.747.469.478.732 1.127.732 1.804s-.264 1.326-.732 1.804a2.475 2.475 0 01-1.768.747zm10-2.551a2.58 2.58 0 01-.732 1.804 2.475 2.475 0 01-1.768.747 2.475 2.475 0 01-1.768-.747 2.579 2.579 0 01-.732-1.804c0-.677.263-1.326.732-1.804a2.475 2.475 0 011.768-.747c.663 0 1.299.268 1.768.747a2.58 2.58 0 01.732 1.804zm5 2.551a2.475 2.475 0 01-1.768-.747 2.579 2.579 0 01-.732-1.804c0-.677.263-1.326.732-1.804a2.475 2.475 0 011.768-.747c.663 0 1.299.268 1.768.747.468.478.732 1.127.732 1.804s-.264 1.326-.732 1.804a2.475 2.475 0 01-1.768.747z"
      />
    </svg>
  );
}

export default SvgIconOptions;
