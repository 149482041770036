import React from "react"
import { useSelector } from "react-redux"
import { IPatientEditData } from "../../state/patientEditTypes"
import { IPatient } from "../../../patient/state/patientTypes"
import { IAppState } from "../../../../app/appTypes"
import { getPatientById } from "../../../../shared/selectors/patients"
import AmendmentText from "./AmendmentText"

interface IPropsFromParent {
  data: IPatientEditData
  patientId: string
  className?: string
}

type Props = IPropsFromParent

const AmendmentList: React.FC<Props> = (props: Props): JSX.Element => {
  const patient: IPatient = useSelector((state: IAppState) => getPatientById(state, props))

  const renderAmendment = (attributeName: string): JSX.Element => {
    if(
      !props.data[attributeName] ||
      patient[attributeName] == props.data[attributeName]
    ) return null

    return(
      <li className="mb-2" key={`patient-amendment-${attributeName}`}>
        <div className="mb-2">
          <AmendmentText data={props.data} patient={patient} attributeName={attributeName} type="original" />
        </div>
        <div>
          <AmendmentText data={props.data} patient={patient} attributeName={attributeName} type="amended" />
        </div>
      </li>
    )
  }

  return (
    <ul className={props.className}>
      {Object.keys(patient).map((attributeName: string) => {
        return renderAmendment(attributeName)
      })}
    </ul>
  )
}

export default AmendmentList
