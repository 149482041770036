import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconMenu({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 12"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M16.68 4.8H1.32C.658 4.8.6 5.336.6 6c0 .664.058 1.2.72 1.2h15.36c.662 0 .72-.536.72-1.2 0-.664-.058-1.2-.72-1.2zm0 4.8H1.32c-.662 0-.72.536-.72 1.2 0 .664.058 1.2.72 1.2h15.36c.662 0 .72-.536.72-1.2 0-.664-.058-1.2-.72-1.2zM1.32 2.4h15.36c.662 0 .72-.536.72-1.2 0-.664-.058-1.2-.72-1.2H1.32C.658 0 .6.536.6 1.2c0 .664.058 1.2.72 1.2z"
        />
        <path d="M-3-6h24v24H-3z" />
      </g>
    </svg>
  );
}

export default SvgIconMenu;
