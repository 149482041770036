import _ from "lodash"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import UserNewForm from "../../features/userNew/components/UserNewForm"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { IAppState } from "../../app/appTypes"
import { getCurrentUser } from "../../shared/selectors/user"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"

const UserNewPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const currentUser = useSelector((state: IAppState) => getCurrentUser(state))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpecialtiesAsync())
    dispatch(fetchAllUsersAsync())
  }, [])

  const renderForm = (): JSX.Element => {
    if(!currentUser.abilities?.user.canCreate) return <h3 className="text-lg">{ t("shared:notPermitted") }</h3>
    return <UserNewForm />
  }

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:users"),
            href: RoutePaths.USERS
          },
          {
            pageTitle: t("user:addANewUser")
          }
        ]}
      />
      { renderForm() }
    </div>
  )
}

export default UserNewPage
