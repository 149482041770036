import classNames from "classnames"
import _ from "lodash"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import { ErrorBoundary } from "../../../../app/ErrorBoundary"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import Card from "../../../../shared/components/layout/Card"
import { getCaseById, getUserIdsForCaseMeetings } from "../../../../shared/selectors/case"
import CaseMeetingUserListItem from "./CaseMeetingUserListItem"

interface IProps {
  store?: MockStore
  caseId: string
}

const CaseMeetingUsers: React.FC<IProps> = (props): JSX.Element => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const userIds = useSelector((state: IAppState) => getUserIdsForCaseMeetings(state, props))

  const [isVisibleList, toggleList] = useState(false)
  const { t } = useTranslation(["case"])

  const caseMeetingOnlyUserIds = userIds.filter(id => !theCase.members.includes(id))

  if (!theCase || !theCase.members) return null
  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("case:otherParticipants")} ({caseMeetingOnlyUserIds.length})
          </h3>
        </div>

        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans
              i18nKey="case:otherParticipantsIntro"
              components={{
                strong: <strong />
              }}
            />
          </p>
          <ButtonExpandCollapse
            isExpanded={isVisibleList}
            action={() => toggleList(!isVisibleList)}
            expandText={t("case:showUsers")}
            collapseText={t("case:hideUsers")} />
        </div>

        <ul className={classNames({ hidden: !isVisibleList })}>
          {_.map(caseMeetingOnlyUserIds, (userId) => {
            return (
              <ErrorBoundary key={userId}>
                <CaseMeetingUserListItem key={userId} userId={userId} caseId={props.caseId} meetingIds={theCase.meetings} />
              </ErrorBoundary>
            )
          })}
        </ul>
      </Card>
    </div>
  )
}

export default CaseMeetingUsers
