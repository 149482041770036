import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"
import CasePathwayHeader from "../../../../casePathways/components/CasePathwayHeader"
import EditCasePathwayForm from "../../../../casePathways/components/EditCasePathwayForm"
import { updateCasePathwayAction } from "../../../../casePathways/state/casePathwayActions"
import { ICasePathway, ICasePathwayEditData } from "../../../../casePathways/state/casePathwayTypes"

interface Props {
  casePathwayId: string
  closeModal: () => void
  isOpen: boolean
}

const EditCasePathwayModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const dispatch = useDispatch()
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))

  const submitHandler = (values: ICasePathwayEditData) => {
    const data: ICasePathwayEditData = {
      name: values.name,
      description: values.description
    }
    dispatch(updateCasePathwayAction(
      casePathway.caseId,
      props.casePathwayId,
      data
    ))
    props.closeModal()
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("case:casePathways:editCasePathway")}
        </h2>

        <CasePathwayHeader casePathwayId={props.casePathwayId} />

        <EditCasePathwayForm
          submitHandler={submitHandler}
          cancelHandler={props.closeModal}
          casePathwayId={props.casePathwayId}
        />
      </ModalContainer>
    </Modal >
  )
}

export default EditCasePathwayModal
