import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconMessageRead({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 27 19"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        stroke="currentColor"
        strokeOpacity={0.6}
        strokeWidth={3}
        d="M1.518 8.734l7.347 7.735L18.66 1m6.131.791L14.666 18.11"
      />
    </svg>
  );
}

export default SvgIconMessageRead;
