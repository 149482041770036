import _ from "lodash"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../shared/state/sharedTypes"
import ModalContainer from "../../../shared/components/modals/ModalContainer"
import { IAppState } from "../../../app/appTypes"
import { PathwaysOrderByOptions } from "../../pathways/state/pathwaysTypes"
import { getAddPathwayToCaseUI } from "../../../shared/selectors/ui"
import useSearch, { ISearchQuery } from "../../../shared/hooks/useSearch"
import CaseDetails from "../../case/components/details/CaseDetails"
import { ScrollActionTypes } from "../../scroll/state/scrollTypes"
import Pagination from "../../../shared/components/search/Pagination"
import OrderBy from "../../../shared/components/search/OrderBy"
import SearchInput from "../../../shared/components/search/SearchInput"
import ResultsPerPage from "../../../shared/components/search/ResultsPerPage"
import { IAddPathwayToCaseUIState } from "../state/addPathwayToCaseTypes"
import AddPathwayToCaseList from "./AddPathwayToCaseList"
import DetailsCard from "../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../shared/components/icons/IconCircleContainer"
import { IconFolder } from "../../../svg/icons"
import { fetchPathwaysAddableToCaseAsync } from "../state/addPathwayToCaseActions"
import PathwayFilter from "../../pathways/components/PathwayFilter"

interface Props {
  caseId: string
  handleClose: () => void
  isOpen: boolean
}

const AddPathwayToCaseModal = (props: Props): JSX.Element  => {
  if(!props.isOpen) return null

  const { t } = useTranslation(["shared", "pathway"])
  const UI: IAddPathwayToCaseUIState = useSelector((state: IAppState) => getAddPathwayToCaseUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchPathwaysAddableToCaseAsync)

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.handleClose} style={defaultModalStyle}>
      <ModalContainer handleClose={props.handleClose}>
        <h2 className="text-lg mb-3">
          {t("pathway:addPathwayToCase:title")}
        </h2>

        <DetailsCard>
          <DetailsCardInner>
            <IconCircleContainer className="sm:mr-4">
              <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
            </IconCircleContainer>
            <CaseDetails caseId={props.caseId} />
          </DetailsCardInner>
        </DetailsCard>

        <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
          <div className="row-start-2 md:row-start-1 self-center">
            <Trans
              i18nKey="pathway:index:searchBy"
              components={{
                strong: <strong />
              }}
            />
          </div>
          <div className="row-end-2 md:row-start-1 flex justify-end">
            <ResultsPerPage
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>

        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          buttonText={t("pathway:index:buttons:searchPathways")}
        />

        <h2 className="text-lg mb-2 text-center md:text-left">
          {UI.totalPathwaysCount} {t("shared:pathways")}
        </h2>

        <div className="grid grid-flow-row md:grid-flow-col">
          <div>
            <PathwayFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
          <div className="flex justify-end">
            <OrderBy
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              orderByOptions={PathwaysOrderByOptions}
              useTranslation={"pathway:index:orderBy"}
            />
          </div>
        </div>

        <AddPathwayToCaseList
          caseId={props.caseId}
          closeModal={props.handleClose}
        />

        <Pagination
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          totalItems={UI.totalPathwaysCount}
          totalPages={UI.totalPages}
          scrollActionType={ScrollActionTypes.UPDATE_MODAL_SCROLL_TO_POSITION}
        />

      </ModalContainer>
    </Modal>
  )
}

export default AddPathwayToCaseModal
