import { Form, Formik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import Button from "../../../../../shared/components/buttons/Button"
import DenotesRequiredMessage from "../../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../../shared/components/forms/FieldError"
import InputField from "../../../../../shared/components/forms/InputField"
import Label from "../../../../../shared/components/forms/Label"
import Buttons from "../../../../../shared/components/layout/Buttons"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IconTask } from "../../../../../svg/icons"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../meetingCase/state/meetingCaseTypes"
import { createCaseTaskAsync } from "../../../../tasks/state/tasksActions"
import { ITaskNewData } from "../../../../tasks/state/tasksTypes"
import { caseTaskNewValidation } from "../../../../tasks/state/validation/caseTaskNewValidation"
import MentionField from "../../../../../shared/components/forms/MentionField"
import SelectField from "../../../../../shared/components/forms/SelectField"
import { TSelectFieldUserOptions } from "../../../../../shared/components/forms/StandardSelectField"
import DatePickerField from "../../../../../shared/components/datePicker/DatePickerField"
import history from "../../../../../app/routes/history"
import { buildUserOptions, filterOptionsBySearchValue } from "../../../../../shared/components/label/UserOption"
import { getCaseMemberUsers } from "../../../../../shared/selectors/case"
import { IAppState } from "../../../../../app/appTypes"

interface IPropsFromDispatch {
  createTask: (caseId: string, task: ITaskNewData) => void
}

interface IPropsFromParent {
  caseId: string
  closeAction: () => void
  casePathwayId?: string
  originTab: string
}

type Props = IPropsFromDispatch & IPropsFromParent

const CaseTaskNewForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "case", "task"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const [assigneeOptions, setAssigneeOptions] = React.useState([])
  const mentionableUsers = useSelector((state: IAppState) => getCaseMemberUsers(state, { caseId: props.caseId }))

  const initialValues: ITaskNewData = {
    action: "",
    description: "",
    dueDate: undefined,
    assigneeIds: [],
    meetingCaseId: meetingCaseId,
    casePathwayId: props.casePathwayId
  }

  useEffect(() => {
    if (!mentionableUsers) return
    const options: TSelectFieldUserOptions = buildUserOptions(Object.values(mentionableUsers))
    setAssigneeOptions(options)
  }, [mentionableUsers])

  return (
    <div>
      <h4 className="flex mb-2">
        <IconTask className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:addNewTask")} />
        {t("case:addNewTask")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={caseTaskNewValidation}
        onSubmit={(values, { setSubmitting }) => {
          props.createTask(props.caseId, values)
          setSubmitting(false)
          props.closeAction()
          history.replace(props.originTab)
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />
              <div className="mb-2">
                <Label name="action" required={true}>
                  {t("task:action")}
                </Label>
                <InputField
                  type="text"
                  name="action"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.action}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.action as string} isVisible={(errors.action && touched.action) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="description" required={true}>
                  {t("task:description")}
                </Label>
                <MentionField
                  name="description"
                  setValue={(description) => setFieldValue("description", description)}
                  onBlur={handleBlur}
                  users={null}
                  value={values.description}
                />
                <FieldError errorMessage={errors.description as string} isVisible={(errors.description && touched.description) as boolean} />
              </div>

              <div className="mb-2">
                <Label name="assigneeIds">
                  {t("task:assignees")}
                </Label>
                <SelectField
                  name="assigneeIds"
                  options={assigneeOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  filterOption={filterOptionsBySearchValue}
                />
                <FieldError errorMessage={errors.assigneeIds as string} isVisible={(errors.assigneeIds && touched.assigneeIds) as boolean} />
              </div>

              <div className="mb-5">
                <Label name="dueDate">
                  {t("task:dueDate")}
                </Label>
                <DatePickerField name="dueDate" />
                <FieldError
                  errorMessage={errors.dueDate as string}
                  isVisible={(errors.dueDate && touched.dueDate) as boolean}
                />
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("case:addTask")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const mapDispatchToProps = (dispatch): IPropsFromDispatch => {
  return {
    createTask: (caseId: string, task: ITaskNewData) => dispatch(createCaseTaskAsync(caseId, task))
  }
}

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(CaseTaskNewForm)
