import { normalize } from "normalizr"
import { takeLeading, put, call, Effect } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { auditEventsSchema } from "../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { AuditEventsActionTypes } from "./auditEventTypes"
import humps from "humps"
import queryString from "query-string"

export function* fetchAuditEventsAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/audit_events?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data, [auditEventsSchema])

    yield put({
      type: AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        entityId: action.payload.entityId
      }
    })
  } catch (error) {
    yield put({
      type: AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchAuditEventsAsync(): Generator {
  yield takeLeading(AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_PENDING, fetchAuditEventsAsync)
}
