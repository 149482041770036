import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconHandshake({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 16"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M16.98.5h-3.355a1.25 1.25 0 00-.844.328l-3.84 3.516-.011.015a1.554 1.554 0 00-.082 2.188c.496.543 1.539.687 2.191.105.004-.004.012-.004.016-.007l3.12-2.86a.624.624 0 11.844.922L14 5.641l5.688 4.617c.113.094.214.195.308.3V3L17.863.867A1.241 1.241 0 0016.98.5zm4.27 2.508v8.746c0 .691.559 1.25 1.25 1.25H25V3.008h-3.75zm1.875 8.746a.627.627 0 01-.625-.625c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zM0 13h2.5a1.25 1.25 0 001.25-1.25V3.008H0V13zm1.875-2.496c.344 0 .625.281.625.625a.627.627 0 01-.625.625.624.624 0 110-1.25zm17.027.726L13.07 6.496 11.898 7.57a2.816 2.816 0 01-3.972-.172 2.814 2.814 0 01.172-3.972L11.293.5H8.02c-.332 0-.649.133-.883.367L5 3v8.746h.715l3.535 3.2a2.5 2.5 0 003.516-.364l.007-.008.7.606c.62.508 1.539.41 2.043-.211l1.226-1.508.211.172a1.248 1.248 0 001.758-.184l.371-.457a1.252 1.252 0 00-.18-1.762z"
        />
        <path d="M0-2h25v20H0z" />
      </g>
    </svg>
  );
}

export default SvgIconHandshake;
