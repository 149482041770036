import { AuditEventsActionTypes, IFetchAuditEventsAsyncAction } from "./auditEventTypes"

export const fetchAuditEventsAsync = (entityType: string, entityId: string): IFetchAuditEventsAsyncAction => {
  return {
    type: AuditEventsActionTypes.FETCH_AUDIT_EVENTS_ASYNC_PENDING,
    payload: {
      entityType,
      entityId
    }
  }
}
