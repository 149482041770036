import React from "react"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import { isAfter, addDays, isPast } from "date-fns"
import { IconAlert, IconTick } from "../../../../svg/icons"
import classNames from "classnames"
import TimeDateOnly from "../../../../shared/components/time/TimeDateOnly"
import { useSelector } from "react-redux"

interface IProps {
  caseId: string
}

const TargetDate: React.FC<IProps> = (props: IProps): JSX.Element => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const { t } = useTranslation(["case"])

  const targetDate = new Date(theCase.targetDate)
  const today = new Date
  const targetDateIsDue = isAfter(addDays(today, 3), targetDate)
  const targetDateIsOverdue = isPast(targetDate)

  const renderTargetDateFulfilled = (): JSX.Element => {
    if (!theCase.targetDateFulfilledOn) return null

    return (
      <li>
        {t("case:targetDateFulfilledOn")}: <TimeDateOnly utcTime={theCase.targetDateFulfilledOn} /> <IconTick className="w-4 h-4 text-ch-green-300 inline-block -mt-1" />
      </li>
    )
  }

  const renderTargetDateAlertIcon = (): JSX.Element => {
    if (!targetDateIsDue && !targetDateIsOverdue) return null
    const iconClassNames = classNames(
      "w-5 h-5 inline-block -mt-2",
      {
        "text-ch-blue-500": (targetDateIsDue && !targetDateIsOverdue),
        "text-ch-orange-500": targetDateIsOverdue
      }
    )
    return (
      <IconAlert className={iconClassNames} />
    )
  }

  if (!theCase) return null
  if (!theCase.targetDate) return null

  if (theCase.targetDateFulfilledOn) return renderTargetDateFulfilled()

  return (
    <li>
      {t("case:targetDate")}: <TimeDateOnly className={classNames({ "font-semibold": targetDateIsOverdue })} utcTime={theCase.targetDate} /> {renderTargetDateAlertIcon()}
    </li>
  )
}

export default TargetDate
