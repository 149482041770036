export default interface ICasesUIState {
  totalCasesCount: number
  totalPages: number
  resultsPerPage: number
  searchTerm: string
  orderBy: string
  appliedFilters: string[]
}

export enum CasesOrderByOptions {
  UpdatedDesc = "updated_desc",
  UpdatedAsc = "updated_asc",
  NameAsc = "name_asc",
  NameDesc = "name_desc",
  RecommendationDeadline = "recommendation_deadline_asc",
  TargetDate = "target_date_asc"
}
