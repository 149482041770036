import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RoutePaths } from "../../app/routes/Routes"
import history from "../../app/routes/history"
import SetPasswordForm from "../../features/account/components/SetPasswordForm"
import { getUserSession } from "../../shared/selectors/user"

const SetPasswordPage: React.FC = (): JSX.Element => {
  const [token, setToken] = useState(null)
  const apiToken = useSelector(getUserSession).apiToken

  useEffect(() => {
    if (apiToken) {
      history.push(RoutePaths.MEETINGS)
    }
  }, [apiToken])

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const token = params.get("token")
    if (!token) history.push(RoutePaths.SIGN_IN)
    setToken(token)
  }, [])

  return (
    <div>
      <SetPasswordForm token={token} />
    </div>
  )
}

export default SetPasswordPage
