import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import classNames from "classnames"
import { IAppState } from "../../../app/appTypes"
import OT from "@opentok/client"
import { subscribeToWebRTCStreamAction } from "../state/webRTCActions"
import { getWebRTCScreenShareStream, getWebRTCSession, getWebRTCStreamByUserId } from "../../../shared/selectors/webRTC"
import { getStreamUserId } from "../../../apis/webRTC/webRTC"
import { getCurrentUserId } from "../../../shared/selectors/user"

interface IProps {
  store?: MockStore
  subscriberOptions: OT.SubscriberProperties
  userId: string
  isScreenShareStream?: boolean
}

const WebRTCSubscriber: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [subscribedStream, setSubscribedStream] = useState<OT.Stream>(null)
  const containerRef = React.useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const currentUserId = useSelector(getCurrentUserId)
  const session = useSelector(getWebRTCSession)
  const stream = props.isScreenShareStream
    ? useSelector(getWebRTCScreenShareStream)
    : useSelector((state: IAppState) => getWebRTCStreamByUserId(state, props))

  useEffect(() => {
    // When session is connected and the provided stream is set, subscribe to it
    // This will show the user's webcam stream or screenshare in the browser
    if (!stream || !session?.connection || (subscribedStream && subscribedStream.streamId == stream.streamId)) return

    dispatch(subscribeToWebRTCStreamAction(session, stream, containerRef, props.subscriberOptions))
    // we record the last stream that we subscribed to. If it changes, we will resubscribe
    setSubscribedStream(stream)
  }, [stream, session])

  const streamUserId = getStreamUserId(subscribedStream)
  // tailwind 2 doesn't support negative scale values, so using inline style for this
  // if you read this and we have updated to tailwind 3, please put this in the classNames
  // (something like "-scale-x-100": streamUserId == currentUserId)
  const style = {}
  if (streamUserId == currentUserId)
    style["transform"] = "scaleX(-1)"

  if (!stream) return null

  return (
    <div
      ref={containerRef}
      style={style}
      className={
        classNames("h-full", {
          "hidden": !stream.hasVideo
        })
      }></div >
  )
}

export default WebRTCSubscriber
