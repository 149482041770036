// State
export interface IScrollUIState {
  mainScrollToPosition: number
  modalScrollToPosition: number
}

// Actions
export interface IScrollUpdatePosition {
  type: string
  payload: number
}

// Action Types
export enum ScrollActionTypes {
  UPDATE_MAIN_SCROLL_TO_POSITION = "@@scroll/UPDATE_MAIN_SCROLL_TO_POSITION",
  UPDATE_MODAL_SCROLL_TO_POSITION = "@@scroll/UPDATE_MODAL_SCROLL_TO_POSITION",
}
