const { setVonageMetadata } = require('@vonage/media-processor');
const { createVonageMediaProcessorConnector, setBackgroundOptions } = require('@vonage/ml-transformers');

// This is copy pasta from @vonage/media-processor, basically.  The only
// difference is their's is an async call, whereas this one is synchronous.
const isSupported = () =>
  typeof MediaStreamTrackProcessor !== 'undefined' && typeof MediaStreamTrackGenerator !== 'undefined';

// Vonage's low-level machine-learning-based media processor libraries
const ML = {
  isSupported,
  setMetadata: setVonageMetadata,
  createConnector: createVonageMediaProcessorConnector,
  // Allows users to change background options in real-time, without having to
  // create destroy an MP connector
  setBackgroundOptions,
};

module.exports = ML;
