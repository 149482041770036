import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconPathway, IconTask } from "../../../../svg/icons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { getTaskById } from "../../../../shared/selectors/task"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import TaskDetails from "./TaskDetails"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { CaseAssetTabNames } from "../../../case/components/activityPanel/CaseActivityPanel"

interface IProps {
  taskId: string
}

const TaskListItem: React.FC<IProps> = (props: IProps) => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  const { t } = useTranslation(["shared", "task"])
  if (!task) return null
  const Icon = task.casePathwayId ? IconPathway : IconTask

  const caseDetailsUrl = generatePath(RoutePaths.CASE, {
    caseId: task.caseId,
    currentTab: CaseAssetTabNames.TASKS
  })

  return (
    <li>
      <DetailsCard isDark={task.isComplete}>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <Icon className="fill-current w-6 h-6" title={t("shared:task")} />
          </IconCircleContainer>
          <TaskDetails taskId={task.id} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons buttons={[
            <Button
              href={caseDetailsUrl}
              variant={StyleVariants.BLUE}
              key="1"
            >
              {t("shared:viewDetails")}
            </Button>
          ]} />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default TaskListItem
