import React from "react"
import { IAppState } from "../../app/appTypes"
import PageTitle from "../../shared/components/ui/PageTitle"
import { RoutePaths } from "../../app/routes/Routes"
import { getTeamsUI } from "../../shared/selectors/ui"
import { useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import SearchInput from "../../shared/components/search/SearchInput"
import TeamList from "../../features/teams/components/list/TeamList"
import Pagination from "../../shared/components/search/Pagination"
import OrderBy from "../../shared/components/search/OrderBy"
import TeamFilter from "../../features/teams/components/search/TeamFilter"
import { fetchTeamsAsync } from "../../features/teams/state/teamsActions"
import ITeamsUIState, {
  TeamsOrderByOptions
} from "../../features/teams/state/teamsTypes"
import { getCurrentUser } from "../../shared/selectors/user"
import { IUser } from "../../features/user/state/userTypes"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"

const TeamsPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "team"])
  const currentUser: IUser = useSelector((state: IAppState) => getCurrentUser(state))
  const UI: ITeamsUIState = useSelector((state: IAppState) => getTeamsUI(state))
  const canCreateTeam = currentUser.abilities?.team.canCreate
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchTeamsAsync)

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:teams")}
        buttonText={canCreateTeam ? t("team:addANewTeam") : ""}
        buttonHref={RoutePaths.TEAMS_NEW}
        hasButtonPermission={canCreateTeam}
      />
      <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
        <div className="row-start-2 md:row-start-1 self-center">
          <Trans
            i18nKey="team:searchBy"
            components={{
              strong: <strong />
            }}
          />
        </div>
        <div className="row-end-2 md:row-start-1 flex justify-end">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      <div className="mb-2">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          buttonText={t("team:searchTeam")}
        />
      </div>
      <h2 className="text-lg mb-2">
        {UI.totalItems} {t("shared:teams")}
      </h2>
      <div className="grid grid-flow-row md:grid-flow-col">
        <div>
          <TeamFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={TeamsOrderByOptions}
            useTranslation={"team"}
          />
        </div>
      </div>
      <TeamList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalItems}
        totalPages={UI.totalPages}
      />
    </div>
  )
}

export default TeamsPage
