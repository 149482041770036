import { createContext } from "react"
import { systemTimezone } from "../../../shared/helpers/timeHelpers"
import { IUserConfigContextState } from "../state/userConfigTypes"


// just need to update these after user logs in
export const userConfigContextInitialState: IUserConfigContextState = {
  locale: "en",
  timezone: systemTimezone
}

export const UserConfigContext = createContext(userConfigContextInitialState)
