import _ from "lodash"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Card from "../../../../shared/components/layout/Card"
import InviteButton from "../../../../shared/components/buttons/InviteButton"
import { getTeamById, getTeamUserByUserIdAndTeamId } from "../../../../shared/selectors/team"
import { ITeam } from "../../../team/state/teamTypes"
import Modal from "react-modal"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import AddTeamUsersModalContent from "./AddTeamUsersModalContent"
import { createTeamUser } from "../../../teamUser/state/teamUserActions"
import TeamUserListItem from "./TeamUserListItem"
import { ITeamUser } from "../../../teamUser/state/teamUserTypes"

interface IPropsFromParent {
  teamId: string
}

const TeamUsers: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "team"])
  const dispatch = useDispatch()
  const team = useSelector((state: IAppState): ITeam => getTeamById(state, props.teamId))
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const createTeamUsers = (values: { users: string[] }, { setSubmitting }) => {
    const teamUser = {
      teamId: team.id,
      userIds: values.users
    }
    dispatch(createTeamUser(teamUser))
    setSubmitting(false)
    closeModal()
  }

  const teamOwner = useSelector((state: IAppState): ITeamUser => getTeamUserByUserIdAndTeamId(state, props.teamId, team.owner))
  const sortedTeamUsers = team.teamUsers.sort((teamUserId1, teamUserId2) => {
    if (teamUserId1 == teamOwner.id) return -1
    if (teamUserId2 == teamOwner.id) return 1
    return 0
  })

  if (!team || !team.users) return null
  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("team:members")} ({team.users.length})
          </h3>
          {team.permissions && team.permissions.canInvite ? (
            <InviteButton openModal={openModal} label={t("team:addMembers")} />
          ) : null}
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans i18nKey="shared:teamsIntro" />
          </p>
        </div>

        <ul>
          {_.map(sortedTeamUsers, (teamUserId) => {
            return (
              <TeamUserListItem
                key={teamUserId}
                teamId={props.teamId}
                teamUserId={teamUserId}
                teamOwnerId={team.owner}
              />
            )
          })}
        </ul>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={defaultModalStyle}
      >
        <AddTeamUsersModalContent closeModal={closeModal} teamId={team.id} createTeamUsers={createTeamUsers} />
      </Modal>
    </div>
  )
}

export default TeamUsers
