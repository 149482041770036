import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, IRemovePathwayUserAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* removePathwayUserAsync(action: IRemovePathwayUserAction): Generator {
  try {
    const { user, pathwayId } = action.payload

    const response: any = yield call(API.delete, `/pathways/${pathwayId}/users/${user}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({
      type: PathwayActionTypes.REMOVE_PATHWAY_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "removePathwayUserSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: PathwayActionTypes.REMOVE_PATHWAY_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "removePathwayUserFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchRemovePathwayUserAsync(): Generator {
  yield takeLeading(PathwayActionTypes.REMOVE_PATHWAY_USER_ASYNC_PENDING, removePathwayUserAsync)
}
