import _ from "lodash"
import React, { useEffect } from "react"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { generatePath } from "react-router"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { IAppState } from "../../app/appTypes"
import { RoutePaths } from "../../app/routes/Routes"
import MeetingEditForm from "../../features/meetingEdit/components/MeetingEditForm"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { getMeetingById } from "../../shared/selectors/meeting"
import { fetchMeetingAction } from "../../features/meeting/state/meetingActions"
import { IHaveMeetingId } from "../../features/meeting/state/meetingTypes"

const MeetingEditPage: React.FC = (): JSX.Element => {
  const { meetingId } = useParams<IHaveMeetingId>()
  const { t } = useTranslation(["shared", "meeting"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId }))
  const dispatch = useDispatch()
  const fetchSpecialties = () => dispatch(fetchSpecialtiesAsync())
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const fetchMeeting = (meetingId: string) => dispatch(fetchMeetingAction(meetingId))

  useEffect(() => {
    fetchSpecialties()
    fetchUsers()
    fetchMeeting(meetingId)
  }, [])

  const viewMeetingUrl = generatePath(RoutePaths.MEETING, {
    meetingId: meetingId
  })

  const renderContent = (): JSX.Element => {
    if (!meeting) return <h3 className="text-lg">{t("meeting:meetingNotFound")}</h3>
    if (!meeting.permissions.canEdit) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    return (
      <MeetingEditForm meetingId={meetingId} />
    )
  }

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:meeting"),
            href: viewMeetingUrl
          },
          {
            pageTitle: t("meeting:editMeeting")
          }
        ]}
      />
      {renderContent()}
    </div>
  )
}

export default MeetingEditPage
