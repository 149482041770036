import React, { FC } from "react"
import { Field, FieldArray, useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import Button from "../../../shared/components/buttons/Button"
import FieldError from "../../../shared/components/forms/FieldError"
import Label from "../../../shared/components/forms/Label"
import SelectField from "../../../shared/components/forms/SelectField"
import { TSelectFieldUserOptions } from "../../../shared/components/forms/StandardSelectField"
import LoadingSpinner from "../../../shared/components/ui/LoadingSpinner"
import { useAppSelector } from "../../../shared/hooks"
import { getTenantConfig } from "../../../shared/selectors/tenantConfig"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { IMdtTeamUserData, ITeamNewData } from "../../team/state/teamTypes"
import MdtTeamUserSelectField from "./MdtTeamUserSelectField"

interface IProps {
  userOptions: TSelectFieldUserOptions
}

const MdtTeamUsersFieldArray: FC<IProps> = (props) => {
  const { mdtRoles } = useAppSelector(getTenantConfig)
  const { t } = useTranslation(["team"])
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched
  } = useFormikContext<ITeamNewData>()

  if (!mdtRoles) return <LoadingSpinner />
  const roleOptions = mdtRoles.map((r) => ({
    value: r,
    label: r
  }))
  return (
    <FieldArray name="teamUsers">
      {({ remove, push }) => (
        <>
          <div className="flex flex-col ">
            <div className="flex flex-row items-center">
              <div className="flex-auto w-3/12 mr-3" >
                <Label
                  name={"teamUsers.[].userId"}
                  required={true}>
                  {t("team:members")}
                </Label>
              </div>
              <div className="flex-auto w-3/12" >
                <Label name={"teamUsers.[].role"} required={true}>
                  {t("team:mdt:role")}
                </Label>
              </div>
              <div className="w-1/12" >
                &nbsp;
              </div>
            </div>
            {values.teamUsers?.length > 0 &&
              (values.teamUsers as IMdtTeamUserData[]).map((teamUser, index, teamUsers) => (
                <div className="flex flex-row w-full items-center" key={index}>
                  <Field type="hidden" name={`teamUsers.${index}.id`} />
                  <div className="mr-3 flex-auto w-3/12">
                    <MdtTeamUserSelectField
                      index={index}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      userOptions={props.userOptions}
                      teamUser={teamUser}
                    />
                  </div>
                  <div className="flex-auto w-3/12">
                    <SelectField
                      name={`teamUsers.${index}.role`}
                      options={roleOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="ml-3 mb-2 w-1/12">
                    {teamUsers.length > 1 ? <Button action={() => remove(index)}>remove</Button> : null}
                  </div>
                </div>
              ))
            }
            <FieldError
              errorMessage={t("team:mdt:teamUsersError") as string}
              isVisible={(errors.teamUsers && touched.teamUsers) as boolean}
            />
          </div>
          <div className="mt-3">
            <Button
              variant={StyleVariants.BLUE} action={() => push({
                id: "",
                userId: null,
                role: null
              })}>Add member</Button>
          </div>
        </>
      )
      }
    </FieldArray >
  )
}

export default MdtTeamUsersFieldArray
