import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export default interface IChatMessagesUIState {
  totalItems: number
  totalPages: number
  resultsPerPage: number
  isLoading: boolean
  unreadCount: number
  notificationMessageId: string
}

// Actions
export interface IFetchChatMessagesAsyncAction {
  type: string
  payload: {
    chatId: string
    searchQuery: ISearchQuery
  }
}

export interface IUpdateNotificationMessage {
  type: string,
  payload: string
}

// Action Types
export enum ChatMessagesActionTypes {
  FETCH_CHAT_MESSAGES_ASYNC_PENDING = "@@messages/FETCH_CHAT_MESSAGES_ASYNC_PENDING",
  FETCH_CHAT_MESSAGES_ASYNC_SUCCESS = "@@messages/FETCH_CHAT_MESSAGES_ASYNC_SUCCESS",
  FETCH_CHAT_MESSAGES_ASYNC_ERROR = "@@messages/FETCH_CHAT_MESSAGES_ASYNC_ERROR",
  UPDATE_NOTIFICATION_MESSAGE = "@@messages/UPDATE_NOTIFICATION_MESSAGE"
}
