import { CaseSharedAssetsActionTypes, ICreateCaseSharedAssetAsyncAction, IDeleteCaseSharedAssetAsyncAction, IFetchCaseAssetsCanBeSharedWithUser } from "./caseSharedAssetsTypes"

export const fetchCaseAssetsCanBeSharedWithUserAction = (caseId: string, userId: string): IFetchCaseAssetsCanBeSharedWithUser => {
  return {
    type: CaseSharedAssetsActionTypes.FETCH_CASE_ASSETS_CAN_BE_SHARED_WITH_USER_ASYNC_PENDING,
    payload: {
      caseId,
      userId
    }
  }
}

export const createCaseSharedAssetAction = (caseId: string, caseAssetId: string, userId: string): ICreateCaseSharedAssetAsyncAction => {
  return {
    type: CaseSharedAssetsActionTypes.CREATE_CASE_SHARED_ASSET_ASYNC_PENDING,
    payload: {
      caseId,
      caseAssetId,
      userId
    }
  }
}

export const deleteCaseSharedAssetAction = (caseId: string, userSharedAssetId: string, userId: string): IDeleteCaseSharedAssetAsyncAction => {
  return {
    type: CaseSharedAssetsActionTypes.DELETE_CASE_SHARED_ASSET_ASYNC_PENDING,
    payload: {
      caseId,
      userSharedAssetId,
      userId
    }
  }
}
