import React from "react"
import { useSelector } from "react-redux"
import Card from "../../../../shared/components/layout/Card"
import AddCaseAssetPanel from "../../../case/components/addCaseAssetPanel/AddCaseAssetPanel"
import CaseActivityPanel from "../../../case/components/activityPanel/CaseActivityPanel"
import { IAppState } from "../../../../app/appTypes"
import { getMeetingUsers, getMeetingById } from "../../../../shared/selectors/meeting"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import { AddCaseAssetPanelDisabled } from "../../../case/components/addCaseAssetPanel/AddCaseAssetPanelDisabled"
import AddCaseTaskPanel from "../../../case/components/addCaseTaskPanel/AddCaseTaskPanel"
import { RoutePaths } from "../../../../app/routes/Routes"
import { generatePath } from "react-router"

interface IProps {
  caseId: string
  meetingId: string
  meetingCaseId: string
}

const MeetingCaseActivity: React.FC<IProps> = (props): JSX.Element => {
  const mentionableUsers = useSelector((state: IAppState) => getMeetingUsers(state, props))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  if (!meeting) { return null }

  const renderMeetingControls = () => {
    const meetingStatus = meeting.status

    if (meetingStatus == MeetingStatuses.Scheduled || meetingStatus == MeetingStatuses.InProgress) {
      return (
        <div>
          <Card>
            <AddCaseAssetPanel
              caseId={props.caseId}
              meetingId={props.meetingId}
              mentionableUsers={mentionableUsers}
            />
          </Card>

          <Card>
            <AddCaseTaskPanel
              caseId={props.caseId}
              meetingId={props.meetingId}
              mentionableUsers={mentionableUsers}
            />
          </Card>
        </div>
      )
    }

    const messageKey = meetingStatus == MeetingStatuses.Ended ? "asset:meetingHasEnded" : "asset:meetingWasCancelled"

    return (
      <Card>
        <AddCaseAssetPanelDisabled messageKey={messageKey} />
      </Card>
    )
  }

  const generateTabPath = (currentTab: string): string => {
    return generatePath(RoutePaths.MEETING_CASE, {
      meetingCaseId: props.meetingCaseId,
      currentTab: currentTab
    })
  }

  return (
    <div className="lg:grid grid-cols-2 gap-1">
      <div>
        {renderMeetingControls()}
      </div>
      <div>
        <Card>
          <CaseActivityPanel
            caseId={props.caseId}
            meetingId={props.meetingId}
            meetingCaseId={props.meetingCaseId}
            showMeetingsTab={false}
            showMeetingAssetsTab={true}
            mentionableUsers={mentionableUsers}
            generateTabPath={generateTabPath}
          />
        </Card>
      </div>
    </div>
  )
}

export default MeetingCaseActivity
