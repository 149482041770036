import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { Formik, Form } from "formik"
import Label from "../../../shared/components/forms/Label"
import { uploadTenantLogoAsync } from "../state/adminActions"
import { IUploadTenantLogoFormValues } from "../state/adminTypes"


interface IPropsFromDispatch {
  uploadFile: (values: IUploadTenantLogoFormValues) => void
}

type Props =  IPropsFromDispatch

const acceptedFileTypes = ["image/jpeg", "image/png"]
const maxFileSizeMB = 2
const maxFileSize = maxFileSizeMB * 1024 * 1024

const TenantLogoUploadForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "asset", "admin"])

  const handleSubmit = (values: IUploadTenantLogoFormValues, { setSubmitting }) => {
    props.uploadFile(values)
    setSubmitting(false)
  }

  const validate = (values: IUploadTenantLogoFormValues) => {
    const errors: { file?: string } = {}
    const fileType = values.file.type
    if (values.file.size > maxFileSize) {
      errors.file = t("shared:fileTooLarge", {maxFileSize: maxFileSizeMB})
    } else if (!acceptedFileTypes.includes(fileType)) {
      errors.file = t("shared:fileTypeWrong", {acceptedFileTypes: acceptedFileTypes})
    }
    return errors
  }

  const initialValues = {
    file: null
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange validate={validate}>
        {({ errors, setFieldValue, isSubmitting, values, isValid }) => (
          <Form>
            <div>
              <Label name="file" className="mt-3">
                {t("admin:updateLogo")}:
              </Label>

              <input
                className="cursor-pointer"
                id="file" name="file"
                type="file"
                accept={acceptedFileTypes.join(", ")}
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0])
                }} />
              {errors.file ? <div className="text-ch-red-500 text-sm mb-1">{errors.file}</div> : null}

              <Button
                className="mt-2 mb-4"
                key="2"
                isDisabled={values.file == null || !isValid || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}>
                {t("asset:uploadFile")}
              </Button>

            </div>
          </Form>
        )
        }
      </Formik >
    </div >
  )
}

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => {
  return {
    uploadFile: (values: IUploadTenantLogoFormValues) => dispatch(uploadTenantLogoAsync(values))
  }
}

export default connect<null, IPropsFromDispatch>(
  null,
  mapDispatchToProps
)(TenantLogoUploadForm)
