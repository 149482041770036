import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import Button from "../../../../shared/components/buttons/Button"
import LiveMeetingElapsedTime from "./LiveMeetingElapsedTime"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { StyleVariants } from "./../../../../shared/state/sharedTypes"
import { generatePath } from "react-router"
import { RoutePaths } from "../../../../app/routes/Routes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import TenantLogo from "../../../../shared/components/images/TenantLogo"
import HelpButton from "../../../../shared/components/buttons/HelpButton"

interface IProps {
  store?: MockStore
  meetingId: string
}


const LiveMeetingHeaderContainer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))

  const { tenantName } = useContext(
    TenantConfigContext
  )

  const leaveMeetingUrl = generatePath(RoutePaths.MEETING, {
    meetingId: props.meetingId
  })

  const renderContent = (): JSX.Element => {
    if (!meeting) return

    return (
      <div className="block sm:flex justify-between items-center">
        <div className="sm:flex items-center p-1 lg:pr-5 justify-center">
          <div className="hidden md:block">
            <TenantLogo className="h-14 mr-2" />
          </div>
          <div className="flex flex-col lg:flex-row  items-start">
            <h1 className="text-sm md:text-base text-ch-gray-600 uppercase mr-2">
              {tenantName}
            </h1>
            <div className="hidden md:block lg:ml-4"><HelpButton /></div>
          </div>
        </div>
        <div className="flex-1 lg:pr-5 text-center">
          <h2 className="text-sm md:text-base lg:text-xl text-ch-blue-600">
            {meeting.name}
          </h2>
          <TimeDefaultFormat
            utcTime={meeting.startDate}
            className="text-sm lg:text-lg text-ch-blue-600 italic font-thin"
          />
        </div>
        <div className="flex justify-center">
          <div className="text-sm lg:text-base pr-5 lg:block hidden">
            <LiveMeetingElapsedTime utcStartTime={meeting.startDateActual} />
          </div>
          <div className="flex flex-col">
            <Button
              href={leaveMeetingUrl}
              className="mt-1 mb-2"
              variant={StyleVariants.PURPLE}
              isChunky={true}
            >
              {t("liveMeeting:leaveMeeting")}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="px-5 pt-2 pb-2 mb-3 bg-white shadow">{renderContent()}</div>
  )
}


export default LiveMeetingHeaderContainer
