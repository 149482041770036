import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconFlag({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 23"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M17.693.118A.692.692 0 0118 .692v10.385a.693.693 0 01-.435.642l-.257-.642.257.642-.004.002-.008.004-.032.012a30.2 30.2 0 01-2 .693c-1.13.349-2.601.724-3.752.724-1.172 0-2.143-.388-2.988-.727l-.039-.014c-.877-.353-1.625-.644-2.511-.644-.97 0-2.268.319-3.375.66-.495.155-.986.324-1.471.506v8.527a.693.693 0 01-1.385 0V.692a.692.692 0 111.385 0v.39a30.95 30.95 0 011.093-.36C3.608.378 5.082 0 6.231 0c1.163 0 2.11.384 2.937.719l.06.025c.86.347 1.61.64 2.541.64.97 0 2.268-.318 3.374-.66a27.205 27.205 0 001.868-.659l.027-.01.005-.002h.002"
      />
    </svg>
  );
}

export default SvgIconFlag;
