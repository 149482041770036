import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById } from "../../../../shared/selectors/case"
import Buttons from "../../../../shared/components/layout/Buttons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconPathway } from "../../../../svg/icons"
import ListBackground from "../../../../shared/components/layout/ListBackground"
import AddPathwayToCaseModal from "../../../addPathwayToCase/components/AddPathwayToCaseModal"

interface Props {
  caseId: string
}

export enum Tabs {
  SelectPathway = "select_pathway",
}

const AddCasePathwayPanel: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "case", "pathway"])
  const caseData = useSelector((state: IAppState) => getCaseById(state, { caseId: props.caseId }))
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const openModal = (): void => {
    setIsModalOpen(true)
  }

  const closeModal = (): void => {
    setIsModalOpen(false)
  }

  if (caseData.locked) return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t("case:addPathwaysLocked")}
      </h3>
      <ListBackground />
    </div>
  )

  return (
    <>
      <h3 className="flex mb-2">
        <IconPathway className="w-6 h-6 mr-2 text-ch-purple-500" title={t("shared:pathways")} />
        {t("shared:pathways")}
      </h3>

      <Buttons
        className="mb-2"
        buttons={[
          <Button
            action={openModal}
            variant={StyleVariants.BLUE_ALT}
            key="1"
          >
            {t("pathway:addPathwayToCase:buttons:selectPathway")}
          </Button>
        ]}
      />

      <AddPathwayToCaseModal
        caseId={props.caseId}
        isOpen={isModalOpen}
        handleClose={closeModal}
      />
    </>
  )
}

export default AddCasePathwayPanel
