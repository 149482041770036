import React from "react"
import { useTranslation } from "react-i18next"
import ButtonFilter from "../../../../shared/components/buttons/ButtonFilter"
import { ISearchQuery, ISetSearchQueryFunction } from "../../../../shared/hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
}

const TaskFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["task"])

  const checkAllIsOn = () => {
    return props.searchQuery.appliedFilters.length == 0
  }

  const checkIsOn = (filter) => {
    return props.searchQuery.appliedFilters.includes(filter)
  }

  const resetFilters = () => {
    if (!checkAllIsOn()) {
      props.setSearchQuery({ appliedFilters: [] })
    }
  }

  const updateFilters = (filter) => {
    const isOn = checkIsOn(filter)
    if (isOn) {
      props.setSearchQuery({
        appliedFilters: props.searchQuery.appliedFilters.filter(i => i !== filter)
      })
    } else {
      props.setSearchQuery({
        appliedFilters: [...props.searchQuery.appliedFilters, filter]
      })
    }
  }

  return (
    <ul className="flex flex-wrap">
      <li className="mb-2 mr-2">
        <ButtonFilter isOn={checkAllIsOn()} action={() => { resetFilters() }}>
          {t("task:filter:all")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter isOn={checkIsOn("outstanding")} action={() => { updateFilters("outstanding") }}>
          {t("task:filter:outstanding")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter isOn={checkIsOn("completed")} action={() => { updateFilters("completed") }}>
          {t("task:filter:completed")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter isOn={checkIsOn("assigned_to_you")} action={() => { updateFilters("assigned_to_you") }}>
          {t("task:filter:assignedToYou")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter isOn={checkIsOn("created_by_you")} action={() => { updateFilters("created_by_you") }}>
          {t("task:filter:createdByYou")}
        </ButtonFilter>
      </li>
    </ul>
  )
}

export default TaskFilter
