import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { IFetchNotificationsAsyncAction, IMarkAllNotificationsAsReadAction, IMarkNotificationAsReadAction, NotificationsActionTypes, NotificationsCableActionTypes } from "./notificationsTypes"

export const fetchNotificationsAsync = (searchQuery: ISearchQuery): IFetchNotificationsAsyncAction => {
  return {
    type: NotificationsActionTypes.FETCH_NOTIFICATIONS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const markAllNotififcationsAsRead = (): IMarkAllNotificationsAsReadAction => {
  return {
    type: NotificationsCableActionTypes.MARK_ALL_AS_READ,
    meta: { isOptimistic: true }
  }
}

export const markNotificationAsRead = (notificationId: string): IMarkNotificationAsReadAction => {
  return {
    type: NotificationsCableActionTypes.MARK_AS_READ,
    payload: {
      notificationId: notificationId
    },
    meta: { isOptimistic: false }
  }
}

export const markNotificationAsUnread = (notificationId: string): IMarkNotificationAsReadAction => {
  return {
    type: NotificationsCableActionTypes.MARK_AS_UNREAD,
    payload: {
      notificationId: notificationId
    },
    meta: { isOptimistic: false }
  }
}
