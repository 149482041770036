import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconMicOn({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <g fill="currentColor">
          <path d="M16 21.333a6.184 6.184 0 006.222-6.222V8A6.184 6.184 0 0016 1.778 6.184 6.184 0 009.778 8v7.111A6.184 6.184 0 0016 21.333z" />
          <path d="M26.667 15.111h-1.778C24.889 20 20.889 24 16 24c-4.889 0-8.889-4-8.889-8.889H5.333c0 5.6 4.267 10.133 9.778 10.578v2.755h-2.667c-.533 0-.888.356-.888.89 0 .533.355.888.888.888h7.112c.533 0 .888-.355.888-.889 0-.533-.355-.889-.888-.889h-2.667V25.69c5.511-.445 9.778-4.978 9.778-10.578z" />
        </g>
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconMicOn;
