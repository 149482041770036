import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getComponentTypeForAsset } from "../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../shared/selectors/asset"
import { AssetComponentTypes, IDocumentAsset } from "../../../assets/state/assetsTypes"

interface IProps {
  assetId: string
}

const CaseAssetViewerHeading: React.FC<IProps> = (props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  if (!asset) return null

  const assetComponentType = getComponentTypeForAsset(asset)
  const headingLookup = {
    [AssetComponentTypes.Pdf]: (asset.asset as IDocumentAsset).name,
    [AssetComponentTypes.Image]: (asset.asset as IDocumentAsset).name,
  }
  const headingText: string = headingLookup[assetComponentType]

  if (!headingText) return null

  return (
    <h3 className="flex items-center justify-center flex-1 font-thin">
      {headingText}
    </h3>
  )
}

export default CaseAssetViewerHeading
