import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import TaskDetails from "./TaskDetails"
import _ from "lodash"
import { getTaskById } from "../../../../../shared/selectors/task"
import { ICaseTaskFilters } from "../../../../tasks/state/tasksTypes"
import { isTaskFiltered } from "../../../../../shared/helpers/taskHelpers"
import classNames from "classnames"

interface IProps {
  taskId: string
  addResponseAction: () => void
  taskFilters: ICaseTaskFilters
  openAssetModal: (assetId: string) => void
}

const TaskListItem: React.FC<IProps> = (props) => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  if (!task) return null

  const hidden = isTaskFiltered(task, props.taskFilters)

  return (
    <li className={classNames("mb-1", { hidden })}>
      <TaskDetails
        taskId={task.id}
        addResponseAction={props.addResponseAction}
        openAssetModal={props.openAssetModal}
      />
    </li>
  )
}

export default TaskListItem
