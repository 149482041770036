import _ from "lodash"
import { AnyAction, combineReducers, Reducer } from "redux"
import { AnalyticsActionTypes, IUserAnalyticsState, TUserMeetingData, TUserMetaData, TUserMonthlyMeetingData, TUserMonthlyRecommendationData, TUserMonthlySpecialtyMeetingData } from "./analyticsTypes"

export function mdtMeetings(state: TUserMeetingData = {}, action: AnyAction): TUserMeetingData {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_USER_DATA_SUCCESS:
      return _.keyBy(action.payload.mdtMeetings, "name")
    default:
      return { ...state }
  }
}

export function monthlyMeetingData(state: TUserMonthlyMeetingData = {}, action: AnyAction): TUserMonthlyMeetingData {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_USER_DATA_SUCCESS: {
      const monthlyData = _.keyBy(action.payload.monthlyMeetingData, "name")
      const totalStart = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      const totalMonthlyData = ["invited", "attended", "mdtsInvited", "mdtsAttended", "mdtsContributed"].reduce((acc, key) => {
        acc[key] = totalStart
        return acc
      },
        {})
      Object.keys(totalMonthlyData).forEach((category) => {
        const total = Object.keys(monthlyData).reduce((total, specialty) => {
          const specialtyCounts = monthlyData[specialty].monthlyData[category]
          const newCounts = total.map((t, i) => t + specialtyCounts[i])
          return newCounts
        }, totalStart)
        totalMonthlyData[category] = total
      })
      monthlyData.Total = {
        name: "Total",
        monthlyData: totalMonthlyData as TUserMonthlySpecialtyMeetingData
      }
      return monthlyData
    }
    default:
      return { ...state }
  }
}

export function monthlyRecommendationData(state: TUserMonthlyRecommendationData = {}, action: AnyAction): TUserMonthlyRecommendationData {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_USER_DATA_SUCCESS:
      return _.keyBy(action.payload.monthlyRecomendationData, "name")
    default:
      return { ...state }
  }
}

const initialMetaDataState = {
  fromYear: (new Date()).getFullYear(),
  months: []
}

export function metaData(state: TUserMetaData = initialMetaDataState, action: AnyAction): TUserMetaData {
  switch (action.type) {
    case AnalyticsActionTypes.FETCH_USER_DATA_SUCCESS:
      return { ...action.payload.meta }
    default:
      return { ...state }
  }
}

export const userAnalyticsReducer: Reducer<IUserAnalyticsState> = combineReducers({
  mdtMeetings,
  monthlyMeetingData,
  monthlyRecommendationData,
  metaData
})
