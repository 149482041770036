import React, { useState } from "react"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import Modal from "react-modal"
import SwitchTenantList from "./SwitchTenantList"
import CreateTenantForm from "./CreateTenantForm"
import SwitchTenantHeader from "./SwitchTenantHeader"

interface IPropsFromParent {
  closeModal: () => void
  isOpen: boolean
}

type Props = IPropsFromParent

const SwitchTenantModal: React.FC<Props> = (props: Props): JSX.Element => {
  const [isCreateTenantOpen, setIsCreateTenantOpen] = useState(false)

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={defaultModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <SwitchTenantHeader
          isCreateTenantOpen={isCreateTenantOpen}
          setIsCreateTenantOpen={setIsCreateTenantOpen}
        />
        <CreateTenantForm
          isVisible={isCreateTenantOpen}
          closeAction={() => setIsCreateTenantOpen(false)}
        />
        <SwitchTenantList
          isVisible={!isCreateTenantOpen}
          closeModal={props.closeModal}
        />
      </ModalContainer>
    </Modal >
  )
}

export default SwitchTenantModal
