import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import IconCircleContainer from "../../../../../shared/components/icons/IconCircleContainer"
import DetailsCardInner from "../../../../../shared/components/layout/detailsCard/DetailsCardInner"
import TimeDefaultFormat from "../../../../../shared/components/time/TimeDefaultFormat"
import { getTaskById } from "../../../../../shared/selectors/task"
import { IconTask } from "../../../../../svg/icons"
import AssigneesList from "../../../../tasks/components/shared/AssigneesList"
import { ITask } from "../../../../tasks/state/tasksTypes"

interface IProps {
  taskId: string
}
const CasePathwayTaskDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const task: ITask = useSelector((state: IAppState) => getTaskById(state, props))
  if (!task) return null

  const { t } = useTranslation(["task"])

  return (
    <DetailsCardInner>
      <div className="flex flex-1 items-center">
        <IconCircleContainer className="sm:mr-4" sizeClasses={["w-7", "h-7"]}>
          <IconTask className="fill-current w-7 h-7" />
        </IconCircleContainer>
        <div>
          <h5 className="font-semibold mb-1">
            <span>{task.casePathwayPosition}. </span>
            <span>{task.action}</span>
          </h5>
          <p>
            {task.description}
          </p>
          { task.users.length ?
            <div>
              <p>
                {t("task:assignedTo")}: <AssigneesList taskId={props.taskId} />
              </p>
              <p>
                {t("task:dueDate")}: {
                  task.dueDate ?
                    <TimeDefaultFormat utcTime={task.dueDate} />
                    : <em>{t("task:dueDateNotSet")}</em>
                }
              </p>
            </div> : null
          }
        </div>
      </div>
    </DetailsCardInner>

  )
}

export default CasePathwayTaskDetails
