import { useTranslation } from "react-i18next"
import React, { useEffect } from "react"
import { Formik, useFormikContext } from "formik"
import Label from "../forms/Label"
import StandardSelectField, { TSelectFieldOptions } from "../forms/StandardSelectField"
import { StyleVariants } from "../../state/sharedTypes"
import humps from "humps"
import { ISearchQuery, ISetSearchQueryFunction } from "../../hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery
  setSearchQuery: ISetSearchQueryFunction
  orderByOptions: Record<string, string>
  useTranslation: string
}

const ValueChangeListener = () => {
  const { submitForm, values } = useFormikContext()
  const value = values

  useEffect(() => {
    if (value) {
      submitForm()
    }
  }, [value, submitForm])

  return null
}

const OrderBy: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation([props.useTranslation])
  const orderOptions: TSelectFieldOptions = Object.keys(props.orderByOptions).map((key) => {
    return {
      value: props.orderByOptions[key],
      label: t(`${props.useTranslation}:${humps.camelize(key)}`)
    }
  })

  const initialValues = {
    orderBy: props.searchQuery.orderBy
  }

  const reload = (value) => {
    props.setSearchQuery({ orderBy: value.orderBy })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={reload}
    >
      {({
        values,
        handleChange,
        handleBlur
      }) => {
        return (
          <div className="flex flex-row items-center">
            <Label className="mr-2" name="orderBy">
              {t("shared:orderBy")}
            </Label>
            <StandardSelectField
              options={orderOptions}
              type="text"
              name="orderBy"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orderBy}
              className="pt-2 pb-2 pl-2 pr-2"
              autoComplete="off"
              includeBlank={false}
              variant={StyleVariants.BLUE_ALT}
              isLight={true}
            />
            <ValueChangeListener />
          </div>
        )
      }}
    </Formik>
  )
}

export default OrderBy
