import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconVideoOff({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M19.557 25H5.958a2 2 0 01-2-2V9.399L0 5.439 1.44 4 25 27.562 23.56 29l-4.003-4zM7 5h16.27a2 2 0 012 2v4.06l5.418-3.87A1 1 0 0132.27 8v14a1 1 0 01-1.58.81l-5.42-3.87V23c0 .098-.007.196-.02.291L7 5z"
        />
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconVideoOff;
