import React from "react"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import { IconStep } from "../../../../svg/icons"
import StepDetails from "../../../pathway/components/details/StepDetails"
import { IPathwayStep } from "../../../pathway/pathwayTypes"

interface IProps {
  step: IPathwayStep
}

const PathwayStep: React.FC<IProps> = ({ step }: IProps) => {
  if (!step) return null
  return (
    <DetailsCardInner width="full">
      <div className="flex flex-1 items-center" >
        <IconCircleContainer className="sm:mr-4" >
          <IconStep className="fill-current w-6 h-6" />
        </IconCircleContainer>
        <StepDetails step={step} />
      </div>
    </DetailsCardInner>
  )
}

export default PathwayStep
