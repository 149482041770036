import { combineReducers } from "redux"
import { IAppState } from "../appTypes"
import { userSessionReducer } from "../../features/user/state/reducers/userSessionReducer"
import { userConfigReducer } from "./../../features/userConfig/state/userConfigReducer"
import { caseEntityReducer } from "../../features/case/state/reducers/caseEntityReducer"
import { liveMeetingUIReducer } from "../../features/liveMeeting/state/reducers/liveMeetingUIReducer"
import { liveMeetingConfigReducer } from "../../features/liveMeeting/state/reducers/liveMeetingConfigReducer"
import { meetingEntityReducer } from "../../features/meeting/state/reducers/meetingEntityReducer"
import { userEntityReducer } from "../../features/user/state/reducers/userEntityReducer"
import { meetingCaseEntityReducer } from "../../features/meetingCase/state/reducers/meetingCaseEntityReducer"
import { meetingUserEntityReducer } from "../../features/meetingUser/state/reducers/meetingUserEntityReducer"
import { tenantConfigReducer } from "../../features/tenantConfig/state/tenantConfigReducer"
import { assetEntityReducer } from "../../features/assets/state/reducers/assetEntityReducer"
import { webRTCSessionReducer } from "../../features/webRTC/state/reducers/webRTCSessionReducer"
import { webRTCPublisherReducer } from "../../features/webRTC/state/reducers/webRTCPublisherReducer"
import { userMessageReducer } from "../../features/userMessage/state/reducers/userMessageReducer"
import { webRTCStreamReducer } from "../../features/webRTC/state/reducers/webRTCStreamReducer"
import { meetingsSearchUIReducer } from "../../features/meetings/state/reducers/meetingsUIReducer"
import { webRTCScreenShareReducer } from "../../features/webRTC/state/reducers/webRTCScreenShareReducer"
import { webRTCAudioDeviceReducer } from "../../features/webRTC/state/reducers/webRTCAudioDeviceReducer"
import { navUIReducer } from "../../features/nav/state/reducers/navUIReducer"
import { specialtiesReducer } from "../../features/specialty/state/reducers/specialtiesReducer"
import { caseClassificationReducer } from "../../features/caseClassification/state/reducers/caseClassificationReducer"
import { patientsReducer } from "../../features/patients/state/reducers/patientsEntityReducer"
import { pathwaysEntityReducer } from "../../features/pathways/state/reducers/pathwaysEntityReducer"
import { usersUIReducer } from "../../features/users/state/reducers/usersUIReducer"
import { casesUIReducer } from "../../features/cases/state/reducers/casesUIReducer"
import { notificationEntityReducer } from "../../features/notification/state/reducers/notificationEntityReducer"
import { notificationsUIReducer } from "../../features/notifications/state/reducers/notificationsUIReducer"
import { addCaseToMeetingUIReducer } from "../../features/addCaseToMeeting/state/reducers/addCaseToMeetingUIReducer"
import { scrollUIReducer } from "../../features/scroll/state/reducers/scrollUIReducer"
import { teamEntityReducer } from "../../features/teams/state/reducers/teamsEntityReducer"
import { taskEntityReducer } from "../../features/tasks/state/reducers/taskEntityReducer"
import { taskResponseEntityReducer } from "../../features/taskResponse/state/reducers/taskResponseEntityReducer"
import { tasksUIReducer } from "../../features/tasks/state/reducers/tasksUIReducer"
import { patientsUIReducer } from "../../features/patients/state/reducers/patientsUIReducer"
import { UserActionTypes } from "../../features/user/state/userTypes"
import { AccountActionTypes } from "../../features/account/state/accountTypes"
import { patientNewUIReducer } from "../../features/patientNew/state/reducers/patientNewUIReducer"
import { teamsSearchUIReducer } from "../../features/teams/state/reducers/teamsUIReducer"
import { teamUserEntityReducer } from "../../features/teamUser/state/reducers/teamUserEntityReducer"
import { chatsUIReducer } from "../../features/chats/state/reducers/chatsUIReducer"
import { chatEntityReducer } from "../../features/chat/state/reducers/chatEntityReducer"
import { messageEntityReducer } from "../../features/message/state/reducers/messageEntityReducer"
import { messagesUIReducer } from "../../features/messages/state/reducers/messagesUIReducer"
import { auditEventsEntityReducer } from "../../features/audit/state/reducers/auditEventsEntityReducer"
import { pathwaysUIReducer } from "../../features/pathways/state/reducers/pathwaysUIReducer"
import { messageAssetEntityReducer } from "../../features/messageAssets/state/reducers/messageAssetEntityReducer"
import { addPathwayToCaseUIReducer } from "../../features/addPathwayToCase/state/reducers/addPathwayToCaseUIReducer"
import { casePathwaysEntityReducer } from "../../features/casePathways/state/reducers/casePathwayEntityReducer"
import { caseSourceEntityReducer } from "../../features/sources/state/reducers/caseSourcesEntityReducer"
import { sharedAssetEntityReducer } from "../../features/caseSharedAssets/state/reducers/sharedAssetEntityReducer"
import { userCheckUIReducer } from "../../features/user/state/reducers/userCheckUIReducer"
import { analyticsReducer } from "../../features/analytics/state/analyticsReducer"
import { externalUsersEntityReducer } from "../../features/externalUser/state/reducers/externalUserEntityReducer"

const entitiesReducer = combineReducers({
  cases: caseEntityReducer,
  meetings: meetingEntityReducer,
  meetingCases: meetingCaseEntityReducer,
  meetingUsers: meetingUserEntityReducer,
  users: userEntityReducer,
  assets: assetEntityReducer,
  specialties: specialtiesReducer,
  caseClassification: caseClassificationReducer,
  patients: patientsReducer,
  pathways: pathwaysEntityReducer,
  casePathways: casePathwaysEntityReducer,
  notifications: notificationEntityReducer,
  teams: teamEntityReducer,
  teamUsers: teamUserEntityReducer,
  tasks: taskEntityReducer,
  taskResponses: taskResponseEntityReducer,
  chats: chatEntityReducer,
  messages: messageEntityReducer,
  messageAssets: messageAssetEntityReducer,
  auditEvents: auditEventsEntityReducer,
  caseSources: caseSourceEntityReducer,
  sharedAssets: sharedAssetEntityReducer,
  externalUsers: externalUsersEntityReducer
})

const uiReducer = combineReducers({
  scroll: scrollUIReducer,
  nav: navUIReducer,
  liveMeeting: liveMeetingUIReducer,
  meetings: meetingsSearchUIReducer,
  cases: casesUIReducer,
  users: usersUIReducer,
  notifications: notificationsUIReducer,
  tasks: tasksUIReducer,
  addCaseToMeeting: addCaseToMeetingUIReducer,
  patients: patientsUIReducer,
  patientNew: patientNewUIReducer,
  teams: teamsSearchUIReducer,
  chats: chatsUIReducer,
  messages: messagesUIReducer,
  pathways: pathwaysUIReducer,
  addPathwayToCase: addPathwayToCaseUIReducer,
  userCheck: userCheckUIReducer,
  analytics: analyticsReducer
})

const wrapReducer = (reducer) => (
  (state, action) => {
    if (action.type == UserActionTypes.SET_TENANT_ASYNC_SUCCESS ||
      action.type == AccountActionTypes.SIGN_OUT_USER_ASYNC_SUCCESS) {
      return reducer(undefined, action)
    }
    return reducer(state, action)
  }
)

const appReducer = combineReducers<IAppState>({
  entities: wrapReducer(entitiesReducer),
  ui: wrapReducer(uiReducer),
  userSession: userSessionReducer,
  tenantConfig: wrapReducer(tenantConfigReducer),
  userConfig: wrapReducer(userConfigReducer),
  liveMeetingConfig: wrapReducer(liveMeetingConfigReducer),
  webRTC: combineReducers({
    session: webRTCSessionReducer,
    publisher: webRTCPublisherReducer,
    streams: webRTCStreamReducer,
    screenShare: webRTCScreenShareReducer,
    audioDevices: webRTCAudioDeviceReducer
  }),
  userMessage: wrapReducer(userMessageReducer)
})


export default appReducer
