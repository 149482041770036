// State
export interface INavUIState {
  isMenuOpen: boolean
  isQuickViewOpen: boolean
  quickViewResourceId: string
}

// Actions
export interface INavToggleIsMenuOpenAction {
  type: string
  payload: boolean
}

export interface INavToggleIsQuickViewOpenAction {
  type: string
  payload: {
    quickViewResourceId: string
    isQuickViewOpen: boolean
  }
}

// Action Types
export enum NavActionTypes {
  TOGGLE_IS_MENU_OPEN = "@@nav/TOGGLE_IS_MENU_OPEN",
  TOGGLE_IS_QUICK_VIEW_OPEN = "@@nav/TOGGLE_IS_QUICK_VIEW_OPEN",
  UPDATE_QUICK_VIEW_RESOURCE_ID = "@@nav/UPDATE_QUICK_VIEW_RESOURCE_ID"
}
