import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import history from "../../../app/routes/history"
import { RoutePaths } from "../../../app/routes/Routes"
import { IAppState } from "../../../app/appTypes"
import ButtonLink from "./ButtonLink"
import { IconSignOut } from "../../../svg/icons"
import MenuLink from "../../../features/nav/components/MenuLink"
import { signOutUserAction } from "../../../features/account/state/accountActions"
import { useSelector } from "react-redux"

interface IPropsFromParent {
  isMenuLink?: boolean
}

const SignOutButton: React.FC<IPropsFromParent> = (props: IPropsFromParent) => {
  const apiToken = useSelector((state: IAppState) => state.userSession.apiToken)
  const dispatch = useDispatch()
  if (!apiToken) return null

  const { t } = useTranslation(["shared"])

  const onClickSignOut = () => {
    dispatch(signOutUserAction())
    history.push(RoutePaths.SIGN_IN)
  }

  const renderMenuButton = (): JSX.Element => {
    return (
      <MenuLink action={onClickSignOut}>
        <IconSignOut className="w-6 h-6 mr-3" /> {t("shared:signout")}
      </MenuLink>
    )
  }

  const renderNormalButton = (): JSX.Element => {
    return (
      <ButtonLink action={onClickSignOut} noPaddingY className="normal-case ml-8" variant="ch-gray-alt">
        {t("shared:signout")} <IconSignOut className="w-6 h-6 ml-2" title={t("shared:signout")} />
      </ButtonLink>
    )
  }
  return props.isMenuLink ? renderMenuButton() : renderNormalButton()
}

export default SignOutButton
