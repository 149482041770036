import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../../shared/components/buttons/Button"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../shared/components/forms/FieldError"
import InputField from "../../../../shared/components/forms/InputField"
import Label from "../../../../shared/components/forms/Label"
import Buttons from "../../../../shared/components/layout/Buttons"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IUser } from "../../../user/state/userTypes"
import { createTenantAsync } from "../../state/tenantActions"
import { ITenantNewData } from "../../state/tenantTypes"
import { tenantNewValidationSchema } from "../../state/validation/tenantNewValidation"

interface Props {
  closeAction: () => void
  isVisible: boolean
}

const CreateTenantForm: React.FC<Props> = (props: Props): JSX.Element => {
  if(!props.isVisible) return null

  const { t } = useTranslation(["shared"])
  const user: IUser = useSelector(getCurrentUser)

  if(!user.abilities?.tenant.canCreate) return <h3 className="text-lg">{ t("shared:notPermitted") }</h3>

  const dispatch = useDispatch()
  const createTenant = (tenant: ITenantNewData) => dispatch(createTenantAsync(tenant))

  const initialValues: ITenantNewData = {
    name: ""
  }

  return (
    <div>
      <h4 className="mb-2 font-semibold">
        {t("tenant:newTenant")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={tenantNewValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          createTenant(values as ITenantNewData)
          setSubmitting(false)
          props.closeAction()
        }}
      >
        {({
          isSubmitting,
          errors,
          values,
          touched,
          handleChange,
          handleBlur
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />
              <div className="mb-6">
                <Label name="name" required={true}>
                  {t("tenant:details:name")}
                </Label>
                <InputField
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="off"
                />
                <FieldError errorMessage={errors.name as string} isVisible={(errors.name && touched.name) as boolean} />
              </div>

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("tenant:buttons:createTenant")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateTenantForm
