import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import UserListItem from "./UserListItem"
import { getAllUserIds } from "../../../../shared/selectors/user"

const UserList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["user"])
  const userIds = useSelector(getAllUserIds)

  if (!userIds.length) return <h3 className="text-lg">{t("user:noUsers")}</h3>

  return (
    <ul>
      {userIds.map((userId: string) => {
        return (
          <UserListItem
            key={userId}
            userId={userId}
          />
        )
      })}
    </ul>
  )
}

export default UserList
