import React from "react"

interface IPropsFromParent {
  buttons: JSX.Element[]
  className?: string
}

type IProps = IPropsFromParent

const DetailsCardButtons: React.FC<IProps> = (props: IProps): JSX.Element => {
  if(!props.buttons) return null

  const defaultClasses = [
    "flex",
    "flex-wrap",
    "flex-1",
    "justify-center",
    "lg:justify-end"
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <ul className={allClasses}>
      {props.buttons.map((button: JSX.Element, index: number) => {
        return (
          <li className="ml-2 mb-2" key={index}>
            { button }
          </li>
          )
      })}
    </ul>
  )
}

export default DetailsCardButtons
