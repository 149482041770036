import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ButtonLink from "../../../../shared/components/buttons/ButtonLink"
import { IconSwitchTenant } from "../../../../svg/icons"
import SwitchTenantModal from "./SwitchTenantModal"
import { QueryStringContext } from "../../../../shared/contexts/QueryStringContextProvider"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"
import { useDispatch } from "react-redux"
import { setTenantAction } from "../../../user/state/userActions"
import { ExternalUsersContext } from "../../../../shared/contexts/ExternalUsersContextProvider"

const SwitchTenantButton: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["tenant"])
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { tenantId: queryTenantId, resetQuery } = useContext(QueryStringContext)
  const currentUser: IUser = useSelector(getCurrentUser)
  const {
    totalTenantCount
  } = useContext(ExternalUsersContext)

  const closeModal = () => setIsModalOpen(false)
  const openModal = () => setIsModalOpen(true)

  useEffect(() => {
    // when tenantId is passed in query string - switch tenant
    if(
      currentUser.id &&
      queryTenantId &&
      currentUser.currentTenantId !== queryTenantId
    ) {
      dispatch(setTenantAction(queryTenantId))
      resetQuery()
    }
  }, [queryTenantId, currentUser.id])

  const renderSwitchTenantButton = (): JSX.Element => {
    if (totalTenantCount < 2 && !currentUser.abilities?.tenant.canCreate) return null

    return (
      <ButtonLink action={openModal} className="normal-case" variant="ch-gray-alt">
        <IconSwitchTenant className="w-6 h-6 mr-3" /> {t("tenant:switchTenant")}
      </ButtonLink>
    )
  }

  return (
    <div className="flex items-center">
      {renderSwitchTenantButton()}
      <SwitchTenantModal isOpen={isModalOpen} closeModal={closeModal} />
    </div >
  )
}

export default SwitchTenantButton
