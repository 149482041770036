import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import IconFiles from "../../../../svg/icons/IconFiles"
import { patientsUploadValidation } from "../../state/validation/patientsUploadValidation"
import Label from "../../../../shared/components/forms/Label"
import FileUploadField from "../../../../shared/components/forms/FileUploadField"
import FieldError from "../../../../shared/components/forms/FieldError"
import Buttons from "../../../../shared/components/layout/Buttons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { fileUploadOptions } from "../../../../shared/helpers/fileHelpers"
import { uploadPatientsCSVAsync } from "../../state/patientsActions"

interface IProps {
  closeAction: () => void
}

const PatientsUploadForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "asset"])
  const dispatch = useDispatch()

  const handleSubmit = (file, { setSubmitting }) => {
    dispatch(uploadPatientsCSVAsync(file))
    setSubmitting(false)
    props.closeAction()
  }

  const initialValues = {
    file: null
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconFiles className="w-6 h-6 mr-2 text-ch-purple-500" title={t("asset:uploadFile")} />
        {t("asset:uploadFile")}
      </h4>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={patientsUploadValidation}
      >
        {({ errors, setFieldValue, isSubmitting, values, isValid }) => (
          <Form>
            <div>
              <div className="mb-2">
                <Label name="file">
                  {t("asset:selectFile")}:
                </Label>
                <FileUploadField
                  name="file"
                  acceptedFileTypes={fileUploadOptions.acceptedCSVTypes.join(", ")}
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0])
                  }}
                />
                <FieldError
                  errorMessage={errors.file as string}
                  isVisible={errors.file != null}
                />
              </div>
              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    key="1"
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    key="2"
                    isDisabled={values.file == null || !isValid || isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                  >
                    {t("asset:uploadFile")}
                  </Button>
                ]}
              />
            </div>
          </Form>
        )
        }
      </Formik >
    </div >
  )
}


export default PatientsUploadForm
