import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import useIsMobileView from "../../../../shared/hooks/useIsMobileView"
import { StyleVariants } from "../../../../shared/state/sharedTypes"

interface Props {
  setSelectedChatId: (selectedChatId: string) => void
  selectedChatId: string
  setIsNewChatFormOpen: (isNewChatFormOpen: boolean) => void
}

const NewMessageButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const isMobileView = useIsMobileView(["sm", "md"])

  const onClick = () => {
    props.setIsNewChatFormOpen(true)
    props.setSelectedChatId(null)
  }

  return (
    <div className="flex justify-center">
      <Button
        isDisabled={
          props.selectedChatId == null &&
          !isMobileView
        }
        action={onClick}
        variant={StyleVariants.ORANGE}
      >
        {t("message:index:buttons:newMessage")}
      </Button>
    </div>
  )
}

export default NewMessageButton
