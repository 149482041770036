import { ISearchQuery } from "../../../shared/hooks/useSearch"

export default interface ITeamsUIState {
  totalItems: number
  totalPages: number
  appliedFilters: string[]
  resultsPerPage: number
  searchTerm: string
  orderBy: string
}

export interface IFetchTeamsAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IFetchAllTeamsAsyncAction {
  type: string
}

export enum TeamsOrderByOptions {
  UpdatedDesc = "updated_at_desc",
  UpdatedAsc = "updated_at_asc",
  NameAsc = "name_asc",
  NameDesc = "name_desc",
}

// Action Types
export enum TeamsActionTypes {
  FETCH_TEAMS_ASYNC_PENDING = "@@teams/FETCH_TEAMS_ASYNC_PENDING",
  FETCH_TEAMS_ASYNC_SUCCESS = "@@teams/FETCH_TEAMS_ASYNC_SUCCESS",
  FETCH_TEAMS_ASYNC_ERROR = "@@teams/FETCH_TEAMS_ASYNC_ERROR",

  FETCH_ALL_TEAMS_ASYNC_PENDING = "@@teams/FETCH_ALL_TEAMS_ASYNC_PENDING",
  FETCH_ALL_TEAMS_ASYNC_SUCCESS = "@@teams/FETCH_ALL_TEAMS_ASYNC_SUCCESS",
  FETCH_ALL_TEAMS_ASYNC_ERROR = "@@teams/FETCH_ALL_TEAMS_ASYNC_ERROR",
}
