import React, { useEffect, useState } from "react"
import { IconPdf, IconSelectCross } from "../../../../svg/icons"

interface Props {
  file: File
  removeItem: (file: File) => void
}

const MessageAssetsFieldListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const [imgSrc, setImgSrc] = useState(null)

  if(!props.file) return null

  useEffect(() => {
    if(props.file.type == "application/pdf") return
    const url = URL.createObjectURL(props.file)
    setImgSrc(url)
  }, [props.file])

  const renderThumb = (): JSX.Element => {
    if(props.file.type == "application/pdf") {
      return (
        <div className="text-ch-blue-alt-400 mr-1">
          <IconPdf className="w-5 h-5" />
        </div>
      )
    }
    return (
      <div className="flex w-5 h-5 items-center justify-center mr-1 overflow-hidden">
        <img src={imgSrc} />
      </div>
    )
  }

  return (
    <div
      className="flex py-0.5 pl-2 rounded-md border border-ch-gray-400 items-center"
    >
      {renderThumb()}
      <span className="text-xs">
        {props.file.name}
      </span>
      <a
        className="text-ch-gray-500 hover:text-ch-red-500 cursor-pointer p-1"
        onClick={() => props.removeItem(props.file)}
      >
        <IconSelectCross className="w-3 h-3 fill-current mr-1"  />
      </a>
    </div>
  )
}

export default MessageAssetsFieldListItem
