import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import TeamListItem from "./TeamListItem"
import { getAllTeamIds } from "../../../../shared/selectors/team"

const TeamList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["team"])
  const teamIds = useSelector(getAllTeamIds)
  if (!teamIds.length)
    return <h3 className="text-lg">{t("team:noTeams")}</h3>

  return (
    <ul>
      {teamIds.map((teamId: string) => {
        return <TeamListItem key={teamId} teamId={teamId} />
      })}
    </ul>
  )
}

export default TeamList
