import { AnyAction, combineReducers, Reducer } from "redux"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"
import { ChatCableActionTypes } from "../../../chat/state/chatTypes"
import IChatMessagesUIState, { ChatMessagesActionTypes } from "../messagesTypes"

export const totalItems: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
      return action.payload.totalItems
    case ChatCableActionTypes.DELIVER_MESSAGE:
      return state + 1
    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
      return action.payload.totalPages

    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
      return action.payload.resultsPerPage

    default:
      return state
  }
}

export const isLoading: Reducer<boolean> = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_SUCCESS:
    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_ERROR:
      return false

    case ChatMessagesActionTypes.FETCH_CHAT_MESSAGES_ASYNC_PENDING:
      return true

    default:
      return state
  }
}

export const unreadCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case ChatCableActionTypes.UPDATE_TOTAL_UNREAD_COUNT:
      return action.payload.unreadCount

    default:
      return state
  }
}

export const notificationMessageId: Reducer<string> = (state = null, action: AnyAction): string => {
  switch (action.type) {
    case ChatCableActionTypes.DELIVER_MESSAGE:
      return action.payload.message.id

    case ChatMessagesActionTypes.UPDATE_NOTIFICATION_MESSAGE:
      return action.payload

    default:
      return state
  }
}

export const messagesUIReducer: Reducer<IChatMessagesUIState> = combineReducers({
  totalItems,
  totalPages,
  resultsPerPage,
  isLoading,
  unreadCount,
  notificationMessageId
})
