import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconRecommendation({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M18.5 2h-13C3.6 2 2 3.6 2 5.5v13C2 20.4 3.6 22 5.5 22H16l6-6V5.5C22 3.6 20.4 2 18.5 2M7 12.5l1.3-1.4 2.1 2.1L15.6 8 17 9.4 10.5 16 7 12.5m8 7.5v-1.5c0-1.9 1.6-3.5 3.5-3.5H20l-5 5z"
        />
      </g>
    </svg>
  );
}

export default SvgIconRecommendation;
