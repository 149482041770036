import React from "react"
import { useTranslation } from "react-i18next"
import ButtonFilter from "../../../../shared/components/buttons/ButtonFilter"
import { ISearchQuery, ISetSearchQueryFunction } from "../../../../shared/hooks/useSearch"

interface Props {
  searchQuery: ISearchQuery,
  setSearchQuery: ISetSearchQueryFunction
}

const TeamFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["team"])
  const checkAllIsOn = () => {
    return props.searchQuery.appliedFilters.length == 0
  }

  const checkIsOn = (filter) => {
    return props.searchQuery.appliedFilters.includes(filter)
  }

  const resetFilters = () => {
    if (!checkAllIsOn()) {
      props.setSearchQuery({ appliedFilters: [] })
    }
  }

  const updateFilters = (filter) => {
    const isOn = checkIsOn(filter)
    if (isOn) {
      props.setSearchQuery({
        appliedFilters: props.searchQuery.appliedFilters.filter(i => i !== filter)
      })
    } else {
      props.setSearchQuery({
        appliedFilters: [...props.searchQuery.appliedFilters, filter]
      })
    }
  }

  return (
    <ul className="flex flex-wrap">
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("isMember")}
          action={() => {
            updateFilters("isMember")
          }}
        >
          {t("team:youAreAMember")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkIsOn("isOwner")}
          action={() => {
            updateFilters("isOwner")
          }}
        >
          {t("team:youAreTheOwner")}
        </ButtonFilter>
      </li>
      <li className="mb-2 mr-2">
        <ButtonFilter
          isOn={checkAllIsOn()}
          action={() => {
            resetFilters()
          }}
        >
          {t("team:all")}
        </ButtonFilter>
      </li>
    </ul>
  )
}

export default TeamFilter
