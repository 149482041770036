import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { RoutePaths } from "../../app/routes/Routes"
import { useSelector } from "react-redux"
import { getCurrentUser } from "../../shared/selectors/user"
import { IUser } from "../../features/user/state/userTypes"

const SetupTotpCompletePage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["account"])
  const currentUser: IUser = useSelector(getCurrentUser)

  const renderLinkToSetupSMS = () => {
    if (currentUser?.mfaMethods.includes("sms")) return null
    return (<div className="mb-5">
      Click <a className="text-ch-blue-500 hover:text-ch-blue-600" href={RoutePaths.SETUP_MFA_INTRO}>here</a> if you wish to set up SMS based 2FA as well.
    </div>)

  }

  return (
    <div className="flex flex-col items-center">
      <div className="text-ch-blue-500 text-xl mb-3">
        {t("mfaSetupCompleteHeader")}
      </div>
      <div className="mb-5">
        {t("mfaSetupTotpCompleteMessage")}
      </div>
      {renderLinkToSetupSMS()}
      <Button variant={StyleVariants.BLUE} href={RoutePaths.ROOT}>{t("mfaContinueToApp")}</Button>
    </div >
  )
}

export default SetupTotpCompletePage
