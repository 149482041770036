import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAlert({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 21 21"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M12.289 3.578l7.264 14.528A2 2 0 0117.764 21H3.236a2 2 0 01-1.789-2.894L8.711 3.578a2 2 0 013.578 0zM10.3 16.488c.409 0 .725.109.949.325.225.217.337.527.337.931 0 .389-.114.696-.34.92-.227.224-.542.336-.946.336-.413 0-.734-.11-.96-.329-.227-.219-.34-.528-.34-.927 0-.414.111-.726.332-.938.222-.212.545-.318.968-.318zM11.586 8l-.377 7.218H9.406L9.03 8h2.556z"
      />
    </svg>
  );
}

export default SvgIconAlert;
