import React from "react"
import ChatTitle from "../chatTitle/ChatTitle"
import ChatIcon from "./ChatIcon"
import ChatCaseDetails from "./ChatCaseDetails"
import ChatClosedText from "../ChatClosedText"
import ChatMenu from "./ChatMenu"
import ChatMutedText from "../ChatMutedText"

interface Props {
  chatId: string
}

const ChatHeader: React.FC<Props> = (props: Props): JSX.Element => {
  if(!props.chatId) return null

  return (
    <div>
      <div className="flex mb-2 overflow-hidden">
        <ChatIcon chatId={props.chatId} />
        <div className="flex-1 overflow-hidden mx-2">
          <ChatTitle chatId={props.chatId} />
        </div>
        <div className="flex self-center">
          <ChatClosedText chatId={props.chatId} />
          <ChatMutedText chatId={props.chatId} />
          <ChatMenu chatId={props.chatId} />
        </div>
      </div>
      <ChatCaseDetails
        chatId={props.chatId}
      />
    </div>
  )
}

export default ChatHeader
