import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"
import { ICase, ICaseMemberStatusByUserId } from "../../state/caseTypes"
import { getCaseById, getCaseMembersStatus } from "../../../../shared/selectors/case"
import RemoveUserButton from "../../../../shared/components/buttons/RemoveUserButton"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"
import { updateCaseMembers } from "../../state/caseActions"
import ShareAssetsButton from "../../../caseSharedAssets/components/ShareAssetsButton"
import { isGuest } from "../../../../shared/helpers/userHelpers"
import WarningBanner from "../../../../shared/components/text/WarningBanner"

interface IPropsFromParent {
  store?: MockStore
  userId: string
  caseId: string
  onClickShareAssets: (userId: string) => void
}

const CaseMemberListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["case", "shared"])
  const [buttonClicked, setButtonClicked] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state: IAppState): IUser => getUserByHasUserId(state, props))
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, props))
  const caseMembersStatus = useSelector((state: IAppState): ICaseMemberStatusByUserId => getCaseMembersStatus(state, props))

  const renderLead = (): JSX.Element => {
    if (theCase.leadCareUser == props.userId) {
      return (
        <span className="flex uppercase font-thin text-ch-green-300 items-center" >
          <span className="text-ch-gray-600">
            {t("case:leadCare")}
          </span>
        </span>
      )
    }
  }

  const removeMemberFromCase = () => {
    setButtonClicked(true)
    const data = {
      ...theCase,
      memberIds: theCase.members.filter(user => user !== props.userId),
    }
    dispatch(updateCaseMembers(data))
  }

  const renderNoAssetsSharedWarning = () => {
    if(
      !isGuest(user) ||
      caseMembersStatus[props.userId]?.sharedAssetCount > 0
    ) return

    return(
      <div className="basis-full w-full mt-2">
        <WarningBanner>
          <span>{t("case:guestUsers:text:noAssetsShared")}</span>
        </WarningBanner>
      </div>
    )
  }

  if (!user || !theCase) return null

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner className="flex-wrap">
          <UserDetailsCard user={user} />
          {renderNoAssetsSharedWarning()}
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[
              <ShareAssetsButton
                action={props.onClickShareAssets}
                canShareAssets={
                  theCase.permissions.canShareAsset &&
                  isGuest(user)
                }
                key={1}
                userId={props.userId}
                caseId={props.caseId}
              />,
              renderLead() ||
              <RemoveUserButton
                key={2}
                action={removeMemberFromCase}
                label={t("shared:remove")}
                canRemove={theCase.permissions.canInvite && theCase.leadCareUser !== props.userId && !theCase.locked}
                submitting={buttonClicked}
              />
            ]}
          />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default CaseMemberListItem
