import { Form, Formik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../shared/components/forms/FieldError"
import InputField from "../../../../shared/components/forms/InputField"
import Label from "../../../../shared/components/forms/Label"
import SelectField from "../../../../shared/components/forms/SelectField"
import Card from "../../../../shared/components/layout/Card"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import WizardForceUpdate from "../../../../shared/components/ui/wizard/WizardForceUpdate"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { getDayOptions, getMonthOptions, getYearOptions, valuesToDateOfBirth } from "../../../../shared/helpers/timeHelpers"
import { getPatientNewUI } from "../../../../shared/selectors/ui"
import { IconAlert } from "../../../../svg/icons"
import { patientCheckValidation } from "../../../patient/state/validation/patientCheckValidation"
import PatientListItem from "../../../patients/components/list/PatientListItem"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { checkPatientAction, resetCheckPatientAction } from "../../state/patientNewActions"
import { IPatientCheckData, IPatientNewData, IPatientNewUIState, PatientNewCheckDetailsStatuses } from "../../state/patientNewTypes"

interface IProps {
  goToNextStep: () => void
  data: IPatientNewData
  setData: (data: IPatientNewData) => void
}

const PatientCheckForm: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef, forceUpdate } = useContext(FormContext)
  const { careProviderIdLabel } = useContext(TenantConfigContext)
  const [hasCheckedPatient, setHasCheckedPatient] = useState(false)
  const patientNewUIState: IPatientNewUIState = useSelector((state: IAppState) => getPatientNewUI(state))

  const existingPatientIds = patientNewUIState?.checkDetails?.existingPatientIds
  const status = patientNewUIState?.checkDetails?.status
  const dispatch = useDispatch()
  const checkPatient = (patientCheckData: IPatientCheckData) => dispatch(checkPatientAction(patientCheckData))
  const resetCheckPatient = () => dispatch(resetCheckPatientAction())

  const initialDateOfBirth: Date = new Date(props.data.dateOfBirth)

  const intialDobValues = {
    dobDay: initialDateOfBirth.getDate().toString(),
    dobMonth: (initialDateOfBirth.getMonth() + 1).toString(),
    dobYear: initialDateOfBirth.getFullYear().toString(),
  }

  const initialValues = {
    lastName: props.data.lastName,
    dateOfBirth: props.data.dateOfBirth,
    careProviderId: props.data.careProviderId,

    ...(props.data.dateOfBirth && intialDobValues)
  }

  useEffect(() => {
    resetCheckPatient()

    return () => {
      resetCheckPatient()
    }
  }, [])

  useEffect(() => {
    const isNewPatient: boolean = status == PatientNewCheckDetailsStatuses.Not_Found

    if (isNewPatient) {
      props.goToNextStep()
    }
  }, [status])

  const renderExistingPatient = (): JSX.Element => {
    if (!status) return null
    if (status == PatientNewCheckDetailsStatuses.Pending) return <LoadingSpinner />
    if (!existingPatientIds?.length) return null

    return (
      <div className="mt-10">
        <h3 className="flex font-semibold text-ch-blue-alt-500 mb-2">
          <IconAlert className="w-5 h-5 mr-2" /> <span>{t("patient:checkDetails:messages:existingPatientsFound")}</span>
        </h3>
        <p className="mb-2">
          {t("patient:checkDetails:existingPatientInfo")}
        </p>
        <ul>
          {existingPatientIds.map((patientId: string) => {
            return <PatientListItem key={patientId} patientId={patientId} />
          })}
        </ul>
      </div>
    )
  }

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full lg:w-2/3">
        <h4 className="flex mb-2 font-semibold">
          {t("patient:checkDetails:sectionTitle")}
        </h4>
        <ol className="mb-2 list-decimal list-inside">
          <li className="mb-2">{t("patient:checkDetails:intro1")}</li>
          <li className="mb-2">{t("patient:checkDetails:intro2")}</li>
          <li className="mb-2">{t("patient:checkDetails:intro3")}</li>
        </ol>

        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={patientCheckValidation}
          onSubmit={(values, { setSubmitting }) => {
            if (hasCheckedPatient) return props.goToNextStep()

            const dateOfBirth = valuesToDateOfBirth(values.dobDay, values.dobMonth, values.dobYear)

            const data = {
              lastName: values.lastName,
              dateOfBirth: dateOfBirth,
              careProviderId: values.careProviderId
            } as IPatientCheckData

            props.setData({
              ...props.data,
              ...data
            })

            checkPatient(data)
            setHasCheckedPatient(true)
            setSubmitting(false)
            forceUpdate()
          }}
          innerRef={formikFormRef}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur
          }) => {

            const _handleChange = (e) => {
              handleChange(e)
              resetCheckPatient()
              setHasCheckedPatient(false)
            }

            return (
              <Form>
                <WizardForceUpdate />
                <DenotesRequiredMessage />

                <div className="mb-2">
                  <Label name="lastName" required={true}>
                    {t("patient:details:lastName")}
                  </Label>
                  <InputField
                    type="text"
                    name="lastName"
                    onChange={_handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.lastName as string} isVisible={(errors.lastName && touched.lastName) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="dateOfBirth" required={true}>
                    {t("patient:details:dateOfBirth")}
                  </Label>

                  <div className="lg:flex lg:w-2/3">
                    <div className="lg:w-1/4 mb-2 lg:mb-0">
                      <SelectField
                        name="dobDay"
                        options={getDayOptions()}
                        onChange={_handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthDay")}
                      />
                    </div>

                    <div className="lg:w-1/2 mb-2 lg:mb-0 lg:ml-2">
                      <SelectField
                        name="dobMonth"
                        options={getMonthOptions(t)}
                        onChange={_handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthMonth")}
                      />
                    </div>

                    <div className="lg:w-1/4 lg:ml-2">
                      <SelectField
                        name="dobYear"
                        options={getYearOptions()}
                        onChange={_handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthYear")}
                      />
                    </div>
                  </div>

                  <FieldError
                    errorMessage={errors.dateOfBirth as string}
                    isVisible={errors.dateOfBirth && touched.dateOfBirth as boolean}
                  />
                </div>

                <div className="mb-2">
                  <Label name="careProviderId">
                    <span>{careProviderIdLabel}</span>
                  </Label>
                  <InputField
                    type="text"
                    name="careProviderId"
                    onChange={_handleChange}
                    onBlur={handleBlur}
                    value={values.careProviderId}
                    autoComplete="off"
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>

      {renderExistingPatient()}
    </Card>
  )
}

export default PatientCheckForm
