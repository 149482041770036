import React, { useEffect, useState } from "react"

export interface ContextProps {
  email?: string
  tenantId?: string
  resetQuery: () => void
}

export const QueryStringContext = React.createContext<Partial<ContextProps>>({})

QueryStringContext.displayName = "QueryStringContext"

interface Props {
  children?: React.ReactNode
}

export const QueryStringContextProvider: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = useState<string>("")
  const [tenantId, setTenantId] = useState<string>(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const emailValue = urlParams.get("email")
    if(emailValue) setEmail(emailValue)
    const tenantIdValue = urlParams.get("tenantId")
    if(tenantIdValue) setTenantId(tenantIdValue)
  }, [window.location.search])

  const resetQuery = () => {
    setEmail("")
    setTenantId(null)
  }

  return (
    <QueryStringContext.Provider
      value={{
        email,
        tenantId,
        resetQuery
      }}
    >
      {props.children}
    </QueryStringContext.Provider>
  )
}
