import { Form, Formik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"
import { IAppState } from "../../../app/appTypes"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ICase, ICaseUpdateData } from "../../case/state/caseTypes"
import { updateCase } from "../../case/state/caseActions"
import DenotesRequiredMessage from "../../../shared/components/forms/DenotesRequiredMessage"
import { caseUpdateValidationSchema } from "../state/caseUpdateValidation"
import { getCaseById } from "../../../shared/selectors/case"
import CaseFields from "../../case/components/forms/CaseFields"
import _ from "lodash"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import Card from "../../../shared/components/layout/Card"

interface IProps {
  caseId: string
}

const CaseEditForm: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const { signedOffByRequired } = useContext(
    TenantConfigContext
  )
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, props))
  const dispatch = useDispatch()

  const initialValues = {
    caseType: theCase.caseType,
    caseClassificationId: theCase.caseClassificationId,
    leadCareUserId: theCase.leadCareUser,
    isSignedOffByRequired: signedOffByRequired,
    signedOffBy: theCase.signedOffBy || "",
    targetDate: theCase.targetDate ? new Date(theCase.targetDate) : undefined,
    targetDateFulfilled: theCase.targetDateFulfilled || false,
    sourceId: theCase.sourceId
  }

  return (
    <Card className="flex justify-left lg:pl-4 sm:pl-0">
      <Formik
        initialValues={initialValues}
        validationSchema={caseUpdateValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const targetDate = values.targetDate ? formatISO(values.targetDate) : null

          const data = {
            id: theCase.id,
            caseType: values.caseType,
            caseClassificationId: values.caseClassificationId,
            leadCareUserId: values.leadCareUserId,
            signedOffBy: values.signedOffBy,
            targetDate: targetDate,
            targetDateFulfilled: values.targetDateFulfilled,
            sourceId: values.sourceId
          } as ICaseUpdateData


          dispatch(updateCase(data))
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty
        }) => (
          <Form className="w-full lg:w-2/3">
            <DenotesRequiredMessage />
            <CaseFields
              errors={errors}
              touched={touched}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              signedOffByRequired={signedOffByRequired}
              includeTargetDateFulfilledField={true}
            />

            <div className="flex justify-center">
              <Button
                isDisabled={!dirty || isSubmitting}
                variant={StyleVariants.PURPLE}
                isSubmit={true}
              >
                {t("case:updateCase")}
              </Button>
            </div>
          </Form>
        )
        }
      </Formik >
    </Card >
  )
}

export default CaseEditForm
