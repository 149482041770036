import React from "react"

interface Props {
  isLeft?: boolean
}

const SpeechBubbleArrow: React.FC<Props> = (props: Props): JSX.Element => {
  const defaultStyle: React.CSSProperties = {
    position: "absolute",
    border: "7px solid transparent"
  }
  const rightStyle = {
    right: "-7px",
    borderLeftColor: "#006F74",
    borderRight: 0
  }
  const leftStyle = {
    left: "-7px",
    borderRightColor: "#E0E0E0",
    borderLeft: 0
  }

  const style = {
    ...defaultStyle,
    ...(props.isLeft ? leftStyle : rightStyle)
  }

  return (
    <div
      style={style}
    ></div>
  )
}

export default SpeechBubbleArrow
