import classNames from "classnames"
import { throttle } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import useOnScreen from "../../../../shared/hooks/useOnScreen"
import { getMessageById } from "../../../../shared/selectors/message"
import { getCurrentUser } from "../../../../shared/selectors/user"
import { IconMessageRead } from "../../../../svg/icons"
import { markMessageAsRead } from "../../../message/state/messageActions"
import { IMessage } from "../../../message/state/messageTypes"
import { IUser } from "../../../user/state/userTypes"
import MessageAssetList from "../../../messageAssets/components/list/MessageAssetList"
import SpeechBubbleArrow from "./SpeechBubbleArrow"

interface Props {
  messageId: string
}

const MessageListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const message: IMessage = useSelector((state: IAppState) => getMessageById(state, props))
  const currentUser: IUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const [showReadBy, setShowReadBy] = useState(false)

  if(!message) return null

  const throttledMarkAsRead = throttle((messageId: string) => dispatch(markMessageAsRead(messageId)), 1000, { trailing: true })

  useEffect(() => {
    if(isVisible && !message.readAt) {
      throttledMarkAsRead(message.id)
    }
  }, [isVisible])

  const isCurrentUser = currentUser.id == message.createdBy.id

  const renderName = (): JSX.Element => {
    if(isCurrentUser) return t("message:index:you")

    return <NameUser
      firstName={message.createdBy.firstName}
      lastName={message.createdBy.lastName}
    />
  }

  const renderReadIcon = (): JSX.Element => {
    if(!isCurrentUser) return null
    if(message.readBy.length <= 1) return null

    return (
      <a className="cursor-pointer" onClick={() => setShowReadBy(!showReadBy)}>
        <IconMessageRead className="text-ch-blue-alt-500 w-4 h-4 mr-2" />
      </a>
    )
  }

  const renderReadBy = (): JSX.Element => {
    if(!showReadBy) return null
    return (
      <div className="text-xs italic text-ch-gray-500 mb-1">
        {t("message:index:seenBy")}:
        {message.readBy.map(r => <NameUser
          className="ml-1"
          key={r.id}
          firstName={r.firstName}
          lastName={r.lastName}
        />)}
      </div>
    )
  }

  const renderMessage = (): JSX.Element => {
    return (
      <div className={classNames(
        "px-4",
        "py-3",
        "rounded-md",
        "text-xs",
        "relative",
        {
          "bg-ch-gray-400": !isCurrentUser,
          "text-white": isCurrentUser,
          "bg-ch-blue-alt-500": isCurrentUser
        }
      )}>
        <SpeechBubbleArrow isLeft={!isCurrentUser} />
        <MessageAssetList messageAssetIds={message.messageAssets} />
        <span>
          <MentionFieldReadOnly
            value={message.body}
            users={null}
            textColorClass={isCurrentUser ? "text-white" : "text-ch-gray-600"}
          />
        </span>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={classNames(
        "flex",
        "flex-col",
        "max-w-md",
        "mb-3",
        {
          "ml-auto": isCurrentUser,
        }
      )}
    >
      <div
        className={classNames(
          "text-xs",
          "mb-1",
          {
            "text-right": isCurrentUser,
          }
        )}
      >
        {renderName()}
      </div>


      <div className={classNames(
        "flex",
        "mb-1",
        "items-center",
        {
          "justify-end": isCurrentUser,
          "mr-1": isCurrentUser,
          "ml-1": !isCurrentUser,
        }
      )}>
        {renderReadIcon()}
        {renderMessage()}
      </div>

      {renderReadBy()}

      <TimeDefaultFormat
        utcTime={message.createdAt}
        className={classNames(
          "text-xs",
          "italic",
          {
            "text-right": isCurrentUser,
          }
        )}
      />
    </div>
  )
}

export default MessageListItem
