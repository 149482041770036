import { ChatNewActionTypes, IChatNewData, ICreateChatAsyncAction } from "./chatNewTypes"

export const createChatAsync = (chat: IChatNewData): ICreateChatAsyncAction => {
  return {
    type: ChatNewActionTypes.CREATE_CHAT_ASYNC_PENDING,
    payload: {
      chat
    }
  }
}
