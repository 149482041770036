import { Store, legacy_createStore as createStore, applyMiddleware, CombinedState, AnyAction } from "redux"
import createSagaMiddleware from "redux-saga"
import dynamicMiddlewares from "redux-dynamic-middlewares"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import appReducer from "./appReducer"
import appSaga from "./appSaga"
import { IAppState } from "../appTypes"
import { loadSession } from "./appSessionStorage"
import { AccountActionTypes } from "../../features/account/state/accountTypes"

const persistedState = loadSession()
const initialState: Record<string, unknown> = { ...persistedState }

export const sagaMiddleware = createSagaMiddleware()

const rootReducer = (state: CombinedState<IAppState>, action: AnyAction) => {
  if (action?.type == AccountActionTypes.SIGN_OUT_USER_ASYNC_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "ui",
    "liveMeetingConfig"
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store: Store<IAppState> = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(
      sagaMiddleware,
      dynamicMiddlewares
    )
  ),
)

export const persistor = persistStore(store)

sagaMiddleware.run(appSaga)
