import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconAttachment({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M24.558 4.474c-1.665-1.47-4.367-1.47-6.031 0L5.974 15.55c-2.872 2.535-2.872 6.64 0 9.175 2.872 2.535 7.525 2.535 10.397 0l10.37-9.15c.743-.657 1.958-.657 2.701 0 .744.655.744 1.727 0 2.383l-10.37 9.151c-4.366 3.853-11.434 3.853-15.8 0-4.363-3.853-4.363-10.09 0-13.943L15.825 2.09c3.159-2.787 8.276-2.787 11.434 0 3.159 2.786 3.159 7.302 0 10.09L15.252 22.776c-1.951 1.721-5.117 1.721-7.068 0-1.952-1.722-1.952-4.516 0-6.238l9.824-8.669c.744-.656 1.958-.656 2.702 0 .743.656.743 1.728 0 2.384l-9.824 8.67a.958.958 0 000 1.469 1.296 1.296 0 001.664 0L24.558 9.796c1.664-1.47 1.664-3.853 0-5.322z"
      />
    </svg>
  );
}

export default SvgIconAttachment;
