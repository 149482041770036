import { Form, Formik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import FieldError from "../../../../shared/components/forms/FieldError"
import InputField from "../../../../shared/components/forms/InputField"
import Label from "../../../../shared/components/forms/Label"
import SelectField from "../../../../shared/components/forms/SelectField"
import { TSelectFieldOptions } from "../../../../shared/components/forms/StandardSelectField"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import { getDayOptions, getMonthOptions, getYearOptions, valuesToDateOfBirth } from "../../../../shared/helpers/timeHelpers"
import { PatientGenders, PatientTitles } from "../../../patient/state/patientTypes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { patientDetailsValidation } from "../../../patient/state/validation/patientDetailsValidation"
import { IPatientDetailsData, IPatientNewData } from "../../state/patientNewTypes"
import Card from "../../../../shared/components/layout/Card"
import WizardForceUpdate from "../../../../shared/components/ui/wizard/WizardForceUpdate"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientNewData
  setData: (data: IPatientNewData) => void
}

type Props = IPropsFromParent

const PatientDetailsForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)
  const { healthcareIdLabel, careProviderIdLabel, healthcareIdRequired, careProviderIdRequired } = useContext(
    TenantConfigContext
  )

  const getGenderOptions = (): TSelectFieldOptions => {
    return Object.keys(PatientGenders).map((key) => {
      const translationKey = humps.camelize(PatientGenders[key])
      return {
        value: PatientGenders[key],
        label: t(`shared:${translationKey}`)
      }
    })
  }

  const getTitleOptions = (): TSelectFieldOptions => {
    return Object.keys(PatientTitles).map((key) => {
      const translationKey = humps.camelize(PatientTitles[key])
      return {
        value: PatientTitles[key],
        label: t(`shared:${translationKey}`)
      }
    })
  }

  const initialDateOfBirth: Date = new Date(props.data.dateOfBirth)

  const initialValues = {
    isHealthcareIdRequired: healthcareIdRequired,
    isCareProviderIdRequired: careProviderIdRequired,

    title: props.data.title,
    firstName: props.data.firstName,
    lastName: props.data.lastName,
    dateOfBirth: initialDateOfBirth,
    gender: props.data.gender,
    healthcareId: props.data.healthcareId,
    careProviderId: props.data.careProviderId,

    dobDay: initialDateOfBirth.getDate().toString(),
    dobMonth: (initialDateOfBirth.getMonth() + 1).toString(),
    dobYear: initialDateOfBirth.getFullYear().toString(),
  }

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full lg:w-2/3">
        <h4 className="flex mb-2 font-semibold">
          { t("patient:details:sectionTitle") }
        </h4>
        <p className="mb-2">
          { t("patient:details:intro") }
        </p>

        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={patientDetailsValidation}
          validateOnChange={true}
          onSubmit={(values, { setSubmitting }) => {
            const dateOfBirth = valuesToDateOfBirth(values.dobDay, values.dobMonth, values.dobYear)

            const data = {
              title: values.title,
              firstName: values.firstName,
              lastName: values.lastName,
              dateOfBirth: dateOfBirth,
              gender: values.gender,
              healthcareId: values.healthcareId,
              careProviderId: values.careProviderId,
            } as IPatientDetailsData

            props.setData({
              ...props.data,
              ...data
            })

            setSubmitting(false)
            props.goToNextStep()
          }}
          innerRef={formikFormRef}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur
          }) => {
            return (
              <Form>
                <WizardForceUpdate />
                <DenotesRequiredMessage />

                <div className="mb-2 lg:w-1/4">
                  <Label name="title" required={true}>
                    {t("patient:details:title")}
                  </Label>

                  <SelectField
                    name="title"
                    options={getTitleOptions()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <FieldError
                    errorMessage={errors.title as string}
                    isVisible={errors.title && touched.title as boolean}
                  />
                </div>

                <div className="mb-2">
                  <Label name="lastName" required={true}>
                    {t("patient:details:lastName")}
                  </Label>
                  <InputField
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.lastName as string} isVisible={(errors.lastName && touched.lastName) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="firstName" required={true}>
                    {t("patient:details:firstName")}
                  </Label>
                  <InputField
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    autoComplete="off"
                  />
                  <FieldError errorMessage={errors.firstName as string} isVisible={(errors.firstName && touched.firstName) as boolean} />
                </div>

                <div className="mb-2">
                  <Label name="dateOfBirth" required={true}>
                    {t("patient:details:dateOfBirth")}
                  </Label>

                  <div className="lg:flex lg:w-2/3">
                    <div className="lg:w-1/4 mb-2 lg:mb-0">
                      <SelectField
                        name="dobDay"
                        options={getDayOptions()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthDay")}
                      />
                    </div>

                    <div className="lg:w-1/2 mb-2 lg:mb-0 lg:ml-2">
                      <SelectField
                        name="dobMonth"
                        options={getMonthOptions(t)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthMonth")}
                      />
                    </div>

                    <div className="lg:w-1/4 lg:ml-2">
                      <SelectField
                        name="dobYear"
                        options={getYearOptions()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("case:dateOfBirthYear")}
                      />
                    </div>
                  </div>

                  <FieldError
                    errorMessage={errors.dateOfBirth as string}
                    isVisible={errors.dateOfBirth && touched.dateOfBirth as boolean}
                  />
                </div>

                <div className="mb-4 lg:w-1/3">
                  <Label name="gender" required={true}>
                    {t("patient:details:gender")}
                  </Label>

                  <SelectField
                    name="gender"
                    options={getGenderOptions()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <FieldError
                    errorMessage={errors.gender as string}
                    isVisible={errors.gender && touched.gender as boolean}
                  />
                </div>

                <h3 className="mb-2 text-lg font-semibold">
                  {t("patient:details:identifiersTitle")}
                </h3>

                <div className="mb-2">
                  <Label name="healthcareId" required={healthcareIdRequired}>
                    <span>{healthcareIdLabel}</span>
                  </Label>
                  <InputField
                    type="text"
                    name="healthcareId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.healthcareId}
                  />
                  <FieldError
                    errorMessage={errors.healthcareId as string}
                    isVisible={errors.healthcareId && touched.healthcareId as boolean}
                  />
                </div>

                <div className="mb-2">
                  <Label name="careProviderId" required={careProviderIdRequired}>
                    <span>{careProviderIdLabel}</span>
                  </Label>
                  <InputField
                    type="text"
                    name="careProviderId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.careProviderId}
                  />
                  <FieldError
                    errorMessage={errors.careProviderId as string}
                    isVisible={errors.careProviderId && touched.careProviderId as boolean}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Card>
  )
}

export default PatientDetailsForm
