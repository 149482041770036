import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import Breadcrumbs from "../../../../shared/components/ui/Breadcrumbs"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import { smallModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconTeam } from "../../../../svg/icons"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { getTeamById } from "../../../../shared/selectors/team"
import Modal from "react-modal"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { generatePath } from "react-router"
import { deleteTeamAsync } from "../../state/teamActions"
import TeamDetails from "../../../teams/components/details/TeamDetails"

interface IProps {
  teamId: string
}

const TeamHeader: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation(["team"])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const team = useSelector((state: IAppState) => getTeamById(state, props.teamId))
  const dispatch = useDispatch()
  const deleteTeam = (teamId: string) => dispatch(deleteTeamAsync(teamId))

  if (!team) return null

  const openModal = (): void => { setIsOpen(true) }
  const closeModal = (): void => { setIsOpen(false) }

  const renderEditButton = (): JSX.Element => {
    if (team.permissions.canEdit) {
      const editTeamUrl = generatePath(RoutePaths.TEAM_EDIT, {
        teamId: props.teamId
      })
      return (
        <Button href={editTeamUrl} variant={StyleVariants.PURPLE} key="1">
          {t("team:editTeam")}
        </Button>
      )
    } else return null
  }

  const renderDeleteButton = (): JSX.Element => {
    if (team.permissions.canUninvite) {
      return (
        <Button
          action={() => openModal()}
          variant={StyleVariants.PURPLE}
          key="2"
        >
          {t("team:deleteTeam")}
        </Button>
      )
    } else return null
  }

  const onDeleteTeam = () => {
    deleteTeam(props.teamId)
    closeModal()
  }

  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:teams"),
              href: RoutePaths.TEAMS
            },
            {
              pageTitle: team.name
            }
          ]}
        />
      </div>

      <DetailsCard>
        <DetailsCardInner>
          <IconCircleContainer className="sm:mr-4">
            <IconTeam className="fill-current w-6 h-6" />
          </IconCircleContainer>
          <TeamDetails teamId={team.id} userId={team.owner} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            buttons={[renderDeleteButton(), renderEditButton()]}
          />
        </DetailsCardRight>
      </DetailsCard>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={smallModalStyle}
      >
        <ModalContainer handleClose={closeModal}>
          <h2 className="text-lg mb-3">{t("team:deleteTeamIntro")}</h2>
          <DetailsCard>
            <DetailsCardInner>
              <IconCircleContainer className="sm:mr-4">
                <IconTeam className="fill-current w-6 h-6" />
              </IconCircleContainer>
              <TeamDetails teamId={team.id} userId={team.owner} />
            </DetailsCardInner>
          </DetailsCard>

          <div className="mb-3">
            <p className="text-ch-blue-600">{t("team:deleteTeamDesc")}</p>
          </div>
          <div>
            <p>{t("team:continueDelete")}</p>
          </div>

          <div className="flex justify-center space-x-2">
            <Button
              action={() => closeModal()}
              variant={StyleVariants.BLUE_ALT}
              key="3"
            >
              {t("shared:cancel")}
            </Button>
            <Button
              action={() => onDeleteTeam()}
              variant={StyleVariants.PURPLE}
              key="4"
            >
              {t("team:deleteTeam")}
            </Button>
          </div>
        </ModalContainer>
      </Modal>
    </div>
  )
}

export default TeamHeader
