import React, { Dispatch, SetStateAction } from "react"
import IconEye from "../../../../images/svg/icons/icon-eye.svg"
import IconEyeSlash from "../../../../images/svg/icons/icon-eye-slash.svg"
import { ColorPalette } from "../../../shared/state/sharedTypes"

interface Props {
  showPassword: boolean
  setShowPassword: Dispatch<SetStateAction<boolean>>
}

const ShowPasswordButton: React.FC<Props> = (props: Props): JSX.Element => {
  const renderIcon = (): JSX.Element => {
    if(props.showPassword) return <IconEyeSlash width={20} height={20} fill={ColorPalette.BLUE} />
    return <IconEye width={20} height={20} fill={ColorPalette.BLUE} />
  }

  return (
    <div
      className="absolute right-2"
      onClick={() => props.setShowPassword(!props.showPassword)}
    >
      {renderIcon()}
    </div>
  )
}

export default ShowPasswordButton
