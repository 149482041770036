import React from "react"
import { getChatById } from "../../../../shared/selectors/message"
import { useSelector } from "react-redux"
import { ChatTypes, IChat } from "../../state/chatTypes"
import { IAppState } from "../../../../app/appTypes"
import { IconTeam, IconUser } from "../../../../svg/icons"

interface Props {
  chatId: string
}

const ChatIcon: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))

  const renderIcon = (): JSX.Element => {
    if(chat.type == ChatTypes.TEAM) return <IconTeam className="text-ch-purple-500 w-6 h-6" />
    return <IconUser className="text-ch-purple-500 w-4 h-4" />
  }

  return (
    <div className="w-9 h-9 rounded-full bg-white flex items-center justify-center border border-ch-gray-500">
      {renderIcon()}
    </div>
  )
}


export default ChatIcon
