import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { IDocumentAsset } from "../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IAppState } from "../../../../app/appTypes"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { ISharedImageZoomState } from "../../state/liveMeetingTypes"
import ImageZoom from "../../../../shared/components/ui/imageZoom/ImageZoom"
import { updateSharedImageZoomStateAction } from "../../state/liveMeetingActions"

interface IProps {
  assetId: string
}

const LiveMeetingSharedViewImageViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch()
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const updateSharedImageZoomState = (shareImageZoomState: ISharedImageZoomState) => dispatch(updateSharedImageZoomStateAction(shareImageZoomState))
  if (!asset) return <LoadingSpinner />

  const image = asset.asset as IDocumentAsset

  return (
    <ImageZoom
      imageSrc={image.path}
      updateCallback={updateSharedImageZoomState}
    />
  )
}

export default LiveMeetingSharedViewImageViewer
