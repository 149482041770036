import { ICreateMeetingCaseAssetAsyncAction, IDeleteMeetingCaseAssetAsyncAction, MeetingCaseAssetsActionTypes, ICreateAllMeetingCaseAssetsAction } from "./meetingCaseAssetsTypes"

export const createMeetingCaseAssetAction = (meetingCaseId: string, caseAssetId: string): ICreateMeetingCaseAssetAsyncAction => {
  return {
    type: MeetingCaseAssetsActionTypes.CREATE_MEETING_CASE_ASSET_ASYNC_PENDING,
    payload: {
      meetingCaseId,
      caseAssetId
    }
  }
}

export const createAllMeetingCaseAssetsAction = (meetingCaseId: string): ICreateAllMeetingCaseAssetsAction => {
  return {
    type: MeetingCaseAssetsActionTypes.CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_PENDING,
    payload: {
      meetingCaseId
    }
  }
}

export const deleteMeetingCaseAssetAction = (meetingCaseId: string, meetingCaseAssetId: string): IDeleteMeetingCaseAssetAsyncAction => {
  return {
    type: MeetingCaseAssetsActionTypes.DELETE_MEETING_CASE_ASSET_ASYNC_PENDING,
    payload: {
      meetingCaseId,
      meetingCaseAssetId
    }
  }
}
