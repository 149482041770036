import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const chatNewValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    message: Yup.string()
      .trim()
      .required(i18n.t("shared:required")),
    userIds: Yup.array()
      .when("isTeamChat", {
        is: false,
        then: Yup.array().required(i18n.t("shared:required"))
        .min(1, i18n.t("shared:mustSelectAtLeastOne")),
      }),
    teamId: Yup
      .string()
      .when("isTeamChat", {
        is: true,
        then: Yup
          .string()
          .trim().required(i18n.t("shared:required")),
      })
  })
}
