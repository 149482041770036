import { Form, Formik } from "formik"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import DenotesRequiredMessage from "../../../../shared/components/forms/DenotesRequiredMessage"
import { FormContext } from "../../../../shared/contexts/FormContextProvider"
import Card from "../../../../shared/components/layout/Card"
import { IPatientAmendmentNewData, IPatientEditData } from "../../state/patientEditTypes"
import { useParams } from "react-router"
import { AmendmentTypes, IHavePatientId, IHavePatientIdParam } from "../../../patient/state/patientTypes"
import AmendmentList from "./AmendmentList"
import Label from "../../../../shared/components/forms/Label"
import MentionField from "../../../../shared/components/forms/MentionField"
import FieldError from "../../../../shared/components/forms/FieldError"
import { patientAmendmentValidation } from "../../../patient/state/validation/patientAmendmentValidation"
import RadioCheckbox from "../../../../shared/components/forms/RadioCheckbox"
import WizardForceUpdate from "../../../../shared/components/ui/wizard/WizardForceUpdate"

interface IPropsFromParent {
  goToNextStep: () => void
  data: IPatientEditData
  setData: (data: IPatientEditData) => void
}

type Props = IPropsFromParent & IHavePatientIdParam

const ReasonForChangeForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const { formikFormRef } = useContext(FormContext)
  const { patientId } = useParams<IHavePatientId>()

  const initialValues: IPatientAmendmentNewData = {
    reason: "",
    amendmentType: null
  }

  return (
    <Card className="flex-1 overflow-scroll pb-36">
      <div className="w-full">
        <h4 className="flex mb-2 font-semibold">
          { t("patient:reasonForChange:sectionTitle") }
        </h4>
        <p className="mb-2 text-ch-blue-alt-500">
          { t("patient:reasonForChange:intro") }:
        </p>

        <AmendmentList patientId={patientId} data={props.data} className="mb-6" />

        <div className="w-full lg:w-2/3">
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={patientAmendmentValidation}
            onSubmit={(values, { setSubmitting }) => {
              const data: IPatientAmendmentNewData = {
                reason: values.reason,
                amendmentType: values.amendmentType
              }

              props.setData({
                ...props.data,
                patientAmendmentsAttributes: [data]
              })

              setSubmitting(false)
              props.goToNextStep()
            }}
            innerRef={formikFormRef}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              setFieldValue
            }) => {
              return (
                <Form>
                  <WizardForceUpdate />
                  <DenotesRequiredMessage />

                  <div className="mb-4">
                    <Label name="reason" required={true}>
                      {t("patient:reasonForChange:details:reasonInfo")}
                    </Label>
                    <MentionField
                      name="reason"
                      setValue={(reason) => setFieldValue("reason", reason)}
                      onBlur={handleBlur}
                      users={null}
                      value={values.reason}
                    />
                    <FieldError errorMessage={errors.reason as string} isVisible={(errors.reason && touched.reason) as boolean} />
                  </div>

                  <div className="mb-2">
                    <Label name="amendmentType" required={true}>
                      {t("patient:reasonForChange:details:amendmentType")}
                    </Label>
                    {Object.values(AmendmentTypes).map((amendmentType: string, index: number) => {
                      const translationKey = humps.camelize(amendmentType)
                      const label = t(`patient:reasonForChange:details:${translationKey}`)
                      const labelInfo = t(`patient:reasonForChange:details:${translationKey}Info`)
                      return (
                        <div key={`amendment-type-${amendmentType}`}>
                          <label className="flex">
                            <RadioCheckbox
                              isSelected={amendmentType == values.amendmentType}
                              name="amendmentType"
                              value={amendmentType}
                            />
                            <span className="ml-2">
                              {label} <span className="text-ch-gray-500"> - {labelInfo}</span>
                            </span>
                          </label>
                          { index < (Object.values(AmendmentTypes).length - 1) ? <div className="text-ch-gray-500 my-4">OR</div> : null }
                        </div>
                      )
                    })}
                    <FieldError errorMessage={errors.amendmentType as string} isVisible={(errors.amendmentType && touched.amendmentType) as boolean} />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default ReasonForChangeForm
