import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import MeetingCases from "../../features/meeting/components/meetingCases/MeetingCases"
import MeetingHeader from "../../features/meeting/components/header/MeetingHeader"
import MeetingInvitation from "../../features/meeting/components/MeetingInvitation"
import MeetingUsers from "../../features/meeting/components/users/MeetingUsers"
import { useDispatch, useSelector } from "react-redux"
import { fetchMeetingAction } from "../../features/meeting/state/meetingActions"
import { IHaveMeetingId, MeetingCablePrefix, MeetingChannelName } from "../../features/meeting/state/meetingTypes"
import { IAppState } from "../../app/appTypes"
import { getMeetingById } from "../../shared/selectors/meeting"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import CableConnection from "../../shared/components/cable/CableConnection"
import { useBeforeunload } from "react-beforeunload"

const MeetingPage: React.FC = (): JSX.Element => {
  const { meetingId } = useParams<IHaveMeetingId>()
  const { t } = useTranslation(["meeting"])
  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(0)
  const dispatch = useDispatch()
  const fetchMeeting = (meetingId: string) => dispatch(fetchMeetingAction(meetingId))
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId }))

  useBeforeunload(() => {
    setConnectionRefresherIndex(connectionRefresherIndex + 1)
  })

  useEffect(() => {
    fetchMeeting(meetingId)
    fetchUsers()
  }, [])

  if (!meeting) { return <h3 className="text-lg">{t("meeting:meetingNotFound")}</h3> }

  return (
    <CableConnection
      resourceId={meetingId}
      channelName={MeetingChannelName}
      actionPrefix={MeetingCablePrefix}
      key={connectionRefresherIndex}
    >
      <MeetingHeader meetingId={meetingId} />
      <MeetingInvitation meetingId={meetingId} />
      <MeetingUsers meetingId={meetingId} />
      <MeetingCases meetingId={meetingId} />
    </CableConnection >
  )
}

export default MeetingPage
