import React, { useEffect, useRef } from "react"
import ChatListItem from "./ChatListItem"
import { getAllChatIds } from "../../../../shared/selectors/message"
import { useSelector } from "react-redux"
import IChatsUIState from "../../state/chatsTypes"
import { IAppState } from "../../../../app/appTypes"
import { getChatsUI } from "../../../../shared/selectors/ui"
import useSearch, { ISearchQuery } from "../../../../shared/hooks/useSearch"
import { fetchChatsAsync } from "../../state/chatsActions"
import InfiniteScroll from "react-infinite-scroll-component"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { useDispatch } from "react-redux"
import { markChatMessagesAsRead } from "../../../chat/state/chatActions"
import { useTranslation } from "react-i18next"

interface Props {
  setSelectedChatId: (selectedChatId: string) => void
  selectedChatId: string
}

const ChatList: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["message"])
  const dispatch = useDispatch()
  const chatIds = useSelector(getAllChatIds)
  const UI: IChatsUIState = useSelector((state: IAppState) => getChatsUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    resultsPerPage: UI.resultsPerPage,
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchChatsAsync)
  const chatListContainerRef = useRef<HTMLDivElement>(null)

  const hasMore = (): boolean => {
    if (searchQuery.page < UI.totalPages) return true
    return false
  }

  const loadMore = (): void => {
    setSearchQuery({
      page: searchQuery.page + 1
    })
  }

  useEffect(() => {
    if (!props.selectedChatId) return

    dispatch(markChatMessagesAsRead(props.selectedChatId))
  }, [props.selectedChatId])

  return (
    <div
      id="chatListContainer"
      ref={chatListContainerRef}
      className="flex flex-1 flex-col h-full overflow-y-scroll"
    >
      {chatIds.length < 1 ?
        <div className="p-4">{t("message:index:noMessages")}</div>
        : <InfiniteScroll
          dataLength={chatIds.length}
          next={loadMore}
          className="flex flex-col px-4 pb-4"
          hasMore={hasMore()}
          loader={
            <div className="flex justify-center p-10">
              <LoadingSpinner />
            </div>
          }
          scrollableTarget="chatListContainer"
        >
          {chatIds.map((chatId: string) => (
            <ChatListItem
              key={chatId}
              chatId={chatId}
              setSelectedChatId={props.setSelectedChatId}
              selectedChatId={props.selectedChatId}
            />
          ))}
        </InfiniteScroll>
      }
    </div>
  )
}


export default ChatList
