import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import NameUser from "../../../../shared/components/names/NameUser"
import { IconChangeTracking, IconFolder } from "../../../../svg/icons"
import _ from "lodash"
import { getCaseById } from "../../../../shared/selectors/case"
import { ICase } from "../../state/caseTypes"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import { AuditEventList } from "../../../audit/component/auditEventList/AuditEventList"

interface IPropsFromParent {
  caseId: string
}

const CaseChangeTracking: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["shared", "meeting"])
  const _case = useSelector((state: IAppState): ICase => getCaseById(state, props))
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  return (
    <div>
      <div className="flex items-center flex-col mb-6">
        <IconCircleContainer className="mb-3">
          <IconFolder className="fill-current w-6 h-6" title={t("shared:_case")} />
        </IconCircleContainer>

        <dl className="text-center mb-3">
          <dt className="mb-1">
            <h5 className="font-semibold">
              <NameUser
                firstName={_case.firstName}
                lastName={_case.lastName}
                title={_case.title}
                gender={_case.gender}
              />
            </h5>
          </dt>
          <dd className="mb-3">
            {t("case:dateOfBirth")}: <TimeDateOfBirth utcTime={_case.dateOfBirth} />
          </dd>

          <dd className="mb-1">
            {healthcareIdLabel}: {_case.healthcareId || "-"}
          </dd>
          <dd className="mb-3">
            {careProviderIdLabel}: {_case.careProviderId || "-"}
          </dd>
        </dl>
      </div>
      <div className="flex">
        <IconChangeTracking className="fill-current w-6 h-6 md:ml-2" title={t("shared:changeTracking")} /> {t("shared:changeTracking")}
      </div>
      <AuditEventList entityId={props.caseId}/>
    </div>
  )
}

export default CaseChangeTracking
