import ReactModal from "react-modal"

export interface ICreatedBy {
  id: string,
  firstName: string,
  lastName: string,
  professionalTitle?: string
  pending?: boolean
}

export interface ILinkProps {
  href?: string
  target?: string
  rel?: string
}

// Action Types
export enum SharedActionTypes {
  REHYDRATE = "persist/REHYDRATE",
}

// Style TODO:: move this somewhere else
export enum StyleVariants {
  GRAY = "ch-gray",
  BLUE = "ch-blue",
  BLUE_ALT = "ch-blue-alt",
  ORANGE = "ch-orange",
  PURPLE = "ch-purple"
}

export enum ColorPalette {
  PURPLE = "#730B4D",
  BLUE = "#13B7BF",
  BLUE_ALT="#006F74",
  ORANGE="#E2781F",
  GREEN="#98C465",
  WHITE="#FFFFFF"
}

export const defaultModalStyle: ReactModal.Styles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    zIndex: 1000
  },
  content: {
    ...ReactModal.defaultStyles.content,
    overflow: "hidden",
    borderRadius: "0.375rem",
    padding: "10px",
    width: "95vw",
    height: "95vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

export const bottomModalStyle: ReactModal.Styles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    zIndex: 900
  },
  content: {
    ...ReactModal.defaultStyles.content,
    overflow: "hidden",
    borderRadius: "0.375rem",
    padding: "10px",
    width: "95vw",
    height: "95vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}
export const smallModalStyle = {
  ...defaultModalStyle,
  content: {
    inset: "30vh 6vw auto 6vw"
  }
}

export const dialogueModalStyle = {
  ...defaultModalStyle,
  content: {
    ...defaultModalStyle.content,
    overflow: "hidden",
    borderRadius: "0.375rem",
    padding: "10px",
    width: "auto",
    maxWidth: "65vw",
    height: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

export enum ResultsPerPageOptions {
  "010" = "10",
  "025" = "25",
  "050" = "50",
  "0100" = "100"
}

export const defaultPerPage = parseInt(ResultsPerPageOptions["025"])

export const SharedChannelName = "SharedChannel"
export const sharedCablePrefix = "@@sharedCable/"

export enum SharedCableActionTypes {
  UPDATE_USER_STATUS = "@@sharedCable/users/UPDATE_USER_STATUS"
}

export interface IApiResponseData {
  error: string
}
