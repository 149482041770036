import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { AssetsActionTypes } from "../assetsTypes"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"

export function* addConsentAsync(action: Effect): Generator {
  try {
    const payload = action.payload
    const data = {
      userId: payload.userId,
      response: payload.response,
      comment: payload.comment,
    }
    const response: any = yield call(API.put, `/recommendations/${payload.recommendationId}/consent`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.ADD_CONSENT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "addConsentToRecommendationSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: AssetsActionTypes.ADD_CONSENT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "addConsentToRecommendationFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchAddConsentAsync(): Generator {
  yield takeLeading(AssetsActionTypes.ADD_CONSENT_ASYNC_PENDING, addConsentAsync)
}
