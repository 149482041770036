import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconTick({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M11.957.82L5.543 9.278 1.75 5.488 0 7.238l5.832 5.832L14 2.57z"
        />
        <path d="M0 0h14v14H0z" />
      </g>
    </svg>
  );
}

export default SvgIconTick;
