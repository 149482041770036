import { takeLeading } from "redux-saga/effects"
import { ICreateMeetingCaseAssetAsyncAction, IDeleteMeetingCaseAssetAsyncAction, MeetingCaseAssetsActionTypes } from "../meetingCaseAssetsTypes"
import { fetchMeetingCaseSchema } from "../../../../apis/contextmeeting/schema"
import { deleteNormalisedSingle, handleError, handleSuccess, postNormalisedSingle } from "../../../../shared/helpers/sagaHelpers"

export function* meetingCaseAssetWatchers(): Generator {
  yield takeLeading(MeetingCaseAssetsActionTypes.CREATE_MEETING_CASE_ASSET_ASYNC_PENDING, createMeetingCaseAssetAsync)
  yield takeLeading(MeetingCaseAssetsActionTypes.CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_PENDING, createAllMeetingCaseAssetsAsync)
  yield takeLeading(MeetingCaseAssetsActionTypes.DELETE_MEETING_CASE_ASSET_ASYNC_PENDING, deleteMeetingCaseAssetAsync)
}

export function* createMeetingCaseAssetAsync(action: ICreateMeetingCaseAssetAsyncAction): Generator {
  try {
    const meetingCaseId = action.payload.meetingCaseId
    const caseAssetId = action.payload.caseAssetId

    const data = { meetingCaseAsset: { caseAssetId } }
    const response = yield postNormalisedSingle(`/meeting_cases/${meetingCaseId}/meeting_case_assets`, data, fetchMeetingCaseSchema)

    yield handleSuccess(MeetingCaseAssetsActionTypes.CREATE_MEETING_CASE_ASSET_ASYNC_SUCCESS, response, "createMeetingCaseAssetSuccess")

  } catch (error) {
    yield handleError(MeetingCaseAssetsActionTypes.CREATE_MEETING_CASE_ASSET_ASYNC_ERROR, error, "createMeetingCaseAssetFail")
  }
}

export function* createAllMeetingCaseAssetsAsync(action: ICreateMeetingCaseAssetAsyncAction): Generator {
  try {
    const meetingCaseId = action.payload.meetingCaseId

    const response = yield postNormalisedSingle(`/meeting_cases/${meetingCaseId}/meeting_case_assets/all`, {}, fetchMeetingCaseSchema)
    yield handleSuccess(MeetingCaseAssetsActionTypes.CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_SUCCESS, response, "createMeetingCaseAssetSuccess")

  } catch (error) {
    yield handleError(MeetingCaseAssetsActionTypes.CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_ERROR, error, "createMeetingCaseAssetFail")
  }
}

export function* deleteMeetingCaseAssetAsync(action: IDeleteMeetingCaseAssetAsyncAction): Generator {
  try {
    const meetingCaseId = action.payload.meetingCaseId
    const meetingCaseAssetId = action.payload.meetingCaseAssetId

    const response = yield deleteNormalisedSingle(`/meeting_cases/${meetingCaseId}/meeting_case_assets/${meetingCaseAssetId}`, fetchMeetingCaseSchema)
    yield handleSuccess(MeetingCaseAssetsActionTypes.DELETE_MEETING_CASE_ASSET_ASYNC_SUCCESS, response, "deleteMeetingCaseAssetSuccess")

  } catch (error) {
    yield handleError(MeetingCaseAssetsActionTypes.DELETE_MEETING_CASE_ASSET_ASYNC_ERROR, error, "deleteMeetingCaseAssetFail")
  }
}
