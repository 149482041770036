// Models
export interface ITeamUser {
  id: string
  userId: string
  teamId?: string
  role: string
}

export interface ITeamUsersNewData {
  teamId: string
  userIds: string[]
}

export interface ITeamUserData {
  id?: string
  userId: string
  role: string
}

export interface IHaveTeamUserId {
  teamUserId: string
}

// Actions
export interface ICreateTeamUserAction {
  type: string
  payload: {
    teamId: string
    userIds: string[]
  }
}

export interface IDestroyTeamUserAction {
  type: string
  payload: string
}

// Action Types
export enum TeamUserActionTypes {
  CREATE_TEAM_USER_ASYNC_PENDING = "@@teamUser/CREATE_TEAM_USER_ASYNC_PENDING",
  CREATE_TEAM_USER_ASYNC_SUCCESS = "@@teamUser/CREATE_TEAM_USER_ASYNC_SUCCESS",
  CREATE_TEAM_USER_ASYNC_ERROR = "@@teamUser/CREATE_TEAM_USER_ASYNC_ERROR",

  DESTROY_TEAM_USER_ASYNC_PENDING = "@@teamUser/DESTROY_TEAM_USER_ASYNC_PENDING",
  DESTROY_TEAM_USER_ASYNC_SUCCESS = "@@teamUser/DESTROY_TEAM_USER_ASYNC_SUCCESS",
  DESTROY_TEAM_USER_ASYNC_ERROR = "@@teamUser/DESTROY_TEAM_USER_ASYNC_ERROR"
}
