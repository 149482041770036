import React from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import IconCircleContainer from "../../../../../shared/components/icons/IconCircleContainer"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import DetailsCard from "../../../../../shared/components/layout/detailsCard/DetailsCard"
import { IconTask } from "../../../../../svg/icons"
import Buttons from "../../../../../shared/components/layout/Buttons"
import Button from "../../../../../shared/components/buttons/Button"
import { dialogueModalStyle, StyleVariants } from "../../../../../shared/state/sharedTypes"
import CasePathwayHeader from "../../../../casePathways/components/CasePathwayHeader"
import { getCasePathwayById } from "../../../../../shared/selectors/casePathway"
import { ICasePathway } from "../../../../casePathways/state/casePathwayTypes"
import { deleteCaseTaskAsync } from "../../../../tasks/state/tasksActions"
import { ITask } from "../../../../tasks/state/tasksTypes"
import { useTranslation } from "react-i18next"
import { getTaskById } from "../../../../../shared/selectors/task"

interface IProps {
  taskId: string
  casePathwayId: string
  isOpen: boolean
  closeModal: () => void
}

const DeleteCasePathwayTaskModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const task: ITask = useSelector((state: IAppState) => getTaskById(state, props))
  const casePathway: ICasePathway = useSelector(getCasePathwayById(props))
  if (!task || !casePathway) return null

  const dispatch = useDispatch()

  const { t } = useTranslation(["task", "pathway"])

  const confirmDelete = (task: ITask) => {
    dispatch(deleteCaseTaskAsync(casePathway.caseId, task.id, casePathway.id))
    props.closeModal()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={dialogueModalStyle}>
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("task:deleteTaskHeader")}:
        </h2>
        <div>
          <CasePathwayHeader casePathwayId={props.casePathwayId} />
        </div>
        <div className="my-3">
          {t("task:deleteTaskDetails")}:
        </div>
        <DetailsCard className="bg-ch-gray-300" >
          <div className="flex flex-1 items-center">
            <IconCircleContainer className="sm:mr-4" sizeClasses={["w-7", "h-7"]}>
              <IconTask className="fill-current w-7 h-7" />
            </IconCircleContainer>
            <div>
              <h5 className="font-semibold mb-1">
                <span>{task.casePathwayPosition}. </span>
                <span>{task.action}</span>
              </h5>
              <p>
                {task.description}
              </p>
            </div>
          </div>
        </DetailsCard>
        <div className="my-3">
          {t("pathway:details:deleteAreYouSure")}
        </div>
        <Buttons buttons={[
          <Button key="1" action={() => props.closeModal()} variant={StyleVariants.BLUE_ALT}>{t("shared:cancel")}</Button>,
          <Button key="2" action={() => confirmDelete(task)} variant={StyleVariants.PURPLE}>{t("task:deleteTask")} </Button>
        ]} />
      </ModalContainer>
    </Modal>
  )
}

export default DeleteCasePathwayTaskModal
