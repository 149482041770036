import { AuditEventListItem } from "./AuditEventListItem"
import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getAuditEventIdsByEntityId } from "../../../../shared/selectors/audit"

interface Props {
  entityId: string
  currentUserId?: string
}

export const AuditEventList = (props: Props): JSX.Element => {
  const auditEventsIds = useSelector((state: IAppState) => getAuditEventIdsByEntityId(state, props.entityId))

  return (
    <div>
      <ul>
        {(auditEventsIds).map((auditEventId, index) => {
          return (
            <AuditEventListItem
              key={index}
              auditEventId={auditEventId}
              currentUserId={props.currentUserId}
            />
          )
        })}
      </ul>
    </div>
  )
}
