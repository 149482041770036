import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { ITenant, IUser } from "../../features/user/state/userTypes"
import { useAppSelector } from "."
import { getCurrentUser } from "../selectors/user"
import { useSelector } from "react-redux"
import { getAllUserTenants } from "../selectors/tenant"
import { IExternalUser } from "../../features/externalUser/state/externalUserTypes"
import _ from "lodash"

const useExternalUserCookie = (): IExternalUser[] => {
  const cookieName = "externalUsers"
  const [cookies, setCookie] = useCookies([cookieName])
  const [externalUsersCookie, setExternalUserCookie] = useState([])
  const currentUser: IUser = useAppSelector(getCurrentUser)
  const userTenants: ITenant[] = useSelector(getAllUserTenants)

  useEffect(() => {
    if(!cookies?.externalUsers) return

    setExternalUserCookie(cookies.externalUsers)
  }, [cookies?.externalUsers])

  useEffect(() => {
    if(!isNewTenant()) return

    addTenantToCookie()
  }, [currentUser.currentTenantId])

  const isNewTenant = (): boolean => {
    const externalUsers: IExternalUser[] = cookies?.externalUsers
    const currentTenantId = currentUser.currentTenantId

    const matchingExternalUsers = externalUsers?.filter((externalUser: IExternalUser) => {
      const hasMatchingTenant: boolean = externalUser.tenants.some((tenant) => tenant.id === currentTenantId)
      const hasSameEmail = externalUser.email === currentUser.email
      const hasSameLocation = externalUser.location === window.location.origin
      return hasMatchingTenant && hasSameEmail && hasSameLocation
    })

    return !matchingExternalUsers?.length
  }

  const addTenantToCookie = (): void => {
    const cookieDomain: string = process.env.INSTANCE_COOKIE_URL

    const cookie: IExternalUser[] = cookies.externalUsers || []

    const newExternalUser: IExternalUser = {
      id: currentUser.currentTenantId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      tenants: userTenants,
      location: window.location.origin
    }

    const newCookie: IExternalUser[] = _.uniq([
      ...cookie,
      newExternalUser
    ])

    setCookie(cookieName, newCookie, { domain: `.${cookieDomain}` })
  }

  return externalUsersCookie
}

export default useExternalUserCookie
