import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"
import { getComponentTypeForAsset } from "../../../../shared/helpers/assetHelpers"
import { getAssetById } from "../../../../shared/selectors/asset"
import LiveMeetingPdfViewer from "../assets/viewer/LiveMeetingPdfViewer"
import LiveMeetingSharedViewImageViewerObserver from "./LiveMeetingSharedViewImageViewerObserver"
import IAssetTypeFilters from "../../../../shared/types/AssetTypeFilters"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  assetId: string
  assetTypeFilters: IAssetTypeFilters
}

const LiveMeetingSharedViewAssetObserverViewer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["asset"])
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const pdfState = useSelector(getLiveMeetingConfig).sharedPdfState

  if (!asset) return <h3 className="text-lg font-bold text-center">{t("asset:assetNotFound")}</h3>

  const assetComponetType = getComponentTypeForAsset(asset)
  const componentLookup = {
    [AssetComponentTypes.Pdf]: LiveMeetingPdfViewer,
    [AssetComponentTypes.CaseReport]: LiveMeetingPdfViewer,
    [AssetComponentTypes.Image]: LiveMeetingSharedViewImageViewerObserver,
  }
  const AssetViewer = componentLookup[assetComponetType]

  if (!AssetViewer) return <h3 className="text-lg font-bold text-center">{t("asset:couldNotDisplayAsset")}</h3>

  return (
    <AssetViewer
      assetId={props.assetId}
      assetTypeFilters={props.assetTypeFilters}
      rotation={pdfState.rotation}
      hideRotateButton
    />
  )
}

export default LiveMeetingSharedViewAssetObserverViewer
