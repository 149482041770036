import { init as initApm } from "@elastic/apm-rum"

const name = "cm2-" + window.location.hostname.replace(/[^0-9a-zA-Z]/g, " - ")
const Apm = initApm({
  serviceName: name,
  serverUrl: "https://cm2-elastic-1.apm.uksouth.azure.elastic-cloud.com",
  serviceVersion: "1",
  active: (window.location.hostname != "localhost"),
  logLevel: "error"
})

export default Apm
