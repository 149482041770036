import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { ISpecialty } from "../../../specialty/state/specialtyTypes"
import { getSpecialtyById } from "../../../../shared/selectors/specialties"

interface IProps {
  specialtyId: string
}

const AuditEventSpecialtyName = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])
  const specialty = useSelector((state: IAppState): ISpecialty => getSpecialtyById(state, props.specialtyId))

  return (
    <div>
      <span className="font-semibold">{t("auditEvent:specialtyName")}
      </span>:&nbsp;
      {specialty.name}
    </div>
  )
}

export default AuditEventSpecialtyName
