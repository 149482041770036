import classNames from "classnames"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import AddGroup from "../../../../shared/components/ui/AddGroup"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import MeetingCaseListItem from "./MeetingCaseListItem"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import Card from "../../../../shared/components/layout/Card"
import { getCurrentUser } from "../../../../shared/selectors/user"
import AddCaseToMeetingModal from "../../../addCaseToMeeting/components/AddCaseToMeetingModal"

interface IProps {
  meetingId: string
}

const MeetingCases = (props: IProps) => {
  const [isVisibleList, toggleList] = useState(true)
  const { t } = useTranslation(["shared", "meeting"])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const currentUser = useSelector(getCurrentUser)

  const openModal = (): void => { setIsModalOpen(true) }
  const closeModal = (): void => { setIsModalOpen(false) }

  const renderAddCaseButton = (): JSX.Element => {
    if (!meeting.permissions?.canAddCase) return null

    return (
      <Button
        action={openModal}
        className="mb-2"
        variant={StyleVariants.ORANGE}
      >
        {t("meeting:addCase")}
      </Button>
    )
  }

  const renderAddGroup = (): JSX.Element => {
    if (!meeting.permissions?.canAddCase) return null
    return (
      <AddGroup variant={StyleVariants.BLUE} action={() => { return }} />
    )
  }

  if (!meeting || !meeting.meetingCases || !currentUser) return null

  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("shared:cases")} ({meeting.meetingCases.length})
          </h3>
          {renderAddCaseButton()}
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            {t("meeting:casesIntro")}
          </p>

          <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("meeting:showCases")} collapseText={t("meeting:hideCases")} />
        </div>

        <div className={classNames({ hidden: !isVisibleList })}>
          {renderAddGroup()}
          <ul>
            {_.map(meeting.meetingCases, (meetingCaseId) => {
              return <MeetingCaseListItem key={meetingCaseId} meetingCaseId={meetingCaseId} />
            })}
          </ul>
        </div>
      </Card>

      <AddCaseToMeetingModal
        meetingId={props.meetingId}
        isOpen={isModalOpen}
        handleClose={closeModal}
      />
    </div>
  )
}

export default MeetingCases
