import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const caseCommentNewValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    commentType: Yup.string()
      .required(i18n.t("shared:required")),
    title: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(100, i18n.t("shared:maxLengthExceeded", { maxLength: 100 })),
    body: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(5000, i18n.t("shared:maxLengthExceeded", { maxLength: 5000 }))
  })
}
