import classNames from "classnames"
import React from "react"
import ReactSlider from "react-slider"

interface IProps {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
}

const defaultProps = {
  min: 0,
  max: 100
}

const Slider: React.FC<IProps> = (props: IProps): JSX.Element => {

  const renderTrack = (props, state): JSX.Element => {
    return (
      <div
        {...props}
        className={classNames(
          "h-1", "rounded-full", {
            "bg-white": state.index == 1,
            "bg-ch-blue-500": state.index == 0
          }
        )}
      />
    )
  }

  const renderThumb = (props, _state): JSX.Element => {
    return (
      <div
        {...props}
        className="w-6 h-6 rounded-full bg-white cursor-pointer outline-none focus:bg-ch-blue-400"
      />
    )
  }

  return (
    <div className="slider w-full py-2 px-3 bg-ch-gray-600 rounded-full">
      <ReactSlider
        className="slider w-full h-6 overflow-hidden flex items-center"
        renderTrack={renderTrack}
        renderThumb={renderThumb}
        value={props.value}
        onChange={(value: number) => props.onChange(value)}
        max={props.max}
        min={props.min}
      />
    </div>
  )
}

Slider.defaultProps = defaultProps

export default Slider
