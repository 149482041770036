import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { IAppState } from "./../../../app/appTypes"
import Card from "../../../shared/components/layout/Card"
import { IconAcceptThin, IconRejectThin } from "../../../svg/icons"
import { AcceptanceResponses, IMeetingUser } from "../../meetingUser/state/meetingUserTypes"
import { updateMeetingUserAction } from "../../meetingUser/state/meetingUserActions"
import { getMeetingById, getMeetingUserByCurrentUserId } from "../../../shared/selectors/meeting"
import { MeetingStatuses } from "../state/meetingTypes"

interface IProps {
  meetingId: string
}

const MeetingInvitation: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["meeting"])
  const dispatch = useDispatch()
  const meetingUser = useSelector((state: IAppState) => getMeetingUserByCurrentUserId(state, props.meetingId))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, props))
  const updateMeetingUser = (meetingUserId: string, meetingUser: IMeetingUser) => dispatch(updateMeetingUserAction(meetingUserId, meetingUser))

  const acceptInvitation = () => {
    updateMeetingUser(meetingUser.id, {
      ...meetingUser,
      acceptanceResponse: AcceptanceResponses.Accepted
    }
    )
  }

  const rejectInvitation = () => {
    updateMeetingUser(meetingUser.id, {
      ...meetingUser,
      acceptanceResponse: AcceptanceResponses.Rejected
    }
    )
  }

  const acceptanceResponseText = () => {
    if (!meetingUser.acceptanceResponse) {
      return t("meeting:pleaseAcceptInvite")
    }

    switch (meetingUser.acceptanceResponse) {
      case AcceptanceResponses.Pending:
        return t("meeting:pleaseAcceptInvite")
      case AcceptanceResponses.Accepted:
        return t("meeting:youHaveAccepted")
      case AcceptanceResponses.Rejected:
        return t("meeting:youHaveRejected")
    }
  }

  if (!meetingUser || meeting.status == MeetingStatuses.Ended) return null
  const accepted = meetingUser.acceptanceResponse == AcceptanceResponses.Accepted
  const rejected = meetingUser.acceptanceResponse == AcceptanceResponses.Rejected
  return (
    <Card className="flex flex-wrap justify-center sm:justify-between">
      <span className="mb-2 text-center sm:text-left sm:mb-0">
        {acceptanceResponseText()}
      </span>
      <ul className="flex">
        <li className="ml-4">
          <a
            href="#"
            onClick={acceptInvitation}
            className={classNames(
              "flex",
              "uppercase",
              "font-thin",
              "items-center",
              "hover:text-ch-green-300",
              {
                "text-ch-gray-500": rejected,
                "text-ch-green-300": accepted
              }
            )}
          >
            <span className="text-ch-gray-600">{t("meeting:accept")}</span> <IconAcceptThin className="fill-current w-4 h-4 ml-2" />
          </a>
        </li>
        <li className="ml-4">
          <a
            href="#"
            onClick={rejectInvitation}
            className={classNames(
              "flex",
              "uppercase",
              "font-thin",
              "items-center",
              "hover:text-ch-red-500",
              {
                "text-ch-gray-500": accepted,
                "text-ch-red-500": rejected
              }
            )}
          >
            <span className="text-ch-gray-600">{t("meeting:reject")}</span> <IconRejectThin className="fill-current w-4 h-4 ml-2" />
          </a>
        </li>
      </ul>
    </Card >
  )
}

export default MeetingInvitation
