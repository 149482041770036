import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import TimeDateOnly from "../../../../shared/components/time/TimeDateOnly"
import { getCaseClassificationById } from "../../../../shared/selectors/caseClassification"
import { IAuditEvent } from "../../state/auditEventTypes"
import { ICaseClassification } from "../../../caseClassification/state/caseClassificationTypes"
import AuditEventSubCard from "./AuditEventSubCard"
import { getUserById } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"

interface IProps {
  auditEvent: IAuditEvent
}

const AuditEventCaseEditedCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])

  const caseClassificiation = useSelector((state: IAppState): ICaseClassification[] => {
    const caseClassificationIds = props.auditEvent.eventData?.changes?.caseClassificationId
    if (!caseClassificationIds) return null
    return [getCaseClassificationById(state, caseClassificationIds[0]), getCaseClassificationById(state, caseClassificationIds[1])]
  })

  const leadCareUsers = useSelector((state: IAppState): IUser[] => {
    const leadCareUserIds = props.auditEvent.eventData.changes.leadCareUserId
    if (leadCareUserIds) {
      return [getUserById(state, leadCareUserIds[0]), getUserById(state, leadCareUserIds[1])]
    }
    return null
  })

  const getSignedOffBy = () => {
    const signedOffBy = props.auditEvent.eventData.changes.signedOffBy

    if (!signedOffBy) return null
    return (<AuditEventSubCard
      title="caseSignedOffByChanged"
      originalProp={signedOffBy[0]}
      changedProp={signedOffBy[1]} />)
  }

  const getTargetDateFulfilled = () => {
    const targetDateFulfilled = props.auditEvent.eventData.changes.targetDateFulfilled

    if (!targetDateFulfilled) return null
    return (<AuditEventSubCard
      title="caseTargetDateFulfilledChanged"
      originalProp={targetDateFulfilled[0] ? t("auditEvent:targetDateFulfilled") : t("auditEvent:targetDateNotFulfilled")}
      changedProp={targetDateFulfilled[1] ? t("auditEvent:targetDateFulfilled") : t("auditEvent:targetDateNotFulfilled")} />)
  }

  const getTargetDate = () => {
    const targetDate = props.auditEvent.eventData.changes.targetDate

    if (!targetDate) return null
    return (<AuditEventSubCard
      title="caseTargetDateChanged"
      originalProp={targetDate[0] ? <TimeDateOnly utcTime={targetDate[0]} key={0} /> : "-"}
      changedProp={targetDate[1] ? <TimeDateOnly utcTime={targetDate[1]} key={1} /> : "-"}
    />)
  }

  const getLeadCaseUser = () => {
    const leadCareUserId = props.auditEvent.eventData.changes.leadCareUserId

    if (!leadCareUserId) return null
    return (<AuditEventSubCard
      title="caseleadChanged"
      originalProp={leadCareUsers[0].firstName + " " + leadCareUsers[0].lastName}
      changedProp={leadCareUsers[1].firstName + " " + leadCareUsers[1].lastName}
    />)
  }

  const getCaseType = () => {
    const caseType = props.auditEvent.eventData.changes.caseType

    if (!caseType) return null
    return (<AuditEventSubCard
      title="caseTypeChanged"
      originalProp={(caseType[0])}
      changedProp={(caseType[1])} />)
  }

  const getCaseClassification = () => {
    const caseClassificationId = props.auditEvent.eventData?.changes?.caseClassificationId

    if (!caseClassificationId) return null
    return (<AuditEventSubCard
      title="caseClassificationChanged"
      originalProp={caseClassificiation[0].name}
      changedProp={caseClassificiation[1].name} />)
  }

  return (
    <>
      {getCaseType()}
      {getCaseClassification()}
      {getLeadCaseUser()}
      {getTargetDate()}
      {getTargetDateFulfilled()}
      {getSignedOffBy()}
    </>
  )
}

export default AuditEventCaseEditedCard
