import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { IMeetingCase, MeetingCaseActionTypes } from "../meetingCaseTypes"
import { MeetingActionTypes } from "../../../meeting/state/meetingTypes"
import { MeetingsActionTypes } from "../../../meetings/state/meetingsActionTypes"
import { MeetingCaseAssetsActionTypes } from "../../../meetingCaseAssets/state/meetingCaseAssetsTypes"

type TByIdState = { [id: string]: IMeetingCase }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  const newState = {
    ...state
  }

  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.entities.meetingCases)

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.meetingCases)

    // Of the meeting cases received here, only the 'result' meeting case is
    // complete, the others are only ID's, needed for the meetings, so the
    // other, empty meeting cases shouldn't be added to the state
    case MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_SUCCESS: {
      const id = action.payload.result
      return _.merge({}, state, { [id]: action.payload.entities.meetingCases[id] })
    }

    case MeetingCaseAssetsActionTypes.CREATE_MEETING_CASE_ASSET_ASYNC_SUCCESS:
    case MeetingCaseAssetsActionTypes.DELETE_MEETING_CASE_ASSET_ASYNC_SUCCESS:
    case MeetingCaseAssetsActionTypes.CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.meetingCases
      }

    case MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.meetingCases
      }

    case MeetingCaseActionTypes.DELETE_MEETING_CASE_ASYNC_SUCCESS:
      delete newState[action.payload.meetingCaseId]
      return newState

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.meetingCases || [])))

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.meetingCases || [])))

    case MeetingCaseActionTypes.CREATE_MEETING_CASE_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.meetingCases || [])))

    default:
      return state
  }
}

export const meetingCaseEntityReducer: Reducer<INormalizedEntities<IMeetingCase>> = combineReducers({
  byId,
  allIds
})
