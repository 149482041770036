import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getComponentTypeForAsset, componentLookup } from "../../../../shared/helpers/assetHelpers"
import AddSharedAssetButton from "./AddSharedAssetButton"
import { getSharedAssetById } from "../../../../shared/selectors/sharedAssets"
import RemoveSharedAssetButton from "./RemoveSharedAssetButton"
import { AssetComponentTypes } from "../../../assets/state/assetsTypes"

interface Props {
  assetId: string
  caseId: string
  userId: string
  action?: () => void
}

const SelectSharedAssetListItem: React.FC<Props> = (props: Props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getSharedAssetById(state, props.assetId))

  if (!asset) return null

  const assetComponetType = getComponentTypeForAsset(asset)
  const Asset = componentLookup[assetComponetType]

  const clickableComponents: string[] = [
    AssetComponentTypes.Pdf,
    AssetComponentTypes.Image,
    AssetComponentTypes.CaseReport
  ]
  const clickAction = (clickableComponents.includes(assetComponetType)) ? props.action : undefined

  const renderButton = () => {
    if(asset.userSharedAssetId) return(
      <RemoveSharedAssetButton
        userSharedAssetId={asset.userSharedAssetId}
        caseId={props.caseId}
        userId={props.userId}
      />
    )
    return (
      <AddSharedAssetButton
        assetId={props.assetId}
        caseId={props.caseId}
        userId={props.userId}
      />
    )
  }

  return (
    <li className="mb-1">
      <Asset
        assetId={props.assetId}
        caseId={props.caseId}
        action={clickAction}
        noAction
      >
        <div className="flex flex-wrap content-top h-8 ml-2 mt-1 justify-contents-end" >
          {renderButton()}
        </div>
      </Asset>
    </li>
  )
}

export default SelectSharedAssetListItem
