import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import _ from "lodash"
import Button from "../../../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../../../shared/state/sharedTypes"
import { IconComment } from "../../../../../../svg/icons"
import { CommentTypes, ICommentNewData } from "../../../../../assets/state/assetsTypes"
import { caseCommentNewValidation } from "../../../../state/validation/caseCommentNewValidation"
import { createCaseCommentAction } from "../../../../../assets/state/assetsActions"
import DenotesRequiredMessage from "../../../../../../shared/components/forms/DenotesRequiredMessage"
import Buttons from "../../../../../../shared/components/layout/Buttons"
import CaseCommentFields from "./CaseCommentFields"
import { IUser } from "../../../../../user/state/userTypes"
import { useParams } from "react-router"
import { IHaveMeetingCaseId } from "../../../../../meetingCase/state/meetingCaseTypes"

interface IProps {
  caseId: string
  closeAction: () => void
  mentionableUsers: { [id: string]: IUser }
  switchToNewAssetTab: () => void
}


const CaseDecisionNewForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const { meetingCaseId } = useParams<IHaveMeetingCaseId>()
  const dispatch = useDispatch()
  const createComment = (caseId: string, comment: ICommentNewData, meetingCaseId: string) =>
    dispatch(createCaseCommentAction(caseId, comment, meetingCaseId))

  const initialValues: ICommentNewData = {
    commentType: CommentTypes.Decision,
    title: "",
    body: "",
    meetingCaseId
  }

  return (
    <div>
      <h4 className="flex mb-2">
        <IconComment className="w-6 h-6 mr-2 text-ch-purple-500" title={t("case:addDecision")} />
        {t("case:addDecision")}
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={caseCommentNewValidation}
        onSubmit={(values, { setSubmitting }) => {
          createComment(props.caseId, values, meetingCaseId)
          setSubmitting(false)
          props.closeAction()
          props.switchToNewAssetTab()
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          errors,
          values,
          touched,
          handleChange,
          handleBlur
        }) => {
          return (
            <Form className="w-full">
              <DenotesRequiredMessage />

              <CaseCommentFields
                users={props.mentionableUsers}
                errors={errors}
                touched={touched}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />

              <Buttons
                className="mb-2"
                buttons={[
                  <Button
                    action={() => { props.closeAction() }}
                    variant={StyleVariants.BLUE_ALT}
                    key="1"
                  >
                    {t("shared:cancel")}
                  </Button>,
                  <Button
                    isDisabled={isSubmitting}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                    key="2"
                  >
                    {t("case:addDecision")}
                  </Button>
                ]}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CaseDecisionNewForm
