import { Reducer, AnyAction } from "redux"
import { AccountActionTypes } from "../../../account/state/accountTypes"
import { MfaActionTypes } from "../../../mfa/state/mfaTypes"
import { IUserSessionState } from "../userTypes"

export const initialState: IUserSessionState = {
  id: null,
  apiToken: null,
  signInType: null,
  isLoading: false,
  error: null,
  terms: null,
  mfa: {
    sms: {
      requesting: false
    }
  }
}

export const userSessionReducer: Reducer<IUserSessionState> = (state: IUserSessionState = initialState, action: AnyAction): IUserSessionState => {
  switch (action.type) {
    case MfaActionTypes.REQUEST_SMS_ASYNC_PENDING:
      return {
        ...state,
        mfa: {
          sms: {
            requesting: true
          }
        }
      }

    case MfaActionTypes.REQUEST_SMS_ASYNC_SUCCESS:
    case MfaActionTypes.REQUEST_SMS_ASYNC_ERROR:
      return {
        ...state,
        mfa: {
          sms: {
            requesting: false
          }
        }
      }

    case AccountActionTypes.SIGN_IN_USER_ASYNC_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case AccountActionTypes.SIGN_IN_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.session,
        isLoading: false,
        error: null
      }

    case AccountActionTypes.SIGN_OUT_USER_ASYNC_SUCCESS:
      return initialState

    case AccountActionTypes.SIGN_IN_USER_ASYNC_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.statusText
      }

    case AccountActionTypes.USER_SIGN_IN_TYPE_ASYNC_SUCCESS:
      return {
        ...state,
        signInType: action.payload.signInType
      }

    case AccountActionTypes.SEND_RESET_PASSWORD_LINK_ASYNC_SUCCESS:
      return initialState

    case AccountActionTypes.FETCH_TERMS_ASYNC_SUCCESS:
      return {
        ...state,
        terms: action.payload
      }

    default:
      return { ...state }
  }
}
