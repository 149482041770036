import { isValidPhoneNumber } from "react-phone-number-input"
import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const caseGuestUserNewValidationSchema = (usersEmail: string[]): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", { maxLength: 50 })),
    lastName: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", { maxLength: 50 })),
    email: Yup.string()
      .trim()
      .email(i18n.t("shared:invalidEmail"))
      .required(i18n.t("shared:required"))
      .notOneOf(usersEmail, i18n.t("shared:emailExists")),
    phoneNumber: Yup.string()
      .required(i18n.t("shared:required"))
      .test("phoneValid", i18n.t("shared:invalidPhone"), function (value) {
        if (!value) return false
        return isValidPhoneNumber(value)
      })
  })
}
