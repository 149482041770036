import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconChangeTracking({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M3.001 10.12h6.78L7.041 7.3c2.73-2.7 7.15-2.8 9.88-.1a6.875 6.875 0 010 9.79 7.02 7.02 0 01-9.88 0c-1.36-1.34-2.04-2.91-2.04-4.89h-2c0 1.98.88 4.55 2.64 6.29 3.51 3.48 9.21 3.48 12.72 0 3.5-3.47 3.53-9.11.02-12.58a8.987 8.987 0 00-12.65 0L3.001 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconChangeTracking;
