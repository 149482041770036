import { CableActionMeta } from "../../../shared/@types/redux-cablecar"
import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { IAssetMeeting } from "../../assets/state/assetsTypes"
import { IUser } from "../../user/state/userTypes"

// Models
export interface IMessage {
  id: string
  chatId: string
  body: string
  createdAt: string
  createdBy: ICreatedBy
  readAt: string
  readBy: IUser[]
  recipientCount: number
  messageAssets: string[]
}

export interface IMessageAssetBase {
  createdAt: string
  createdBy: ICreatedBy
  messageId: string
  meeting?: IAssetMeeting
  meetingId?: string
  summary?: string
}

export interface IMessageDocumentAsset extends IMessageAssetBase {
  name: string
  bytes: number
  path: string
  contentType: string
}

export interface IMessageAsset {
  id: string
  assetType: string
  messageId: string
  createdAt: string
  createdBy: ICreatedBy
  asset: IMessageDocumentAsset
}

// State
export interface IHaveMessageId {
  messageId: string
}

export interface IHaveMessageAssetId {
  messageAssetId: string
}

export interface INewMessageAssetData {
  assetType: string
  name?: string
  file?: File
}

export interface INewMessageData {
  body: string
  assetsAttributes?: INewMessageAssetData[]
}

// Actions
export interface ICreateChatMessageAsyncAction {
  type: string,
  payload: {
    chatId: string
    message: INewMessageData
  }
}

export interface IMarkMessageAsReadAction {
  type: string
  payload: {
    messageId: string
  }
  meta: CableActionMeta
}

// Action Types
export enum ChatMessageActionTypes {
  CREATE_CHAT_MESSAGE_ASYNC_PENDING = "@@messages/CREATE_CHAT_MESSAGE_ASYNC_PENDING",
  CREATE_CHAT_MESSAGE_ASYNC_SUCCESS = "@@messages/CREATE_CHAT_MESSAGE_ASYNC_SUCCESS",
  CREATE_CHAT_MESSAGE_ASYNC_ERROR = "@@messages/CREATE_CHAT_MESSAGE_ASYNC_ERROR",
}
