import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IUser } from "../../../user/state/userTypes"
import { getTaskUsers } from "../../../../shared/selectors/task"
import { IAppState } from "../../../../app/appTypes"
import { PendingLabel } from "../../../../shared/components/label/PendingLabel"
import { useTranslation } from "react-i18next"

interface IProps {
  taskId: string
}

const AssigneeList: React.FC<IProps> = (props: IProps) => {
  const assignees = useSelector((state: IAppState) => getTaskUsers(state, props))
  const { t } = useTranslation(["shared", "task"])
  if (!props.taskId) return null

  if ((Object.keys(assignees).length <= 0)) {
    return (
      <>
        <em>{t("task:notAssigned")}</em>
      </>
    )
  }
  const renderListText = (): JSX.Element[] => {
    return Object.values(assignees).map((assignee: IUser, i, { length }) => {
      return (
        <span key={assignee.id}>
          <span>{assignee.firstName} {assignee.lastName} <PendingLabel isPending={assignee.pending} />
            {length - 1 != i ? ", " : null}
          </span>
        </span>
      )
    })
  }

  return (
    <>
      {renderListText()}
    </>
  )
}

export default AssigneeList
