import React from "react"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import ListBackground from "../../../../../shared/components/layout/ListBackground"
import { getAllCasePathwayIds } from "../../../../../shared/selectors/pathway"
import CasePathwayListItem from "./CasePathwayListItem"
import ModalContainer from "../../../../../shared/components/modals/ModalContainer"
import CaseAssetViewer from "../../assetViewer/CaseAssetViewer"
import { defaultModalStyle } from "../../../../../shared/state/sharedTypes"

interface Props {
  caseId: string
}

const CasePathwayList = (props: Props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const casePathwayIds: string[] = useSelector((state: IAppState) => getAllCasePathwayIds(state, props))
  const [isAssetModalOpen, setAssetModalIsOpen] = React.useState(false)
  const [selectedAssetId, setSelectedAssetId] = React.useState(null)
  const openAssetModal = (selectedAssetId: string): void => {
    setSelectedAssetId(selectedAssetId)
    setAssetModalIsOpen(true)
  }

  const closeAssetModal = (): void => {
    setAssetModalIsOpen(false)
  }

  if (!casePathwayIds || casePathwayIds.length == 0) return (
    <div>
      <h3 className="text-center text-ch-blue-alt-400">
        {t("case:casePathways:noPathways")}
      </h3>
      <ListBackground />
    </div>
  )

  return (
    <>
      <Modal isOpen={isAssetModalOpen} onRequestClose={closeAssetModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeAssetModal}>
          <CaseAssetViewer assetId={selectedAssetId} />
        </ModalContainer>
      </Modal>
      <ul>
        {casePathwayIds.map((casePathwayId: string) => {
          return (
            <CasePathwayListItem
              key={casePathwayId}
              casePathwayId={casePathwayId}
              openAssetModal={openAssetModal}
            />
          )
        })}
      </ul>
    </>
  )
}

export default CasePathwayList
