import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconMicOff({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M26.667 15.111h-1.778c0 1.6-.445 3.111-1.245 4.445l1.334 1.333c1.066-1.778 1.6-3.733 1.689-5.778zm-4.445 0V8a6.13 6.13 0 00-6.31-6.133c-2.579 0-4.979 1.689-5.779 4.177L21.69 17.6c.355-.8.533-1.689.533-2.489zm.178 8.533l6.133 6.134 1.245-1.245L3.556 2.311 2.31 3.556l7.467 7.466v4.09A6.184 6.184 0 0016 21.332c1.156 0 2.222-.266 3.2-.889l1.956 1.956C19.644 23.467 17.866 24 16 24c-4.8.178-8.711-3.644-8.889-8.356v-.533H5.333c.09 5.511 4.267 10.133 9.778 10.667v2.666h-2.667c-.533 0-.888.356-.888.89 0 .533.355.888.888.888h7.112c.533 0 .888-.355.888-.889 0-.533-.355-.889-.888-.889h-2.667v-2.666c1.955-.178 3.911-.89 5.511-2.134z"
        />
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconMicOff;
