import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export default interface IPatientsUIState {
  totalPatientsCount: number
  totalPages: number
  resultsPerPage: number
  searchTerm: string
  orderBy: string
}

export enum PatientsOrderByOptions {
  CreatedAtDesc = "created_at_desc",
  CreatedAtAsc = "created_at_asc",
  UpdatedAtDesc = "updated_at_desc",
}

// Actions
export interface IFetchPatientsAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IFetchAllPatientsAsyncAction {
  type: string
}

export enum PatientsActionTypes {
  FETCH_PATIENTS_ASYNC_PENDING = "@@patients/FETCH_PATIENTS_ASYNC_PENDING",
  FETCH_PATIENTS_ASYNC_SUCCESS = "@@patients/FETCH_PATIENTS_ASYNC_SUCCESS",
  FETCH_PATIENTS_ASYNC_ERROR = "@@patients/FETCH_PATIENTS_ASYNC_ERROR",

  FETCH_ALL_PATIENTS_ASYNC_PENDING = "@@patients/FETCH_ALL_PATIENTS_ASYNC_PENDING",
  FETCH_ALL_PATIENTS_ASYNC_SUCCESS = "@@patients/FETCH_ALL_PATIENTS_ASYNC_SUCCESS",
  FETCH_ALL_PATIENTS_ASYNC_ERROR = "@@patients/FETCH_ALL_PATIENTS_ASYNC_ERROR",

  UPLOAD_PATIENTS_CSV_ASYNC_PENDING = "@@patients/UPLOAD_PATIENTS_CSV_ASYNC_PENDING",
  UPLOAD_PATIENTS_CSV_ASYNC_SUCCESS = "@@patients/UPLOAD_PATIENTS_CSV_ASYNC_SUCCESS",
  UPLOAD_PATIENTS_CSV_ASYNC_ERROR = "@@patients/UPLOAD_PATIENTS_CSV_ASYNC_ERROR",
}
