import * as Yup from "yup"
import i18n from "../../../app/i18n"

export const caseNewValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    isNewPatient: Yup.boolean(),

    caseType: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    caseClassificationId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    leadCareUserId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    sourceId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),

    patientId: Yup
      .string()
      .when("isNewPatient", {
        is: false,
        then: Yup
          .string()
          .trim().required(i18n.t("shared:required")),
      }),

    title: Yup
      .string()
      .trim()
      .when("isNewPatient", {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      }),
    firstName: Yup
      .string()
      .when("isNewPatient", {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    lastName: Yup
      .string()
      .when("isNewPatient", {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    dateOfBirth: Yup
      .string()
      .when(["isNewPatient", "dobDay", "dobMonth", "dobYear"], {
        is: (isNewPatient: boolean, dobDay: string, dobMonth: string, dobYear: string) => {
          return isNewPatient == true && (!dobDay || !dobMonth || !dobYear)
        },
        then: Yup.string().trim().required(i18n.t("shared:required"))
      }),
    healthcareId: Yup
      .string()
      .when(["isNewPatient", "isHealthcareIdRequired"], {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(20, i18n.t("shared:maxLengthExceeded", {maxLength: 20})),
    careProviderId: Yup
      .string()
      .when(["isNewPatient", "isCareProviderIdRequired"], {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(20, i18n.t("shared:maxLengthExceeded", {maxLength: 20})),
    signedOffBy: Yup
      .string()
      .when("isSignedOffByRequired", {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50}))

  })
}
