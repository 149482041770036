import { takeLeading, put, call } from "redux-saga/effects"
import { TenantConfigActionTypes } from "./tenantConfigTypes"
import API from "../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"

export function* fetchTenantConfigAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/tenant_config", withAuthHeader())

    yield put({
      type: TenantConfigActionTypes.FETCH_TENANT_CONFIG_ASYNC_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: TenantConfigActionTypes.FETCH_TENANT_CONFIG_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchTenantConfigAsync(): Generator {
  yield takeLeading(TenantConfigActionTypes.FETCH_TENANT_CONFIG_ASYNC_PENDING, fetchTenantConfigAsync)
}
