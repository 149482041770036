import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import Button from "../../../../../shared/components/buttons/Button"
import { isGuest } from "../../../../../shared/helpers/userHelpers"
import { getCaseById } from "../../../../../shared/selectors/case"
import { getUserById } from "../../../../../shared/selectors/user"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import { IUser } from "../../../../user/state/userTypes"
import { updateCaseMembers } from "../../../state/caseActions"
import { ICase } from "../../../state/caseTypes"

interface Props {
  caseId: string
  userId: string
  closeModal: () => void
}

const InviteExistingGuestUserButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "user"])
  const dispatch = useDispatch()
  const user = useSelector((state: IAppState): IUser => getUserById(state, props.userId))
  const theCase = useSelector((state: IAppState): ICase => getCaseById(state, { caseId: props.caseId }))

  const addGuestUserToCaseMembers = () => {
    const data = {
      id: theCase.id,
      memberIds: [...theCase.members, props.userId]
    }
    dispatch(updateCaseMembers(data))
    props.closeModal()
  }

  if (!theCase || !isGuest(user)) return null

  if (theCase.members.includes(props.userId)) return (
    <span>
      {t("user:userCheck:text:isAMember")}
    </span>
  )

  return (
    <Button
      action={addGuestUserToCaseMembers}
      variant={StyleVariants.ORANGE}
      key={props.userId}
    >
      {t("user:userCheck:buttons:inviteGuestToCase")}
    </Button>
  )
}

export default InviteExistingGuestUserButton
