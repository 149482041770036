import { ICreatedBy } from "../../../shared/state/sharedTypes"
import { ICasePathwayTaskUpdateData, ITask } from "../../tasks/state/tasksTypes"

export interface ICasePathway {
  id: string
  caseId: string
  pathwayId: string
  name: string
  description: string
  createdAt: string
  createdBy: ICreatedBy
  updatedAt: string
  tasks?: string[]
  dueDate?: string
  permissions: ICasePathwayPermissions
}

export interface ICasePathwayPermissions {
  canEdit: boolean
}

export interface IHaveCasePathwayId {
  casePathwayId: string
}

export interface ICasePathwayEditData {
  name?: string
  description?: string
  dueDate?: string
  casePathwayTask?: ITask
}

// Actions
export interface ICreateCasePathwayAsyncAction {
  type: string
  payload: {
    caseId: string,
    pathwayId: string
  }
}

export interface IFetchCasePathwaysAsyncAction {
  type: string,
  payload: string
}

export interface IUpdateCasePathwayAsyncAction {
  type: string
  payload: {
    caseId: string
    casePathwayId: string
    casePathway: ICasePathwayEditData
  }
}

export interface IUpdateCasePathwayTaskAsyncAction {
  type: string
  payload: {
    taskId: string
    caseId: string
    task: ICasePathwayTaskUpdateData
  }
}
// Action Types
export enum CasePathwaysActionTypes {
  FETCH_CASE_PATHWAYS_ASYNC_PENDING = "@@casePathways/FETCH_CASE_PATHWAYS_ASYNC_PENDING",
  FETCH_CASE_PATHWAYS_ASYNC_SUCCESS = "@@casePathways/FETCH_CASE_PATHWAYS_ASYNC_SUCCESS",
  FETCH_CASE_PATHWAYS_ASYNC_ERROR = "@@casePathways/FETCH_CASE_PATHWAYS_ASYNC_ERROR",

  CREATE_CASE_PATHWAY_ASYNC_PENDING = "@@casePathways/CREATE_CASE_PATHWAY_ASYNC_PENDING",
  CREATE_CASE_PATHWAY_ASYNC_SUCCESS = "@@casePathways/CREATE_CASE_PATHWAY_ASYNC_SUCCESS",
  CREATE_CASE_PATHWAY_ASYNC_ERROR = "@@casePathways/CREATE_CASE_PATHWAY_ASYNC_ERROR",

  UPDATE_CASE_PATHWAY_ASYNC_PENDING = "@@casePathways/UPDATE_CASE_PATHWAY_ASYNC_PENDING",
  UPDATE_CASE_PATHWAY_ASYNC_SUCCESS = "@@casePathways/UPDATE_CASE_PATHWAY_ASYNC_SUCCESS",
  UPDATE_CASE_PATHWAY_ASYNC_ERROR = "@@casePathways/UPDATE_CASE_PATHWAY_ASYNC_ERROR",

  UPDATE_CASE_PATHWAY_TASK_ASYNC_PENDING = "@@casePathways/UPDATE_CASE_PATHWAY_TASK_ASYNC_PENDING",
  UPDATE_CASE_PATHWAY_TASK_ASYNC_SUCCESS = "@@casePathways/UPDATE_CASE_PATHWAY_TASK_ASYNC_SUCCESS",
  UPDATE_CASE_PATHWAY_TASK_ASYNC_ERROR = "@@casePathways/UPDATE_CASE_PATHWAY_TASK_ASYNC_ERROR",
}
