import classNames from "classnames"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import Card from "../../../../shared/components/layout/Card"
import { getPatientById } from "../../../../shared/selectors/patients"
import PatientCaseListItem from "./PatientCaseListItem"

interface IProps {
  patientId: string
}

const PatientCases: React.FC<IProps> = (props) => {
  const [isVisibleList, toggleList] = useState(true)
  const { t } = useTranslation(["shared", "patient"])
  const patient = useSelector((state: IAppState) => getPatientById(state, props))
  if (!patient || !patient.cases) return null

  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("patient:cases:sectionTitle")}
          </h3>
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            {t("patient:cases:intro")}
          </p>

          <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("patient:cases:show")} collapseText={t("patient:cases:hide")} />
        </div>

        <div className={classNames({ hidden: !isVisibleList })}>
          <ul>
            {_.map(patient.cases, (caseId) => {
              return <PatientCaseListItem key={caseId} caseId={caseId} />
            })}
          </ul>
        </div>
      </Card>
    </div>
  )
}

export default PatientCases
