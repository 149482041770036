import API from "../../../apis/contextmeeting/api"
import { userSchema } from "../../../apis/contextmeeting/schema"
import { UsersActionTypes } from "./usersActionTypes"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { normalize } from "normalizr"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import humps from "humps"
import queryString from "query-string"

export function* fetchUsersAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/users?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [userSchema])
    yield put({
      type: UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        searchTerm: response.data.searchQuery.searchTerm,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        appliedFilters: response.data.searchQuery.appliedFilters,
        orderBy: response.data.searchQuery.orderBy
      }
    })
  } catch (error) {
    yield put({
      type: UsersActionTypes.FETCH_USERS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "usersNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchUsersAsync(): Generator {
  yield takeLeading(UsersActionTypes.FETCH_USERS_ASYNC_PENDING, fetchUsersAsync)
}

export function* fetchAllUsers(): Generator {
  try {
    const response: any = yield call(API.get, "/users?paged=false", withAuthHeader())

    const normalizedResponse = normalize(response.data, [userSchema])

    yield put({
      type: UsersActionTypes.FETCH_ALL_USERS_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: UsersActionTypes.FETCH_ALL_USERS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchAllUsers(): Generator {
  yield takeLeading(UsersActionTypes.FETCH_ALL_USERS_ASYNC_PENDING, fetchAllUsers)
}
