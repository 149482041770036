import React from "react"
import { IPathwayStep } from "../../pathwayTypes"

type Props = {
  step: IPathwayStep
}

export const StepDetails: React.FC<Props> = (props: Props): JSX.Element => {
  if (!props.step) return null

  return (
    <div className="flex-1 lg:grid grid-cols-1 gap-3">
      <ul className="col-span-1">
        <li className="font-semibold">
          {props.step.position}. {props.step.name}
        </li>
        <li>
          {props.step.description}
        </li>
      </ul>
    </div>
  )
}

export default StepDetails
