import React, { ReactNode } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import { MockStore } from "redux-mock-store"
import { IRecommendationAsset } from "../../state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IconRecommendation, IconCalendar } from "../../../../svg/icons"
import MentionFieldReadOnly from "../../../../shared/components/forms/MentionFieldReadOnly"
import { getAllUsers, getCurrentUser } from "../../../../shared/selectors/user"
import { IUser } from "../../../user/state/userTypes"
import RecommendationConsentItem from "./RecommendationConsentItem"
import TimeDefaultFormat from "../../../../shared/components/time/TimeDefaultFormat"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import { ConsentResponse, RecommendationStatus } from "../../../liveMeeting/state/liveMeetingTypes"
import { saveCaseRecommendationAction } from "../../state/assetsActions"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import _ from "lodash"
import { IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import AssetSubHeader from "./AssetSubHeading"
import { useSelector } from "react-redux"

interface Props {
  caseId: string
  store?: MockStore
  assetId: string
  action?: () => void
  isSelected?: boolean
  mentionableUsers?: { [id: string]: IUser }
  meetingCaseAsset?: IMeetingCaseAsset
  meetingId?: string
  children?: ReactNode
  hideConsentList?: boolean
}

const Recommendation: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["asset", "shared"])
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const currentUser = useSelector(getCurrentUser)
  const users = useSelector(getAllUsers)
  const dispatch = useDispatch()

  const saveRecommendation = (recommendationId: string) => dispatch(saveCaseRecommendationAction(recommendationId))
  if (!currentUser) { return null }

  const recommendation = asset.asset as IRecommendationAsset
  const isPending = recommendation.recommendationStatus == RecommendationStatus.PENDING

  const pendingLabel = isPending
    ? (<div className="font-bold text-ch-blue-600 uppercase"> {t("asset:pending")} </div>)
    : null

  const allowedToSave = isPending && currentUser.id == recommendation.createdBy.id
  const readyToSave = recommendation.consents.filter(({ response }) => (response == ConsentResponse.PENDING)).length == 0

  const saveButton = allowedToSave
    ? <Button variant={StyleVariants.PURPLE} isDisabled={!readyToSave} action={() => { saveRecommendation(recommendation.id) }}>
      {t("shared:saveRecommendation")}
    </Button>
    : null

  const consentList = () => {
    if (props.hideConsentList) { return null }
    const orderedConsents = _.orderBy(recommendation.consents, [(c) => (c.user.lastName), (c) => (c.user.firstName)])
    return <ul>
      {orderedConsents.map((consent) => (
        <RecommendationConsentItem caseId={props.caseId} mentionableUsers={props.mentionableUsers} consent={consent} key={consent.user.id} recommendation={recommendation} />
      ))}
    </ul>
  }

  const deadline = () => {
    if (recommendation.deadline == null || !isPending) return null
    const date = recommendation.deadline
    return (
      <div className="flex items-center">
        <IconCalendar className="text-ch-blue-600 mx-2 w-5 h-5" />
        <div className="flex-1">
          {t("approvalDeadline")}: <TimeDefaultFormat utcTime={date} />
        </div>
        {saveButton}
      </div>
    )
  }
  return (
    <div>
      <div
        onClick={props.action ? () => props.action() : null}
        className={classNames(
          "p-2",
          "rounded-md",
          "border-2",
          {
            "bg-ch-blue-200": !isPending,
            "border-ch-blue-200": !props.isSelected,
            "border-ch-blue-500": props.isSelected,
            "hover:border-ch-blue-500": props.action,
            "cursor-pointer": props.action,
            "cursor-default": !props.action
          }
        )}
      >
        <div className="flex">
          <div className="text-ch-purple-500 mr-2 mt-1">
            <IconRecommendation className="w-5 h-5" title={recommendation.title} />
          </div>
          <div className="flex-1">
            <div className="flex">
              <h4 className="font-semibold text-lg flex-1">
                {recommendation.title}
              </h4>
              {pendingLabel}
            </div>
            <AssetSubHeader
              meetingCaseAsset={props.meetingCaseAsset}
              meetingId={props.meetingId}
              asset={asset}
            />
            <MentionFieldReadOnly
              value={recommendation.body}
              users={users}
            />
            {consentList()}
            {deadline()}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Recommendation
