import React, { useEffect } from "react"
import { generatePath, useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IHaveCaseId } from "../../features/case/state/caseTypes"
import { IAppState } from "../../app/appTypes"
import { getCaseById } from "../../shared/selectors/case"
import { fetchCaseAction } from "../../features/case/state/caseActions"
import CaseEditForm from "../../features/caseEdit/components/CaseEditForm"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { RoutePaths } from "../../app/routes/Routes"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import { fetchCaseSourcesAction } from "../../features/sources/state/caseSourceActions"
import { fetchCaseClassificationAction } from "../../features/caseClassification/state/caseClassificationActions"

const CaseEditPage: React.FC = (): JSX.Element => {
  const { caseId } = useParams<IHaveCaseId>()
  const { t } = useTranslation(["shared", "case"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, { caseId }))

  const dispatch = useDispatch()
  const fetchUsers = () => dispatch(fetchAllUsersAsync())
  const fetchCaseClassification = () => dispatch(fetchCaseClassificationAction())
  const fetchCase = (caseId: string) => dispatch(fetchCaseAction(caseId))
  const fetchCaseSources = () => dispatch(fetchCaseSourcesAction())

  useEffect(() => {
    fetchCase(caseId)
    fetchCaseClassification()
    fetchUsers()
    fetchCaseSources()
  }, [])

  const caseUrl = generatePath(RoutePaths.CASE, {
    caseId: caseId
  })

  const renderContent = (): JSX.Element => {
    if (!theCase) return <h3 className="text-lg">{t("case:caseNotFound")}</h3>

    if (!theCase.permissions.canEdit) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    return (
      <div>
        <CaseEditForm caseId={caseId} />
      </div>
    )
  }

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:case"),
            href: caseUrl
          },
          {
            pageTitle: t("case:editCase")
          }
        ]}
      />

      {renderContent()}
    </div>
  )
}

export default CaseEditPage
