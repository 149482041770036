import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import { PathwayActionTypes, IAddPathwayUsersAction } from "../../pathwayTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { pathwaysSchema } from "../../../../apis/contextmeeting/schema"

export function* addPathwayUsersAsync(action: IAddPathwayUsersAction): Generator {
  try {
    const pathwayId = action.payload.pathwayId

    const response: any = yield call(API.post, `/pathways/${pathwayId}/users`, { users: action.payload.users }, withAuthHeader())

    const normalizedResponse = normalize(response.data, pathwaysSchema)

    yield put({
      type: PathwayActionTypes.ADD_PATHWAY_USERS_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "addPathwayUsersSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: PathwayActionTypes.ADD_PATHWAY_USERS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "addPathwayUsersFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchAddPathwayUsersAsync(): Generator {
  yield takeLeading(PathwayActionTypes.ADD_PATHWAY_USERS_ASYNC_PENDING, addPathwayUsersAsync)
}
