import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import history from "../../../../app/routes/history"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import API from "../../../../apis/contextmeeting/api"
import { patientsSchema } from "../../../../apis/contextmeeting/schema"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { RoutePaths } from "../../../../app/routes/Routes"
import { PatientEditActionTypes } from "../patientEditTypes"
import { generatePath } from "react-router"

export function* updatePatientAsync(action: Effect): Generator {
  try {
    const data = {
      patient: action.payload.patient
    }

    const response: any = yield call(API.patch, `/patients/${action.payload.id}`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, patientsSchema)
    yield put({
      type: PatientEditActionTypes.UPDATE_PATIENT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "updatePatientSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const patientUrl = generatePath(RoutePaths.PATIENT, {
      patientId: action.payload.id
    })

    yield call(history.push, patientUrl)
  } catch (error) {
    yield put({
      type: PatientEditActionTypes.UPDATE_PATIENT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updatePatientFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUpdatePatientAsync(): Generator {
  yield takeLeading(PatientEditActionTypes.UPDATE_PATIENT_ASYNC_PENDING, updatePatientAsync)
}

export function* addCaseForPatientAsync(action: Effect): Generator {
  try {
    const data = {
      patient: action.payload.patient
    }

    const response: any = yield call(API.patch, `/patients/${action.payload.id}/add_case`, data, withAuthHeader())

    const normalizedResponse = normalize(response.data, patientsSchema)
    yield put({
      type: PatientEditActionTypes.ADD_CASE_FOR_PATIENT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "addCaseForPatientSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const patientUrl = generatePath(RoutePaths.PATIENT, {
      patientId: action.payload.id
    })

    yield call(history.push, patientUrl)
  } catch (error) {
    yield put({
      type: PatientEditActionTypes.ADD_CASE_FOR_PATIENT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "addCaseForPatientFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchAddCaseForPatientAsync(): Generator {
  yield takeLeading(PatientEditActionTypes.ADD_CASE_FOR_PATIENT_ASYNC_PENDING, addCaseForPatientAsync)
}
