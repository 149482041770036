import React from "react"
import Bowser from "bowser"
import WarningBanner from "../text/WarningBanner"
import { useTranslation } from "react-i18next"

const BrowserWarning: React.FC = () => {
  const { t } = useTranslation(["shared"])
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isValidBrowser = browser.satisfies({
    mobile: {
      edge: ">=100"
    },
    // or in general
    chrome: ">=105",
    edge: ">=105",
    safari: ">=15.6"
  })

  if (isValidBrowser) return

  return (
    <WarningBanner>
      <span>{t("shared:browserWarning")}</span>
    </WarningBanner>
  )
}


export default BrowserWarning
