import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ICase } from "../../state/caseTypes"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { IAppState } from "../../../../app/appTypes"
import { getCaseById, getLeadCareUserByCaseId } from "../../../../shared/selectors/case"
import TextCaseType from "../../../../shared/components/text/TextCaseType"
import { useSelector } from "react-redux"

interface IProps {
  caseId: string
}

const CaseDetailsSimple: React.FC<IProps> = (props: IProps): JSX.Element => {
  const theCase: ICase = useSelector((state: IAppState) => getCaseById(state, props))
  const leadCareUser = useSelector((state: IAppState) => getLeadCareUserByCaseId(state, props))

  const { t } = useTranslation(["shared", "case"])
  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  const renderPatientName = (): JSX.Element =>
  (
    <li>
      <h4 className="font-semibold">
        <NameUser
          firstName={theCase.firstName}
          lastName={theCase.lastName}
          title={theCase.title}
          gender={theCase.gender}
        />
      </h4>
    </li>
  )

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("case:dateOfBirth")}: <TimeDateOfBirth utcTime={theCase.dateOfBirth} />
      </li>
    )
  }

  const renderPatientHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {theCase.healthcareId || "-"}
      </li>
    )
  }

  const renderPatientCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {theCase.careProviderId || "-"}
      </li>
    )
  }

  const renderCaseType = (): JSX.Element => {
    return (
      <li>
        {t("case:type")}: <TextCaseType caseType={theCase.caseType} />
      </li>
    )
  }

  const renderLeadCare = (): JSX.Element => {
    if (!leadCareUser) return (
      <li>
        {t("case:leadCare")}: -
      </li>
    )
    return (
      <li>
        {t("case:leadCare")}: <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          professionalTitle={leadCareUser.professionalTitle}
        />
      </li>
    )
  }

  const renderClassification = (): JSX.Element => {
    return (
      <li>
        {t("shared:classification")}: {theCase.classification}
      </li>
    )
  }

  if (!theCase || !theCase.id) return null
  return (
    <div className="flex-1 lg:grid grid-cols-2 gap-3" >
      <ul className="col-span-1">
        {renderPatientName()}
        {renderDateOfBirth()}
        {renderPatientHealthcareId()}
        {renderPatientCareProviderId()}
      </ul>

      <ul className="col-span-1">
        {renderCaseType()}
        {renderLeadCare()}
        {renderClassification()}
      </ul>
    </div >
  )
}

export default CaseDetailsSimple
