import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export default interface IChatsUIState {
  selectedChatId: string
  totalItems: number
  totalPages: number
  resultsPerPage: number
}

// Actions
export interface IFetchChatsAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IUpdateSelectedChatId {
  type: string
  payload: string
}

// Action Types
export enum ChatsActionTypes {
  UPDATED_SELECTED_CHAT_ID = "@@chats/UPDATED_SELECTED_CHAT_ID",
  FETCH_CHATS_ASYNC_PENDING = "@@chats/FETCH_CHATS_ASYNC_PENDING",
  FETCH_CHATS_ASYNC_SUCCESS = "@@chats/FETCH_CHATS_ASYNC_SUCCESS",
  FETCH_CHATS_ASYNC_ERROR = "@@chats/FETCH_CHATS_ASYNC_ERROR"
}
