import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { IAppState } from "../../../app/appTypes"
import { getPathwaysAddableToCaseIds } from "../../../shared/selectors/pathway"
import AddPathwayToCaseListItem from "./AddPathwayToCaseListItem"

interface Props {
  caseId: string
  closeModal: () => void
}

const AddPathwayToCaseList = (props: Props): JSX.Element => {
  const { t } = useTranslation(["pathway"])
  const pathwayIds: string[] = useSelector((state: IAppState) => getPathwaysAddableToCaseIds(state))

  if (!pathwayIds) return (
    <h3 className="text-lg font-bold text-center">
      { t("pathway:addPathwayToCase:noPathways") }
    </h3>
  )

  return (
    <ul>
      {pathwayIds.map((pathwayId: string) => {
        return (
          <AddPathwayToCaseListItem
            key={pathwayId}
            caseId={props.caseId}
            pathwayId={pathwayId}
            closeModal={props.closeModal}
          />
        )
      })}
    </ul>
  )
}

export default AddPathwayToCaseList
