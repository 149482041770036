import { createSelector } from "reselect"
import { getLiveMeetingConfig } from ".."
import { ISharedImageZoomState } from "../../../../features/liveMeeting/state/liveMeetingTypes"
import IAssetTypeFilters from "../../../types/AssetTypeFilters"

export const getLiveMeetingSharedViewSelectedAssetId = createSelector(
  getLiveMeetingConfig,
  (liveMeetingConfig): string => {
    return liveMeetingConfig.sharedViewSelectedAssetId
  }
)

export const getLiveMeetingSharedViewSelectedAssetTypeFilters = createSelector(
  getLiveMeetingConfig,
  (liveMeetingConfig): IAssetTypeFilters => {
    return liveMeetingConfig.sharedViewSelectedAssetTypeFilters
  }
)

export const getLiveMeetingSharedImageZoomState = createSelector(
  getLiveMeetingConfig,
  (liveMeetingConfig): ISharedImageZoomState => {
    return liveMeetingConfig.sharedImageZoomState
  }
)
