import React from "react"
import _ from "lodash"
import { Mention, MentionsInput } from "react-mentions"
import { IUser } from "../../../features/user/state/userTypes"
import { formatUserMentionString } from "../../helpers/stringHelpers"
import { allUser } from "./MentionField"

interface IProps {
  className?: string
  textColorClass?: string
  value: string
  height?: number
  users: { [id: string]: IUser }
  isSingleLine?: boolean
}

const defaultProps = {
  isSingleLine: false,
  textColorClass: "text-ch-gray-600"
}

const MentionFieldReadOnly: React.FC<IProps> = (props: IProps): JSX.Element => {
  const renderContent = (): JSX.Element => {
    const prefixClass = "mentions-read-only"
    const defaultClasses = [prefixClass, props.textColorClass]
    const propClasses = props.className ? props.className.split(" ") : []
    const allClasses = [...defaultClasses, ...propClasses].join(" ")

    const renderMentionText = (id, _display): string => {
      if (id == "all") return formatUserMentionString(allUser)
      if (!props.users[id]) return ""
      return formatUserMentionString(props.users[id])
    }

    return (
      <MentionsInput
        value={props.value}
        className={allClasses}
        singleLine={props.isSingleLine}
      >
        <Mention
          className="bg-ch-blue-alt-200"
          trigger="@"
          markup="@[__display__](__id__)"
          displayTransform={(id, display) => renderMentionText(id, display)}
          data={[]}
        />
      </MentionsInput>
    )
  }

  return renderContent()
}

MentionFieldReadOnly.defaultProps = defaultProps

export default MentionFieldReadOnly
