export interface ICreateMeetingCaseAssetAsyncAction {
  type: string
  payload: {
    meetingCaseId: string
    caseAssetId: string
  }
}

export interface ICreateAllMeetingCaseAssetsAction {
  type: string
  payload: {
    meetingCaseId: string
  }
}

export interface IDeleteMeetingCaseAssetAsyncAction {
  type: string
  payload: {
    meetingCaseId: string
    meetingCaseAssetId: string
  }
}

export enum MeetingCaseAssetsActionTypes {
  CREATE_MEETING_CASE_ASSET_ASYNC_PENDING = "@@meetingCaseAssets/CREATE_MEETING_CASE_ASSET_ASYNC_PENDING",
  CREATE_MEETING_CASE_ASSET_ASYNC_SUCCESS = "@@meetingCaseAssets/CREATE_MEETING_CASE_ASSET_ASYNC_SUCCESS",
  CREATE_MEETING_CASE_ASSET_ASYNC_ERROR = "@@meetingCaseAssets/CREATE_MEETING_CASE_ASSET_ASYNC_ERROR",

  CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_PENDING = "@@meetingCaseAssets/CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_PENDING",
  CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_SUCCESS = "@@meetingCaseAssets/CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_SUCCESS",
  CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_ERROR = "@@meetingCaseAssets/CREATE_ALL_MEETING_CASE_ASSETS_ASYNC_ERROR",

  DELETE_MEETING_CASE_ASSET_ASYNC_PENDING = "@@meetingCaseAssets/DELETE_MEETING_CASE_ASSET_ASYNC_PENDING",
  DELETE_MEETING_CASE_ASSET_ASYNC_SUCCESS = "@@meetingCaseAssets/DELETE_MEETING_CASE_ASSET_ASYNC_SUCCESS",
  DELETE_MEETING_CASE_ASSET_ASYNC_ERROR = "@@meetingCaseAssets/DELETE_MEETING_CASE_ASSET_ASYNC_ERROR"
}
