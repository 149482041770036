import React from "react"
import { useSelector } from "react-redux"
import CaseListItemContainer from "../../cases/components/list/CaseListItemContainer"
import DetailsCardButtons from "../../../shared/components/layout/detailsCard/DetailsCardButtons"
import { getCaseById } from "../../../shared/selectors/case"
import { IAppState } from "../../../app/appTypes"
import { getMeetingCaseByMeetingAndCaseId } from "../../../shared/selectors/meetingCase"
import AddCaseToMeetingButton from "./AddCaseToMeetingButton"

interface IProps {
  caseId: string
  meetingId: string
}

const CaseListItem: React.FC<IProps> = (props: IProps) => {
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseByMeetingAndCaseId(state, props))
  if (!theCase) return null

  return (
    <CaseListItemContainer caseId={props.caseId} key={props.caseId} isDark={meetingCase != null}>
      <DetailsCardButtons buttons={[
        <AddCaseToMeetingButton caseId={props.caseId} meetingId={props.meetingId} key="1" />
      ]} />
    </CaseListItemContainer>
  )
}

export default CaseListItem
