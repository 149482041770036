import React from "react"
import { useTranslation } from "react-i18next"

interface IPropsFromParent {
  meetingJoinedCount: number
}

export const MeetingUserAttendanceLabel: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["user"])

  return (
    <span className={"text-sm text-white px-2  ml-1 uppercase " + (props.meetingJoinedCount > 0 ? "rounded bg-ch-green-500" : "rounded bg-ch-purple-500")}>
      {props.meetingJoinedCount > 0 ? t("user:attended") : t("user:notAttended")}
    </span>
  )
}
