import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import Tabs, { ITab } from "../../../../shared/components/ui/Tabs"
import { getCaseById } from "../../../../shared/selectors/case"
import CaseAssetList from "../assetList/CaseAssetList"
import CaseMeetingList from "../meetingList/CaseMeetingList"
import Modal from "react-modal"
import { IUser } from "../../../user/state/userTypes"
import CaseTaskList from "../taskList/list/CaseTaskList"
import { bottomModalStyle, StyleVariants } from "../../../../shared/state/sharedTypes"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { useLocation, useParams } from "react-router"
import history from "../../../../app/routes/history"
import MeetingCaseAssetList from "../../../meetingCase/components/activity/MeetingCaseAssetList"
import Button from "../../../../shared/components/buttons/Button"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import CasePathwayList from "../casePathwayList/list/CasePathwayList"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import SelectMeetingAssets from "./SelectMeetingAssets"
import { isGuest } from "../../../../shared/helpers/userHelpers"
import { getCurrentUser } from "../../../../shared/selectors/user"
import ViewAssetModal from "../assetViewer/ViewAssetModal"
import WarningBanner from "../../../../shared/components/text/WarningBanner"

interface Props {
  store?: MockStore
  caseId: string
  meetingId?: string
  meetingCaseId?: string
  showMeetingsTab?: boolean
  showMeetingAssetsTab?: boolean
  mentionableUsers: { [id: string]: IUser }
  generateTabPath: (currentTab: string) => string
}

const defaultProps = {
  showMeetingsTab: true,
  showMeetingAssetsTab: false
}

export const CaseAssetTabNames = {
  MEETING_ASSETS: "meeting_assets",
  ASSETS: "assets",
  MEETINGS: "meetings",
  TASKS: "tasks",
  PATHWAYS: "pathways"
}

interface ITabUrlParams {
  currentTab: string
}

const CaseActivityPanel: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "case"])
  const currentUser = useSelector(getCurrentUser)
  const { pathwaysEnabled } = useContext(TenantConfigContext)

  const [isSelectAssetModalOpen, setIsSelectAssetModalOpen] = useState(false)
  const [isViewAssetModalOpen, setViewAssetModalIsOpen] = React.useState(false)
  const [selectedAssetId, setSelectedAssetId] = React.useState(null)

  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId: props.meetingId }))

  const { currentTab }: ITabUrlParams = useParams()
  const { pathname } = useLocation()

  const meetingsCount = theCase.meetings && theCase.meetings.length || 0
  const outstandingTasksCount = theCase.outstandingTaskCount || 0

  const openViewAssetModal = (selectedAssetId: string): void => {
    setSelectedAssetId(selectedAssetId)
    setViewAssetModalIsOpen(true)
  }

  const closeViewAssetModal = (): void => {
    setViewAssetModalIsOpen(false)
  }

  const renderSelectAssetModal = () => {
    if (!props.meetingCaseId || !props.meetingId || !theCase.assets) { return null }
    return (<Modal isOpen={isSelectAssetModalOpen} onRequestClose={() => setIsSelectAssetModalOpen(false)} style={bottomModalStyle}>
      <ModalContainer handleClose={() => setIsSelectAssetModalOpen(false)}>
        <SelectMeetingAssets
          meetingId={props.meetingId}
          caseId={props.caseId}
          meetingCaseId={props.meetingCaseId}
          openViewAssetModal={openViewAssetModal}
        />
      </ModalContainer>
    </Modal>
    )
  }
  const tabs: ITab[] = []

  if (props.showMeetingAssetsTab) tabs.push({
    label: t("shared:meetingAssets"),
    value: CaseAssetTabNames.MEETING_ASSETS,
  })

  tabs.push({
    label: t("shared:timeline"),
    value: CaseAssetTabNames.ASSETS
  })

  if (!isGuest(currentUser) && props.showMeetingsTab) tabs.push({
    label: t("shared:meetings"),
    value: CaseAssetTabNames.MEETINGS,
    count: meetingsCount
  })

  if (!isGuest(currentUser)) tabs.push({
    label: t("shared:tasks"),
    value: CaseAssetTabNames.TASKS,
    count: outstandingTasksCount,
    isCountHighlighted: true
  })

  if (!isGuest(currentUser) && pathwaysEnabled && theCase.casePathways?.length) tabs.push({
    label: t("shared:pathways"),
    value: CaseAssetTabNames.PATHWAYS,
  })

  useEffect(() => {
    const defaultTab = props.meetingCaseId ? CaseAssetTabNames.MEETING_ASSETS : CaseAssetTabNames.ASSETS
    if (!currentTab) setCurrentTab(defaultTab)
  }, [pathname])

  const setCurrentTab = (value: string) => {
    const url = props.generateTabPath(value)
    history.replace(url)
  }

  const renderSelectAssetButton = () => {
    if (![CaseAssetTabNames.ASSETS, CaseAssetTabNames.MEETING_ASSETS].includes(currentTab)) return null
    if (![MeetingStatuses.Scheduled, MeetingStatuses.InProgress].includes(meeting?.status)) return null

    return (
      <Button
        className={"text-sm max-h-8"}
        variant={StyleVariants.BLUE_ALT}
        action={() => setIsSelectAssetModalOpen(true)}>
        {t("asset:selectMeetingAssets")}
      </Button>
    )
  }

  const renderWelcomeGuestBanner = () => {
    if(!isGuest(currentUser) || theCase?.assets?.length > 0) return null

    return (
      <WarningBanner>
        <span>{t("case:guestUsers:text:welcomeNoAssetsYet")}</span>
      </WarningBanner>
    )
  }

  if (!theCase) return null

  return (
    <div>
      <ViewAssetModal
        isOpen={isViewAssetModalOpen}
        closeModal={closeViewAssetModal}
        selectedAssetId={selectedAssetId}
      />
      {renderSelectAssetModal()}
      {renderWelcomeGuestBanner()}
      <div className="flex flex-wrap justify-center sm:justify-between mb-2">
        <Tabs
          className="mb-2 sm:mb-0 flex-1"
          onClick={setCurrentTab}
          tabs={tabs}
          value={currentTab}
        />
        {!props.showMeetingAssetsTab ? null : renderSelectAssetButton()}
      </div>

      {props.meetingCaseId ?
        <div className={classNames({
          "hidden": currentTab !== CaseAssetTabNames.MEETING_ASSETS
        })}>
          <MeetingCaseAssetList
            meetingCaseId={props.meetingCaseId}
            mentionableUsers={props.mentionableUsers}
            openAssetModal={openViewAssetModal}
            caseId={props.caseId}
          />
        </div>
        : null}

      <div className={classNames({
        "hidden": currentTab !== CaseAssetTabNames.ASSETS
      })}>
        <CaseAssetList
          openAssetModal={openViewAssetModal}
          meetingCaseId={props.meetingCaseId}
          mentionableUsers={props.mentionableUsers}
          caseId={props.caseId}
          meetingId={props.meetingId}
        />
      </div>

      {!isGuest(currentUser) ?
        <div className={classNames({
          "hidden": currentTab !== CaseAssetTabNames.MEETINGS
        })}>
          <CaseMeetingList caseId={props.caseId} />
        </div>
        : null
      }

      {!isGuest(currentUser) ?
         <div className={classNames({
          "hidden": currentTab !== CaseAssetTabNames.TASKS
        })}>
          <CaseTaskList
            caseId={props.caseId}
            openAssetModal={openViewAssetModal}
          />
        </div>
        : null
      }

      {pathwaysEnabled && !isGuest(currentUser) ?
        <div className={classNames({
          "hidden": currentTab !== CaseAssetTabNames.PATHWAYS
        })}>
          <CasePathwayList
            caseId={props.caseId}
          />
        </div>
        : null
      }
    </div>
  )
}

CaseActivityPanel.defaultProps = defaultProps

export default CaseActivityPanel

