import React from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { IAppState } from "../../../../app/appTypes"
import { IconMicOn } from "../../../../svg/icons"
import Button from "../../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { setAudioSourceAction } from "../../../webRTC/state/webRTCActions"
import { getSelectedAudioDeviceId, getWebRTCAudioDeviceById, getWebRTCPublisher } from "../../../../shared/selectors/webRTC"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

interface IProps {
  deviceId: string;
}

const LiveMeetingUserAudioDeviceListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const dispatch = useDispatch()
  const onClickSelect = (publisher: OT.Publisher, deviceId: string) => dispatch(setAudioSourceAction(publisher, deviceId))
  const publisher = useSelector(getWebRTCPublisher)
  const selectedAudioDeviceId = useSelector(getSelectedAudioDeviceId)
  const audioDevice = useSelector((state: IAppState) => getWebRTCAudioDeviceById(state, props))

  const isSelected = audioDevice.deviceId == selectedAudioDeviceId
  const renderSelectButton = (): JSX.Element => {
    if (isSelected) return (
      <span className="text-sm font-semibold uppercase text-ch-blue-500">
        {t("shared:selected")}
      </span>
    )
    return (
      <Button action={() => onClickSelect(publisher, audioDevice.deviceId)} className="self-center self-end" variant={StyleVariants.BLUE} isChunky={true}>
        {t("shared:select")}
      </Button>
    )
  }

  return (
    <li className={classNames(
      "flex",
      "flex-1",
      "mb-2",
      "rounded-md",
      "p-4",
      "justify-between",
      "border-2",
      "items-center", {
      "border-ch-gray-300": !isSelected,
      "border-ch-blue-500": isSelected,
      "bg-ch-gray-300": !isSelected,
      "bg-white": isSelected
    }
    )}>
      <div className="flex items-center justify-center p-2 text-ch-gray-600 bg-white border border-ch-gray-400 rounded-full w-12 h-12 text-ch-purple-500 mr-4">
        <IconMicOn className="fill-current w-6 h-6" title={audioDevice.label} />
      </div>

      <div className="flex-1 mr-4">
        {audioDevice.label}
      </div>

      {renderSelectButton()}
    </li>
  )
}

export default LiveMeetingUserAudioDeviceListItem
