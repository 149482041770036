import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import PatientListItem from "./PatientListItem"
import { getAllPatientIds } from "../../../../shared/selectors/patients"

const PatientList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["patient"])
  const patientIds = useSelector(getAllPatientIds)

  if (!patientIds.length) return (
    <h3 className="text-lg font-bold text-center">
      {t("patient:index:noPatients")}
    </h3>
  )

  return (
    <ul>
      {patientIds.map((patientId: string) => {
        return (
          <PatientListItem key={patientId} patientId={patientId} />
        )
      })}
    </ul>
  )
}

export default PatientList
