import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import InputField from "../../shared/components/forms/InputField"
import Buttons from "../../shared/components/layout/Buttons"
import Button from "../../shared/components/buttons/Button"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentUser } from "../../shared/selectors/user"
import history from "../../app/routes/history"
import { RoutePaths } from "../../app/routes/Routes"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import { fetchCurrentUserAction } from "../../features/user/state/userActions"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"

interface IPropsFromParent {
  switchToSms: () => void
}

const MfaTotpPage: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["account"])
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const currentUser = useSelector(getCurrentUser)

  const verifyTotpCode = async (code: string, setSubmitting: (isSubmitting: boolean) => void) => {
    API.post("/user/mfa/verify_totp", { code }, withAuthHeader()).then(() => {
      dispatch(fetchCurrentUserAction())
      history.push(RoutePaths.MEETINGS)
    }).catch(() => {
      setSubmitting(false)
      dispatch(
        displayUserMessageAction({
          messageKey: "mfaInvalidCode",
          type: UserMessageTypes.ERROR
        })
      )
      inputRef.current.focus()
    })
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden">
      <div className="text-ch-blue-500 text-xl mb-5">
        {t("mfaRequiredHeader", { email: currentUser.email })}
      </div>
      <div className="mb-5">
        {t("mfaEnterTotpCode")}
      </div>

      <Formik initialValues={{ code: "" }} onSubmit={() => (null)}>
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setSubmitting,
        }) => (
          <Form>
            <div className="m-auto w-full md:w-48 mb-5">
              <InputField className="text-xl text-center" innerRef={inputRef} name="code" onChange={handleChange} onBlur={handleBlur} />
            </div>
            {currentUser.mfaMethods.includes("sms") ?
              <div className="mb-5 text-sm">
                If you prefer to receive an sms to authenticate, please click
                <a onClick={props.switchToSms} className="whitespace-pre text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer"> here </a>
              </div>
              : null}

            <Buttons buttons={[
              <Button
                key="1"
                isDisabled={isSubmitting}
                variant={StyleVariants.PURPLE}
                action={() => { verifyTotpCode(values.code, setSubmitting) }}
              >
                {t("submit")}
              </Button>
            ]} />
          </Form>
        )
        }
      </Formik>
    </div >
  )
}

export default MfaTotpPage
