import { takeLeading, put, call, Effect } from "redux-saga/effects"
import { normalize } from "normalizr"
import { AssetsActionTypes, CommentTypes } from "../assetsTypes"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { fetchMeetingCaseAction } from "../../../meetingCase/state/meetingCaseActions"

export function* createCommentAsync(action: Effect): Generator {
  const caseId = action.payload.caseId
  const comment = action.payload.comment
  const isDecision = comment.commentType === CommentTypes.Decision
  const data = { comment }
  let messageKey: string

  try {
    const response: any = yield call(API.post, `/cases/${caseId}/comments`, data, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    if (action.payload.meetingCaseId) {
      yield put(fetchMeetingCaseAction(action.payload.meetingCaseId))
    }
    messageKey = isDecision ? "createDecisionSuccess" : "createCommentSuccess"

    yield put(displayUserMessageAction({
      messageKey,
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_ERROR,
      payload: error
    })

    messageKey = isDecision ? "createDecisionFail" : "createCommentFail"
    yield put(displayUserMessageAction({
      messageKey,
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchCreateCommentAsync(): Generator {
  yield takeLeading(AssetsActionTypes.CREATE_CASE_COMMENT_ASYNC_PENDING, createCommentAsync)
}
