import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconNote({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M15.714 16.714v5.572H3.286a1.24 1.24 0 01-.911-.375A1.24 1.24 0 012 21V3c0-.357.125-.66.375-.91s.554-.376.91-.376h18c.358 0 .661.125.911.375s.375.554.375.911v12.429H17c-.357 0-.66.125-.91.375a1.24 1.24 0 00-.376.91zm1.715.429h5.102c-.134.732-.424 1.321-.87 1.768l-2.465 2.464c-.446.446-1.035.737-1.767.87v-5.102z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconNote;
