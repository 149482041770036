import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ImageThumb from "../../../../shared/components/images/ImageThumb"
import { getMessageAssetById } from "../../../../shared/selectors/message"
import { IconPdf } from "../../../../svg/icons"
import { IMessageAsset } from "../../../message/state/messageTypes"

interface Props {
  messageAssetId: string
  openAssetModal: (selectedAssetId: string) => void
}

const MessageAssetListItem = (props: Props): JSX.Element => {
  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  const renderThumb = (): JSX.Element => {
    if(messageAsset.asset.contentType == "application/pdf") return (
      <div className="text-ch-blue-alt-400 mr-2">
        <IconPdf className="w-6 h-6" />
      </div>
    )
    return (
      <div className="w-6 h-6 flex items-center justify-center mr-2 overflow-hidden">
        <ImageThumb path={messageAsset.asset.path} title={messageAsset.asset.name} />
      </div>
    )
  }

  return (
    <a className="flex p-2 bg-white rounded-md mb-2 min-w-48 items-center cursor-pointer text-ch-gray-600 hover:text-ch-blue-alt-400 shadow-inner" onClick={() => props.openAssetModal(props.messageAssetId)}>
      {renderThumb()}
      <span className="text-xs font-semibold">{messageAsset.asset.name}</span>
    </a>
  )
}

export default MessageAssetListItem
