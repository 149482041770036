import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import humps from "humps"
import NameUser from "../../../../shared/components/names/NameUser"
import TimeDateOfBirth from "../../../../shared/components/time/TimeDateOfBirth"
import { TenantConfigContext } from "../../../tenantConfig/contexts/TenantConfigContext"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconPatient } from "../../../../svg/icons"
import { IPatientNewData } from "../../../patientNew/state/patientNewTypes"
import { IPatientEditData } from "../../../patientEdit/state/patientEditTypes"

interface Props {
  data: IPatientNewData | IPatientEditData
}

const PatientDetailsFormCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "patient"])

  const { healthcareIdLabel, careProviderIdLabel } = useContext(
    TenantConfigContext
  )

  const renderName = (): JSX.Element => {
    return (
      <li>
        <h4 className="font-semibold">
          <NameUser
            firstName={props.data.firstName}
            lastName={props.data.lastName}
            title={props.data.title}
          />
        </h4>
      </li>
    )
  }

  const renderGender = (): JSX.Element => {
    const translationKey = humps.camelize(props.data.gender)
    return (
      <li>
        {t("patient:details:gender")}: {t(`shared:${translationKey}`)}
      </li>
    )
  }

  const renderDateOfBirth = (): JSX.Element => {
    return (
      <li>
        {t("patient:details:dateOfBirth")}: <TimeDateOfBirth utcTime={props.data.dateOfBirth} />
      </li>
    )
  }

  const renderHealthcareId = (): JSX.Element => {
    return (
      <li>
        {healthcareIdLabel}: {props.data.healthcareId || "-"}
      </li>
    )
  }

  const renderCareProviderId = (): JSX.Element => {
    return (
      <li>
        {careProviderIdLabel}: {props.data.careProviderId || "-"}
      </li>
    )
  }

  return (
    <DetailsCard>
      <DetailsCardInner>
        <IconCircleContainer className="sm:mr-4">
          <IconPatient className="fill-current w-6 h-6" title={t("shared:patient")} />
        </IconCircleContainer>
        <div className="flex-1 lg:grid grid-cols-2 gap-3">
          <ul className="col-span-1">
            { renderName() }
            { renderGender() }
            { renderDateOfBirth() }
          </ul>

          <ul className="col-span-1">
            { renderHealthcareId() }
            { renderCareProviderId() }
          </ul>
        </div>
      </DetailsCardInner>
    </DetailsCard>
  )
}

export default PatientDetailsFormCard
