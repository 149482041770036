import * as Yup from "yup"
import i18n from "../../../../app/i18n"

export const patientDetailsValidation = (): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    firstName: Yup
      .string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    lastName: Yup
      .string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", {maxLength: 50})),
    dateOfBirth: Yup
      .string()
      .when(["dobDay", "dobMonth", "dobYear"], {
        is: (dobDay: string, dobMonth: string, dobYear: string) => {
          return (!dobDay || !dobMonth || !dobYear)
        },
        then: Yup.string().trim().required(i18n.t("shared:required"))
      }),
    gender: Yup
      .string()
      .trim()
      .required(i18n.t("shared:required")),
    healthcareId: Yup
      .string()
      .when(["isHealthcareIdRequired"], {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(20, i18n.t("shared:maxLengthExceeded", {maxLength: 20})),
    careProviderId: Yup
      .string()
      .when(["isCareProviderIdRequired"], {
        is: true,
        then: Yup.string().trim().required(i18n.t("shared:required"))
      })
      .trim()
      .max(20, i18n.t("shared:maxLengthExceeded", {maxLength: 20}))
  })
}
