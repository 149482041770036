import { Reducer, AnyAction } from "redux"
import { IScrollUIState, ScrollActionTypes } from "../scrollTypes"

export const initialState: IScrollUIState = {
  mainScrollToPosition: null,
  modalScrollToPosition: null
}

export const scrollUIReducer: Reducer<IScrollUIState> = (state: IScrollUIState = initialState, action: AnyAction): IScrollUIState => {
  switch (action.type) {
    case ScrollActionTypes.UPDATE_MAIN_SCROLL_TO_POSITION:
      return {
        ...state,
        mainScrollToPosition: action.payload
      }
    case ScrollActionTypes.UPDATE_MODAL_SCROLL_TO_POSITION:
      return {
        ...state,
        mainScrollToPosition: action.payload
      }
    default:
      return { ...state }
  }
}
