import { useEffect, useState } from "react"
import { getCurrentBreakpoint } from "../helpers/breakpointHelpers"
import useWindowSize, { Size } from "./useWindowSize"

const useCurrentBreakPoint = (): string => {
  const windowSize: Size = useWindowSize()
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint())

  useEffect(() => {
    setCurrentBreakpoint(getCurrentBreakpoint())
  }, [windowSize])

  return currentBreakpoint
}

export default useCurrentBreakPoint
