import { put, takeLeading } from "@redux-saga/core/effects"
import { Effect } from "@redux-saga/types"
import { call } from "redux-saga-test-plan/matchers"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { AdminActionTypes, IUploadTenantLogoFormValues } from "../adminTypes"

const valuesToFormData = (values: IUploadTenantLogoFormValues) => {
  const data = new FormData()
  data.append("tenant[logo]", values.file)
  return data
}

export function* uploadTenantLogoAsync(action: Effect): Generator {
  try {
    const data = valuesToFormData(action.payload.values)
    const config = withAuthHeader()
    // the upload can't be done with JSON, it needs this content type.
    config.headers["Content-Type"] = "multipart/form-data"

    yield put(displayUserMessageAction({
      messageKey: "fileUploadStarted",
      type: UserMessageTypes.MESSAGE
    }))

    const response: any = yield call(API.patch, "/tenant_logo", data, config)

    yield put({
      type: AdminActionTypes.UPLOAD_TENANT_LOGO_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "fileUploadSuccess",
      type: UserMessageTypes.SUCCESS
    }))
  } catch (error) {
    yield put({
      type: AdminActionTypes.UPLOAD_TENANT_LOGO_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "fileUploadError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchUploadTenantLogoAsync(): Generator {
  yield takeLeading(AdminActionTypes.UPLOAD_TENANT_LOGO_ASYNC_PENDING, uploadTenantLogoAsync)
}
