import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { IAppState } from "../../../../app/appTypes"
import CaseMeetingListItem from "./CaseMeetingListItem"
import CaseMemberListItem from "./CaseMemberListItem"
import PillSwitch from "../../../../shared/components/ui/PillSwitch"
import { getCaseById } from "../../../../shared/selectors/case"
import ListBackground from "../../../../shared/components/layout/ListBackground"

interface IProps {
  caseId: string
}

const CaseQuickViewTabs: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(["shared", "case"])
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))

  if (!theCase) return null
  const [currentTab, setCurrentTab] = useState(0)
  const membersCount = theCase.members && theCase.members.length || 0
  const meetingsCount = theCase.meetings && theCase.meetings.length || 0
  const tabText1 = `${membersCount} ${t("shared:members")}`
  const tabText2 = `${meetingsCount} ${t("shared:meetings")}`

  const renderMembersList = (): JSX.Element => {
    if (membersCount == 0) return null
    return (
      <ul>
        {_.map(theCase.members, (userId) => {
          return <CaseMemberListItem key={userId} userId={userId} />
        })}
      </ul>
    )
  }

  const renderMeetingList = (): JSX.Element => {
    if (meetingsCount == 0) {
      return (
        <div>
          <h3 className="text-center text-ch-blue-alt-400">
            {t("case:noMeetings")}
          </h3>
          <ListBackground />
        </div>
      )
    } else {
      return (
        <ul>
          {_.map(theCase.meetings, (meetingId) => {
            return <CaseMeetingListItem key={meetingId} meetingId={meetingId} />
          })}
        </ul>
      )
    }
  }

  return (
    <div>
      <PillSwitch onClick={setCurrentTab} text1={tabText1} text2={tabText2} value={currentTab} className="mb-2" />

      <div className={classNames({
        "hidden": currentTab !== 0
      })}>
        {renderMembersList()}
      </div>

      <div className={classNames({
        "hidden": currentTab !== 1
      })}>
        {renderMeetingList()}
      </div>
    </div>
  )
}

export default CaseQuickViewTabs
