import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import CurrentUserSessionDetails from "../../user/components/details/CurrentUserSessionDetails"
import MenuLink from "./MenuLink"
import { RoutePaths } from "../../../app/routes/Routes"
import { IconMeeting, IconNotification, IconFolder, IconTeam, IconUsers, IconPatient, IconUser, IconTask, IconMessage, IconPathway, IconRadiology } from "../../../svg/icons"
import IconChart from "../../../../images/svg/icons/icon-chart.svg"
import Timezone from "../../../shared/components/ui/Timezone"
import SignOutButton from "../../../shared/components/buttons/SignOutButton"
import { getChatMessagesUI, getIsMenuOpen, getNotificationsUI, getTasksUI } from "../../../shared/selectors/ui"
import INotificationsUIState from "../../notifications/state/notificationsTypes"
import { getCurrentUser } from "../../../shared/selectors/user"
import Counter from "../../../shared/components/ui/Counter"
import ITasksUIState from "../../tasks/state/tasksTypes"
import { TenantConfigContext } from "../../tenantConfig/contexts/TenantConfigContext"
import IChatMessagesUIState from "../../messages/state/messagesTypes"
import HelpButton from "../../../shared/components/buttons/HelpButton"
import { isAdmin, isGuest } from "../../../shared/helpers/userHelpers"

const Menu: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const currentUser = useSelector(getCurrentUser)
  const { messagingEnabled, pathwaysEnabled, analyticsEnabled, radiologyEnabled } = useContext(TenantConfigContext)
  const notificationUIState: INotificationsUIState = useSelector(getNotificationsUI)
  const taskUIState: ITasksUIState = useSelector(getTasksUI)
  const messagesUIState: IChatMessagesUIState = useSelector(getChatMessagesUI)

  const isMenuOpen = useSelector(getIsMenuOpen)
  const notificationsUnreadCount = notificationUIState?.unreadCount
  const tasksOutstandingCount = taskUIState?.outstandingCount
  const messagesUnreadCount = messagesUIState.unreadCount

  const renderAdminLink = (): JSX.Element => {
    if (!isAdmin(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.ADMIN}>
          <IconUser className="w-6 h-6 mr-3" /> {t("shared:admin")}
        </MenuLink>
      </li>
    )
  }

  const renderAnalyticsLink = (): JSX.Element => {
    if (!isAdmin(currentUser) || !analyticsEnabled) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.ANALYTICS}>
          <IconChart width={20} height={20} className="mr-3" fill="currentColor" /> {t("shared:analytics")}
        </MenuLink>
      </li>
    )
  }

  const renderMeetingsLink = (): JSX.Element => {
    if (isGuest(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.MEETINGS}>
          <IconMeeting className="w-6 h-6 mr-3" /> {t("shared:meetings")}
        </MenuLink>
      </li>
    )
  }

  const renderPathwaysLink = (): JSX.Element => {
    if (isGuest(currentUser) || !pathwaysEnabled) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.PATHWAYS}>
          <IconPathway className="w-6 h-6 mr-3" /> {t("shared:pathways")}
        </MenuLink>
      </li>
    )
  }

  const renderUsersLink = (): JSX.Element => {
    if (isGuest(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.USERS}>
          <IconUsers className="w-6 h-6 mr-3" /> {t("shared:users")}
        </MenuLink>
      </li>
    )
  }

  const renderRadiologyLink = (): JSX.Element => {
    if (isGuest(currentUser) || !radiologyEnabled) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.RADIOLOGY}>
          <IconRadiology className="w-6 h-6 mr-3" /> {t("shared:radiology")}
        </MenuLink>
      </li>
    )
  }

  const renderTeamsLink = (): JSX.Element => {
    if (isGuest(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.TEAMS}>
          <IconTeam className="w-6 h-6 mr-3" /> {t("shared:teams")}
        </MenuLink>
      </li>
    )
  }

  const renderMessagesLink = (): JSX.Element => {
    if (!messagingEnabled) return null
    if (isGuest(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.MESSAGES}>
          <div className="relative mr-3">
            <Counter count={messagesUnreadCount} />
            <IconMessage className="w-6 h-6" />
          </div>
          {t("shared:messages")}
        </MenuLink>
      </li>
    )
  }
  const renderTasksLink = (): JSX.Element => {
    if (isGuest(currentUser)) return null
    return (
      <li className="mb-2">
        <MenuLink to={RoutePaths.TASKS}>
          <div className="relative mr-3">
            <Counter count={tasksOutstandingCount} />
            <IconTask className="w-6 h-6" />
          </div>
          {t("shared:tasks")}
        </MenuLink>
      </li>
    )
  }

  return (
    <div className={classNames(
      "flex flex-col py-8 px-3 bg-ch-blue-800 shadow h-full w-full overflow-y-scroll absolute z-20 md:static",
      "md:w-64", {
      "hidden": !isMenuOpen,
      "md:flex": !isMenuOpen,
      "flex": isMenuOpen
    }
    )}>
      <div className="flex justify-center md:hidden mb-6">
        <Timezone />
      </div>
      <div className="mb-6">
        <CurrentUserSessionDetails />
      </div>
      <ul>
        <li className="mb-2">
          <MenuLink to={RoutePaths.NOTIFICATIONS}>
            <div className="relative mr-3">
              <Counter count={notificationsUnreadCount} />
              <IconNotification className="w-6 h-6" />
            </div>
            {t("shared:notifications")}
          </MenuLink>
        </li>
        {renderMeetingsLink()}
        <li className="mb-2">
          <MenuLink to={RoutePaths.CASES}>
            <IconFolder className="w-6 h-6 mr-3" /> {t("shared:cases")}
          </MenuLink>
        </li>
        <li className="mb-2">
          <MenuLink to={RoutePaths.PATIENTS}>
            <IconPatient className="w-6 h-6 mr-3" /> {t("shared:patients")}
          </MenuLink>
        </li>
        {renderRadiologyLink()}
        {renderMessagesLink()}
        {renderTasksLink()}
        {renderPathwaysLink()}
        {renderUsersLink()}
        {renderTeamsLink()}
        {renderAnalyticsLink()}
        {renderAdminLink()}
        <li className="mb-2 md:hidden">
          <SignOutButton isMenuLink={true} />
        </li>
      </ul>
      <HelpButton asButton />
    </div>
  )
}


export default Menu
