import React from "react"
import { useSelector } from "react-redux"
import { getCurrentUserWithConfig } from "../../../../shared/selectors/user"
import RecommendationForm from "./RecommendationForm"
import RecommendationDisplay from "./RecommendationDisplay"
import { CaseStage } from "../../state/liveMeetingTypes"
import { getLiveMeetingConfig } from "../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  meetingId: string
  readOnly: boolean
}

const RecommendationArea: React.FC<IProps> = (props: IProps) => {
  const currentUserConfig = useSelector(getCurrentUserWithConfig).config
  const caseStage = useSelector(getLiveMeetingConfig).caseStage
  const isPresenting = caseStage == CaseStage.PRESENTING
  const headerKey = caseStage == CaseStage.DEFERRING
    ? "shared:deferHeader"
    : "shared:recommendationHeader"
  if (
    !props.readOnly &&
    (isPresenting && currentUserConfig.isScribe) ||
    (!isPresenting && currentUserConfig.isPresenter)
  ) {
    return <RecommendationForm headerKey={headerKey} meetingId={props.meetingId} />
  }
  return <RecommendationDisplay headerKey={headerKey} {...props} />
}

export default RecommendationArea
