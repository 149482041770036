import React from "react"
import { useTranslation } from "react-i18next"
import { MockStore } from "redux-mock-store"
import { IconFolder } from "../../../../svg/icons"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import CaseDetails from "../../../case/components/details/CaseDetails"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import classNames from "classnames"

interface IPropsFromParent {
  store?: MockStore
  caseId: string
  children?: React.ReactNode
  isDark?: boolean
  hasSmallText?: boolean
}

type Props = IPropsFromParent

const CaseListItemContainer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "case"])

  const renderChildren = (): JSX.Element => {
    if(!props.children) return null
    return (
      <DetailsCardRight>
        {props.children}
      </DetailsCardRight>
    )
  }

  const renderContent = (): JSX.Element => {
    return (
      <li className={classNames({
        "text-xs": props.hasSmallText
      })}>
        <DetailsCard isDark={props.isDark}>
          <DetailsCardInner width={!props.children ? "w-full" : ""}>
            <IconCircleContainer
              className="sm:mr-4"
              sizeClasses={props.hasSmallText ? ["w-7", "h-7"] : null}
            >
              <IconFolder
                className={classNames(
                  "fill-current  h-6",
                  {
                    "w-6": !props.hasSmallText,
                    "h-6": !props.hasSmallText,
                    "w-4": props.hasSmallText,
                    "h-4": props.hasSmallText,
                  }
                )}
                title={t("shared:case")}
              />
            </IconCircleContainer>
            <CaseDetails caseId={props.caseId} />
          </DetailsCardInner>

          {renderChildren()}
        </DetailsCard>
      </li>
    )
  }
  return renderContent()
}

export default CaseListItemContainer
