import { Reducer, AnyAction } from "redux"
import { LiveMeetingActionTypes, ILiveMeetingUIState, YourViewTabs, UsersViewTabs } from "./../liveMeetingTypes"
import { SharedActionTypes } from "./../../../../shared/state/sharedTypes"

export const initialState: ILiveMeetingUIState = {
  isSplitScreen: true,
  selectedYourViewTab: YourViewTabs.USERS_VIEW,
  selectedUsersViewTab: UsersViewTabs.GRID,
  isYourViewAssetListOpen: false,
  yourViewSelectedAssetId: null,
}

export const liveMeetingUIReducer: Reducer<ILiveMeetingUIState> = (state: ILiveMeetingUIState = initialState, action: AnyAction): ILiveMeetingUIState => {
  switch (action.type) {
    case SharedActionTypes.REHYDRATE:
      if (!action.payload?.ui.liveMeeting) return { ...state }
      return {
        ...state,
        ...action.payload.ui.liveMeeting
      }

    case LiveMeetingActionTypes.TOGGLE_SPLIT_SCREEN:
      return {
        ...state,
        isSplitScreen: action.payload
      }

    case LiveMeetingActionTypes.CHANGE_YOUR_VIEW_TAB:
      return {
        ...state,
        selectedYourViewTab: action.payload
      }

    case LiveMeetingActionTypes.CHANGE_USERS_VIEW_TAB:
      return {
        ...state,
        selectedUsersViewTab: action.payload
      }

    case LiveMeetingActionTypes.TOGGLE_YOUR_VIEW_ASSET_LIST_OPEN:
      return {
        ...state,
        isYourViewAssetListOpen: action.payload
      }

    case LiveMeetingActionTypes.UPDATE_YOUR_VIEW_SELECTED_ASSET:
      return {
        ...state,
        yourViewSelectedAssetId: action.payload
      }

    default:
      return { ...state }
  }
}
