import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import { smallModalStyle } from "../../../../shared/state/sharedTypes"
import PatientsUploadForm from "./PatientsUploadForm"


interface Props {
  closeModal: () => void
  isOpen: boolean
}

const PatientsUploadModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["patients"])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} style={smallModalStyle} >
      <ModalContainer handleClose={props.closeModal}>
        <h2 className="text-lg mb-3">
          {t("patient:bulkUpload:intro")}
        </h2>
        <p className="text-ch-blue-600 mb-2">
          {t("patient:bulkUpload:emailOnCompletion")}
        </p>
        <PatientsUploadForm closeAction={props.closeModal}/>
      </ModalContainer>
    </Modal >
  )
}

export default PatientsUploadModal
