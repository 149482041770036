import { AnyAction, combineReducers, Reducer } from "redux"
import _ from "lodash"
import { MeetingsActionTypes } from "../meetingsActionTypes"
import { IMeetingsUIState } from "../meetingsTypes"
import { defaultPerPage } from "../../../../shared/state/sharedTypes"

type TAllIdsState = string[]
export const idsOnIndexPage: Reducer<TAllIdsState> = (state: TAllIdsState = [], action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.all.result
    default:
      return state
  }
}

export const totalMeetingsCount: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.totalItems
    default:
      return state
  }
}

export const totalPages: Reducer<number> = (state = 0, action: AnyAction): number => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.totalPages
    default:
      return state
  }
}

export const appliedFilters: Reducer<string[]> = (state = [], action: AnyAction): string[] => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.appliedFilters
    default:
      return state
  }
}

export const searchTerm: Reducer<string> = (state = "", action: AnyAction): string => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.searchTerm
    default:
      return state
  }
}

export const resultsPerPage: Reducer<number> = (state = defaultPerPage, action: AnyAction): number => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.resultsPerPage
    default:
      return state
  }
}

export const orderBy: Reducer<string> = (state = "date_desc", action: AnyAction): string => {
  switch (action.type) {
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return action.payload.orderBy
    default:
      return state
  }
}

export const isListView: Reducer<boolean> = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case MeetingsActionTypes.TOGGLE_LIST_VIEW:
      return action.payload
    default:
      return state
  }
}

export const meetingsSearchUIReducer: Reducer<IMeetingsUIState> = combineReducers({
  idsOnIndexPage,
  totalMeetingsCount,
  totalPages,
  appliedFilters,
  orderBy,
  resultsPerPage,
  searchTerm,
  isListView
})
