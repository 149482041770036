import { Form, Formik, FormikProps } from "formik"
import * as KeyCode from "keycode-js"
import React, { useRef } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Button from "../../../shared/components/buttons/Button"
import MentionField from "../../../shared/components/forms/MentionField"
import { StyleVariants } from "../../../shared/state/sharedTypes"
import { ChatTypes } from "../../chat/state/chatTypes"
import { createChatAsync } from "../state/chatNewActions"
import { IChatNewData, IChatNewFormData } from "../state/chatNewTypes"
import { chatNewValidation } from "../state/validation/chatNewValidation"
import ChatNewHeader from "./ChatNewHeader"
import ChatNewUsersField from "./ChatNewUsersField"
import ChatNewCaseField from "./selectCase/ChatNewCaseField"

const ChatNewForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const dispatch = useDispatch()
  const createChat = (chat: IChatNewData) => dispatch(createChatAsync(chat))
  const initialValues: IChatNewFormData = {
    message: "",
    userIds: [],
    teamId: "",
    caseId: "",
    isTeamChat: false
  }
  const formikFormRef = useRef<FormikProps<IChatNewFormData>>(null)
  let pressedKeys = []

  const onMessageFieldKeyDown = (event: React.KeyboardEvent)=> {
    pressedKeys = _.uniq([...pressedKeys, event.key])
    if(
      _.includes(pressedKeys, KeyCode.VALUE_RETURN) &&
      !_.includes(pressedKeys, KeyCode.VALUE_SHIFT)
    ) {
      event.preventDefault()
      formikFormRef.current.submitForm()
    }
  }

  const onMessageFieldKeyUp = (event: React.KeyboardEvent)=> {
    const withoutKeyUp = pressedKeys.filter((key) => key != event.key)
    pressedKeys = _.uniq(withoutKeyUp)
  }

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={chatNewValidation}
        validateOnChange
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const type = values.isTeamChat ? ChatTypes.TEAM : ""
          const data: IChatNewData = {
            type: type,
            messagesAttributes:[
              { body: values.message }
            ],
            chatUsersAttributes: values.userIds.map((userId: string) => { return { userId: userId } }),
            caseId: values.caseId,
            teamId: values.teamId
          }
          createChat(data)
          setSubmitting(false)
          resetForm()
        }}
        innerRef={formikFormRef}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          isValid
        }) => {
          return (
            <Form className="flex flex-1 flex-col justify-between h-full">
              <div className="p-4 border-b border-ch-gray-400 overflow-y-scroll md:overflow-visible">
                <ChatNewHeader />
                <div className="flex flex-col items-center">
                  <div className="w-full md:w-3/4 mb-4">
                    <ChatNewUsersField
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <ChatNewCaseField
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
              </div>
              <div className="p-4 border-t border-ch-gray-400">
                <div className="mb-2">
                  <MentionField
                    name="message"
                    setValue={(description) => setFieldValue("message", description)}
                    onBlur={handleBlur}
                    onKeyDown={onMessageFieldKeyDown}
                    onKeyUp={onMessageFieldKeyUp}
                    users={null}
                    value={values.message}
                    height={42}
                  />
                </div>
                <div className="flex justify-center">
                  <Button
                    isDisabled={isSubmitting || !isValid}
                    variant={StyleVariants.PURPLE}
                    isSubmit={true}
                  >
                    {t("message:new:buttons:send")}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
  )
}

export default ChatNewForm
