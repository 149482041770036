import { ICasePathwayTaskUpdateData } from "../../tasks/state/tasksTypes"
import { CasePathwaysActionTypes, ICasePathwayEditData, ICreateCasePathwayAsyncAction, IFetchCasePathwaysAsyncAction, IUpdateCasePathwayAsyncAction, IUpdateCasePathwayTaskAsyncAction } from "./casePathwayTypes"

export const fetchCasePathwaysAsync = (caseId: string): IFetchCasePathwaysAsyncAction => {
  return {
    type: CasePathwaysActionTypes.FETCH_CASE_PATHWAYS_ASYNC_PENDING,
    payload: caseId
  }
}

export const createCasePathwayAsync = (caseId: string, pathwayId: string): ICreateCasePathwayAsyncAction => {
  return {
    type: CasePathwaysActionTypes.CREATE_CASE_PATHWAY_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      pathwayId: pathwayId
    }
  }
}

export const updateCasePathwayAction = (caseId: string, casePathwayId: string, casePathway: ICasePathwayEditData): IUpdateCasePathwayAsyncAction => {
  return {
    type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_ASYNC_PENDING,
    payload: {
      caseId,
      casePathwayId,
      casePathway
    }
  }
}

export const updateCasePathwayTaskAction = (caseId: string, taskId: string, task: ICasePathwayTaskUpdateData): IUpdateCasePathwayTaskAsyncAction => {
  return {
    type: CasePathwaysActionTypes.UPDATE_CASE_PATHWAY_TASK_ASYNC_PENDING,
    payload: {
      caseId,
      taskId,
      task
    }
  }
}
