

import { normalize } from "normalizr"
import { call, put, takeLeading } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { caseClassificationsSchema } from "../../../apis/contextmeeting/schema"
import { CaseClassificationActionTypes } from "./caseClassificationTypes"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"

function* fetchCaseClassificationAsync(_action): Generator {
  try {
    const response: any = yield call(API.get, "/case_classifications/", withAuthHeader())

    const normalizedResponse = normalize(response.data, [caseClassificationsSchema])

    yield put({
      type: CaseClassificationActionTypes.FETCH_CASE_CLASSIFICATION_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: CaseClassificationActionTypes.FETCH_CASE_CLASSIFICATION_ASYNC_ERROR,
      payload: error
    })
  }
}

function* watchFetchCaseClassificationAsync(): Generator {
  yield takeLeading(CaseClassificationActionTypes.FETCH_CASE_CLASSIFICATION_ASYNC_PENDING, fetchCaseClassificationAsync)
}

export { watchFetchCaseClassificationAsync }
