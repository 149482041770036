import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconScreenShareOn({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M26.667 24c1.466 0 2.653-1.2 2.653-2.667L29.333 8a2.667 2.667 0 00-2.666-2.667H5.333A2.657 2.657 0 002.667 8v13.333A2.667 2.667 0 005.333 24H0v2.667h32V24h-5.333zm-9.334-4.707v-2.92c-3.706 0-6.146 1.134-8 3.627.747-3.56 2.814-7.107 8-7.827v-2.84l5.334 4.974-5.334 4.986z"
        />
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconScreenShareOn;
