import _ from "lodash"
import { AnyAction, combineReducers, Reducer } from "redux"
import { INormalizedEntities } from "../../../../app/appTypes"
import { PathwaysActionTypes } from "../pathwaysTypes"
import { IPathway, PathwayActionTypes } from "../../../pathway/pathwayTypes"
import { AddPathwayToCaseActionTypes } from "../../../addPathwayToCase/state/addPathwayToCaseTypes"

export type TByIdState = { [id: string]: IPathway }
export type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []


export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
    case AddPathwayToCaseActionTypes.FETCH_PATHWAYS_ADDABLE_TO_CASE_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.pathways || {})
    case PathwayActionTypes.FETCH_PATHWAY_ASYNC_SUCCESS:
    case PathwayActionTypes.CREATE_PATHWAY_ASYNC_SUCCESS:
    case PathwayActionTypes.UPDATE_PATHWAY_ASYNC_SUCCESS:
    case PathwayActionTypes.CREATE_PATHWAY_STEP_ASYNC_SUCCESS:
    case PathwayActionTypes.ADD_PATHWAY_USERS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.entities.pathways || {})
    case PathwayActionTypes.DELETE_PATHWAY_STEP_ASYNC_SUCCESS:
    case PathwayActionTypes.UPDATE_PATHWAY_STEP_ASYNC_SUCCESS:
    case PathwayActionTypes.REMOVE_PATHWAY_USER_ASYNC_SUCCESS:
      // here we need to replace the returned pathway, so no _.merge
      return {
        ...state,
        ...action.payload.entities.pathways
      }
    case PathwayActionTypes.DELETE_PATHWAY_ASYNC_SUCCESS:
      return _.omit(state, [action.payload.pathwayId])
    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.all.result || []))
    case PathwayActionTypes.FETCH_PATHWAY_ASYNC_SUCCESS:
    case PathwayActionTypes.CREATE_PATHWAY_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.result || []))
    case PathwayActionTypes.DELETE_PATHWAY_ASYNC_SUCCESS:
      return _.without(state, action.payload.pathwayId)
    default:
      return state
  }
}

export const pathwaysEntityReducer: Reducer<INormalizedEntities<IPathway>> = combineReducers({
  byId,
  allIds
})
