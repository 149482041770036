import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import Button from "./Button"


interface IPropsFromParent {
  openModal: () => void
  label: string
}

type Props = IPropsFromParent

const InviteButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      action={props.openModal}
      className="mb-2"
      variant={StyleVariants.ORANGE}
    >
      {props.label}
    </Button>
  )
}

export default InviteButton
