import { IBaseAction } from "../../shared/types/baseActionTypes"

export enum AppActionTypes {
  CHECK_SESSION_ACTIVE_PENDING = "@@app/CHECK_SESSION_ACTIVE_PENDING"
}

export const checkSessionAction = (): IBaseAction => {
  return {
    type: AppActionTypes.CHECK_SESSION_ACTIVE_PENDING
  }
}
