import { takeLeading, put, call } from "redux-saga/effects"
import _ from "lodash"
import { WebRTCActionTypes } from "../webRTCTypes"
import { getAudioDevices, getAudioSource, setAudioSource, togglePublisherAudio } from "../../../../apis/webRTC/webRTC"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"

export function* toggleWebRTCPublishAudioAsync(action) {
  try {
    yield call(togglePublisherAudio, action.payload.publisher, action.payload.isSharingAudio)

    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_SUCCESS
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "publishWebRTCAudioError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchToggleWebRTCPublishAudioAsync() {
  yield takeLeading(WebRTCActionTypes.TOGGLE_WEB_RTC_PUBLISHER_AUDIO_PENDING, toggleWebRTCPublishAudioAsync)
}

export function* getWebRTCAudioDevicesAsync(_action) {
  try {
    const devices = yield call(getAudioDevices)

    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_SUCCESS,
      payload: {
        devices
      }
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "getWebRTCAudioDevicesError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchGetWebRTCAudioDevicesAsync() {
  yield takeLeading(WebRTCActionTypes.GET_WEB_RTC_AUDIO_DEVICES_PENDING, getWebRTCAudioDevicesAsync)
}

export function* setWebRTCAudioSourceAsync(action) {
  try {
    yield call(setAudioSource, action.payload.publisher, action.payload.deviceId)

    yield put({
      type: WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_SUCCESS,
      payload: {
        deviceId: action.payload.deviceId
      }
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "setWebRTCAudioSourceError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchSetWebRTCAudioSourceAsync() {
  yield takeLeading(WebRTCActionTypes.SET_WEB_RTC_AUDIO_SOURCE_PENDING, setWebRTCAudioSourceAsync)
}

export function* getWebRTCAudioSourceAsync(action) {
  try {
    const device: OT.Device = yield call(getAudioSource, action.payload.publisher)

    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_SUCCESS,
      payload: {
        device: device
      }
    })
  } catch (error) {
    yield put({
      type: WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "getWebRTCAudioSourceError",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchGetWebRTCAudioSourceAsync() {
  yield takeLeading(WebRTCActionTypes.GET_WEB_RTC_AUDIO_SOURCE_PENDING, getWebRTCAudioSourceAsync)
}
