import * as React from "react";
const SvgCepLogoColour = (props) => (
  <svg
    viewBox="0 0 2621 1208"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M336.944 178.736v35.567l61.604-35.568-30.802-17.783-30.802 17.784Z"
      style={{
        fill: "#127074",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <path
      d="m336.944 72.035 61.604 35.568-30.802 17.783-30.802-17.784-30.801 17.784-30.802-17.783 61.603-35.568ZM376.986 24.02 336.944.902 213.738 72.036l40.042 23.119L376.986 24.02Z"
      style={{
        fill: "#75a8a9",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <path
      d="m420.109 95.155 40.042-23.119v71.132l-40.042 23.119V95.155ZM336.944 239.2v46.237l83.165-48.016v.001l40.042-23.119v-46.238l-40.042 23.119-83.165 48.016Z"
      style={{
        fill: "#127074",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <path
      d="m398.548 36.469 61.603 35.567-40.042 23.119-61.603-35.568 40.042-23.118Z"
      style={{
        fill: "#75a8a9",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <path
      d="M336.944 178.736v35.567l-30.801-17.784-30.802-17.784v-71.132l30.802 17.783v35.566l30.801 17.784Z"
      style={{
        fill: "#a2c4c5",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <path
      d="m253.78 95.155-40.042-23.119v142.267l40.042 23.119v-.001l83.164 48.016V239.2l-83.164-48.016V95.155Z"
      style={{
        fill: "#a2c4c5",
      }}
      transform="translate(-879 8) scale(4.16667)"
    />
    <text
      x={1255.04}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue-Bold','Helvetica Neue'",
        fontWeight: 700,
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(-26 117)"
    >
      {"C"}
    </text>
    <text
      x={1477.34}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue','Helvetica Neue'",
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(-26 117)"
    >
      {"ONTEXT"}
    </text>
    <text
      x={1255.04}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue-Bold','Helvetica Neue'",
        fontWeight: 700,
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(-30.3 446.313)"
    >
      {"E"}
    </text>
    <text
      x={1449.44}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue','Helvetica Neue'",
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(-30.3 446.313)"
    >
      {"xchange"}
    </text>
    <text
      x={1255.04}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue-Bold','Helvetica Neue'",
        fontWeight: 700,
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(-30.3 782.25)"
    >
      {"P"}
    </text>
    <text
      x={1255.04}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue','Helvetica Neue'",
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(166.7 778.95)"
    >
      {"o"}
    </text>
    <text
      x={1427.24}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue','Helvetica Neue'",
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(166.7 778.95)"
    >
      {"r"}
      <tspan x={1542.14} y={291.718}>
        {"t"}
      </tspan>
    </text>
    <text
      x={1651.64}
      y={291.718}
      style={{
        fontFamily: "'HelveticaNeue','Helvetica Neue'",
        fontSize: 300,
        fill: "#137074",
      }}
      transform="translate(166.7 778.95)"
    >
      {"al"}
    </text>
  </svg>
);
export default SvgCepLogoColour;

