// Models
export interface IPatientCheckData {
  lastName: string
  dateOfBirth: string
  careProviderId: string
}

export interface IPatientDetailsData {
  title: string
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: string
  healthcareId: string
  careProviderId: string
}

export interface IPatientFurtherDetailsData {
  id: string
  postcode: string
  addressLine1: string
  addressLine2: string
  city: string
  county: string
  country: string
  email: string
  phoneNumber: string
  phoneNumberAlt: string
  gpPostcode: string
  gpPracticeName: string
  gpAddressLine1: string
  gpAddressLine2: string
  gpCity: string
  gpCounty: string
  gpCountry: string
  gpEmail: string
  gpPhoneNumber: string
  gpName: string
}

export interface IPatientCaseDetailsData {
  caseType: string
  caseClassificationId: string
  sourceId: string
  leadCareUserId: string
  signedOffBy?: string
  targetDate?: string
}

export interface IPatientNewData extends IPatientDetailsData {
  furtherDetailsAttributes?: IPatientFurtherDetailsData,
  casesAttributes?: IPatientCaseDetailsData[]
}

// State
export interface IPatientNewUIState {
  checkDetails: IPatientNewCheckDetailsUIState
}

export enum PatientNewCheckDetailsStatuses {
  Pending = "pending",
  Found = "found",
  Not_Found = "not_found"
}

export interface IPatientNewCheckDetailsUIState {
  existingPatientIds: string[]
  status: string
}

// Actions
export interface ICheckPatientAsyncAction {
  type: string,
  payload: {
    patientCheckData: IPatientCheckData
  }
}

export interface IResetCheckPatientAction {
  type: string
}

export interface ICreatePatientAsyncAction {
  type: string,
  payload: {
    patient: IPatientNewData
  }
}

export enum PatientNewActionTypes {
  CHECK_PATIENT_ASYNC_RESET = "@@patient/CHECK_PATIENT_RESET",
  CHECK_PATIENT_ASYNC_PENDING = "@@patient/CHECK_PATIENT_ASYNC_PENDING",
  CHECK_PATIENT_ASYNC_FOUND = "@@patient/CHECK_PATIENT_ASYNC_FOUND",
  CHECK_PATIENT_ASYNC_NOT_FOUND = "@@patient/CHECK_PATIENT_ASYNC_NOT_FOUND",

  CREATE_PATIENT_ASYNC_PENDING = "@@patient/CREATE_PATIENT_ASYNC_PENDING",
  CREATE_PATIENT_ASYNC_SUCCESS = "@@patient/CREATE_PATIENT_ASYNC_SUCCESS",
  CREATE_PATIENT_ASYNC_ERROR = "@@patient/CREATE_PATIENT_ASYNC_ERROR",
}
