import _ from "lodash"
import React, { useEffect } from "react"
import { useParams } from "react-router"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import AppLayout from "../../layouts/AppLayout"
import UserEditForm from "../../features/userEdit/components/UserEditForm"
import { fetchSpecialtiesAsync } from "../../features/specialty/state/specialtyActions"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { fetchUserAction } from "../../features/user/state/userActions"
import { IHaveUserId } from "../../features/user/state/userTypes"
import { getCurrentUser, getUserById, getCurrentUserId } from "../../shared/selectors/user"
import { IAppState } from "../../app/appTypes"
import UserConfigEditForm from "../../features/userConfig/components/UserConfigEditForm"
import { fetchAllUsersAsync } from "../../features/users/state/usersActions"
import Card from "../../shared/components/layout/Card"
import ButtonChangeTracking from "../../shared/components/buttons/ButtonChangeTracking"
import { fetchAuditEventsAsync } from "../../features/audit/state/auditEventActions"
import { toggleIsQuickViewOpen } from "../../features/nav/state/navActions"
import QuickView from "../../shared/components/ui/quickView/QuickView"
import UserChangeTracking from "../../features/userEdit/components/UserChangeTracking"

const UserEditPage = (): JSX.Element => {
  const { userId } = useParams<IHaveUserId>()
  const dispatch = useDispatch()
  const { t } = useTranslation(["shared", "user"])
  const user = useSelector((state: IAppState) => getUserById(state, userId), shallowEqual)
  const currentUser = useSelector((state: IAppState) => getCurrentUser(state))
  const currentUserId = useSelector((state: IAppState) => getCurrentUserId(state))

  useEffect(() => {
    dispatch(fetchSpecialtiesAsync())
    dispatch(fetchUserAction(userId))
    dispatch(fetchAllUsersAsync())
    if (!(userId == currentUserId)) dispatch(fetchUserAction(currentUserId))
  }, [])

  const renderUserEditForm = (): JSX.Element => {
    if (!userId || !currentUser) return <h3 className="text-lg">{t("userMessage:userNotFound")}</h3>
    if (userId !== currentUser.id && !user?.permissions?.canManage) return <h3 className="text-lg">{t("shared:notPermitted")}</h3>

    return <UserEditForm userId={userId} />
  }

  return (
    <AppLayout>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:users"),
              href: RoutePaths.USERS
            },
            {
              pageTitle: t("user:editUser")
            }
          ]}
        />
        <ButtonChangeTracking
          hasPermission={user?.permissions?.canTrackChanges}
          action={() => {
            dispatch(fetchAuditEventsAsync("User", userId))
            dispatch(toggleIsQuickViewOpen(userId, true))
          }}
        />
      </div>
      <Card>
        {renderUserEditForm()}
        <UserConfigEditForm userId={userId} />
        <QuickView>
          <UserChangeTracking userId={userId} />
        </QuickView>
      </Card>
    </AppLayout>
  )
}

export default UserEditPage
