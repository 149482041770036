import { differenceInSeconds } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import TimeDefaultFormat from "./TimeDefaultFormat"

type Props = {
  utcTime: string
  className?: string
}

const TimeAgo = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  const timeAgoInWords = (utcTime: Date, timeNow: Date) => {
    const timeAgoInSeconds = differenceInSeconds(timeNow, utcTime)
    if (timeAgoInSeconds < 0) return t("shared:time:fewSecondsAgo")

    const days = Math.floor(timeAgoInSeconds / 60 / 60 / 24)
    const hours = Math.floor(timeAgoInSeconds / 60 / 60) - days * 24
    const minutes = Math.floor(timeAgoInSeconds / 60) - days * 24 * 60 - hours * 60

    if (days >= 1 && days <= 3) {
      return days + " " + (days > 1 ? t("shared:time:days") : t("shared:time:day")) + " " + t("shared:time:ago")
    }
    if (hours >= 1) {
      return hours + " " + (hours > 1 ? t("shared:time:hours") : t("shared:time:hour")) + " " + t("shared:time:ago")
    }
    if (minutes >= 1) {
      return minutes + " " + (minutes > 1 ? t("shared:time:minutes") : t("shared:time:minute")) + " " + t("shared:time:ago")
    }
    if (minutes < 1) {
      return t("shared:time:fewSecondsAgo")
    }
  }

  if (!props.utcTime) return null

  const now: Date = new Date()
  const utcDate: Date = new Date(props.utcTime)

  const days = Math.floor(differenceInSeconds(now, utcDate) / 60 / 60 / 24)

  if (days > 3) return (<>
    <TimeDefaultFormat
      className={props.className}
      utcTime={props.utcTime}
      withoutTZ
    />
  </>)

  const timeAgoText = timeAgoInWords(utcDate, now)
  return <span className={props.className}>{timeAgoText}</span>
}
export default TimeAgo
