import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import { getAllCaseTaskResponseIds } from "../../../../../shared/selectors/task"
import ResponseListItem from "./ResponseListItem"
import DetailsCard from "../../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../../shared/components/layout/detailsCard/DetailsCardInner"

interface IProps {
  taskId: string;
  openAssetModal?: (assetId: string) => void
}

const ResponseList: React.FC<IProps> = (props): JSX.Element => {
  const taskResponseIds = useSelector((state: IAppState) => getAllCaseTaskResponseIds(state, props))
  if (!taskResponseIds || taskResponseIds.length == 0) return null

  return (
    <ul>
      {taskResponseIds.map((taskResponseId: string, index: number) => {
        return (
          <DetailsCard key={index}>
            <DetailsCardInner width="w-full">
              <ResponseListItem
                key={taskResponseId}
                taskResponseId={taskResponseId}
                openAssetModal={props.openAssetModal}
              />
            </DetailsCardInner>
          </DetailsCard>
        )
      })}
    </ul >
  )
}

export default ResponseList
