import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconPatient({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M18.75 3H15c0-1.655-1.345-3-3-3S9 1.345 9 3H5.25A2.25 2.25 0 003 5.25v16.5A2.25 2.25 0 005.25 24h13.5A2.25 2.25 0 0021 21.75V5.25A2.25 2.25 0 0018.75 3zM12 1.875c.623 0 1.125.502 1.125 1.125S12.623 4.125 12 4.125A1.122 1.122 0 0110.875 3c0-.623.502-1.125 1.125-1.125zm4.5 14.25a.376.376 0 01-.375.375H13.5v2.625a.376.376 0 01-.375.375h-2.25a.376.376 0 01-.375-.375V16.5H7.875a.376.376 0 01-.375-.375v-2.25c0-.206.169-.375.375-.375H10.5v-2.625c0-.206.169-.375.375-.375h2.25c.206 0 .375.169.375.375V13.5h2.625c.206 0 .375.169.375.375v2.25zm0-9a.376.376 0 01-.375.375h-8.25a.376.376 0 01-.375-.375v-.75c0-.206.169-.375.375-.375h8.25c.206 0 .375.169.375.375v.75z"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgIconPatient;
