import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { RoutePaths } from "../../app/routes/Routes"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import PathwayNewForm from "../../features/pathwayNew/components/PathwayNewForm"


const PathwaysNewPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          {
            pageTitle: t("shared:pathways"),
            href: RoutePaths.PATHWAYS
          },
          {
            pageTitle: t("pathway:addNewPathway")
          }
        ]}
      />
      <PathwayNewForm />
    </div>
  )
}

export default PathwaysNewPage
