import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../../app/appTypes"
import { getTaskById } from "../../../../../shared/selectors/task"
import { IconPathway, IconTask } from "../../../../../svg/icons"

interface Props {
  taskId: string
}


const TaskDetail: React.FC<Props> = (props: Props): JSX.Element => {
  const task = useSelector((state: IAppState) => getTaskById(state, props))
  if (task == null) return null
  const Icon = task.casePathwayId ? IconPathway : IconTask

  return (
    <div className="flex items-center my-1">
      <div className="text-ch-purple-500 mr-2">
        <Icon className="w-5 h-5" title={task.action} />
      </div>
      <div className="font-thin">
        {task.action}
      </div>
    </div>
  )
}

export default TaskDetail
