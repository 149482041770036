import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconExpandDown({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M0 0v2.5h20V0H0zm5 5l5 5 5-5H5zM0 15v5h20v-5H0z"
        />
        <path d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}

export default SvgIconExpandDown;
