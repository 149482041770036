import React from "react"
import { StyleVariants } from "../../state/sharedTypes"
import { IconTick } from "../../../svg/icons"
import { Field } from "formik"
import classNames from "classnames"

interface IProps {
  className?: string
  variant?: string
  name: string
  value: string
  isSelected: boolean
}

const defaultProps = {
  variant: StyleVariants.BLUE
}

const RadioCheckbox: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div className="mt-1">
      <div
        className={classNames(
          "flex items-center justify-center cursor-pointer rounded border border-ch-blue-alt-300 hover:border-ch-blue-alt-400 w-5 h-5",
          props.className,
          {
            "text-ch-gray-500": !props.value
          }
        )}
      >
        <IconTick
          className={classNames(
            "text-green-500 fill-current w-3 h-3",
            {
              "hidden": !props.isSelected
            }
          )}
        />
      </div>

      <div className="hidden">
        <Field type="radio" name={props.name} value={props.value} />
      </div>
    </div>
  )
}

RadioCheckbox.defaultProps = defaultProps

export default RadioCheckbox
