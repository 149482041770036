import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IAppState } from "../../../../app/appTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { getComponentTypeForAsset, componentLookup, clickableComponentAction } from "../../../../shared/helpers/assetHelpers"
import { getMeetingCaseById } from "../../../../shared/selectors/meeting"
import { IHaveMeetingCaseId, IMeetingCaseAsset } from "../../../meetingCase/state/meetingCaseTypes"
import RemoveMeetingCaseAssetButton from "../../../../shared/components/buttons/RemoveMeetingCaseAssetButton"
import AddMeetingCaseAssetButton from "../../../../shared/components/buttons/AddMeetingCaseAssetButton"

interface IProps {
  assetId: string
  meetingId?: string
  action?: () => void
  deleteAction?: (assetId: string) => void
  isSelected?: boolean
  caseId: string
  meetingCaseId?: string
  hideConsentList?: boolean
  openAssetModal: (selectedAssetId: string) => void
}

const SelectCaseAssetListItem: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const meetingCase = useSelector((state: IAppState) => getMeetingCaseById(state, props as IHaveMeetingCaseId))

  if (!asset) return null
  if (!meetingCase) return null

  const assetComponentType = getComponentTypeForAsset(asset)
  const Asset = componentLookup[assetComponentType]
  const clickAction = clickableComponentAction(props.action, assetComponentType)

  const meetingCaseAsset = _.find(meetingCase.meetingCaseAssets, (meetingCaseAsset: IMeetingCaseAsset) => meetingCaseAsset.caseAssetId == props.assetId)


  const renderButton = () => meetingCaseAsset ?
    <RemoveMeetingCaseAssetButton
      meetingCaseAssetId={meetingCaseAsset.id}
      meetingCaseId={props.meetingCaseId}
      disabled={!meetingCaseAsset.permissions.canDelete}
    />
    : <AddMeetingCaseAssetButton
      assetId={props.assetId}
      meetingCaseId={props.meetingCaseId} />

  return (
    <li className="mb-1">
      <Asset  {...props} action={clickAction} noAction>
        <div className="flex flex-wrap content-top h-8 ml-2 mt-1 justify-contents-end" >
          {renderButton()}
        </div>
      </Asset>
    </li>
  )
}

export default SelectCaseAssetListItem
