import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconVideoOn({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none">
        <path
          fill="currentColor"
          d="M21 26H4a2 2 0 01-2-2V8a2 2 0 012-2h17a2 2 0 012 2v4.06l5.42-3.87A1 1 0 0130 9v14a1 1 0 01-1.58.81L23 19.94V24a2 2 0 01-2 2z"
        />
        <path d="M0 0h32v32H0z" />
      </g>
    </svg>
  );
}

export default SvgIconVideoOn;
