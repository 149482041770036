import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { createMeetingCaseAssetAction } from "../../../features/meetingCaseAssets/state/meetingCaseAssetsActions"
import { StyleVariants } from "../../state/sharedTypes"
import Button from "./Button"

interface IPropsFromParent {
  assetId: string
  meetingCaseId: string
}

type Props = IPropsFromParent

const AddMeetingCaseAssetButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["case"])
  const [clicked, setClicked] = useState(false)
  const dispatch = useDispatch()

  const addTheAsset = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (!clicked) {
      setClicked(true)

      dispatch(createMeetingCaseAssetAction(
        props.meetingCaseId,
        props.assetId
      ))
    }
  }

  return (
    <Button action={addTheAsset} isChunky={false} variant={StyleVariants.BLUE} className="h-8">
      {t("case:selectAsset")}
    </Button>
  )
}

export default AddMeetingCaseAssetButton
