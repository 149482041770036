import { IAppState } from "../../../app/appTypes"
import { IHaveTeamId, IHaveTeamIdParam, ITeam } from "../../../features/team/state/teamTypes"
import { IHaveTeamUserId, ITeamUser } from "../../../features/teamUser/state/teamUserTypes"

export const getAllTeams = (state: IAppState): { [id: string]: ITeam } =>
  state.entities.teams.byId

export const getAllTeamIds = (state: IAppState): string[] =>
  state.entities.teams.allIds

export const getTeamById = (state: IAppState, id: string): ITeam =>
  state.entities.teams.byId[id]

export const getTeamByHasTeamId = (
  state: IAppState,
  ownProps: IHaveTeamId
): ITeam => getTeamById(state, ownProps.teamId)

export const getTeamByParamId = (
  state: IAppState,
  ownProps: IHaveTeamIdParam
): ITeam => {
  const teamId = ownProps.match.params.teamId
  return state.entities.teams.byId[teamId]
}

export const getTeamUserByTeamUserId = (state: IAppState, ownProps: IHaveTeamUserId): ITeamUser => {
  return state.entities.teamUsers.byId[ownProps.teamUserId] ?? null
}

export const getTeamUserByUserIdAndTeamId = (state: IAppState, teamId: string, userId: string): ITeamUser => {
  const team = state.entities.teams.byId[teamId]

  if (!team || !team.teamUsers) return null
  return team.teamUsers.map(x => state.entities.teamUsers.byId[x]).find((user): boolean => {
    if (!user) return false
    return user.userId == userId
  })
}

export const getTeamUsersByTeamId = (state: IAppState, teamId: string): ITeamUser[] => {
  const team = state.entities.teams.byId[teamId]
  const teamUsers: ITeamUser[] = team.teamUsers.map((id) => getTeamUserByTeamUserId(state, { teamUserId: id }))
  return teamUsers
}
