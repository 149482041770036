import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { ITeam, TeamActionTypes } from "./../../../team/state/teamTypes"
import { TeamsActionTypes } from "../teamsTypes"
import { TeamUserActionTypes } from "../../../teamUser/state/teamUserTypes"
import { ChatsActionTypes } from "../../../chats/state/chatsTypes"
import { ChatCableActionTypes } from "../../../chat/state/chatTypes"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"
import { normalize } from "normalizr"

type TByIdState = { [id: string]: ITeam }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_SUCCESS:
    case TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_SUCCESS:
    case TeamActionTypes.UPDATE_TEAM_ASYNC_SUCCESS:
    case TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_SUCCESS:
    case TeamActionTypes.FETCH_TEAM_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.teams || []
      }
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
    case ChatsActionTypes.FETCH_CHATS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.teams)
    case ChatCableActionTypes.DELIVER_CHAT:
      return _.merge({}, state, normalize(action.payload.chat, fetchChatsSchema).entities.teams)
    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return action.payload.all.result
    case TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_SUCCESS:
      return action.payload.result
    default:
      return state
  }
}

export const teamEntityReducer: Reducer<INormalizedEntities<ITeam>> = combineReducers({
  byId,
  allIds
})
