import React from "react"
import { useTranslation } from "react-i18next"
import Card from "../../../../shared/components/layout/Card"

interface IProps {
  title: string
  originalProp: string | string[] | JSX.Element
  changedProp: string | string[] | JSX.Element
  meetingId?: string
  duration?: string
}

const AuditEventSubCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["auditEvent"])

  return (
    <Card className="mt-2">
      <span className="block font-semibold">{t(`auditEvent:${props.title}`)}</span>
      <span className="block">
        <span className="block"> <span className="font-semibold">{t("auditEvent:original")}</span>:&nbsp;{props.originalProp}</span>
        <span className="block"> <span className="font-semibold">{t("auditEvent:change")}</span>:&nbsp;{props.changedProp}</span>
      </span>
    </Card>
  )
}

export default AuditEventSubCard
