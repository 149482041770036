import { normalize } from "normalizr"
import { takeLeading, put, call, Effect } from "redux-saga/effects"
import API from "../../../../apis/contextmeeting/api"
import { assetsSchema } from "../../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { AssetsActionTypes } from "../assetsTypes"

export function* fetchCaseAssetsAsync(action: Effect): Generator {
  try {
    const caseId = action.payload
    const response: any = yield call(API.get, `/cases/${caseId}/assets`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })
  } catch (error) {
    yield put({
      type: AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_ERROR,
      payload: error
    })
  }
}

export function* watchFetchCaseAssetsAsync(): Generator {
  yield takeLeading(AssetsActionTypes.FETCH_CASE_ASSETS_ASYNC_PENDING, fetchCaseAssetsAsync)
}

export function* deleteAssetsAsync(action: Effect): Generator {
  try {
    const caseId = action.payload.caseId
    const assetId = action.payload.assetId
    const response: any = yield call(API.delete, `/cases/${caseId}/assets/${assetId}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [assetsSchema])

    yield put({
      type: AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        caseId: caseId
      }
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteAssetSuccess",
      type: UserMessageTypes.SUCCESS
    }))

  } catch (error) {
    yield put({
      type: AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteAssetFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchDeleteAssetsAsync(): Generator {
  yield takeLeading(AssetsActionTypes.DELETE_CASE_ASSET_ASYNC_PENDING, deleteAssetsAsync)
}
