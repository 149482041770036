import { takeLeading, put, call } from "redux-saga/effects"
import { normalize } from "normalizr"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { fetchChatsSchema } from "../../../../apis/contextmeeting/schema"
import { ChatActionTypes, IFetchChatAsyncAction } from "../chatTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"

export function* fetchChatAsync(action: IFetchChatAsyncAction): Generator {
  try {
    const chatId = action.payload

    const response: any = yield call(API.get, `/chats/${chatId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchChatsSchema)

    yield put({
      type: ChatActionTypes.FETCH_CHAT_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: ChatActionTypes.FETCH_CHAT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "chatNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchChatAsync(): Generator {
  yield takeLeading(ChatActionTypes.FETCH_CHAT_ASYNC_PENDING, fetchChatAsync)
}
