import { AnyAction, combineReducers, Reducer } from "redux"
import { IPatientNewCheckDetailsUIState, IPatientNewUIState, PatientNewActionTypes, PatientNewCheckDetailsStatuses } from "../patientNewTypes"

export const checkDetailsInitialState: IPatientNewCheckDetailsUIState = {
  existingPatientIds: null,
  status: null
}

export const checkDetails: Reducer<IPatientNewCheckDetailsUIState> = (state = checkDetailsInitialState, action: AnyAction): IPatientNewCheckDetailsUIState => {
  switch (action.type) {
    case PatientNewActionTypes.CHECK_PATIENT_ASYNC_FOUND:
      return {
        ...state,
        existingPatientIds: action.payload.result,
        status: PatientNewCheckDetailsStatuses.Found
      }

    case PatientNewActionTypes.CHECK_PATIENT_ASYNC_NOT_FOUND:
      return {
        ...state,
        existingPatientIds: null,
        status: PatientNewCheckDetailsStatuses.Not_Found
      }

    case PatientNewActionTypes.CHECK_PATIENT_ASYNC_PENDING:
      return {
        ...state,
        existingPatientIds: null,
        status: PatientNewCheckDetailsStatuses.Pending
      }

    case PatientNewActionTypes.CHECK_PATIENT_ASYNC_RESET:
      return {
        ...state,
        existingPatientIds: null,
        status: null
      }

    default:
      return state
  }
}

export const patientNewUIReducer: Reducer<IPatientNewUIState> = combineReducers({
  checkDetails
})
