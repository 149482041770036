import React from "react"
import { getChatById } from "../../../../shared/selectors/message"
import { useSelector } from "react-redux"
import { ChatTypes, IChat } from "../../state/chatTypes"
import { IAppState } from "../../../../app/appTypes"
import { getTeamById } from "../../../../shared/selectors/team"
import ChatTitleUserList from "./ChatTitleUserList"

interface Props {
  chatId: string
}

const ChatTitle: React.FC<Props> = (props: Props): JSX.Element => {
  const chat: IChat = useSelector((state: IAppState) => getChatById(state, props))
  const team = useSelector((state: IAppState) => getTeamById(state, chat.team))

  const renderTitleText = (): JSX.Element => {
    if(chat.type == ChatTypes.TEAM) return renderTeamText()
    return <ChatTitleUserList chatId={props.chatId} />
  }

  const renderTeamText = (): JSX.Element => {
    if(!team) return
    return <span>{team.name}</span>
  }

  return (
    <div className="font-semibold text-sm truncate mb-1">
      {renderTitleText()}
    </div>
  )
}

export default ChatTitle
