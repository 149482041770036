import React, { useState } from "react"
import PageTitle from "../../shared/components/ui/PageTitle"
import { useTranslation } from "react-i18next"
import Button from "../../shared/components/buttons/Button"
import { useAppDispatch } from "../../shared/hooks"
import API from "../../apis/contextmeeting/api"
import { withAuthHeader } from "../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../features/userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../features/userMessage/state/userMessageTypes"
import { StyleVariants } from "../../shared/state/sharedTypes"
import { CepLogoColour, LogoSectra } from "../../svg/branding"

const RadiologyPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "radiology"])
  const dispatch = useAppDispatch()
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const clickHandler = async () => {
    setButtonDisabled(true)
    setTimeout(() => setButtonDisabled(false), 2000)
    try {
      const { data } = await API.get("/radiology/link", withAuthHeader())
      open(data.link)
    } catch (e) {
      dispatch(displayUserMessageAction({
        messageKey: "radiologyLinkFailed",
        type: UserMessageTypes.ERROR
      }))
    }
  }

  const outerClasses = "m-auto lg:w-1/2 border border-ch-gray-400 rounded-md lg:p-4 bg-white "
  const innerClasses = "flex flex-1 m-8 items-center text-center flex-wrap justify-center "

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:radiology")}
      />
      <div className={outerClasses}>
        <div className={innerClasses} >
          <CepLogoColour className="h-28" />
        </div>
        <div className={innerClasses} >
          <div>
            {t("radiology:text:tagLine")}
          </div>
        </div>
        <div className={innerClasses}>
          <div>
            <Button variant={StyleVariants.BLUE} action={clickHandler} isDisabled={buttonDisabled}>
              {t("radiology:buttons:open")}
            </Button>
          </div>
        </div>
        <div className={innerClasses} >
          <div>
            {t("radiology:text:sessionMessage")}
          </div>
        </div>
      </div>
      <div className={`${outerClasses} mt-12`}>
        <div className={innerClasses} >
          <div style={{ color: "#808080" }}>
            <LogoSectra className="h-8" />
          </div>
        </div>
        <div className={innerClasses}>
          <div>
            <Button external customColour="sectra-blue" href={"https://www.iepservice.co.uk/IEP/External/Login"} isDisabled={buttonDisabled}>
              {t("radiology:buttons:IEP")}
            </Button>
          </div>
        </div>
        <div className={innerClasses} >
          <div>
            {t("radiology:text:IEPMessage")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RadiologyPage
