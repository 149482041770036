import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgIconQuestionMark({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 45"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M22.5 0C10.094 0 0 10.094 0 22.5 0 34.907 10.094 45 22.5 45 34.907 45 45 34.907 45 22.5 45 10.094 34.907 0 22.5 0zm2.25 36h-4.5v-4.5h4.5V36zm2.196-10.991c-.441.355-.866.695-1.204 1.032-.918.916-.99 1.749-.992 1.785v.299h-4.5v-.376c0-.265.065-2.648 2.308-4.891.44-.439.984-.884 1.555-1.348 1.652-1.339 2.736-2.315 2.736-3.66a4.351 4.351 0 00-8.7.002h-4.5c0-4.88 3.97-8.852 8.851-8.852 4.88 0 8.852 3.971 8.852 8.852 0 3.593-2.653 5.737-4.406 7.157z"
      />
    </svg>
  );
}

export default SvgIconQuestionMark;
