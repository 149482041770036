import React from "react"
import classNames from "classnames"
import UserConsentList from "./UserConsentList"
import { useTranslation } from "react-i18next"
import { StyleVariants } from "../../../../../shared/state/sharedTypes"
import Button from "../../../../../shared/components/buttons/Button"
import { IUser } from "../../../../user/state/userTypes"
import RecommendationDisplay from "../../shared/RecommendationDisplay"
import { IAppState } from "../../../../../app/appTypes"
import Buttons from "../../../../../shared/components/layout/Buttons"
import UnsavedChangesPrompt from "../../../../../shared/components/forms/UnsavedChangesPrompt"
import { useSelector } from "react-redux"
import { getCurrentUserWithConfig } from "../../../../../shared/selectors/user"
import { getLiveMeetingConfig } from "../../../../../shared/selectors/liveMeetingConfig"

interface IProps {
  users: IUser[]
  saveRecommendation: () => void
  shareRecommendation: () => void
}

const ApprovalAreaWrapper: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["liveMeeting", "shared"])
  const currentUserConfig = useSelector((state: IAppState) => getCurrentUserWithConfig(state).config)
  const consents = useSelector(getLiveMeetingConfig).consents
  const allConsentsGiven = props.users.length == Object.keys(consents).length

  return (
    <div className={classNames(
      "flex",
      "flex-col",
      "flex-1",
      "p-2",
      "overflow-y-auto")}>
      <div
        className={classNames(
          "p-2",
          "rounded-md",
          "mt-2")}>
        <RecommendationDisplay headerKey={"shared:recommendationHeader"} />
        <Buttons
          className="my-4"
          buttons={[
            <Button
              key="1"
              action={props.shareRecommendation}
              variant={StyleVariants.BLUE_ALT}
            >
              {t("liveMeeting:changeRecommendation")}
            </Button>,
            <Button
              key="2"
              action={props.saveRecommendation}
              variant={StyleVariants.PURPLE}
              isDisabled={!allConsentsGiven}
            >
              {t("shared:saveRecommendation")}
            </Button>
          ]}
        />
        <p className="text-sm mb-4">{t("liveMeeting:approvalInstructions")}</p>
        <p className="text-sm mb-4">{t("liveMeeting:changeRecommendationInstructions")}</p>
        <UserConsentList users={props.users} />
      </div>
      <UnsavedChangesPrompt isEnabled={
        (currentUserConfig.isPresenter || currentUserConfig.isScribe)
      } />
    </div>
  )
}
export default ApprovalAreaWrapper
