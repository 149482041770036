import React from "react"
import ReactTooltip from "react-tooltip"
import SvgIconOptions from "../../../svg/icons/IconOptions"

interface MenuProps {
  id: string
  actions: ContextMenuAction[]
}

interface ContextMenuAction {
  text: string
  action: () => void
  isDisabled?: boolean
}

const menuLinkClasses = "block text-xs p-3 my-1 hover:bg-ch-blue-alt-300 text-ch-gray-600 min-w-32 cursor-pointer"

const menuLinkDisabledClasses = "block text-xs p-3 my-1 text-ch-gray-500 min-w-32 cursor-pointer"

const ContextMenu: React.FC<MenuProps> = (props: MenuProps): JSX.Element => {
  const toolTipId = `context-menu-${props.id}`
  return (
    <>
      <a
        data-tip
        data-for={toolTipId}
        data-event='click'
        data-padding="12px 50px"
        className="cursor-pointer"
      >
        <SvgIconOptions className="text-ch-purple-500 w-6 h-6" />
      </a>
      <ReactTooltip
        className="popup-menu"
        globalEventOff="click"
        id={toolTipId}
        clickable={true}
        effect="solid"
        backgroundColor={"white"}
        border={true}
        borderColor={"#E0E0E0"}
      >
        {props.actions.map((action, index) => {
          const classes = action.isDisabled ? menuLinkDisabledClasses : menuLinkClasses
          return <a onClick={action.action} className={classes} key={index}>{action.text}</a>
        }
        )}
      </ReactTooltip>
    </>
  )
}

export default ContextMenu
