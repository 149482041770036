import React, { ReactNode, useEffect } from "react"
import Breadcrumbs from "../../shared/components/ui/Breadcrumbs"
import { RoutePaths } from "../../app/routes/Routes"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getPathway } from "../../shared/selectors/pathway"
import { IHavePathwayId } from "../../features/pathway/pathwayTypes"
import { fetchPathwayAction } from "../../features/pathway/pathwayActions"
import PathwayHeader from "../../features/pathway/components/PathwayHeader"
import PathwayUserList from "../../features/pathway/components/PathwayUserList"
import PathwaySteps from "../../features/pathway/components/PathwaySteps"

interface IProps {
  children?: ReactNode
}

const PathwayPage: React.FC<IProps> = (): JSX.Element => {
  const pathwayId = useParams<IHavePathwayId>()
  const { t } = useTranslation(["shared", "pathway"])
  const dispatch = useDispatch()
  const pathway = useSelector(getPathway(pathwayId))

  useEffect(() => {
    dispatch(fetchPathwayAction(pathwayId.pathwayId))
  }, [])

  if (!pathway) return null

  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs
          breadcrumbs={[
            {
              pageTitle: t("shared:pathways"),
              href: RoutePaths.PATHWAYS
            },
            {
              pageTitle: t("pathway:pathwayDetails")
            }
          ]}
        />
      </div>

      <PathwayHeader {...pathwayId} />
      <PathwayUserList {...pathwayId} />
      <PathwaySteps {...pathwayId} />
    </div>
  )

}


export default PathwayPage
