/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../shared/components/buttons/Button"
import Buttons from "../../../../shared/components/layout/Buttons"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import useIsMobileView from "../../../../shared/hooks/useIsMobileView"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import CaseListItemContainer from "../../../cases/components/list/CaseListItemContainer"
import ChatCaseTag from "../../../chats/components/list/ChatCaseTag"
import { IChatNewFormData } from "../../state/chatNewTypes"

interface Props {
  values: IChatNewFormData
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  openModal: () => void
}

const ChatNewSelectedCaseDetails: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "message"])
  const isMobileView = useIsMobileView(["sm", "md"])

  if (!props.values.caseId) return null

  const onClickRemove = () => {
    props.setFieldValue("caseId", "")
  }

  const onClickChange = () => {
    props.setFieldValue("caseId", "")
    props.openModal()
  }

  const buttons = [
    <Button
      action={onClickChange}
      variant={StyleVariants.BLUE}
      key={1}
    >
      {t("message:new:buttons:changeCase")}
    </Button>,
    <Button
        action={onClickRemove}
        variant={StyleVariants.ORANGE}
        key={1}
      >
        {t("message:new:buttons:removeCase")}
    </Button>
  ]

  if(isMobileView) return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <ChatCaseTag caseId={props.values.caseId} />
      </div>
      <Buttons buttons={buttons} />
    </div>
  )

  return (
    <ul>
      <CaseListItemContainer caseId={props.values.caseId} key={props.values.caseId} hasSmallText>
        <DetailsCardButtons buttons={buttons} />
      </CaseListItemContainer>
    </ul>
  )
}

export default ChatNewSelectedCaseDetails
