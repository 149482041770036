import * as Yup from "yup"
import i18n from "../../../app/i18n"
import { timeFormatValid, dateIsFuture } from "../../../shared/helpers/timeHelpers"

export const meetingNewValidationSchema = (timezone: string): Yup.AnyObjectSchema => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(i18n.t("shared:required"))
      .max(50, i18n.t("shared:maxLengthExceeded", { maxLength: 50 })),
    specialtyId: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    coordinator: Yup
      .string()
      .trim().required(i18n.t("shared:required")),
    startDate: Yup.date()
      .required(i18n.t("shared:required"))
      .test("dateisFuture", i18n.t("meeting:requireFutureDate"), function (value) {
        return dateIsFuture(value, this.parent.startTime, timezone)
      }),
    startTime: Yup.string()
      .required(i18n.t("shared:required"))
      .test("timeValid", i18n.t("shared:invalidDate"), (value) => {
        return timeFormatValid(value)
      }),
    endTime: Yup.string()
      .required(i18n.t("shared:required"))
      .test("timeValid", i18n.t("shared:invalidDate"), (value) => {
        return timeFormatValid(value)
      })
  })
}

export const mdtMeetingNewValidationSchema = (timezone: string): Yup.AnyObjectSchema => {
  return meetingNewValidationSchema(timezone).shape({
    mdtTeamId: Yup.string()
      .required(i18n.t("shared:required"))
  }
  )
}
