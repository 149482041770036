import { CableActionMeta } from "../../../shared/@types/redux-cablecar"
import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export default interface INotificationsUIState {
  totalNotificationsCount: number
  unreadCount: number
  totalPages: number
  resultsPerPage: number
}

// Actions
export interface IFetchNotificationsAsyncAction {
  type: string
  payload: ISearchQuery
}

export interface IMarkAllNotificationsAsReadAction {
  type: string
  meta: CableActionMeta
}

export interface IMarkNotificationAsReadAction {
  type: string
  payload: {
    notificationId: string
  }
  meta: CableActionMeta
}

// Action Types
export enum NotificationsActionTypes {
  FETCH_NOTIFICATIONS_ASYNC_PENDING = "@@notifications/FETCH_NOTIFICATIONS_ASYNC_PENDING",
  FETCH_NOTIFICATIONS_ASYNC_SUCCESS = "@@notifications/FETCH_NOTIFICATIONS_ASYNC_SUCCESS",
  FETCH_NOTIFICATIONS_ASYNC_ERROR = "@@notifications/FETCH_NOTIFICATIONS_ASYNC_ERROR"
}

export enum NotificationsCableActionTypes {
  // INBOUND
  UPDATE_UNREAD_COUNT = "@@userCable/notifications/UPDATE_UNREAD_COUNT",
  DELIVER_NOTIFICATION = "@@userCable/notifications/DELIVER_NOTIFICATION",

  // OUTBOUND
  MARK_ALL_AS_READ = "@@userCable/notifications/MARK_ALL_AS_READ",
  MARK_AS_READ = "@@userCable/notifications/MARK_AS_READ",
  MARK_AS_UNREAD = "@@userCable/notifications/MARK_AS_UNREAD",
}
