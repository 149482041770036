import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { MeetingsActionTypes, IFetchMeetingsAsyncAction, IMeetingsToggleListViewAction, IFetchMeetingsInDateRangeAsyncAction } from "./meetingsActionTypes"

export const fetchMeetingsAsync = (searchQuery: ISearchQuery): IFetchMeetingsAsyncAction => {
  return {
    type: MeetingsActionTypes.FETCH_MEETINGS_ASYNC_PENDING,
    payload: searchQuery
  }
}
export const fetchMeetingsInDateRangeAsync = (start: string, end: string): IFetchMeetingsInDateRangeAsyncAction => {
  return {
    type: MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_PENDING,
    payload: {
      start,
      end
    }
  }
}

export const toggleListView = (isListView: boolean): IMeetingsToggleListViewAction => {
  return {
    type: MeetingsActionTypes.TOGGLE_LIST_VIEW,
    payload: isListView
  }
}
