import { Reducer, AnyAction } from "redux"
import OT from "@opentok/client"
import { WebRTCActionTypes } from "../webRTCTypes"

export const initialState: OT.Session = null

export const webRTCSessionReducer: Reducer<OT.Session> = (state: OT.Session = initialState, action: AnyAction): OT.Session => {
  switch(action.type) {
    case WebRTCActionTypes.CONNECT_TO_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case WebRTCActionTypes.UPDATE_WEB_RTC_SESSION:
      return {
        ...state,
        ...action.payload.session
      }

    case WebRTCActionTypes.DISCONNECT_FROM_WEB_RTC_SESSION_ASYNC_SUCCESS:
      return initialState

    default:
      return state
  }
}
