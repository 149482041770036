import { takeLeading } from "redux-saga/effects";
import API from "../../../apis/contextmeeting/api";
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader";
import { handleError, handleSuccess } from "../../../shared/helpers/sagaHelpers";
import { MfaActionTypes } from "./mfaTypes";

export function* mfaWatchers(): Generator {
  yield takeLeading(MfaActionTypes.REQUEST_SMS_ASYNC_PENDING, requestSmsAsync)
}

export function* requestSmsAsync() {
  try {
    console.log('requesting sms')
    yield API.post("/user/mfa/request_sms", {}, withAuthHeader())
    yield handleSuccess(MfaActionTypes.REQUEST_SMS_ASYNC_SUCCESS, {})
  } catch (error) {
    yield handleError(MfaActionTypes.REQUEST_SMS_ASYNC_ERROR, {}, "failed to request sms")
  }
}
