import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CaseListItem from "./CaseListItem"
import { getAllCaseIds } from "../../../../shared/selectors/case"


const CaseList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["case"])
  const caseIds = useSelector(getAllCaseIds)

  if (!caseIds.length) return (
    <h3 className="text-lg font-bold text-center">
      {t("case:noCases")}
    </h3>
  )

  return (
    <ul>
      {caseIds.map((caseId: string) => {
        return (
          <CaseListItem
            key={caseId}
            caseId={caseId}
          />
        )
      })}
    </ul>
  )
}

export default CaseList
