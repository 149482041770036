import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { differenceInSeconds } from "date-fns"

interface IProps {
  utcStartTime: string,
}

const LiveMeetingElapsedTime: React.FC<IProps> = ({ utcStartTime }): JSX.Element => {
  const { t } = useTranslation(["liveMeeting"])
  const [time, setTime] = useState(getTime())

  function getTime() {
    const diff = differenceInSeconds(new Date(), new Date(utcStartTime))
    const hours = Math.floor(diff / 3600)
    const minutes = Math.floor((diff - 3600 * hours) / 60)

    return {
      h: hours,
      m: minutes,
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getTime())
    }, 500)
    return () => clearInterval(intervalId)
  })

  const renderContent = (): JSX.Element => {
    return (
      <div className={classNames("flex items-center uppercase flex-col")}>
        <span>{t("liveMeeting:meetingElapsedTime")}</span>
        <div>
          <span className={classNames("mr-2")}>
            <span>{time.h}</span>&nbsp;
            <span>{t("liveMeeting:hours")}</span>
          </span>

          <span>
            <span>{time.m}</span>&nbsp;
            <span>{t("liveMeeting:mins")}</span>
          </span>
        </div>
      </div>
    )
  }

  return renderContent()
}

export default LiveMeetingElapsedTime
