import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { getLiveMeetingSharedImageZoomState } from "../../../selectors/liveMeetingConfig/asset"
import ImageZoomImage from "./ImageZoomImage"

interface IProps {
  readonly imageSrc: string,
}

const ImageZoomObserver: React.FC<IProps> = (props: IProps): JSX.Element => {
  const sharedImageZoomState = useSelector(getLiveMeetingSharedImageZoomState)

  const imageRef = useRef<HTMLImageElement>(null)
  const [pos, setPos] = useState({
    x: 0,
    y: 0
  })

  useEffect(() => {
    if (!sharedImageZoomState) return
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect()
      setPos({
        x: sharedImageZoomState.posX * rect.width,
        y: sharedImageZoomState.posY * rect.height,
      })
    }
  }, [imageRef.current, props])

  if (!sharedImageZoomState) return null

  const attr = {
    zoom: sharedImageZoomState.zoom,
    imageSrc: props.imageSrc,
    posX: pos.x,
    posY: pos.y,
    imageRef: imageRef,
  }

  return <ImageZoomImage {...attr} />
}

export default ImageZoomObserver
