import React from "react"

interface IProps {
  className?: string
  children: JSX.Element
  colour?: string
  sizeClasses?: string[]
}

const IconCircleContainer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex",
    "items-center",
    "justify-center",
    "p-1",
    "bg-white",
    "border",
    "border-ch-gray-500",
    "rounded-full"
  ]

  const sizeClasses = props.sizeClasses ? props.sizeClasses : ["w-12", "h-12"]
  const colour = props.colour || "text-ch-purple-500"
  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses, colour, ...sizeClasses].join(" ")

  return (
    <div className={allClasses}>
      {props.children}
    </div>
  )
}

export default IconCircleContainer
