import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CaseList from "../../features/cases/components/list/CaseList"
import { fetchCasesAsync } from "../../features/cases/state/casesActions"
import ICasesUIState from "../../features/cases/state/casesTypes"
import PageTitle from "../../shared/components/ui/PageTitle"
import Pagination from "../../shared/components/search/Pagination"
import QuickView from "../../shared/components/ui/quickView/QuickView"
import { getCasesUI, getQuickViewResourceId } from "../../shared/selectors/ui"
import { IAppState } from "../../app/appTypes"
import CaseQuickView from "../../features/cases/components/quickView/CaseQuickView"
import { TenantConfigContext } from "../../features/tenantConfig/contexts/TenantConfigContext"
import SearchInput from "../../shared/components/search/SearchInput"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import OrderBy from "../../shared/components/search/OrderBy"
import { CasesOrderByOptions } from "../../features/cases/state/casesTypes"
import CaseFilter from "../../features/case/components/search/CaseFilter"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"

const CasesPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "case"])
  const { careProviderIdLabel } = useContext(
    TenantConfigContext
  )
  const UI: ICasesUIState = useSelector((state: IAppState) => getCasesUI(state))
  const quickViewResourceId = useSelector((state: IAppState) => getQuickViewResourceId(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchCasesAsync)

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:cases")}
      />
      <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
        <div className="row-start-2 md:row-start-1 self-center">
          <Trans
            i18nKey="case:searchBy"
            components={{
              strong: <strong />
            }}
            values={{ careProviderIdLabel: careProviderIdLabel }}
          />
        </div>
        <div className="row-end-2 md:row-start-1 flex justify-end">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        buttonText={t("case:searchCases")}
      />

      <h2 className="text-lg mb-2">
        {UI.totalCasesCount} {t("shared:cases")}
      </h2>
      <div className="grid grid-flow-row md:grid-flow-col">
        <div>
          <CaseFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={CasesOrderByOptions}
            useTranslation={"case"}
          />
        </div>
      </div>
      <CaseList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalCasesCount}
        totalPages={UI.totalPages}
      />
      <QuickView>
        <CaseQuickView caseId={quickViewResourceId} />
      </QuickView>
    </div>
  )
}

export default CasesPage
