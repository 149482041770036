import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import ImageThumb from "../../../../shared/components/images/ImageThumb"
import { getMessageAssetById } from "../../../../shared/selectors/message"
import { IconPdf, IconSelectCross } from "../../../../svg/icons"
import { IMessageAsset } from "../../../message/state/messageTypes"

interface Props {
  messageAssetId: string
  removeItem: (messageAssetId: string) => void
}

const ExportChatAssetsSelectItem: React.FC<Props> = (props: Props): JSX.Element => {
  const messageAsset: IMessageAsset = useSelector((state: IAppState) => getMessageAssetById(state, props))

  if(!messageAsset.asset) return null

  const renderThumb = (): JSX.Element => {
    if(messageAsset.asset.contentType == "application/pdf") {
      return (
        <div className="text-ch-blue-alt-400 mr-1">
          <IconPdf className="w-5 h-5" />
        </div>
      )
    }
    return (
      <div className="flex w-5 h-5 items-center justify-center mr-1 overflow-hidden">
        <ImageThumb path={messageAsset.asset.path} title={messageAsset.asset.name} />
      </div>
    )
  }

  return (
    <div
      className="flex py-0.5 pl-2 rounded-md border border-ch-gray-400 items-center"
    >
      {renderThumb()}
      <span className="text-xs">
        {messageAsset.asset.name}
      </span>
      <a
        className="text-ch-gray-500 hover:text-ch-red-500 cursor-pointer p-1"
        onClick={() => props.removeItem(props.messageAssetId)}
      >
        <IconSelectCross className="w-3 h-3 fill-current mr-1"  />
      </a>
    </div>
  )
}

export default ExportChatAssetsSelectItem
