import React, { useContext } from "react"
import Button from "../../buttons/Button"
import { IWizardStep } from "./Wizard"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { FormContext } from "../../../contexts/FormContextProvider"
import { useTranslation } from "react-i18next"
import Buttons from "../../layout/Buttons"

interface IPropsFromParent {
  steps: IWizardStep[]
  currentStepNumber: number
  goToNextStep: () => void
  goToPreviousStep: () => void
}

type IProps = IPropsFromParent;

const WizardFooter: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const { formikFormRef, forceUpdate } = useContext(FormContext)
  const currentStep = props.steps[props.currentStepNumber]

  const onClickNext = () => {
    formikFormRef.current?.submitForm()
    setTimeout(() => {
      forceUpdate()
    })
  }

  const onClickBack = () => {
    props.goToPreviousStep()
    setTimeout(() => {
      forceUpdate()
    })
  }

  const onClickSkip = () => {
    props.goToNextStep()
  }

  if(!formikFormRef.current) return null

  const renderNextButton = (): JSX.Element => {
    return (
      <Button
        variant={StyleVariants.BLUE}
        action={onClickNext}
        isSubmit
        isDisabled={
          !formikFormRef.current?.isValid ||
          formikFormRef.current?.isSubmitting ||
          (currentStep.updateRequiredBeforeNext && !formikFormRef.current?.dirty)
        }
      >
        {currentStep.nextButtonText}
      </Button>
    )
  }

  const renderPreviousButton = (): JSX.Element => {
    if(!props.steps[props.currentStepNumber - 1]) return null

    return (
      <Button
        variant={StyleVariants.PURPLE}
        action={onClickBack}
      >
        {currentStep.previousButtonText}
      </Button>
    )
  }

  const renderSkipButton = (): JSX.Element => {
    const step: IWizardStep = props.steps[props.currentStepNumber]
    if(!step.hasSkip || !props.steps[props.currentStepNumber + 1]) return null

    return (
      <Button
        variant={StyleVariants.ORANGE}
        action={onClickSkip}
      >
        {t("shared:skip")}
      </Button>
    )
  }

  return (
    <div className="my-2 flex justify-between">
      <div>
        {renderPreviousButton()}
      </div>
      <div className="ml-auto">
        <Buttons
          className="mb-2"
          buttons={[
            renderSkipButton(),
            renderNextButton()
          ]}
        />
      </div>
    </div>
  )
}

export default WizardFooter
