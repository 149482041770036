import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getCaseAssetsCanBeSharedWithUser } from "../../../../shared/selectors/sharedAssets"
import { fetchCaseAssetsCanBeSharedWithUserAction } from "../../state/caseSharedAssetsActions"
import SelectSharedAssetListItem from "./SelectSharedAssetListItem"

interface Props {
  caseId: string
  userId: string
  openAssetModal: (assetId: string) => void
}

const SelectSharedAssetsList: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["case"])
  const dispatch = useDispatch()
  const assetIds = useSelector((state: IAppState) => getCaseAssetsCanBeSharedWithUser(state, props.caseId, props.userId))

  useEffect(() => {
    if(!props.userId) return

    dispatch(fetchCaseAssetsCanBeSharedWithUserAction(props.caseId, props.userId))
  }, [props.userId])

  if (!assetIds) return (
    <h3 className="text-lg font-bold text-center">
      {t("case:guestUsers:text:noAssetsToShare")}
    </h3>
  )

  return (
    <ul>
      {assetIds?.map((assetId: string) => {
          return (
            <SelectSharedAssetListItem
              key={assetId}
              assetId={assetId}
              caseId={props.caseId}
              userId={props.userId}
              action={() => props.openAssetModal(assetId)}
            />
          )
        })}
    </ul>
  )
}

export default SelectSharedAssetsList
