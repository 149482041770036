import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "../../../../app/appTypes"
import { ITeamUser, TeamUserActionTypes } from "../teamUserTypes"
import { TeamsActionTypes } from "../../../teams/state/teamsTypes"
import { TeamActionTypes } from "../../../team/state/teamTypes"

type TByIdState = { [id: string]: ITeamUser }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.all.entities.teamUsers
      }
    case TeamUserActionTypes.CREATE_TEAM_USER_ASYNC_SUCCESS:
    case TeamUserActionTypes.DESTROY_TEAM_USER_ASYNC_SUCCESS:
    case TeamActionTypes.UPDATE_TEAM_ASYNC_SUCCESS:
    case TeamActionTypes.FETCH_TEAM_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.teamUsers
      }
    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    default:
      return state
  }
}

export const teamUserEntityReducer: Reducer<INormalizedEntities<ITeamUser>> = combineReducers({
  byId,
  allIds
})
