import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FullCalendar, { FormatterInput, DatesSetArg } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { getAllMeetingsAsArray } from "../../../../shared/selectors/meeting"
import { IMeeting } from "../../../meeting/state/meetingTypes"
import { toggleIsQuickViewOpen } from "../../../nav/state/navActions"
import { useTranslation } from "react-i18next"
import { UserConfigContext } from "./../../../userConfig/contexts/UserConfigContext"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { formatDayDate, utcDateToZonedTime } from "../../../../shared/helpers/timeHelpers"
import IconCalendarCircle from "../../../../shared/components/calendar/IconCalendarCircle"
import { getCurrentUser } from "../../../../shared/selectors/user"
import classNames from "classnames"
import ReactTooltip from "react-tooltip"
import { fetchMeetingsInDateRangeAsync } from "../../state/meetingsActions"

interface IProps {
  isVisible?: boolean
}

const MeetingCalendar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation(["shared"])
  const { timezone, locale } = useContext(UserConfigContext)
  const [meetingEvents, setMeetingEvents] = useState(null)
  const meetings = useSelector(getAllMeetingsAsArray)
  const currentUser = useSelector(getCurrentUser)

  const dispatch = useDispatch()
  const onClickQuickView = (meetingId: string) => dispatch(toggleIsQuickViewOpen(meetingId, true))
  const fetchMeetingsInDateRange = ({ startStr, endStr }: DatesSetArg) => dispatch(fetchMeetingsInDateRangeAsync(startStr, endStr))
  const updateCalendarSize = () => {
    window.dispatchEvent(new Event("resize"))
  }

  const formatMeetingEvents = () => {
    if (!currentUser || !meetings) return

    return meetings.map((meeting: IMeeting) => {
      // multidayEvents are using backgroundColor attribute
      const isCoordinator = meeting.coordinatorId == currentUser.id
      return {
        meetingId: meeting.id,
        meetingStatus: meeting.status,
        title: meeting.name,
        start: utcDateToZonedTime(meeting.startDate, timezone),
        end: utcDateToZonedTime(meeting.endDate, timezone),
        backgroundColor: isCoordinator ? "#FFFFFF" : "#D0E2E3",
        className: classNames("overflow-hidden border rounded-sm cursor-pointer", {
          "bg-white": isCoordinator,
          "bg-ch-blue-200": !isCoordinator,
          "border-ch-gray-400": isCoordinator,
          "border-ch-blue-200": !isCoordinator,
        })
      }
    })
  }

  useEffect(() => {
    const meetingEvents = formatMeetingEvents()
    setMeetingEvents(meetingEvents)

    setTimeout(() => {
      updateCalendarSize()
    })
  }, [meetings])

  useEffect(() => {
    if (!props.isVisible) return

    setTimeout(() => {
      updateCalendarSize()
    })
  }, [props.isVisible])

  const showSpinner = (isLoading: boolean): JSX.Element => {
    if (isLoading) {
      return <LoadingSpinner />
    } else return null
  }

  const handleEventClick = (e) => {
    onClickQuickView(e.event.extendedProps.meetingId)
    updateCalendarSize()
  }

  const renderEventContent = (eventInfo, _element) => {
    return (
      <div data-tip={eventInfo.event.title}>
        {eventInfo.timeText}
        <IconCalendarCircle colorKey={eventInfo.event.extendedProps.meetingStatus} />
        {eventInfo.event.title}
        <ReactTooltip
          backgroundColor={"white"}
          border={true}
          borderColor={"#A3C3C4"}
          textColor={"#75A7A9"}
        />
      </div>
    )
  }

  const viewSettings = {
    dayGrid: {
      dayMaxEventRows: 2 //adjust for timeGridMonth
    }
  }

  const buttonText = {
    today: t("shared:today"),
    month: t("shared:month"),
    week: t("shared:week"),
    day: t("shared:day")
  }

  // the locale overrides this - Full Calender shows European time as 24 hour
  const eventTimeFormat: FormatterInput = {
    hour: "2-digit",
    minute: "2-digit",
    meridiem: "short"
  }

  const dayHeaderContent = (args) => {
    if (args.view.type == "dayGridMonth") return
    return formatDayDate(args.date)
  }

  const moreLinkContent = (args) => {
    return `+ ${args.num} ${t("shared:more")}`
  }

  return (
    <div className="flex h-full">
      <div className="flex-grow p-5 bg-white border border-ch-gray-400 text-ch-gray-600">
        <FullCalendar
          locale={locale}
          loading={showSpinner}
          height="100%"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay"
          }}
          buttonText={buttonText}
          dayHeaderContent={dayHeaderContent}
          allDaySlot={false}
          events={meetingEvents}
          eventClick={handleEventClick}
          eventTimeFormat={eventTimeFormat}
          eventTextColor={"#515151"}
          eventBorderColor={"#E0E0E0"}
          eventContent={renderEventContent}
          fixedWeekCount={false}
          dayMaxEventRows={true}
          views={viewSettings}
          moreLinkContent={moreLinkContent}
          slotEventOverlap={false}
          datesSet={fetchMeetingsInDateRange}
        />
      </div>
    </div>
  )
}

export default MeetingCalendar
