import React from "react"

interface IPropsFromParent {
  children: React.ReactNode
  className?: string
}

type IProps = IPropsFromParent

const Card: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "mb-2",
    "bg-white",
    "border",
    "border-ch-gray-400",
    "rounded-md p-4"
  ]

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  return (
    <div className={allClasses}>
      { props.children }
    </div>
  )
}

export default Card
