import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import Button from "../../../../shared/components/buttons/Button"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import DetailsCardWithNested from "../../../../shared/components/layout/detailsCard/DetailsCardWithNested"
import { getCaseById } from "../../../../shared/selectors/case"
import { getMeetingById } from "../../../../shared/selectors/meeting"
import { StyleVariants } from "../../../../shared/state/sharedTypes"
import { IconCalendar, IconFolder } from "../../../../svg/icons"
import MeetingDateTime from "../../../meeting/components/header/MeetingDateTime"
import MeetingTimeToStart from "../../../meeting/components/header/MeetingTimeToStart"
import { MeetingStatuses } from "../../../meeting/state/meetingTypes"
import MeetingCaseDetails from "../../../meetingCase/components/MeetingCaseDetails"
import { createAllMeetingCaseAssetsAction } from "../../../meetingCaseAssets/state/meetingCaseAssetsActions"
import SelectCaseAssetListItem from "../assetList/SelectCaseAssetListItem"

interface Props {
  meetingId: string
  caseId: string
  meetingCaseId: string
  openViewAssetModal: (assetId: string) => void
}

const SelectMeetingAssets: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "case"])
  const meeting = useSelector((state: IAppState) => getMeetingById(state, { meetingId: props.meetingId }))
  const theCase = useSelector((state: IAppState) => getCaseById(state, props))
  const dispatch = useDispatch()

  const selectAll = () => dispatch(createAllMeetingCaseAssetsAction(props.meetingCaseId))

  return (
    <>
      <h1>{t("case:selectingAssetsForCaseInMeeting")}</h1>
      <DetailsCardWithNested className="flex-col" noBorder>
        <DetailsCard hasBorder={false}>
          <DetailsCardInner>
            <div className="md:flex md:text-lg flex-row font-semibold mb-3">
              <IconCalendar className="text-ch-purple-500 self-center mr-1 w-5 h-5" />
              <p>
                {meeting.name} {t("shared:on")}&nbsp;
              </p>
              <MeetingDateTime
                startDate={meeting.startDate}
                endDate={meeting.endDate}
              />
            </div>
          </DetailsCardInner>

          <DetailsCardRight>
            <div className="text-center lg:text-right mb-2">
              {
                meeting.status == MeetingStatuses.Ended
                  ? t("meeting:meetingEnded")
                  : <MeetingTimeToStart startDate={meeting.startDate} />
              }
            </div>
          </DetailsCardRight>
        </DetailsCard>

        <DetailsCard isDark={true}>
          <DetailsCardInner width={"w-full"}>
            <IconCircleContainer className="sm:mr-4">
              <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
            </IconCircleContainer>
            <MeetingCaseDetails meetingCaseId={props.meetingCaseId} />
          </DetailsCardInner>
        </DetailsCard>
      </DetailsCardWithNested>
      <div className="w-full md:mb-3 flex justify-end">
        <Button variant={StyleVariants.BLUE} action={selectAll} >{t("case:selectAllAssets")}</Button>
      </div>
      <ul>
        {theCase.assets.map((assetId: string) => {
          return (
            <SelectCaseAssetListItem
              action={() => props.openViewAssetModal(assetId)}
              key={assetId}
              assetId={assetId}
              caseId={props.caseId}
              meetingId={props.meetingId}
              meetingCaseId={props.meetingCaseId}
              hideConsentList
              openAssetModal={props.openViewAssetModal}
            />
          )
        })}
      </ul>
    </>
  )
}
export default SelectMeetingAssets
