import classNames from "classnames"
import _ from "lodash"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { MockStore } from "redux-mock-store"
import Modal from "react-modal"
import { IAppState } from "../../../../app/appTypes"
import ButtonExpandCollapse from "../../../../shared/components/buttons/ButtonExpandCollapse"
import Card from "../../../../shared/components/layout/Card"
import ModalContainer from "../../../../shared/components/modals/ModalContainer"
import SvgIconCalendar from "../../../../svg/icons/IconCalendar"
import { getMeetingById, getMeetingUserByUserIdAndMeetingId } from "../../../../shared/selectors/meeting"
import { defaultModalStyle } from "../../../../shared/state/sharedTypes"
import { IMeeting, MeetingStatuses } from "../../state/meetingTypes"
import MeetingUserListItem from "./MeetingUserListItem"
import MeetingDateTime from "../header/MeetingDateTime"
import { createMeetingUserAcion } from "../../../meetingUser/state/meetingUserActions"
import InviteButton from "../../../../shared/components/buttons/InviteButton"
import AddUsersForm from "../../../../shared/components/user/AddUsersForm"
import { IMeetingUser } from "../../../meetingUser/state/meetingUserTypes"

interface IPropsFromParent {
  store?: MockStore
  meetingId: string
}

const MeetingUsers: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const meeting = useSelector((state: IAppState): IMeeting => getMeetingById(state, props))
  const coordinatorMeetingUser = useSelector((state: IAppState): IMeetingUser => getMeetingUserByUserIdAndMeetingId(state, props.meetingId, meeting.coordinatorId))
  const [isVisibleList, toggleList] = useState(false)
  const { t } = useTranslation(["shared", "meeting"])
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const dispatch = useDispatch()

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const createMeetingUsers = (values, { setSubmitting }) => {
    const data = {
      meetingId: meeting.id,
      userIds: values.users,
      teamIds: values.teams
    }
    dispatch(createMeetingUserAcion(data))
    setSubmitting(false)
    closeModal()
  }

  if (!meeting.users || !coordinatorMeetingUser) return null
  // sort sorts in place
  meeting.meetingUsers?.sort((a, b) => a == coordinatorMeetingUser.id ? -1 : b == coordinatorMeetingUser.id ? 1 : 0)

  return (
    <div>
      <Card className="flex-col">
        <div className="flex items-center flex-wrap">
          <h3 className="mr-4 mb-2 text-lg">
            {t("shared:users")} ({meeting.users.length})
          </h3>
          {meeting.permissions && meeting.permissions.canInvite && meeting.status !== MeetingStatuses.Ended ?
            <InviteButton
              openModal={openModal}
              label={t("meeting:inviteParticipantsOrTeams")}
            /> : null
          }
        </div>
        <div className="flex mb-2 items-end">
          <p className="flex-1 pr-10 lg:pr-24">
            <Trans i18nKey="meeting:usersIntro" components={{ strong: <strong /> }} />
          </p>

          <ButtonExpandCollapse isExpanded={isVisibleList} action={() => toggleList(!isVisibleList)} expandText={t("meeting:showUsers")} collapseText={t("meeting:hideUsers")} />
        </div>

        <ul className={classNames({ hidden: !isVisibleList })}>
          {_.map(meeting.meetingUsers, (meetingUserId) => {
            return <MeetingUserListItem key={meetingUserId} meetingId={props.meetingId} meetingUserId={meetingUserId} />
          })}
        </ul>
      </Card>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={defaultModalStyle}>
        <ModalContainer handleClose={closeModal}>
          <h2 className="text-lg mb-3">
            {t("meeting:inviteParticipantsIntro")}
          </h2>

          <div className="md:flex md:text-lg flex-row font-semibold mb-3">
            <SvgIconCalendar className="text-ch-purple-500 self-center mr-1 w-5 h-5" />
            <p>
              {meeting.name} {t("shared:on")}&nbsp;
            </p>
            <MeetingDateTime
              startDate={meeting.startDate}
              endDate={meeting.endDate}
            />
          </div>

          <div className="mb-3">
            <p className="text-ch-blue-600">{t("meeting:inviteParticipantsDesc1")}</p>
            <p className="text-ch-blue-600">{t("meeting:inviteParticipantsDesc2")}</p>
          </div>

          <div className="w-full">
            <AddUsersForm
              submitHandler={createMeetingUsers}
              excludedUserIds={meeting.users}
              userLabel={t("shared:chooseUsers", { user: _.lowerCase(t("shared:participant")) })}
              teamUserListHeader={t("meeting:teamUserListHeader")}
              buttonText={
                t("shared:addUsers", {
                  users: t("shared:participants"),
                  entity: t("shared:meeting")
                })
              }
            />
          </div>
        </ModalContainer>
      </Modal>
    </div>
  )
}

export default MeetingUsers
