import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../shared/components/buttons/Button"
import { StyleVariants } from "../../../shared/state/sharedTypes"

interface Props {
  canShareAssets: boolean
  caseId: string
  userId: string
  action: (userId: string) => void
}

const ShareAssetsButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["case"])

  if(!props.canShareAssets) return null

  return(
    <Button
      variant={StyleVariants.BLUE}
      action={() => props.action(props.userId)}
    >
      { t("case:guestUsers:buttons:shareAssets") }
    </Button>
  )
}

export default ShareAssetsButton
