import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { deleteMeetingCaseAssetAction } from "../../../features/meetingCaseAssets/state/meetingCaseAssetsActions"
import { StyleVariants } from "../../state/sharedTypes"
import Button from "./Button"

interface IPropsFromParent {
  meetingCaseAssetId: string
  meetingCaseId: string
  disabled?: boolean
}

type Props = IPropsFromParent

const RemoveMeetingCaseAssetButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["case"])
  const [clicked, setClicked] = useState(false)
  const dispatch = useDispatch()

  const removeMeetingCaseAsset = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    if (props.disabled) return
    if (!clicked) {
      setClicked(true)
      dispatch(deleteMeetingCaseAssetAction(
        props.meetingCaseId,
        props.meetingCaseAssetId
      ))
    }
  }
  const variant = props.disabled ? StyleVariants.GRAY : StyleVariants.ORANGE
  return (
    <Button action={removeMeetingCaseAsset} isChunky={false} variant={variant} isDisabled={props.disabled} >
      {t("case:deselectAsset")}
    </Button>
  )
}

export default RemoveMeetingCaseAssetButton
