import React from "react"
import { Link } from "react-router-dom"
import { StyleVariants } from "../../state/sharedTypes"

interface Props {
  className?: string
  variant?: string
  href?: string
  action?: () => void
  noPaddingX?: boolean
  noPaddingY?: boolean
  noHover?: boolean
  baseTextColour?: string
  hoverTextColour?: string
}

const ButtonLink: React.FC<Props> = ({ noHover, className, href, action, variant, children, ...props }): JSX.Element => {
  if (!variant) {
    variant = StyleVariants.GRAY
  }

  const baseTextColour = props.baseTextColour || `text-${variant}-500`
  const hoverTextColour = props.hoverTextColour || `hover:text-${variant}-600`
  const defaultClasses = [
    baseTextColour,
    props.noPaddingX ? "" : "px-4",
    props.noPaddingY ? "" : "py-2",
    noHover ? "cursor-default" : hoverTextColour,
    "flex",
    "items-center"
  ]

  const propClasses = className ? className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses].join(" ")

  if (href) {
    if (href.toLowerCase().startsWith("http")) {
      return (
        <a href={href} target="_blank" rel="noreferrer" className={allClasses}>
          {children}
        </a>
      )
    }
    return (
      <Link to={href} className={allClasses}>
        {children}
      </Link>
    )
  }

  if (action)
    return (
      <button onClick={action} className={allClasses}>
        {children}
      </button>
    )

  return (
    <button type="submit" className={allClasses}>
      {children}
    </button>
  )

}

export default ButtonLink
