import classNames from "classnames"
import React from "react"
import { IconAccept, IconRecommendation, IconComment, IconHandshake, IconImage, IconLink, IconPdf, IconReject, IconUser, IconFolder, IconMeeting, IconLock, IconTask, IconVideoOn, IconPresenter, IconScribe, IconDeferral } from "../../../../svg/icons"
import { CardIconStyle } from "../../state/auditEventTypes"

interface IProps {
  icon: string
  color?: string
}

const AuditEventCardIcon = (props: IProps): JSX.Element => {
  const iconComponents = {
    iconAccept: IconAccept,
    iconRecommendation: IconRecommendation,
    iconComment: IconComment,
    iconHandshake: IconHandshake,
    iconImage: IconImage,
    iconLink: IconLink,
    iconPdf: IconPdf,
    iconReject: IconReject,
    iconUser: IconUser,
    iconFolder: IconFolder,
    iconMeeting: IconMeeting,
    iconLock: IconLock,
    iconTask: IconTask,
    iconVideoOn: IconVideoOn,
    iconPresenter: IconPresenter,
    iconScribe: IconScribe,
    iconDeferral: IconDeferral
  }
  const IconComponent = iconComponents[`${props.icon}`]
  const color = props.color || CardIconStyle.purpleColor

  return (
    <>
      {<IconComponent className={classNames(CardIconStyle.imageStyle, color)} />}
    </>
  )
}

export default AuditEventCardIcon
