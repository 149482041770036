import React from "react"
import ExportChatAssetsSelectItem from "./ExportChatAssetsSelectItem"

interface Props {
  assetIds: string[]
  setFieldValue: (field: string, value: string[], shouldValidate?: boolean) => void
}

const ExportChatAssetsSelect: React.FC<Props> = (props: Props): JSX.Element => {
  const onRemoveAsset = (removedAssetId: string) => {
    if(props.assetIds.length == 1) return
    const filteredAssetIds = props.assetIds.filter((id) => id != removedAssetId)
    props.setFieldValue("assetIds", filteredAssetIds)
  }

  return (
   <div className="flex gap-1 item overflow-scroll">
    {(props.assetIds).map((messageAssetId: string) => {
      return (
        <ExportChatAssetsSelectItem
          key={messageAssetId}
          messageAssetId={messageAssetId}
          removeItem={onRemoveAsset}
        />
      )
    })}
   </div>
  )
}

export default ExportChatAssetsSelect
