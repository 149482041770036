import React from "react"
import _ from "lodash"
import { IAppState } from "../../../../../app/appTypes"
import { getTaskResponseById } from "../../../../../shared/selectors/task"
import { useTranslation } from "react-i18next"
import TimeDefaultFormat from "../../../../../shared/components/time/TimeDefaultFormat"
import classNames from "classnames"
import ResponseAsset from "./ResponseAsset"
import { useSelector } from "react-redux"

interface IProps {
  taskResponseId: string
  openAssetModal: (assetId: string) => void
}

const ResponseDetails: React.FC<IProps> = (props) => {
  const taskResponse = useSelector((state: IAppState) => getTaskResponseById(state, props))
  if (!taskResponse) return null

  const { t } = useTranslation(["task"])

  return (
    <div className="rounded-md bg-white">
      <div className={classNames(
        {
          "mb-2": taskResponse.asset
        }
      )}>
        <h4 className="font-semibold">
          {taskResponse.createdBy.firstName} {taskResponse.createdBy.lastName}
        </h4>
        <p>
          {t("task:responseOn")}: <TimeDefaultFormat utcTime={taskResponse.createdAt} />
        </p>
        <p className="font-semibold">
          {t("task:response")}: {taskResponse.response}
        </p>
      </div>
      <ResponseAsset
        assetId={taskResponse.asset}
        openAssetModal={props.openAssetModal}
      />
    </div>
  )
}

export default ResponseDetails
