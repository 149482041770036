import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"
import { IUser } from "../../../user/state/userTypes"

type Props = {
  userId: string
}

const PathwayUserListItem: React.FC<Props> = ({userId}: Props) => {

  const user:IUser = useSelector((state: IAppState) => state.entities.users.byId[userId])

  if (!user) { return <LoadingSpinner /> }
  return (
    <li>
      <DetailsCard>
        <UserDetailsCard user={user} />
      </DetailsCard>
    </li>
  )
}

export default PathwayUserListItem
