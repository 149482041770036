import { ITeamNewData, ICreateTeamAction, TeamActionTypes, IUpdateTeamAction, ITeamDeleteAction, IFetchTeamAsyncAction, ITeamUsersUpdateData, IUpdateTeamUsersAction } from "./teamTypes"

export const fetchTeamAsync = (teamId: string): IFetchTeamAsyncAction => {
  return {
    type: TeamActionTypes.FETCH_TEAM_ASYNC_PENDING,
    payload: teamId
  }
}

export const createTeamAsync = (teamData: ITeamNewData): ICreateTeamAction => {
  return {
    type: TeamActionTypes.CREATE_TEAM_ASYNC_PENDING,
    payload: teamData
  }
}

export const updateTeamAsync = (teamId: string, teamData: ITeamNewData): IUpdateTeamAction => {
  return {
    type: TeamActionTypes.UPDATE_TEAM_ASYNC_PENDING,
    payload: {
      id: teamId,
      team: teamData
    }
  }
}

export const deleteTeamAsync = (teamId: string): ITeamDeleteAction => {
  return {
    type: TeamActionTypes.DELETE_TEAM_ASYNC_PENDING,
    payload: teamId
  }
}

export const updateTeamUsersAction = (teamId: string, teamData: ITeamUsersUpdateData): IUpdateTeamUsersAction => {
  return {
    type: TeamActionTypes.UPDATE_TEAM_USERS_ASYNC_PENDING,
    payload: {
      id: teamId,
      data: teamData
    }
  }
}
