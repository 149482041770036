import React from "react"
import { useTranslation } from "react-i18next"
import { IMeeting, MeetingStatuses } from "../../state/meetingTypes"

interface IPropsFromParent {
  meeting: IMeeting
}
const MeetingStatus: React.FC<IPropsFromParent> = ({ meeting }: IPropsFromParent) => {
  let status = meeting.status as string
  if (status == MeetingStatuses.InProgress as string) {
    status = "inProgress"
  }
  const { t } = useTranslation(["shared", "meeting"])
  return (
    <div>
      {t("meeting:status")}: { t(`meeting:${status}`)}
    </div>
  )
}

export default MeetingStatus
