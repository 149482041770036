import React from "react"
import { useSelector } from "react-redux"
import { IDocumentAsset } from "../../../assets/state/assetsTypes"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IAppState } from "../../../../app/appTypes"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import ImageZoomObserver from "../../../../shared/components/ui/imageZoom/ImageZoomObserver"

interface IProps {
  assetId: string
}

const LiveMeetingSharedViewImageViewerObserver: React.FC<IProps> = (props: IProps): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  if (!asset) return <LoadingSpinner />

  const image = asset.asset as IDocumentAsset

  return (
    <ImageZoomObserver
      imageSrc={image.path}
    />
  )
}

export default LiveMeetingSharedViewImageViewerObserver
