import { AmendmentTypes } from "../../patient/state/patientTypes"
import { IPatientCaseDetailsData, IPatientDetailsData, IPatientFurtherDetailsData } from "../../patientNew/state/patientNewTypes"

export interface IPatientCaseNewData {
  casesAttributes: IPatientCaseDetailsData[]
}

export interface IPatientEditData extends IPatientDetailsData {
  furtherDetailsAttributes?: IPatientFurtherDetailsData,
  casesAttributes?: IPatientCaseDetailsData[],
  patientAmendmentsAttributes: IPatientAmendmentNewData[]
}

export interface IPatientEditFurtherDetailsData {
  furtherDetailsAttributes: IPatientFurtherDetailsData,
}

export interface IPatientAmendmentNewData {
  reason: string
  amendmentType: AmendmentTypes
}

export interface IUpdatePatientAsyncAction {
  type: string,
  payload: {
    id: string
    patient: IPatientEditData | IPatientCaseNewData | IPatientEditFurtherDetailsData
  }
}

export interface IAddCaseForPatientAsyncAction {
  type: string,
  payload: {
    id: string
    patient: IPatientCaseNewData
  }
}

export enum PatientEditActionTypes {
  UPDATE_PATIENT_ASYNC_PENDING = "@@patient/UPDATE_PATIENT_ASYNC_PENDING",
  UPDATE_PATIENT_ASYNC_SUCCESS = "@@patient/UPDATE_PATIENT_ASYNC_SUCCESS",
  UPDATE_PATIENT_ASYNC_ERROR = "@@patient/UPDATE_PATIENT_ASYNC_ERROR",

  ADD_CASE_FOR_PATIENT_ASYNC_PENDING = "@@patient/ADD_CASE_FOR_PATIENT_ASYNC_PENDING",
  ADD_CASE_FOR_PATIENT_ASYNC_SUCCESS = "@@patient/ADD_CASE_FOR_PATIENT_ASYNC_SUCCESS",
  ADD_CASE_FOR_PATIENT_ASYNC_ERROR = "@@patient/ADD_CASE_FOR_PATIENT_ASYNC_ERROR",
}
