import { addDays } from "date-fns"
import React from "react"
import { parseTime } from "../../../shared/helpers/timeHelpers"
import { differenceInSeconds } from "date-fns"

type Props = {
  startTime: string,
  endTime: string
}
export const DurationLabel = ({startTime, endTime}: Props): JSX.Element => {

  if(!startTime || !endTime) return null
  const duration = () => {

    const
      start = parseTime(startTime),
      endDate = parseTime(endTime),
      end =
        endDate <= start ? addDays(endDate, 1) : endDate

    const diff = differenceInSeconds(end, start)
    const hours = Math.floor(diff / 3600)
    const minutes = Math.floor((diff - 3600 * hours) / 60)

    let str = ""
    if (hours > 0) str = hours + "h"
    if (hours > 0 && minutes > 0) str += " "
    if (minutes > 0) str += minutes + "min" // TODO: Translate

    return str
  }

  return (
    <div>
      {duration()}
    </div>
  )
}
