import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgLogoContextBadge({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 47 55"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <filter
          id="logo-context-badge_svg__a"
          width="101%"
          height="116.5%"
          x="-.5%"
          y="-5.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={2}
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
        </filter>
        <path id="logo-context-badge_svg__b" d="M0 0h1440v85H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#ECF5F5" d="M-20-15h1440v900H-20z" />
        <g transform="translate(-20 -15)">
          <use
            fill="#000"
            filter="url(#logo-context-badge_svg__a)"
            xlinkHref="#logo-context-badge_svg__b"
          />
          <use fill="#FFF" xlinkHref="#logo-context-badge_svg__b" />
        </g>
        <path
          fill="#006F74"
          d="M23.444 33.839v6.768l11.722-6.768-5.86-3.384z"
        />
        <path
          fill="#75A7A9"
          d="M23.444 13.536l11.722 6.767-5.86 3.384-5.862-3.384-5.86 3.384-5.862-3.384zm0-13.536L0 13.536l7.62 4.399L31.062 4.399z"
        />
        <path
          fill="#006F74"
          d="M39.269 17.935V31.47l7.62-4.4V13.537zm0 18.272l-15.825 9.137v8.798l15.825-9.136 7.62-4.4v-8.798z"
        />
        <path
          fill="#75A7A9"
          d="M27.547 11.167l11.722 6.768 7.62-4.4-11.723-6.767z"
        />
        <path
          fill="#A3C3C4"
          d="M23.444 33.839v6.768l-5.86-3.384-5.862-3.384V20.303l5.861 3.384v6.768z"
        />
        <path
          fill="#A3C3C4"
          d="M0 40.607l7.62 4.399 15.824 9.136v-8.798L7.62 36.208V17.935L0 13.535z"
        />
      </g>
    </svg>
  );
}

export default SvgLogoContextBadge;
