import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { IconBin } from "../../../svg/icons"
import { StyleVariants } from "../../state/sharedTypes"

interface IPropsFromParent {
  deleteAction: (assetId: string) => void
  assetId: string
  variant?: string
}

type Props = IPropsFromParent

const defaultProps = {
  variant: StyleVariants.BLUE
}

const DeleteAssetButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared"])

  const [clicked, setClicked] = useState(false)
  const deleteTheAsset = (e: React.MouseEvent) => {
    if (!clicked) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      setClicked(true)
      props.deleteAction(props.assetId)
    }
  }

  return (
    <a href="#" onClick={deleteTheAsset} >
      <IconBin className="w-7 h-7 mr-1 text-ch-blue-500 hover:text-ch-blue-600" title={t("shared:delete")} />
    </a>
  )
}

DeleteAssetButton.defaultProps = defaultProps

export default DeleteAssetButton
