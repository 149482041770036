import React from "react"
import { IconMessage } from "../../../../svg/icons"
import { useTranslation } from "react-i18next"
import Counter from "../../../../shared/components/ui/Counter"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import { getChatById } from "../../../../shared/selectors/message"

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  chatId: string
}

const ChatViewOpenButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared", "message"])
  const chat = useSelector((state: IAppState) => getChatById(state, props))

  if (props.isOpen) return null

  return (
    <div className="absolute right-10 bottom-10 z-50">
      <a onClick={() => props.setIsOpen(true)} className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer text-white bg-ch-blue-alt-500 shadow relative">
        <Counter count={chat?.unreadCount} top="4px" />
        <IconMessage className="fill-current w-6 h-6" title={t("message:shared:buttons:openChat")} />
      </a>
    </div>
  )
}

export default ChatViewOpenButton
