import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { ICreateCaseTaskAsyncAction, IDeleteCaseTaskAsyncAction, IFetchCaseTasksAsyncAction, IFetchTasksAsyncAction, ITaskNewData, TasksActionTypes } from "./tasksTypes"

export const fetchTasksAsync = (searchQuery: ISearchQuery): IFetchTasksAsyncAction => {
  return {
    type: TasksActionTypes.FETCH_TASKS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const fetchCaseTasksAsync = (caseId: string): IFetchCaseTasksAsyncAction => {
  return {
    type: TasksActionTypes.FETCH_CASE_TASKS_ASYNC_PENDING,
    payload: caseId
  }
}

export const createCaseTaskAsync = (caseId: string, task: ITaskNewData): ICreateCaseTaskAsyncAction => {
  return {
    type: TasksActionTypes.CREATE_CASE_TASK_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      task: task,
      casePathwayId: task.casePathwayId
    }
  }
}

export const deleteCaseTaskAsync = (caseId: string, taskId: string, casePathwayId: string): IDeleteCaseTaskAsyncAction => {
  return {
    type: TasksActionTypes.DELETE_CASE_TASK_ASYNC_PENDING,
    payload: {
      caseId: caseId,
      taskId: taskId,
      casePathwayId: casePathwayId
    }
  }
}
