import { normalize } from "normalizr"
import queryString from "query-string"
import humps from "humps"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import API from "../../../apis/contextmeeting/api"
import { pathwaysSchema } from "../../../apis/contextmeeting/schema"
import { withAuthHeader } from "../../../apis/contextmeeting/withAuthHeader"
import { displayUserMessageAction } from "../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../userMessage/state/userMessageTypes"
import { PathwaysActionTypes } from "./pathwaysTypes"

export function* fetchPathwaysAsync(action: Effect): Generator {
  try {
    const query = queryString.stringify(humps.decamelizeKeys(action.payload), { arrayFormat: "bracket" })
    const response: any = yield call(API.get, `/pathways?${query}`, withAuthHeader())
    const normalizedResponse = normalize(response.data.all, [pathwaysSchema])

    yield put({
      type: PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS,
      payload: {
        all: normalizedResponse,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        appliedFilters: response.data.searchQuery.appliedFilters,
        resultsPerPage: response.data.searchQuery.resultsPerPage,
        searchTerm: response.data.searchQuery.searchTerm,
        orderBy: response.data.searchQuery.orderBy
      }
    })
  } catch (error) {
    yield put({
      type: PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "pathwaysNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* watchFetchPathways(): Generator {
  yield takeLeading(PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_PENDING, fetchPathwaysAsync)
}
