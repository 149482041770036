import React from "react"
import { useTranslation } from "react-i18next"
import { IconClose } from "../../../../../svg/icons"

interface IPropsFromParent {
  clearSelectedAsset: () => void
}

type Props = IPropsFromParent

const LiveMeetingAssetViewerCloseButton: React.FC<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation(["shared"])

  return (
    <a
      className="block text-ch-blue-500 hover:text-ch-blue-600 cursor-pointer -mr-2"
      onClick={() => props.clearSelectedAsset()}
    >
      <div className="p-2">
        <IconClose
          className="fill-current w-6 h-6"
          title={t("shared:close")}
        />
      </div>
    </a>
  )
}

export default LiveMeetingAssetViewerCloseButton
