import { ISearchQuery } from "../../../shared/hooks/useSearch"
import { ChatsActionTypes, IFetchChatsAsyncAction, IUpdateSelectedChatId } from "./chatsTypes"

export const fetchChatsAsync = (searchQuery: ISearchQuery): IFetchChatsAsyncAction => {
  return {
    type: ChatsActionTypes.FETCH_CHATS_ASYNC_PENDING,
    payload: searchQuery
  }
}

export const updateSelectedChatId = (chatId: string): IUpdateSelectedChatId => {
  return {
    type: ChatsActionTypes.UPDATED_SELECTED_CHAT_ID,
    payload: chatId
  }
}
