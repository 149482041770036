import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardButtons from "../../../../shared/components/layout/detailsCard/DetailsCardButtons"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import DetailsCardRight from "../../../../shared/components/layout/detailsCard/DetailsCardRight"
import RemoveUserButton from "../../../../shared/components/buttons/RemoveUserButton"
import { getUserByHasUserId } from "../../../../shared/selectors/user"
import UserDetailsCard from "../../../../shared/components/user/UserDetailsCard"
import { getTeamById, getTeamUserByTeamUserId } from "../../../../shared/selectors/team"
import { destroyTeamUser } from "../../../teamUser/state/teamUserActions"
import OwnerLabel from "./OwnerLabel"

interface IPropsFromParent {
  teamId: string
  teamUserId: string
  teamOwnerId: string
}

const TeamUserListItem: React.FC<IPropsFromParent> = (props: IPropsFromParent): JSX.Element => {
  const { t } = useTranslation(["team", "shared"])
  const dispatch = useDispatch()
  const teamUser = useSelector((state: IAppState) => getTeamUserByTeamUserId(state, props))
  const user = useSelector((state: IAppState) => getUserByHasUserId(state, teamUser))
  const team = useSelector((state: IAppState) => getTeamById(state, props.teamId))
  const ownerLabel = props.teamOwnerId == teamUser.userId ? OwnerLabel(t("team:owner")) : null
  const removeUserFromTeam = () => {
    dispatch(destroyTeamUser(props.teamUserId))
  }

  const renderRemoveButton = (): JSX.Element => {
    if (props.teamOwnerId == teamUser.userId) return null
    if (team.permissions.canUninvite) {
      return (
        <RemoveUserButton
          action={removeUserFromTeam}
          label={t("shared:remove")}
          canRemove={true}
        />
      )
    }
  }

  return (
    <li>
      <DetailsCard>
        <DetailsCardInner>
          <UserDetailsCard user={user} nameLabel={ownerLabel} />
        </DetailsCardInner>

        <DetailsCardRight>
          <DetailsCardButtons
            className="items-center"
            buttons={[renderRemoveButton()]}
          />
        </DetailsCardRight>
      </DetailsCard>
    </li>
  )
}

export default TeamUserListItem
