import { Reducer, AnyAction, combineReducers } from "redux"
import _ from "lodash"
import { INormalizedEntities } from "./../../../../app/appTypes"
import { MeetingActionTypes } from "./../../../meeting/state/meetingTypes"
import { UserActionTypes } from "./../../../user/state/userTypes"
import { IUser } from "./../userTypes"
import { MeetingsActionTypes } from "../../../meetings/state/meetingsActionTypes"
import { UsersActionTypes } from "../../../users/state/usersActionTypes"
import { CasesActionTypes } from "../../../cases/state/casesActionTypes"
import { AccountActionTypes } from "../../../account/state/accountTypes"
import { MeetingCaseActionTypes } from "../../../meetingCase/state/meetingCaseTypes"
import { PatientActionTypes } from "../../../patient/state/patientTypes"
import { TeamsActionTypes } from "../../../teams/state/teamsTypes"
import { TeamActionTypes } from "../../../team/state/teamTypes"
import { TenantActionTypes } from "../../../tenant/state/tenantTypes"
import { SharedCableActionTypes } from "../../../../shared/state/sharedTypes"
import { PatientsActionTypes } from "../../../patients/state/patientsTypes"
import { PathwayActionTypes } from "../../../pathway/pathwayTypes"
import { PathwaysActionTypes } from "../../../pathways/state/pathwaysTypes"
import { CaseActionTypes } from "../../../case/state/caseTypes"

type TByIdState = { [id: string]: IUser }
type TAllIdsState = string[]

export const byIdInitialState: TByIdState = {}
export const allIdsInitialState: TAllIdsState = []

export const byId: Reducer<TByIdState> = (state: TByIdState = byIdInitialState, action: AnyAction): TByIdState => {
  switch (action.type) {
    case AccountActionTypes.VERIFY_MFA_ASYNC_SUCCESS:
    case UserActionTypes.FETCH_USER_ASYNC_SUCCESS:
    case UserActionTypes.CHECK_USER_ASYNC_FOUND:
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
    case MeetingCaseActionTypes.FETCH_MEETING_CASE_ASYNC_SUCCESS:
    case PatientActionTypes.FETCH_PATIENT_ASYNC_SUCCESS:
    case UsersActionTypes.FETCH_ALL_USERS_ASYNC_SUCCESS:
    case TeamsActionTypes.FETCH_ALL_TEAMS_ASYNC_SUCCESS:
    case TeamActionTypes.FETCH_TEAM_ASYNC_SUCCESS:
    case TenantActionTypes.CREATE_TENANT_ASYNC_SUCCESS:
    case PathwayActionTypes.FETCH_PATHWAY_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.entities.users)

    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
    case MeetingsActionTypes.FETCH_MEETINGS_IN_RANGE_ASYNC_SUCCESS:
    case CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS:
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
    case TeamsActionTypes.FETCH_TEAMS_ASYNC_SUCCESS:
    case PatientsActionTypes.FETCH_PATIENTS_ASYNC_SUCCESS:
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.users)
    case SharedCableActionTypes.UPDATE_USER_STATUS:
      return _.merge({}, state, action.payload)

    case UserActionTypes.CREATE_USER_ASYNC_SUCCESS:
    case UserActionTypes.UPDATE_USER_ASYNC_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload
      }

    case CaseActionTypes.CREATE_CASE_GUEST_USER_ASYNC_SUCCESS:
      return _.merge({}, state, action.payload.all.entities.users)

    case AccountActionTypes.SIGN_IN_USER_ASYNC_SUCCESS:
      return {
        ...state,
        ...action.payload.user.entities.users
      }

    default:
      return { ...state }
  }
}

export const allIds: Reducer<TAllIdsState> = (state: TAllIdsState = allIdsInitialState, action: AnyAction): TAllIdsState => {
  switch (action.type) {
    case MeetingActionTypes.FETCH_MEETING_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.users)))
    case MeetingsActionTypes.FETCH_MEETINGS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.users || [])))
    case CasesActionTypes.FETCH_CASES_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.users || [])))
    case UsersActionTypes.FETCH_ALL_USERS_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.entities.users)))
    case UsersActionTypes.FETCH_USERS_ASYNC_SUCCESS:
      return _.uniq(action.payload.all.result)
    case UserActionTypes.CREATE_USER_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, action.payload.id))
    case AccountActionTypes.SIGN_IN_USER_ASYNC_SUCCESS:
      return _.uniq(_.concat(state, Object.keys(action.payload.user.entities.users)))
    case PathwaysActionTypes.FETCH_PATHWAYS_ASYNC_SUCCESS:
        return _.uniq(_.concat(state, Object.keys(action.payload.all.entities.users || [])))
    default:
      return state
  }
}

export const userEntityReducer: Reducer<INormalizedEntities<IUser>> = combineReducers({
  byId,
  allIds
})
