import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { IAppState } from "../../../app/appTypes"
import { getUsersById } from "../../selectors/user"
import { getTeamById } from "../../selectors/team"
import NameUser from "../names/NameUser"

interface IProps {
  teamIds: string[]
  header: string
}

const TeamMemberList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const teams = useSelector((state: IAppState) => props.teamIds.map((id) => getTeamById(state, id)))
  const [userIds, setUsers] = useState<string[]>([])

  useEffect(() => {
    const userIds = _.uniq(teams.flatMap((team) => team.users)) || []
    setUsers(userIds)
  }, [props.teamIds])

  const users = useSelector((state: IAppState) => getUsersById(state, userIds))
  const sortedUsers = _.sortBy(users, ["lastName", "firstName"])

  if (!users || users.length == 0) return null

  const maxH = users.length > 20 ? "max-h-64" : "max-h-32"
  return (
    <div>
      <div className="mb-2">{props.header}</div>
      <div className={`flex flex-col flex-wrap ${maxH} place-content-start overflow-auto mb-6`}>
        {sortedUsers.map((user) => <div className="w-1/6 mb-1" key={user.id}><NameUser firstName={user.firstName} lastName={user.lastName} isPending={user.pending} /></div>)}
      </div>
    </div>
  )
}

export default TeamMemberList
