import { useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Label from "../../../../shared/components/forms/Label"
import InputField from "../../../../shared/components/forms/InputField"
import FieldError from "../../../../shared/components/forms/FieldError"
import { IPatientFurtherDetailsData } from "../../state/patientNewTypes"
import PostcodeLookup from "../../../../shared/components/forms/PostcodeLookup"

const GPFields: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["shared", "patient"])
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange
  } = useFormikContext<IPatientFurtherDetailsData>()

  return (
    <div>
      <div className="mb-2">
        <PostcodeLookup
          handleChange={handleChange}
          fieldNames={{
            postcode: "gpPostcode",
            addressLine1: "gpAddressLine1",
            addressLine2: "gpAddressLine2",
            city: "gpCity",
            county: "gpCounty",
            country: "gpCountry",
          }}
        />

        <Label name="gpAddressLine1">
          {t("patient:details:furtherDetails:gpAddressLine1")}
        </Label>
        <InputField
          type="text"
          name="gpAddressLine1"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpAddressLine1}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpAddressLine1 as string} isVisible={(errors.gpAddressLine1 && touched.gpAddressLine1) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpAddressLine2">
          {t("patient:details:furtherDetails:gpAddressLine2")}
        </Label>
        <InputField
          type="text"
          name="gpAddressLine2"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpAddressLine2}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpAddressLine2 as string} isVisible={(errors.gpAddressLine2 && touched.gpAddressLine2) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpCity">
          {t("patient:details:furtherDetails:gpCity")}
        </Label>
        <InputField
          type="text"
          name="gpCity"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpCity}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpCity as string} isVisible={(errors.gpCity && touched.gpCity) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpCounty">
          {t("patient:details:furtherDetails:gpCounty")}
        </Label>
        <InputField
          type="text"
          name="gpCounty"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpCounty}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpCounty as string} isVisible={(errors.gpCounty && touched.gpCounty) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpCountry">
          {t("patient:details:furtherDetails:gpCountry")}
        </Label>
        <InputField
          type="text"
          name="gpCountry"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpCountry}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpCountry as string} isVisible={(errors.gpCountry && touched.gpCountry) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpEmail">
          {t("patient:details:furtherDetails:gpEmail")}
        </Label>
        <InputField
          type="email"
          name="gpEmail"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpEmail}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpEmail as string} isVisible={(errors.gpEmail && touched.gpEmail) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpPhoneNumber">
          {t("patient:details:furtherDetails:gpPhoneNumber")}
        </Label>
        <InputField
          type="tel"
          name="gpPhoneNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpPhoneNumber}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpPhoneNumber as string} isVisible={(errors.gpPhoneNumber && touched.gpPhoneNumber) as boolean} />
      </div>

      <div className="mb-2">
        <Label name="gpName">
          {t("patient:details:furtherDetails:gpName")}
        </Label>
        <InputField
          type="tel"
          name="gpName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gpName}
          autoComplete="off"
        />
        <FieldError errorMessage={errors.gpName as string} isVisible={(errors.gpName && touched.gpName) as boolean} />
      </div>
    </div>
  )
}

export default GPFields
