import React, { Dispatch, SetStateAction } from "react"
import { useFormikContext } from "formik"
import Label from "../../../shared/components/forms/Label"
import ToggleField from "../../../shared/components/forms/ToggleField"
import { ITeamNewData, TeamTypes } from "../../team/state/teamTypes"

interface IProps {
  isMdtTeam: boolean
  setIsMdtTeam: Dispatch<SetStateAction<boolean>>
}

const MdtTeamToggleTypeField: React.FC<IProps> = (props) => {
  const {
    initialValues,
    values,
    setFieldValue,
    resetForm
  } = useFormikContext<ITeamNewData>()

  return (
    <div className="flex justify-right my-2" >
      <div className="">
        <Label name="type">
          Team type
        </Label>

        <ToggleField
          AActive={!props.isMdtTeam}
          handlerA={() => {
            resetForm({
              values: {
                ...initialValues,
                name: values.name,
                specialtyId: values.specialtyId
              }
            })

            setFieldValue("teamType", TeamTypes.basic)
            props.setIsMdtTeam(false)
          }}
          handlerB={() => {
            resetForm({
              values: {
                ...initialValues,
                name: values.name,
                specialtyId: values.specialtyId
              }
            })
            setFieldValue("teamType", TeamTypes.mdt)
            props.setIsMdtTeam(true)
          }}
          translationNamespace="team"
          textAKey="team:teamTypes:basicTeam"
          textBKey="team:teamTypes:mdtTeam"
        />
      </div>
    </div >
  )
}

export default MdtTeamToggleTypeField
