import { ICheckPatientAsyncAction, ICreatePatientAsyncAction, IPatientCheckData, IPatientNewData, IResetCheckPatientAction, PatientNewActionTypes } from "./patientNewTypes"

export const checkPatientAction = (patientCheckData: IPatientCheckData): ICheckPatientAsyncAction => {
  return {
    type: PatientNewActionTypes.CHECK_PATIENT_ASYNC_PENDING,
    payload: {
      patientCheckData: patientCheckData
    }
  }
}

export const resetCheckPatientAction = (): IResetCheckPatientAction => {
  return {
    type: PatientNewActionTypes.CHECK_PATIENT_ASYNC_RESET
  }
}

export const createPatientAction = (patient: IPatientNewData): ICreatePatientAsyncAction => {
  return {
    type: PatientNewActionTypes.CREATE_PATIENT_ASYNC_PENDING,
    payload: {
      patient: patient
    }
  }
}
