/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash"
import { parse, addMinutes, format } from "date-fns"
import React from "react"
import { ISelectFieldOption } from "../../../shared/components/forms/types"
import { formatTimeString } from "../../../shared/helpers/timeHelpers"
import SelectField from "../../../shared/components/forms/SelectField"

interface IPropsFromParent {
  name: string,
  isDisabled?: boolean
  placeholder?: string
  onChange: (e: React.ChangeEvent<any>) => void
  onBlur: (e: React.FocusEvent<any>) => void
  onFocus?: (e: React.FocusEvent<any>) => void
}

type Props = IPropsFromParent

const TimeInput = (props: Props): JSX.Element => {
  const today = parse("06:45am", formatTimeString, new Date())

  const options: ISelectFieldOption[] = Array.from({ length: 4 * 24 }, (_, i) => i + 1).map((i) => {
    const time = addMinutes(new Date(today), i * 15)
    return {
      label: format(time, formatTimeString),
      value: format(time, formatTimeString),
    }
  })

  return (
    <div className="w-32">
      <SelectField
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        options={options}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export default TimeInput
