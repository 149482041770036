import { useEffect, useState } from "react"
import useCurrentBreakPoint from "./useCurrentBreakPoint"

const useIsMobileView = (breakpoints: string[] = ["sm"]): boolean => {
  const currentBreakpoint: string = useCurrentBreakPoint()
  const mobileViewBreakpoints = breakpoints
  const checkMobileView = (): boolean => {
    return mobileViewBreakpoints.includes(currentBreakpoint) || currentBreakpoint == undefined
  }
  const [isMobileView, setIsMobileView] = useState(checkMobileView())

  useEffect(() => {
    setIsMobileView(checkMobileView())
  }, [currentBreakpoint])

  return isMobileView
}

export default useIsMobileView
