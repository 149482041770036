import React, { useEffect, useState } from "react"
import fetchBlob from "../../helpers/fetchBlob"
import LoadingSpinner from "../ui/LoadingSpinner"

interface IPropsFromParent {
  path: string
  title: string
}

type Props = IPropsFromParent

const ImageThumb: React.FC<Props> = (props: Props): JSX.Element => {
  const [blobUrl, setBlobUrl] = useState("")

  useEffect(() => {
    if (!props.path) return

    fetchBlob(props.path, setBlobUrl)
  }, [props.path])

  if (!blobUrl) return <LoadingSpinner />

  return (
    <img
      src={blobUrl}
      title={props.title}
    />
  )
}

export default ImageThumb
