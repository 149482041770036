import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import LoadingSpinner from "../../../../shared/components/ui/LoadingSpinner"
import { getAssetById } from "../../../../shared/selectors/asset"
import { IRadiologyLinkAsset } from "../../../assets/state/assetsTypes"
import API from "../../../../apis/contextmeeting/api"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"

interface IProps {
  assetId: string
}

const CaseRadiologyViewer: React.FC<IProps> = (props): JSX.Element => {
  const asset = useSelector((state: IAppState) => getAssetById(state, props))
  const [targetUrl, setTargetUrl] = useState(null)

  const link = asset?.asset as IRadiologyLinkAsset

  const fetchTargetUrl = async () => {
    // we swap the link on the radiology link, which points at our server, for a
    // url that points at Idonia, which we then load in the iframe
    if (!link) return
    const { data } = await API.get(link.link, withAuthHeader())
    setTargetUrl(data.link)
  }

  useEffect(() => {
    fetchTargetUrl()
  }, [props.assetId])

  if (!link) return null
  if (!targetUrl) return (
      <div className="flex items-center justify-center h-full">
        <LoadingSpinner />
      </div>
  )

  return (
    <iframe className="w-full h-full" src={targetUrl} >
    </iframe>
  )
}

export default CaseRadiologyViewer
