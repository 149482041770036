import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../app/appTypes"
import PathwayListItem  from "./PathwayListItem"

export const PathwayList = (): JSX.Element => {
  const pathwayIds = useSelector((state: IAppState) => state.ui.pathways.onPage)
  return (
    <div>
      <ul>
        {pathwayIds.map((id: string) =>
            <PathwayListItem
              key={id}
              pathwayId={id}
            />
        )}
      </ul>
    </div>
  )
}

export default PathwayList
