import { ISearchQuery } from "../../../shared/hooks/useSearch"

// State
export interface IAddCaseToMeetingUIState {
  totalCasesCount: number
  totalPages: number
  appliedFilters: string[]
  orderBy: string
  resultsPerPage: number
  searchTerm: string
  resultIds: string[]
}

// Actions
export interface IFetchCasesAddableToMeetingsAsyncAction {
  type: string
  payload: ISearchQuery
}

// Action Types
export enum AddCaseToMeetingActionTypes {
  FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_PENDING = "@@addCaseToMeeting/FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_PENDING",
  FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_SUCCESS = "@@addCaseToMeeting/FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_SUCCESS",
  FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_ERROR = "@@addCaseToMeeting/FETCH_CASES_ADDABLE_TO_MEETINGS_ASYNC_ERROR",
}
