import React from "react"
import { useSelector } from "react-redux"
import { IAppState } from "../../app/appTypes"
import { Trans, useTranslation } from "react-i18next"
import Pagination from "../../shared/components/search/Pagination"
import PageTitle from "../../shared/components/ui/PageTitle"
import ResultsPerPage from "../../shared/components/search/ResultsPerPage"
import { getPathwaysUI, getQuickViewResourceId } from "../../shared/selectors/ui"
import { IPathwaysUIState, PathwaysOrderByOptions } from "../../features/pathways/state/pathwaysTypes"
import OrderBy from "../../shared/components/search/OrderBy"
import SearchInput from "../../shared/components/search/SearchInput"
import useSearch, { ISearchQuery } from "../../shared/hooks/useSearch"
import { fetchPathwaysAction } from "../../features/pathways/state/pathwaysActions"
import PathwayList from "../../features/pathways/components/list/PathwayList"
import { RoutePaths } from "../../app/routes/Routes"
import QuickView from "../../shared/components/ui/quickView/QuickView"
import PathwayQuickView from "../../features/pathways/components/quickView/PathwayQuickView"
import PathwayFilter from "../../features/pathways/components/PathwayFilter"

const PathwaysPage = (): JSX.Element => {
  const { t } = useTranslation(["shared", "pathway"])
  const UI: IPathwaysUIState = useSelector((state: IAppState) => getPathwaysUI(state))
  const initialSearchQuery: ISearchQuery = {
    page: 1,
    appliedFilters: UI.appliedFilters,
    searchTerm: UI.searchTerm,
    resultsPerPage: UI.resultsPerPage,
    orderBy: UI.orderBy
  }
  const [searchQuery, setSearchQuery] = useSearch(initialSearchQuery, fetchPathwaysAction)
  const quickViewResourceId = useSelector((state: IAppState) => getQuickViewResourceId(state))

  return (
    <div>
      <PageTitle
        pageTitle={t("shared:pathways")}
        buttonText={t("pathway:addNewPathway")}
        buttonHref={RoutePaths.PATHWAY_NEW}
        hasButtonPermission={true}
      />
      <div className="grid grid-rows-2 md:grid-rows-1 flex items-stretch">
        <div className="row-start-2 md:row-start-1 self-center">
          <Trans
            i18nKey="pathway:index:searchBy"
            components={{
              strong: <strong />
            }}
          />
        </div>
        <div className="row-end-2 md:row-start-1 flex justify-end">
          <ResultsPerPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>

      <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        buttonText={t("pathway:index:buttons:searchPathways")}
      />

      <h2 className="text-lg mb-2 text-center md:text-left">
        {UI.totalPathwaysCount} {t("shared:pathways")}
      </h2>

      <div className="grid grid-flow-row md:grid-flow-col">
        <div>
          <PathwayFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        <div className="flex justify-end">
          <OrderBy
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            orderByOptions={PathwaysOrderByOptions}
            useTranslation={"pathway:index:orderBy"}
          />
        </div>
      </div>
      <PathwayList />
      <Pagination
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        totalItems={UI.totalPathwaysCount}
        totalPages={UI.totalPages}
      />
      <QuickView>
        <PathwayQuickView pathwayId={quickViewResourceId} />
      </QuickView>
    </div>
  )
}

export default PathwaysPage
