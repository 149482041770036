import API from "../../../../apis/contextmeeting/api"
import { TeamActionTypes } from "../teamTypes"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { call, Effect, put, takeLeading } from "redux-saga/effects"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { RoutePaths } from "../../../../app/routes/Routes"
import history from "../../../../app/routes/history"
import { normalize } from "normalizr"
import { fetchTeamSchema } from "../../../../apis/contextmeeting/schema"
import { generatePath } from "react-router"

export function* teamWatchers(): Generator {
  yield takeLeading(TeamActionTypes.FETCH_TEAM_ASYNC_PENDING, fetchTeamAsync)
  yield takeLeading(TeamActionTypes.CREATE_TEAM_ASYNC_PENDING, createTeamAsync)
  yield takeLeading(TeamActionTypes.DELETE_TEAM_ASYNC_PENDING, deleteTeamAsync)
  yield takeLeading(TeamActionTypes.UPDATE_TEAM_USERS_ASYNC_PENDING, updateTeamUsersAsync)
  yield takeLeading(TeamActionTypes.UPDATE_TEAM_ASYNC_PENDING, updateTeamAsync)
}

export function* fetchTeamAsync(action: Effect): Generator {
  try {
    const teamId = action.payload

    const response: any = yield call(API.get, `/teams/${teamId}`, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchTeamSchema)

    yield put({
      type: TeamActionTypes.FETCH_TEAM_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: TeamActionTypes.FETCH_TEAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "teamNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* createTeamAsync(action: Effect): Generator {
  try {
    const team = {
      team: action.payload
    }

    const response: any = yield call(API.post, "/teams", team, withAuthHeader())

    yield put({
      type: TeamActionTypes.CREATE_TEAM_ASYNC_SUCCESS,
      payload: response.data
    })

    yield put(displayUserMessageAction({
      messageKey: "createTeamSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    yield call(history.push, RoutePaths.TEAMS)
  } catch (error) {
    yield put({
      type: TeamActionTypes.CREATE_TEAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "createTeamFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* updateTeamAsync(action: Effect): Generator {
  try {
    const team = {
      team: action.payload.team
    }

    const response: any = yield call(API.patch, `/teams/${action.payload.id}`, team, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchTeamSchema)

    yield put({
      type: TeamActionTypes.UPDATE_TEAM_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "updateTeamSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewTeamUrl = generatePath(RoutePaths.TEAM, {
      teamId: action.payload.id
    })

    yield call(history.push, viewTeamUrl)
  } catch (error) {
    yield put({
      type: TeamActionTypes.UPDATE_TEAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateTeamFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* updateTeamUsersAsync(action: Effect): Generator {
  try {
    console.log(action)

    const response: any = yield call(API.patch, `/teams/${action.payload.id}/update_team_members`, { team: action.payload.data }, withAuthHeader())

    const normalizedResponse = normalize(response.data, fetchTeamSchema)

    yield put({
      type: TeamActionTypes.UPDATE_TEAM_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

    yield put(displayUserMessageAction({
      messageKey: "updateTeamSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewTeamUrl = generatePath(RoutePaths.TEAM, {
      teamId: action.payload.id
    })

    yield call(history.push, viewTeamUrl)
  } catch (error) {
    yield put({
      type: TeamActionTypes.UPDATE_TEAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "updateTeamFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* deleteTeamAsync(action: Effect): Generator {
  try {
    const teamId = action.payload

    yield call(API.delete, `/teams/${teamId}`, withAuthHeader())

    yield put({
      type: TeamActionTypes.DELETE_TEAM_ASYNC_SUCCESS
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteTeamSuccess",
      type: UserMessageTypes.SUCCESS
    }))

    const viewTeamUrl = generatePath(RoutePaths.TEAMS)
    yield call(history.push, viewTeamUrl)

  } catch (error) {
    yield put({
      type: TeamActionTypes.DELETE_TEAM_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "deleteTeamFail",
      type: UserMessageTypes.ERROR
    }))
  }
}
