import React from "react"
import { useTranslation } from "react-i18next"
import NameUser from "../../../../shared/components/names/NameUser"
import DetailsCard from "../../../../shared/components/layout/detailsCard/DetailsCard"
import DetailsCardInner from "../../../../shared/components/layout/detailsCard/DetailsCardInner"
import IconCircleContainer from "../../../../shared/components/icons/IconCircleContainer"
import { IconFolder } from "../../../../svg/icons"
import TimeDateOnly from "../../../../shared/components/time/TimeDateOnly"
import { useSelector } from "react-redux"
import { IUser } from "../../../user/state/userTypes"
import { getUserById } from "../../../../shared/selectors/user"
import { IAppState } from "../../../../app/appTypes"
import TextCaseType from "../../../../shared/components/text/TextCaseType"
import { ICaseClassification } from "../../../caseClassification/state/caseClassificationTypes"
import { getCaseClassificationById } from "../../../../shared/selectors/caseClassification"
import { ICase } from "../../../case/state/caseTypes"
import { getCaseById } from "../../../../shared/selectors/case"

interface Props {
  caseId: string
  isDark?: boolean
}

const PatientCaseListItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["shared", "patient"])
  const theCase: ICase = useSelector((state: IAppState) => getCaseById(state, props))
  const leadCareUser: IUser = useSelector((state: IAppState) => getUserById(state, theCase.leadCareUser))
  const caseClassificiation: ICaseClassification = useSelector((state: IAppState) => getCaseClassificationById(state, theCase.caseClassificationId))

  const renderCaseType = (): JSX.Element => {
    return (
      <li>
        {t("case:type")}: <TextCaseType caseType={theCase.caseType} />
      </li>
    )
  }

  const renderLeadCare = (): JSX.Element => {
    if (!leadCareUser) return (
      <li>
        {t("case:leadCare")}: -
      </li>
    )
    return (
      <li>
        {t("case:leadCare")}: <NameUser
          firstName={leadCareUser.firstName}
          lastName={leadCareUser.lastName}
          professionalTitle={leadCareUser.professionalTitle}
          isPending={leadCareUser.pending}
        />
      </li>
    )
  }

  const renderClassification = (): JSX.Element => {
    if (!caseClassificiation) return (
      <li>
        {t("shared:classification")}: -
      </li>
    )
    return (
      <li>
        {t("shared:classification")}: {caseClassificiation.name}
      </li>
    )
  }

  const renderSourceName = (): JSX.Element => {
    return (
      <li>
        {t("case:source")}: {theCase.sourceName}
      </li>
    )
  }

  const renderTargetDate = (): JSX.Element => {
    const targetDate = theCase.targetDate
    if (!targetDate) return (
      <li>
        {t("case:targetDate")}: -
      </li>
    )
    return (
      <li>
        {t("case:targetDate")}: <TimeDateOnly utcTime={targetDate} />
      </li>
    )
  }

  return (
    <DetailsCard isDark={props.isDark}>
      <DetailsCardInner>
        <IconCircleContainer className="sm:mr-4">
          <IconFolder className="fill-current w-6 h-6" title={t("shared:case")} />
        </IconCircleContainer>
        <div className="flex-1 lg:grid grid-cols-2 gap-3">
          <ul className="col-span-1">
            {renderLeadCare()}
            {renderClassification()}
            {renderSourceName()}
          </ul>

          <ul className="col-span-1">
            {renderCaseType()}
            {renderTargetDate()}
          </ul>
        </div>
      </DetailsCardInner>
    </DetailsCard>
  )
}

export default PatientCaseListItem
