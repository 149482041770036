import React from "react"
import { MeetingRoles } from "../../../features/meeting/state/meetingTypes"

interface IProps {
  className?: string
  colorKey?: string
}

const IconCalendarSquare: React.FC<IProps> = (props: IProps): JSX.Element => {
  const defaultClasses = [
    "flex-col",
    "flex-shrink-0",
    "border",
    "rounded-none",
    "w-3",
    "h-3",
    "mx-1",
  ]

  const assignColors = () => {
    switch (props.colorKey) {
      case MeetingRoles.Coordinator:
        return ["bg-white", "border-ch-gray-400"]
      case MeetingRoles.Invited:
        return ["bg-ch-blue-200", "border-ch-blue-200"]
      default:
        return []
    }
  }

  const colorClasses = assignColors()

  const propClasses = props.className ? props.className.split(" ") : []
  const allClasses = [...defaultClasses, ...propClasses, ...colorClasses].join(" ")

  return (
    <div className={"self-center"}>
      <div className={allClasses}></div>
    </div>

  )
}

export default IconCalendarSquare
